import {useState} from 'react';
import {appointment} from '@solimat/solimat-web-endpoint';
import Moment from 'moment'
import {StatusDto} from '@solimat/solimat-web-endpoint/lib/appointment/dto/Appointment/StatusDto';
import useDecode from 'hooks/useDecode';

const useGetAppointmentById = () => {
  const [loadingGetAppointmentById, setLoadingGetAppointmentById] = useState(false);
  const [successGetAppointmentById, setSuccessGetAppointmentById] = useState(false);
  const [errorGetAppointmentById, setErrorGetAppointmentById] = useState(null);
  const [dataAppointment, setDataAppointment] = useState(null);
  const BASE_URL = process.env.REACT_APP_CITAS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_CITAS_KEY || "";
  const apiAppointment = new appointment.AppointmeService(BASE_URL, FUNCTIONS_KEY);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
  const getAppointmentById = (id:any, _cb:(data:any)=>void) => {
    setLoadingGetAppointmentById(true);
    setSuccessGetAppointmentById(false);
    apiAppointment.getAppointment(id, userId)
      .then((res:any) => {
        
        let formatedAppointment = {
          ...res.data,
          formated_item_type: res.data.appointment_type,
          formated_start: Moment(res.data.appointment_date).format("L"),
          formated_hour:  Moment(res.data.appointment_date).format("HH:mm")
        }
        setLoadingGetAppointmentById(false);
        setSuccessGetAppointmentById(true);
        setDataAppointment(formatedAppointment);
        if (typeof _cb === "function") {
          _cb(formatedAppointment);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetAppointmentById(false);
        setErrorGetAppointmentById(err);
      })
  }

  return {
    getAppointmentById,
    loadingGetAppointmentById,
    successGetAppointmentById,
    errorGetAppointmentById,
    dataAppointment
  };
}

export default useGetAppointmentById;