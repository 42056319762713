import React, { useState, useEffect } from "react";
import Moment from "moment";
import PageMenu from "../../../components/blocks/PageMenu/PageMenu";
import Pagination from "../../../components/blocks/Pagination/Pagination";
import CardLinkColor from "../../blocks/CardsLink/CardLinkColor";
import RecordsGrid from "../../blocks/Grids/RecordsGrid";
import useCheckTypes from "../../../hooks/useCheckRecordStatusAndTypes";
import { IRecordResponse } from "@solimat/solimat-web-endpoint/lib/record/dto";
import useGetAllAvailableRecordByUser from "../../../services/recordSheets/useGetAllAvailableRecordByUser";
import CardNewManagement from "components/CardNewManagement/CardNewManagement";
import useSortDataRecords from "hooks/useSortDataRecords";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import useGetRole from "hooks/useGetRole";
import NoData from "../noData/NoData";

const Record: React.FC = (props: any) => {
  const [records, setRecords] = useState<IRecordResponse[]>([]);
  const [recordsCards, setRecordsCards] = useState<IRecordResponse[]>([]);
  const [ascendingCards, setAscendingCards] = useState(true);
  const [viewCard, setViewCard] = useState(true);

  const {
    getAllAvailableRecordByUserS,
    loadingGetAllAvailableRecordByUser,
    userId,
  } = useGetAllAvailableRecordByUser();
  const { orderDescendingData, orderAscendingData } = useSortDataRecords();
  const { checkCoverageType, checkContingencyType } = useCheckTypes();

  const [errorRequest, setErrorRequest] = useState(null);
  // State pagination
  const [currentPageItems, setCurrentPageItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [paginationPageSizes] = useState<string[]>([
    "2",
    "4",
    "8",
    "12",
    "16",
    "20",
    "Todos",
  ]);
  // Get current recordCard
  const indexOfLastItem = currentPageItems * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = recordsCards.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginateCards = (pageNumber: number) => setCurrentPageItems(pageNumber);
  const handleOnChangeItemsPerPage = (dropdownValue: string) => {
    let numItems = itemsPerPage;
    if (dropdownValue === "Todos") {
      numItems = recordsCards.length;
    } else {
      numItems = parseInt(dropdownValue);
    }
    setItemsPerPage(numItems);
    paginateCards(1);
  };

  const { isImpersonation , isSIP} = useGetRole;

  useEffect(() => {
    getAllAvailableRecordByUserS(userId, handleSetAllRecords);
    return () => {
      getAllAvailableRecordByUserS(userId, handleSetAllRecords);
    }
    Moment().locale("es").format("L");
  }, []);

  const handleSetAllRecords = (formatRes: any) => {
    orderDescendingData(formatRes, setRecords);
    orderDescendingData(formatRes, setRecordsCards);
  };

  const handleUpdateRecords = (recordsOrdered: any) => {
    setRecordsCards(recordsOrdered);
  };

  const orderByAscending = () => {
    // by old to new
    orderAscendingData(recordsCards, handleUpdateRecords);
    setAscendingCards(false);
  };

  const orderByDescending = () => {
    // by new to old
    orderDescendingData(recordsCards, handleUpdateRecords);
    setAscendingCards(true);
  };

  const handleToggleViewCard = () => {
    setViewCard(!viewCard);
  };

  const breadcrumbs = ["Historial"];
  if (records === null ) {
    return (
      <section className="container">
        {/* <p className="loader-text">No hay datos para mostrar</p> */}
        <PageMenu title="Historial"> </PageMenu>
        <NoData message={'No hay datos de historial para mostrar.'}/>
      </section>
    );
  } else {
    return (
      <>
        <PageMenu title="Historial" breadcrumbs={breadcrumbs}>
          {records.length ? (
            <div className="btns-header-container">
              <button
                className={`btn-toggle-view ${viewCard ? "selected" : ""}`}
                onClick={handleToggleViewCard}
              >
                <span className="e-icons e-Level4"></span>
              </button>
              <button
                className={`btn-toggle-view ${viewCard ? "" : "selected"}`}
                onClick={handleToggleViewCard}
              >
                <span className="e-icons e-M_Bullets"></span>
              </button>
            </div>
          ) : null}
        </PageMenu>
        <section className="container">
          <div className="row">
            <div className="col">
              {errorRequest ? (
                <p className="loader-text">
                  Lo sentimos. Se ha producido un error.
                </p>
              ) : null}
              {loadingGetAllAvailableRecordByUser && records.length < 1 ? (
                // <p className="loader-text">Cargando...</p>
                <LoaderMin />
              ) : null}
              {!loadingGetAllAvailableRecordByUser &&
                records.length < 1 &&
                !errorRequest && (
                  // <p className="loader-text">
                  //   No hay datos de historial para mostrar.
                  // </p>
                  <NoData message={'No hay datos de historial para mostrar.'}/>
                )}
              {records.length > 0 && (
                <>
                  {viewCard ? (
                    <>
                      <div className="row">
                        <div className="col-12 btns-upon-box-container">
                          {ascendingCards ? (
                            <button onClick={() => orderByAscending()}>
                              Fecha reciente{" "}
                              <span className="e-icons e-Filter descending"></span>
                            </button>
                          ) : (
                            <button onClick={() => orderByDescending()}>
                              Fecha antigua{" "}
                              <span className="e-icons e-Filter ascending"></span>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        {currentItems.map((item: any) => {
                          return (
                            <div
                              className="col-sm-6 col-md-4 col-lg-4 col-xl-4 container-card-link-color"
                              key={item.id}
                            >
                              <CardLinkColor
                                props={props}
                                tipoPrestacion={item.TipoPrestacion}
                                contingencia={item.contingencia}
                                title={checkContingencyType(
                                  parseInt(item.contingencia)
                                )}
                                expedienteId={item.ExpedienteId}
                                tieneExtincion={item.tieneExtincion}
                                descripcionExtincion={item.descripcionExtincion}
                                oficioId={item.oficioId}
                                procesoId={item.procesoId}
                                linkTo={`/historial/${item.TipoPrestacion}_${item.id}`}
                                descripcion={checkCoverageType(
                                  parseInt(item.cobertura)
                                )}
                                info={
                                  item.status === "0" ||
                                    item.fechaInicio !== null
                                    ? ` ${Moment(item.fechaInicio).format("L")}`
                                    : ` `
                                }
                                info2={
                                  item.status === "0" || item.fechaFin !== null
                                    ? ` ${Moment(item.fechaFin).format("L")}`
                                    : ` `
                                }
                                statusClass={
                                  item.TipoPrestacion === 1
                                    ? "bright"
                                    : item.TipoPrestacion === 2
                                      ? "dark"
                                      : item.TipoPrestacion === 3
                                        ? "clear"
                                        : item.cobertura === 0 ||
                                          item.contingencia === 0
                                          ? "undetermined"
                                          : ""
                                }
                              />
                            </div>
                          );
                        })}
                        {(!isImpersonation() || isSIP())&&
                          <CardNewManagement />
                        }
                        {recordsCards.length >= 5 ? (
                          <div className="col-12">
                            <Pagination
                              itemsPerPage={itemsPerPage}
                              totalItems={recordsCards.length}
                              paginate={paginateCards}
                              currentPage={currentPageItems}
                              handleOnChangeItemsPerPage={
                                handleOnChangeItemsPerPage
                              }
                              paginationPageSizes={paginationPageSizes}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="records__grid">
                      <RecordsGrid arrData={records && records} props={props} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Record;