import React, { createContext } from 'react';
import CardLinkIcon from 'components/blocks/Cards/CardLinkIcon/CardLinkIconDelta';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import { Link } from 'react-router-dom';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";


class Context {
  masterData: any;
  handleMasterDataChange: (key: string, value: any) => void
}
const defaultValue: Context = {
  masterData: { init: true, type: '' },
  handleMasterDataChange: function (key: string, value: any): void {

  }
}
export const MasterDataContext = createContext<Context>(defaultValue);

const Delta:React.FC = () => {
  const breadcrumbs = ["Unidad Delta"];
  const closerToTop: any = { 'marginTop': -55, 'paddingLeft': 30, 'paddingRight': 30};

  return (
    <>
    <PageMenu title="Unidad Delta" breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to="/">
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
    
    <section className="container">
      <div className="row" style={closerToTop}>
        <PageMenu title="Con Baja" level={1}>
        </PageMenu>
        <div className="col-xl-6">
          <CardLinkIcon 
            iconType="consultation"
            title="Accidentes con baja pendientes Delt@"
            pathTo="/unidad-delta/accidentes-con-baja-pendientes-de-delta"
            bgColorStyle="01"
          />
        </div>
        
        <div className="col-xl-6">
          <CardLinkIcon 
            bgColorStyle="04"
            iconType="documentation"
            title="Accidentes con baja Delt@ remesados"
            pathTo="/unidad-delta/accidentes-con-baja-delta-remesados"
          />
        </div>

        <PageMenu title="Sin Baja" level={1}>
        </PageMenu>

        <div className="col-xl-6">
          <CardLinkIcon 
            iconType="consultation"
            title="Accidentes sin baja pendientes Delt@"
            pathTo="/unidad-delta/accidentes-sin-baja-pendientes-delta"
            bgColorStyle="01"
            />
        </div>
        
        <div className="col-xl-6">
          <CardLinkIcon 
            bgColorStyle="04"
            iconType="documentation"
            title="Accidentes sin baja Delt@ remesados"
            pathTo="/unidad-delta/accidentes-sin-baja-delta-remesados"
          />

        </div>
      </div>
    </section>
    </>
  )
}

export default Delta;