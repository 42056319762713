import { useState } from "react";
import { notifications } from "@solimat/solimat-web-endpoint";
import { INotificationRequest } from "@solimat/solimat-web-endpoint/lib/notifications/dto"


const useUpdateNotification = () => {
  const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_NOTIFICACIONES_KEY || "";
  const apiNotifications = new notifications.NotificationsService(BASE_URL, FUNCTIONS_KEY);
  
  const [loadingUpdateNotification, setLoadingUpdateNotification] = useState(false);
  const [errorUpdateNotification, setErrorUpdateNotification] = useState(null);
  const [responseUpdate, setResponseUpdate] = useState(false);

  const updateNotification = (userId: string, notificationId: string,_cb?:()=>void) => {
    setLoadingUpdateNotification(true);
    setErrorUpdateNotification(null);
    apiNotifications
      .updateNotification(userId, notificationId)
      .then((res: any) => {
        setLoadingUpdateNotification(false);
        setResponseUpdate(res.data);
        if(typeof _cb === 'function') {
          _cb()
        }
      })
      .catch((err) => {
        console.error("updateNotification: ", err);
        setErrorUpdateNotification(err);
        setLoadingUpdateNotification(false);
      })
      
  };

  return {
    updateNotification,
    responseUpdate,
    loadingUpdateNotification,
    errorUpdateNotification
  };
};

export default useUpdateNotification;