// getAllUserCommunicationByUserId

import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { ICreateUserCommunication } from "@solimat/solimat-web-endpoint/lib/communication/dto"

const useCreateUserCommunicationAppointment = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateUserCommunicationAppointment, setLoadingCreateUserCommunicationAppointment] = useState(false);
  const [successCreateUserCommunicationAppointment, setSuccessCreateUserCommunicationAppointment] = useState(false);
  const [errorCreateUserCommunicationAppointment, setErrorCreateUserCommunicationAppointment] = useState(null);

  const createUserCommunicationAppointment = (id:string ,data:any, _cb?:(id:string)=>void) => {
    setLoadingCreateUserCommunicationAppointment(true);
    setErrorCreateUserCommunicationAppointment(null);
    apiCommunication
      .createUserCommunicationAppointment(id, data)
      .then((res: any) => {
        setSuccessCreateUserCommunicationAppointment(true);
        setLoadingCreateUserCommunicationAppointment(false);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch(err => {
        console.error("createUserCommunicationAppointment error: ", err);
        setErrorCreateUserCommunicationAppointment(err);
        setLoadingCreateUserCommunicationAppointment(false);
      });
  };

  return {
    createUserCommunicationAppointment,
    successCreateUserCommunicationAppointment,
    loadingCreateUserCommunicationAppointment,
    errorCreateUserCommunicationAppointment
  };
};

export default useCreateUserCommunicationAppointment;