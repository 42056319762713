import React, { useEffect, useState } from 'react';
import useGetAllTypeDocument from "services/utils/useGetAllTypeDocument";

interface IDoubleInputProps {
  legend: string,
  nameCode: string,
  idCode: string,
  nameDescription: string,
  idDescription: string,
  placeholderDescription: string,
  errors: any,
  register: any,
  watchTypeDocumentCode?: any,
  watchTypeDocumentDescription?: any
  disabled: boolean
}

const Ipf: React.FC<IDoubleInputProps> = ({  watchTypeDocumentCode, idCode, nameCode, idDescription, nameDescription, legend, placeholderDescription, errors, register, disabled }) => {
  const { getAllTypeDocument } = useGetAllTypeDocument()
  const [allTypeDocument, setAllTypeDocument] = useState<any[] | null>(null);
  const [descriptionTypeDocument, setDescriptionTypeDocument] = useState('');

  useEffect(() => {
    handleGetAllTypeDocument()
  }, [])

  const handleGetAllTypeDocument = async () => {
    const response = await getAllTypeDocument();
    if (response) { 
      setAllTypeDocument(response); 
    }
  }

  const isCode = (input: any) => {
    return input?.code === watchTypeDocumentCode
  }

  const handleCheckCodeDescription = () => {
    const descriptionDocument = allTypeDocument?.find(isCode);
    if (descriptionDocument) {
      setDescriptionTypeDocument(descriptionDocument.description_code);
    } else {
      setDescriptionTypeDocument('');
    }
  }

  return (
    <fieldset className={`input-block input-block--code ${errors && errors[nameCode]?.message || errors && errors[nameDescription]?.message ? 'error' : ''}`} disabled={disabled}>
      <legend className="input-block--code__legend">{legend}</legend>

      <select
        className="code"
        name={nameCode}
        id={idCode}
        {...register(idCode)}
        onChange={(e: any) => console.log("Ipf changed", idCode, e)}
      >
        {allTypeDocument?.map((item: any) => {
          return (
            <option key={item.Codigo} value={item.Codigo}>{item.Descripcion}</option>
          )
        })}
      </select>
      <input
        type="text"
        name={idDescription}
        id={idDescription}
        placeholder={idDescription}
        {...register(idDescription)}
      />
      {errors &&
        <p className="error m-0">
          {errors[nameCode]?.message}
          {errors[nameDescription]?.message}
        </p>
      }
    </fieldset>
  )
}

export default Ipf;

// USE EXAMPLE
{/* <Ipf 
  legend="Ipf"
  nameCode="ipfCode"
  idCode="ipfCode"
  nameDescription="ipfDescription"
  idDescription="ipfDescription"
  placeholderDescription="IPF"
  errors={errors}
  register={register}
/> */}