import React, { createContext, useState } from 'react';

const UserContext = createContext();

const UserContextProvider = ({children}) => {
    const [userData, setUserData] = useState(
        {
        "id": null,
        "first_name": null,
        "first_last_name": null,
        "second_last_name": null,
        "identity": null,
        "identity_type": null,
        "full_name": null,
        "bith_date": null,
        "gender_type": null,
        "members": null
        }
    );
    return (
        <UserContext.Provider value={{userData, setUserData}}>
            {children}
        </UserContext.Provider>
    );
}

export {UserContext, UserContextProvider};