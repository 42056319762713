import React from 'react'
import { dataInicio, dataMisCitas, dataHistorial, dataMisGestiones, dataMisPrestaciones, dataMisNotificaciones } from './dataAyuda'
import { useHistory, useLocation } from 'react-router-dom';
import PageMenu from '../../blocks/PageMenu/PageMenu';

import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
const AyudaGeneral = () => {
    const history = useHistory();
    const location = useLocation();
    const from = new URLSearchParams(location.search).get('from');
  
    let currentPageData = from ? getPageData(from) : null;

    function getPageData(from) {
        switch (from) {
            case "/":
            case "/inicio":
                return dataInicio;
            case "/mis-citas":
                return dataMisCitas;
            case "/historial":
                return dataHistorial;
            case "/mis-gestiones":
                return dataMisGestiones;
            case "/historial/*":
                return dataMisPrestaciones;
            case "/notificaciones":
                return dataMisNotificaciones;
            default:
                return null;
        }
    }
    if (from?.match(/^\/historial\/\d+(_\w+)?/)) {
        currentPageData = dataMisPrestaciones;
    }

    return (
        <>
            <PageMenu
                title={'Ayuda'}
            >
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}><img src={IconArrowGray} alt="icon arrow right" /></button>
                </div>
            </PageMenu>


            <div className="container container-carrusel-ayuda">
                <div id="carouselAyudaGeneral" className="carousel carousel-dark carousel slide carousel-fade" data-bs-touch="false">
                    <div className="carousel-indicators">
                        {Object.keys(currentPageData).map((path, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselAyudaGeneral"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-label={currentPageData.name}
                            />
                        ))}
                    </div>
                    <div className="carousel-inner">

                        {currentPageData?.map((help, i) => (
                            <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                                <img src={help.url} className='img-fluid img-carrusel-ayuda' alt={help.alt} />
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev prev" type="button" data-bs-target="#carouselAyudaGeneral" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next next" type="button" data-bs-target="#carouselAyudaGeneral" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </>
    )
}

export default AyudaGeneral