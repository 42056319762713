import React, { useContext } from "react";
import { CookiesPolicyContext } from "contexts/CookiesPolicyContext";
import useCreateEvent from "services/stats/useCreateEvent";
import useDecode from "../../../hooks/useDecode";
import "./cookiesPolicy.scss";
import { useHistory } from "react-router-dom";

const CookiesPolicy: React.FC = () => {
  const tokenResponse = useDecode("token");
  const { setCookiesPolicyState } = useContext(CookiesPolicyContext);
  const history = useHistory();
  const {
    createEvent,
    successCreateEvent,
    loadingCreateEvent,
    errorCreateEvent,
  } = useCreateEvent();
  const handleAccept = () => {
     localStorage.setItem("cookiesPolicy", "true");
    // const data = {
    //   userId: tokenResponse.id,
    //   application: 0,
    //   operation: 4,
    //   entity: 0,
    //   entityId: "",
    //   status: 0,
    //   process: 5,
    //   details: "",
    // };
    // createEvent(data);
    setCookiesPolicyState(true);
    history.push("/inicio") 
  };
  const handleCancel = () => {
    history.push("/") 
  };
  return (
    <section className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="section-title_big">Política de Cookies</h1>
          <p>
            {" "}
            Una cookie es un fichero que se descarga en el
            ordenador/smartphone/tablet del usuario al acceder a determinadas
            páginas web para almacenar y recuperar información sobre la
            navegación que se efectúa desde dicho equipo.
          </p>
          <p>
            <b>¿Qué ocurre si desactivo o rechazo las cookies de la web?</b>
          </p>
          <p>
            No afectará a la visualización de la página pues no está rechazando
            las cookies necesarias para el correcto funcionamiento y
            visualización del sitio web.
          </p>
          <p>
            <b>
              ¿Cuáles son los diferentes tipos de Cookies que utilizamos en
              SOLIMAT?
            </b>
          </p>
          <p>
            <b>a) Cookies propias:</b> son aquellas que se envían al equipo
            terminal del usuario desde un equipo o dominio gestionado por el
            propio editor y desde el que se presta el servicio solicitado por el
            usuario.
          </p>
          <p>
            <b>b) Cookies de tercero:</b> son aquellas que se envían al equipo
            terminal del usuario desde un equipo o dominio que no es gestionado
            por el editor, sino por otra entidad que trata los datos obtenidos
            través de las cookies.
          </p>
          <p>
            <b>c) Cookies técnicas:</b> son aquellas que permiten al usuario la
            navegación a través de una página web, plataforma o aplicación y la
            utilización de las diferentes opciones o servicios que en ella
            existan, incluyendo aquellas que el editor utiliza para permitir la
            gestión y operativa de la página web y habilitar sus funciones y
            servicios. Este tipo de cookies son necesarias para el correcto
            funcionamiento de la web por lo que no requieren el consentimiento
            informado del usuario y por lo tanto no pueden ser gestionadas por
            el mismo.
          </p>
          <p>
            <b>d) Cookies de análisis o medición:</b> son aquellas que permiten
            al responsable de las mismas el seguimiento y análisis del
            comportamiento de los usuarios de los sitios web a los que están
            vinculadas, incluida la cuantificación de los impactos de los
            anuncios.
          </p>
          <p>
            <b>e) Cookies de sesión:</b> son aquellas diseñadas para recabar y
            almacenar datos mientras el usuario accede a una página web. Se
            suelen emplear para almacenar información que solo interesa
            conservar para la prestación del servicio solicitado por el usuario
            en una sola ocasión (por ejemplo, una lista de productos adquiridos)
            y desaparecen al terminar la sesión.
          </p>
          <p>
            <b>f) Cookies persistentes:</b> son aquellas en las que los datos
            siguen almacenados en el terminal y pueden ser accedidos y tratados
            durante un periodo definido por el responsable de la cookie, y que
            puede ir de unos minutos a varios años.
          </p>
          <p>
            <b>g) Cookies publicitarias:</b> son aquéllas que permiten la
            gestión, de la forma más eficaz posible, de los espacios
            publicitarios del SITIO WEB, almacenando información del
            comportamiento del Usuario obtenida a través de la observación
            continuada de sus hábitos de navegación, lo que permite desarrollar
            un perfil específico para mostrar publicidad en función del mismo.
          </p>
          <p>
            A continuación, se detallan los tipos de Cookies que se utilizan en
            SOLIMAT:
          </p>
          <p>
            <b>
              Cookies necesarias y por lo tanto no gestionables por el usuario:
            </b>
          </p>
          <div className="container-table-cookies">
            <div className="container-title-table">
              {" "}
              TIPOS DE COOKIES NECESARIAS QUE SE USAN EN ESTE SITIO WEB
            </div>
            <div className="container-items">
              <div className="items-table">Nombre</div>
              <div className="items-table">Cookies</div>
              <div className="items-table">Tipo de cookie según Finalidad</div>
              <div className="items-table">
                Tipos de cookies según el tiempo que permanecen activadas
                Información (*)
              </div>
              <div className="items-table">Output (*)</div>
            </div>
          </div>
          <p>
            <b>
              ¿Cómo se pueden gestionar las Cookies que utilizamos en SOLIMAT?
            </b>
          </p>
          <p>
            Para poder gestionar las cookies anteriormente descritas de manera
            unitaria o en su totalidad deberá pulsar sobre el botón configurar
            que aparece en el cuadro de aceptación de las mismas al cargar la
            página.
          </p>
          <p>
            Si por el contrario ya acepto las cookies y desea getionar la
            selección de las mismas deberá borrarlas de manera manual desde la
            configuración del navegador que esté utilizando y que se explica en
            el siguiente apartado.
          </p>
          <p>
            <b>¿Cómo se pueden desactivar las cookies desde el navegador?</b>
          </p>
          <p>
            Instrucciones para desactivar COOKIES desde la configuración del
            navegador:
          </p>
          <ol>
            <li>
              Chrome, desde{" "}
              <a className="link-cookies" href="http://support.google.com">
                http://support.google.com{" "}
              </a>{" "}
            </li>
            <li>
              Explorer, desde
              <a className="link-cookies" href="http://windows.microsoft.com">
                {" "}
                http://windows.microsoft.com{" "}
              </a>
            </li>
            <li>
              Firefox, desde
              <a className="link-cookies" href="http://support.mozilla.org">
                {" "}
                http://support.mozilla.org{" "}
              </a>
            </li>
            <li>
              Safari, desde{" "}
              <a className="link-cookies" href="http://support.apple.com">
                http://support.apple.com
              </a>{" "}
            </li>
          </ol>
         <p><b> ¿Se realizan transferencias internacionales de mis datos?</b></p>
          <p>SOLIMAT no realiza transferencias internacionales de datos.</p>
          <p><b>¿Se elabora un perfil de mi navegación y se toman decisiones automatizadas que puedan
          afectarme jurídica o significativamente?</b></p> 
         <p>SOLIMAT no elabora perfiles de la navegación ni hace uso de decisiones automatizadas las cuales
          puedan afectar de manera jurídica o significativa.</p> 
          <p><b>¿Se realiza un tratamiento de mis datos sensibles?</b></p>
          <p>  SOLIMAT no realiza tratamiento de
          datos en categorías especiales de datos Más información Si tiene dudas
          sobre esta política de cookies y almacenamiento local, puede contactar
          con SOLIMAT en <a className="link-cookies" href="mailto:derechos_arco@SOLIMAT.es">derechos_arco@SOLIMAT.es</a> </p>
          <div className="btn-container c-center mt-5">
            <button className="btn-clear" onClick={handleCancel}>
              Cancelar
            </button>
            <button className="btn-box btn-accent" onClick={handleAccept}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CookiesPolicy;
