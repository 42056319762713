import React, {useEffect, useState} from 'react';
import './ViewerForm.scss';
import LoaderMin from 'components/blocks/Loader/LoaderMin';
const parse = require('html-react-parser');

interface ITemplateForm {
  formTemplate: string;
  handleSaveForm: () => void;
  formId: string,
  refreshTemplate: boolean
}

const TemplateForm:React.FunctionComponent<ITemplateForm> = ({formTemplate, handleSaveForm, formId, refreshTemplate}) => {
  let fieldsetId = '';
  const [templateReact, setTemplateReact ] = useState(null);

  useEffect(() => {
    const templateReactGenerated = HtmlTemplateToReact(formTemplate)
    setTemplateReact(templateReactGenerated)
  }, [])

  const removeEventListenerFieldsets = (arrFieldset:NodeListOf<Element>) => {
    for (let i=0;i<arrFieldset.length;i++) {
      arrFieldset[i].removeEventListener('click',
      handleFocusOutFieldsets,
      false)
    }
  }

  const handleFocusOutFieldsets = (e:any) => {
    if(e.currentTarget.getAttribute('data-fieldset') == fieldsetId) {
        // console.log('SIGUES EN MISMO GRUPO', fieldsetId);
    } else {
      if(fieldsetId !== ''){
        // console.log('HAS CAMBIADO DE GRUPO: SAVE DATA AND TEMPLATE', fieldsetId)
        fieldsetId = e.currentTarget.getAttribute('data-fieldset');
        handleSaveForm()
      } else {
        fieldsetId = e.currentTarget.getAttribute('data-fieldset');
      }
        
    }
  }

  useEffect(() => {
    fieldsetId = '';

    if(templateReact !== '' && templateReact !== null) {
      // /// focusout
      const arrFieldset =  document.querySelectorAll('[data-fieldset]');
      for (let i=0;i<arrFieldset.length;i++) {
        arrFieldset[i].addEventListener('click', handleFocusOutFieldsets)
      }

      //// JS INSIDE TEMPLATE
      if(document.getElementById('templateScript')) {
        
    // // // // ///////

        // SELECT SET INITIAL STATE
        const selectsForm = document.querySelectorAll(`#template_${formId} select`);
        for (let i = 0; i< selectsForm.length; i++) {
          const select = selectsForm[i] as HTMLSelectElement;
          const selectDataValue = select.getAttribute('data-value');
          if (selectDataValue !== null) {         
            select.setAttribute('data-value', selectDataValue);
            const optionsSelect = select.getElementsByTagName('option');
            for(let j=0;j<optionsSelect.length;j++) {
              if(optionsSelect[j].value === selectDataValue) {
                optionsSelect[j].setAttribute('selected', 'true')
              }
            }
          }
        }

        // /// ADD EVENTS INPUTS SELECT
        const inputsSelect =  document.querySelectorAll("select");
        for(const input of inputsSelect) {
          input.addEventListener('change', (ev) => {
            for (const option of input.options) {
              if(option.index === input.selectedIndex) {
                option.setAttribute('selected', '');
                input.setAttribute('data-value', option.value)
              } else {
                option.removeAttribute('selected');
              }
            }
          })
        }

        // /// RADIO OR CHECKBOX ADD EVENTS
        const inputsCheckbox:any = document.querySelectorAll("input[data-checked]");
        for(const input of inputsCheckbox){
          if(input.type == 'checkbox' || input.type == 'radio') {
            input.addEventListener('change', (ev:any) => {
              if(input.getAttribute('data-checked')=='true') {
                input.removeAttribute('checked');
                input.setAttribute('data-checked', 'false');
              } else {
                input.setAttribute('checked', 'true');
                input.setAttribute('data-checked', 'true');
              }
            })
          }
        }

        // /// RADIO OR CHECKBOX SET INITIAL STATE
        for(let i=0;i<inputsCheckbox.length;i++){
          if(inputsCheckbox[i].type == 'checkbox' || inputsCheckbox[i].type == 'radio') {
            if(inputsCheckbox[i].getAttribute('data-checked')=='true') {
              inputsCheckbox[i].setAttribute('checked', 'true');
              inputsCheckbox[i].setAttribute('data-checked', 'true');
            } else {
              inputsCheckbox[i].removeAttribute('checked');
              inputsCheckbox[i].setAttribute('data-checked', 'false');
            }
          }
        }
    // // // // ///////

        const script = (document.getElementById('templateScript') as HTMLScriptElement).innerHTML;
        window.eval(script);
      }
      //// END JS INSIDE TEMPLATE
      // return removeEventListenerFieldsets(arrFieldset);

    }
  },[templateReact])

  // Generate inputs as React element
  const HtmlTemplateToReact = (formTemplate:string) => {
    if(typeof formTemplate === 'string') {
      return parse(formTemplate, {
        replace: function (domNode:any) {
          if (!domNode.attribs) return;
          if(domNode.attribs.type === 'text' || domNode.attribs.type === 'number' || domNode.attribs.type === 'date') {
            const attributes = domNode.attribs;
            return React.createElement('input', {id:attributes.id, className:attributes.class, type:attributes.type, placeholder:attributes.placeholder, name:attributes.name, defaultValue:attributes.value})
          } 
          else if (domNode.name === 'textarea') {
            const attributes = domNode.attribs;
            return React.createElement('textarea', {id:attributes.id, className:attributes.class, placeholder:attributes.placeholder, name:attributes.name, defaultValue:attributes.value, ['data-value']: attributes.value})
          }
          else if (domNode.attribs.type === 'checkbox'|| domNode.attribs.type === 'radio') {
            const attributes = domNode.attribs;
            const dataChecked = attributes['data-checked'] == 'true'?true:false
            return React.createElement('input', {id:attributes.id, className:attributes.class, type:attributes.type, name:attributes.name, defaultValue:attributes.value, ['data-checked']: dataChecked})
          } 
          else if (domNode.name === 'select') {
            let options:any = [];
            const attributes = domNode.attribs;
            for(let i = 0; i < domNode.children.length; i++) {
              if(domNode.children[i].name == 'option') {
                let optionValue = domNode.children[i].attribs.value;
                let optionText = optionValue !== ""? optionValue:"Seleccionar"
                let optionSelected = domNode.children[i].attribs.selected?true:false;
                options.push(React.createElement('option', {value: optionValue, selected:optionSelected}, optionText))
              }
            }
            return React.createElement('select', {id:attributes.id, className:attributes.class,  name:attributes.name, defaultValue:attributes['data-value'], ['data-value']:attributes['data-value'] }, options)
          }
        }
      })
    } else {
      return null
    }
  }

  return (
    <>
      {templateReact !== null && refreshTemplate?
      
        <div className="form-content" id={formId}>{templateReact}</div>
        :
        <div className="container">
          <div className="row">
            {/* <p className="loader-text">Cargando formulario...</p> */}
            <LoaderMin/>
          </div>
        </div>
      }   
    </>
  )
}

export default TemplateForm;
