import { useState } from "react";
import { stats } from "@solimat/solimat-web-endpoint";
import { ICreateEvent } from "@solimat/solimat-web-endpoint/lib/stats/dto";

const useCreateEvent = () => {
  const BASE_URL = process.env.REACT_APP_STATS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_STATS_KEY || "";
  const apiStats = new stats.StatsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateEvent, setLoadingCreateEvent] = useState(false);
  const [successCreateEvent, setSuccessCreateEvent] = useState(false);
  const [errorCreateEvent, setErrorCreateEvent] = useState(null);

  const createEvent = (data:ICreateEvent, _cb?:(data:any)=>void) => {
    setLoadingCreateEvent(true);
    setErrorCreateEvent(null);
    // apiStats
    //   .createEvent(data)
    //   .then((res: string) => {
    //     setSuccessCreateEvent(true);
    //     setLoadingCreateEvent(false);
    //     if (typeof _cb === "function") {
    //       _cb(res);
    //     }
    //   })
    //   .catch((err:any) => {
    //     console.error("createEvent error: ", err);
    //     setErrorCreateEvent(err);
    //     setLoadingCreateEvent(false);
    //   });
  };

  return {
    createEvent,
    successCreateEvent,
    loadingCreateEvent,
    errorCreateEvent
  };
};

export default useCreateEvent;