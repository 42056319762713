import React, { useEffect } from "react";
import "../ManagementSheets.scss";
import useDecode from "hooks/useDecode";
import EntornoLaboral from "../Comun/EntornoLaboral";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import Moment from "moment";
import LoaderMin from "components/blocks/Loader/LoaderMin";
function GastosJuridico({ props }: any) {
  const gastoJuridicoId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getLegalExpenses,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(() => {
    getLegalExpenses(userId, prestacionId, gastoJuridicoId);
  }, []);

  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Gasto Juridíco"}</h3>
              </div>

              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Tipo de Gasto General"}
                  </label>
                  <input
                    type="text"
                    id="tipoGastoGeneralDescripcion"
                    className="item-value"
                    value={dataEconomicSheet?.tipoGastoGeneralDescripcion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Tipo de Gasto Juridico"}
                  </label>
                  <input
                    type="text"
                    id="tipoGastoJuridicoDescripcion"
                    className="item-value"
                    value={dataEconomicSheet?.tipoGastoJuridicoDescripcion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Parte Empresa"}</label>
                  <input
                    type="text"
                    id="parteEmpresa"
                    className="item-value"
                    value={dataEconomicSheet?.parteEmpresa}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Parte Solimat"}</label>
                  <input
                    type="text"
                    id="parteSolimat"
                    className="item-value"
                    value={dataEconomicSheet?.parteSolimat}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fianza"}</label>
                  <input
                    type="text"
                    id="fianza"
                    className="item-value"
                    value={dataEconomicSheet?.fianza}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Resolución"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Pagado"}</label>
                  <input
                    type="text"
                    id="estaPagado"
                    className="item-value"
                    value={dataEconomicSheet?.estaPagado === true ? "Si" : "No"}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Pago"}</label>
                  <input
                    type="text"
                    id="FechaPresentacion"
                    className="item-value"
                    value={
                      dataEconomicSheet?.fechaPago !== null
                        ? Moment(dataEconomicSheet?.fechaPago).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Fecha Traspaso a Banco"}
                  </label>
                  <input
                    type="text"
                    id="fechaTraspasoABanco"
                    className="item-value"
                    value={
                      dataEconomicSheet?.fechaTraspasoABanco !== null
                        ? Moment(dataEconomicSheet?.fechaTraspasoABanco).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Cuantía económica neto"}
                  </label>
                  <input
                    type="text"
                    id="CuantiaEconomicaNeto"
                    className="item-value"
                    value={`${dataEconomicSheet?.neto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Tasa"}</label>
                  <input
                    type="text"
                    id="tasa"
                    className="item-value"
                    value={dataEconomicSheet?.tasa}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <EntornoLaboral
          id={'EntornoLaboral'}
          nombre={dataEconomicSheet?.entornoLaboral?dataEconomicSheet.entornoLaboral.nombre:''}
          CCC={dataEconomicSheet?.entornoLaboral?dataEconomicSheet.entornoLaboral.ccc:''}
          CIF={dataEconomicSheet?.entornoLaboral?dataEconomicSheet.entornoLaboral.cif:''}
        /> */}
        </section>
      )}
    </>
  );
}

export default GastosJuridico;
