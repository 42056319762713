import React, {useEffect,useState} from 'react';

interface IYear {
  id: string,
  label: string,
  register: any,
  errors: any,
  defaultValue: number
}

const Year: React.FC<IYear> = ({id,label, register, errors, defaultValue}) => {
  const [years,setYears] = useState<number[]|null>(null)

  useEffect(()=> {
    rangeYear();
  }, [])
  
  const rangeYear  = () => {
    const max = new Date().getFullYear();
    const min = 2003;
    const years = [];
  
    for (let i = max; i >= min; i--) {
        years.push(i)
    }
    setYears([defaultValue]);
  }
  return (
    <div className="input-block">
      <label htmlFor={id} className="input-block__label">{label}</label>
      <select 
        name={id} 
        id={id}
        {...register(id)}
        defaultValue={defaultValue}
        
      >
        {
          years?.map((item:number)=> {
            return <option key={item} value={item}>{item}</option>
          })
        }
      </select>
      {errors&&      
        <p className="error m-0">
          {errors[id]?.message}
        </p>
      }
    </div>
  )
}

export default Year;