import { setValue } from '@syncfusion/ej2-base';
import React, { useEffect, useState } from 'react';
import useGetAllCnae from "services/utils/useGetAllCnae";
import { ICnaeResponse } from "@solimat/solimat-web-endpoint/lib/utils/dto";

interface IInputProps {
  errors: any,
  register: any
  watchCnae?: any
}

const Cnae: React.FC<IInputProps> = ({ errors, register, watchCnae }) => {
  const { getAllCnae } = useGetAllCnae()
  const [allCnae, setAllCnae] = useState<any[] | null>(null);
  const [descriptionCnae, setDescriptionCnae] = useState('');
  useEffect(() => {
    handleGetAllCnae();
  }, [])

  const handleGetAllCnae = async () => {
    const res: any = await getAllCnae()
    if (res) setAllCnae(res.data)
  }

  const isCnaeCode = (cnae: any) => {
    return cnae?.Codigo === watchCnae
  }
  const handleCheckCodeDescription = () => {
    const descriptionCnae = allCnae?.find(isCnaeCode);
    if (descriptionCnae) {
      setDescriptionCnae(descriptionCnae.Descripcion);
    } else {
      setDescriptionCnae('');
    }
  }

  return (
    <div className="mb-4">
      <fieldset className="input-block input-block--code mb-0">
        <legend className="input-block--code__legend">CNAE</legend>
        <div className='row cnae-container'>
          <input
            className="code col-sm-3 col-md-3 mb-2"
            type="text"
            name="cnaeCode"
            id="cnaeCode"
            {...register('cnaeCode')}
            onChange={handleCheckCodeDescription}
            placeholder='Codigo Cnae'
          />
          <input
            className="col-sm-12 col-md-8 mb-2"
            type="text"
            name="cnaeDescription"
            id="cnaeDescription"
            {...register('cnaeDescription')}
            disabled={true}
            readOnly={true}
            value={descriptionCnae}
            placeholder='Descripción Cnae'
          />
        </div>
      </fieldset>
      {errors["cnaeDescription"]?.message &&
        <p className="error w-100 m-0">
          {errors["cnaeDescription"]?.message}
        </p>
      }
    </div>
  )
}

export default Cnae;