import React, { useState} from "react";
import { useForm } from "react-hook-form";
import useDecode from "../../../hooks/useDecode";
import useGetValidateDocumentOtp from "services/record/useGetValidateDocumentOtp";
import { guid } from "@solimat/solimat-web-endpoint/lib/record/dto";

interface IFormValidateOTP {
  onClose: () => void;
  props: any;
  handleDisableCloseButton: (state: boolean) => void;
  documentID: guid;
  userId: string;
  prestacionId: string;
}

const FormValidateOTP: React.FC<IFormValidateOTP> = ({
  onClose,
  props,
  handleDisableCloseButton,
  documentID,
  prestacionId,
  userId,
}) => {
  const { register, handleSubmit,  formState: { errors }  } = useForm();
  const [userIdentity, setUserIdentity] = useState("");
  const tokenResponse = useDecode("token");

  const {
    validateDocumentOtp,
    getValidateDocumentOtp,
    loadingGetValidateDocumentOtp,
    successGetValidateDocumentOtp,
    errorGetValidateDocumentOtp,
  } = useGetValidateDocumentOtp();

  const onSubmit = (args: any) => {
    getValidateDocumentOtp(
      userId,
      prestacionId,
      documentID,
      args.otpCode,
      handleRedirect
    );
  };

  const handleRedirect = (href: string) => {
    if (href.includes("https://")) {
      onClose();
      Object.assign(document.createElement("a"), {
        href,
        target: "_blank",
        className: "file-link",
      }).click();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-regular">
        <input
          type="otpCode"
          id="otpCode"
          className={`input ${
            validateDocumentOtp && validateDocumentOtp.uri === ""
              ? "invalid"
              : ""
          }`}
          name="otpCode"
          placeholder="Introduzca su código"
          {...register('otpCode',{
            required: "Campo requerido",
          })}
        />
        {validateDocumentOtp && validateDocumentOtp.uri === "" ? (
          <div className="error">Código incorrecto</div>
        ) : (
          validateDocumentOtp &&
          validateDocumentOtp.uri === "" && (
            <div className="error">
              Lo sentimos, se ha producido un error interno.
            </div>
          )
        )}

        {validateDocumentOtp &&
        validateDocumentOtp.uri !== null &&
        validateDocumentOtp.uri === "" ? (
          <>
            <div className="error-otp">
              <p>El código que ha introducido es incorrecto.</p>
              <p>
                Por favor intente descargar el documento de nuevo para volver a
                generar un código nuevo y asegúrese de introducirlo correctamente.
              </p>
            </div>
            <div className="btns-container">
              <button
                type="button"
                className="btn-box btn-accent align-self-end"
                onClick={() => onClose()}
              >
                Cerrar
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="btns-container">
              <button
                type="button"
                className="btn-box btn-clear"
                onClick={onClose}
              >
                Cancelar
              </button>
              {loadingGetValidateDocumentOtp ? (
                <button
                  type="button"
                  className="btn btn-outline-light"
                 
                >
                  <div className="loader-spinner" data-testid="divLoader"></div>
                </button>
              ) : (
                <button type="submit" className="btn-box btn-accent">
                  Enviar
                </button>
              )}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default FormValidateOTP;
