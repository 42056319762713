import React, { useState, useEffect } from "react";
import useUtils from "services/utils/useUtils";
import { DataManager } from "@syncfusion/ej2-data";
import { DropDownList } from "@syncfusion/ej2-dropdowns";

import Moment from "moment";
const GridAccidentRelationToPrint = ({
  data,
  validationPropertyResult,
  errors,
}) => {
  const [arrAccident, setArrAccident] = useState(null);
  const [dataObjectsParams, setDataObjectsParams] = useState(null);
  const [dataObjectsResponse, setDataObjectsResponse] = useState(null);
  const [error, setError] = useState(false);
  const {
    getAllContract,
    getAllContactForm,
    getAllInjuryPartOfBody,
    getTypeInjury,
    getTypeDocument,
  } = useUtils();

  useEffect(() => {
    handleFormatAccidents();
  }, [data, dataObjectsResponse]);

  useEffect(() => {
    handleGetAllData();
  }, [errors]);

  const handleGetAllData = async () => {
    try {
      const responseUpdate = await Promise.all([
        getAllContactForm(),
        getAllInjuryPartOfBody(),
        getTypeInjury(),
        getAllContract(),
        getTypeDocument(),
      ]);

      const arrContractType = [
        {
          code: "1",
          description_code: "Contrato indefinido",
        },
        {
          code: "2",
          description_code: "Contrato temporal",
        },
      ];
      const arrGenderType = [
        {
          code: "1",
          description_code: "Hombre",
        },
        {
          code: "2",
          description_code: "Mujer",
        },
      ];
      const responseTypeDocument = responseUpdate[4];
      const responseAllContract2 = responseUpdate[3];
      const responseAllContract = arrContractType;
      const responseAllGender = arrGenderType;
      const responseAllContactForm = responseUpdate[0];
      const responseAllInjuryPartOfBody = responseUpdate[1];
      const responseAllTypeInjury = responseUpdate[2];
      const objectResponse = {};
      if (responseTypeDocument) {
        const newData = responseTypeDocument.map((item) => {
          return { code: item.Codigo, description_code: item.Descripcion };
        });
        objectResponse.TypeDocument = {
          field: "formatted_type_document",
          data: newData,
        };
      }
      if (responseAllContract2) {
        const newData = responseAllContract2.map((item) => {
          return { code: item.Codigo, description_code: item.Descripcion };
        });
        objectResponse.Contracts = {
          field: "formatted_contracts",
          data: newData,
        };
      }
      if (responseAllContract) {
        const newData = responseAllContract.map((item) => {
          return { code: item.code, description_code: item.description_code };
        });
        objectResponse.allContracts = {
          field: "formatted_contract_type",
          data: newData,
        };
      }
      if (responseAllGender) {
        const newData = responseAllGender.map((item) => {
          return { code: item.code, description_code: item.description_code };
        });
        objectResponse.allGender = {
          field: "formatted_gender_type",
          data: newData,
        };
      }
      if (responseAllContactForm) {
        const newData = responseAllContactForm.map((item) => {
          return { code: item?.Codigo, description_code: item?.Descripcion };
        });
        objectResponse.allContactForm = {
          field: "formatted_contact",
          data: newData,
        };
      }
      if (responseAllInjuryPartOfBody) {
        const newData = responseAllInjuryPartOfBody.map((item) => {
          return { code: item?.Codigo, description_code: item?.Descripcion };
        });
        objectResponse.allInjuryPartOfBody = {
          field: "formatted_injury_part",
          data: newData,
        };
      }
      if (responseAllTypeInjury) {
        const newData = responseAllTypeInjury.map((item) => {
          return { code: item?.Codigo, description_code: item?.TipoLesion };
        });
        objectResponse.allTypeInjury = {
          field: "formatted_injury_type",
          data: newData,
        };
      }

      let objParams = {};
      for (const property in objectResponse) {
        objParams[property] = {};
        objParams[property].params = handleGenerateParams(
          objectResponse[property].data,
          objectResponse[property].field
        );
      }
      setDataObjectsParams(objParams);
      setDataObjectsResponse(objectResponse);
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };
  const handleGenerateParams = (dataObject, field) => {
    let stateElem;
    let stateObj;

    const obj = {
      create: () => {
        stateElem = document.createElement("input");
        return stateElem;
      },
      destroy: () => {
        stateObj.destroy();
      },
      read: () => {
        return stateObj.text;
      },
      write: (args) => {
        const itemFieldRowData = dataObject.find(
          (item) => item.description_code === args.rowData[field]
        );
        stateObj = new DropDownList({
          dataSource: new DataManager(dataObject),
          enabled: true,
          fields: { value: "code", text: "description_code" },
          floatLabelType: "Never",
          placeholder: "Seleccionar",
          value: itemFieldRowData?.code || null, //stateObj?.value || args.rowData[field],
          width: "300",
          popupWidth: "auto",
        });
        stateObj.appendTo(stateElem);
      },
    };
    return obj;
  };
  const formatNumberToString = (number, totalLength) => {
    let valueOuput;
    if (typeof number === "number") {
      valueOuput = number.toString();
      if (valueOuput.length < totalLength) {
        let diff = totalLength - valueOuput.length;
        for (let i = 0; i < diff; i++) {
          valueOuput = "0" + valueOuput;
        }
      }
      return valueOuput;
    }
    return number;
  };
  const handleFormatAccidents = () => {
    if (data !== null && dataObjectsResponse !== null) {
      const arr = data.map((item, index) => {
        const getDescriptionGender = dataObjectsResponse?.allGender?.data.find(
          (gender) => gender.code === item.SexoId.toString()
        );
        const getcontract = dataObjectsResponse?.Contracts?.data.find(
          (contract) => contract.code === item.Contrato
        );
        const getTypeDocument = dataObjectsResponse?.TypeDocument?.data.find(
          (typeDocu) => typeDocu.code === item.TipoIPFCodigo.toString()
        );
        const getDescriptionContractType =
          dataObjectsResponse?.allContracts?.data.find(
            (contract) => contract.code === item.TipoContrato?.toString()
          );
        const itemContact = formatNumberToString(item.FormaContactoCodigo, 2);
        let getDescriptionContactForm =
          dataObjectsResponse?.allContactForm?.data.find(
            (contactForm) => contactForm.code === itemContact
          );
        const itemInjuryPart = formatNumberToString(
          item.ParteCuerpoLesionadaCodigo,
          2
        );
        let getDescriptionInjuryPartOfBody =
          dataObjectsResponse?.allInjuryPartOfBody?.data.find(
            (partOfBody) => partOfBody.code === itemInjuryPart
          );
        const itemInjuryType = formatNumberToString(item.TipoLesionCodigo, 3);
        let getDescriptionTypeInjury =
          dataObjectsResponse?.allTypeInjury?.data.find(
            (typeInjury) => typeInjury.code === itemInjuryType
          );
        let rowError = false;
        if (
          validationPropertyResult !== null &&
          validationPropertyResult.length > 0
        ) {
          for (const error of validationPropertyResult) {
            if (error.Item1.includes("accident")) {
              const indexAccident = error.item1.substring(9, 10);
              if (index === parseInt(indexAccident)) {
                // Cumple = red border
                rowError = true;
              }
            }
          }
        }
        return {
          ...item,
          formatted_gender_type: getDescriptionGender?.description_code || null,
          formatted_contract_type:
            getDescriptionContractType?.description_code || null, //'1',
          formatted_contracts: getcontract?.description_code || null,
          formatted_contact:
            getDescriptionContactForm?.description_code || null, //'00',
          formatted_injury_part:
            getDescriptionInjuryPartOfBody?.description_code || null, //'00',
          formatted_injury_type:
            getDescriptionTypeInjury?.description_code || null, //'000',
          formatted_type_document: getTypeDocument?.description_code || null,
          formatted_accident_date: item.FechaAccidente,
          formatted_error: rowError,
          errorUpdate: errors && errors,
        };
      });

      setArrAccident(arr);
    }
  };
  return (
    <>
      <div className="container">

        {/* <div className="header-content">
          <div className="logo-block">
            <div className="logo-container">
              <img src={logo} className="logo-landingpage" alt="solimat" />
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12 mt-5 mb-4">
            <h2 className="title-highlighted">
              Relación de accidentados: {arrAccident?.length}
            </h2>
          </div>
        </div>
      </div>
      {arrAccident &&
        arrAccident?.map((accidentado, index) => (
          <section className="container" key={index}>
            <div className="col-xl-12 remarkable">
              <div className="row">
                <div className="col-xl-12">
                  <h3 className="remarkable-title">{`Accidentado ${
                    index + 1
                  }`}</h3>
                </div>
                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{"Apellidos y Nombre"}</label>
                    <input
                      type="text"
                      id="Apellidos-Nombre"
                      className="item-value"
                      value={accidentado.NombreApellidos || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{"Núm. Afiliación SS"}</label>
                    <input
                      type="text"
                      id="Núm-Afiliación-SS"
                      className="item-value"
                      value={accidentado.NAF || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="item">
                    <label className="item-label">{"Tipo Documento"}</label>
                    <input
                      type="text"
                      id="Tipo-Documento"
                      className="item-value"
                      value={accidentado.formatted_type_document || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="item">
                    <label className="item-label">{"DNI/NIE"}</label>
                    <input
                      type="text"
                      id="DNI/NIE2"
                      className="item-value"
                      value={accidentado.IPF || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{"Sexo"}</label>
                    <input
                      type="text"
                      id="Sexo2"
                      className="item-value"
                      value={accidentado.formatted_gender_type || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                {/* <div className="col-xl-12">
                  <div className="item">
                    <label className="item-label">{"Contrato"}</label>
                    <input
                      type="text"
                      id="Contrato2"
                      className="item-value"
                      value={accidentado.formatted_contracts || ""}
                      readOnly={true}
                    />
                  </div>
                </div> */}

                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{"Tipo Contrato"}</label>
                    <input
                      type="text"
                      id="Tipo-Contrato"
                      className="item-value"
                      value={accidentado.formatted_contract_type || "Contrato indefinido"}
                      readOnly={true}
                      defaultValue={'Contrato indefinido'}
                    />
                  </div>
                </div>

                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{"Fecha Accidente"}</label>
                    <input
                      type="text"
                      id="Fecha-Accidente"
                      className="item-value"
                      value={
                        Moment(accidentado.formatted_accident_date).format(
                          "L"
                        ) || ""
                      }
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="item">
                    <label className="item-label">{"Forma Contacto"}</label>
                    <input
                      type="text"
                      id="Forma-Contacto"
                      className="item-value"
                      value={accidentado.formatted_contact || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="item">
                    <label className="item-label">{"Parte Lesionada"}</label>
                    <input
                      type="text"
                      id="Parte-Lesionada"
                      className="item-value"
                      value={accidentado.formatted_injury_part || ""}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="item">
                    <label className="item-label">{"Tipo Lesión"}</label>
                    <input
                      type="text"
                      id="Tipo-Lesión"
                      className="item-value"
                      value={accidentado.formatted_injury_type || ""}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
};

export default GridAccidentRelationToPrint;
