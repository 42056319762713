import React, {useState, useEffect} from 'react';
import useUtils from 'services/utils/useUtils'

interface IInputProps {
  errors: any,
  register: any,
  SocialSecurityRegime:string
}

const SocialSecurityRegime:React.FC<IInputProps> = ({errors, register,SocialSecurityRegime}) => {
  const [data,setData] = useState<any[]|null>([])
  const [selectedValue, setSelectedValue] = useState(SocialSecurityRegime);
  const {
    getAllSocialSecurityRegimen
  } = useUtils();

  useEffect(() => {
    handleGetAllSocialSecurityRegimen()
  }, [])
  const handleSelectChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleGetAllSocialSecurityRegimen = async() => {
    const response = await getAllSocialSecurityRegimen();
    setData(response);
  }

  return (
    <fieldset className={`input-block input-block--code ${errors&&errors["worker_socialSecurityRegime"]?.message?'error':''}`}>
      <legend className="input-block--code__legend">Régimen Seguridad Social</legend>
      <select 
        name="worker_socialSecurityRegime" 
        id="worker_socialSecurityRegime"  {...register('worker_socialSecurityRegime')}
        onChange={(e)=>handleSelectChange(e)}
        value={selectedValue}
      >
        {!data?.length?null:data?.map((item:any)=> {
          return(
            <option  key={item.Codigo} value={item.Codigo}>{item.Descripcion}</option>
          )
        })}
      </select>
      {errors&&      
        <p className="error m-0">
        {errors["worker_socialSecurityRegime"]?.message}
        </p>
      }
    </fieldset>
  )
}

export default SocialSecurityRegime;