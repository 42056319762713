import React, { useState, useEffect } from "react";
import { guid } from "@solimat/solimat-web-endpoint/lib/appointment/dto";
import PageMenu from "../../../components/blocks/PageMenu/PageMenu";
import TableAppointment from "../../blocks/TableAppointment/TableAppointment";
import "./appointmentDetail.scss";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import IconArrowWhite from "../../../img/icons/svg/botones/flecha-big-blanco.svg";
import useModal from "../../../hooks/useModal";
import useGetUserChatCommunicationByIdAppointment from "../../../services/communications/useGetUserChatCommunicationByIdAppointment";
import useSendMessageUserToManager from "../../../services/communications/useSendMessageUserToManager";
import useCreateUserCommunicationAppointment from "../../../services/communications/useCreateUserCommunicationAppointment";
import useGetExternalRequestById from "../../../services/appointments/useGetExternalRequestById";
import { IAppointmentResponse } from "@solimat/solimat-web-endpoint/lib/appointment/dto/Appointment/AppointmentResponse";
import Moment from "moment";
import MapGoogle2 from "components/blocks/MapGoogle/MapGoogle2";
import useGetDocumentsByAppointmentId from "services/appointments/useGetDocumentsByAppointmentId";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import useModals2 from "hooks/useModal2";
import useDecode from "../../../hooks/useDecode";
import { AppointmentVideoCall } from "../AppointmentVideoCall/AppointmentVideoCall";
interface IvaluesFormReplyCommunication {
  externalId: string;
  userId: string;
  personId: string;
  managerId: null | string;
  appointmentId: string;
  visitId: string;
  prestacionId: string;
  subject: string;
  message: string;
  files?: any[];
}
interface IdataComunication {
  externalId: string;
  userId: string;
  personId: string;
  managerId: null | string;
  appointmentId: string;
  visitId: string;
  prestacionId: string;
  subject: string;
  message: string;
  files?: any[];
}
interface IAppointmentFormated extends IAppointmentResponse {
  formated_status: string;
  formated_start: string;
  formated_hour: string;
  communication_id: string;
  record_type: number;
  visit_type: number;
  cont_type: number;
}

const AppointmentDetailExt: React.FC = (props: any) => {
  const appointmentId: guid = props.match.params.id;
  const [documentID, setDocumentID] = useState<string | guid>("");
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const [appointment, setAppointment] = useState<IAppointmentFormated | any>({
    citaId: "1234",
    centro: {
      centroId: "1234456",
      codigoPostal: "28034",
      descripcion: "Una clínica muy buena",
      email: "centro@centro.com",
      latitud: 40.473333,
      longitud: -3.62,
      letra: "A",
      localidad: "Madrid",
      nombre: "Clínica Central",
      nombreCalle: "Calle Falsa",
      numeroCalle: "12",
      piso: "2",
      provincia: "Madrid",
      telefono: "606606959",
      tipoCalle: "Calle",
    },
    doctor: {
      email: "felipe@doctor.com",
      nombre: "Felipe",
      telefono: "606606959",
    },
    fecha: "2045-12-05T00:00:00",
    formated_hour: "08:33",
    formated_item_type: undefined,
    formated_start: "16/01/2023",
    id: "30c5ebdd-e0fc-4fe2-8663-5e3e861d89fa",
    mensaje: "Cita en el futuro lejano",
    nombrePrueba: "Nombre de la prueba",
    personaId: "1234",
    realizada: false,
    reprogramada: false,
    tipoActividad: "Tipo",
    userId: "30c5ebdd-e0fc-4fe2-8663-5e2e860d88fa",
  });
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const {

    handleCloseModal,

  } = useModal();
  const {
    handleOpenModal2,
 
  } = useModals2();

  const {
    getUserChatCommunicationByIdAppointment,
    userChatCommunicationAppointment,
  } = useGetUserChatCommunicationByIdAppointment();

  const {
    createUserCommunicationAppointment,
    errorCreateUserCommunicationAppointment,
  } = useCreateUserCommunicationAppointment();

  const { sendMessageUserToManager } = useSendMessageUserToManager();

  const {
    getExternalRequestById,
    loadingGetExternalRequestById,
    errorGetExternalRequestById,
    dataExternalRequest,
  } = useGetExternalRequestById();
  const subjectsFromComunication =
    userChatCommunicationAppointment &&
    userChatCommunicationAppointment.map((sub: any, i: number) => {
      return sub.Subject;
    });
  const { documentsByAppointmentId, getDocumentstByAppointmentId } =
    useGetDocumentsByAppointmentId();
  const { getRequestDocumentOtp, errorGetRequestDocumentOtp } =
    useGetRequestDocumentOtp();
  const subjectForAnswer =
    subjectsFromComunication &&
    subjectsFromComunication[subjectsFromComunication.length - 1];
  useEffect(() => {
    handleGetAppointment();

    getUserChatCommunicationByIdAppointment(userId, appointmentId);
    return () => {
      handleGetAppointment();
      getUserChatCommunicationByIdAppointment(userId, appointmentId);
    };
  }, []);

  const handleGetAppointment = () => {
    getExternalRequestById(appointmentId, setAppointment);
  };

  useEffect(() => {
    // AFTER SET APPOINTMENT IF APPOINTMENT HAS COMMUNICATION
    if (
      appointment.communication_id !== null &&
      appointment.communication_id !== "" &&
      appointment.communication_id !== undefined
    ) {
      getUserChatCommunicationByIdAppointment(userId, appointmentId);
    }
    // AFTER SET APPOINTMENT IF THERE ARE BREADCRUMBS
    if (breadcrumbs.length < 1 && appointment.citaId !== "") {
      setBreadcrumbs(["Mis citas", "Cita"]);
    }
    if (appointment) {
      getDocumentstByAppointmentId(
        appointment?.userId,
        appointment?.prestacionId,
        appointment?.citaId
      );
    }
  }, [appointment]);

  // REDIRECT TO GOOGLE MAPS
  const redirect = (latitud: number, longitud: number) => {
    window.open(`https://maps.google.com/?q=${latitud},${longitud}`);
  };
  const isPending =
    parseInt(Moment(appointment.fecha).format("Y MM DD").replace(/\s+/g, "")) >=
    parseInt(Moment().format("Y MM DD").replace(/\s+/g, ""));

  const convertBase64 = async (files: any) => {
    var arrayAuxiliar: any = [];
    Array.from(files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          id: "61bd8783-0f25-48bd-87fc-3d1a16ca51bb",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };

  // ADD FILES TO COMMUNICATION
  const onSubmitCommunication = async (values: any) => {
    const dataFiles = await convertBase64(values["loadfiles[]"]);
    //send communication
    setTimeout(() => {
      const newDataJson: IdataComunication = {
        externalId: "",
        userId: appointment.userId,
        personId: "",
        managerId: null,
        appointmentId: appointment.id,
        visitId: null,
        prestacionId: null,
        subject: values.subject,
        message: values.comment,
        files: dataFiles,
      };
      createUserCommunicationAppointment(
        appointment.id,
        newDataJson,
        handleRefreshData
      );
      handleCloseModal();
    }, 700);

    // redirect
  };

  const onSubmitReply = async (values: any) => {
    const dataFiles = await convertBase64(values["loadfiles[]"]);
    setTimeout(() => {
      const newDataJson: IvaluesFormReplyCommunication = {
        externalId: "",
        userId: appointment.userId,
        personId: "",
        managerId: null,
        appointmentId: appointment.id,
        visitId: null,
        prestacionId: null,
        subject: values.subject,
        message: values.comment,
        files: dataFiles,
      };
      //   sendMessageUserToManager(newDataJson, () =>
      //   getUserChatCommunicationByIdAppointment(appointment.id)
      // );
      createUserCommunicationAppointment(appointment.id, newDataJson, () =>
        getUserChatCommunicationByIdAppointment(userId, appointment.id)
      );

      handleCloseModal();
    }, 700);
  };
  const handleRequestDocument = (documentID: string) => {
    setDocumentID(documentID);
    handleOpenModal2();
    getRequestDocumentOtp(
      appointment?.userId,
      appointment?.prestacionId,
      documentID
    );
  };
  const handleRefreshData = (communicationId: guid) => {
    setAppointment({ ...appointment, communication_id: communicationId });
  };

  const handleGoBack = () => {
    props.history.goBack();
  };
  const [messageCollpased, setMessageCollapsed] = useState(true);
  useEffect(() => {
    if (errorCreateUserCommunicationAppointment) {
      setMessageCollapsed(false);
    }
  }, [errorCreateUserCommunicationAppointment]);
  const handleCloseMessage = () => {
    setMessageCollapsed(true);
  };

  if (errorGetExternalRequestById) {
    return <section>{TextMessagesTemplates.errorResponse}</section>;
  }

  if (loadingGetExternalRequestById || !dataExternalRequest) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  return (
    <>
      <PageMenu
        title={appointment != null ? appointment.subject : ""}
        type="photo"
        breadcrumbs={breadcrumbs}
      >
        <div className="btns-header-container">
          <button onClick={handleGoBack}>
            <img src={IconArrowWhite} alt="icon arrow right" />
          </button>
        </div>
      </PageMenu>
      <section className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section-title"> Información de la petición externa</h2>
          </div>
          <div className="col-lg-12">
            <TableAppointment appointment={appointment} isExt={true}/>
          </div>
          <div className="col-md-12 map-appointment-container ">
            {appointment.linkVideoConsulta ? (
              <AppointmentVideoCall
                appointment={appointment}
              ></AppointmentVideoCall>
            ) : (
              <MapGoogle2
                latitudeCenter={appointment.centro.latitud}
                longitudeCenter={appointment.centro.longitud}
                redirect={redirect}
                zoom={18}
                title={appointment.centro.descripcion}
              />
            )}
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 ">
            <h2 className="section-title">Otros datos de interés</h2>
            {appointment !== null && (
              <ul className="episode-info__list">
                {!appointment.linkVideoConsulta ? (
                  <li>
                    <span className="header">Dirección del centro:</span>
                    <span className="data">{`${appointment.centro.nombreCalle}, ${appointment.centro.numeroCalle}`}</span>
                  </li>
                ) : (
                  ""
                )}

                <li>
                  <span className="header">Teléfono del centro:</span>
                  <span className="data">{appointment.centro.telefono}</span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </section>
      {/* aqui comienza comentarios de detalle consulta */}
      {(isPending ||
        (!isPending &&
          userChatCommunicationAppointment &&
          userChatCommunicationAppointment)) &&
        ""}
    </>
  );
};

export default AppointmentDetailExt;
