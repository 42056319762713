import React, { createContext, useState } from 'react';

const TabsFormsContext = createContext();

const TabsFormsContextProvider = ({children}) => {
    const [tabSelected, setTabSelected] = useState(null);
    const [tabFormSelected, setTabFormSelected] = useState(null);

    return (
        <TabsFormsContext.Provider value={{
          tabSelected, 
          setTabSelected, 
          tabFormSelected, 
          setTabFormSelected
        }}>
            {children}
        </TabsFormsContext.Provider>
    );
}

export {TabsFormsContext, TabsFormsContextProvider};