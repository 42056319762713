import React, { useState, useEffect } from "react";
import "../ManagementSheets.scss";

interface IEntornoLaboral {
  id: string;
  nombre: string;
  CCC: string;
  CIF: string;
  EntornoLaboralEmpresaId: string;
  EsCuentaPropia: boolean;
  NAF: string;
  data?: any;
}

const EntornoLaboral: React.FunctionComponent<IEntornoLaboral> = ({
  nombre,
  CCC,
  CIF,
  EntornoLaboralEmpresaId,
  EsCuentaPropia,
  NAF,
  data,
}) => {
  
  const entornosLaborales = data && data.entornosLaborales;

  return (
    <>
      {entornosLaborales && entornosLaborales.length > 0 ? (
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title2">{"Entornos Laborales"}</h3>
            </div>
            {entornosLaborales.map((ent: any, i: number) => {
              return (
                <div className="col-xl-12  m-2" key={i}>
                  <div className="row">
                    <div className="col-xl-12">
                      <br />
                      <h3 className="remarkable-Subtitle">
                        {ent.EsCuentaPropia
                          ? "Entorno Laboral Cuenta Propia"
                          : "Entorno Laboral Cuenta Ajena"}
                      </h3>
                    </div>

                    <div className="container container-entornos">
                      <div className="col-md-12 col-xl-6">
                        <div className="item">
                          <label className="item-label">{"Nombre"}</label>
                          <input
                            type="text"
                            id="Nombre"
                            className="item-value"
                            value={ent.Nombre}
                            readOnly={true}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-xl-3">
                        <div className="item">
                          <label className="item-label">{"CIF"}</label>
                          <input
                            type="text"
                            id="CIF"
                            className="item-value"
                            value={ent.CIF}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-xl-3">
                        <div className="item">
                          <label className="item-label">{"CCC"}</label>
                          <input
                            type="text"
                            id="CCC"
                            className="item-value"
                            value={ent.CCC}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      {ent.EsCuentaPropia && (
                        <div className="col-md-3 col-xl-3">
                          <div className="item">
                            <label className="item-label">{"NAF"}</label>
                            <input
                              type="text"
                              id="NAF"
                              className="item-value"
                              value={ent.NAF}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <br></br>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{"Entorno Laboral"}</h3>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{"Nombre"}</label>
                <input
                  type="text"
                  id="Nombre"
                  className="item-value"
                  value={nombre}
                  readOnly={true}
                  style={{ fontSize: "14px" }}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"CIF"}</label>
                <input
                  type="text"
                  id="CIF"
                  className="item-value"
                  value={CIF}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"CCC"}</label>
                <input
                  type="text"
                  id="CCC"
                  className="item-value"
                  value={CCC}
                  readOnly={true}
                />
              </div>
            </div>
            {/* <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Id Empresa"}</label>
                <input
                  type="text"
                  id="EntornoLaboralEmpresaId"
                  className="item-value"
                  value={EntornoLaboralEmpresaId}
                  readOnly={true}
                />
              </div>
            </div> */}
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Es Cuenta Propia"}</label>
                <input
                  type="text"
                  id="EsCuentaPropia"
                  className="item-value"
                  value={EsCuentaPropia ? "Si" : "No"}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"NAF"}</label>
                <input
                  type="text"
                  id="NAF"
                  className="item-value"
                  value={NAF}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EntornoLaboral;
