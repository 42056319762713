import React, { useEffect } from "react";
import "../ManagementSheets.scss";
import useDecode from "hooks/useDecode";
// import EntornoLaboral from "../Comun/EntornoLaboral";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import Moment from "moment";
import EntornoLaboral from "../Comun/EntornoLaboral";
import LoaderMin from "components/blocks/Loader/LoaderMin";

function Regularizacion({ props }: any) {
  const regularizacionId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getRegularization,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(() => {
    getRegularization(userId, prestacionId, regularizacionId);
  }, []);
  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    );
  }
  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Regularización"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Descripción"}</label>
                  <input
                    type="text"
                    id="tipoGastoGeneralDescripcion"
                    className="item-value"
                    value={dataEconomicSheet.tipoGastoGeneralDescripcion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Pagado"}</label>
                  <input
                    type="text"
                    id="Pagado"
                    className="item-value"
                    value={dataEconomicSheet.estaPagado === true ? "Si" : "No"}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Fecha Periodo Inicial"}
                  </label>
                  <input
                    type="text"
                    id="fechaPeriodoInicial"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaPeriodoInicial !== null
                        ? Moment(dataEconomicSheet.fechaPeriodoInicial).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Periodo Final"}</label>
                  <input
                    type="text"
                    id="fechaPeriodoFinal"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaPeriodoFinal !== null
                        ? Moment(dataEconomicSheet.fechaPeriodoFinal).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Fecha Traspaso al Banco"}
                  </label>
                  <input
                    type="text"
                    id="fechaTraspasoABanco"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaTraspasoABanco !== null
                        ? Moment(dataEconomicSheet.fechaTraspasoABanco).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha de Pago"}</label>
                  <input
                    type="text"
                    id="fechaPago"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaRegistro !== null
                        ? Moment(dataEconomicSheet.fechaPago).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Cantidad Abonada (Bruto)"}
                  </label>
                  <input
                    type="text"
                    id="bruto"
                    className="item-value"
                    value={`${dataEconomicSheet.bruto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Cantidad Abonada (Neto)"}
                  </label>
                  <input
                    type="text"
                    id="neto"
                    className="item-value"
                    value={`${dataEconomicSheet.neto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Retención %"}</label>
                  <input
                    type="text"
                    id="retencion"
                    className="item-value"
                    value={dataEconomicSheet.retencion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"IPREM Diario "}</label>
                  <input
                    type="text"
                    id="IPREM_Diario"
                    className="item-value"
                    value={dataEconomicSheet.IPREM_Diario}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Base Reguladora Diaria"}
                  </label>
                  <input
                    type="text"
                    id="baseReguladoraDiaria"
                    className="item-value"
                    value={dataEconomicSheet.baseReguladoraDiaria}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Cuota Obrera "}</label>
                  <input
                    type="text"
                    id="cuotaObrera"
                    className="item-value"
                    value={dataEconomicSheet.cuotaObrera}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Dias Devengados"}</label>
                  <input
                    type="text"
                    id="diasDevengados"
                    className="item-value"
                    value={dataEconomicSheet.diasDevengados}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe Cuota Obrera"}</label>
                  <input
                    type="text"
                    id="importeCuotaObrera"
                    className="item-value"
                    value={`${dataEconomicSheet.importeCuotaObrera}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe Retenciones"}</label>
                  <input
                    type="text"
                    id="importeRetenciones"
                    className="item-value"
                    value={`${dataEconomicSheet.importeRetenciones}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Porcentaje Horas Trabajadas %"}
                  </label>
                  <input
                    type="text"
                    id="porcentajeHorasTrabajadas"
                    className="item-value"
                    value={`${dataEconomicSheet.porcentajeHorasTrabajadas}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Porcentaje IT %"}</label>
                  <input
                    type="text"
                    id="porcentajeIT"
                    className="item-value"
                    value={`${dataEconomicSheet.porcentajeIT}`}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {dataEconomicSheet?.entornoLaboral ? 
      <EntornoLaboral
      id={"EntornoLaboral"}
      nombre={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.Nombre : ""}
      CCC={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CCC : ""}
      CIF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CIF : ""}
      EntornoLaboralEmpresaId={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
      EsCuentaPropia={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EsCuentaPropia : ""}
      NAF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.NAF : ""}
    />
    
   : '' }
        </section>
      )}
    </>
  );
}

export default Regularizacion;
