import { useState } from "react";
import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetTypeRoad = () => {
  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllTypeRoad, setLoadingGetAllTypeRoad] = useState(false);
  const [successGetAllTypeRoad, setSuccessGetAllTypeRoad] = useState(false);
  const [errorGetAllTypeRoad, setErrorGetAllTypeRoad] = useState(null);
  const [allTypeRoad, setAllTypeRoad] = useState<null|any>(null);

  const getAllTypeRoad = async () => {
    try {
      const res = await apiUtils.getTypeRoad()
      setAllTypeRoad(res.data)
      setSuccessGetAllTypeRoad(true);
      setLoadingGetAllTypeRoad(false);
        return res.data;
        
      }
      catch(err) {
        console.error("getAllTypeRoad error: ", err);
        setErrorGetAllTypeRoad(err);
        setLoadingGetAllTypeRoad(false);
      }
  };

  return {
    allTypeRoad,
    getAllTypeRoad,
    successGetAllTypeRoad,
    loadingGetAllTypeRoad,
    errorGetAllTypeRoad
  };
};

export default useGetTypeRoad;
