import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";


const useGetEpisodeHospitalization = () => {
const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
const [loadingGetEpisodeHospitalization, setLoadingGetEpisodeHospitalization] = useState(false);
const [successGetEpisodeHospitalization, setSuccessGetEpisodeHospitalization] = useState(false);
const [errorGetEpisodeHospitalization, setErrorGetEpisodeHospitalization] = useState(null);
const [episodeHospitalization, setEpisodeHospitalization] = useState<null|any>(null);

const getEpisodeHospitalization = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisodeHospitalization(true);
    setSuccessGetEpisodeHospitalization(false);
    apiRecord.getEpisodeHospitalization(userId,prestacionId)
      .then((res:any) => {       
         
        setLoadingGetEpisodeHospitalization(false);
        setSuccessGetEpisodeHospitalization(true);
        setEpisodeHospitalization( res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisodeHospitalization(false);
        setErrorGetEpisodeHospitalization(err)
      })

    }
      return {
        episodeHospitalization,
        getEpisodeHospitalization,
        loadingGetEpisodeHospitalization,
        successGetEpisodeHospitalization,
        errorGetEpisodeHospitalization
        };

    
  }
  export default useGetEpisodeHospitalization;