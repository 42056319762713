import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

const useCreateRequestTransaction = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateRequestTransaction, setLoadingCreateRequestTransaction] = useState(false);
  const [successCreateRequestTransaction, setSuccessCreateRequestTransaction] = useState(false);
  const [errorCreateRequestTransaction, setErrorCreateRequestTransaction] = useState(null);

  const createRequestTransaction = (id:any, _cb?:(data:any)=>void) => {
    setLoadingCreateRequestTransaction(true);
    setErrorCreateRequestTransaction(null);
    apiFormsIngestion
      .createRequestTransaction(id)
      .then((res: any) => {
        setSuccessCreateRequestTransaction(true);
        setLoadingCreateRequestTransaction(false);
        if (typeof _cb === "function") {
          _cb(res);
        }
      })
      .catch((err:any) => {
        console.error("createRequestTransaction error: ", err);
        setErrorCreateRequestTransaction(err);
        setLoadingCreateRequestTransaction(false);
      });
  };

  return {
    createRequestTransaction,
    successCreateRequestTransaction,
    loadingCreateRequestTransaction,
    errorCreateRequestTransaction
  };
};

export default useCreateRequestTransaction;