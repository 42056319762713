import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { ICreateUserCommunication } from "@solimat/solimat-web-endpoint/lib/communication/dto"


const useGetAllUserCommunicationByUserId = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [allUserCommunication, setAllUserCommunication] = useState<ICreateUserCommunication[]>([]);
  const [loadingGetAllUserCommunication, setLoadingGetAllUserCommunication] = useState(false);
  const [errorGetAllUserCommunication, setErrorGetAllUserCommunication] = useState(null);
const [isComunicacionHabilitada, setComunicacionHabilitada] = useState(false)
  const getAllUserCommunication = (userId:string) => {
    setLoadingGetAllUserCommunication(true);
    setErrorGetAllUserCommunication(null);
    apiCommunication
      .getAllUserCommunicationByUserId(userId)
      .then((data: any) => {
        setComunicacionHabilitada(data.data.comunicacionHabilitada)
        let dataAllUserCommunications = data && data.data.rows.map((item:any) => {
          let communicationType = "";
          if (item.CitaId!== null) {
            communicationType = "Comentario cita"
          } else if (item.visit_id !== null) {
            communicationType = "Comentario visita"
          } else if (item.PrestacionId !== null) {
            communicationType = "Comentario historial"
          } else {
            communicationType = "General"
          }
          return ({
            ...item,
            "communication_type": communicationType,
           
          })
        })
        setAllUserCommunication(dataAllUserCommunications);
        
        setLoadingGetAllUserCommunication(false);
      })
      .catch(err => {
        console.error("getAllUserCommunication: ", err);
        setErrorGetAllUserCommunication(err);
        setLoadingGetAllUserCommunication(false);
      });
  };

  return {
    isComunicacionHabilitada,
    getAllUserCommunication,
    allUserCommunication,
    loadingGetAllUserCommunication,
    errorGetAllUserCommunication
  };
};

export default useGetAllUserCommunicationByUserId;