import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

// import {Redirect} from 'react-router-dom'
import { UserContext } from "../../../contexts/UserContext";

const View404 = () => {
  // importar el userData y en función del rol redirigir a una home o a otra
  const userRole = localStorage.getItem("role");
 
  let rolePermision = "";
  if (typeof userRole === "string") {
    // let roleJson = JSON.parse(userRole);
    rolePermision = userRole;
  }

  if (typeof userRole === "string") {
    return (
      <section className="container">
        <div className="row">
          <div className="col-12">
            <h1>Ruta desconocida.</h1>
          </div>
        </div>
      </section>
    );
  } else {
    // return <Redirect to="/login" />ojo login
    return <Redirect to="/inicio" />;
  }
};

export default View404;
