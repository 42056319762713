import React, {useState,useEffect} from 'react';
import TriggerUnusualFactDetail from 'components/blocks/Delta/Inputs/TriggerUnusualFactDetail';
import MaterialAgent from 'components/blocks/Delta/Inputs/MaterialAgent';
import useUtils from 'services/utils/useUtils';
interface IInputProps {
  reset: any
  errors: any
  register: any
  setValue: any
  watchTriggerUnusualFactDetailCode: any
  watchTriggerUnusualFactDetailDescription: any
  concatCode: string
}

const TriggerUnusualFact:React.FC<IInputProps> = ({reset,errors, register, setValue, watchTriggerUnusualFactDetailCode,
  watchTriggerUnusualFactDetailDescription, concatCode}) => {
    const [allData,setAllData] = useState<any>();
    const {
      getAllDesviation
    } = useUtils();

    useEffect(() => {
      handleGetData()
    }, [])
    const handleGetData = async () => {
      const response = await getAllDesviation()
      if(response) {
        setAllData(response);
      } else {
        // SHOW ERROR
      }
    }
  return (
    <>
    <TriggerUnusualFactDetail 
      arraData={allData && allData}
      id="accident_extension_desv"
      errors={errors}
      register={register}
      title="	Desviación"
    />
    <MaterialAgent 
      code2name={'code2_trigger_unusual_fact'}
      reset={reset}
      errors={errors}
      register={register}
      id={'trigger_unusual_fact'}
      title={'Agente material asociado a la Desviación'}
      concatCode={concatCode}
      setValue={setValue}
    />
  </>
  )
}

export default TriggerUnusualFact;