import React, { useEffect } from "react";
import Moment from "moment";
import EntornoLaboral from "../Comun/EntornoLaboral";
import "../ManagementSheets.scss";
import { recordSheets } from "@solimat/solimat-web-endpoint";
import useCheckRecordStatusAndTypes from "../../../../hooks/useCheckRecordStatusAndTypes";
import useDecode from "hooks/useDecode";
import useGetEconomicSheets from 'services/recordSheets/useGetEconomicSheets';
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;
interface WorkEnvironment {
  workEnvironmentCompanyId: number;
  name: string;
  ccc: string;
  cif: string;
}
interface Management {
  name: string;
  date: string;
  company: string;
}
interface IGastosAsistencial extends Management {
  id: guid | number;
  start_period_date?: Date | string;
  fechaTraspasoABanco?: Date | string;
  estaPagado: boolean;
  neto: number | string;
  fechaPago?: Date | string;
  MedicoResponsable: string;
  tipoGastoGeneralDescripcion: number;
  record_id?: guid;
  work_environment: WorkEnvironment;
  management_type: string;
}

interface ISheet {
  props: any;
}

const GastosAsistencial: React.FC<ISheet> = ({ props }: any) => {
  const sheetId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {getCareExpenses, dataEconomicSheet, loadingEconomicSheet, errorEconomicSheet}= useGetEconomicSheets()

  useEffect(() => {
    getCareExpenses(userId,prestacionId,sheetId)
    Moment().locale("es");
  }, []);


  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }

  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Detalle"}</h3>
              </div>
              <div className="col-xl-9">
                <div className="item">
                  <label className="item-label">{"Médico Responsable"}</label>
                  <input
                    type="text"
                    id="medicoResponsable"
                    className="item-value"
                    value={dataEconomicSheet.MedicoResponsable}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Pagado"}</label>
                  <input
                    type="text"
                    id="estaPagado"
                    className="item-value"
                    value={
                      dataEconomicSheet.estaPagado !== null && dataEconomicSheet.estaPagado
                        ?'Si'
                        : "No"
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Pago"}</label>
                  <input
                    type="text"
                    id="FechaPago"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaPago !== null
                        ? Moment(dataEconomicSheet.fechaPago).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-9">
                <div className="item">
                  <label className="item-label">{"Neto"}</label>
                  <input
                    type="text"
                    id="neto"
                    className="item-value"
                    value={`${dataEconomicSheet.neto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-9">
                <div className="item">
                  <label className="item-label">{"Concepto"}</label>
                  <input
                    type="text"
                    id="tipoGastoGeneralDescripcion"
                    className="item-value"
                    value= {dataEconomicSheet.tipoGastoGeneralDescripcion}
                    readOnly={true}
                  />
                </div>
              </div>
             
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Traspaso a Banco"}</label>
                  <input
                    type="text"
                    id="fechaTraspasoABanco"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaTraspasoABanco !== null
                        ? Moment(dataEconomicSheet.fechaTraspasoABanco).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataEconomicSheet.work_environment ? dataEconomicSheet.EntornosLaborales.nombre : ""}
            CCC={dataEconomicSheet.work_environment ? dataEconomicSheet.EntornosLaborales.ccc : ""}
            CIF={dataEconomicSheet.work_environment ? dataEconomicSheet.EntornosLaborales.cif : ""}
          /> */}
        </section>
      )}
    </>
  );
};

export default GastosAsistencial;
