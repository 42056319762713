import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IconCheck from "../../../img/icons/symbols/check-confirm.png";
import useGetRoyalDecreeFormById from 'hooks/useGetRoyalDecreeFormById';
import useUpdateIncompatibilityDocuments from 'hooks/useUpdateIncompatibilityDocuments';
import moment from "moment";
import useDecode from "hooks/useDecode";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import Delete from "../../../img/icons/svg/botones/delete.png";
import IMGnotice from "../../../img/icons/symbols/notice.png";
import './formRoyalDecree22021.scss';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'components/blocks/Loader/Loader';
const FormRoyalDecree22021: React.FC = (props: any) => {
    const { getRoyalDecreeFormById, loadingGetRoyalDecreeFormById, royalDecreeFormById, successGetRoyalDecreeFormById } = useGetRoyalDecreeFormById();
    const { UpdateIncompatibilityDocuments, loadingUpdateIncompatibilityDocuments, successUpdateIncompatibilityDocuments, errorUpdateIncompatibilityDocuments } = useUpdateIncompatibilityDocuments();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const params = props.match.params;
    const paramRecordId = params.id.toString().split("_");
    const prestacionId = paramRecordId.length > 0 ?  paramRecordId.length > 1 ? paramRecordId.at(1) : paramRecordId.at(0) : params.id ;
    const formId = params.idform;
    const { checkFormStatusRD, checkFormStatusDocumentsRD } = useCheckRecordStatusAndTypes();
    const history = useHistory();
    const { control, handleSubmit, setValue, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccessfully, setShowModalSuccessfully] = useState(false);
    const [currentObservations, setCurrentObservations] = useState('');
    const noData = 'Sin datos'
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: { file: File, incompatibilityId: string, incompatibilityName: string, documentName: string } }>({});

    useEffect(() => {
        getRoyalDecreeFormById(userId, prestacionId, formId);
        setSelectedFiles({})
    }, [userId, prestacionId, formId, successUpdateIncompatibilityDocuments]);

    useEffect(() => {

        if (successUpdateIncompatibilityDocuments) {
            handleShowModalsuccessfully()
        }
    }, [successGetRoyalDecreeFormById, successUpdateIncompatibilityDocuments]);

    const handleFileChange = async (e: any, documentId: string, incompatibilityId: string, incompatibilityName: string, documentName: string) => {
        const file = e.target.files[0];
        if (file) {
            // Solo permite un archivo por documentId
            setSelectedFiles(prevSelectedFiles => ({
                ...prevSelectedFiles,
                [documentId]: { file, incompatibilityId, incompatibilityName, documentName }
            }));
        }
    };

    const removeFile = (documentId: string) => {
        setSelectedFiles(prevSelectedFiles => {
            const updatedFiles = { ...prevSelectedFiles };
            delete updatedFiles[documentId];
            return updatedFiles;
        });
    };

    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    };

    const onSubmit = async () => {
        const incompatibilitiesMap = new Map();
    
        for (const documentId in selectedFiles) {
            const { file, incompatibilityId, incompatibilityName, documentName } = selectedFiles[documentId];
            const base64 = await convertFileToBase64(file);
            const extension = file.name.split('.').pop();
    
            if (!incompatibilitiesMap.has(incompatibilityId)) {
                incompatibilitiesMap.set(incompatibilityId, {
                    id: incompatibilityId,
                    documentos: []
                });
            }
    
            incompatibilitiesMap.get(incompatibilityId).documentos.push({
                id: documentId,
                nombre: documentName,
                extension: extension,
                base64: base64.split(',')[1],
            });
        }
    
        const incompatibilidades = Array.from(incompatibilitiesMap.values());
    
        const finalObject = {
            id: royalDecreeFormById?.Id,
            incompatibilidades: incompatibilidades
        };
    
        await UpdateIncompatibilityDocuments(userId, prestacionId, formId, finalObject);
    
      
    };

    const hasSelectedFiles = Object.keys(selectedFiles).length > 0;

    const handleShowModal = (observations: string) => {
        setCurrentObservations(observations);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentObservations('');
    };
    const handleShowModalsuccessfully = () => {

        setShowModalSuccessfully(true);
    };

    const handleCloseModalsuccessfully = () => {
        setShowModalSuccessfully(false);
        setCurrentObservations('');
    };

    const handlePrevetAttach = (estate: number) => {
        switch (estate) {
            case 0:
                return false; // "Pendiente Adjuntar Documentos"
            case 1:
                return false; //"Pendiente Validar Gestor"
            case 2:
                return true; //"Aceptado"
            case 3:
                return true; //"Aceptado sin Requerir Documento"
            case 4:
                return true; //"Rechazado"
            case 5:
                return true; //"Obsoleto"
            default:
                return true; //"Estado Desconocido"
        }
    }
  
    return (
        <>
            <PageMenu title='Real Decreto 2/2021'>
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>
            { loadingGetRoyalDecreeFormById && <Loader activeLoader={true} label="Cargando Formulario"></Loader>}
            <div className='container container-incopatibilidad'>
                <div className="container purpose-header">
                    <h2>{royalDecreeFormById?.Encabezado ? royalDecreeFormById?.Encabezado:noData}</h2>
                </div>
                <div className="container container-validations-observations">
                    <h2 className='title-incompatibilidades'>Validaciones y Observaciones</h2>
                    <p>{royalDecreeFormById?.ValidacionesObserv ? royalDecreeFormById?.ValidacionesObserv : noData}</p>
                </div>
                <div className="container container-operational-description">
                    <h2 className='title-incompatibilidades'>Descripción Operativa</h2>
                    <p>{royalDecreeFormById?.DescripcionOperativa ? royalDecreeFormById?.DescripcionOperativa : noData}</p>
                </div>
                <div className="container-title-incompatibilidades">
                    <h1 className='title-incompatibilidades'>Incompatibilidades</h1>
                </div>
                {royalDecreeFormById?.Incompatibilidades.map((incopa: any, i: number) => (
                    <div className="container-incopatibilidad" key={incopa.Id}>
                        <fieldset className={`fieldset-container`}>
                         
                            <legend className="col-12">
                                <span className="legend-text">{incopa.Nombre}</span>
                            </legend>
                            <h6 className='incompatibility-status'>{`Estado:  ${checkFormStatusRD(incopa.EstadoRevision)}`}</h6>
                            {incopa?.Documentos.map((doc: any, j: number) => (
                                <div key={doc.Id}>
                                    <div className="container-documentos" >
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Documento solicitado</div>
                                            <div className="name-and-document-container">

                                                <p className='name-doc-incopatibilidad-rd'>{doc.NombrePortal ?`${doc.NombrePortal}`:noData}</p>
                                                {doc.SasUri &&
                                                    <TooltipComponent content="Archivo adjunto">
                                                        <a
                                                            href={doc.SasUri}
                                                            rel={doc.NombrePortal}
                                                            // target="_blank"
                                                            className="link-bold"
                                                        >
                                                            <span className="file-icon-blue"></span>
                                                        </a>
                                                    </TooltipComponent>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Fecha Presentación</div>
                                            <p className='presentation-date-rd'>{doc.FechaPresentacion ?moment(doc.FechaPresentacion).format('DD-MM-YYYY'): noData}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-4'>
                                            <div className="column-name">Estado Documento</div>
                                            <div className='name-and-icon-information'>
                                                <p>{checkFormStatusDocumentsRD(doc.EstadoDoc)}</p>
                                                {doc.Observaciones &&
                                                    <TooltipComponent content="Observaciones">
                                                        <span className='information-icon-orange' onClick={() => handleShowModal(doc.Observaciones)}></span>
                                                    </TooltipComponent>
                                                }

                                            </div>
                                        </div>
                                        
                                        <div className='col-sm-12 col-md-3 col-lg-2'>
                                            <Controller
                                                name={`filesRd-${i}-${j}`}
                                                control={control}
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <>
                                                        <label htmlFor={`filesRd-${i}-${j}`} className={`btn-communication btn-load-file-rd ${handlePrevetAttach(doc.EstadoDoc) ? 'btn-disabled' : ''} `}>
                                                            Adjuntar archivo
                                                            <input
                                                                type="file"
                                                                id={`filesRd-${i}-${j}`}
                                                                className="input-file input-file-rd "
                                                                onChange={(e) => {
                                                                    handleFileChange(e, doc.Id, incopa.Id, incopa.Nombre, doc.NombrePortal);
                                                                    field.onChange(e.target.files[0]);
                                                                }}
                                                                disabled={handlePrevetAttach(doc.EstadoDoc)}
                                                                accept="image/*, .pdf, .doc, .docx"
                                                            />
                                                        </label>
                                                    </>
                                                )}
                                            />
                                        </div>

                                    </div>

                                    {selectedFiles[doc.Id] && (
                                        <div className='container-file-info'>
                                            <p className='selected-file-text-rd'>
                                                {`(${selectedFiles[doc.Id].documentName})`}
                                            </p>
                                            <div className='container-name-file-rd'>
                                                <p className='name-file-rd'>
                                                    {`${selectedFiles[doc.Id].file.name}`}
                                                </p>
                                                <img
                                                    src={Delete}
                                                    alt="borrar"
                                                    className="remove-file-btn"
                                                    onClick={() => removeFile(doc.Id)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </fieldset>
                    </div>
                ))}
                <div className="container container-btn-send-documents-rd">
                    {loadingUpdateIncompatibilityDocuments ? <Button className={`btn-box btn-accent loading btn-otpep active`}><div className="loader-btn"></div>
                    </Button> :
                      <button
                      type="submit"
                      className="btn-box btn-accent"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!hasSelectedFiles}
                  >
                      Enviar Documentos
                  </button>
                    
                    }
                  
                </div>
                <div className="container container-next-actions">
                    <h2 className='title-incompatibilidades'>Próximas Acciones</h2>
                    <p>{royalDecreeFormById?.ProximasAcciones ? royalDecreeFormById?.ProximasAcciones : noData}</p>
                </div>
                <div className="container-lopd">
                    <p className='text-lopd'>
                        {royalDecreeFormById?.LOPD}
                    </p>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    <span className='information-icon-orange big'></span>
                    <Modal.Title>

                        <h2 className='title-obsevaciones-rd'>Observaciones</h2>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body-modal-observaciones-rd'>
                    <p className='text-body-modal-observaciones-rd'>{currentObservations ? currentObservations : noData}</p>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* successfully OR ERROR */}
            <Modal show={showModalSuccessfully} onHide={handleCloseModalsuccessfully} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    {errorUpdateIncompatibilityDocuments ? (
                        <>
                            <img src={IMGnotice} alt="error" />
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>¡ Error al enviar documentos !</h2>
                                <p className='text-obsevaciones-error-rd'>Por favor intentelo de nuevo</p>
                            </Modal.Title>
                        </>
                    ) : (
                        <>
                            <span className='icon-check-blue big'></span>
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>Documentos enviados con éxito</h2>
                            </Modal.Title>
                        </>
                    )}
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalsuccessfully}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default FormRoyalDecree22021;
