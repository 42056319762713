import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';

import RecordDetailManagementGrid from "../../blocks/Grids/RecordDetailManagementGrid";
import PageMenu from '../../blocks/PageMenu/PageMenu';

import useCheckTypes from '../../../hooks/useCheckRecordStatusAndTypes';
import useGetAllAdministrationRecord from '../../../services/recordSheets/useGetAllAdministrationRecord';
import useGetAllEconomicRecord from '../../../services/recordSheets/useGetAllEconomicRecord';

import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useDecode from 'hooks/useDecode';
import LoaderMin from "components/blocks/Loader/LoaderMin";
const RecordDetailManagement: React.FC = (props: any) => {
  const type = props.match.params.managementType;
  const paramsId =  props.match.params.id;
  const arrParamsId = paramsId.split('_');
  const recordType = arrParamsId[0];
  const recordId = arrParamsId[1];
  const [managementSheets, setManagementSheets] = useState<any | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
  const {
    checkRecordType
  } = useCheckTypes();

  const {
    getAllAdministrationRecord,
    loadingGetAllAdministrationRecord,
  } = useGetAllAdministrationRecord();
  const {
    getAllEconomicRecord,
    loadingGetAllEconomicRecord,

  } = useGetAllEconomicRecord();
  useEffect(() => {
    if (type==='economico') {
      getAllEconomicRecord(userId, recordId, handleSetManagementSheets);
    } else if (type==='administracion') {
      getAllAdministrationRecord( userId, recordId, handleSetManagementSheets);
    }
    let arrBreadcrumbs = [ "Historial",  checkRecordType(parseInt(recordType)), checkManagementType(type) ];
    setBreadcrumbs(arrBreadcrumbs);
  }, []);

  const handleSetManagementSheets = (arrDocuments:any[]) => {
    setManagementSheets(arrDocuments)
  }

  const checkManagementType = (type:string) => {
    switch(type) {
      case 'economico':
        return 'Económico';
      case 'administracion':
        return 'Administración';
      default:
        return 'Gestión';
    }
  }

  return (
    <>
      <PageMenu
        title={checkManagementType(type)}
        type="bottom-box-info"
        breadcrumbs={breadcrumbs}
      >
        <div className="btns-header-container">
          <Link to={`/historial/${recordType}_${recordId}`}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {(loadingGetAllAdministrationRecord || loadingGetAllEconomicRecord) && managementSheets===null ?
              // <p className="loader-text">Cargando...</p>
              <LoaderMin/>
              :
              <>
              {//(errorGetAllAdministrationRecord || errorGetAllEconomicRecord) &&
              <div className="link-rows__grid">
                {/* GESTIÓN: Administrativo | Económico */}
                {managementSheets !== null && managementSheets.length >= 0 ? (
                  <RecordDetailManagementGrid
                    arrData={managementSheets}
                    props={props}
                    typeComponent={checkManagementType(type)}
                  />
                ) : (
                  <p>No hay datos para mostrar.</p>
                )}
                </div>
                }
              </>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecordDetailManagement;
