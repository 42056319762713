import React from 'react'
import OnlineMeeting from "../../../img/icons/svg/cita/online-meeting.png";

import './AppointmentVideoCall.scss'
interface ITableAppointment {
    appointment: any
  }
export const AppointmentVideoCall: React.FC <ITableAppointment> = ({appointment}) => {
  return (
    <div className='container-video-call'>
        <img className= 'img-principal-video-call' src={OnlineMeeting} alt="video call" />
        <p className='text-appointment-video-call'>Tiene programada una video consulta a través de la aplicación Microsoft Teams.</p>
        <p className='text-appointment-video-call'>Haga click en el siguiente enlace el dia y la hora indicados para unirse a la video llamada con su médico.</p>
        <a className='link-video-call' href={appointment.linkVideoConsulta}  target="_blank" rel="noopener noreferrer">Unirse a la video Consulta</a>
    </div>
  )
}
