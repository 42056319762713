import React, {useEffect, useState} from 'react';
import Moment from 'moment';

import '../ManagementSheets.scss';

import {recordSheets} from '@solimat/solimat-web-endpoint';
import LoaderMin from 'components/blocks/Loader/LoaderMin';

const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

type guid = string;
interface Management {
  name: string,
  date: string,
  company: string
}
interface SummaryCATA extends Management {        
    id: guid | string,
    date_summary_cata?: Date | string,
    is_single_payment_request?: boolean,
    is_invitation_payment_quotas?: boolean, //
    is_regularize_quotas?: boolean, //
    amount?: number, //
    description_cause: string,
    estimate_date?: Date | string,
    claim_date?: Date | string,
    dismissal_date?: Date | string,
    desisted_date?: Date | string,
    favorable_date?: Date | string,         
    start_effect?: Date | string,
    end_effect?: Date | string,
    unforable_date?: Date | string,
    start_date_benefit?: Date | string,
    end_date_benefit?: Date | string,
    cause_autonomous_cessation_description: string,        
    autonomouns_collective_description: string,        
    cata_status_description: string,           
    cause_extinction_suspension_description: string,
    record_id: guid,
    management_type: string,
    status_cata: StatusCataEnum,
    beginning_suspension: Date,
    ending_suspension: Date
}

export enum StatusCataEnum {
  InicioSolicitud = 1,
  AcuerdoFavorable = 2,
  AcuerdoDesfavorable = 3,
  ReclamacionPrevia = 4,
  EstimacionReclamacionPrevia = 5,
  DesestimacionReclamacionPrevia = 6,
  DesistidoAutonomo = 7,
  ExpedienteSuspendido = 8,
  FavorableFinSuspension = 9
}

interface ISheet {
  props:any
}

const ResumenCATA:React.FC<ISheet> = ({props}:any) => {
  const sheetId = props.match.params.detailId.split('__')[1];
  const [data, setData] = useState<SummaryCATA|null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [statusSummaryCata, setStatusSummaryCata] = useState<string|null>(null)

  useEffect(()=> {
    getData();
    Moment().locale("es");
  },[])
  
  const getData = () => {
    setLoading(true)
    apiRecordSheets.getSummaryCata(sheetId)
      .then((res:any)=> {        
        setStatusSummaryCata(res.data.cata_status_description)
        setLoading(false);
        setSuccess(true);
        if(res.status !== 204) {
          setData(res.data);
        } else {
          setError(true);
        }
      })
      .catch((err:any) => {
        console.log(err);
        setLoading(false);
        setError(true);
      })
  }

  if(loading) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(error) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  return (
    <>
    {data===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Datos expediente'}</h3>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Estado'}</label>
              <input type="text" id="EstadosCATADescripcion" className="item-value" value={data.cata_status_description} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Colectivo autónomo'}</label>
              <input type="text" id="ColectivoAutonomosDescripcion" className="item-value" value={data.autonomouns_collective_description} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item item-checkbox">
              <input type="checkbox" id="EsSolicitudPagoUnico" value={'EsSolicitudPagoUnico'} defaultChecked={data.is_single_payment_request?true:false} readOnly={true}/>
              <label className="item-label">{'Solicitud pago único'}</label>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha inicio prestación'}</label>
              <input type="text" id="FechaInicioPrestacion" className="item-value" value={data.start_date_benefit!==null?Moment(data.start_date_benefit).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha fin prestación'}</label>
              <input type="text" id="FechaFinPrestacion" className="item-value" value={data.end_date_benefit!==null?Moment(data.end_date_benefit).format("L"):""} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      {/* 1 Inicio Solicitud */}
      {data.status_cata === StatusCataEnum.InicioSolicitud &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Inicio de solicitud'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha'}</label>
                <input type="text" id="Fecha" className="item-value" value={data.date_summary_cata!==null?Moment(data.date_summary_cata).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="item">
                <label className="item-label">{'Causa Cese'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/*  2 Acuerdo Favorable */}
      {data.status_cata === StatusCataEnum.AcuerdoFavorable || data.status_cata === StatusCataEnum.FavorableFinSuspension &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Acuerdo Favorable'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Favorable'}</label>
                <input type="text" id="FechaFavorable" className="item-value" value={data.favorable_date!==null?Moment(data.favorable_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="item">
                <label className="item-label">{'Causa Cese'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 3 Acuerdo Desfavorable */}
      {data.status_cata === StatusCataEnum.AcuerdoDesfavorable&&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Acuerdo Desfavorable'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Inicio Efecto'}</label>
                <input type="text" id="InicioEfecto" className="item-value" value={data.start_effect!==null?Moment(data.start_effect).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Fin Efecto'}</label>
                <input type="text" id="FinEfecto" className="item-value" value={data.end_effect!==null?Moment(data.end_effect).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Desfavorable'}</label>
                <input type="text" id="FechaDesfavorable" className="item-value" value={data.unforable_date!==null?Moment(data.unforable_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="item">
                <label className="item-label">{'Causa Desestimación'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 4 Reclamación previa */}
      {data.status_cata === StatusCataEnum.ReclamacionPrevia &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Reclamación Previa'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Reclamación'}</label>
                <input type="text" id="FechaReclamacion" className="item-value" value={data.claim_date!==null?Moment(data.claim_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="item">
                <label className="item-label">{'Causa Desestimación'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 5 Estimación reclamación previa */}
      {data.status_cata === StatusCataEnum.EstimacionReclamacionPrevia &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Estimación Reclamación Previa'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Reclamación'}</label>
                <input type="text" id="FechaReclamacion" className="item-value" value={data.claim_date!==null?Moment(data.claim_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Estimación'}</label>
                <input type="text" id="FechaEstimacion" className="item-value" value={data.estimate_date!==null?Moment(data.estimate_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Causa Desestimación'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/*  6 Desestimación Reclamación previa*/}
      {data.status_cata === StatusCataEnum.DesestimacionReclamacionPrevia &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Desestimación Reclamación Previa'}</h3>
            </div>          
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Reclamación'}</label>
                <input type="text" id="FechaReclamacion" className="item-value" value={data.claim_date!==null?Moment(data.claim_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Desestimación'}</label>
                <input type="text" id="FechaDesestimacion" className="item-value" value={data.dismissal_date!==null?Moment(data.dismissal_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Causa Desestimación'}</label>
                <input type="text" id="DescripcionCausa" className="item-value" value={data.description_cause} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/* Para el estado 7: “Desistido por Autónomo” no se han encontrado registro de ejemplo. Mostrar solo la sección de “Datos expediente” y si queda algún campo sin sección comunicarlo para valorar dónde se ubica. */}
      {/*  7 Desistido por el autónomo*/}
      {data.status_cata === StatusCataEnum.DesistidoAutonomo &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{'Desistido por el autónomo'}</h3>
              </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Desistido'}</label>
                <input type="text" id="FechaDesistido" className="item-value" value={data.desisted_date!==null?Moment(data.desisted_date).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Causa cese autónomos'}</label>
                <input type="text" id="CausaCeseAutonomosDescripcion" className="item-value" value={data.cause_autonomous_cessation_description} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/*  8 Expediente suspendido*/}
      {data.status_cata === StatusCataEnum.ExpedienteSuspendido &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Expediente Suspendido'}</h3>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Causa Suspension'}</label>
                <input type="text" id="CausaExtincionSuspensionDescripcion" className="item-value" value={data.cause_extinction_suspension_description} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Inicio Suspensión'}</label>
                <input type="text" id="FechaInicioSuspension" className="item-value" value={data.beginning_suspension!==null?Moment(data.beginning_suspension).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Fin Suspensión'}</label>
                <input type="text" id="FechaFinSuspension" className="item-value" value={data.ending_suspension!==null?Moment(data.ending_suspension).format("L"):""} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {/*  9 Favorable por fin de suspensión*/}
      {data.status_cata === StatusCataEnum.FavorableFinSuspension &&      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Favorable por fin de suspensión'}</h3>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Causa Suspension'}</label>
                <input type="text" id="CausaExtincionSuspensionDescripcion" className="item-value" value={data.cause_extinction_suspension_description} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Inicio Suspensión'}</label>
                <input type="text" id="FechaInicioSuspension" className="item-value" value={data.beginning_suspension!==null?Moment(data.beginning_suspension).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Fin Suspensión'}</label>
                <input type="text" id="FechaFinSuspension" className="item-value" value={data.ending_suspension!==null?Moment(data.ending_suspension).format("L"):""} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
      {data.is_invitation_payment_quotas&&
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Invitación al pago'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item item-checkbox">
                <input type="checkbox" id="EsInvitacionPagoCuotas" value={'EsInvitacionPagoCuotas'} defaultChecked={data.is_invitation_payment_quotas?true:false} readOnly={true}/>
                <label className="item-label">{'Invitacion pago cuotas'}</label>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item item-checkbox">
                <input type="checkbox" id="EsRegularizaCuotas" value={'EsRegularizaCuotas'} defaultChecked={data.is_regularize_quotas?true:false} readOnly={true}/>
                <label className="item-label">{'Regulariza cuotas'}</label>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Importe'}</label>
                <input type="text" id="Importe" className="item-value" value={data.amount} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      }
    </section>
    }
    </>
  )
}

export default ResumenCATA;