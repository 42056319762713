import React from 'react';

interface IInputProps {
  errors:any,
  register:any,
  name: string
}

const HealthcareType:React.FC<IInputProps> = ({
  errors,
  register,
  name
}) => {
  const inptName = `${name ? name : "healthcareType"}`;
  return (
    <div className={`pl-4 pr-4 pb-2 d-flex inputs--group ${errors && errors[inptName]?.message?'error':''}`}>
    <label htmlFor="hospitalisation" className="input-block--radio-check__label">
      <span className="text">Hospitalaria</span>
        <input type="radio" name={inptName} id="hospitalisation"  {...register(inptName)} value="0"/>
    </label>
    <label htmlFor="ambulatory" className="input-block--radio-check__label">
      <span className="text">Ambulatoria</span>
        <input type="radio" name={inptName} id="ambulatory" {...register(inptName)} value="1"/>
    </label>
    {errors&&      
      <p className="error m-0">
        {errors[name]?.message}
      </p>
    }
  </div>
  )
}

export default HealthcareType;