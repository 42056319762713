import React, { useState, useRef } from "react";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import Delete from "../../../img/icons/svg/botones/delete.png";

interface IFormCreateCommunication {
  title?: string;
  onSubmit: (dataForm: IDataForm) => void;
  onClose: () => void;
  loadingProcess?: boolean;
  subject?: string;
  idComunication?: string;
}

interface IDataForm {
  asunto: string;
  mensaje: string;
  files: File[];
  idComunication: string;
}

const FormCreateCommunication: React.FC<IFormCreateCommunication> = ({
  title,
  onSubmit,
  onClose,
  subject,
  loadingProcess,
  idComunication
}) => {
  const [maxSize, setMaxSize] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [dataForm, setDataForm] = useState<IDataForm>({
    asunto: title === 'Responder'? subject : '',
    mensaje: "",
    files: [],
    idComunication: idComunication || ""
  });

  const MAX_FILE_SIZE_KB = 100;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_KB * 1024 * 1024;
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: FileList | null = event.target.files;
    if (newFiles) {
      let exceedMaxSize = false;
      let tempSelectedFiles: File[] = [...selectedFiles];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        if (file.size > MAX_FILE_SIZE_BYTES) {
          exceedMaxSize = true;
          break;
        } else {
          tempSelectedFiles.push(file);
        }
      }

      if (exceedMaxSize) {
        setMaxSize(true);
        event.target.value = "";
      } else {
        setMaxSize(false);
        setSelectedFiles(tempSelectedFiles);
        setDataForm((prevData: any) => ({
          ...prevData,
          files: tempSelectedFiles,
        }));
      }
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setDataForm((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(dataForm);
  };

  const removeFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);

    setSelectedFiles(updatedFiles);
    setDataForm((prevData) => ({
      ...prevData,
      files: updatedFiles,
    }));

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  return (
    <>
      <section>{loadingProcess && TextMessagesTemplates.loading}</section>
      <form onSubmit={onSubmitHandler} className="form-modal form-regular">
        {title && <h1 className="form-title">{title}</h1>}

        <input
          type="text"
          className={`input`}
          name="asunto"
          id="asunto"
          placeholder="Asunto"
          required
          onChange={handleInputChange}
          readOnly={title === 'Responder'? true : false}
          defaultValue={title === 'Responder'? 'Respuesta'+ '  ' + subject : ''}
          style={{marginBottom:'10px'}}
        />

        <textarea
          className={`input textarea`}
          name="mensaje"
          cols={50}
          placeholder="Escribe tu comentario"
          required
          onChange={handleInputChange}
        />
        {selectedFiles.length > 0 && (
          <>
            <h5 className="title-selected-files">Archivos Seleccionados:</h5>
            <div className="selected-files">
              <ul>
                {selectedFiles.map((file, index) => (
                  <li className="li-file-selected" key={index}>
                    {file.name}
                    <img
                      src={Delete}
                      alt="borrar"
                      className="remove-file-btn"
                      onClick={() => removeFile(index)}
                    >
                    </img>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {maxSize && (
          <p className="form-error">
            Algunos archivos son demasiado grandes. El tamaño máximo es 100MB
            por archivo.
          </p>
        )}

        <div className="btns-container btns-container-create-communication">
          <label htmlFor="loadfiles" className="btn-box btn-accent btn-communication">
            Adjuntar archivos
            <input
              type="file"
              name="loadfiles"
              id="loadfiles"
              className="input-file"
              multiple
              ref={(e) => {
                fileInputRef.current = e;
              }}
              onChange={handleFileChange}
            />
          </label>
          <button
            type="submit"
            className="btn-box btn-accent"
            disabled={loadingProcess || maxSize}
          >
            Enviar
          </button>
        </div>
      </form>
    </>
  );
};

export default FormCreateCommunication;
