import React from "react";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";

import InputText from "components/blocks/Delta/Inputs/InputText";
interface IHeader {
  data: any;
  register?: any, //UseFormRegister<FieldValues>;
  errors?: any;
}
const HeaderFormDeltaWithLeaveDetail: React.FC<IHeader> = ({
  data,
  register,
  errors,
}) => {
 
  const { Estado } = data;
  const checkDeltaType = (type: number) => {
    switch (type) {
      case 1:
        return "Accidente";
      case 2:
        return "Recaída";
      default:
        return "";
    }
  };

  return (
    <div className="row form--simple-styled">
      <form>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <InputText
            id="type"
            name="type"
            label="Tipo"
            placeholder="Tipo"
            errors={errors}
            readonly={false}
            register={register}
            defaultValue={checkDeltaType(data.TipoDelta)}
            disabled
          />
        </div>
        {Estado > 3 && (
          <div className="col-xl-3 col-md-6">
            <InputText
              id="numReference"
              name="numReference"
              label="Nº de referencia Delt@"
              placeholder="Nº de referencia Delt@"
              errors={errors}
              readonly={true}
              register={register}
              defaultValue={data.NumeroReferencia}
            />
          </div>
        )}
        {Estado === 6 && (
          <div className="col-xl-3 col-md-6">
            <InputText
              id="extinguish"
              name="extinguish"
              label="Estado"
              placeholder="Extinguido"
              errors={errors}
              readonly={true}
              register={register}
              defaultValue={"Extinguido"}
            />
          </div>
        )}
      </div>
      </form>
    </div>
  );
};

export default HeaderFormDeltaWithLeaveDetail;
