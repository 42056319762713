import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetAllNonAssistanceDocuments = () => {
  const [loadingGetAllNonAssistanceDocuments, setLoadingGetAllNonAssistanceDocuments] = useState(false);
  const [successGetAllNonAssistanceDocuments, setSuccessGetAllNonAssistanceDocuments] = useState(false);
  const [errorGetAllNonAssistanceDocuments, setErrorGetAllNonAssistanceDocuments] = useState(null);
  const [allNonAssistanceDocuments, setAllNonAssistanceDocuments] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
 
  const getAllNonAssistanceDocuments = (userId:string, recordId:string, _cb?:(data:any)=>void) => {
    setLoadingGetAllNonAssistanceDocuments(true);
    setSuccessGetAllNonAssistanceDocuments(false);
    apiRecord.getAllNonAssistanceDocuments(userId,recordId)
      .then((res:any) => {       
        setAllNonAssistanceDocuments(res.data);
        setLoadingGetAllNonAssistanceDocuments(false);
        setSuccessGetAllNonAssistanceDocuments(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetAllNonAssistanceDocuments(false);
        setErrorGetAllNonAssistanceDocuments(err)
      })
  }


  return {
    allNonAssistanceDocuments,
    getAllNonAssistanceDocuments,
    loadingGetAllNonAssistanceDocuments,
    successGetAllNonAssistanceDocuments,
    errorGetAllNonAssistanceDocuments
    };
}

export default useGetAllNonAssistanceDocuments;