import React from "react";
import InputText from "../Inputs/InputText";
import InputDate from "../Inputs/InputDate";
import Ipf from "../Inputs/Ipf";
import Nationality from "../Inputs/Nationality";
import DoubleRadioCheckGroup from "../Inputs/DoubleRadioCheckGroup";
import ProffesionalSituation from "../Inputs/ProfessionalSituation";
import Seniority from "../Inputs/Seniority";
import AgreementType from "../Inputs/AgreementType";
import SocialSecurityRegime from "../Inputs/SocialSecurityRegime";
import CnaeContributionAtEp from "../Inputs/CnaeContributionAtEp";
import AddressFieldset from "../Groups/AddressGroup";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import OcupationCode2 from "../Inputs/OcupationCode2";
import CnoCotizacionATEP from "../Inputs/CnoCotizacionATEP";
import { FieldValues, UseFormRegister } from "react-hook-form";
interface ITabContent {
  data: any;
  reset: any;
  watch: any;
  errors: any;
  register: UseFormRegister<FieldValues>;
  setValue: any;
  codeCNO: string;
  disabled: boolean;
  filterByProvince? : boolean;
  valuesForm?:any
}

const Worker: React.FC<ITabContent> = ({
  data,
  watch,
  errors,
  register,
  setValue,
  codeCNO,
  filterByProvince,
  disabled,
  
}) => {
  const watchTypeDocumentCode = watch("worker_ipfType");
  const watchWorkerProvinceCode = watch("worker_province");
  const watchWorkerCnaeCode = watch("worker_atepCnae");
  const watchProvinceDescription = watch("worker_provinceDescription");
  const watchCommunityCode = watch("worker_city");
  const watchNationalityCode = watch("worker_nation");
  const watchNationalityDescription = watch("worker_nationDescription");
  const watchWorkerContractCode = watch("worker_contract");
  const watchCnoCode = watch("worker_cno_code");
  const watchCnoDescription = watch("worker_cno_description");

  return (
    <div className="container form--simple-styled" id="worker_form">
      <div className="row">
        <div className="col-12">
          <FieldsetContainer legend="Datos personales" >
            <div className="col-xl-4 col-md-6">
              <InputText
                id="worker_name"
                name="worker_name"
                label="Nombre"
                placeholder="Nombre"
                errors={errors}
                register={register}
                // {...register('worker_name') }
                disabled={disabled}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="worker_firstName"
                name="worker_firstName"
                label="Apellido 1º"
                placeholder="Apellido 1º"
                errors={errors}
                register={register}
                // {...register('worker_firstName') }
                disabled={disabled}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="worker_secondName"
                name="worker_secondName"
                label="Apellido 2º"
                placeholder="Apellido 2º"
                errors={errors}
                register={register}
                // {...register('worker_secondName') }
                disabled={disabled}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <Ipf
                legend="IPF (Identificador de Persona Pública)"
                nameCode="worker_ipfType"
                idCode="worker_ipfType"
                nameDescription="worker_ipf"
                idDescription="worker_ipf"
                placeholderDescription="IPF"
                errors={errors}
                register={register}
                watchTypeDocumentCode={watchTypeDocumentCode}
                disabled={disabled}
              // watchTypeDocumentDescription={watchTypeDocumentDescription}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="worker_naf"
                name="worker_naf"
                label="Núm. Afiliación Seguridad Social (NAF)"
                placeholder="NAF"
                errors={errors}
                register={register}
                // {...register('worker_naf') }
                disabled={disabled}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className={`input-block ${errors && errors["worker_birthDate"]?.message ? "error" : ""
                  }`}
              >

                <InputDate
                  name="worker_birthDate"
                  id="worker_birthDate"
                  register={register}
                  // {...register('worker_birthDate') }
                  label="Fecha de nacimiento"
                  placeholder="Fecha de nacimiento"
                  defaultValue={data?.FechaNacimiento}
                  errors={errors}
                  setValue={setValue}
                />
                {/* <DatePickerComponent
                  format="dd-MM-yyyy"
                  name="worker_birthDate"
                  id="worker_birthDate"  {...register('worker_birthDate')}
                  disabled={disabled}
                ></DatePickerComponent> */}
                {/* {errors && (
                  <p className="error m-0">
                    {errors["worker_birthDate"]?.message}
                  </p>
                )} */}
              </div>
            </div>
            <div className="col-xl-8 col-md-6">
              <Nationality
                legend="Nacionalidad"
                nameCode="worker_nation"
                idCode="worker_nation"
                nameDescription="worker_nationDescription"
                idDescription="worker_nationDescription"
                placeholderDescription="Nacionalidad"
                errors={errors}
                register={register}
                // {...register('worker_nation') }
                setValue={setValue}
                watchNationalityCode={watchNationalityCode}
                watchNationalityDescription={watchNationalityDescription}
                disabled={disabled}
              />
            </div>
            <div className="col-xl-4 col-md-6" >
              <DoubleRadioCheckGroup
                legend="Sexo"
                idA="worker_genderTypeM"
                valueA="1" // mascle
                valueB="2" // female
                labelA="Hombre"
                idB="worker_genderTypeF"
                labelB="Mujer"
                nameA="worker_genderType"
                type="radio"
                errors={errors}
                register={register}
                // {...register('worker_genderType') }
                disabled={disabled}
              />
            </div>
          </FieldsetContainer>
          <FieldsetContainer legend="Datos profesionales" disabled={disabled}>
            <div className="col-xl-4 col-sm-6">
              <ProffesionalSituation
                errors={errors}
                register={register}
                // {...register('worker_situation')}
                id="worker_situation"
                name="worker_situation"
              />
            </div>
            <div className="col-xl-4 col-sm-6">
              <div
                className={`input-block ${errors && errors["worker_admissionDate"]?.message
                    ? "error"
                    : ""
                  }`}
              >
                <InputDate
                  name="worker_admissionDate"
                  id="worker_admissionDate"
                  register={register}
                  // {...register('worker_admissionDate') }
                  label="Fecha Ingreso en empresa"
                  placeholder="Fecha Ingreso en empresa"
                  defaultValue={data?.FechaIngreso}
                  errors={errors}
                  setValue={setValue}
                />
              </div>
            </div>
            <div className="col-xl-4">
              <Seniority
                // worker_months
                // worker_days
                errors={errors}
                register={register}
                // {...register('') }
              />
            </div>
            <div className="col-12">
              <InputText
                id="worker_cno_text"
                name="worker_cno_text"
                label="Texto Ocupación"
                placeholder="Texto ocupación"
                errors={errors}
                register={register}
                // {...register('worker_cno_text') }
              />
            </div>
            <div className="col-12">
              {/* CÓDIGO OCUPACIÓN (3 select)
                worker_cno_code
                worker_cno_description
                ocupationCode3
              */}

              <OcupationCode2
                legend="Código Ocupación"
                nameCode="worker_cno_code"
                idCode="worker_cno_code"
                nameDescription="worker_cno_code"
                idDescription="worker_cno_description"
                placeholderDescription="Código Ocupación"
                errors={errors}
                register={register}
                // {...register('worker_cno_code') }
                setValue={setValue}
                watchCnoCode={watchCnoCode}
                watchCnoDescription={watchCnoDescription}
                codeCNO={codeCNO}
              />
            </div>
            <div className="col-12">
              {/* TIPO DE CONTRATO */}
              <AgreementType
                errors={errors}
                register={register}
                // {...register('worker_contract') }
                setValue={setValue}
                watchWorkerContractCode={watchWorkerContractCode}
                idCode="worker_contract"
                idDescription="worker_contractDescription"
              />
            </div>
            <div className=" col-sm-12 col-12">
              <SocialSecurityRegime
                errors={errors}
                register={register}
                // {...register('') }
                SocialSecurityRegime={data ? data.RegimenSSId : ""}
              />
            </div>
            <div className="col-12">
              <InputText
                id="worker_agreement_text"
                name="worker_agreement_text"
                label="Texto Convenio Aplicable"
                placeholder="Texto convenio aplicable"
                errors={errors}
                register={register}
                // {...register('worker_agreement_text') }
              />
            </div>
          </FieldsetContainer>
          <FieldsetContainer legend="Cotización AT/EP" disabled={disabled}>
            <div className="col-12">
              <CnaeContributionAtEp
                errors={errors}
                // {...register('')}
                register={register}
                setValue={setValue}
                watchCnaeCode={watchWorkerCnaeCode}
                idCode="worker_atepCnae"
                idDescription="worker_atepCnaeDescription"
              />
            </div>
            <div className="col xl-6">

              <CnoCotizacionATEP
                errors={errors}
                // {...register('')}
                register={register}
                codeCNO={codeCNO}
                name="worker_Cno_Price_ATEP"
                label="Ocupación para cotización AT/EP"
              />

            </div>
          </FieldsetContainer>
          <FieldsetContainer legend="Domicilio" disabled={disabled}>
            <AddressFieldset
              errors={errors}
              register={register}
              // {...register('') }
              setValue={setValue}
              idStreet="worker_address"
              idPhone="worker_phone"
              idProvinceCode="worker_province"
              idProvinceDescription="worker_provinceDescription"
              idCommunityCode="worker_city"
              idCommunityDescription="worker_cityDescription"
              idPostalCode="worker_cp"
              watchProvinceCode={watchWorkerProvinceCode}
              watchProvinceDescription={watchProvinceDescription}
              watchCommunityCode={watchCommunityCode}
              filterByProvince={filterByProvince}
            />
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Worker;
