const useDownloadFile = () => {

  const downloadCSV = (csvData:any, fileName:string) => {
    const FileSaver = require('file-saver');
    var file = new File([csvData], fileName, {type: "text/csv;charset=utf-8"})
    FileSaver.saveAs(file);
      // // CSV file
      // const csvFile = new Blob([csvData], {type: "text/csv"});

      // // Download link
      // const downloadLink = document.createElement("a");

      // // File name
      // downloadLink.download = fileName;

      // // Create a link to the file
      // downloadLink.href = window.URL.createObjectURL(csvFile);

      // // Hide download link
      // downloadLink.style.display = "none";

      // // Add the link to DOM
      // document.body.appendChild(downloadLink);

      // // Click download link
      // downloadLink.click();
  }

  const downloadXML = (csvData:any, fileName:string) => {
    const FileSaver = require('file-saver');
    var file = new File([csvData], fileName, {type: "text/xml;charset=utf-8"})
    FileSaver.saveAs(file);
      // // CSV file
      // const csvFile = new Blob([csvData], {type: "text/csv"});

      // // Download link
      // const downloadLink = document.createElement("a");

      // // File name
      // downloadLink.download = fileName;

      // // Create a link to the file
      // downloadLink.href = window.URL.createObjectURL(csvFile);

      // // Hide download link
      // downloadLink.style.display = "none";

      // // Add the link to DOM
      // document.body.appendChild(downloadLink);

      // // Click download link
      // downloadLink.click();
  }

  return {
    downloadCSV,
    downloadXML
  }

}

export default useDownloadFile