import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Inject,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";

interface IAppointmentsGrid {
  dataGrid: any[];
  classAppointments: string;
  props: any;
  direction: string;
}

const AppointmentsGrid: React.FC<IAppointmentsGrid> = ({
  dataGrid,
  classAppointments,
  props,
  direction,
}) => {
  const sortingOptions: Object = {
    columns: [{ field: "fecha", direction: direction }],
  };

  const rowSelected = (args: any) => {
    props.history.push(`/mis-citas/${args.data.id}`);
  };

  let pageOptions: PageSettingsModel = {
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  };

  return (
    <div className={`${classAppointments} appointments__grid`}>
      <GridComponent
        dataSource={dataGrid}
        allowPaging={dataGrid.length >=5 ? true : false}
        allowSorting={true}
        rowSelected={rowSelected}
        pageSettings={pageOptions}
        sortSettings={sortingOptions}
      >
        <ColumnsDirective>
          <ColumnDirective field="estado" headerText="Estado" width="120" />
          <ColumnDirective
            field="especialidad"
            headerText="Motivo"
            width="170"
          />
          <ColumnDirective
            field="fecha"
            headerText="Fecha"
            type="date"
            format="dd/MM/yyyy"
            width="140"
          />
          <ColumnDirective
            field="fecha"
            type="date"
            format="hh:mm"
            headerText="Hora"
            width="120"
          />
          <ColumnDirective
            field="centro.descripcion"
            headerText="Centro"
            width="140"
          />
          <ColumnDirective
            field="comment"
            headerText="Comentarios"
            width="120"
          />
        </ColumnsDirective>
        <Inject services={[Page, Sort]} />
      </GridComponent>
    </div>
  );
};

export default AppointmentsGrid;
