import React, {useEffect, useState} from 'react';
// blocks
import {TextMessagesTemplates} from '../../blocks/TextMessagesTemplates/TextMessagesTemplates';
import PageMenu from '../../blocks/PageMenu/PageMenu';
import Modal from '../../blocks/Modal/Modal';
// imags
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IMGcheck from "../../../img/07.png";

import './Application.scss'

// hooks
import useModal from '../../../hooks/useModal';
import useGetDetailTransaction from '../../../services/transaction/useGetDetailTransaction';
import useCreateRequestTransaction from '../../../services/transaction/useCreateRequestTransaction';
import BreadCrumbs from "../../blocks/BreadCrumbs/BreadCrumbs";
import useCheckTypes from 'hooks/useCheckRecordStatusAndTypes';

import { guid } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

// MOVE TO SOLIMAT-API
interface ITransactionForm {
  id:guid,
  form_id:guid,
  transaction_id:guid,
  name: string,
  description:string
}
export interface IDetailTransactionResponse {
  name: string,
  description: string,
  transaction_forms: ITransactionForm[],
  is_request: boolean
}

const Application:React.FC = function(props:any) {
  const transactionId = props.match.params.transactionId;
  const [breadcrumbs, setBreadCrumbs] = useState<string[]|any>([]);
  const {
    collapsed,
    handleOpenModal, 
    handleCloseModal
  } = useModal();
  const {    
    detailTransaction,
    getDetailTransaction,
    successGetDetailTransaction,
    loadingGetDetailTransaction,
    errorGetDetailTransaction} = useGetDetailTransaction();
  const {
    createRequestTransaction,
    successCreateRequestTransaction,
    loadingCreateRequestTransaction,
    errorCreateRequestTransaction
  } = useCreateRequestTransaction();
  const {
    checkRecordType
  } = useCheckTypes();

  useEffect(() => {
    getDetailTransaction(transactionId);
    if(props.match.url.includes("historial")) {
      const paramsId =  props.match.params.id;
      const arrParamsId = paramsId.split('__');
      const recordType = arrParamsId[0];
      const recordTypeText = checkRecordType(parseInt(recordType));
      setBreadCrumbs(["Historial", recordTypeText, "Solicitar trámite"])
    } else {
      setBreadCrumbs(["Mis gestiones", "Solicitar"])
    }
  }, [])

  const sendApplication = () => {
    createRequestTransaction({TransactionId: transactionId}, ()=>handleOpenModal())
  }

  if(errorGetDetailTransaction) {
    return (
      <>
      <div className="container">
        <BreadCrumbs breadcrumbs={breadcrumbs}/>
      </div>
      <section>
        {TextMessagesTemplates.errorResponse}
      </section>
      </>
    )
  }
  if(loadingGetDetailTransaction) {
    return (
      <section>
        {TextMessagesTemplates.loading}
      </section>
    )
  }

  return (
    <>
    {detailTransaction !== null?
      <>
        {detailTransaction.name !== '' &&
          <>
            <PageMenu
              title={detailTransaction.name}
              breadcrumbs={breadcrumbs}
            >
              <div className="btns-header-container">
                <button onClick={()=> props.history.goBack()}><img src={IconArrowGray} alt="icon arrow right"/></button>
              </div>
            </PageMenu>
            <section className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="application-content">
                    <p>{detailTransaction.description}</p>
                    <ul>
                      {detailTransaction.transaction_forms !== undefined &&
                        detailTransaction.transaction_forms.map((form:ITransactionForm, index:number) => 
                        <li key={index}>{form.name}</li>
                        )
                      }
                    </ul>
                    <div className="application-button-container">
                      <button 
                        className="btn-box btn-accent"
                        onClick={sendApplication}
                        disabled={successCreateRequestTransaction || detailTransaction.is_request?true:false}
                      >
                        Solicitar trámite
                      </button>
                      {(successCreateRequestTransaction || detailTransaction.is_request?true:false)?
                        <p className="application-applied__message">
                          <span className="icon"></span>
                          <span className="text-em_dark-blue">Trámite solicitado</span>
                        </p>
                        :
                        null
                      }
                      {(errorCreateRequestTransaction)&&
                        <p className="form-error-general">Se ha producido un error durante la solicitud. Inténtelo de nuevo más tarde.</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal collapsed={collapsed} onClose={handleCloseModal}>
              <div className="confirm-message">
                <div className="img-container">
                  <img src={IMGcheck} alt="" />
                </div>           
                  <p>Estamos validando sus datos, en breve recibirá una notificación con el resultado de la validación para iniciar el trámite.</p>
                <div className="btn-resolve-container">
                  <button className="btn-box btn-accent" onClick={handleCloseModal}>
                    Entendido
                  </button>
                </div>
              </div>
            </Modal>
          </>
        }
      </>
      :null
    }
    </>
  )
}

export default Application;