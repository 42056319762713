import React from 'react';

interface IInputProps {
  errors: any,
  register: any
}

const Seniority: React.FC<IInputProps> = ({ errors, register }) => {
  return (
    <fieldset className={`input-block input-block--fieldset ${errors && (errors["worker_months"]?.message || errors["worker_days"]?.message) ? 'error' : ''}`}>
      <legend className="input-block--code__legend">Antigüedad Puesto Trabajo</legend>
      <label htmlFor="worker_months" className="input-block__label">(en meses)</label>
      <input
        type="text"
        name="worker_months"
        id="worker_months"  {...register('worker_months')}
      />
      <label htmlFor="worker_days" className="input-block__label">(en días si es inferior a un mes)</label>
      <input
        type="text"
        name="worker_days"
        id="worker_days"  {...register('worker_days')}
      />
      {errors &&
        <p className="error m-0 ">
          {errors["worker_months"]?.message}
          {errors["worker_days"]?.message}
        </p>
      }
    </fieldset>
  )
}

export default Seniority;