import React, { createContext, useContext, useReducer } from "react";
const GeneralManagementStateContext = createContext();
const initialState = {
  prestacionActiva: [],
  prestacionGeneral: [],
  gruposPrestacionActiva: [],
  gruposPrestacionGeneral: [],
  formulariosAutorizaciones: [],
  documentosAutorizaciones: [],
  isActiveCommunication: true,
  loadinGetMyManagement: false,
  successGetMyManagement: false,
  countNotice: false

};

function reducer(state, action) {
  switch (action.type) {
    case "SET_PRESTACION_ACTIVA":
      return { ...state, prestacionActiva: action.payload };
    case "SET_PRESTACION_GENERAL":
      return { ...state, prestacionGeneral: action.payload };
    case "SET_FORMS_AUTORIZACIONES":
      return { ...state, formulariosAutorizaciones: action.payload };
    case "SET_DOCUMENTOS_AUTORIZACIONES":
      return { ...state, documentosAutorizaciones: action.payload };
    case "SET_GRUPOS_PRESTACION_GENERAL":
      return { ...state, gruposPrestacionGeneral: action.payload };
    case "SET_GRUPOS_PRESTACION_ACTIVA":
      return { ...state, gruposPrestacionActiva: action.payload };
    case "SET_LOADING":
      return { ...state, loadinGetMyManagement: action.payload };
    case "SET_SUCCESS":
      return { ...state, successGetMyManagement: action.payload };
    case "REFRESH_COUNT_NOTICE":
      return { ...state, countNotice: action.payload };
    default:
      return state;
  }
}
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GeneralManagementStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GeneralManagementStateContext.Provider>
  );
};

//  hook para acceder al estado global
export const useGeneralManagementState = () => {
  const context = useContext(GeneralManagementStateContext);
  if (!context) {
    throw new Error(
      "GeneralManagementStateContext debe usarse dentro de un GeneralManagementStateContext Provider "
    );
  }
  return context;
};
