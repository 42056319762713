import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Moment from "moment";
import "moment/locale/es";
import useDecode from "../../../hooks/useDecode";
import { useHistory } from "react-router-dom";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import Message from "../../blocks/Message/Message";
import Modal from "../../blocks/Modal/Modal";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";

import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IconCommentGray from "../../../img/icons/svg/botones/comentarios-gris.svg";

import useGetUserCommunicationById from "../../../services/communications/useGetUserCommunicationById";
import useGetUserChatCommunicationByIdRecord from "../../../services/communications/useGetUserChatCommunicationByIdRecord";
import useSendMessageUserToManager from "../../../services/communications/useSendMessageUserToManager";
import useCreateUserCommunicationRecord from "services/communications/useCreateUserCommunicationRecord";
import useCheckTypes from "hooks/useCheckRecordStatusAndTypes";
import FormCreateCommunication from "components/blocks/Forms/FormCreateCommunication";

interface IvaluesFormReplyCommunication {
  subject: string;
  message: string;
  "loadfiles[]": any[];
}
interface IdataComunication {
  externalUserId: string;
  userId: string;
  prestacionId: string;
  asunto: string;
  mensaje: string;
  files: any[];
  comunicacionReferenciaId: null | string
}
const CommunicationChat: React.FC = (props: any) => {
  const history = useHistory();
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const recordType = arrParamsId[0]
  const recordId = arrParamsId[1];
  const communicationId = arrParamsId[2];
  const communicationSubject = props.history.location.subject;
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const externalUserId = tokenResponse?.extension_external_id;
  const [collapsed, setCollapsed] = useState(true);
  const [userTokenId, setUserTokenId] = useState("");
  const {
    createUserCommunicationRecord,
    successCreateUserCommunicationRecord
  } = useCreateUserCommunicationRecord();
  const {
    getUserChatCommunicationByIdRecord,
    userChatCommunicationRecord,

  } = useGetUserChatCommunicationByIdRecord();
  const {

    checkRecordType,

  } = useCheckTypes();

  const handleOpenModal = () => {
    setCollapsed(false);
  };
  const handleCloseModal = () => {
    setCollapsed(true);
  };
  const convertBase64 = async (files: any) => {

    var arrayAuxiliar: any = [];
    Array.from(files && files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          // id: "",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };
  const recordTypeText = checkRecordType(parseInt(recordType))
  const onSubmitCommunication = async (values: any) => {
    const dataFiles = await convertBase64(values.files || []);
    //send communication
    setTimeout(() => {
      const newDataJsonRecord: IdataComunication = {
        externalUserId: externalUserId,
        userId: userId,
        prestacionId: recordId,
        asunto: values.asunto,
        mensaje: values.mensaje,
        files: dataFiles,
        comunicacionReferenciaId: dataCommunicationChat?.puedeResponder ? dataCommunicationChat?.id : null

      };

      createUserCommunicationRecord(userId, recordId, newDataJsonRecord);
      if (!successCreateUserCommunicationRecord) {
        setTimeout(() => {
          history.goBack()
          
        }, 2000);
      }
      setCollapsed(true);
    }, 700);
  };
  useEffect(() => {
    if (tokenResponse !== undefined) {
      setUserTokenId(tokenResponse.extension_id);
    }
    Moment().locale("es");
    getUserChatCommunicationByIdRecord(userId, recordId);
  }, []);
  const [loadingData, setLoadingData] = useState(true);
  useEffect(() => {
    if (userChatCommunicationRecord) {
      setLoadingData(false);
    }
  }, [userChatCommunicationRecord,successCreateUserCommunicationRecord]);

  const dataCommunicationChat =
    userChatCommunicationRecord &&
    userChatCommunicationRecord.find(
      (communication: any) => communication.id === communicationId
    );
  const subjectForAnswer =
    dataCommunicationChat && dataCommunicationChat?.asunto;
    
  return (
    <>
      {loadingData ? (
        <section>{TextMessagesTemplates.loading}</section>
      ) : (
        <>
          <PageMenu
            title={'Comentario'}
            breadcrumbs={[
              "Historial",
              recordTypeText,
              "Comunicación",
            ]}
          >
            <div className="btns-header-container">
              <a onClick={() => props.history.goBack()}>
                <img src={IconArrowGray} alt="icon arrow right" />
                {/* <span className="e-icons e-ArrowLeft"></span> */}
              </a>

   
            </div>
           
          </PageMenu>

          <div className="container">
            <div className="row">
              <div className="col">
                <section>
                  {userChatCommunicationRecord && (
                    <Message
                      userChatCommunication={dataCommunicationChat}
                      userId={userTokenId}
                    />
                  )}
                  {dataCommunicationChat && dataCommunicationChat.puedeResponder &&

                    <div className="container-btn-response">
                      <button className="btn-text btn-gray btn-response" onClick={handleOpenModal}>
                        {/* <img src={IconCommentGray} alt="icon reply" /> */}
                        <span className="e-icons e-AddComment"></span>
                        <span className="text-response"> Responder</span>
                      </button>
                    </div>

                  }
                </section>
              </div>
            </div>
          </div>

          <Modal collapsed={collapsed} onClose={handleCloseModal}>
            <>
              {!collapsed ? (
                <FormCreateCommunication
                  title={"Responder"}
                  onSubmit={onSubmitCommunication}
                  onClose={handleCloseModal}
                  subject={subjectForAnswer}
                />
              ) : (
                <div className="ghost-form"></div>
              )}
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default CommunicationChat;
