import React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import './Pagination.scss';

interface IPagination {
  itemsPerPage:number,
  totalItems:number
  paginate: (num:number) => void,
  currentPage: number,
  handleOnChangeItemsPerPage?: (dropdownValue:string) =>void,
  paginationPageSizes?:string[]
}

const Pagination: React.FC<IPagination> = ({ itemsPerPage, totalItems, paginate, currentPage, handleOnChangeItemsPerPage, paginationPageSizes }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleOnChange = (args:any) => {
    if(typeof handleOnChangeItemsPerPage === 'function' && args.value)
    handleOnChangeItemsPerPage(args.value)
    paginate(1)
  }

  return (
    <div className="pagination-block">
      <nav className="pagination-nav">
        <ul className='pagination-list'>
          <li className={`page-item ${currentPage===1?"disabled":''}`}>
            <button onClick={() => paginate(1)} className='page-btn' disabled={currentPage===1?true:false}>
              {`<<`}
            </button>
          </li>
          <li className={`page-item ${currentPage===1?"disabled":''}`}>
            <button onClick={() => paginate(currentPage -1)} className='page-btn' disabled={currentPage===1?true:false}>
              {`<`}
            </button>
          </li>
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage===number?"active":''}`}>
              <button onClick={() => paginate(number)} className='page-btn'>
                {number}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage===pageNumbers.length?"disabled":''}`}>
            <button onClick={() => paginate(currentPage +1)} className='page-btn' disabled={currentPage===pageNumbers.length?true:false}>
              {`>`}
            </button>
          </li>
          <li className={`page-item ${currentPage===pageNumbers.length?"disabled":''}`}>
            <button onClick={() => paginate(pageNumbers.length)} className='page-btn' disabled={currentPage===pageNumbers.length?true:false}>
              {`>>`}
            </button>
          </li>
        </ul>
        {handleOnChange&&paginationPageSizes&&        
          <div className="dropDownList">
            <DropDownListComponent id="ddlelement" dataSource={paginationPageSizes} change={handleOnChange} value={itemsPerPage.toString()} />
            <p className="pagination-text">resultados {itemsPerPage!==totalItems&&"por página"}</p>
          </div>
        }
      </nav>
      <p className="pagination-text"> {currentPage} de {pageNumbers.length} páginas ({totalItems} registros)</p>
    </div>
  );
};

export default Pagination;