import React from "react";
import MainLayout from "./MainLayout";
import RegisterLayout from "./RegisterLayout";
import { CookiesPolicyProvider } from "contexts/CookiesPolicyContext";
import IdleTimerContainer from "components/blocks/IdleTimer/IdleTimerContainer";
import useLayout from "../../hooks/useLayout";
import { createBrowserHistory } from "history";
import Authorizations from "components/pages/Authorizations/Authorizations";
import { ROLES } from "hooks/useGetRole";

export interface LayoutProps {
  children: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { userRole, rolePermision, isImpersonation } = useLayout();
  const history = createBrowserHistory();

  const locationID = history.location.pathname.split("/");
  switch (locationID[1]) {
    case "politica-de-cookies":
      return <RegisterLayout>{children}</RegisterLayout>;
    default:
      if (typeof userRole === "string") {
        if (isImpersonation()) {
          return (
            <>
            <MainLayout> {children}</MainLayout>
              <IdleTimerContainer isImpersonation={true}/>
            </>
          )
        }
        else {
          if (rolePermision === ROLES.PACIENTE || rolePermision === ROLES.AUTONOMO) {
            return (
              <CookiesPolicyProvider>
                <IdleTimerContainer />
                <MainLayout>{children}</MainLayout>
              </CookiesPolicyProvider>
            );
          }
          else {
            return (
              <CookiesPolicyProvider>
                <MainLayout>{children}</MainLayout>
              </CookiesPolicyProvider>
            );
          }
        }
      } else {
        return (
          <>
            {/* <Redirect to="/inicio" /> */}
            <CookiesPolicyProvider> <MainLayout>{children}</MainLayout>
            <IdleTimerContainer></IdleTimerContainer></CookiesPolicyProvider>
          </>
        );
      }
  }
};

export default Layout;
