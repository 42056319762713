import React, {useState} from 'react';
import CardLinkColor from '../../../blocks/CardsLink/CardLinkColor';
import Pagination from '../../../blocks/Pagination/Pagination';

interface IAppointmentsCards {
  props:any,
  ascendingSelected: boolean, // ascendingSelectedScheduled or ...Another
  orderByAscending: (typeAppointment: string) => void,
  orderByDescending: (typeAppointment: string) => void,
  typeAppointments: string // 'scheduled' or 'another'
  items: any[] // appointmentsScheduled or appointmentsAnother
  orderIconA:string
  orderIconB:string
  labelA:string
  labelB:string
}

const AppointmentsCards:React.FC<IAppointmentsCards> = ({
    props,
    ascendingSelected, 
    orderByDescending, 
    orderByAscending,
    items,
    typeAppointments,
    orderIconA, orderIconB,
    labelA, labelB
  }) => {

// State pagination
  const [currentPageItems, setCurrentPageItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [paginationPageSizes] = useState<string[]>(["4","8", "12", "16", "20", "Todos"]);
// Get current items
  const indexOfLastItem = currentPageItems * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
// Change page
  const paginateCards = (pageNumber:number) => setCurrentPageItems(pageNumber);
// ChangeItemsPerPage
  const handleOnChangeItemsPerPage = (dropdownValue:string) => {
    let numItems = itemsPerPage;
    if(dropdownValue === "Todos") {
      numItems = items.length;
    } else {
      numItems = parseInt(dropdownValue);
    }
    setItemsPerPage(numItems);
    paginateCards(1)
  }
  return (
    <>
      <div className="row">
        <div className="col-12 btns-upon-box-container">
          {ascendingSelected ?
            <button onClick={() => orderByAscending(typeAppointments)}>
              Fecha {labelA}{" "}
              <span className={`e-icons e-Filter ${orderIconA}`}></span>
            </button>
          :
            <button onClick={() => orderByDescending(typeAppointments)}>
              Fecha {labelB}{" "}
              <span className={`e-icons e-Filter ${orderIconB}`}></span>
            </button>
          }
        </div>
        <>
          {currentItems.map((appo: any, index:number) => {
            return (
              <div
                className="col-sm-6 col-md-4 col-lg-3 col-lg-4 col-xl-4 "
                key={index}
              >
                <CardLinkColor
                  props={props}
                  title={appo.especialidad}
                  descripcion={appo.centro.descripcion}
                  linkTo={`/mis-citas/${appo.id}`}
                  fecha={`${appo.formated_start}`}
                  hora=  {`${appo.formated_hour}`}
                  info={appo.centro.nombre}
                  fechaHora= {appo.fecha}
                  mensaje = {appo.mensaje}
                  realizada= {appo.realizada}
                  reprogramada={appo.reprogramada}
                  estado= {appo.estado}
                  isVideoCall= {appo.linkVideoConsulta !== undefined ?appo.linkVideoConsulta: '' }
                  statusClass={`${typeAppointments === 'scheduled'?'bright':'dark'}`}
                />
              
              </div>
            );
          })}
          {
            items.length >= 5 ?
            <div className="col-12">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={items.length}
              paginate={paginateCards}
              currentPage={currentPageItems}
              handleOnChangeItemsPerPage={handleOnChangeItemsPerPage}
              paginationPageSizes={paginationPageSizes}
            />
          </div>
         : '' }
         
        </>
      </div>

    </>
  )
}

export default AppointmentsCards;