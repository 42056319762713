import React from "react";
import './CardLinkIcon.scss';
import LoaderMin from "../Loader/LoaderMin";

interface ICardLinkIcon {
  loading?: boolean;
  iconType: string;
  title: string;
  numberSessions?: number;
  typeSessions?: string;
  healthcareDocuments?:number;
  handlerRedirect: (pathTo:string,params: {})=> void;
  params: {};
  pathTo: string
}

const CardLinkIcon: React.FC<ICardLinkIcon> = ({
  loading,
  iconType,
  title,
  numberSessions,
  typeSessions,
  healthcareDocuments,
  handlerRedirect,
  params,
  pathTo
}) => {
  return (
    <>
    {healthcareDocuments !== undefined ?
      <a className={`record-detail-card_block ${healthcareDocuments<1?'disabled':''}`} onClick = {(() => handlerRedirect(pathTo, params))}>
        <div className={`icon-record-type-container ${iconType}`}>
          <div className={`icon-record-type`}></div>
        </div>
        <div className="info-record-container">
          <h5 className="title">{`${title}`}</h5>
          {(healthcareDocuments !== undefined && healthcareDocuments>=1)&&
            <p className="description">{`${healthcareDocuments} ${healthcareDocuments>1?"documentos":"documento"}`}</p>
          }
        </div>
      </a>
    :
      <a className={`record-detail-card_block ${numberSessions === undefined || numberSessions<1?'disabled':''}`} onClick = {(() => handlerRedirect(pathTo, params))}>
        <div className={`icon-record-type-container ${iconType}`}>
          <div className={`icon-record-type`}></div>
        </div>
        <div className="info-record-container">
          <h5 className="title">{`${title}`}</h5>
          {loading?
            <div className="loader">
             
              <LoaderMin/>
            </div>
          :
          <>
            {(numberSessions === undefined || numberSessions < 1) && typeSessions ?
              <></>
              :
              <p className="description">{`${numberSessions} Episodios`}</p>
            }
          </>
          }
        </div>
      </a>
    }
    </>
  );
};

export default CardLinkIcon;
