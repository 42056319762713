import PageMenu from "components/blocks/PageMenu/PageMenu";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useGetCenter from "../../../services/company/useGetCenter";
import "./Centers.scss";
import moment from "moment";
import MapGoogle2 from "components/blocks/MapGoogle/MapGoogle2";
import aviso from '../../../img/icons/ayuda/warning.png'
const CenterDetail = (props) => {
  const [center, setCenter] = useState({});
  const centroId = props.match.params.id;
  const { getCenter, successGetCenter, errorGetCenter, loadingGetCenter } =
    useGetCenter();
  const redirect = (latitud, longitud) => {
    window.open(`https://maps.google.com/?q=${latitud},${longitud}`);
  };
  useEffect(() => {
    handleGetCenter();
    return () => {
      handleGetCenter();
    };
  }, []);

  const handleGetCenter = async () => {
    const center = await getCenter(centroId);
    center.Horarios && center.Horarios.sort((a, b) => a.TipoHorario - b.TipoHorario);
    setCenter(center);
  };
  return (
    <div>
      <PageMenu title="Centro Solimat">
        <div className="btns-header-container">

          <img src={IconArrowGray} alt="icon arrow right" onClick={() => window.history.back()} />

        </div>
      </PageMenu>
      <div className="container col-lg-12">
        <div className="container container-aviso">
          {/* {center.ObservacionesPublicas && center.ObservacionesPublicas.length > 0 && (
            <>
              <img className='img-warning' src={aviso} alt='aviso' />
              <span className="span-warning">Aviso</span>
            </>
          )} */}

          {center.ObservacionesPublicas && center.ObservacionesPublicas.map((obs, i) => {
            const fechaPublicacion = moment(obs.FechaPublicacion);
            const fechaCaducidad = obs.FechaCaducidad ? moment(obs.FechaCaducidad) : null;
            const currentDate = moment();
            if (fechaPublicacion.isSameOrBefore(currentDate) && (!fechaCaducidad || fechaCaducidad.isSameOrAfter(currentDate))) {
              return (
                <div className="container" key={i}>
                  <img className='img-warning' src={aviso} alt='aviso' />
                  <span className="span-warning">Aviso</span>
                  <div className="container-fechas">
                    <p className="fecha-aviso">Fecha de inicio : {moment(obs.FechaPublicacion).format('DD/MM/YYYY')}</p>
                    <p className="fecha-aviso">Fecha final : {obs.FechaCaducidad ? moment(obs.FechaCaducidad).format('DD/MM/YYYY') : ''}</p>
                  </div>
                  <p className="descripcion-warning">{obs.Descripcion}</p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <div className="row centers-block">
        <ul className="container">
          <li className="row center-item" key={center.id}>
            <div className="col-lg-6 mb-4">
              <h3>{center.Descripcion}</h3>
              <address>{`${center.TipoViaDescripcion} ${center.NombreCalle} ${center.NumeroCalle}, ${center.CodigoPostal}, ${center.LocalidadDescripcion} `}</address>
              <div>
                <div className="center-info-header mb-3">
                  {/* <span className="e-icons e-MT_DateTime icon"></span> */}
                  <div className="center-info-header-icon icon--01"></div>
                  <span className="header">Telf:</span>
                  {center.Telefono}
                </div>
              </div>
              {center.Fax && (
                <div>
                  <div className="center-info-header mb-3">
                    {/* <span className="e-icons e-MT_DateTime icon"></span> */}
                    <div className="center-info-header-icon icon--02"></div>
                    <span className="header">Fax:</span>
                    {center.Fax}
                  </div>
                </div>
              )}
              {center.Horarios && (
                <>
                  {center.Horarios.map((shedule, i) => (
                    <div className="container-info-center" key={i}>
                      <div className="icon-text center-info-header">
                        {shedule.TipoHorarioDesc === "Horario Asistencial" ? (
                          <div className="center-info-header-icon icon--03"></div>
                        ) : (
                          <div className="center-info-header-icon e-icons e-MT_DateTime icon"></div>
                        )}
                        <span className="header">{`${shedule.TipoHorarioDesc}`}</span>
                      </div>
                      <div className="center-day-date">
                        <p>{`Desde el ${moment(shedule.FechaValidezInicio).format('DD-MM-YYYY')} al ${moment(shedule.FechaValidezFin).format('DD-MM-YYYY')}`}</p>
                      </div>
                      <div className="center-day-date">
                        <p>{shedule.DiasSemana}</p>
                        <p>{`de ${moment(shedule.FechaValidezInicio).format('H:mm')} a ${moment(shedule.FechaValidezFin).format('H:mm')}`}</p>
                      </div>
                      <br></br>
                    </div>
                  ))}
                </>
              )}

              {center.ServiciosOfrecidos?.length > 0 && (
                <div>
                  <div className="center-info-header">
                    {/* <span className="e-icons e-MT_DateTime icon"></span> */}
                    <div className="center-info-header-icon icon--06"></div>
                    <span className="header">Servicios:</span>
                  </div>
                  <ul>
                    {center && center.ServiciosOfrecidos.map((service, i) => {
                      return <li key={i}>{service.Nombre}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="col-lg-6 container-map-detail-center">
              {loadingGetCenter ? null : (
                <>
                  {center.Latitud && center.Longitud && (
                    <>
                      <MapGoogle2
                        latitudeCenter={center.Latitud}
                        longitudeCenter={center.Longitud}
                        redirect={redirect}
                        title="Centro Solimat"
                        zoom={18}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CenterDetail;
