import { useState } from "react";
import SurveyIngestion from "@solimat/solimat-web-endpoint/lib/survey-ingestion";

const useGetAllAvailableUserSurveys = () => {
  const BASE_URL = process.env.REACT_APP_SURVEY_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_SURVEY_KEY || "";
  const apiSurvey = new SurveyIngestion.SurveyIngestionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllAvailableUserSurveys, setLoadingGetAllAvailableUserSurveys] = useState(false);
  const [successGetAllAvailableUserSurveys, setSuccessGetAllAvailableUserSurveys] = useState(false);
  const [errorGetAllAvailableUserSurveys, setErrorGetAllAvailableUserSurveys] = useState(null);
  const [allAvailableUserSurveys, setAllAvailableUserSurveys] = useState([]);

  const getAllAvailableUserSurveys = (_cb?:(data:any[])=>void) => {
    setLoadingGetAllAvailableUserSurveys(true);
    setErrorGetAllAvailableUserSurveys(null);
    apiSurvey
      .getAllAvailableUserSurveys()
      .then((res: any) => {
        setSuccessGetAllAvailableUserSurveys(true);
        setLoadingGetAllAvailableUserSurveys(false);
        setAllAvailableUserSurveys(res.data)
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any) => {
        console.error("getAllAvailableUserSurveys error: ", err);
        setErrorGetAllAvailableUserSurveys(err);
        setLoadingGetAllAvailableUserSurveys(false);
      });
  };

  return {
    allAvailableUserSurveys,
    getAllAvailableUserSurveys,
    successGetAllAvailableUserSurveys,
    loadingGetAllAvailableUserSurveys,
    errorGetAllAvailableUserSurveys
  };
};

export default useGetAllAvailableUserSurveys;