import { useState } from "react";
import SurveyIngestion from "@solimat/solimat-web-endpoint/lib/survey-ingestion";

const useGetSurveyJsonBySurveyBySurvey = () => {
  const BASE_URL = process.env.REACT_APP_SURVEY_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_SURVEY_KEY || "";
  const apiSurvey = new SurveyIngestion.SurveyIngestionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetSurveyJsonBySurvey, setLoadingGetSurveyJsonBySurvey] = useState(false);
  const [successGetSurveyJsonBySurvey, setSuccessGetSurveyJsonBySurvey] = useState(false);
  const [errorGetSurveyJsonBySurvey, setErrorGetSurveyJsonBySurvey] = useState(null);
  const [surveyJsonBySurvey, setSurveyJsonBySurvey] = useState<null|any>(null);

  const getSurveyJsonBySurvey = (surveyId:string,_cb?:(data:any)=>void) => {
    setLoadingGetSurveyJsonBySurvey(true);
    setErrorGetSurveyJsonBySurvey(null);
    apiSurvey
      .getSurveyJsonBySurvey(surveyId)
      .then((res: any) => {
        setSuccessGetSurveyJsonBySurvey(true);
        setLoadingGetSurveyJsonBySurvey(false);
        setSurveyJsonBySurvey(res.data)
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any) => {
        console.error("getSurveyJsonBySurvey error: ", err);
        setErrorGetSurveyJsonBySurvey(err);
        setLoadingGetSurveyJsonBySurvey(false);
      });
  };

  return {
    surveyJsonBySurvey,
    getSurveyJsonBySurvey,
    successGetSurveyJsonBySurvey,
    loadingGetSurveyJsonBySurvey,
    errorGetSurveyJsonBySurvey
  };
};

export default useGetSurveyJsonBySurveyBySurvey;
