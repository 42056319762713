import React, { useEffect } from "react";
import Moment from "moment";
import "../ManagementSheets.scss";
import useGetAdministrationSheets from "services/recordSheets/useGetAdministrationSheets";
import useDecode from "hooks/useDecode";
import EntornoLaboral from "../Comun/EntornoLaboral";
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;
interface Management {
  name: string,
  date: string,
  company: string
}
interface IConfirmationPart extends Management {
  id: guid       
  date_part?: Date | string,      
  number_part: string,
  revision_date?: Date | string,       
  check_pass_date_inss?: Date | string,       
  estimate_days?:number,       
  description_CIE9: string,
  diagnostic_code_CIE10: string,
  diagnostic_CIE10_description: string,
  diagnostic_code_CIE9: string,
  description_limitation: string,
  medical_treatment: string,
  diagnostic_tests: string,
  treatment_duration?:number,       
  treatment_duration_from?:number,       
  suffering_ailments: string,
  is_healing_6_month?:boolean       
  name_doctor: string,
  number_doctor: string,
  cias_doctor: string,
  type_process_description: string,
  diagnostic: string,
  record_id: guid,
  management_type: string
}

interface ISheet {
  props:any
}

const ParteConfirmacion:React.FC<ISheet> = ({props}:any) => {
  const parteId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    dataAdministrationSheet,
    loadingAdministrationSheet,
    errorAdministrationSheet,
    getConfirmationPart
  } = useGetAdministrationSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(()=> {
    getConfirmationPart(userId, prestacionId, parteId)
    Moment().locale("es");
  },[])
  
 

  if(loadingAdministrationSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorAdministrationSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  
  return (
    <>
    {dataAdministrationSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Detalles'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha parte'}</label>
              <input type="text" id="FechaParte" className="item-value" value={dataAdministrationSheet.fechaParte!==null?Moment(dataAdministrationSheet.fechaParte).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Número parte'}</label>
              <input type="text" id="NumeroParte" className="item-value" value={dataAdministrationSheet.numeroParte} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha revisión'}</label>
              <input type="text" id="FechaRevision" className="item-value" value={dataAdministrationSheet.fechaRevision!==null?Moment(dataAdministrationSheet.fechaRevision).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{' Fecha pase de control INSS'}</label>
              <input type="text" id="FechaPaseControlInss" className="item-value" value={dataAdministrationSheet.fechaPaseControlInss!==null?Moment(dataAdministrationSheet.fechaPaseControlInss).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Estimación días'}</label>
              <input type="text" id="EstimacionDias" className="item-value" value={dataAdministrationSheet.estimacionDias || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Duración tratamiento'}</label>
              <input type="text" id="DuracionTratamiento" className="item-value" value={dataAdministrationSheet.duracionTratamiento || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Limitación funcional'}</label>
              <input type="text" id="DescripcionLimitacion" className="item-value" value={dataAdministrationSheet.descripcionLimitacion || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Tipo de proceso'}</label>
              <input type="text" id="TipoProcesoDescripcion" className="item-value" value={dataAdministrationSheet.tipoProcesoDescripcion || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Diagnóstico CIE9'}</label>
              <input type="text" id="CodigoDiagnosticoCIE9" className="item-value w-25" value={dataAdministrationSheet.codigoDiagnosticoCIE9 || ''} readOnly={true}/>
              <input type="text" id="DescripcionCIE9" className="item-value w-75" value={dataAdministrationSheet.descripcionCIE9 || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Diagnóstico CIE10'}</label>
              <input type="text" id="CodigoDiagnosticoCIE10" className="item-value w-25" value={dataAdministrationSheet.codigoDiagnosticoCIE10 || ''} readOnly={true}/>
              <input type="text" id="DiagnosticoCIE10Descripcion" className="item-value w-75" value={dataAdministrationSheet.diagnosticoCIE10Descripcion || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Nombre facultativo'}</label>
              <input type="text" id="NombreFacultativo" className="item-value" value={dataAdministrationSheet.nombreFacultativo || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Número colegiado'}</label>
              <input type="text" id="NumeroFacultativo" className="item-value" value={dataAdministrationSheet.numeroFacultativo || ''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'CIAS facultativo'}</label>
              <input type="text" id="CIASFacultativo" className="item-value" value={dataAdministrationSheet.CIASFacultativo || ''} readOnly={true}/>
            </div>
          </div>       
        </div>
      </div>
      <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.Nombre : ""}
            CCC={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CCC : ""}
            CIF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CIF : ""}
            EntornoLaboralEmpresaId={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
            EsCuentaPropia={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EsCuentaPropia : ""}
            NAF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.NAF : ""}
            data={dataAdministrationSheet}
          />
    </section>
    
    }
    </>
  )
}

export default ParteConfirmacion;