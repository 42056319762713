import { formsBuilder } from "@solimat/solimat-web-endpoint";

const BASE_URL = process.env.REACT_APP_FORMS_BUILDER_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_BUILDER_KEY || "";

export const apiForm = new formsBuilder.Form(BASE_URL,FUNCTIONS_KEY);
export const apiGroup = new formsBuilder.Group(BASE_URL,FUNCTIONS_KEY);
export const apiGroupField = new formsBuilder.GroupField(BASE_URL,FUNCTIONS_KEY);
export const apiGroupGrid = new formsBuilder.GroupGrid(BASE_URL,FUNCTIONS_KEY);
export const apiGrid = new formsBuilder.Grid(BASE_URL,FUNCTIONS_KEY);
export const apiGridField = new formsBuilder.GridField(BASE_URL,FUNCTIONS_KEY);
export const apiField = new formsBuilder.Field(BASE_URL,FUNCTIONS_KEY);
export const apiTemplate = new formsBuilder.Template(BASE_URL,FUNCTIONS_KEY);
