import { useState } from "react";
import { notifications } from "@solimat/solimat-web-endpoint";


const useGetAllNotificationsByUser = () => {
  const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_NOTIFICACIONES_KEY || "";
  const apiNotifications = new notifications.NotificationsService(BASE_URL, FUNCTIONS_KEY);

  const [allUserNotifications, setAllUserNotifications] = useState<any[]>([]);
  const [loadingGetAllUserNotifications, setLoadingGetAllUserNotifications] = useState(false);
  const [errorGetAllUserNotifications, setErrorGetAllUserNotifications] = useState(null);

  const getAllUserNotifications = (userId: string) => {
    setLoadingGetAllUserNotifications(true);
    setErrorGetAllUserNotifications(null);
    if (userId) {
      apiNotifications
      .getAllNotificationByUser(userId)
      .then((data: any) => {
        
        setAllUserNotifications(data.data);
        setLoadingGetAllUserNotifications(false);
      })
      .catch((err: any) => {
        console.error("getAllUserNotifications: ", err);
        setErrorGetAllUserNotifications(err);
        setLoadingGetAllUserNotifications(false);
      });
    }
     
  };

  return {
    getAllUserNotifications,
    allUserNotifications,
    loadingGetAllUserNotifications,
    errorGetAllUserNotifications
  };
};

export default useGetAllNotificationsByUser;