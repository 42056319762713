// import React, { useEffect, useRef, useState } from "react";
// import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
// import "./MapGoogle.scss";

// const MapGoogle2 = ({
//   latitudeCenter,
//   longitudeCenter,
//   redirect,
//   google,
//   zoom,
//   title,
// }) => {
//   const refContainer = useRef(null);
//   const [activeMarker] = useState(null);
//   useEffect(() => {
//     refContainer.current.parentElement.setAttribute(
//       "style",
//       "height:100%; width: 100%;"
//     );
//   }, []);
//   return (
//     <div className="map-container" ref={refContainer}>
//       <Map
//         google={google}
//         initialCenter={{ lat: latitudeCenter, lng: longitudeCenter }}
//         zoom={zoom}
//       >
//         <Marker
//           id={1}
//           mapCenter={{ lat: latitudeCenter, lng: longitudeCenter }}
//           onClick={() => redirect(latitudeCenter, longitudeCenter)}
//         ></Marker>
//         <InfoWindow
//           visible={true}
//           marker={activeMarker}
//         >
//           <div className="info-window">
//             <p>{title}</p>
//           </div>
//         </InfoWindow>
//       </Map>
//     </div>
//   );
// };

// export default GoogleApiWrapper({
//   apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
// })(MapGoogle2);
import React from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function MapGoogle2({ latitudeCenter, longitudeCenter, redirect, zoom, title }) {
  const center = { lat: latitudeCenter, lng: longitudeCenter };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });
  const [map, setMap] = React.useState(null);
  const [showInfoWindow, setShowInfoWindow] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const handleMarkerClick = () => {
    setActiveMarker(null);
    setShowInfoWindow(true);
  };

  const onCloseClick = () => {
    setShowInfoWindow(false);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div style={{ width: '100%', height: '100%' }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={center}
          onClick={handleMarkerClick}
        />
        {showInfoWindow && activeMarker && (
          <InfoWindow
            position={center}
            onCloseClick={onCloseClick}
            options={{ maxWidth: 200 }}
          >
            <div className="info-window">
              <p>{title}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  ) : null;
}

export default MapGoogle2;





