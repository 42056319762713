import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import useGetProvinces from "services/company/useGetProvinces";
import useGetCentersByProvince from "services/company/useGetCentersByProvince";
// import CenterSchedule2 from "components/blocks/CenterSchedule/CenterShedule2";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import "./Centers.scss";
// import MapGoogle from "components/blocks/MapGoogle/MapGoogle";
// import MapGoogle2 from "components/blocks/MapGoogle/MapGoogle2";
import LoaderMin from "components/blocks/Loader/LoaderMin";
// import { isNullOrUndefined } from "@syncfusion/ej2-base";


const Centers: React.FC = (props: any) => {
  const [filterValue, setFilterValue] = useState("");
  const breadcrumbs = ["Red de Centros"];
  const [provinces, setProvinces] = useState([]);
  const [centers, setCenters] = useState([]);

  // REDIRECT TO GOOGLE MAPS
  // const redirect = (latitud: number, longitud: number) => {
  //   window.open(`https://maps.google.com/?q=${latitud},${longitud}`);
  // };

  const { getProvinces, loadingGetProvinces } = useGetProvinces();

  const { getCentersByProvince, loadingGetCenterByProvince } =
    useGetCentersByProvince();

  const handleChange = async (args: any) => {
    setFilterValue(args.itemData.Descripcion);

    const centers = await getCentersByProvince(`${args.value}`);
    setCenters(centers);
  };
  useEffect(() => {
    handleGetProvinces();
  }, []);

  const handleGetProvinces = async () => {
    const provinces = await getProvinces();
    setProvinces(provinces.filter((x: { Id: string, Descripcion: string, Codigo: string; }) => x.Codigo != "99"));
  };
  // console.log('provinces',provinces)
  // const provincePreselected:any = provinces.filter((province)=> province.Descripcion === 'alba') 
  // console.log('provincePreselected',provincePreselected)
  const fields = { text: "Descripcion", value: "Codigo" };
  const handleRedirectCenter = (id: string, props: any) => {
    props.history.push({
      pathname: `/red-de-centros/centro/${id}`,
    });
  };

  return (
    <>
      <PageMenu title="Red de centros" breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to="/">
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <section className="container">
        <div className="row">
          {loadingGetProvinces ? (
            // <p className="col-12 loader-text">Cargando...</p>
            <LoaderMin />
          ) : (
            <div className="col-lg-4 centers-dropdown">
              <DropDownListComponent
                id="centersElements"
                dataSource={provinces}
                popupHeight="200px"
                placeholder="Selecciona provincia"
                change={handleChange}
                fields={fields}
              // value= {provincePreselected.length ? provincePreselected[0].Codigo : '00'}
              />
            </div>
          )}
        </div>
        <div className="row centers-block">
          {filterValue !== "" ? (
            <>
              <div className="col-12">
                <h2 className="section-title uppercase">{filterValue}</h2>
              </div>
              <div className="col-12">
                {loadingGetCenterByProvince ? (
                  <LoaderMin />
                ) : (
                  <div className="card-columns">
                    {centers !== undefined && centers !== null && (
                      <>
                        {centers?.length > 0 ? (
                          <>
                            {centers?.map((center: any, i: number) => {
                              return (
                                <div
                                  className="card"
                                  onClick={() =>
                                    handleRedirectCenter(center.Id, props)
                                  }
                                  key={i}
                                >
                                  <div className="card-header card-title article-title_medium">
                                    {" "}
                                    {center.Descripcion}
                                  </div>
                                  <div className="card-body body-card-center">
                                    <p className="text-address-card">
                                      {`${center.TipoViaDescripcion} ${center.NombreCalle} ${center.NumeroCalle}  C.P ${center.CodigoPostal}, ${center.LocalidadDescripcion} `}
                                    </p>
                                  </div>
                                  <div className="card-footer">
                                    <small className="text-muted">
                                      <a href="tel:+34967511417">
                                        Telf:{" "}
                                        {center.Telefono ? center.Telefono : ""}
                                      </a>
                                    </small>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <li className="col-12">
                            No hay centros para esta provincia.
                          </li>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default Centers;
