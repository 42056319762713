import React, { useContext, useEffect } from 'react';
import Header from '../blocks/Header/Header';
import Footer from '../blocks/Footer/Footer';
import LegalWarningPopup from 'components/blocks/LegalWarningPopup/LegalWarningPopup';
import { navLinksPatient, navLinksCompany, navLinksImpersonationHisam, navLinksImpersonationSip, navLinksImpersonationSipCompany } from '../../data/nav.data';
import { HeaderContextProvider } from '../../contexts/HeaderContext';
import { CookiesPolicyContext } from "contexts/CookiesPolicyContext";
import useLogin from "hooks/useLogin";
import Loader from 'components/blocks/Loader/Loader';
import useGetRole, { ROLES } from "../../hooks/useGetRole";
import ButtonGeneralHelp from 'components/pages/ayuda/ButtonGeneralHelp';
import { useLocation } from 'react-router-dom';
import useGetMyManagement from "services/management/useGetMyManagement";
import { useGeneralManagementState } from "../../components/pages/GeneralManagement/generalManagementState";
export interface MainLayoutProps {
  children: any,
}

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const location = useLocation();
  const { state, dispatch } = useGeneralManagementState();
  const {
    getMyManagement,
    loadinGetMyManagement,
    successGetMyManagement,
    dataMyManagement,
    generalManagements,
    activeManagements,
    groupsActiveManagements,
    groupsGeneralManagements,

  } = useGetMyManagement();
  const {

    prestacionGeneral,

  } = state;
  const currentPath = location.pathname;
  const showButtonGeneralHelp = () => {
    if (currentPath === '/' || currentPath === '/inicio') {
      return true;
    }
    if (currentPath.match(/^\/historial\/\d+(_\w+)?/)) {
      return true;
    }
    if (currentPath === '/mis-citas' || currentPath === '/mis-gestiones' || currentPath === '/notificaciones' || currentPath === '/historial') {
      return true;
    }
    return false;
  };
  
  const userRole = useGetRole.getRole();
  const { isImpersonation, isHisam, isSIP, impersonationAppName } = useGetRole;
  const { cookiesPolicyState } = useContext(CookiesPolicyContext);
  const { currentAccount } = useLogin();
  let navLinks: any = null;
  if (isImpersonation() && !!impersonationAppName()) {
    if (isHisam())
      navLinks = navLinksImpersonationHisam;
    if (isSIP())
      navLinks = userRole === ROLES.PACIENTE ? navLinksImpersonationSip : navLinksImpersonationSipCompany;;
  }
  if (!navLinks) {
    navLinks = userRole === ROLES.PACIENTE ? navLinksPatient : navLinksCompany;
  }

  useEffect(() => {
    if (prestacionGeneral?.length <= 0 && userRole) {
      getMyManagement();
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (prestacionGeneral.length <= 0) {
      dispatch({
        type: "SET_PRESTACION_ACTIVA",
        payload: activeManagements && activeManagements,
        // payload: [],
      });
      dispatch({
        type: "SET_PRESTACION_GENERAL",
        payload: generalManagements && generalManagements,
        // payload: [],
      });
      dispatch({
        type: "SET_GRUPOS_PRESTACION_ACTIVA",
        payload: groupsActiveManagements && groupsActiveManagements,
        // payload: [],
      });
      dispatch({
        type: "SET_GRUPOS_PRESTACION_GENERAL",
        payload: groupsGeneralManagements && groupsGeneralManagements,
        // payload: [],
      });
      dispatch({
        type: "SET_LOADING",
        payload: loadinGetMyManagement && loadinGetMyManagement,
      });
      dispatch({
        type: "SET_SUCCESS",
        payload: successGetMyManagement && successGetMyManagement,
      });
    }
  }, [dataMyManagement]);
  return (
    <>
      {currentAccount ?
        <>
          <HeaderContextProvider>
            <Header
              dataSource={navLinks}
              homePath={`/inicio`}
              isImpersonation={isImpersonation()}
            />

          </HeaderContextProvider>
          <main className="main-container">
            {props.children}
          </main>
          {showButtonGeneralHelp() && <ButtonGeneralHelp />}
          <Footer dataSource={navLinks} />
          {!cookiesPolicyState && !isImpersonation() &&
            <LegalWarningPopup />
          }
        </>
        : <Loader activeLoader />}

    </>

  );
}

export default MainLayout;