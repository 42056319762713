import React, {useState} from 'react';
import { management } from "@solimat/solimat-web-endpoint";

const useGetAllGeneralcareDocument = () => {
  const [loadingGetAllGeneralDocuments, setLoadingGetAllGeneralDocuments] = useState(false);
  const [successGetAllGeneralDocuments, setSuccessGetAllGeneralDocuments] = useState(false);
  const [errorGetAllGeneralDocuments, setErrorGetAllGeneralDocuments] = useState(null);
  const [allGeneralDocuments, setAllGeneralDocuments] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_MANAGEMENT_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MANAGEMENT_KEY || "";
  const apiManagement = new management.ManagementService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  const getAllGeneralDocuments = (_cb?:(data:any)=> void) => {
    apiManagement.getAllGeneralDocuments()
    .then((res:any) => {
      setAllGeneralDocuments(res.data);
      setLoadingGetAllGeneralDocuments(true);
      setSuccessGetAllGeneralDocuments(true);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any) => {
      console.log(err)
      setLoadingGetAllGeneralDocuments(false);
      setErrorGetAllGeneralDocuments(err)
    })
  }
  
  return {
    allGeneralDocuments,
    getAllGeneralDocuments,
    loadingGetAllGeneralDocuments,
    successGetAllGeneralDocuments,
    errorGetAllGeneralDocuments

  }
}

export default useGetAllGeneralcareDocument;