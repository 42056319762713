import { useState } from 'react';

const useModals = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [childrenProps, setChildrenProps] = useState({path:''});
    const [ disabledCloseButton, setDisabledCloseButton ] = useState(false);

    const handleOpenModal = (childrenProps?:any, _cb?:()=>void) => {
      setCollapsed(false);
      setChildrenProps(childrenProps);
      if(typeof _cb === 'function') {
        _cb();
      }
    }
    const handleCloseModal = () => {
      setCollapsed(true);
      setChildrenProps({path:''});
    }

    const handleDisableCloseButton = (state:boolean) => {
      setDisabledCloseButton(state)
    }
 
    return {
      collapsed, 
      childrenProps, 
      handleOpenModal, 
      handleCloseModal, 
      disabledCloseButton, 
      handleDisableCloseButton
    };
}

export default useModals;