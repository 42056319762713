import React, { createContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearcherWithoutLeavePending from "components/blocks/Searchers/Delta/SearcherWithoutLeavePending";
import GridDeltaAccidentWithoutLeave from "components/blocks/Grids/GridDeltaAccidentWithoutLeave";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import useAllRatsb from "services/delta/ratsb/useAllRatsb";
import Accordion from "components/blocks/Accordion/Accordion";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import { useLocation } from "react-router-dom";
import { TextMessagesTemplates } from "components/blocks/TextMessagesTemplates/TextMessagesTemplates";
//hooks
import useToggleExpandedAccordion from "hooks/useToggleExpandedAccordion";
import SearcherWithoutLeaveRemited from "components/blocks/Searchers/Delta/SearcherWithoutLeaveRemited";


const DeltaWithoutLeave: React.FC = () => {
  const location = useLocation();
  const [allFilterRatsb, setAllFilterRatsb] = useState<any>();
  const [allFilterRatsbRemesed, setAllFilterRatsbRemesed] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isRemitted, setIsRemitted] = useState<null | boolean>(null);
  const title = location?.pathname.includes("remesados")
    ? "Accidentes sin baja Delta remesados"
    : "Accidentes sin baja pendientes de delta";
  const breadcrumbs = [
    "unidad delta",
    location?.pathname.includes("remesados")
      ? "Accidentes sin baja Delta remesados"
      : "Accidentes sin baja pendientes de delta",
  ];
  const { getAllRatsbByUser, getCompleteRatsbByUserRemesed } = useAllRatsb();
  const { expandedState, toggleExpandedAccordionState } =
    useToggleExpandedAccordion();

  const [masterData, setMasterData] = useState<any>({ init: true, type: 'RATSB' });
  const handleFilterRatsb = async (values: any) => {
    const sendData: any = {
      validacion: parseInt(values.validation) || null,
      remesado: parseInt(values.remesados) || null,
      fechaAccidenteDesde: values.FromDate || null,
      fechaAccidenteHasta: values.ToDate || null,
      trabajador: values.name || null,
      cnaeCodigo: values.cnaeCode || null,
      empresa: values.company || null,
      empresasId: values.companyCif || null,
    };
    setLoading(true);
    setError(false);
    const response = await getAllRatsbByUser(sendData);
    if (response) {
      setAllFilterRatsb(response);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
      // SHOW ERROR
    }
  };
  const handleFilterRatsbRemesed = async (values: any) => {
    const sendData: any = {
      fechaAccidenteDesde: values.FromDate || null,
      fechaAccidenteHasta: values.ToDate || null,
      trabajador: values.name || null,
      cnaeCodigo: values.cnaeCode || null,
      empresa: values.company || null,
      empresasId: values.companyCif || null,
      aceptado:
        typeof values.deltaPart === "string"
          ? JSON.parse(values.deltaPart.toLowerCase())
          : null,
    };
    setLoading(true);
    setError(false);
    const response = await getCompleteRatsbByUserRemesed(sendData);
    if (response) {
      setAllFilterRatsbRemesed(response);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
      // SHOW ERROR
    }
  };
  useEffect(() => {
    setIsRemitted(location.pathname.includes("remesados"));
  }, [location]);
  useEffect(() => {
    // handleFilterRatsb({});
  }, []);

  const handleMasterDataChange = (key: string, value: any) => {
    var newObj = { ...masterData, [key]: value };
    setMasterData(newObj);
  }

  return (
    <>
      <PageMenu title={title} breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to="/unidad-delta">
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      {!isRemitted ? (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Accordion
                  title="Buscador de Relación Sin Baja pendiente de Delta"
                  expandedInit={true}
                  expandedHide={expandedState}
                >
                  <SearcherWithoutLeavePending
                    handleFilterRatsb={handleFilterRatsb}
                    loading={loading}
                  />
                </Accordion>
              </div>
            </div>
          </div>
          <div className="container-fluid--wrapper">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12">
                  {loading && (
                    <div className="container">
                      <div className="col p-0 loading">
                        {TextMessagesTemplates.loading}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  {error && (
                    <div className="container">
                      <div className="col p-0 errors">
                        {TextMessagesTemplates.errorResponse}
                      </div>
                    </div>
                  )}
                </div>
                {allFilterRatsb?.length > 0 && !loading && (
                  <div className="col p-0">
                    <div className="container results">
                      <GridDeltaAccidentWithoutLeave data={allFilterRatsb} isRemitted={isRemitted} />
                    </div>
                  </div>
                )}
                {allFilterRatsb?.length === 0 && !loading && (
                  <div className="col p-0">
                    <div className="container">
                      <p>No hay resultados</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Accordion
                  title=" Buscador Relación Sin Baja Remesada"
                  expandedInit={true}
                  expandedHide={expandedState}
                >
                  <SearcherWithoutLeaveRemited
                    handleFilterRatsbRemesed={handleFilterRatsbRemesed}
                    loading={loading}
                  />
                </Accordion>
              </div>
            </div>
          </div>
          <div className="container-fluid--wrapper">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12">
                  {loading && (
                    <div className="container">
                      <div className="col p-0">
                        {TextMessagesTemplates.loading}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  {error && (
                    <div className="container">
                      <div className="col p-0">
                        {TextMessagesTemplates.errorResponse}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col p-0">
                  {allFilterRatsbRemesed?.length > 0 && !loading && (
                    <div className="container">
                      <GridDeltaAccidentWithoutLeave
                        data={allFilterRatsbRemesed}
                        isRemitted={isRemitted}
                      />
                    </div>
                  )}
                  {allFilterRatsbRemesed?.length == 0 && !loading && (
                  <div className="col p-0">
                    <div className="container">
                      <p>No hay resultados</p>
                    </div>
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </ >
  );
};

export default DeltaWithoutLeave;
