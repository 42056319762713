import React from 'react';

interface IInputProps {
  errors: any,
  register: any,
  name: string
}

const InjurySeverity: React.FC<IInputProps> = ({
  errors,
  register,
  name
}) => {
  const inptName = `${name ? name : "injurySeverity"}`;
  return (
    <div className={`pl-4 pr-4 pb-2 d-flex inputs--group ${errors && errors[name]?.message ? 'error' : ''}`}>
      <label htmlFor="minor" className="input-block--radio-check__label">
        <span className="text">Leve</span>
        <input type="radio" name={inptName} id="minor" {...register(inptName)} value="1" />
      </label>
      <label htmlFor="severe" className="input-block--radio-check__label">
        <span className="text">Grave</span>
        <input type="radio" name={inptName} id="severe" {...register(inptName)} value="2" />
      </label>
      <label htmlFor="critical" className="input-block--radio-check__label">
        <span className="text">Muy grave</span>
        <input type="radio" name={inptName} id="critical" {...register(inptName)} value="3" />
      </label>
      <label htmlFor="death" className="input-block--radio-check__label">
        <span className="text">Fallecimiento</span>
        <input type="radio" name={inptName} id="death" {...register(inptName)} value="4" />
      </label>
      {errors &&
        <p className="error m-0">
          {errors[name]?.message}
        </p>
      }
    </div>
  )
}

export default InjurySeverity;