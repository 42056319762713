
import { useState } from "react";

import {apiForm} from './apiFormsBuilder';

interface Iform {
  id: string;
  name: string;
  description: string;
  version: 0;
  version_description: null;
  groups: any[];
  status?: number
}

const useGetCompleteForm = () => {

  const [formData, setFormData] = useState<Iform>({
    id: "",
    name: "",
    description: "",
    version: 0,
    version_description: null,
    groups: []
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCompleteForm = (formId: any, arrInputsValue?:any, _cb?:(data:any, arrInputsValue:any)=>void) => {
    setLoading(true);
    setError(null);
    apiForm
      .getCompleteForm(formId)
      .then((data: any) => {
        setFormData(data.data);
        setLoading(false);

        if(typeof _cb == 'function') {
          _cb(data.data, arrInputsValue);
        }
      })
      .catch(err => {
        console.error("getCompleteForm: ", err);
        setError(err);
        setLoading(false);
      });
  };


  return {
    getCompleteForm,
    formData,
    loading,
    error
  };
};

export default useGetCompleteForm;