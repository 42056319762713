import {useState} from 'react';
import { checkFieldInputType } from './checkTypes';

export const useGenerateFormTemplateObject = () => {
  const [JSONschema, setJSONschema] = useState(null)
  const [loadingGenerateJSONschema,setLoadingGenerateJSONschema] = useState(false)

  const generateJSON = (completeForm:any, formValues:any) => {
    setLoadingGenerateJSONschema(true);
    const arrFormValues = formValues;

    const formJSON:any = {}
    
    const groups = completeForm.groups.map((group:any)=> {
      let groupName:string = group.name;
      let groupId:string = group.id;
      let objectGroup:any = {}

      let groupFields = group.fields.map((field:any)=> {
        let objectGroupFields:any = {};
        objectGroupFields['name'] =field.name;
        objectGroupFields['type'] = checkFieldInputType(field.type);
        objectGroupFields['description'] = field.description;
        objectGroupFields['value'] = [];
        objectGroupFields['id'] = field.group_field_id;
        for(let i = 0; i<arrFormValues.length;i++) {
          if(arrFormValues[i].name === field.name) { // SI EL NAME DEL CAMPO COINCIDE CON EL NAME DEL INPUT
            objectGroupFields['value'].push(arrFormValues[i].value)
          }
        }

        if(field.options.length > 0) { // SI NO TIENE OPTIONS NO AÑADIR
          let optionsField = field.options.map((option:any)=> {
            return {value: option.value};
          })
          objectGroupFields['options'] = [];
          objectGroupFields['options'] = optionsField;
        }
        return objectGroupFields;
      })

      const gridsDom = document.querySelectorAll('.grid');

      let groupGrids = group.grids.map((grid:any, index:number)=> {
        let gridName = grid.name;
        let objectGroupGrids: any = {};
        const totalGridTr = gridsDom[index].querySelectorAll('tbody tr');
        let gridFields = grid.fields.map((gridField:any)=> {
          let objectGroupFieldsGrid:any = {};
          objectGroupFieldsGrid['name'] = gridField.name;
          objectGroupFieldsGrid['type'] = checkFieldInputType(gridField.type);
          objectGroupFieldsGrid['description'] = gridField.description;
          objectGroupFieldsGrid['value'] = [];

          if(gridField.options.length > 0) { // SI NO TIENE OPTIONS NO AÑADIR
            let optionsField = gridField.options.map((option:any)=> {
              return {value: option.value};
            })
            objectGroupFieldsGrid['options'] = [];
            objectGroupFieldsGrid['options'] = optionsField;
          }

          let gridFieldsRows = [];
          for(let i = 0; i<totalGridTr.length;i++) {
            let objectGridFieldsRows:any = {};
            objectGridFieldsRows['name'] = gridField.name + '_' + i;
            objectGridFieldsRows['type'] = checkFieldInputType(gridField.type);
            objectGridFieldsRows['description'] = gridField.description;
            objectGridFieldsRows['value'] = [];
            for(let j = 0; j<arrFormValues.length;j++) { // SI EL NAME DEL CAMPO COINCIDE CON EL NAME DEL INPUT
              if(arrFormValues[j].name === gridField.name + '_' + i) {
                objectGridFieldsRows['value'].push(arrFormValues[j].value)
              }
            }
            if(gridField.options.length > 0) { // SI NO TIENE OPTIONS NO AÑADIR
              let optionsField = gridField.options.map((option:any)=> {
                return {value: option.value};
              })
              objectGridFieldsRows['options'] = [];
              objectGridFieldsRows['options'] = optionsField;
            }
            gridFieldsRows.push(objectGridFieldsRows);
          }
          objectGroupFieldsGrid['rows'] = gridFieldsRows;
          return objectGroupFieldsGrid;
        })
        objectGroupGrids['fields'] = gridFields;
        objectGroupGrids['name'] = gridName;
        objectGroupGrids['id'] = grid.id;
        return objectGroupGrids;
      })

      objectGroup['id'] = groupId;
      objectGroup['name'] = groupName;
      objectGroup['fields'] = groupFields;
      objectGroup['grids'] = groupGrids

      return objectGroup;
    })
    formJSON['name'] = completeForm.name;
    formJSON['groups'] = groups;
    
    setJSONschema(formJSON);
    setLoadingGenerateJSONschema(false);
    return formJSON;
  }
  
  return {
    generateJSON,
    JSONschema,
    loadingGenerateJSONschema
  }
  
}