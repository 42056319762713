import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import useDecode from "../../../hooks/useDecode";

import useValidateUserCredentials from '../../../services/authenticate/useValidateUserCredentials';


interface IFormPassword {
  redirectPath: string,
  onClose: () => void,
  props:any,
  completeUser: any
}

const FormPassword : React.FC<IFormPassword> = ({ redirectPath, onClose, props, completeUser}) => {
  const {register, handleSubmit,  formState: { errors } } = useForm();
  const [userIdentity, setUserIdentity] = useState("");
  const tokenResponse = useDecode("token");

  const {state, validateUserCredentials, errorRequest } = useValidateUserCredentials();


  useEffect(() => {
    if (tokenResponse !== undefined) {
      
      setUserIdentity(tokenResponse.extension_id);
    } else {
      // props.history.push("/login")ojo;
    }
  }, []);

  const onSubmit = (args:any) => {
    validateUserCredentials({
      identity: userIdentity,
      password: args.password,
      twoFaToken: ""
    }, handleRedirect )
  }

  const handleRedirect = () => {
    props.history.push({
      pathname: redirectPath,
      completeUser: completeUser
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}
      className="form-regular"
    >
      <input type="password"
        id="prompt_password" className={`input ${errors.password || !state ? "invalid" : ""}`}
        name="password"
        placeholder="Contraseña"
        {...register('password',{
            required: "Campo requerido"
          })
        }
      />
      {
      state == false && !errorRequest ?
        <div className="error">Contraseña incorrecta</div>
      :
      errorRequest &&
          <div className="error">Lo sentimos, se ha producido un error interno.</div>
      }
      <div className="btns-container">
          <button type="button" className="btn-box btn-clear" onClick={onClose}>Cancelar</button>
          <button type="submit" className="btn-box btn-accent" >Enviar</button>
      </div>
    </form>
  )
}

export default FormPassword;