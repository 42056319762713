import { useState } from "react";
import { notifications } from "@solimat/solimat-web-endpoint";


const useGetAllNotificationsByUser = () => {
  const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_NOTIFICACIONES_KEY || "";
  const apiNotifications = new notifications.NotificationsService(BASE_URL, FUNCTIONS_KEY);

  const [allUserNotices, setAllUserNotices] = useState<any[]>([]);
  const [loadingGetAllUserNotices, setLoadingGetAllUserNotices] = useState(false);
  const [errorGetAllUserNotices, setErrorGetAllUserNotices] = useState(null);

  const getAllUserNotices = (userId: string) => {
    setLoadingGetAllUserNotices(true);
    setErrorGetAllUserNotices(null);
      apiNotifications
        .getAllNoticesByUser(userId)
        .then((data: any) => {
          let dataAllUserNotices = data.data;
         
          setAllUserNotices(dataAllUserNotices);
          setLoadingGetAllUserNotices(false);
        })
        .catch((err: any) => {
          console.error("getAllUserNotices: ", err);
          setErrorGetAllUserNotices(err);
          setLoadingGetAllUserNotices(false);
        });
  };

  return {
    getAllUserNotices,
    allUserNotices,
    loadingGetAllUserNotices,
    errorGetAllUserNotices
  };
};

export default useGetAllNotificationsByUser;