import React, {useEffect, useState, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import {TabsFormsContext} from '../../../contexts/TabsFormsContext';
import {guid} from '@solimat/solimat-web-endpoint/lib/forms-builder/dto';

import useModal from '../../../hooks/useModal';
// services
import useGetFormTemplateByForm from '../../../services/formsIngestion/useGetFormTemplateByForm';
import useGetRequestTransaction from '../../../services/formsIngestion/useGetRequestTransaction';
import useCreateFormTemplate from '../../../services/formsIngestion/useCreateFormTemplate';
import useGetCompleteForm from 'services/formsBuilder/useGetCompleteForm';

//hooks
import {useGenerateFormTemplateObject} from 'hooks/useGenerateFormTemplateObject';

//blocks
import TabsForms from "../../blocks/TabsForms/TabsForms";
import ActionsForms from "../../blocks/ActionsForms/ActionsForms";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import TemplateForm from "./TemplateForm";
import Modal from "../../blocks/Modal/Modal";

// images
import IconCrossGray from "../../../img/icons/svg/hamburger/cerrar-cruz-gris.svg";
import useFinishedRequestTransaction from '../../../services/formsIngestion/useFinishedRequestTransaction';
import Loader from 'components/blocks/Loader/Loader';
import LoaderMin from 'components/blocks/Loader/LoaderMin';

const FormsManagement:React.FC = (props:any) => {
  const [formId, setFormId] = useState<guid>('');
  const [formTemplate, setFormTemplate] = useState<string>('');
  const {
    tabSelected,
    tabFormSelected,
    setTabSelected,
    setTabFormSelected} = useContext(TabsFormsContext);
  const [refreshTemplate, setRefreshTemplate] = useState(false);
  const {handleOpenModal, handleCloseModal, collapsed} = useModal();
  const [title] = useState('Solicitud');
  const [activeProcess,setActiveProcess] = useState<boolean>(false);
  const { getCompleteForm, formData, loading, error } = useGetCompleteForm();
  const {    generateJSON,
    JSONschema,
    loadingGenerateJSONschema} = useGenerateFormTemplateObject();
  const { getRequestTransaction,
    requestTransaction,
    successGetRequestTransaction,
    loadingGetRequestTransaction,
    errorGetRequestTransaction } = useGetRequestTransaction();
  const {
    getFormTemplateByForm,
    successGetFormTemplateByForm,
    loadingGetFormTemplateByForm,
    errorGetFormTemplateByForm
  } = useGetFormTemplateByForm();
  const {
    createFormTemplate,
    successCreateFormTemplate,
    loadingCreateFormTemplate,
    errorCreateFormTemplate
  } = useCreateFormTemplate();
  const {
    finishedRequestTransaction,
    successFinishedRequestTransaction,
    loadingFinishedRequestTransaction,
    errorFinishedRequestTransaction
  } = useFinishedRequestTransaction();
  const [transactionTabs, setTransactionTabs] = useState<any>(null);
  const idTransaction = props.match.params.id;

  useEffect(()=> {
      getRequestTransaction(idTransaction, setTransactionTabs);
  }, [])

  useEffect(()=>{
    if(transactionTabs !== null && transactionTabs !== undefined) {
      if(transactionTabs.length>0) {
        setTabSelected(0); // Inicializa tabSelected index a 0
        const tabForm = transactionTabs[0].form_id; // selecciona el id del formulario 0
        setTabFormSelected(tabForm)
      }
    }
  }, [transactionTabs])

  useEffect(() => {
    setRefreshTemplate(false);
    if(tabSelected !== null && transactionTabs !== null && transactionTabs.length>0) {
      setTabFormSelected(transactionTabs[tabSelected].form_id)
    }
  },[tabSelected, transactionTabs])

  useEffect(()=> {
    setRefreshTemplate(false);
    if(tabFormSelected !== null && tabFormSelected !== '' && tabFormSelected !== undefined) {
      getFormTemplateByForm(idTransaction,tabFormSelected,handleSetFormTemplate ); // get form template html
    }
  },[tabFormSelected])

  const handleSetFormTemplate = (data:any) => {
    if(data !== null) {
      setFormTemplate(data.content_template)
      setFormId(data.form_id);
    } else {
      setFormTemplate('')
    }
  }

  useEffect(() => {
    setRefreshTemplate(true);
  },[formTemplate])

  const[crudeFormData, setCrudeFormData] = useState<null|any>(null);
  useEffect(()=> {
    if(formId) {
      // getCompleteForm(formId);
      getCompleteForm(formId, setCrudeFormData);
    }
  },[formId])

  const handleFinishedRequestTransaction = () => {
    finishedRequestTransaction(idTransaction)
  }

  // /// ACCIONES DEL FORMULARIO: GUARDAR Y ENVIAR
  function saveForm (_cb?:()=> void, step?:string) {
    const form = (document.getElementById(`template_${formId}`) as HTMLFormElement);

    if(form !== null) {
      const inputsForm = document.querySelectorAll(`#template_${formId} input`);
      const selectsForm = document.querySelectorAll(`#template_${formId} select`);
      const textareaForm = document.querySelectorAll(`#template_${formId} textarea`);

      // INPUT TEXT, NUMBER OR DATE SET VALUE
      for (let i = 0; i<inputsForm.length; i++) {
        const input = inputsForm[i] as HTMLInputElement;
        if(input.type == 'text' || input.type == 'number'|| input.type == 'date') {
          input.setAttribute('value', input.value)
        }
      }

      // SELECT
      for (let i = 0; i< selectsForm.length; i++) {
        const select = selectsForm[i] as HTMLSelectElement;
        const selectDataValue = select.getAttribute('data-value');
        if (selectDataValue !== undefined && selectDataValue !== null) {
          select.setAttribute('value', selectDataValue);
        }
      }

      // TEXTAERA
      for (let i = 0; i< textareaForm.length; i++) {
        const textarea = textareaForm[i] as HTMLTextAreaElement;
        const textareaValue = textarea.value;
        if (textareaValue !== undefined && textareaValue !== null) {
          textarea.setAttribute('value', textarea.value);
        }
      }

      // RADIO OR CHECKBOX
      const inputsCheckbox:any = document.querySelectorAll("input[data-checked]");
      for(let i=0;i<inputsCheckbox.length;i++){
        if(inputsCheckbox[i].type == 'checkbox' || inputsCheckbox[i].type == 'radio') {
          if(inputsCheckbox[i].getAttribute('checked')=='true') {
            inputsCheckbox[i].setAttribute('checked', 'true');
            inputsCheckbox[i].setAttribute('data-checked', 'true');
          } else {
            inputsCheckbox[i].removeAttribute('checked');
            inputsCheckbox[i].setAttribute('data-checked', 'false');
          }
        }
      }

      // /// TEMPLATE PARA GUARDAR
      const formTemplateHtml = form.outerHTML;

      /// CREATE JSON FORMULARIO
      const formInputsName:any = form.querySelectorAll('[name]');

      let arrInputsValue = [];
      for (let i = 0; i<formInputsName.length;i++) {
        const inputName = formInputsName[i].name;
        const inputValue = formInputsName[i].value;
        const inputType = formInputsName[i].type;
        const inputChecked = formInputsName[i].defaultChecked;

        switch(inputType) {
          case 'radio':
            if(inputChecked) {
              arrInputsValue.push({name: inputName,value:inputValue});
            }
            break;
          case 'checkbox':
            if(inputChecked) {
              arrInputsValue.push({name: inputName,value:true});
            }
            break;
          default:
            if(inputValue !== "") {
              arrInputsValue.push({name: inputName,value:inputValue});
            }
            break;
        }
      }

      handleGetFormData(arrInputsValue, (generatedJSON:any)=>{
        // /// SAVE JSON FORM
        const dataTemplate = {
          formId: formId,
          dataTemplate: JSON.stringify(generatedJSON),
          contentTemplate: formTemplateHtml,
          requestTransactionId: idTransaction
        }

        if(tabSelected===(transactionTabs.length-1) && step === 'finish') {
          createFormTemplate(dataTemplate, handleFinishedRequestTransaction);
        } else {
          createFormTemplate(dataTemplate)
        }

        /// ACTION AFTER SAVE (open modal)
        if(typeof _cb === 'function') {
          _cb()
        }

      }) // PIDE FORMDATA Y GENERA JSON + JSONVALUES


    }

  }

  const handleGetFormData = (arrInputsValue:any, _cb:(generatedJSON:any)=>void) => {
    getCompleteForm(formId, arrInputsValue, (data,arrInputsValue)=>{
      const generatedJSON = generateJSONtemplate(data,arrInputsValue);
      _cb(generatedJSON)
    });
  }

  const generateJSONtemplate = (data:any, arrInputsValue:any) => {
    const generatedJSON = generateJSON(data, arrInputsValue);
    return generatedJSON;
  }

  const handleSaveForm = () => {
    setActiveProcess(true);
    saveForm(()=>setActiveProcess(false));
  }

  const handleSaveFormAndRedirect = () => {
    setActiveProcess(true);
    saveForm(()=>handleRedirect());
  }

  const handleFinishProcess = () => {
    setActiveProcess(true);
    saveForm(()=>handleOpenModal(()=>setActiveProcess(false)), 'finish');
  }

  const handleRedirect = () => {
    setActiveProcess(false);
    props.history.push('/mis-gestiones');
  }

  // error and loading messages
  if(loadingGetRequestTransaction) {
    return(
      <section className="container">
        {/* <p className="loader-text">Cargando...</p> */}
        <LoaderMin/>
      </section>
    )
  }
  if(errorGetRequestTransaction) {
    return(
      <section className="container">
        <p className="loader-text">Lo sentimos se ha producido un error</p>
      </section>
    )
  }
  if(transactionTabs !== null && transactionTabs !== undefined) {
    if(transactionTabs.length<1) {
      return(
        <section className="container">
          <p className="loader-text">No hay formularios disponibles para esta solicitud</p>
        </section>
      )
    }
  }

  return (
    <>
    {(transactionTabs !== null && transactionTabs !== undefined)&&
    <>
      <PageMenu
        title={requestTransaction.name?requestTransaction.name:"Solicitud"}
      >
        <div className="btns-header-container">
          <button onClick={formTemplate!==''?handleSaveFormAndRedirect:handleRedirect}><img src={IconCrossGray} alt="icon arrow right"/></button>
        </div>
      </PageMenu>
      {/* NAV FORM BUTTONS */}
      <div className="forms-management-content">
        {/* <div className={`page-overlay active ${activeProcess?'active':''}`}>
          <div className="loader-form"></div>
        </div> */}
        <div className={`box-loader page-overlay ${loadingGetFormTemplateByForm || loadingGetRequestTransaction || loadingCreateFormTemplate || loadingFinishedRequestTransaction?"active":""}`}>
          <div className="loader-spinner"></div>
        </div>
        <div className="container">
          <div className="row">
            {transactionTabs !== null &&
              <TabsForms
                transaction={transactionTabs}
                handleSaveForm={handleSaveForm}
              />
            }
          </div>
          <div className="row">
            <div className="col-12">
              {formTemplate !== '' && formTemplate !== null && formId !== '' && refreshTemplate?
                <TemplateForm
                  formTemplate={formTemplate}
                  handleSaveForm={handleSaveForm}
                  formId={formId}
                  refreshTemplate={refreshTemplate}
                />
                :
                <>
                  { errorGetFormTemplateByForm &&
                    <p className="loader-text">Se ha producido un error al cargar el formulario.</p>
                  }
                  { loadingGetFormTemplateByForm &&
                    // <p className="loader-text">Cargando...</p>
                    <LoaderMin/>
                  }
                  { (formTemplate == null || formTemplate == '' && !errorGetFormTemplateByForm && !loadingGetFormTemplateByForm) &&
                    <p className="loader-text">No hay datos para mostrar.</p>
                  }
                </>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* NAV FORM BUTTONS */}
            {transactionTabs !== null &&
              <ActionsForms
                successGetFormTemplateByForm={successGetFormTemplateByForm}
                loadingGetFormTemplateByForm={loadingGetFormTemplateByForm}
                errorGetFormTemplateByForm={errorGetFormTemplateByForm}
                transaction={transactionTabs}
                totalForms={transactionTabs.length}
                handleFinishProcess={handleFinishProcess}
                handleSaveFormAndRedirect={handleSaveFormAndRedirect}
                handleSaveForm={handleSaveForm}
                formTemplate={formTemplate}
              />
            }
            </div>
          </div>
        </div>
      </div>
      {loadingFinishedRequestTransaction?
      <>
      </>
      :
      <>
      {(successFinishedRequestTransaction||errorFinishedRequestTransaction)&&
        <Modal
          title={errorFinishedRequestTransaction?"Se ha producido un error":"Proceso finalizado con éxito"}
          collapsed={collapsed}
          onClose={errorFinishedRequestTransaction?handleCloseModal:handleRedirect}
        >
          <div className="">
            <p className="c-center">{errorFinishedRequestTransaction?`Inténtelo de nuevo más tarde`:``}</p>
            <div className="btns-container c-center">
              <button className="btn-box btn-accent"
                onClick={errorFinishedRequestTransaction?handleCloseModal:handleRedirect}
              >OK</button>
            </div>
          </div>
        </Modal>
      }
      </>
      }
      </>
      }
    </>
  )
}

export default withRouter(FormsManagement);