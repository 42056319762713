import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';

const useGetEpisode = () => {
  const [loadingGetEpisode, setLoadingGetEpisode] = useState(false);
  const [successGetEpisode, setSuccessGetEpisode] = useState(false);
  const [errorGetEpisode, setErrorGetEpisode] = useState(null);
  const [dataEpisode, setDataEpisode] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

  const getVisit = (userId:string, prestacionId:string, episodioId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getVisit(userId, prestacionId, episodioId)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }

  const getRehabilitation = (userId:string, prestacionId:string, episodioId:number,sesionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getEpisodeRehabilitationById(userId, prestacionId, episodioId, sesionId)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }
const getEpisodeImagesbyId = (userId:string, prestacionId:string, episodioId:string, _cb?:(data:any)=>void) => {
  setLoadingGetEpisode(true);
  setSuccessGetEpisode(false);
  apiRecordSheets.getEpisodeImagesbyId(userId, prestacionId, episodioId)
    .then((res:any) => {
      setLoadingGetEpisode(false);
      setSuccessGetEpisode(true);
      setDataEpisode(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any)=> {
      console.log(err)
      setLoadingGetEpisode(false);
      setErrorGetEpisode(err)
    })
}

  const getOperationRoom = (userId:string, prestacionId:string, episodioId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getOperationRoom(userId, prestacionId, episodioId)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }
  const getImagesbyId = (id:string, _cb?:(data:any)=>any) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getImagesbyId(id)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }

  
  const getEmergencies = (userId:string, prestacionId:string, episodioId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getEmergencies(userId, prestacionId, episodioId)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }

  const getHospitalization = (userId:string, prestacionId:string, episodioId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisode(true);
    setSuccessGetEpisode(false);
    apiRecordSheets.getHospitalization(userId, prestacionId, episodioId)
      .then((res:any) => {
        setLoadingGetEpisode(false);
        setSuccessGetEpisode(true);
        setDataEpisode(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisode(false);
        setErrorGetEpisode(err)
      })
  }

  return {
    getVisit,
    getRehabilitation,
    getHospitalization,
    getOperationRoom,
    getEmergencies,
    loadingGetEpisode,
    successGetEpisode,
    errorGetEpisode,
    dataEpisode,
    getImagesbyId,
    getEpisodeImagesbyId
    };
}

export default useGetEpisode;