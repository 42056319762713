import React, { useContext, useEffect, useState } from "react";
import {
  AutoCompleteComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import useGetAllCnaeEmployee from  '../../../../services/utils/useGetAllCnaeEmployee.'
import { ICnaeResponse } from "@solimat/solimat-web-endpoint/lib/utils/dto";
// import { MasterDataContext } from "components/pages/Delta/Delta";
import masterDataUtils from "services/utils/masterDataUtils";
import { useFormContext } from "react-hook-form";
import Fuse from "fuse.js";

interface IInputProps {
  errors: any;
  register: any;
  watchCnaeCode?: any;
  setValue?: any;
  label?: string;
  idCode: string;
  idDescription: string;
}

const CnaeContributionAtEp: React.FC<IInputProps> = ({
  errors,
  register,
  setValue,
  watchCnaeCode,
  label,
  idCode,
  idDescription,
}) => {
  const { getAllCnaeEmployee } = useGetAllCnaeEmployee();
  const [allCnae, setAllCnae] = useState<any[]>([]);

  const itemKey: string = "cnae";
  // const masterDataContext = useContext(MasterDataContext);

  // const masterData = masterDataContext?.masterData;

  // const { checkAndUpdate } = masterDataUtils(masterDataContext);

  const { getValues } = useFormContext();

  useEffect(() => {
    const cnaes = getAllCnaeEmployee().then((result)=>{
      setAllCnae(result)
    })
    // checkAndUpdate(itemKey, allCnae, getAllCnaeEmployee, setAllCnae);
  }, []);

  useEffect(() => {
    setValueCnaeDescription();
  }, [watchCnaeCode, allCnae]);

  const handleChange = (args: any) => {
    if (allCnae) {
      const selectedValue = allCnae.find(
        (item: any) => item.Descripcion === args.value
      );
      if (selectedValue) {
        setValue(idCode, selectedValue.Codigo || "");
        setValue(idDescription, selectedValue.Descripcion || "");
      } else {
        setValue(idCode, "");
        setValue(idDescription, "");
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setValue(idDescription, "");
    } else {
      const selectedValue = allCnae.find(
        (item: any) => item.Codigo === value
      );
      if (selectedValue) {
        setValue(idDescription, selectedValue.Descripcion || "");
      } else {
        setValue(idDescription, "");
      }
    }
  };

  const setValueCnaeDescription = () => {
    if (allCnae) {
      const descriptionValue = allCnae.find(
        (item: any) => item.Codigo === watchCnaeCode
      );
      setValue(
        idDescription,
        descriptionValue ? descriptionValue.Descripcion : ""
      );
    }
  };

  const itemsData: { [key: string]: Object }[] = allCnae;
  const fields: object = { value: "Descripcion" };

  function onFiltering(e: FilteringEventArgs) {
    const options = {
      keys: ["Descripcion"],
      includeMatches: true,
      findAllMatches: true,
    };

    const fuse = new Fuse(itemsData, options);
    const result = fuse.search(e.text);
    const data = result.map((res: any) => res.item);

    e.updateData(data, undefined);

    const lists = document
      ?.getElementById("agreement_popup")
      ?.querySelectorAll(".e-list-item");

    if (lists) {
      highlightSearch(Array.from(lists), result);
    }
  }

  function highlightSearch(listItems: Element[], result: any): void {
    if (result.length > 0) {
      for (let i = 0; i < listItems.length; i++) {
        let innerHTML = listItems[i].innerHTML;
        for (let j = result[i].matches[0].indices.length - 1; j >= 0; j--) {
          const indexes = result[i].matches[0].indices[j];
          innerHTML =
            innerHTML.substring(0, indexes[0]) +
            '<span class="e-highlight">' +
            innerHTML.substring(indexes[0], indexes[1] + 1) +
            "</span>" +
            innerHTML.substring(indexes[1] + 1);
          listItems[i].innerHTML = innerHTML;
        }
      }
    }
  }

  return (
    <fieldset
      className={`input-block input-block--code ${
        (errors && errors[idCode]?.message) || (errors && errors[idDescription]?.message)
          ? "error"
          : ""
      }`}
    >
      <div className="container-leyend">
        <legend className="input-block--code__legend">
          {label ? label : "CNAE para cotización AT/EP"}
        </legend>
      </div>
      <div className="row">
        <div className="col-sm-8 col-md-3 col-lg-3">
        <input
          className=""
          type="text"
          name={idCode}
          id={idCode}
          {...register(idCode)}
          maxLength={4}
          minLength={4}
          onChange={handleInputChange}
        />
        </div>
        {itemsData.length > 0 ? (
          <div className=" col-sm-12 col-md-8 ">
            <AutoCompleteComponent
              change={handleChange}
              id={idDescription}
              name={idDescription}
              dataSource={itemsData}
              filtering={onFiltering}
              fields={fields}
              placeholder="CNAE para cotización AT/EP"
              autofill={true}
              {...register(idDescription)}
              popupHeight="250px"
              popupWidth="250px"
              onBlur={() => {
                const value = getValues(idDescription);
                if (value === "") {
                  setValue(idCode, "");
                }
              }}
            />
          </div>
        ) : null}
      </div>
      {errors && <p className="error m-0">{errors[idCode]?.message}</p>}
    </fieldset>
  );
};

export default CnaeContributionAtEp;
