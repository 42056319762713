import React, { useEffect } from "react";

import useDecode from "../../../hooks/useDecode";

import IconCalendarBlue from "../../../img/icons/svg/cita/calendario-azul.svg";
import IconType from "../../../img/icons/svg/cita/especialidad-azul.svg";
import IconState from "../../../img/icons/svg/cita/estado-azul.svg";
import IconHospital from "../../../img/icons/svg/cita/hospital-azul.svg";
import Moment from "moment";
import useCheckTypes from "../../../hooks/useCheckRecordStatusAndTypes";
import "./TableAppointment.scss";

interface ITableEpisode {
  episode: any;
  episodeType: number;
}

const TableEpisode: React.FC<ITableEpisode> = ({ episode, episodeType }) => {
  const tokenResponse = useDecode("token");
  const { checkEpisodeType } = useCheckTypes();
  let especialidad = episode !== null ? episode.especialidad : "";

  //Show surgeon doctor speciality instead of episode's speciality
  especialidad = (especialidad == "" && episode.cirujanoEspecialidad) ? episode.cirujanoEspecialidad : especialidad;
    
  useEffect(() => {
    Moment().locale("es");
  }, []);
  return (
    <>
      <div className="table-appointment-block">
        <div className="table-appointment-header">
          <h3 className="table-appointment_title">{`${tokenResponse.extension_first_name} ${tokenResponse.extension_first_last_name} ${tokenResponse.extension_second_last_name || ''}`}</h3>
        </div>
        <div className="table-row-info">
          <div className="info-content ">
            <div className="icon">
              <img src={IconCalendarBlue} alt="" />
            </div>
            <div className="text-container">
              <p className="text-header">Fecha:</p>
              <p className="text">
                {episode?.date !== null
                  ? Moment(episodeType !== 205 ? episode?.FechaHoraInicio : episode.fechaHoraInicioSesion).format("L")
                  : ""}
              </p>
            </div>
          </div>
          <div className="info-content">
            <div className="icon">
              <img src={IconHospital} alt="" />
            </div>
            <div className="text-container">
              <p className="text-header">Centro:</p>
              <p className="text">
                {episode != null ? `${episode.centro.descripcion}` : ""}
              </p>
            </div>
          </div>
        </div>
        {episodeType !== 205 && episode.tipoComponente !== 203? (
          <div className="table-row-info">
            <div className="info-content">
              <div className="icon-container">
                <img src={IconType} alt="" />
              </div>

              <div className="text-container">
                <p className="text-header">Especialidad:</p>
                <p className="text">
                  {especialidad}
                </p>
              </div>
            </div>
            <div className="info-content">
              <div className="icon">
                <img src={IconState} alt="" />
              </div>
              <div className="text-container">
                <p className="text-header">Descripción del servicio:</p>
                <p className="text">
                  {episode != null ? episode.servicioDescripcion : ""}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default TableEpisode;
