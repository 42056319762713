import React from 'react'
import IconNoData from '../../../img/icons/symbols/sin-datos.png'
import './noData.scss'
const NoData = ({ message }) => {
  return (
    <div className='container-no-data'>
      <div className='card-no-data'>
        <div className="into-no-data">
        <img className='img-no-data' src={IconNoData} alt="sin datos que enseñar" />
        <h5 className='message-no-data'>{message}</h5>
        </div>
      </div>
    </div>
  )
}

export default NoData