import React, { useContext, useEffect } from 'react';
import {TabsFormsContext} from '../../../contexts/TabsFormsContext';
import {guid} from '@solimat/solimat-web-endpoint/lib/forms-ingestion/dto';

interface IFormTransaction {
  description: string,
  form_id: guid,
  id: guid,
  name: string,
  transaction_id: guid
}

interface IActionsForms {
  successGetFormTemplateByForm: null | boolean,
  loadingGetFormTemplateByForm: null | boolean,
  errorGetFormTemplateByForm: null | boolean,
  transaction: IFormTransaction[],
  totalForms:number,
  handleFinishProcess: () => void,
  handleSaveFormAndRedirect: () => void,
  handleSaveForm: () => void,
  formTemplate:any
}

const ActionsForms:React.FC<IActionsForms> = ({transaction,totalForms, handleSaveFormAndRedirect, handleFinishProcess,handleSaveForm,formTemplate, errorGetFormTemplateByForm, loadingGetFormTemplateByForm, successGetFormTemplateByForm}) => {
  const {tabSelected, setTabSelected, setTabFormSelected} = useContext(TabsFormsContext);
  const handleNextTab = () => {
    const tabSelectedIndex = tabSelected + 1;
    const formIdSelected = transaction[tabSelectedIndex].form_id;
    handleSaveForm();
    setTabSelected(tabSelectedIndex);
    setTabFormSelected(formIdSelected)
  }

  return (
    <>
      <div className="actions-forms c-right">
        <button className="btn-box btn-clear clear-accent" 
          onClick={handleSaveFormAndRedirect}
          disabled={(loadingGetFormTemplateByForm || errorGetFormTemplateByForm || !successGetFormTemplateByForm) || formTemplate === ''?true:false}
        >Guardar y cerrar</button>
        <button className={`btn-box btn-accent ${errorGetFormTemplateByForm?"disabled":""}"`} 
          onClick={tabSelected===(totalForms-1)? handleFinishProcess : handleNextTab}
          disabled={(loadingGetFormTemplateByForm || errorGetFormTemplateByForm || !successGetFormTemplateByForm) || !totalForms?true:false}
        >{tabSelected===(totalForms-1)?'Finalizar': 'Siguiente'}
        </button>
      </div>
    </>
  );
}

export default ActionsForms;