import React, {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetDocumentsByAppointmentId= () => {
  const [loadingGetDocumentsByAppointmentId, setLoadingGetDocumentsByAppointmentId] = useState(false);
  const [successGetDocumentsByAppointmentId, setSuccessGetDocumentsByAppointmentId] = useState(false);
  const [errorGetDocumentsByAppointmentId, setErrorGetDocumentsByAppointmentId] = useState(null);
  const [documentsByAppointmentId, setDocumentsByAppointmentId,] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);

  const getDocumentstByAppointmentId = (userId: string ,prestacionId: string, citaId: string, _cb?:(data:any)=> void) => {
    apiRecord.getDocumentstByAppointmentId(userId ,prestacionId, citaId)
    .then((res:any) => {
      setDocumentsByAppointmentId(res.data);
      setLoadingGetDocumentsByAppointmentId(false);
      setSuccessGetDocumentsByAppointmentId(true);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any) => {
      console.log(err)
      setLoadingGetDocumentsByAppointmentId(false);
      setErrorGetDocumentsByAppointmentId(err)
    })
  }
  
  return {
    documentsByAppointmentId,
    getDocumentstByAppointmentId,
    loadingGetDocumentsByAppointmentId,
    successGetDocumentsByAppointmentId,
    errorGetDocumentsByAppointmentId

  }
}

export default useGetDocumentsByAppointmentId;