import React from "react";
import { Route, Redirect } from "react-router-dom";
import useGetRole from "../hooks/useGetRole";
import Layout from "../components/layouts/Layout";

const PrivatePatientRoute = ({ component: Component, ...rest }) => {

  const roleSelected = useGetRole.getRole();
  const isPatient = roleSelected === 'paciente' || roleSelected === 'autonomo' ;

  return (
    // Show the component only when the user role is patient
    // Otherwise, redirect the user to /login page
    <Layout>
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
    </Layout>

  );
};

export default PrivatePatientRoute;
