import React from 'react';

interface ILoaderBtn {
  loading: boolean,
  text: string
  disabled?: boolean
}

const LoaderSubmitBtn: React.FC<ILoaderBtn> = ({loading,text,disabled}) => {
  return (
    <div className="d-inline-block">
      <button type="submit" 
        className={`btn-box btn-accent loading ${loading?'active':''}`}
        disabled={loading||disabled?true:false} 
      >
        <div className="loader-btn"></div>
        {loading?'':text}
      </button>
    </div>
  )
}

export default LoaderSubmitBtn;