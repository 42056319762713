import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetDocumentstByEpisodeId = () => {
  const [loadingGetDocumentstByEpisodeId, setLoadingGetDocumentstByEpisodeId] = useState(false);
  const [successGetDocumentstByEpisodeId, setSuccessGetDocumentstByEpisodeId] = useState(false);
  const [errorGetDocumentstByEpisodeId, setErrorGetDocumentstByEpisodeId] = useState(null);
  const [documentsByEpisodeId, setDocumentsByEpisodeId] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
 
  const getDocumentstByEpisodeId = (userId:string, recordId:string, episodioId: string, _cb?:(data:any)=>void) => {
    setLoadingGetDocumentstByEpisodeId(true);
    setSuccessGetDocumentstByEpisodeId(false);
    apiRecord.getDocumentstByEpisodeId(userId,recordId, episodioId)
      .then((res:any) => {       
        setDocumentsByEpisodeId(res.data);
        setLoadingGetDocumentstByEpisodeId(false);
        setSuccessGetDocumentstByEpisodeId(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetDocumentstByEpisodeId(false);
        setErrorGetDocumentstByEpisodeId(err)
      })
  }


  return {
    documentsByEpisodeId,
    getDocumentstByEpisodeId,
    loadingGetDocumentstByEpisodeId,
    successGetDocumentstByEpisodeId,
    errorGetDocumentstByEpisodeId
    };
}

export default useGetDocumentstByEpisodeId;