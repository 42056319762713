import React, { useEffect } from "react";
import "../ManagementSheets.scss";
import useGetAdministrationSheets from "services/recordSheets/useGetAdministrationSheets";
import useDecode from "hooks/useDecode";
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;

interface Management { 
  name: string,
  date: string,
  company: string
}

interface IEntornoTrabajador extends Management { // workerEnvironment
  id: string,
  city: string,
  postal_code: string,
  phone: string,
  movil: string,
  movil2: string,
  fax: string,
  email: string,
  is_dompf_type: boolean,
  dompf_type_street_code: string,
  dompf_type_street_description: string,
  dompf_street: string,
  dompf_number: string,
  dompf_bis_number: string,
  dompf_block: string,
  dompf_stairs: string,
  dompf_floor: string,
  dompf_door: string,       
  dompf_city_code: string, 
  bank_account: string,        
  province_description: string,        
  foreign_address: string,        
  foreign_location: string,        
  foreign_country: string,        
  record_id: guid,
  management_type: string
}

interface ISheet {
  props:any
}

const EntornoTrabajador:React.FC<ISheet> = ({props}:any) => {
  const parteId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    dataAdministrationSheet,
    loadingAdministrationSheet,
    errorAdministrationSheet,
    getWorkerEnvironment
  } = useGetAdministrationSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(()=> {
    getWorkerEnvironment(userId, prestacionId, parteId)
  },[])
  
  if(loadingAdministrationSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorAdministrationSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  return (
    <>
    {dataAdministrationSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
      <section className="container">
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Detalle'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Domicilio completo'}</label>
                <input type="text" className="item-value" value={`${dataAdministrationSheet.dompf_type_street_description} ${dataAdministrationSheet.dompf_street}, ${dataAdministrationSheet.dompf_number}`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tipo Vía'}</label>
                <input type="text" id="DomPfTipoViaCodigo" className="item-value w-25" value={dataAdministrationSheet.dompf_type_street_code} readOnly={true}/>
                <input type="text" id="DomPfTipoViaDescripcion" className="item-value w-75" value={dataAdministrationSheet.dompf_type_street_description} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Nombre Vía'}</label>
                <input type="text" id="DomPfNombreVia" className="item-value" value={dataAdministrationSheet.dompf_street} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item item-checkbox">
                <input type="checkbox" id="EsDomPfTipo" value="EsDomPfTipo" checked={dataAdministrationSheet.is_dompf_type?true:false} />
                <label className="item-label">{'Domicilio Extranjero'}</label>
              </div>
            </div>
            {/* DOMICILIO EXTRANJERO */}
            {dataAdministrationSheet.is_dompf_type?
            <div className="container">
              <div className="row">
                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{'Domicilio Extranjero'}</label>
                    <input type="text" id="DireccionExtranjero" className="item-value" value={dataAdministrationSheet.foreign_address} readOnly={true}/>
                  </div>
                </div>  
                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{'Localidad Extranjero'}</label>
                    <input type="text" id="LocalidadExtranjero" className="item-value" value={dataAdministrationSheet.foreign_location} readOnly={true}/>
                  </div>
                </div> 
                <div className="col-xl-3">
                  <div className="item">
                    <label className="item-label">{'País Extranjero'}</label>
                    <input type="text" id="PaisExtranjero" className="item-value" value={dataAdministrationSheet.foreign_country} readOnly={true}/>
                  </div>
                </div> 
              </div>
            </div>
            :null
            }
            {/*  */}
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Código Postal'}</label>
                <input type="text" id="CodigoPostal" className="item-value" value={dataAdministrationSheet.postal_code} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Código Localidad'}</label>
                <input type="text" id="DomPfCodLocalidad" className="item-value w-25" value={dataAdministrationSheet.dompf_city_code} readOnly={true}/>
                <input type="text" id="Localidad" className="item-value w-75" value={''} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Provincia'}</label>
                <input type="text" id="ProvinciaDescripcion" className="item-value" value={dataAdministrationSheet.province_description} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item-pair">
                <div className="item">
                  <label className="item-label">{'Número'}</label>
                  <input type="text" id="DomPfNumeroVia" className="item-value" value={dataAdministrationSheet.dompf_number} readOnly={true}/>
                </div>
                <div className="item">
                  <label className="item-label">{'BIS'}</label>
                  <input type="text" id="DomPfBisNumero" className="item-value" value={dataAdministrationSheet.dompf_bis_number} readOnly={true}/>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item-pair">
                <div className="item">
                  <label className="item-label">{'Bloque'}</label>
                  <input type="text" id="DomPfBloque" className="item-value" value={dataAdministrationSheet.dompf_block} readOnly={true}/>
                </div>
                <div className="item">
                  <label className="item-label">{'Escalera'}</label>
                  <input type="text" id="DomPfEscalera" className="item-value" value={dataAdministrationSheet.dompf_stairs} readOnly={true}/>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item-pair">
                <div className="item">
                  <label className="item-label">{'Piso'}</label>
                  <input type="text" id="DomPfPiso" className="item-value" value={dataAdministrationSheet.dompf_floor} readOnly={true}/>
                </div>
                <div className="item">
                  <label className="item-label">{'Puerta'}</label>
                  <input type="text" id="DomPfPuerta" className="item-value" value={dataAdministrationSheet.dompf_door} readOnly={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Datos electrónicos'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Teléfono'}</label>
                <input type="text" id={'Telefono'} className="item-value" value={dataAdministrationSheet.phone} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Teléfono Móvil'}</label>
                <input type="text" id={'Movil'} className="item-value" value={dataAdministrationSheet.movil} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Teléfono Móvil 2'}</label>
                <input type="text" id={'Movil2'} className="item-value" value={dataAdministrationSheet.movil2} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fax'}</label>
                <input type="text" id={'Fax'} className="item-value" value={dataAdministrationSheet.fax} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Email'}</label>
                <input type="text" id={'Email'} className="item-value" value={dataAdministrationSheet.email} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Cuenta Bancaria'}</label>
                <input type="text" id={'CuentaBancaria'} className="item-value" value={dataAdministrationSheet.bank_account} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      
      </section>
    }
    </>
  )
}

export default EntornoTrabajador;
