
import { useHistory } from "react-router-dom";
import iconAdvertencia from '../../../img/icons/ayuda/advertencia.png'
import './notices.scss'
function Notices({ countNotices }) {
    const history = useHistory();

    const handleOnclick = () => {
        history.push({
            pathname: '/avisos',
        });

    }
    return (

        <div className="container-btn-avisos " title='Avisos' onClick={handleOnclick}>
            {countNotices > 0 ? <span className='count-avisos'>{countNotices}</span> : ''}
            <img className='img-btn-avisos' src={iconAdvertencia} alt="" />
        </div>


    );
}



export default Notices