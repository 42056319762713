import { useState } from "react";
import { recordSheets } from "@solimat/solimat-web-endpoint";

const useGetImage = () => {
  const [loadingGetImage, setLoadingGetImage] = useState(false);
  const [successGetImage, setSuccessGetImage] = useState(false);
  const [errorGetImage, setErrorGetImage] = useState(null);
  const [dataImage, setDataImage] = useState<any | null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(
    BASE_URL,
    FUNCTIONS_KEY
  );
  const getImagesbyId =  (id: string) => {
    setLoadingGetImage(true);
    setSuccessGetImage(false);
  
    try {
      const response =  apiRecordSheets.getImagesbyId(id);
      const image = response;
      setLoadingGetImage(false);
      setSuccessGetImage(true);
      setDataImage(image);
      return image;
    } catch (error) {
      console.log(error);
      setLoadingGetImage(false);
      setErrorGetImage(error);
      throw error; 
    }
  };

  return {
    getImagesbyId,
    loadingGetImage,
    successGetImage,
    errorGetImage,
    dataImage,
  };
};

export default useGetImage;
