import React from 'react';

interface IFieldsetContainer {
  legend: string,
  children: any,
  error?: boolean
  disabled?: boolean
}

const FieldsetContainer: React.FC<IFieldsetContainer> = ({ legend, children, error, disabled }) => {
  return (
    <fieldset className={`fieldset-container ${error ? 'error' : ''}`} disabled={disabled}>
      <div className="container">
        <div className="row">
          <legend className="col-12">
            <span className="legend-text">{legend}</span>
          </legend>
          {children}
        </div>
      </div>
    </fieldset>
  )
}

export default FieldsetContainer;