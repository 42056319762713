import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

// MOVE TO SOLIMAT-API
interface ITransactionForms {
  id:guid,
  form_id:guid,
  transaction_id:guid,
  name: string,
  description:string
}

export interface IDetailTransactionResponse {
  name: string,
  description: string,
  transaction_forms: ITransactionForms[],
  is_request: boolean
}

const useGetDetailTransaction = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);

  const [detailTransaction, setDetailTransaction] = useState<IDetailTransactionResponse|null>(null)
  const [loadingGetDetailTransaction, setLoadingGetDetailTransaction] = useState(false);
  const [successGetDetailTransaction, setSuccessGetDetailTransaction] = useState(false);
  const [errorGetDetailTransaction, setErrorGetDetailTransaction] = useState<any>(null);

  const getDetailTransaction = (id:guid, _cb?:(data:IDetailTransactionResponse)=>void) => {
    setLoadingGetDetailTransaction(true);
    setErrorGetDetailTransaction(null);
    apiFormsIngestion
      .getDetailTransaction(id)
      .then((res: any) => {
        setSuccessGetDetailTransaction(true);
        setLoadingGetDetailTransaction(false);
        setErrorGetDetailTransaction(false)
        if(res.status === 204) {
          setErrorGetDetailTransaction(true);
        } else {
          setDetailTransaction(res.data);
        }
        if (typeof _cb === "function") {
          _cb(res);
        }
      })
      .catch((err:any) => {
        console.error("getDetailTransaction error: ", err);
        setErrorGetDetailTransaction(err);
        setLoadingGetDetailTransaction(false);
      });
  };

  return {
    detailTransaction,
    getDetailTransaction,
    successGetDetailTransaction,
    loadingGetDetailTransaction,
    errorGetDetailTransaction
  };
};

export default useGetDetailTransaction;