import { StatusDto } from "@solimat/solimat-web-endpoint/lib/appointment/dto/Appointment/StatusDto";

const useCheckStatusAppointment = () => {

  const checkStatusAppointment = (status: StatusDto) => {
    switch (status) {
      case StatusDto.Scheduled:
        return "Programada";
      case StatusDto.Done:
        return "Realizada";
      case StatusDto.Modified:
        return "Modificada";
      case StatusDto.Canceled:
        return "Cancelada";
      default:
        return "";
    }
  };

  return {
    checkStatusAppointment
  }
}

export default useCheckStatusAppointment;
