import {useState} from 'react';

import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetCentersByProvince = () => {
  const BASE_URL = process.env.REACT_APP_RED_CENTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_RED_CENTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY)
  const [successGetCentersByProvince, setSuccessGetCentersByProvince] = useState<boolean>(false)
  const [errorGetCentersByProvince, setErrorGetCentersByProvince] = useState<boolean>(false)
  const [loadingGetCenterByProvince, setLoadingGetCentersByProvince] = useState<boolean>(false)



  const getCentersByProvince = async (provice:string) => {
    setLoadingGetCentersByProvince(true)
    try {
      const response = await apiUtils.getCentersByProvince(provice);
      setSuccessGetCentersByProvince(true)
      setErrorGetCentersByProvince(false)
      setLoadingGetCentersByProvince(false)

      return response.data;
    }
    catch(err) {
      console.error(err);
      setLoadingGetCentersByProvince(false)
      setSuccessGetCentersByProvince(false)
      setErrorGetCentersByProvince(true)
    }
  }

  return { 
    getCentersByProvince,
    successGetCentersByProvince,
    errorGetCentersByProvince,
    loadingGetCenterByProvince
  };
}

export default useGetCentersByProvince;