const useDateFormat = () => {
  const dateFormatLocaleShortStr = (date:Date|null) => {
    if(!date) return null;
    return new Date(date).toLocaleDateString('es-ES',{ year: "numeric", month: "2-digit", day: "2-digit" }).substring(0, 10).replaceAll('/','-')
  }
  const dateFormatLocaleISOStr = (date:Date|null) => {
    if(!date) return null;
    const utcSeconds=new Date(date).getTime()/1000
    const isoLocal=new Date(utcSeconds*1000-new Date().getTimezoneOffset()*60000).toISOString().substring(0,16)
    if(!isoLocal) return null;
    return isoLocal;
  }
  return {
    dateFormatLocaleShortStr,
    dateFormatLocaleISOStr
  }
}

export default useDateFormat;