import React, { useEffect } from "react";
// import useMsalB2C from "hooks/useMsalB2C";
import * as msal from "@azure/msal-browser";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { useMsal, MsalContext, useIsAuthenticated } from "@azure/msal-react";
import useMsalB2C, { msalImpConfig, MSAL_STORAGE_KEYS, MSAL_IMP_PARAMS } from "hooks/useMsalB2C";
import { setToken } from "hooks/useSetToken";
import Loader from "components/blocks/Loader/Loader";
const Internal: React.FC = (props: any) => {

    const params: URLSearchParams = new URLSearchParams(props.location.search);
    const states: URLSearchParams = new URLSearchParams(props.location.hash);

    localStorage.setItem(MSAL_STORAGE_KEYS.CONFIG, JSON.stringify(msalImpConfig));

    const scopes = ["openid", 'profile', 'offline_access'];
    const { msalInstance } = useMsalB2C();
    const { inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    let allParamsOk = false;
    let hasErrors = false;
    let msalError = '';
    let flowEnded = false;
    if (params.has(MSAL_IMP_PARAMS.APP) && params.has(MSAL_IMP_PARAMS.TARGET) && params.has(MSAL_IMP_PARAMS.ID) && params.has(MSAL_IMP_PARAMS.TS)) {
        allParamsOk = true;
    }
    if (states.has("#error") || states.has("error") || states.has("error_description")) {
        hasErrors = true;
        msalError = states.get("error_description");
    }


    useEffect(() => {
        checkLoginAuthResult();
        setLoginProcess();
    }, [isAuthenticated, inProgress]);

    const checkLoginAuthResult = () => {
        if (localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE)) {
            if (localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE) == EventType.LOGIN_SUCCESS) {
                flowEnded = true;
                console.log(EventType.LOGIN_SUCCESS);
                localStorage.clear();
                setToken();
                props.history.push('/inicio');
            }
            if (localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE) == EventType.LOGIN_FAILURE) {
                hasErrors = true;
                msalError = localStorage.getItem(MSAL_STORAGE_KEYS.ERROR);
                flowEnded = true;
            }
            if (!flowEnded)
                setTimeout(checkLoginAuthResult, 1000);
        }
    }
    const setLoginProcess: () => Promise<void> = async () => {
        if (!isAuthenticated) {
            if (inProgress === InteractionStatus.None) {
                if (accounts.length == 0) {
                    let req: msal.RedirectRequest = {
                        scopes: scopes,
                        extraQueryParameters: {
                            app: params.get(MSAL_IMP_PARAMS.APP),
                            targetUsername: params.get(MSAL_IMP_PARAMS.TARGET),
                            impersonatorId: params.get(MSAL_IMP_PARAMS.ID),
                            ts: encodeURIComponent(params.get(MSAL_IMP_PARAMS.TS)),
                            ui_locales: 'es',
                        }
                    }

                    if (!localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE) && allParamsOk) {
                        localStorage.setItem(MSAL_STORAGE_KEYS.INSTANCE, EventType.LOGIN_START);
                        await msalInstance.loginRedirect(req);
                    } else {
                        // setTimeout(() => {
                        //     checkLoginAuthResult();
                        // }, 1000);
                    }
                }
            }
        }

        if (isAuthenticated) {
            console.log("isAuthenticated");
        }
    }


    const inLoginProgress = (!flowEnded) && (!localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE) ||
        localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE) == EventType.LOGIN_START);

    // const messageParams = allParamsOk && inLoginProgress ? <div></div> : <div>ERROR FALTAN PARAMETROS</div>;

    const messageError = hasErrors ? <div> {msalError}</div> : <div></div>;

    const loading = inLoginProgress && !msalError
    return (
        <>
            <div>Internal login</div>
            <Loader activeLoader={loading} />
            {/* {messageParams} */}
            {messageError}
        </>
    )
}
export default Internal;