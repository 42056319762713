import React, { useState } from "react";
import Moment from 'moment';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  PageSettingsModel,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-grids";
import "./grids.scss";
interface IRecordDetailVisitGrid {
  arrData: any[] | undefined;
  props: any;
  type_id: number;
  recordType: string;
  contingencyType: number;
}

const RecordDetailVisitGrid: React.FC<IRecordDetailVisitGrid> = ({
  arrData,
  props,
  type_id,
  recordType,
  contingencyType,
}) => {
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const recordId = arrParamsId[1]
  const typeRecordDetailVisitGridRrow: string =
    recordType === "1"
      ? "row-bright"
      : recordType === "2 "
      ? "row-dark"
      : recordType === "3"
      ? "row-clear"
      : "azul";
      const [pageOptions2] = useState<PageSettingsModel>({
        pageSize: 3,
        pageSizes: ["3", "10", "15", "20", "Todos"],
      });

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });
  const [sortingOptions] = useState<Object>({
    columns: [{ field: "FechaHoraInicio", direction: "Descending" }],
  });
  const [sortingOptions2] = useState<Object>({
    columns: [{ field: "fechaHoraFinSesion", direction: "Descending" }],
  });

  const estadoValue = function(field:any, data:any, column:any) {

  return (data[field] === 0) ? "Realizada" : "Cancelada";

}

  const rowSelected = (args: any) => {
    if (args != null) {
      let idRow = args.data.id;
      let eventTypeRow = type_id;
      let redirectPath = `/historial/${recordType}_${recordId}_${contingencyType}/informacion-asistencial/${eventTypeRow}/${idRow}`;
      props.history.push({
        pathname: redirectPath,
        origin: "historial",
        contingencyType: contingencyType,
      });
    }
  };
  const rowSelectedReha = (args: any) => {
    if (args != null) {
      let idRow = args.data.id;
      let episodeId = args.data.episodioId
      let idSesion = args.data.id
      let eventTypeRow = type_id;
      let redirectPath = `/historial/${recordType}_${recordId}_${contingencyType}/informacion-asistencial/${eventTypeRow}/${idSesion}`;
      props.history.push({
        pathname: redirectPath,
        origin: "historial",
        contingencyType: contingencyType,
        idSesion:idSesion,
        episodeId: episodeId
      });
    }
  };
  const customAttributes = { class: 'customcss' };
  // const setNewData = (arrData: any[]) => {
  //   const newArray: any[] = [];
  //   const FechaHoraInicioP = "2023-06-15T15:41:27.7260737"
  //   arrData.forEach((data) => {
  //       data.FechaHoraInicio2 = Moment(FechaHoraInicioP).format("LT") 
  //       newArray.push(data)
  //     }
    
  //   )
  
  //   return newArray;
    
  // };

  // const data = setNewData(arrData);

  // console.log('data55555555555555',data)
  // Emergencias
  if (type_id === 206) {
    return (
      <div
        className={`${typeRecordDetailVisitGridRrow} record-detail-visit-grid_row `}
      >
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            {/* <ColumnDirective field="estado" headerText="Estado" width="100" /> */}
            <ColumnDirective
              field="servicioDescripcion"
              headerText="Descripción"
              width="100"
            />
            <ColumnDirective
              field="especialidad"
              headerText="Especialidad"
              width="120"
            />
            <ColumnDirective
              field="FechaHoraInicio"
              headerText="Fecha y Hora"
              type="date"
              format="dd/MM/yyyy HH:mm"
              width="120"
            />
            <ColumnDirective
              field="nombreFacultativo"
              headerText="Medico"
              width="120"
            />
            <ColumnDirective
              field="centro.descripcion"
              headerText="Centro"
              width="120"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>

        
      </div>
    );
  }
  // CONSULTAS
  if (type_id === 200) {
    return (
      <div className={`${typeRecordDetailVisitGridRrow}`}>
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective >
            {/* <ColumnDirective field="estado" headerText="Estado" width="80" /> */}
            <ColumnDirective
              field="especialidad"
              headerText="Especialidad"
              width="80"
            />
            <ColumnDirective
              field="FechaHoraInicio"
              headerText="Fecha y Hora"
              type="date"
              format="dd/MM/yyyy HH:mm"
              width="100"
            />
            <ColumnDirective
              field="nombreFacultativo"
              headerText="Medico"
              width="120"
            />
            <ColumnDirective
              field="centro.descripcion"
              headerText="Centro"
              width="130"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </div>
    );
  }
  // REHABILITACION
  if (type_id === 205 && arrData.length > 0 ) 
  {
    let count = 1
   const datae = arrData.map((item, i) => (
      <div key={i} className='episode-rehab'>
     
        <h4 className="title-epidode-reha">Episodio Rehabilitación {count+i}</h4>
         <div className={`${typeRecordDetailVisitGridRrow}`}  key={i}>
           <GridComponent
             dataSource={[item]}
            //  allowPaging={arrData.length >= 5 ? true : false}
             allowSorting={false}
            //  rowSelected={rowSelected}
            //  pageSettings={pageOptions}
            //  sortSettings={sortingOptions}
           >
             <ColumnsDirective>
             
               <ColumnDirective
                 field="numeroSesionesSolicitadas"
                 headerText="Sesiones Solicitadas"
                 width="70"
                 customAttributes= {customAttributes}
               />
               <ColumnDirective
                 field="numeroSesionesRealizadas"
                 headerText="Sesiones Finalizadas"
                 width="70"
                 customAttributes= {customAttributes}
               />
 
               {/* <ColumnDirective
                 field="especialidad"
                 headerText="Especialidad"
                 width="80"
               /> */}
               <ColumnDirective
               field="FechaHoraInicio"
               headerText="Fecha y Hora"
               type="date"
               format="dd/MM/yyyy HH:mm"
               width="100"
               customAttributes= {customAttributes}
             />
             </ColumnsDirective>
             <Inject services={[Page, Sort]} />
           </GridComponent>
         </div>
         <br />
         <div className={`${typeRecordDetailVisitGridRrow}`}>
           <GridComponent
             dataSource={item.Sesiones}
             allowPaging={true}
             allowSorting={false}
             rowSelected={rowSelectedReha}
             pageSettings={pageOptions2}
             sortSettings={sortingOptions2}
             key={i}
           >
             <ColumnsDirective>
               <ColumnDirective
                 field="numeroSerie"
                 headerText="Nº Serie"
                 width="70"
               />

               <ColumnDirective
                 field="estado"
                 headerText="Estado"
                 width="100"
                 valueAccessor={estadoValue}
               />
 
               <ColumnDirective
                 field="fechaHoraInicioSesion"
                 headerText="Fecha y Hora"
                 type="date"
                 format="dd/MM/yyyy HH:mm"
                 width="100"
               />
               <ColumnDirective
                 field="nombreAsistencial"
                 headerText="Profesional"
                 width="100"
               />
                <ColumnDirective
                 field="centro.descripcion"
                 headerText="Centro"
                 width="100"
               />
               <ColumnDirective
                 field="observaciones"
                 headerText="Comentarios"
                 width="100"
               />
             </ColumnsDirective>
             <Inject services={[Page, Sort]} />
           </GridComponent>
         </div>
       
       </div>
     ));
     return (<>{datae}</>)
  }
  
  
  // QUIROFANO
  if (type_id === 204) {
    return (
      <div className={`${typeRecordDetailVisitGridRrow}`}>
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            {/* <ColumnDirective field="estado" headerText="Estado" width="100" /> */}
            <ColumnDirective
              field="especialidad"
              headerText="Especialidad"
              width="100"
            />
            <ColumnDirective
              field="FechaHoraInicio"
              headerText="Fecha y Hora"
              type="date"
              format="dd/MM/yyyy HH:mm"
              width="100"
            />
            <ColumnDirective field="cirujano" headerText="Medico" width="100" />
            <ColumnDirective
              field="centro.descripcion"
              headerText="Centro"
              width="100"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </div>
    );
  }
  //HOSPITALIZACION
  if (type_id === 202) {
    return (
      <div
        className={`${typeRecordDetailVisitGridRrow} record-detail-visit-grid_row `}
      >
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            {/* <ColumnDirective field="estado" headerText="Estado" width="100" /> */}
            <ColumnDirective
              field="diasEstancia"
              headerText="Dias Estancia"
              width="100"
            />
            <ColumnDirective
              field="especialidad"
              headerText="Especialidad"
              width="120"
            />
            <ColumnDirective
              field="FechaHoraInicio"
              headerText="Fecha y Hora"
              type="date"
              format="dd/MM/yyyy HH:mm"
              width="120"
            />
            <ColumnDirective
              field="medicoResponsable"
              headerText="Medico"
              width="120"
            />
            <ColumnDirective
              field="centro.descripcion"
              headerText="Centro"
              width="120"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </div>
    );
  }
// IMAGEN
  if (type_id === 203) {
    return (
      <div
        className={`${typeRecordDetailVisitGridRrow} record-detail-visit-grid_row `}
      >
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            {/* <ColumnDirective field="estado" headerText="Estado" width="100" /> */}
            <ColumnDirective
              field="Titulo"
              headerText="Titulo"
              width="100"
            />
            {/* <ColumnDirective
              field="especialidad"
              headerText="Especialidad"
              width="120"
            /> */}
            <ColumnDirective
              field="FechaHoraInicio"
              headerText="Fecha y Hora"
              type="date"
              format="dd/MM/yyyy HH:mm"
              width="120"
            />
            <ColumnDirective
              field="nombreFacultativo"
              headerText="Medico"
              width="120"
            />
            <ColumnDirective
              field="centro.descripcion"
              headerText="Centro"
              width="120"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </div>
    );
  }
};

export default RecordDetailVisitGrid;
