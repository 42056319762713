
import React, {useState, useEffect} from "react";
import Swiper from 'react-id-swiper';
// import { news } from "@solimat/solimat-web-endpoint";
import { INewsResponse } from "@solimat/solimat-web-endpoint/lib/news/dto";

import Modal from "../Modal/Modal";
import useModal from '../../../hooks/useModal';
// css
import './news.scss';

const ReactMarkdown = require('react-markdown');
// const BASE_URL = process.env.REACT_APP_NOTICIAS_PATH || "";
// const FUNCTIONS_KEY = process.env.REACT_APP_NEWS_KEY || "";
// const apiNews = new news.NewsService(BASE_URL, FUNCTIONS_KEY);

const NewsComponent: React.FC = (props: any) => {

  const [news, setNews] = useState<INewsResponse[]>([]);
  const {
    collapsed,
    handleOpenModal,
    handleCloseModal
  } = useModal();

  let params: any = { // swiper params
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    noSwiping: news.length<2,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    spaceBetween: 30
  }

  useEffect(() => {
    // apiNews.getAllNewsByUser()
    // .then((res: any) => {
    //   let newsList = res.data as INewsResponse[]
    //   setNews(newsList);
    //   if(newsList != null && newsList.length > 0) {
    //     handleOpenModal();
    //   }
    // })
    // .catch((ex : any) => console.log(ex))
  }, []);

  const handleCheckNewsType = (type:number) => {
    switch(type) {
      case 0:
        return "news";
      case 1:
        return "notice";
    }
  }


  return (
    <>
    {news.length>0 && 
      <Modal collapsed={collapsed} onClose={handleCloseModal}>
        <>
          <div className={`swiper-box-container ${news.length<2?"disabled-bullets":''}`}>
            <Swiper {...params}>
              {news.map((item:any) => {
                return (
                  <div key={item.id}>
                  <div className={`news-icon ${handleCheckNewsType(item.type)}`}></div>
                    <div className="swiper-content">
                      <ReactMarkdown source={item.text}/>
                      {/* <h3 className="title">{item.title}</h3>
                      <p className="text">{item.text}</p> */}
                    </div>
                  </div>
                )
              })}
            </Swiper>
          </div>
            <div className="btns-container right">
              <button 
                className="btn-box btn-clear"
                onClick={handleCloseModal}
              >
                Entendido
              </button>
            </div>
          </>
      </Modal>
    }
    </>
  )
}

export default NewsComponent;