import React, { useState } from "react";
// import { Shortener } from "./index"
import Shortener from "@solimat/solimat-web-endpoint/lib/shortener";

const useGetShortener = () => {
  const [loadingGetShortenerPage, setLoadingGetShortenerPage] = useState(false);
  const [successGetShortenerPage, setSuccessGetShortenerPage] = useState(false);
  const [errorGetShortenerPage, setErrorGetShortenerPage] = useState(null);
  const [shortenerPage, setShortenerPage] = useState<null | any>(null);

  const BASE_URL = process.env.REACT_APP_ACORTADOR_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_ACORTADOR_KEY || "";
  const apiShortener = new Shortener.ShortenerService(BASE_URL, FUNCTIONS_KEY);

  const getShortenerOriginalUrl = (_url: string): Promise<string> => {
    setLoadingGetShortenerPage(true);
    return apiShortener
      .getOriginalUrl(_url)
      .then((res: any) => {
        setLoadingGetShortenerPage(false);
        setSuccessGetShortenerPage(true);
        setShortenerPage(res.data);
        return res.data;
      })
      .catch((err: any) => {
        setLoadingGetShortenerPage(false);
        setErrorGetShortenerPage(err);
        return "";
      });
  };

  return {
    shortenerPage,
    getShortenerOriginalUrl,
    loadingGetShortenerPage,
    successGetShortenerPage,
    errorGetShortenerPage,
  };
};

export default useGetShortener;
