import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { CompleteRATSBResponse } from "@solimat/solimat-web-endpoint/lib/Delta/dto";

// blocks
import Modal from "components/blocks/Modal/Modal";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import PrintButton from "../../blocks/Delta/Buttons/PrintButton";
// import Modal from 'components/pages/Modal/Modal';
import GridAccidentRelation from "components/blocks/Grids/GridAccidentRelation";
import { TextMessagesTemplates } from "components/blocks/TextMessagesTemplates/TextMessagesTemplates";
// context
// services
import useAllRatsb from "services/delta/ratsb/useAllRatsb";
// hooks
import useDownloadFile from "hooks/useDownloadFile";
import useModal from "hooks/useModal";
import useDateFormat from "hooks/useDateFormat";
import logo from "../../../img/solimat-logo-blanco.svg";

// data
import {
  errorsValidationDeltaWithoutLeave,
  findNameByDbKeyError,
  createNewerrorsObject,
} from "./delta.data";
// images
import IMGcheck from "img/icons/symbols/check-confirm.png";
import IMGnotice from "img/icons/symbols/notice.png";
import DeltaWithoutLeaveDetailForm from "components/blocks/Delta/Forms/DeltaWithoutLeaveDetailForm";
import Loader from "components/blocks/Loader/Loader";
import { MasterDataContext } from "components/pages/Delta/Delta";
import masterDataUtils from "services/utils/masterDataUtils";
import Moment from "moment";
import GridAccidentRelationToPrint from "components/blocks/Grids/GridAccidentRelationToPrint";
interface footer {
  acceptation_date: null;
  authority_code: 0;
  broadcast_date: null;
  complete_name: null;
  id: "b66e0ff3-fe47-405e-e68d-08d8e91d90cf";
  province: null;
  quality_of: null;
  reception_date: "2021-03-17T08:23:49.563";
  rejection: "";
}

interface IErrorValidation {
  name: string;
  message: string;
}
const DeltaWithoutLeaveDetail: React.FC = (props: any) => {
  const deltaId = props.match.params.deltaId;
  const location = useLocation();
  const dateFormatES = "DD/MM/YYYY";
  const formMethods = useForm();
  const { handleSubmit, reset, watch, register, setValue, getValues } =
    formMethods;
  const { dateFormatLocaleShortStr, dateFormatLocaleISOStr } = useDateFormat();
  const watchProvinceCode = watch("province");
  const watchProvinceDescription = watch("province_description");
  const watchCommunityCode = watch("communityCode");
  const watchCommunityDescription = watch("communityDescription");
  const watchCommunity = watch("community");
  const watchCnaeCode = watch("cnaeContributionAtEpCode");
  const [completeRatsbByUser, setCompleteRatsbByUser] =
    useState<null | CompleteRATSBResponse>(null);
  const [accidents, setAccidents] = useState<null | any>(null);
  const [loadingAccidents, setLoadingAccidents] = useState(false);
  const [errorAccidents, setErrorAccidents] = useState(false);
  const [validationPropertyResult, setValidationPropertyResult] =
    useState<any>(null);
  const [errorsValidationProcess, setErrorsValidationProcess] =
    useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loadingValidationProcess, setLoadingValidationProcess] =
    useState<boolean>(false);
  const [successValidation, setSuccessValidation] = useState<boolean>(false);

  const [errors, setErrors] = useState<any | null>(null); // errors inputs

  const [state, setState] = useState(); // TO REVIEW: Provisional state Force to render and watchAllFiles
  const handleChange = (e: any) => {
    setState(e.value);
  };
  const { collapsed, handleOpenModal, handleCloseModal } = useModal();
  const { downloadXML } = useDownloadFile();
  const estado = completeRatsbByUser?.Estado;
  const remesed = estado >= 3 ? true : false;
  const formRef = useRef<HTMLDivElement | null>(null);
  const [showComponent, setShowComponent] = useState(false);

  const [masterData, setMasterData] = useState<any>({
    init: true,
    type: "RATSB",
  });
  const {
    getCompleteRatsbByUser,
    validateRatsb,
    updateRatsb,
  } = useAllRatsb();
  const title = location?.pathname.includes("remesados")
    ? "Accidentes sin baja Delta-remesados "
    : "Accidentes sin baja pendientes de Delta";

  const breadcrumbs = ["unidad delta", `${title}`, "Detalle"];
  const { clearSessionStoragekeys } = masterDataUtils(MasterDataContext);
  useEffect(() => {
    handleGetCompleteRatsbByUser();
  }, []);
  const toggleComponentVisibility = (show: boolean) => {
    setShowComponent(show);
  };
  useEffect(() => {
    if (!completeRatsbByUser?.Id) return;
    // handleGetAccidentsByRatsb(completeRatsbByUser?.Id)
  }, [completeRatsbByUser]);

  const handleGetCompleteRatsbByUser = async () => {
    setLoading(true);
    const response = await getCompleteRatsbByUser(deltaId);
    clearSessionStoragekeys();
    if (response) {
      setCompleteRatsbByUser(response);
      const monthDescription: any = monthsData.find(
        (item: any) =>
          item.Month.code === response.Cabecera?.Mes.toString() || ""
      );
      reset({
        numReference: response.NumeroReferencia,
        month: monthDescription?.Month.name || "",
        year: response?.Cabecera?.Anyo || "",
        cif: response.Cabecera?.CifNif || "",
        reason: response.Cabecera?.Razon || "", // social name
        ccc: response.Cabecera?.CCC || "",
        naf: response.Cabecera?.NAF || "",
        workforce: '0',//response.Cabecera?.Plantilla || "", // workforce
        province: response.Cabecera?.ProvinciaCodigo || "",
        communityCode: response.Cabecera?.Municipio || "",
        cnaeContributionAtEpCode: response.Cabecera?.CnaeCodigo || "",
        cnaeContributionAtEpDescription:
          response.Cabecera?.CnaeDescripcion || "",
        textMainEconomicActivityCode: response?.Cabecera?.CnaeTexto || "",
        representativeCompleteName: response.Pie?.FEmpresaNombreApellidos || "",
        representative: response.Pie?.FEmpresaCalidadDe || "",
        representativeLocation:
          response.Pie?.FEmpresaProvinciaDescripcion || "",
        acceptation_date: dateFormatLocaleShortStr(
          response.Pie?.EGCFechaAceptacion
        ),
        reception_date: dateFormatLocaleShortStr(
          response.Pie?.ALFechaRecepcion
        ),
        submissionDate: dateFormatLocaleShortStr(
          response.Pie?.FEmpresaFechaPresenta
        ),
        // accidentadosNombreApellidos:response.Accidentados[0].NombreApellidos,
        // accidentadosNombreApellidos:response.Accidentados[0].NombreApellidos
      });
    } else {
      // SHOW ERROR
      setError(true);
    }
    setLoading(false);
  };

  const submitForm = (values: any) => {
    // console.log(values)
  };

  // useEffect(()=> {
  //   handleSetPageMenu("Detalle", ["Unidad Delta","Accidentes sin baja pendientes de Delta", "Detalle"])
  // },[])

  // const handleGetAccidentsByRatsb = async(ratsbId:any) => {
  //   setLoadingAccidents(true);
  //   const response = await getAccidentsByRatsb(ratsbId);
  //   if(response) {
  //     setAccidents(response);
  //     setLoadingAccidents(false);
  //   } else {
  //     setLoadingAccidents(false);
  //     setErrorAccidents(true);
  //   }

  // }

  const monthsData: { [key: string]: Object }[] = [
    { Month: { name: "Enero", code: "1" } },
    { Month: { name: "Febrero", code: "2" } },
    { Month: { name: "Marzo", code: "3" } },
    { Month: { name: "Abril", code: "4" } },
    { Month: { name: "Mayo", code: "5" } },
    { Month: { name: "Junio", code: "6" } },
    { Month: { name: "Julio", code: "7" } },
    { Month: { name: "Agosto", code: "8" } },
    { Month: { name: "Septiembre", code: "9" } },
    { Month: { name: "Octubre", code: "10" } },
    { Month: { name: "Noviembre", code: "11" } },
    { Month: { name: "Diciembre", code: "12" } },
  ];
  const handleVerify = async () => {
    handleOpenModal();
    setLoadingValidationProcess(true);
    setErrorsValidationProcess(false);
    const values = getValues();
    const monthCode: any = monthsData.find(
      (item: any) => item.Month.name === values.month
    );

    const updateRequest: any = {
      id: completeRatsbByUser?.Id || null,
      nombre: completeRatsbByUser?.Nombre || null,
      // numReference: completeRatsbByUser?.header.num_reference ||'',
      cabecera: {
        mes: values.month || null,
        anyo: values.year || null,
        id: completeRatsbByUser?.Cabecera.Id || null,
        // EGC: completeRatsbByUser?.Cabecera?.EGC || '', // Mutua = 1, Aseguradora, Gestora
        razon: values.reason || null, //reason
        ccc: values.ccc || null,
        naf: values.naf || null,
        provinciaCodigo: values.province || null,
        municipio: values.communityCode || null,
        plantilla: values.workforce || null,
        cnaeDescripcion: values.cnaeContributionAtEpDescription || null,
        cnaeTexto: values.textMainEconomicActivityCode || null,
        cnaeCodigo: values.cnaeContributionAtEpCode || null,
        provinciaDescripcion: values.province_description,
        municipioDescripcion: values.communityDescription || null,
      },
      pie: {
        fEmpresaNombreApellidos: values.representativeCompleteName || null,
        fEmpresaCalidadDe: values.representative || null,
        FEmpresaProvinciaDescripcion: values.representativeLocation || null,
        fEmpresaFechaPresenta:
          values.submissionDate &&
          Moment(values.submissionDate, dateFormatES).isValid()
            ? Moment.utc(values.submissionDate, dateFormatES).toISOString()
            : Moment().toISOString(), // date // NO NULL
        id: completeRatsbByUser?.Pie.Id || null,
      },
    };

    let createFooterRequest: any = {
      completeName: values.representativeCompleteName,
      qualityOf: values.representative,
      province: values.representativeLocation,
      broadcastDate: dateFormatLocaleISOStr(values.acceptation_date),
      acceptationDate: dateFormatLocaleISOStr(values.reception_date),
      authorityCode: 0, //
      receptionDate: dateFormatLocaleISOStr(values.reception_date), //
      rejection: "", //
      ratsbId: completeRatsbByUser?.Id || "",
    };

    // if(completeRatsbByUser?.Pie.Id === null) {
    //   const responseCreateFooter = await createFooter(createFooterRequest);
    //   if(responseCreateFooter) {
    //     updateFooterRequest.id = responseCreateFooter
    //     delete updateFooterRequest.ratsbId;
    //   } else {
    //     // console.log('MOSTRAR ERRORES CREATE');
    //     // SHOW ERROR
    //     setErrorsValidationProcess(true);
    //     return;
    //   }
    // }

    setErrors(null);
    const responseUpdate = await Promise.all([updateRatsb(updateRequest)])
      .then(() => true)
      .catch((err: any) => false);
    if (responseUpdate) {
      const response = await validateRatsb(completeRatsbByUser?.Id);
      setLoadingValidationProcess(false);
      if (!response?.Result) {
        if (response?.PropertyResult) {
          handleCloseModal();
          setValidationPropertyResult(response?.PropertyResult);
          setErrorsValidationProcess(true);
        } else {
          setErrorsValidationProcess(true);
          setValidationPropertyResult(null);
        }
      } else {
        setSuccessValidation(true);
        setValidationPropertyResult(null);
        // const response = await exportRatsb(completeRatsbByUser?.Id||'');
        // setLoadingValidationProcess(false);
        // if(response) {
        //   downloadXML(response,'informe_delta');
        //   setErrorsValidationProcess(false);
        // } else {
        //   // SHOW ERROR Export Ratsb
        //   setErrorsValidationProcess(true);
        // }
      }
    } else {
      // SHOW ERROR
      setErrorsValidationProcess(true);
    }
    setLoadingValidationProcess(false);
  };
  const arraynew = completeRatsbByUser?.Accidentados;
  const newErrosData = createNewerrorsObject(
    arraynew,
    errorsValidationDeltaWithoutLeave
  );
  useEffect(() => {
    if (!validationPropertyResult) {
      return setErrors({});
    } else {
      let objError: any = {};
      const formValidationData = [...newErrosData];
      for (const propertyResult of validationPropertyResult) {
        const isProperty = formValidationData.find(
          (item: any) => item.dbKey === propertyResult?.Item1
        );

        if (!isProperty) {
          if (!objError["noDbKey"]) {
            objError["noDbKey"] = {
              messages: [],
              message: "",
            };
          }
          objError["noDbKey"].messages.push(propertyResult?.Item2);
          objError["noDbKey"].message += propertyResult?.Item2;
        } else {
          if (
            !objError[findNameByDbKeyError(propertyResult?.Item1, formValidationData)]
          ) {
            objError[
              findNameByDbKeyError(propertyResult?.Item1, formValidationData)
            ] = {
              ...isProperty,
              messages: [],
            };
          }
          objError[
            findNameByDbKeyError(propertyResult?.Item1, formValidationData)
          ].messages.push(propertyResult?.Item2);
          objError[
            findNameByDbKeyError(propertyResult?.Item1, formValidationData)
          ].message += propertyResult?.Item2;
        }
      }
      setErrors((prevState: any) => {
        return { ...objError };
      });
      window.scrollTo(0, 0);
    }
  }, [validationPropertyResult]);

  if (error) {
    return <section>{TextMessagesTemplates.errorResponse}</section>;
  }

  const handleMasterDataChange = (key: string, value: any) => {
    var newObj = { ...masterData, [key]: value };
    setMasterData(newObj);
  };

  return (
    <MasterDataContext.Provider value={{ masterData, handleMasterDataChange }}>
      {showComponent && (
        <Loader activeLoader={true} label="Descargando formulario" />
      )}
      <PageMenu title={title} breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to="/unidad-delta">
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <section ref={formRef} id="formDeltawithoutleave">
        <div className="container">
          {validationPropertyResult && validationPropertyResult ? (
            <div className="row mb-4">
              <div className="col-12">
                <div className="container">
                  <h1 className="title-errors">Errores de Validación</h1>
                </div>
                <ul className="form-erros-validation">
                  {validationPropertyResult?.map(
                    (error: any, index: number) => {
                      const errorItemID = error?.Item1.includes("accident")
                        ? "accidentsGrid"
                        : error?.Item1;
                      let nameByBbKey = findNameByDbKeyError(
                        errorItemID,
                        newErrosData
                      );
                      const errorItemMessage = error?.Item2;
                      if (errorItemID !== "") {
                        return (
                          <li key={index}>
                            <AnchorLink offset="32" href={`#${nameByBbKey}`}>
                              <span className="badge text-danger-li">
                                {errorItemMessage}
                              </span>
                            </AnchorLink>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index}>
                            <p>
                              <span>{errorItemMessage}</span>
                            </p>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          ) : null}
          {showComponent && (
          <>
            <div
              className="header-content"
              style={{
                backgroundColor: "#409CCE",
                display: "flex",
                alignItems: "center",
                height: "100px",
                paddingLeft: "20px",
                marginBottom: "20px",
              }}
            >
              <div className="logo-block">
                <div className="logo-container">
                  <img src={logo} className="logo-landingpage" alt="solimat" />
                </div>
              </div>
            </div>
          </>
          )}
          <FormProvider {...formMethods}>
            <DeltaWithoutLeaveDetailForm
              handleSubmit={handleSubmit}
              reset={reset}
              watch={watch}
              register={register}
              setValue={setValue}
              getValues={getValues}
              submitForm={submitForm}
              errors={errors}
              completeRatsbByUser={completeRatsbByUser}
              handleChange={handleChange}
              watchProvinceCode={watchProvinceCode}
              watchProvinceDescription={watchProvinceDescription}
              watchCnaeCode={watchCnaeCode}
              watchCommunityCode={watchCommunityCode}
              watchCommunityDescription={watchCommunityDescription}
              remesed={remesed}
            />
          </FormProvider>
        </div>
        {loadingAccidents || loading ? (
          <>
            <div className="container">
              <div className="row">
                <div className="col-12 mt-5 mb-4">
                  <h2 className="title-highlighted">
                    Relación de accidentados:
                  </h2>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-4">{TextMessagesTemplates.loading}</div>
          </>
        ) : (
          <>
            {!completeRatsbByUser?.Accidentados || showComponent ? null : (
              <div id="accidentsGrid">
                <GridAccidentRelation
                  dataDelta={completeRatsbByUser}
                  remesed={remesed}
                  errors={errors}
                  register={register}
                  completeRatsbId={completeRatsbByUser?.Id}
                  accidents={completeRatsbByUser?.Accidentados}
                  // handleGetAccidentsByRatsb={handleGetAccidentsByRatsb}
                  loadingAccidents={loadingAccidents || loading}
                  // errorAccidents={errorAccidents}
                  validationPropertyResult={validationPropertyResult}
                />
              </div>
            )}
          </>
        )}
        {showComponent && (
          <GridAccidentRelationToPrint
            data={completeRatsbByUser?.Accidentados}
            validationPropertyResult={validationPropertyResult}
            errors={errors}
          ></GridAccidentRelationToPrint>
        )}
        {!showComponent && (
          <div className="container mt-5">
            <div className="row">
              <div className="col-12">
                <div className="delta-buttons c-right">
                  <button
                    type="submit"
                    className={`btn-box btn-accent loading ${
                      loadingValidationProcess ? "active" : ""
                    }`}
                    disabled={
                      loadingValidationProcess ||
                      loadingAccidents ||
                      loading ||
                      remesed
                        ? true
                        : false
                    }
                    onClick={handleVerify}
                  >
                    <div className="loader-btn"></div>
                    {loadingValidationProcess ? "" : "Verificar"}
                  </button>
                  {completeRatsbByUser?.Estado <6 && 
                   <PrintButton
                   DomIdPrint={"formDeltawithoutleave"}
                   success={false}
                   formRef={formRef}
                   toggleComponentVisibility={toggleComponentVisibility}
                 />
                  }
                 
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Modal collapsed={collapsed}>
        <div className="confirm-message">
          <div
            className={`img-container ${
              loadingValidationProcess ? "box-img-loader" : ""
            }`}
          >
            {/* Procesando validación */}

            {loadingValidationProcess && (
              <div className={`box-loader active`}>
                <Loader
                  activeLoader
                  label="Validando datos del formulario"
                ></Loader>
              </div>
            )}
            {/* Success validation message */}
            {!loadingValidationProcess && !errorsValidationProcess && (
              <img src={IMGcheck} alt="" />
            )}
            {/* Error de servicio */}
            {errorsValidationProcess && !errors && (
              <img src={IMGnotice} alt="" />
            )}
          </div>
          {/* Procesando validación */}
          {/* {loadingValidationProcess && (
            <p className="loader-text loading">
              Estamos validando sus datos.
              <br />
              Por favor, no cierre la ventana
            </p>
          )} */}
          {/* Success validation message */}
          {!loadingValidationProcess && !errorsValidationProcess && (
            <p>Datos validados correctamente.</p>
          )}
          {/* Error de servicio */}
          {errorsValidationProcess && !errors && (
            <p>
              Lo sentimos, se ha producido un error.
              <br />
              Inténtelo de nuevo más tarde.
            </p>
          )}

          <div className="btn-resolve-container">
            {((!loadingValidationProcess && !errorsValidationProcess) ||
              (errorsValidationProcess && !errors)) && (
              <>
                <button
                  className="btn-box btn-accent"
                  onClick={handleCloseModal}
                >
                  Entendido
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </MasterDataContext.Provider>
  );
};

export default DeltaWithoutLeaveDetail;
