import React, { createContext, useState, useEffect, useCallback } from 'react';
import useSignalR from "../services/notifications/useSignalR";
import useGetAllNotificationsByUser from "../services/notifications/useGetAllNotificationsByUser";
import useDecode from "../hooks/useDecode";
import useGetRole, { ROLES } from 'hooks/useGetRole';
const SignalRContext = createContext();

const SignalRContextProvider = ({ children }) => {

  const {
    newNotification,
    connectionState,
    connectionHub,
    handleStartSignalR,
    handleDisconnectSignalR
  } = useSignalR();

  const [notification, setNotification] = useState({});
  const [allNotifications, setAllNotifications] = useState([]);
  const [isConnectedSignalR, setisConnectedSignalR] = useState(false)
  const [userRole, setUserRole] = useState('');
  const [changeRole, setChangeRole] = useState(false);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const [isUpdated, setIsUpdated] = useState(false)
  var roleSelected = tokenResponse && tokenResponse.extension_roles
  const { isImpersonation } = useGetRole;
  const {
    getAllUserNotifications,
    allUserNotifications,
    loadingGetAllUserNotifications,
  } = useGetAllNotificationsByUser();
const thereIsToken = localStorage.getItem('token')
  useEffect(() => {
    var rolePermision = "";
    if (typeof roleSelected === "string") {
      rolePermision = JSON.parse(roleSelected);
      let role2 = rolePermision[0];
      setUserRole(role2)
    }

    if (!isConnectedSignalR ) {
      handleStartSignalR(ROLES.PACIENTE);
      setisConnectedSignalR(true)

    }
    return () => {
      setUserRole('')
    }


  }, []);
  useEffect(() => {
      setNotification(newNotification)

  }, [isUpdated, newNotification,thereIsToken])

  useEffect(() => {

    if (changeRole && connectionState) {

      handleDisconnectSignalR(connectionHub);
      setChangeRole(false);
    } else if (changeRole && userRole === ROLES.PACIENTE) {
 
      setChangeRole(false);
    }

    return () => {
      setChangeRole(false);
    }
  }, [userRole, changeRole])

  const handleGetAllNotifications = () => {
    
    getAllUserNotifications(userId && userId);

  }


  const handleSetUserAndChangeRole = (userRole, change) => {
    setUserRole(userRole)
    setChangeRole(change)
  }

  const handleAbortSignalR = () => {

    handleDisconnectSignalR(connectionHub);
  }

  return (
    <SignalRContext.Provider value={{
      notification,
      allUserNotifications,
      allNotifications,
      loadingGetAllUserNotifications,
      getAllUserNotifications,
      handleGetAllNotifications,
      setUserRole,
      setChangeRole,
      handleSetUserAndChangeRole,
      handleAbortSignalR,
      setIsUpdated,
      isUpdated
    }}>
      {children}
    </SignalRContext.Provider>
  );
}

export { SignalRContext, SignalRContextProvider };