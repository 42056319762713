import React from "react";
import "./Modal.scss";
import { useHistory } from "react-router-dom";

export interface Imodal {
  collapsed: boolean;
  children: any;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
  disabledCloseButton?: boolean;
  onClick?: () => void;
  position?: number;
  classId?: string
 
}

const Modal: React.FC<Imodal> = ({
  collapsed,
  children,
  onClose,
  title,
  subtitle,
  disabledCloseButton,
  onClick,
  position,
  
}) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  return (
    <div
      className={`modal-wrapper ${collapsed ? "collapsed" : ""}`}
      onClick={onClick ? onClick : () => null}
      style={{ zIndex: position || 9999 }}
    >
      <div className="bg"></div>
      <div className="container-modal">
        <div className="modal_block">
          {/* currentPath !== '/inicio' &&  para quitar close de modal*/} 
          {onClose &&  currentPath !== '/inicio' &&(
            <div className="btn-close">
              <button
                onClick={onClose}
                disabled={disabledCloseButton || false}
              ></button>
            </div>
          )}
          <div className="modal-content-block">
            <div className="modal-header-block">
              {title && <h3 className="modal_title">{title}</h3>}
              {subtitle && <p className="modal_subtitle">{subtitle}</p>}
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
