import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Moment from "moment";
import "moment/locale/es";
import useDecode from "../../../hooks/useDecode";

import PageMenu from "../../blocks/PageMenu/PageMenu";
import Message from "../../blocks/Message/Message";
import Modal from "../../blocks/Modal/Modal";
import FormAnswerCommunications from "../../blocks/Forms/FormAnswerCommunication";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IconCommentGray from "../../../img/icons/svg/botones/comentarios-gris.svg";
import useGetUserChatCommunicationByIdAppointment from "../../../services/communications/useGetUserChatCommunicationByIdAppointment";

interface IvaluesFormReplyCommunication {
  subject: string;
  message: string;
  "loadfiles[]": any[];
}
interface IdataComunication {
  externalId: string;
  userId: string;
  personId: string;
  managerId: null | string;
  appointmentId: null | string;
  visitId: null | string;
  recordId: string;
  subject: string;
  message: string;
  files: any[];
}
const CommunicationChatAppointment: React.FC = (props: any) => {
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const appointmentId = arrParamsId[0];
  const communicationId = arrParamsId[1];
  const communicationSubject = props.history.location.subject;
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const [collapsed, setCollapsed] = useState(true);
  const [userTokenId, setUserTokenId] = useState("");

  const {
    errorGetUserChatCommunicationAppointment,
    loadingGetUserChatCommunicationAppointment,
    getUserChatCommunicationByIdAppointment,
    userChatCommunicationAppointment,
  } = useGetUserChatCommunicationByIdAppointment();


  const handleOpenModal = () => {
    setCollapsed(false);
  };
  const handleCloseModal = () => {
    setCollapsed(true);
  };
  const convertBase64 = async (files: any) => {
    var arrayAuxiliar: any = [];
    Array.from(files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          id: "61bd8783-0f25-48bd-87fc-3d1a16ca51bb",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };
  
  const onSubmitCommunication = async (values: any) => {
    const dataFiles = await convertBase64(values["loadfiles[]"]);
    //send communication
    setTimeout(() => {
      const newDataJsonRecord: IdataComunication = {
        externalId: "",
        userId: userId,
        personId: "",
        managerId: null,
        appointmentId: appointmentId,
        visitId: null,
        recordId: null,
        subject: values.subject,
        message: values.comment,
        files: dataFiles,
      };

      // createUserCommunicationRecord(appointmentId, newDataJsonRecord);
      setCollapsed(true);
    }, 700);

    // redirect
  };

  useEffect(() => {
    if (tokenResponse !== undefined) {
      setUserTokenId(tokenResponse.extension_id);
    }
    Moment().locale("es");
    getUserChatCommunicationByIdAppointment(userId, appointmentId);
  }, []);
  const [loadingData, setLoadingData] = useState(true);
  useEffect(() => {
    if (userChatCommunicationAppointment) {
      setLoadingData(false);
    }
  }, [userChatCommunicationAppointment]);

  const dataCommunicationChat =
  userChatCommunicationAppointment &&
  userChatCommunicationAppointment.filter(
      (communication: any) => communication.Id === communicationId
    );
  const subjectForAnswer =
    dataCommunicationChat && dataCommunicationChat[0]?.Subject;
  return (
    <>
      {loadingData ? (
        <section>{TextMessagesTemplates.loading}</section>
      ) : (
        <>
          <PageMenu
            title={'Comentario'}
            breadcrumbs={[
              "Mis Citas",
              "Comunicación",
            ]}
          >
            <div className="btns-header-container">
              {/* <button className="btn-text btn-gray" onClick={handleOpenModal}>
                <img src={IconCommentGray} alt="icon reply" />
                <span className="e-icons e-AddComment"></span>
                Responder
              </button> */}
              <a onClick={() => props.history.goBack()}>
                <img src={IconArrowGray} alt="icon arrow right" />
                {/* <span className="e-icons e-ArrowLeft"></span> */}
              </a>
            </div>
          </PageMenu>
          <div className="container">
            <div className="row">
              <div className="col">
                <section>
                  {userChatCommunicationAppointment && (
                    <Message
                      userChatCommunication={dataCommunicationChat}
                      userId={userTokenId}
                    />
                  )}
                </section>
              </div>
            </div>
          </div>

          <Modal collapsed={collapsed} onClose={handleCloseModal}>
            <>
              {!collapsed ? (
                <FormAnswerCommunications
                  title={"Responder"}
                  onSubmit={onSubmitCommunication}
                  onClose={handleCloseModal}
                  subject={subjectForAnswer}
                />
              ) : (
                <div className="ghost-form"></div>
              )}
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default CommunicationChatAppointment;
