import React, {useEffect} from 'react';
import Moment from 'moment';
// import EntornoLaboral from '../Comun/EntornoLaboral';
import useDecode from "hooks/useDecode";
import '../ManagementSheets.scss';
import {recordSheets} from '@solimat/solimat-web-endpoint';
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import LoaderMin from 'components/blocks/Loader/LoaderMin';

type guid = string;
interface WorkEnvironment {
  workEnvironmentCompanyId: number,
  name: string,
  ccc: string,
  cif: string,
}
interface Management {
  name: string,
  date: string,
  company: string
}
interface Recovery extends Management {
  id: number,
  tipoRecobro: string,
  fechaRegistro: Date,
  codigo: string,
  cantidadReclamada?: number,
  cantidadRecobrada?: number,
  estadoRecobro: string,    
  record_id: guid,
  recobranteDescripcion: string,
  work_environment: WorkEnvironment
}

interface ISheet {
  props:any
}

const Recobro:React.FC<ISheet> = ({props}:any) => {
  const recobroId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getRecovery,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(()=> {
    getRecovery(userId,prestacionId,recobroId)
    Moment().locale("es");
  },[])

  if(loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }
  
  return (
    <>
    {dataEconomicSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Datos Recobro'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Código'}</label>
              <input type="text" id="Codigo" className="item-value" value={dataEconomicSheet.codigo} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Registro'}</label>
              <input type="text" id="FechaRegistro" className="item-value" value={dataEconomicSheet.fechaRegistro!==null?Moment(dataEconomicSheet.fechaRegistro).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Tipo Recobro'}</label>
              <input type="text" id="TipoRecobro" className="item-value" value={dataEconomicSheet.tipoRecobro} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Recobrante'}</label>
              <input type="text" id="EstadoRecobro" className="item-value" value={dataEconomicSheet.recobranteDescripcion} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Detalles Recobro'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Total Cantidad Reclamada'}</label>
              <input type="text" id="CantidadReclamada" className="item-value" value={`${dataEconomicSheet.cantidadReclamada}€`} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Total Cantidad Abonada'}</label>
              <input type="text" id="CantidadRecobrada" className="item-value" value={`${dataEconomicSheet.cantidadRecobrada ? dataEconomicSheet.cantidadRecobrada : '' }€`} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Estado Recobro'}</label>
              <input type="text" id="EstadoRecobro" className="item-value" value={dataEconomicSheet.estadoRecobro} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      {/* <EntornoLaboral
        id={'EntornoLaboral'}
        nombre={dataEconomicSheet.work_environment?dataEconomicSheet.work_environment.name:''}
        CCC={dataEconomicSheet.work_environment?dataEconomicSheet.work_environment.ccc:''}
        CIF={dataEconomicSheet.work_environment?dataEconomicSheet.work_environment.cif:''}
      /> */}
    </section>
    }
    </>
  )
}

export default Recobro;