import React, {useEffect, useState} from 'react';
import Moment from 'moment';
import EntornoLaboral from '../Comun/EntornoLaboral';
import '../ManagementSheets.scss';
import {recordSheets} from '@solimat/solimat-web-endpoint';
import useDecode from "hooks/useDecode";
import useGetEconomicSheets from 'services/recordSheets/useGetEconomicSheets';
import LoaderMin from 'components/blocks/Loader/LoaderMin';


type guid = string;
interface WorkEnvironment {
  workEnvironmentCompanyId: number,
  name: string,
  ccc: string,
  cif: string,
}
interface Management {
  name: string,
  date: string,
  company: string
}
interface ImsSummary extends Management {
  id: number | guid,
  codigoNumeracion: string,
  cantidadAbonada?: number, //Primera Cantidad Abonada (Bruto)
  fechaIngreso?: Date | string,
  cantidadAbonada2?: number, //Segunda Cantidad Abonada (Bruto)
  // DateTime?: Date | string,
  fechaIngresoViuda?: Date | string,
  fechaIngreso2?: Date | string,
  gastosSepelio?: number,
  fechaIngresoSepelio?: Date | string,
  cantidadAbonadaNeto?: number, //Primera Cantidad Abonada (Neto)
  cantidadAbonadaNeto2?: number,//Segunda Cantidad Abonada (Neto)
  retencion: number,
  retencion2?: number,
  fechaPago1?: Date | string,
  fechaPago2?: Date | string,
  cantidadAbonadaNeto3?: number,
  fechaIngreso3?: Date | string,
  retencion3?: number,
  fechaPago3?: Date | string,
  cantidadAbonada3?: number, //Tercera Cantidad Abonada (Neto)
  imsEtapa: string,  // Solo Tribunal o Juzgado.
  //Relaciones
  record_id: guid,
  record_code: string,
  work_environment: WorkEnvironment
}

interface ISheet {
  props:any
}

const ImsResumen:React.FC<ISheet> = ({props}:any) => {
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const resumenIMSId = props.match.params.detailId.split('__')[1];
  const [error, setError] = useState<boolean>(false);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {getImsSummary, dataEconomicSheet, loadingEconomicSheet, errorEconomicSheet}= useGetEconomicSheets()

  useEffect(()=> {
    getImsSummary(userId,prestacionId,resumenIMSId);
    
  },[])
 
  

  if(loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  return (
    <>
    {dataEconomicSheet === null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="container">
        <div className="row remarkable">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Detalle'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Código'}</label>
              <input type="text" id="CodigoNumeracion" className="item-value" value={dataEconomicSheet.codigoNumeracion} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Etapa actual I.M.S.'}</label>
              <input type="text" id="ImsEtapa" className="item-value" value={dataEconomicSheet.imsEtapa} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="row remarkable">
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha primer pago'}</label>
              <input type="text" id="FechaPago1" className="item-value" value={dataEconomicSheet?.fechaPago1!==null?Moment(dataEconomicSheet?.fechaPago1).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha primer Ingreso'}</label>
              <input type="text" id="FechaIngreso" className="item-value" value={dataEconomicSheet.fechaIngreso!==null?Moment(dataEconomicSheet.fechaIngreso).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Primera Cantidad Abonada (Bruto)'}</label>
              <input type="text" id="CantidadAbonada" className="item-value" value={`${dataEconomicSheet.cantidadAbonada}€`} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Primera Cantidad Abonada (Neto)'}</label>
              <input type="text" id="CantidadAbonadaNeto" className="item-value" value={`${dataEconomicSheet.cantidadAbonadaNeto}€`} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'% Retención'}</label>
              <input type="text" id="Retencion" className="item-value" value={dataEconomicSheet.retencion} readOnly={true}/>
            </div>
          </div>
          {/* falta usuario */}
        </div>
      </div>
      
        <div className="container">
          <div className="row remarkable">
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Segunda fecha pago'}</label>
                <input type="text" id="FechaPago2" className="item-value" value={dataEconomicSheet.fechaPago2!==null?Moment(dataEconomicSheet.fechaPago2).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Segunda fecha ingreso'}</label>
                <input type="text" id="FechaIngreso2" className="item-value" value={dataEconomicSheet.fechaIngreso2!==null?Moment(dataEconomicSheet.fechaIngreso2).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="item">
                <label className="item-label">{'Segunda Cantidad Abonada (Bruto)'}</label>
                <input type="text" id="CantidadAbonada2" className="item-value" value={`${dataEconomicSheet.cantidadAbonada2}€`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Segunda Cantidad Abonada (Neto)'}</label>
                <input type="text" id="CantidadAbonadaNeto2" className="item-value" value={`${dataEconomicSheet.cantidadAbonadaNeto2}€`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'% Retención'}</label>
                <input type="text" id="Retencion2" className="item-value" value={dataEconomicSheet.retencion2} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row remarkable">
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tercera fecha pago'}</label>
                <input type="text" id="FechaPago3" className="item-value" value={dataEconomicSheet.fechaPago3!==null?Moment(dataEconomicSheet.fechaPago3).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tercera fecha ingreso'}</label>
                <input type="text" id="FechaIngreso3" className="item-value" value={dataEconomicSheet.fechaIngreso3!==null?Moment(dataEconomicSheet.fechaIngreso3).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tercera cantidad abonada (Bruto)'}</label>
                <input type="text" id="CantidadAbonada3" className="item-value" value={`${dataEconomicSheet.cantidadAbonada3}€`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tercera cantidad abonada (Neto)'}</label>
                <input type="text" id="CantidadAbonadaNeto3" className="item-value" value={`${dataEconomicSheet.cantidadAbonadaNeto3}€`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'% Retención'}</label>
                <input type="text" id="Retencion3" className="item-value" value={dataEconomicSheet.retencion3} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row remarkable">
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha pago alzado viuda'}</label>
                <input type="text" id="FechaIngresoViuda" className="item-value" value={dataEconomicSheet.fechaIngresoViuda!==null?Moment(dataEconomicSheet.fechaIngresoViuda).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Gastos sepelio'}</label>
                <input type="text" id="GastosSepelio" className="item-value" value={`${dataEconomicSheet.gastosSepelio}€`} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha pago sepelio'}</label>
                <input type="text" id="FechaIngresoSepelio" className="item-value" value={dataEconomicSheet.fechaIngresoSepelio!==null?Moment(dataEconomicSheet.fechaIngresoSepelio).format("L"):""} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
        <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.Nombre : ""}
            CCC={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CCC : ""}
            CIF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CIF : ""}
            EntornoLaboralEmpresaId={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
            EsCuentaPropia={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EsCuentaPropia : ""}
            NAF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.NAF : ""}
          />
      </section>
  
    }
    </>
  )
}

export default ImsResumen;