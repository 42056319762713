import React, {useState} from 'react';

interface ISurveyFormGridItem {
  grid:any,
  checkFieldInputType: (inputType:number)=>string,
  register:any
}

const SurveyFormGridItem:React.FC<ISurveyFormGridItem> = ({grid,checkFieldInputType, register}) => {
  const initialRows = 3;
  const [totalGridRows, setTotalGridRows] = useState<number>(initialRows);

  const handleAddRow = () => {
    setTotalGridRows(totalGridRows+1);
  }
  const handleDeleteRow = () => {
    if(totalGridRows>1) {
      setTotalGridRows(totalGridRows-1);
    }
  }

  return(
    <div className="grid" id={grid.id}>
      <h4 className="grid__name">{grid.name}</h4>
      {grid.fields.map((field:any)=> {
        if(field.type === 9) {
          return (
            <p className="paragraph_field">{field.description}</p>
          )
        }
      })}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {grid.fields.map((field:any)=> {
                if(field.type !== 9) {
                  return (
                  <th scope="col">{field.description}</th>
                  )
                }
              })}
            </tr>
          </thead>
          <tbody>
            {[...Array(totalGridRows)].map((row:any, index:number)=> {
              return(
              <tr>
                  {grid.fields.map((field:any)=> {
                    if(field.type < 4) {
                      return(
                        <td scope="row">
                          <input id={`${field.grid_field_id}_${index}`} className="input-grid" type={field.type} name={`${field.name}_${index}`}
                          ref={register}/>
                        </td>
                      )
                    } else if (field.type === 4) { // INPUT CHECKBOX único
                      return(
                        <td scope="row" className="input-block">
                          <input type={checkFieldInputType(field.type)} id={`${field.grid_field_id}_${index}`} name={`${field.name}_${index}`} defaultValue={field.name} defaultChecked={false} ref={register}/>
                        </td>
                      )
                    } else if (field.type === 5 ) { // select
                      return(
                        <td scope="row" className="input-block">
                          <select id={`${field.grid_field_id}_${index}`} name={`${field.name}_${index}`} ref={register}>
                            <option defaultValue="" selected >Seleccionar</option>
                            {field.options.map((option:any)=> {
                              return(
                                <option defaultValue={option.value}>{option.value}</option>
                              )
                            })}
                          </select>
                        </td>
                      )
                    } else if (field.type === 6) { // textarea
                      return(
                        <td className="input-block">
                          <textarea placeholder={field.description} id={`${field.grid_field_id}_${index}`} name={`${field.name}_${index}`} ref={register}></textarea>
                        </td>
                      )
                    } else if (field.type === 7 ) { // grupo checkbox
                      return(                    
                        <td scope="row" className="input-block">
                          <fieldset className={`input-block ${checkFieldInputType(field.type)} inputs-group`}>
                            {field.options.map((option:any)=> {
                              return(
                                <div className={`input-block ${checkFieldInputType(field.type)}`}>
                                  <label htmlFor={`${option.value}_${field.grid_field_id}_${index}`}>{option.value}</label>
                                  <input type={`${checkFieldInputType(field.type)}`} id={`${option.value}_${field.grid_field_id}_${index}`} name={`${option.value}_${index}`} defaultValue={`${option.value}`} ref={register} />
                                </div>
                              )
                            })}
                          </fieldset>
                        </td>
                      )
                    } else if (field.type === 8 ) { // grupo radio
                      return (
                        <td scope="row" className="input-block">
                          <fieldset className={`input-block ${checkFieldInputType(field.type)} inputs-group`}>
                            {field.options.map((option:any)=> {
                              return (
                                <div className={`input-block ${checkFieldInputType(field.type)}`}>
                                  <label htmlFor={`${option.value}_${field.grid_field_id}_${index}`}>{option.value}</label>
                                  <input type={`${checkFieldInputType(field.type)}`} id={`${option.value}_${field.grid_field_id}_${index}`} name={`${field.name}_${index}`} defaultValue={option.value} ref={register}/>
                                </div>
                              )
                            })}
                          </fieldset>
                        </td>
                      )
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="btns-container">
          <button type="button" className="btn-box btn-clear"
            onClick={handleAddRow}
          >
            <span className="icon">+</span>Añadir fila
          </button>
          <button type="button" className="btn-box btn-clear"
            onClick={handleDeleteRow}
            disabled={(totalGridRows<=1)}
          >
            <span className="e-icons e-delete"></span>Eliminar fila
          </button>
        </div>
      </div>

    </div>
  )
}

export default SurveyFormGridItem;