import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/communication/dto"


const useGetUserChatCommunicationByIdRecord = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [userChatCommunicationRecord , setUserChatCommunicationRecord ] = useState<any>([]);
  const [loadingGetUserChatCommunicationRecord, setLoadingGetUserChatCommunicationRecord ] = useState(false);
  const [errorGetUserChatCommunicationRecord , setErrorGetUserChatCommunicationRecord ] = useState(null);
  const [isComunicacionHabilitada, setisComunicacionHabilitada] = useState(false)
  const getUserChatCommunicationByIdRecord  = async(userId :string, prestacionId: string ) => {
    setLoadingGetUserChatCommunicationRecord (true);
    setErrorGetUserChatCommunicationRecord (null);
    apiCommunication
      .getUserChatCommunicationByIdRecord (userId, prestacionId)
      .then((res: any) => {
        setisComunicacionHabilitada(res.data.comunicacionHabilitada)
        const userChatCommunication = res.data.rows;
        setUserChatCommunicationRecord (userChatCommunication);
        setLoadingGetUserChatCommunicationRecord (false);
      })
      .catch((err:any) => {
        setErrorGetUserChatCommunicationRecord (err);
        setLoadingGetUserChatCommunicationRecord (false);
      });
  };
  return {
    isComunicacionHabilitada,
    getUserChatCommunicationByIdRecord ,
    userChatCommunicationRecord ,
    loadingGetUserChatCommunicationRecord ,
    errorGetUserChatCommunicationRecord 
  };
};

export default useGetUserChatCommunicationByIdRecord ;