import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import LoaderSubmitBtn from 'components/blocks/Buttons/LoaderSubmitBtn';

import Cnae from 'components/blocks/Searchers/Delta/Cnae/Cnae';
import DateDouble from 'components/blocks/Inputs/DateDouble';

import "../Forms.scss";

interface ISearcherWithLeavePending {
  validation: string,
  remesados: string,
  leaveDateFrom: Date,
  leaveDateTo: Date,
  workerData: IWorkerData,
}

interface IWorkerData {
  name: string,
  surname: string,
}

interface IPendingSearcher {
  loading: boolean,
  handleFilterPat: (values:any) => void
}

const PendingSearcher:React.FC<IPendingSearcher> = ({handleFilterPat, loading}) => {
  const { handleSubmit, reset, watch, formState: { errors }, register } = useForm()
  const watchCnae = watch('cnaeCode')

  const submitForm = (values:any) => {
    handleFilterPat(values)
  }

  return (
    <form className="container form--simple-styled searcher" onSubmit={handleSubmit(submitForm)}>
      <fieldset className="row">
        <div className="col-xl-3 col-md-6 input-block">
          <label htmlFor="validation" className="input-block__label">Validación: </label>
          <select 
            name="validation" 
            id="validation"
            {...register('validation')}
          >
            <option value="2">Todos</option>
            <option value="0">Correcta</option>
            <option value="1">Incorrecta</option>
          </select>
        </div>
        <div className="col-xl-3 col-md-6 input-block">
          <label htmlFor="remitted" className="input-block__label">Remesados:</label>
          <select 
            name="remitted" 
            id="remitted"
            {...register('validation')}
          >
            <option value="2">Todos</option>
            <option value="0">Sí</option>
            <option value="1">No</option>
          </select>
        </div>
        <div className="col-xl-6">
          <DateDouble
            register={register}
            errors={errors}
            label="Fecha de baja"
          />
        </div>
      </fieldset>
      <fieldset className="row">
        <legend className="col-12">Datos del trabajador</legend>
        <div className="col-xl-4 col-md-6">
          <div className="input-block">
            <label htmlFor="name" className="input-block__label">Nombre:</label>
            <input type="text" name="name" id="name" placeholder={"Nombre"} {...register('name')}/>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 input-block">
          <div className="input-block">
            <label htmlFor="surname" className="input-block__label">Apellidos:</label>
            <input type="text" name="surname" id="surname" placeholder={"Apellidos"} {...register('surname')}/>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 input-block">
          <label htmlFor="dni" className="input-block__label">DNI:</label>
          <input type="text" name="dni" id="dni" placeholder={"Dni"} {...register('dni')}/>
        </div>
        
          <Cnae
            register={register}
            errors={errors}
            watchCnae={watchCnae}
          />
        
      </fieldset>  
      <div className="row">
        <div className="col-12">
          <div className="btns-container c-right">
            <input className="btn-box btn-clear " type="button" value="Borrar"
              onClick={()=>reset()}
            />
            <LoaderSubmitBtn
              loading={loading}
              text='Buscar'
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default PendingSearcher;