import React from 'react';
import FormsManagement from './FormsManagement';
import './ManagementForms.scss';

import {TabsFormsContextProvider} from '../../../contexts/TabsFormsContext';

const ManagementForms:React.FC = (props:any) => {
  return(
    <>
      <TabsFormsContextProvider>
        <FormsManagement />
      </TabsFormsContextProvider>
    </>
  )
}


export default ManagementForms;