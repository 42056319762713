export const errorsValidationDeltaWithLeave = [
  {
    dbKey: 'TipoDelta',
    numReference: '',
    label: '',
    name: 'type',
    message: ''
  },
  // WORKER
  {
    dbKey: 'Trabajador.Nombre',
    numReference: '',
    label: '',
    name: 'worker_name',
    message: ''
  },
  {
    dbKey: 'Trabajador.Apellido1',
    numReference: '',
    label: '',
    name: 'worker_firstName',
    message: ''
  },
  {
    dbKey: 'Trabajador.Apellido2',
    numReference: '',
    label: '',
    name: 'worker_secondName',
    message: ''
  },
  {
    dbKey: 'Trabajador.TipoIPFDescripcion',
    numReference: '',
    label: '',
    name: 'worker_ipfType',
    message: ''
  },
  {
    dbKey: 'Trabajador.FechaNacimiento',
    numReference: '',
    label: '',
    name: 'worker_birthDate',
    message: ''
  },
  {
    dbKey: 'Trabajador.IPF',
    numReference: '',
    label: '',
    name: 'worker_ipf',
    message: ''
  },
  {
    dbKey: 'Trabajador.NAF',
    numReference: '',
    label: '',
    name: 'worker_naf',
    message: ''
  },
  {
    dbKey: 'Trabajador.FechaNacimiento',
    numReference: '',
    label: '',
    name: 'worker_birthDate',
    message: ''
  },
  {
    dbKey: 'Trabajador.Nacionalidad',
    numReference: '',
    label: '',
    name: 'worker_nation',
    message: ''
  },
  {
    dbKey: 'Trabajador.SexoId',
    numReference: '',
    label: '',
    name: 'worker_genderType',
    message: ''
  },
  {
    dbKey: 'Trabajador.SituacionId',
    numReference: '',
    label: '',
    name: 'worker_situation',
    message: ''
  },
  {
    dbKey: 'Trabajador.FechaIngreso',
    numReference: '',
    label: '',
    name: 'worker_admissionDate',
    message: ''
  },
  {
    dbKey: 'Trabajador.CNOTexto',
    numReference: '',
    label: '',
    name: 'worker_cno_text',
    message: ''
  },
  {
    dbKey: 'Trabajador.CNOCodigo',
    numReference: '',
    label: '',
    name: 'worker_cno_code',
    message: ''
  },
  {
    dbKey: 'Trabajador.CNODescripcion',
    numReference: '',
    label: '',
    name: 'worker_cno_description',
    message: ''
  },
  {
    dbKey: 'Trabajador.AntiguedadMeses',
    numReference: '',
    label: '',
    name: 'worker_months',
    message: ''
  },
  {
    dbKey: 'Trabajador.AntiguedadDias',
    numReference: '',
    label: '',
    name: 'worker_days',
    message: ''
  },
  {
    dbKey: 'Trabajador.TextoConv',
    numReference: '',
    label: '',
    name: 'worker_agreement_text',
    message: ''
  },

  {
    dbKey: 'Trabajador.RegimenSSId,',
    numReference: '',
    label: '',
    name: 'worker_socialSecurityRegime',
    message: ''
  },
  {
    dbKey: 'Trabajador.ContratoDescripcion',
    numReference: '',
    label: '',
    name: 'worker_contractDescription',
    message: ''
  },
  {
    dbKey: 'Trabajador.Contrato',
    numReference: '',
    label: '',
    name: 'worker_contract',
    message: ''
  },
  {
    dbKey: 'Trabajador.AtepCnae',
    numReference: '',
    label: '',
    name: 'worker_atepCnae',
    message: ''
  },
  {
    dbKey: 'worker_atepCnaeDescription',
    numReference: '',
    label: '',
    name: 'worker_atepCnaeDescription',
    message: ''
  },
  {
    dbKey: 'Trabajador.Domicilio',
    numReference: '',
    label: '',
    name: 'worker_address',
    message: ''
  },
  {
    dbKey: 'Trabajador.AtepOcupacion',
    numReference: '',
    label: '',
    name: 'worker_Cno_Price_ATEP',
    message: ''
  },
  {
    dbKey: 'Trabajador.Telefono',
    numReference: '',
    label: '',
    name: 'worker_phone',
    message: ''
  },
  {
    dbKey: 'Trabajador.ProvinciaCodigo',
    numReference: '',
    label: '',
    name: 'worker_province',
    message: ''
  },
  {
    dbKey: 'Trabajador.ProvinciaDescripcion',
    numReference: '',
    label: '',
    name: 'worker_provinceDescription',
    message: ''
  },
  {
    dbKey: 'Trabajador.Municipio',
    numReference: '',
    label: '',
    name: 'worker_city',
    message: ''
  },

  {
    dbKey: 'Trabajador.MunicipioDescripcion',
    numReference: '',
    label: '',
    name: 'worker_cityDescription',
    message: ''
  },
  {
    dbKey: 'Trabajador.CodigoPostal',
    numReference: '',
    label: '',
    name: 'worker_cp',
    message: ''
  },
  // COMPANY
  {
    dbKey: 'Empresa.CifNif',
    numReference: '',
    label: '',
    name: 'company_cifnif',
    message: ''
  },
  {
    dbKey: 'Empresa.CCC',
    numReference: '',
    label: '',
    name: 'company_ccc',
    message: ''
  },
  {
    dbKey: 'Empresa.Plantilla',
    numReference: '',
    label: '',
    name: 'company_workforce',
    message: ''
  },
  {
    dbKey: 'Empresa.Razon',
    numReference: '',
    label: '',
    name: 'company_reason',
    message: ''
  },

  {
    dbKey: 'Empresa.CnaeTexto',
    numReference: '',
    label: '',
    name: 'company_textCNAE',
    message: ''
  },
  {
    dbKey: 'Empresa.CnaeCodigo',
    numReference: '',
    label: '',
    name: 'company_codeCNAE',
    message: ''
  },
  {
    dbKey: 'Empresa.CnaeDescripcion',
    numReference: '',
    label: '',
    name: 'company_codeCNAEDescription',
    message: ''
  },
  {
    dbKey: 'Empresa.Domicilio',
    numReference: '',
    label: '',
    name: 'company_address',
    message: ''
  },
  {
    dbKey: 'Empresa.Telefono',
    numReference: '',
    label: '',
    name: 'company_phone',
    message: ''
  },
  {
    dbKey: 'Empresa.ProvinciaCodigo',
    numReference: '',
    label: '',
    name: 'company_province',
    message: ''
  },
  {
    dbKey: 'Empresa.ProvinciaDescripcion',
    numReference: '',
    label: '',
    name: 'company_provinceDescripcion',
    message: ''
  },
  {
    dbKey: 'Empresa.Municipio',
    numReference: '',
    label: '',
    name: 'company_city',
    message: ''
  },

  {
    dbKey: 'Empresa.MunicipioDescripcion',
    numReference: '',
    label: '',
    name: 'company_cityDescription',
    message: ''
  },
  {
    dbKey: 'Empresa.CodigoPostal',
    numReference: '',
    label: '',
    name: 'company_cp',
    message: ''
  }, {
    dbKey: 'Empresa.EsContrata',
    numReference: '',
    label: '',
    name: 'company_contracted',
    message: ''
  },

  {
    dbKey: 'Empresa.EsETT',
    numReference: '',
    label: '',
    name: 'company_ett1',
    message: ''
  }, {
    dbKey: 'Empresa.EsPreventivaAsunPersona',
    numReference: '',
    label: '',
    name: 'company_assumptionPerson',
    message: ''
  },
  {
    dbKey: 'Empresa.EsPreventivaServPrevAje',
    numReference: '',
    label: '',
    name: 'company_externalPreventionService',
    message: ''
  },
  {
    dbKey: 'Empresa.EsPreventivaServPrevMan',
    numReference: '',
    label: '',
    name: 'company_jointPreventionServices',
    message: ''
  },
  {
    dbKey: 'Empresa.EsPreventivaServPrevPro',
    numReference: '',
    label: '',
    name: 'company_ownPreventionService',
    message: ''
  },
  {
    dbKey: 'Empresa.EsPreventivaTrabDesigna',
    numReference: '',
    label: '',
    name: 'company_designatedWorkers',
    message: ''
  },
  {
    dbKey: 'Empresa.EsPreventivaNinguna',
    numReference: '',
    label: '',
    name: 'company_nothing',
    message: ''
  },
  // CC LOCATION
  {
    dbKey: 'CCCLugar.Codigo',
    numReference: '',
    label: '',
    name: 'ccclocation_codeDescription',
    message: ''
  },
  {
    dbKey: 'CCCLugar.EsTrafico',
    numReference: '',
    label: '',
    name: 'ccclocation_traffic',
    message: ''
  },
  {
    dbKey: 'CCCLugar.EsCentroCentroEp2',
    numReference: '',
    label: '',
    name: 'ccclocation_centerEP2',
    message: ''
  },
  {
    dbKey: 'CCCLugar.EsCentroEmpresaEp2',
    numReference: '',
    label: '',
    name: 'ccclocation_companyEP2',
    message: ''
  },
  {
    dbKey: 'CCCLugar.CentroTipoEmpresaCodigo',
    numReference: '',
    label: '',
    name: 'ccclocation_CCCLugarCentroTipoEmpresaCodigo',
    message: ''
  },
  {
    dbKey: 'CCCLugar.Pais',
    numReference: '',
    label: '',
    name: 'ccclocation_nation',
    message: ''
  },
  {
    dbKey: 'ccclocation_phone',
    numReference: '',
    label: '',
    name: 'ccclocation_phone',
    message: ''
  },
  {
    dbKey: 'CCCLugar.ProvinciaCodigo',
    numReference: '',
    label: '',
    name: 'ccclocation_province',
    message: ''
  },
  {
    dbKey: 'CCCLugar.ProvinciaDescripcion',
    numReference: '',
    label: '',
    name: 'ccclocation_provinceDescription',
    message: ''
  },
  {
    dbKey: 'CCCLugar.Municipio',
    numReference: '',
    label: '',
    name: 'ccclocation_city',
    message: ''
  },
  {
    dbKey: 'CCCLugar.MunicipioDescripcion',
    numReference: '',
    label: '',
    name: 'ccclocation_cityDescription',
    message: ''
  },
  {
    dbKey: 'CCCLugar.Direccion',
    numReference: '',
    label: '',
    name: 'ccclocation_address',
    message: ''
  },
  {
    dbKey: 'CCCLugar.Viakm',
    numReference: '',
    label: '',
    name: 'ccclocation_public_road',
    message: ''
  },
  {
    dbKey: 'CCCLugar.Otro',
    numReference: '',
    label: '',
    name: 'ccclocation_other_side',
    message: ''
  },
  {
    dbKey: 'CCCLugar.CentroCifTipo',
    numReference: '',
    label: '',
    name: 'ccclocation_CCCLugarCentroCifTipo',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosRazon',
    numReference: '',
    label: '',
    name: 'cccclocation_reason',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosRazon',
    numReference: '',
    label: '',
    name: 'cccclocation_reason',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosDomicilio',
    numReference: '',
    label: '',
    name: 'cccclocation_company_address',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosProvinciaCodigo',
    numReference: '',
    label: '',
    name: 'ccclocation_company_province',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosMunicipio',
    numReference: '',
    label: '',
    name: 'ccclocation_company_city',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosMunicipioDescripcion',
    numReference: '',
    label: '',
    name: 'ccclocation_company_cityDescription',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosCodigopostal',
    numReference: '',
    label: '',
    name: 'ccclocation_company_cp',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosTelefono',
    numReference: '',
    label: '',
    name: 'ccclocation_company_phone',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosCCC',
    numReference: '',
    label: '',
    name: 'ccclocation_company_ccc',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosPlantilla',
    numReference: '',
    label: '',
    name: 'ccclocation_company_workforce',
    message: ''
  },
  {
    dbKey: 'CCCLugar.DatosCnaeCodigo',
    numReference: '',
    label: '',
    name: 'ccclocation_company_codeCNAE',
    message: ''
  },
  // ACCIDENT
  {
    dbKey: 'Accidente.FechaAccidente',
    numReference: '',
    label: '',
    name: 'accident_accidentDate',
    message: ''
  },
  {
    dbKey: 'Accidente.FechaBaja',
    numReference: '',
    label: '',
    name: 'accident_leavingDate',
    message: ''
  },
  {
    dbKey: 'Accidente.Hora',
    numReference: '',
    label: '',
    name: 'accident_hour',
    message: ''
  },
  {
    dbKey: 'Accidente.HoraTrabajo',
    numReference: '',
    label: '',
    name: 'accident_hourWork',
    message: ''
  },
  {
    dbKey: 'Accidente.DiaSemana',
    numReference: '',
    label: '',
    name: 'accident_day_week',
    message: ''
  },
  {
    dbKey: 'Accidente.Descripcion',
    numReference: '',
    label: '',
    name: 'accident_description',
    message: ''
  },
  {
    dbKey: 'Accidente.Habitual',
    numReference: '',
    label: '',
    name: 'accident_habitual',
    message: ''
  },
  {
    dbKey: 'Accidente.Evaluacion',
    numReference: '',
    label: '',
    name: 'accident_evaluation',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.EntornoLugar',
    numReference: '',
    label: '',
    name: 'accident_extension_site',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.TareaActividad',
    numReference: '',
    label: '',
    name: 'accident_extension_work',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.TareaEspecificaDescripcion',
    numReference: '',
    label: '',
    name: 'accident_extension_activity',
    message: ''
  },
  {
    dbKey: 'aaa',
    numReference: '',
    label: '',
    name: 'triggerUnusualFactDetailCode',
    message: ''
  },

  {
    dbKey: 'Accidente.Ampliacion.DesencadenanteDesv',
    numReference: '',
    label: '',
    name: 'code1_accident_extension_desv',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.EntornoTipoLugar',
    numReference: '',
    label: '',
    name: 'code1_accident_extension_typeSite',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.ProcesoTipoTrabajo',
    numReference: '',
    label: '',
    name: 'code1_accident_extension_typeWork',
    message: ''
  },

  {
    dbKey: 'Accidente.Ampliacion.DesencadenanteHech',
    numReference: '',
    label: '',
    name: 'accident_extension_agentText',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.TareaEspecifica',
    numReference: '',
    label: '',
    name: 'code1_accident_extension_specify',
    message: ''
  },
  {
    dbKey: 'Accidente.Descripcion',
    numReference: '',
    label: '',
    name: 'accident_extension_injuryForm',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.FormaContactoCodigo',
    numReference: '',
    label: '',
    name: 'code1_accident_extension_form_contact_code',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.ModoTipoModo',
    numReference: '',
    label: '',
    name: 'accident_extension_how_injury',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.ModoTextoAgente',
    numReference: '',
    label: '',
    name: 'accident_extension_form_text_agent',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.EsTestigos',
    numReference: '',
    label: '',
    name: 'accident_extension_witnesses',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.EsMultiples',
    numReference: '',
    label: '',
    name: 'accident_extension_multiples',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.TareaAgente',
    numReference: '',
    label: '',
    name: 'code1_physical_activity',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.DesencadenanteAgen',
    numReference: '',
    label: '',
    name: 'code1_trigger_unusual_fact',
    message: ''
  },
  {
    dbKey: 'Accidente.Ampliacion.ModoAgente',
    numReference: '',
    label: '',
    name: 'code1_material_Agent_As_Injured',
    message: ''
  },
  // ASSISTANCE
  {
    dbKey: 'Asistenciales.TipoLesionCodigo',
    numReference: '',
    label: '',
    name: 'code1_assistance_injury',
    message: ''
  },

  {
    dbKey: 'Asistenciales.ParteCuerpoLesionadaCodigo',
    numReference: '',
    label: '',
    name: 'code1_assistance_part',
    message: ''
  },
  {
    dbKey: 'Asistenciales.TipoLesionCodigo',
    numReference: '',
    label: '',
    name: 'assistance_injuryDescription',
    message: ''
  },
  {
    dbKey: 'Asistenciales.ParteCuerpoLesionadaCodigo',
    numReference: '',
    label: '',
    name: 'assistance_partDescription',
    message: ''
  },

  {
    dbKey: 'Asistenciales.GradoLesionCodigo',
    numReference: '',
    label: '',
    name: 'assistance_degree',
    message: ''
  },
  {
    dbKey: 'Asistenciales.MedicoNombre',
    numReference: '',
    label: '',
    name: 'assistance_nameDoctor',
    message: ''
  },
  {
    dbKey: 'Asistenciales.MedicoTelefono',
    numReference: '',
    label: '',
    name: 'assistance_phoneDoctor',
    message: ''
  },

  {
    dbKey: 'Asistenciales.MedicoDomicilio',
    numReference: '',
    label: '',
    name: 'assistance_addressDoctor',
    message: ''
  },
  {
    dbKey: 'Asistenciales.HospitalNombre',
    numReference: '',
    label: '',
    name: 'assistance_codeHospital',
    message: ''
  },
  {
    dbKey: 'Asistenciales.EsHospitalCodigo',
    numReference: '',
    label: '',
    name: 'riskAssessment',
    message: ''
  },
  {
    dbKey: 'Asistenciales.TipoAsistenciaCodigo',
    numReference: '',
    label: '',
    name: 'assistance_assistenceType',
    message: ''
  },
  // ECONOMIC
  {
    dbKey: 'Economicos.MensualMesAnterior',
    numReference: '',
    label: '',
    name: 'economic_previousMonth',
    message: ''
  },
  {
    dbKey: 'Economicos.MensualDias',
    numReference: '',
    label: '',
    name: 'economic_days',
    message: ''
  },
  {
    dbKey: 'Economicos.MensualBase',
    numReference: '',
    label: '',
    name: 'economic_base',
    message: ''
  },
  {
    dbKey: 'Economicos.AnualB1',
    numReference: '',
    label: '',
    name: 'economic_b1',
    message: ''
  },
  {
    dbKey: 'Economicos.AnualB2',
    numReference: '',
    label: '',
    name: 'economic_b2',
    message: ''
  },
  {
    dbKey: 'Economicos.AnualTotal',
    numReference: '',
    label: '',
    name: 'economic_totalAnnual',
    message: ''
  },
  {
    dbKey: 'Economicos.AnualPromedio',
    numReference: '',
    label: '',
    name: 'economic_average',
    message: ''
  },

  {
    dbKey: 'Economicos.SubsidioPromedioA',
    numReference: '',
    label: '',
    name: 'economic_averageA',
    message: ''
  },
  {
    dbKey: 'Economicos.SubsidioPromedioB',
    numReference: '',
    label: '',
    name: 'economic_averageB',
    message: ''
  },
  {
    dbKey: 'Economicos.SubsidioTotal',
    numReference: '',
    label: '',
    name: 'economic_totalBenefit',
    message: ''
  },
  {
    dbKey: 'Economicos.SubsidioIndemnizacion',
    numReference: '',
    label: '',
    name: 'economic_compensation',
    message: ''
  },
  // ACTORS
  {
    dbKey: 'Actores.FEmpresaNombreApellidos',
    numReference: '',
    label: '',
    name: 'actors_completeName',
    message: ''
  },
  {
    dbKey: 'Actores.FEmpresaCalidadDe',
    numReference: '',
    label: '',
    name: 'actors_serveAs',
    message: ''
  },
  {
    dbKey: 'Actores.FEmpresaProvincia',
    numReference: '',
    label: '',
    name: 'actors_province',
    message: ''
  }, {
    dbKey: 'Actores.FEmpresaFechaPresenta',
    numReference: '',
    label: '',
    name: 'actors_submissionDate',
    message: ''
  }, {
    dbKey: 'actors_codeAlp',
    numReference: '',
    label: '',
    name: 'actors_codeAlp',
    message: ''
  }, {
    dbKey: 'actors_numExpedientEgc',
    numReference: '',
    label: '',
    name: 'actors_numExpedientEgc',
    message: ''
  }, {
    dbKey: 'actors_acceptationDate',
    numReference: '',
    label: '',
    name: 'actors_acceptationDate',
    message: ''
  }, {
    dbKey: 'actors_code',
    numReference: '',
    label: '',
    name: 'actors_code',
    message: ''
  }, {
    dbKey: 'actors_receptionDate',
    numReference: '',
    label: '',
    name: 'actors_receptionDate',
    message: ''
  }, {
    dbKey: 'actors_reasonRejection',
    numReference: '',
    label: '',
    name: 'actors_reasonRejection',
    message: ''
  },
]
export const findNameByDbKey = (dbKey: string, errorsValidationDeltaWithLeave: any[]) => {
  for (const item of errorsValidationDeltaWithLeave) {
    if (item?.dbKey === dbKey) {
      return item?.name;
    }
  }
  return null;
}