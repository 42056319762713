import React from "react";
import "./loader.scss";

const LoaderMin: React.FC = () => {
  return (
    <div>
      <span className="loader-text c-center">Cargando Datos......</span>
      <div className="loader-spinner" data-testid="divLoader"></div>
    </div>
  );
};

export default LoaderMin;
