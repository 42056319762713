import React, { useEffect } from 'react'
import logo from "../../../img/logoBlanco.png";
import PageMenu from '../../blocks/PageMenu/PageMenu';
import useTerminosCondiciones from '../../../services/TermsAndConditions/useGetTermsAndConditionsPage'
import { dataTerminosCondiciones2 } from '../../../dataMocks'
import './terminosCondiciones.scss'
import Loader from 'components/blocks/Loader/Loader';
const SeparatorReverse = () => {
    return (
        <section className="separator-triangle-reverse"></section>
    )
}
const TerminosCondiciones = () => {
    const { getTermsAndConditionsPage, termsAndConditionsPage, loadingGetTermsAndConditionsPage, successGetTermsAndConditionsPage, errorGetTermsAndConditionsPage } = useTerminosCondiciones()
    useEffect(() => {
        getTermsAndConditionsPage()


    }, [])
    return (
        <>

            <header className={`header-block`}>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="header-content">
                                <div className="logo-block">
                                    <div className="logo-container">
                                        <img src={logo} className="logo-landingpage" alt="solimat" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <PageMenu
                title={'Términos y Condiciones'}
            >
            </PageMenu>

            {/* <Separator /> */}
            <br />
            <br />
            {!loadingGetTermsAndConditionsPage ? <Loader activeLoader={true} label="Descargando Terminos y Condiciones" /> :
                <div dangerouslySetInnerHTML={{ __html: termsAndConditionsPage && termsAndConditionsPage }} />
            }

            <SeparatorReverse />
        </>
    )
}

export default TerminosCondiciones