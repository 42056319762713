import React, { useState } from "react";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

interface IRecordManagementGrid {
  arrData2: any[];
  props: any;
}

const RoyalDecreeFormsGrid: React.FC<IRecordManagementGrid> = ({
  arrData2,
  props,
}) => {
  let tooltip: any;
  
  const { checkFormStatusRD } = useCheckRecordStatusAndTypes();
  
  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const Template = (props: any): any => {
    return (
      <div className="link-document">
        <a
          href={props.uri}
          rel={props.nombre}
          target="_blank"
          className="link-bold"
        >
          <span className="edit-delta-icon"></span>
        </a>
      </div>
    );
  };

  const rowSelected = (args: any) => {
    if (args != null) {
      let idForm = args.data.Id;
      let idRecord = props.match.params.id;
      let redirectPath = `/historial/${idRecord}/${idForm}/formulario-real-decreto`;
      props.history.push({
        pathname: redirectPath,
      });
    }
  };

  const transformedData = arrData2 && arrData2.map(form => ({
    ...form,
    EstadoGeneralRevision: checkFormStatusRD(form.EstadoGeneralRevision),
  }));

  const beforeRender = (args: any) => {
    const cell = args.target.closest("td");
    const isVerColumn = cell.querySelector(".edit-delta-icon"); 
    
    if (isVerColumn) {
      tooltip.content = "Ver"; 
    } else {
      tooltip.content = cell.innerText; 
    }
  };

  return (
    <div>
      <TooltipComponent
        ref={(t: any) => (tooltip = t)}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
          dataSource={transformedData}
          allowPaging={true}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="TipoStr"
              headerText="Tipo"
              width="60"
              textAlign="Center"
            />
            <ColumnDirective
              field="EstadoGeneralRevision"
              headerText="Estado"
              width="140"
              textAlign="Center"
            />
            <ColumnDirective
              field=""
              headerText="Ver"
              template={Template}
              width="30"
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default RoyalDecreeFormsGrid;
