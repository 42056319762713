import React, {useEffect} from 'react';
import useGetCompleteForm from 'services/formsBuilder/useGetCompleteForm';
import useGetSurveyJson from 'services/surveys/useGetSurveyJson';
import useGetSurveyJsonBySurvey from 'services/surveys/useGetSurveyJsonBySurvey';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import SurveyForm from "components/blocks/Forms/SurveyForm/SurveyForm";
import { TextMessagesTemplates } from 'components/blocks/TextMessagesTemplates/TextMessagesTemplates';
import LoaderMin from 'components/blocks/Loader/LoaderMin';


const SurveyDetail:React.FC = (props:any) => {
  const surveyId = props.match.params.surveyId;
  const {
    getCompleteForm,
    formData,
    loading,
    error } = useGetCompleteForm();
  
  // const {
  //   surveyJson,
  //   getSurveyJson,
  //   successGetSurveyJson,
  //   loadingGetSurveyJson,
  //   errorGetSurveyJson
  // } = useGetSurveyJson();
  const {
    surveyJsonBySurvey,
    getSurveyJsonBySurvey,
    successGetSurveyJsonBySurvey,
    loadingGetSurveyJsonBySurvey,
    errorGetSurveyJsonBySurvey
  } = useGetSurveyJsonBySurvey();

  useEffect(()=> {
    getCompleteForm(surveyId)
    // getSurveyJson(surveyId)
    getSurveyJsonBySurvey(surveyId);
  }, [])

  if(loading) {
    return(
      <section>
        {TextMessagesTemplates.loading}
      </section>
    )
  }
  if(error) {
    return(
      <section>
        {TextMessagesTemplates.errorResponse}
      </section>
    )
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <PageMenu
              title={"Encuesta"}
              breadcrumbs={["Mis encuestas","Encuesta"]}
            >
              <div className="btns-header-container">
                <button onClick={()=>props.history.goBack()}>
                  <img src={IconArrowGray} alt="icon arrow right" />
                </button>
              </div>
            </PageMenu>
          </div>
        </div>
        <section className="container">
          <div className="row">
            <div className="col-12">
              {/* <SurveyForm
                {...props}
                surveyData={surveyJson}
              /> */}
              {formData && !loading?               
                <SurveyForm
                  {...props}
                  surveyData={formData}
                />
                :
                <>
                  {loading &&
                    // <p className="loader-text">Cargando...</p>
                    <LoaderMin/>
                  }
                  {error &&
                    <p className="loader-text">Lo sentimos, se ha producido un error.</p>
                  }
                </>
              }
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SurveyDetail;