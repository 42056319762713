import {useState} from 'react';
import { user } from "@solimat/solimat-web-endpoint";
import {guid} from '@solimat/solimat-web-endpoint/lib/record/dto';

const useGetOtpPhoneEmail = () => {
  const [loadingGetOtpPhoneEmail, setLoadingGetOtpPhoneEmail] = useState(false);
  const [successGetOtpPhoneEmail, setSuccessGetOtpPhoneEmail] = useState(false);
  const [errorGetOtpPhoneEmail, setErrorGetOtpPhoneEmail] = useState<null|boolean>(null);
  const [otpPhoneEmail, setOtpPhoneEmail] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_USUARIOS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_USUARIOS_KEY || "";
  const apiUser = new user.UserService(BASE_URL, FUNCTIONS_KEY);

  const getOtpPhoneEmail = (usuarioId: guid, telefono: string, email: string, _cb?:(data:any)=>void) => {
    setLoadingGetOtpPhoneEmail(true);
    setSuccessGetOtpPhoneEmail(false);
    apiUser.getOtpPhoneEmail(usuarioId, telefono, email)
      .then((res:any) => {     
        if(res.data !== "") {
            setOtpPhoneEmail(res);
          setLoadingGetOtpPhoneEmail(false);
          setSuccessGetOtpPhoneEmail(true);
          if (typeof _cb === "function") {
            _cb(res.data.uri);
          }
        } else {
            setLoadingGetOtpPhoneEmail(false);
            setErrorGetOtpPhoneEmail(true);
        }
      })
      .catch((err:any)=> {
        setLoadingGetOtpPhoneEmail(false);
        setErrorGetOtpPhoneEmail(true);
        console.log(err);
      })
  }


  return {
    otpPhoneEmail,
    getOtpPhoneEmail,
    loadingGetOtpPhoneEmail,
    successGetOtpPhoneEmail,
    errorGetOtpPhoneEmail
    };
}

export default useGetOtpPhoneEmail;