import React, { useState } from "react";
import DoubleRadioCheckGroup from "../Inputs/DoubleRadioCheckGroup";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import PlaceOutSideWork from "../Groups/PlaceOutSideWork";
import InputText from "../Inputs/InputText";
import Province from "../Inputs/Province";
import TestCommunity from "../Inputs/TestCommunity";
import MainEconomicActivityCodeCNAE from "../Inputs/MainEconomicActivityCodeCNAE";

interface ITabContent {
  data: any;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean;
  filterByProvince?: boolean;
}

interface IAccidentLocation {
  address: "string";
  address_data: "string";
  ccc: "122332";
  center_ep2: true;
  city: "1";
  city_data: "1";
  city_description: "string";
  city_description_data: "string";
  code: 1;
  codeCNAE: null;
  codeCNAEDescription: null;
  code_description: "string";
  company_ep2: true;
  company_type: 1;
  country: "1";
  country_description: "string";
  cp: "1";
  id: "95e7aa81-4a82-4db6-ebc4-08d8bc955fe6";
  other: "string";
  pat: null;
  phone: "string";
  province: 1;
  province_data: "1";
  province_description: "string";
  province_description_data: "string";
  reason: "string";
  textCNAE: null;
  traffic: true;
  type_cif: "string";
  viakm: "string";
  workforce: "1";
}

const AccidentLocation: React.FC<ITabContent> = ({
  data,
  reset,
  watch,
  errors,
  register,
  setValue,
  disabled,
  filterByProvince
}) => {


  const [isvalidateNumberPostalCode, setIsValidateNumberPostalCode] =
    useState(false);
  const [isvalidateNumber, setIsValidateNumber] = useState(false);
  const watchCCCCompanyCnaeCode = watch("ccclocation_company_codeCNAE");
  const watchCCCCompanyProvinceCode = watch("ccclocation_company_province");
  const watchProvinceDescription = watch("ccclocation_company_provinceDescription");
  const belongsToAnotherCompany = watch("ccclocation_centerEP2");
  const watchCommunityCode = watch("ccclocation_company_city");
  const watchCommunityDescription = watch("ccclocation_company_cityDescription");
  const istrafico = watch('ccclocation_traffic')
  const maxLengthAddress = 40;
  const maxLengthPostalCode = 5;
  const maxLengthPhone = 16;
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumber(true);
    } else {
      setIsValidateNumber(false);
    }
  };
  const handleChangePostalCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumberPostalCode(true);
    } else {
      setIsValidateNumberPostalCode(false);
    }
  };
  return (
    <div className="container form--simple-styled" id="accident_location_form">
      <div className="row">
        <div className="col-12">
          <FieldsetContainer legend="Lugar del accidente" disabled={disabled} >
            <div className="col-12">
              <div className={`input-block--radio-check__group d-block  ${errors && errors['ccclocation_codeDescription']?.message ? 'error' : ''}`}>
                <label
                  htmlFor={"idA"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    1. En el centro o lugar de trabajo habitual
                  </span>
                  <input
                    type={"radio"}
                    name={"ccclocation_codeDescription"}
                    id={"idA"}
                    {...register("ccclocation_codeDescription", { required: 'Selecciona al menos una opción' })}
                    value={"1"}
                  />
                </label>
                <label
                  htmlFor={"idB"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    2. En desplazamiento en su jornada laboral (1)(2)
                  </span>
                  <input
                    type={"radio"}
                    name={"ccclocation_codeDescription"}
                    id={"idB"}
                    {...register("ccclocation_codeDescription", { required: 'Selecciona al menos una opción' })}
                    value={"2"}
                  />
                </label>
                <label
                  htmlFor={"idC"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    3. Al ir o al volver del trabajo (1)
                  </span>
                  <input
                    type={"radio"}
                    name={"ccclocation_codeDescription"}
                    id={"idC"}
                    {...register("ccclocation_codeDescription", { required: 'Selecciona al menos una opción' })}
                    value={"3"}
                  />
                </label>
                <label
                  htmlFor={"idD"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    4. En otro centro o lugar de trabajo (3)
                  </span>

                  <input
                    type={"radio"}
                    name={"ccclocation_codeDescription"}
                    id={"idD"}
                    {...register("ccclocation_codeDescription", { required: 'Selecciona al menos una opción' })}
                    value={"4"}
                  />
                </label>
                <span className="separator-line"></span>
                <label
                  htmlFor={"ccclocation_traffic"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    Marque si ha sido accidente de tráfico
                  </span>
                  <input
                    type={"checkbox"}
                    name={"ccclocation_traffic"}
                    id={"ccclocation_traffic"}
                    {...register("ccclocation_traffic", { required: 'Selecciona al menos una opción' })}
                  />
                </label>
                <div className="container-help-point-one">
                  <p className="text-help-point-one">
                    <b>(1)</b> En estos casos, los datos del centro se cumplimentarán con los correspondientes al centro de trabajo habitual.
                  </p>
                  <p className="text-help-point-one">
                    <b>(2)</b> Incluye
                    desplazamientos fuera de un centro de trabajo, tanto
                    ocasionales en cualquier actividad como habituales en
                    aquellas actividades con puesto de trabajo móvil, -
                    transportes, mantenimiento, vigilancia etc. También incluye
                    accidentes ocurridos en centros de trabajo en el extranjero
                  </p>
                  <p className="text-help-point-one"><b>(3)</b> Excepto si el centro está en el extranjero que se codificará como "En desplazamiento en su jornada laboral"</p>
                </div>
              </div>
            </div>
            {errors && (
              <p className="error">{errors['ccclocation_codeDescription'] && errors['ccclocation_codeDescription'].message}</p>
            )}
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <PlaceOutSideWork
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            disabled={disabled}
            filterByProvince={filterByProvince}
          />
        </div>
        <div className="col-12">
          <FieldsetContainer legend="Centro de trabajo" disabled={disabled}>
            <p className="col-12">
              (En aquellos trabajos que no tengan una ubicación fila -
              transporte, mantenimiento, vigilancia o trabajos con
              desplazamiento - se considera centro de trabajo aquel desde el que
              se imparten instrucciones o se organiza el trabajo)
            </p>
            <div className="col-12">
              <div className="input-block--radio-check__group d-block">
                <label
                  htmlFor={"idAa"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    El centro pertenece a la empresa en la que está dado de alta
                    el trabajador
                  </span>
                  <input
                    type={"radio"}
                    name={"ccclocation_centerEP2"}
                    id={"idAa"}
                    {...register("ccclocation_centerEP2")}
                    value={"true"}
                  />
                </label>
                <label
                  htmlFor={"idBb"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    El centro pertenece a otra empresa
                  </span>
                  <input
                    type={"radio"}
                    name={"ccclocation_centerEP2"}
                    id={"idBb"}
                    {...register("ccclocation_centerEP2")}
                    value={"false"}
                  />
                </label>
              </div>
            </div>
          </FieldsetContainer>
        </div>

        <div className="col-12 ">
          {belongsToAnotherCompany === 'false' ? (
              <FieldsetContainer legend="Marque si el centro pertenece a otra empresa ( en este caso indicar su relación con la Empresa del apartado 2 )" disabled={disabled} >
                <div className="col-12">
                  <div className="input-block--radio-check__group d-block">
                    <label
                      htmlFor={"cOs"}
                      className="input-block--radio-check__label"
                    >
                      <span className="text">Contrata o subcontrata</span>
                      <input
                        type={"radio"}
                        name={"ccclocation_CCCLugarCentroTipoEmpresaCodigo"}
                        id={"cOs"}
                        {...register("ccclocation_CCCLugarCentroTipoEmpresaCodigo", { required: belongsToAnotherCompany === 'false' })}
                        value={"1"}
                        required
                      />
                    </label>
                    <label
                      htmlFor={"uETT"}
                      className="input-block--radio-check__label"
                    >
                      <span className="text"> Usuaria de ETT</span>
                      <input
                        type={"radio"}
                        name={"ccclocation_CCCLugarCentroTipoEmpresaCodigo"}
                        id={"uETT"}
                        {...register("ccclocation_CCCLugarCentroTipoEmpresaCodigo", { required: belongsToAnotherCompany === 'false' })}
                        value={"2"}
                        required
                      />
                    </label>
                    <label
                      htmlFor={"otra"}
                      className="input-block--radio-check__label"
                    >
                      <span className="text">Otra</span>
                      <input
                        type={"radio"}
                        name={"ccclocation_CCCLugarCentroTipoEmpresaCodigo"}
                        id={"otra"}
                        {...register("ccclocation_CCCLugarCentroTipoEmpresaCodigo", { required: belongsToAnotherCompany === 'false' })}
                        value={"3"}
                        required
                      />
                    </label>
                  </div>


                  <InputText
                    id="ccclocation_CCCLugarCentroCifTipo"
                    name="ccclocation_CCCLugarCentroCifTipo"
                    label="Cumplimentar CIF o NIF / NIE"
                    placeholder="Cumplimentar CIF o NIF / NIE"
                    errors={errors}
                    register={register}
                  />


                </div>
              </FieldsetContainer>
          ) : (
            ""
          )}
          {belongsToAnotherCompany === 'true' &&
            <FieldsetContainer disabled={disabled} legend="Los datos coinciden con los de la Empresa">
              <div className="col-12">
                <div className="input-block--radio-check__group d-block">
                  <label
                    htmlFor={"ccclocation_companyEP2Yes"}
                    className="input-block--radio-check__label"
                  >
                    <span className="text">{"Sí"}</span>
                    <input
                      type={"radio"}
                      name={"ccclocation_companyEP2"}
                      id={"ccclocation_companyEP2Yes"}
                      {...register('ccclocation_companyEP2')}
                      value={"true"}
                    />
                  </label>
                  <label
                    htmlFor={"ccclocation_companyEP2No"}
                    className="input-block--radio-check__label"
                  >
                    <span className="text">{"No"}</span>
                    <input
                      type={"radio"}
                      name={"ccclocation_companyEP2"}
                      id={"ccclocation_companyEP2No"}
                      {...register("ccclocation_companyEP2")}
                      value={"false"}
                    />
                  </label>
                </div>
              </div>
              {/* <DoubleRadioCheckGroup
      legend=""
      idA="ccclocation_companyEP2Yes"
      valueA="yes"
      valueB="no"
      labelA="Sí"
      idB="ccclocation_companyEP2No"
      labelB="No"
      nameA="ccclocation_companyEP2"
      type="radio"
      errors={errors}
      register={register}
    /> */}
            </FieldsetContainer>

          }
          <FieldsetContainer legend="Datos del centro" disabled={disabled}>
            <div className="col-sm-12">
              <InputText
                id="ccclocation_reason"
                name="cccclocation_reason"
                label="Nombre o razón social"
                placeholder="Nombre o razón social"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-sm-12">
              <InputText
                id="cccclocation_company_address"
                name="cccclocation_company_address"
                label="Domicilio"
                placeholder="Domicilio"
                errors={errors}
                register={register}
                maxLength={maxLengthAddress}
              />
            </div>
            <div className="col-sm-12 col-lg-4 mt-2">
              <Province
                setValue={setValue}
                errors={errors}
                register={register}
                watchProvinceCode={watchCCCCompanyProvinceCode}
                watchProvinceDescription={watchProvinceDescription}
                idCode="ccclocation_company_province"
                idDescription="ccclocation_company_provinceDescription"
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <TestCommunity
                errors={errors}
                register={register}
                setValue={setValue}
                idCode="ccclocation_company_city"
                idDescription="ccclocation_company_cityDescription"
                watchCommunityCode={watchCommunityCode}
                watchCommunityDescription={watchCommunityDescription}
                filterByProvince={filterByProvince}
                watchProvinceCode={watchCCCCompanyProvinceCode}
              // idProvinceCode={watchCCCCompanyProvinceCode}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <InputText
                id="ccclocation_company_cp"
                name="ccclocation_company_cp"
                label="Código postal"
                placeholder="Código postal"
                errors={errors}
                register={register}
                maxLength={maxLengthPostalCode}
                handleOnChange={handleChangePostalCode}
                isvalidateNumberPostalCode={isvalidateNumberPostalCode}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <InputText
                id="ccclocation_company_phone"
                name="ccclocation_company_phone"
                label="Teléfono"
                placeholder="Teléfono"
                errors={errors}
                register={register}
                maxLength={maxLengthPhone}
                handleOnChange={handleChangePhone}
                isvalidateNumber={isvalidateNumber}
              />
            </div>
            <div className="col-xl-4 col-md-8">
              <InputText
                id="ccclocation_company_ccc"
                name="ccclocation_company_ccc"
                label="Cuenta Cotización a la Seguridad Social"
                placeholder="Cuenta Cotización a la Seguridad Social"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-4 col-md-4">
              <InputText
                id="ccclocation_company_workforce"
                name="ccclocation_company_workforce"
                label="Plantilla actual de la empresa"
                placeholder="Plantilla actual de la empresa"
                errors={errors}
                register={register}
                readonly
              />
            </div>
            <div className="col-12 mt-2">
              <MainEconomicActivityCodeCNAE
                errors={errors}
                register={register}
                watchCnaeCode={watchCCCCompanyCnaeCode}
                setValue={setValue}
                idCode="ccclocation_company_codeCNAE"
                idDescription="ccclocation_company_codeCNAEDescription"
              />
            </div>
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default AccidentLocation;
