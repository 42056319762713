import React, { useEffect, useState } from "react";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import InputText from "../Inputs/InputText";
import YesNoNoAnswer from "../Groups/YesNoNoAnswer";
import HealthcareType from "../Groups/HealthcareType";
import InjurySeverity from "../Groups/InjurySeverity";
import InjuryDescription from "../Groups/InjuryDescription";
import InjuryPartBody from "../Groups/InjuryPartBody";
import useUtils from "services/utils/useUtils";

interface ITabContent {
  data: any;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean;
}

interface IAssistenceData {
  address_doctor: "string";
  assistence_type: 1;
  code_hospital: true;
  degree: 1;
  id: "9c8ef178-717a-4c95-b285-08d8bc955fe2";
  injury: 0;
  injury_description: "string";
  name_doctor: "string";
  name_hospital: "string";
  part: 0;
  part_decription: "string";
  pat: null;
  phone_doctor: "string";
}

const Assistance: React.FC<ITabContent> = ({
  data,
  reset,
  watch,
  errors,
  register,
  disabled,
  setValue,
}) => {
  // const watchTypeInjuryCode = watch("assistance_injury");
  // const watchTypeInjuryDescription = watch("assistance_injuryDescription");
  // const watchInjuryPartOfBodyCode = watch("assistance_part");
  // const watchInjuryPartOfBodyDescription = watch("assistance_partDescription");
  const [allData, setAllData] = useState<any>();
  const [allDataInjuryPartOfBody, setAllDataInjuryPartOfBody] = useState<any>();
  const { getTypeInjury, getAllInjuryPartOfBody } = useUtils();
  useEffect(() => {
    handleGetData();
    handleGetDataInjuryPartOfBody();
  }, []);
  const handleGetData = async () => {
    const response = await getTypeInjury();
    if (response) {
      setAllData(response);
    }
  };
  const handleGetDataInjuryPartOfBody = async () => {
    const response = await getAllInjuryPartOfBody();
    if (response) {
      setAllDataInjuryPartOfBody(response);
    }
  };
  return (
    <div className="container form--simple-styled" id="assistance_form">
      <div className="row">
        <div className="col-xl-6">
          <FieldsetContainer
            legend="Descripción de la lesión"
            disabled={disabled}
          >
            <div className="col-12">
              <InjuryDescription
                arraData={allData && allData}
                id="assistance_injury"
                errors={errors}
                register={register}
                title="Tipo de lesión"
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-xl-6">
          <FieldsetContainer
            disabled={disabled}
            legend="Parte del cuerpo lesionada"
          >
            <div className="col-12">
              <InjuryPartBody
                arraData={allDataInjuryPartOfBody && allDataInjuryPartOfBody}
                id="assistance_part"
                errors={errors}
                register={register}
                title="Parte del cuerpo"
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer legend="Grado de la lesión" disabled={disabled}>
            <InjurySeverity
              errors={errors}
              register={register}
              name="assistance_degree"
            />
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer
            disabled={disabled}
            legend="Médico que efectúa la asistencia inmediata"
          >
            <div className="col-xl-8">
              <InputText
                id="assistance_nameDoctor"
                name="assistance_nameDoctor"
                label="Nombre"
                placeholder="Nombre"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-4">
              <InputText
                id="assistance_phoneDoctor"
                name="assistance_phoneDoctor"
                label="Teléfono"
                placeholder="Teléfono"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-8">
              <InputText
                id="assistance_addressDoctor"
                name="assistance_addressDoctor"
                label="Domicilio"
                placeholder="Domicilio"
                errors={errors}
                register={register}
              />
            </div>
            {/* <div className="col-xl-4">
              <InputText 
                id="assistance_codeHospital"
                name="assistance_codeHospital"
                label="Núm. Colegiado (Parte Baja)"
                placeholder="Núm. Colegiado (Parte Baja)"
                errors={errors}
                register={register}
              />
            </div> */}
          </FieldsetContainer>
        </div>
        <div className="col-xl-4">
          <FieldsetContainer
            disabled={disabled}
            legend="Tipo de asistencia sanitaria"
          >
            <HealthcareType
              errors={errors}
              register={register}
              name="assistance_assistenceType"
            />
          </FieldsetContainer>
        </div>
        <div className="col-xl-4">
          <FieldsetContainer
            disabled={disabled}
            legend="Marque si ha sido hospitalizado"
          >
            <YesNoNoAnswer
              yesId="riskAssessmentYes"
              noId="riskAssessmentNo"
              noAnswerId="riskAssessmentNoAnswer"
              name="riskAssessment"
              errors={errors}
              register={register}
            />
          </FieldsetContainer>
        </div>
        <div className="container col-xl-12">
        <FieldsetContainer
          disabled={disabled}
          legend="	Nombre del Establecimiento donde ha sido hospitalizado"
        >
        
            <InputText
              id="assistance_codeHospital"
              name="assistance_codeHospital"
              label=""
              placeholder=""
              errors={errors}
              register={register}
            />
         
        </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Assistance;
