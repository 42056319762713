import React from 'react';
import {NavLink} from 'react-router-dom';
import {Link} from 'react-router-dom';

import logoblanco from "../../../img/solimat-logo-blanco.svg";
import phone from "../../../img/07.svg";

import './Footer.scss';

interface IFooterProps {
  dataSource: any
}

const Footer: React.FC<IFooterProps> = ({dataSource}) => {

  return (
    <footer className="footer-block">
      <div className="footer-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-md-3 offset-md-1">
              <div className="col-container">
                <img className="logo" src={logoblanco} alt="solimat" />
              </div>
            </div>
            <div className="col-sm-4 col-md-3 offset-md-1">
              <div className="col-container tel-info-container">
                <a href="tel:900111072" className="tel-link">
                  <img src={phone} alt="" />
                  <span className="tel-title">Teléfono Urgencias:</span>
                  <span className="tel-number">900 111 072</span>
                </a>
              </div>
            </div>
            <div className="col-sm-4 col-md-3 offset-md-1">
              <nav className="col-container nav-container">
                {dataSource.map((item:any, i:number) => {
                  return (
                  <NavLink to={item.path} 
                    className="nav_link"
                    key={i}>
                    {item.text}
                  </NavLink>
                  );
                })}
                <Link to={"/red-de-centros"} 
                  className="nav_link"
                >
                  {"Red de centros"}
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <p className="copy"><small>&copy; Solimat. All rights reserved.</small></p>
    </footer>
  );
}


export default Footer;