import React from "react";
import PrintButton from "./PrintButton";
interface IPropertyResult {
  Item1: string;
  Item2: string;
}

interface IValidationResult {
  Result: boolean;
  PropertyResult: IPropertyResult[];
}
interface IButtons {
  handleReset: ()=> void;
  handleCancel: ()=> void
  handleVerify?: () => Promise<IValidationResult | { errorInServer: string }>;
  submitForm: (values?:any) => void;
  DomFormId: string;
  loading: boolean;
  tabSelected: number;
  formRef?: React.RefObject<HTMLDivElement>;
  toggleComponentVisibility?: (show: boolean) => void;
  disabled: boolean;
  isExtinct?:boolean;
}

const Buttons: React.FC<IButtons> = ({
  handleReset,
  handleVerify,
  handleCancel,
  DomFormId,
  loading,
  tabSelected,
  formRef,
  toggleComponentVisibility,
  disabled,
  submitForm,
  isExtinct
}) => {
  return (
    <div className="delta-buttons c-right">
      {!disabled && (
        <button className="btn-box btn-accent" onClick={handleCancel}>
          Cancelar
        </button>
      )}
      {tabSelected === 6 && !disabled ? (
        <button
          type="submit"
          className={`btn-box btn-accent loading ${loading ? "active" : ""}`}
          disabled={disabled}
          onClick={()=>submitForm()}
        >
          <div className="loader-btn"></div>
          {loading ? "" : "Verificar"}
        </button>
      ) : (
        ""
      )}
      {
        !isExtinct && (
          <PrintButton
          DomIdPrint={DomFormId}
          success={false}
          formRef={formRef}
          toggleComponentVisibility={toggleComponentVisibility}
        />
        )

      }
     
    </div>
  );
};

export default Buttons;
