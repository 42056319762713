import {useState} from 'react';

import {company} from '@solimat/solimat-web-endpoint';

const useGetProvinces = () => {
  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";
  const apiCompany = new company.Center(BASE_URL, FUNCTIONS_KEY)
  const [successGetProvinces, setSuccessGetProvinces] = useState<boolean>(false)
  const [errorGetProvinces, setErrorGetProvinces] = useState<boolean>(false)
  const [loadingGetProvinces, setLoadingGetProvinces] = useState<boolean>(false)



  const getProvinces = async () => {
    setLoadingGetProvinces(true)
    try {
      const response = await apiCompany.getProvinces();
      setSuccessGetProvinces(true)
      setErrorGetProvinces(false)
      setLoadingGetProvinces(false)

      return response.data;
    }
    catch(err) {
      console.error(err);
      setLoadingGetProvinces(false)
      setSuccessGetProvinces(false)
      setErrorGetProvinces(true)
    }
  }

  return { 
    getProvinces,
    successGetProvinces,
    errorGetProvinces,
    loadingGetProvinces
  };
}

export default useGetProvinces;