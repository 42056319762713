import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";


const useGetEpisodeOperatingRoom = () => {
const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
const [loadingGetEpisodeOperatingRoom, setLoadingGetEpisodeOperatingRoom] = useState(false);
const [successGetEpisodeOperatingRoom, setSuccessGetEpisodeOperatingRoom] = useState(false);
const [errorGetEpisodeOperatingRoom, setErrorGetEpisodeOperatingRoom] = useState(null);
const [episodeOperatingRoom, setEpisodeOperatingRoom] = useState<null|any>(null);

const getEpisodeOperatingRoom = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisodeOperatingRoom(true);
    setSuccessGetEpisodeOperatingRoom(false);
    apiRecord.getEpisodeOperatingRoom(userId,prestacionId)
      .then((res:any) => {       
         
        setLoadingGetEpisodeOperatingRoom(false);
        setSuccessGetEpisodeOperatingRoom(true);
        setEpisodeOperatingRoom( res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisodeOperatingRoom(false);
        setErrorGetEpisodeOperatingRoom(err)
      })

    }
      return {
        episodeOperatingRoom,
        getEpisodeOperatingRoom,
        loadingGetEpisodeOperatingRoom,
        successGetEpisodeOperatingRoom,
        errorGetEpisodeOperatingRoom
        };

    
  }
  export default useGetEpisodeOperatingRoom;