import React, { useState, useEffect } from "react";
import useUtils from "services/utils/useUtils";

interface IInputProps {
  reset: any;
  errors: any;
  register: any;
  setValue: any;
  id: string;
  title: string;
  concatCode: string;
  code2name: string;
  children?: any;
  watch?: any;
}

const MaterialAgent: React.FC<IInputProps> = ({
  reset,
  errors,
  register,
  setValue,
  id,
  title,
  concatCode,
  code2name,
  children,
  watch,
}) => {
  const [data, setData] = useState<any[] | null>(null);
  const [select1, setSelect1] = useState<any[] | null>(null);
  const [select2, setSelect2] = useState<any[] | null>(null);
  const [select3, setSelect3] = useState<any[] | null>(null);
  const [select1value, setSelect1value] = useState<string | null>(null);
  const [select2value, setSelect2value] = useState<string | null>(null);
  const [defaultSelect, setDefaultSelect] = useState<any | null>(null);

  const { getAllMaterialAgent } = useUtils();

  useEffect(() => {
    const dataMaterial = getAllMaterialAgent();
    dataMaterial.then((data) => {
      setData(data);
    });
  }, []);

  useEffect(() => {
    if (concatCode) {
      const selectCode1 = concatCode.slice(0, 2);
      const selectCode2 = concatCode.slice(2, 4);
      const selectCode3_1 = concatCode.slice(4, 6);
      const selectCode3_2 = concatCode.slice(6, 8);
      const selectCode3 = `${selectCode3_1}${selectCode3_2}`;
      setDefaultSelect({
        select1: selectCode1,
        select2: selectCode2,
        select3: selectCode3,
      });
    }
  }, [concatCode]);

  useEffect(() => {
    if (!defaultSelect || !data) return;

    if (defaultSelect?.select1 && data) {
      setValue(`code1_${id}`, defaultSelect.select1);
      handleFilterSelect2(defaultSelect.select1, true);
    }
  }, [defaultSelect, data]);

  useEffect(() => {
    if (!data) return;
    handleFilterSelect1(data);
  }, [data]);

  const handleFilterSelect1 = (data: any[]) => {
    const filteredSelect1: any[] = [];
    data.forEach((element) => {
      const existCode = filteredSelect1.filter(
        (item: any) => item.Codigo1 === element.Codigo1
      );
      if (existCode.length < 1) {
        filteredSelect1.push(element);
      }
    });
    setSelect1(filteredSelect1);
  };

  const handleFilterSelect2 = (select1Value: string, isInitial = false) => {
    setSelect1value(select1Value);
    if (select1Value === "") {
      setSelect2([]);
      setSelect3([]);
      setValue(`code1_${id}`, "");
      setValue(`code2_${id}`, ""); 
      setValue(`code3_${id}`, ""); 
      setDefaultSelect(null); 
      return;
    }
    if (data?.length) {
      const filteredSelect2 = data?.filter(
        (item: any) => item.Codigo1 === select1Value && item.Codigo3 === "0000"
      );
      setSelect2(filteredSelect2);

      if (isInitial && defaultSelect?.select2) {
        setValue(`code2_${id}`, defaultSelect.select2);
        setSelect2value(defaultSelect.select2);
      } else {
        setSelect2value(null);
      }
    }
  };

  useEffect(() => {
    if (!select1value || !select2value || !data) return;
    const filteredSelect3 = data?.filter(
      (item: any) =>
        item.Codigo1 === select1value &&
        item.Codigo2 === select2value
    );
    setSelect3(filteredSelect3);

    if (defaultSelect?.select3) {
      setValue(`code3_${id}`, defaultSelect.select3);
    }
  }, [select1value, select2value, data]);

  const handleChange = (e: any, select: number) => {
    switch (select) {
      case 1:
        handleFilterSelect2(e.target.value);
        return;
      case 2:
        setSelect2value(e.target.value);
        if (e.target.value === "") {
          setSelect3([]);
          setValue(`code3_${id}`, "")
        }
        return;
      default:
        return;
    }
  };
  return (
    <>
      <fieldset
        className={`input-block ${(errors && errors[`code1_${id}`]?.message) ||
          (errors && errors[`code2_${id}`]?.message) ||
          (errors && errors[`code3_${id}`]?.message)
            ? "error"
            : ""
          }`}
      >
        <legend className="input-block--code__legend">{title}</legend>
        {select1 !== null && select1?.length > 0 ? (
          <select
            name={`code1_${id}`}
            id={`code1_${id}`}
            {...register(`code1_${id}`)}
            onChange={(e) => handleChange(e, 1)}
          >
            <option value={""}>Elija Opción</option>
            {select1?.map((item: any) => {
              return (
                <option key={item.Codigo1 * 2} value={item.Codigo1}>
                  {item.Codigo1} - {item.Descripcion1}
                </option>
              );
            })}
          </select>
        ) : null}
        {select2 !== null && select2?.length > 0 ? (
          <select
            name={`code2_${id}`}
            id={`code2_${id}`}
            {...register(`code2_${id}`)}
            onChange={(e) => handleChange(e, 2)}
          >
            <option value={""}>Elija Opción</option>
            {select2?.map((item: any) => {
              return (
                <option key={item.Codigo2} value={item.Codigo2}>
                  {item.Codigo2} - {item.Descripcion2}
                </option>
              );
            })}
          </select>
        ) : null}
        {(select2value !== null && select2value !== "") && (select3 !== null && select3?.length > 0) ? (
          <select
            name={`code3_${id}`}
            id={`code3_${id}`}
            {...register(`code3_${id}`)}
            onChange={(e) => handleChange(e, 3)}
          >
            <option value={""}>Elija Opción</option>
            {select3?.map((item: any) => {
              return (
                <option key={item.Codigo3} value={item.Codigo3}>
                  {item.Codigo3} - {item.Descripcion3}
                </option>
              );
            })}
          </select>
        ) : null}
        {errors && (
          <p className="error m-0">
            {errors[`code1_${id}`]?.message}
            {errors[`code2_${id}`]?.message}
            {errors[`code3_${id}`]?.message}
          </p>
        )}
      </fieldset>
      {children}
    </>
  );
};

export default MaterialAgent;
