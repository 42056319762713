import React, { useEffect, useState, useContext } from "react";
import Moment from "moment";
import "./Home.scss";
import { SignalRContext } from "../../../contexts/SignalRContext";
import useGetAllAvailableRecordByUser from "../../../services/recordSheets/useGetAllAvailableRecordByUser";
import { Link } from "react-router-dom";
// import CommunicationsGridGeneral from "components/blocks/Grids/CommunicationsGridGeneral";
import RecordsGridHome from "components/blocks/Grids/RecordsGridHome";
import useGetRole from 'hooks/useGetRole';

function Carrusel(props) {
  const [records, setRecords] = useState([]);
  const [lastCommunication, setLastCommunication] = useState({});
  const [daysPassed, setDaysPassed] = useState(10);
  const { allNotifications } = useContext(SignalRContext);
  const {
    getAllAvailableRecordByUserS,
    userId,
  } = useGetAllAvailableRecordByUser();
  const checkDaysPassed = (initial_date) => {
    if (initial_date !== null && initial_date !== undefined) {
      return Moment().diff(Moment(initial_date), "days");
    } else {
      return "";
    }
  };

  const isImpersonation = useGetRole.isImpersonation();

  useEffect(() => {
    getAllAvailableRecordByUserS(userId, setRecords);
    let orderedAscendingData = [...allNotifications].sort(
      (a, b) =>
        new Date(b.creation_date).getTime() -
        new Date(a.creation_date).getTime()
    );
    const communication = orderedAscendingData[0];
    setLastCommunication(communication);
    return () => {
      setLastCommunication({});
      setRecords({})
    };
  }, []);

  useEffect(() => {
    if (lastCommunication) {
      let days = checkDaysPassed(lastCommunication.creation_date) + " días";
      setDaysPassed(days);
    }
    return () => {
      setDaysPassed(0);
    };
  }, []);

  return (
    <div
      id="carouselExampleFade"
      className="carousel  carousel-dark slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-interval="true"
    >
      <div className="carousel-indicators ">
        <button
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        {(!isImpersonation) &&
          <button
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>}
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="5000">
          <div className="container-item-carrusel-img">
            <div
              style={{
                width: "100%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              {/* <img src={img1} className="d-block w-100" alt="../../../img/01.jpg" /> */}
              <div className="carousel-caption  d-md-block container-text-img-home">
                <h2>Solimat</h2>
                <h4>Portal Pacientes</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="5000">
          <div className="container-imgs-carrusel ">
            <div className="container container-imgs-carrusel">
              <br />
              <h2 className="section-title_big title-carrusel-record ">
                Historial
              </h2>
              <div className="container-historial-carrusel">
                <RecordsGridHome arrData={records && records} props={props} />
                <div className="opaci ">
                  <Link to="/historial" className="btn-text icon-right">
                    Consultar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(!isImpersonation) && <div className="carousel-item" data-bs-interval="5000">
          <div style={{ width: "100%", height: "500px" }}>
            <div className="notifications-carrusel">
              <h2 className="section-title_big">Notificaciones</h2>
              <div className="container-text-notifications-carrusel">
                <div className="">
                  {lastCommunication && (
                    <>
                      {lastCommunication.fechaHora && (
                        <h3>Hace {daysPassed}</h3>
                      )}
                      <p>{lastCommunication.mensaje}</p>
                    </>
                  )}
                  <Link to="/notificaciones" className="btn-text icon-right">
                    Consultar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        <button
          className="carousel-control-prev prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carrusel;
