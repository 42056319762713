import { useState } from 'react';

import Utils from "@solimat/solimat-web-endpoint/lib/utils";;

const useGetMainCenters = () => {
  const BASE_URL = process.env.REACT_APP_RED_CENTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_RED_CENTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY)
  const [successGetMainCenters, setSuccessGetMainCenters] = useState<boolean>(false)
  const [errorGetMainCenters, setErrorGetMainCenters] = useState<boolean>(false)
  const [loadingGetCenterByProvince, setLoadingGetMainCenters] = useState<boolean>(false)
  const [mainCenters, setMainCenters] = useState([])


  const getMainCenters = async () => {
    setLoadingGetMainCenters(true)
    try {
      const response = await apiUtils.getMainCenters();
      setMainCenters(response.data)
      setSuccessGetMainCenters(true)
      setErrorGetMainCenters(false)
      setLoadingGetMainCenters(false)

      return response.data;
    }
    catch (err) {
      console.error(err);
      setLoadingGetMainCenters(false)
      setSuccessGetMainCenters(false)
      setErrorGetMainCenters(true)
    }
  }

  return {
    getMainCenters,
    mainCenters,
    successGetMainCenters,
    errorGetMainCenters,
    loadingGetCenterByProvince
  };
}

export default useGetMainCenters;