import React, { useEffect } from "react";
import Moment from "moment";
import "../ManagementSheets.scss";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import useDecode from "hooks/useDecode";
import EntornoLaboral from "../Comun/EntornoLaboral";
import LoaderMin from "components/blocks/Loader/LoaderMin";

function GastosFarmacia({ props }: any) {
  const gastoFarmaciaId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getPharmacyExpense,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(() => {
    getPharmacyExpense(userId, prestacionId, gastoFarmaciaId);
    Moment().locale("es");
  }, []);

  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
 
  return(

    <>
    {dataEconomicSheet === null ? (
      <section className="container">
        <p className="loader-text">No hay datos para mostrar.</p>
      </section>
    ) : (
      <section className="container">
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{"Gastos Farmacia"}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Medicamento"}</label>
                <input
                  type="text"
                  id="medicamento"
                  className="item-value"
                  value={dataEconomicSheet.medicamento}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Cantidad"}</label>
                <input
                  type="text"
                  id="cantidad"
                  className="item-value"
                  value={dataEconomicSheet.cantidad}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Fecha Gasto"}</label>
                <input
                  type="text"
                  id="fechaGasto"
                  className="item-value"
                  value={
                    dataEconomicSheet.fechaGasto !== null
                      ? Moment(dataEconomicSheet.fechaGasto).format("L")
                      : ""
                  }
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Fecha Pago"}</label>
                <input
                  type="text"
                  id="fechaPago"
                  className="item-value"
                  value={
                    dataEconomicSheet.fechaPago !== null
                      ? Moment(dataEconomicSheet.fechaPago).format("L")
                      : ""
                  }
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Importe"}</label>
                <input
                  type="text"
                  id="importe"
                  className="item-value"
                  value={`${dataEconomicSheet.importe}€`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Médico"}</label>
                <input
                  type="text"
                  id="medico"
                  className="item-value"
                  value={dataEconomicSheet.medico}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Pagado"}</label>
                <input
                  type="text"
                  id="estaPagado"
                  className="item-value"
                  value={dataEconomicSheet.estaPagado === true ? 'Si': 'No'}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{"Excluido"}</label>
                <input
                  type="text"
                  id="estaExcluido"
                  className="item-value"
                  value={dataEconomicSheet.estaExcluido === true ? 'Si': 'No'}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <EntornoLaboral
        id={'EntornoLaboral'}
        nombre={dataEconomicSheet.work_environment.nombre}
        CCC={dataEconomicSheet.work_environment.ccc}
        CIF={dataEconomicSheet.work_environment.cif}
      /> */}
      </section>
    )}
  </>
  );
}

export default GastosFarmacia;
