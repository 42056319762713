import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";

const useGetRoyalDecreeForms = () => {
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
  const [loadingGetRoyalDecreeForms, setLoadingGetRoyalDecreeForms] =
    useState(false);
  const [successGetRoyalDecreeForms, setSuccessGetRoyalDecreeForms] =
    useState(false);
  const [errorGetRoyalDecreeForms, setErrorGetRoyalDecreeForms] =
    useState(null);
  const [royalDecreeForms, setRoyalDecreeForms] = useState<
    null | any
  >(null);

  const getRoyalDecreeForms = (
    userId: string,
    prestacionId: string,
    _cb?: (data: any) => void
  ) => {
    setLoadingGetRoyalDecreeForms(true);
    setSuccessGetRoyalDecreeForms(false);
    apiRecord
      .getRoyalDecreeForms(userId, prestacionId)
      .then((res: any) => {
        setLoadingGetRoyalDecreeForms(false);
        setSuccessGetRoyalDecreeForms(true);
        setRoyalDecreeForms(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingGetRoyalDecreeForms(false);
        setErrorGetRoyalDecreeForms(err);
      });
  };
  return {
    royalDecreeForms,
    getRoyalDecreeForms,
    loadingGetRoyalDecreeForms,
    successGetRoyalDecreeForms,
    errorGetRoyalDecreeForms,
  };
};
export default useGetRoyalDecreeForms;