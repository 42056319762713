import React, { useEffect, useState } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import IconHelp from "../../../../img/icons/Delta/question.png";
import OcupationCode from "./OcupationCode";
import { useFormContext } from "react-hook-form";

interface IDoubleInputProps {
  legend: string;
  nameCode: string;
  idCode: string;
  nameDescription: string;
  idDescription: string;
  placeholderDescription: string;
  errors: any;
  register: any;
  setValue: any;
  watchCnoCode: any;
  watchCnoDescription: any;
  codeCNO: string;
}

const OcupationCode2: React.FC<IDoubleInputProps> = ({
  idCode,
  nameCode,
  idDescription,
  nameDescription,
  legend,
  placeholderDescription,
  errors,
  register,
  setValue,
  watchCnoCode,
  watchCnoDescription,
  codeCNO,
}) => {
  const { getAllCno3Digi, onlyDigitsWithMaxLength } = useUtils();
  const [data, setData] = useState<any[] | null>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [autoCompleteVisible, setAutoCompleteVisible] = useState(false);
  const [codeFromOcupationCode, setCodeFromOcupationCode] = useState("");

  useEffect(() => {
    handleGetAllCno();
  }, []);

  const handleGetAllCno = async () => {
    const response = await getAllCno3Digi();
    if (response) {
      const filteredData = response.filter((item: any) => item.Nivel === '4');
      setData(filteredData);
    } else {
    }
  };

  const { getValues } = useFormContext();

  useEffect(() => {
    setValueCnoDescription();
  }, [watchCnoCode]);

  useEffect(() => {
    if (codeFromOcupationCode && data) {
      const matchingEntry = data.find((item) => item.Codigo === codeFromOcupationCode);
      if (matchingEntry) {
        setValue(idDescription, matchingEntry.Descripcion);
      }
    }
  }, [codeFromOcupationCode, data]);

  const handleChangeAutoComplete = (args: any) => {
    const selectedDescription = args.value;
    if (selectedDescription === "" || selectedDescription === null) {
      setCodeFromOcupationCode("");
      setValue(nameCode, "");
      setValue(idCode, "");
      setValue(idDescription, "");
    } else if (data) {
      const matchingEntry = data.find((item) => item.Descripcion === selectedDescription);
      if (matchingEntry) {
        setCodeFromOcupationCode(matchingEntry.Codigo);
        setValue(nameCode, matchingEntry.Codigo);
        setValue(idCode, matchingEntry.Codigo);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCodeFromOcupationCode(value);
    if (value === "") {
      setValue(idDescription, "");
    }
  };

  const setValueCnoDescription = () => {
    if (data) {
      const descriptionValue = data.find((item: any) => item.Codigo === watchCnoCode);
      setValue(idDescription, descriptionValue ? descriptionValue.Descripcion : "");
    }
  };

  const handleSearchVisible = () => {
    setSearchVisible(!searchVisible);
    setValue(idCode, '');
    setValue('worker_cno_code1', '')
    setValue('worker_cno_code2', '')
    setValue('worker_cno_code3', '')
    setValue('worker_cno_code4', '')
    setAutoCompleteVisible(!autoCompleteVisible)
  };

  return (
    <fieldset
      className={`input-block input-block--code ${(errors && errors[nameCode]?.message) || (errors && errors[idDescription]?.message)
          ? "error"
          : ""
        }`}
    >
      <div className="container-leyend">
        <legend className="input-block--code__legend">
          {legend}{" "}
          <span>
            <img
              className="icon-help-delta"
              src={IconHelp}
              alt=""
              onClick={() => handleSearchVisible()}
            />
          </span>
        </legend>
      </div>
      {searchVisible && (
        <OcupationCode
          errors={errors}
          register={register}
          codeCNO={codeCNO}
          handlesetCodeFromOcupationCode={setCodeFromOcupationCode}
        />
      )}

      <div className="row">
        <div className="col-sm-12 col-lg-3">
          <input
            className=""
            type="text"
            name={nameCode}
            id={idCode}
            {...register(nameCode)}
            maxLength={3}
            onChange={handleInputChange}
            onKeyDown={(e) => onlyDigitsWithMaxLength(e, getValues(nameCode), 3)}
            disabled={autoCompleteVisible}
          />
        </div>

        <div className="col-sm-12 col-lg-8" >
          <AutoCompleteComponent
            id={idDescription}
            name={idDescription}
            dataSource={data}
            fields={{ value: "Descripcion" }}
            placeholder={placeholderDescription}
            autofill={true}
            highlight={true}
            change={handleChangeAutoComplete}
            {...register(idDescription)}
            disabled={autoCompleteVisible}
            onBlur={() => {
              const value = getValues(idDescription);
              if (value === "") {
                setValue(idCode, "");
                setValue(nameCode, "");
              }
            }}
          />
        </div>
      </div>

      {errors && (
        <p className="error m-0">
          {errors[nameCode]?.message}
          {errors[idDescription]?.message}
        </p>
      )}
    </fieldset>
  );
};

export default OcupationCode2;
