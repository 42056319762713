import React, { useState, useEffect, useRef } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import './modal-dialog.css';
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
const FormOTPPhoneEmail = ({ openOTPModal, showOTPModal, getOtpPhoneEmail, completeUser, closeOTPModal, modalTitle, hasChangedMail, hasChangedPhone, newPhone, newEmail, values, onSubmitWithOTP, loadingSaveAddress, updateError, setModalTitle, setUpdateError, loading }) => {
    let dialogInstance = useRef(null);
    const [display, setDisplay] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const settings = { effect: 'Zoom', duration: 400, delay: 0 };
    const [errorPhone, setErrorPhone] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorboth, setErrorBoth] = useState(false)



    useEffect(() => {

        if (Object.keys(updateError).length !== 0 && updateError && updateError?.response?.data.telefono.status !== 0 && !errorboth) {
            reset({
                otpPhone: ''
            })
            setErrorPhone(true)
            setErrorBoth(false)

        }
        if (Object.keys(updateError).length !== 0 && updateError && updateError?.response?.data.email.status !== 0 && !errorboth) {
            reset({
                otpEmail: ''
            })
            setErrorEmail(true)
            setErrorBoth(false)
        }
        if (Object.keys(updateError).length !== 0 && updateError && updateError?.response?.data.email.status !== 0 && updateError && updateError?.response?.data.telefono.status !== 0) {
            reset({
                otpEmail: '',
                otpPhone: ''
            })
            setErrorBoth(true)

        }

    }, [showOTPModal, updateError]);
    useEffect(() => {



    }, [hasChangedMail, hasChangedPhone, modalTitle, updateError, errorboth])

    const dialogClose = () => {

        reset({
            otpEmail: '',
            otpPhone: ''
        })
        setErrorPhone(false);
        setErrorEmail(false);
        setErrorBoth(false);
        setUpdateError({})
        setDisplay(false);
        closeOTPModal();

    };
    const handleGenerateEmailCode = () => {
        setModalTitle('Hemos enviado de nuevo código a su email')
        setUpdateError({})
        getOtpPhoneEmail(completeUser.id, null, newEmail)

        setErrorPhone(false);
        setErrorEmail(false);
        setErrorBoth(false);

    }
    const handleGeneratePhoneCode = () => {
        setModalTitle('Hemos enviado de nuevo código a su teléfono')
        setUpdateError({})
        getOtpPhoneEmail(completeUser.id, newPhone, null)
        setErrorPhone(false);
        setErrorEmail(false);
        setErrorBoth(false);

    }
    const handleGenerateBothCode = () => {
        setModalTitle('Hemos enviado de nuevo código a su email y su teléfono')
        setUpdateError({})
        setErrorPhone(false);
        setErrorEmail(false);
        setErrorBoth(false);
        setTimeout(() => {
            getOtpPhoneEmail(completeUser.id, newPhone, newEmail);
        }, 100);
    }
    const onSubmit = (data) => {

        onSubmitWithOTP(values, data?.otpPhone || '', data?.otpEmail || '');
        // dialogClose();


    };

    const hideFirstDigits = (phone) => {
        return '****' + phone?.substring(phone?.length - 4);
    };
    const hideEmail = (email) => {
        const atIndex = email?.indexOf('@');
        const username = email?.substring(0, atIndex);
        return username + '@***';
    };
    return (
        <>
            <Modal
                show={showOTPModal}
                onHide={dialogClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                ref={dialogInstance}
            >
                <Modal.Header closeButton>
                    <Modal.Title> 
                        <h5 className='title-otp-modal'>{modalTitle}</h5>
                        {updateError?.response?.status === 500 ?

                            <p className='form-error'> No hemos podido procesar su peticion </p> : ''
                        }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="otpFormEmailPhone">
                        {hasChangedPhone && !hasChangedMail &&
                            <div className="form-group">
                                <label htmlFor="otpPhone">Código para teléfono: {hideFirstDigits(newPhone)}</label>
                                <input type="text" className="form-control" id="otpPhone" name="otpPhone"  {...register("otpPhone", {
                                    required: "Campo requerido",
                                    maxLength: {
                                        value: 6,
                                        message: "Máximo 6 caracteres",
                                    }
                                })} />
                                {updateError && errorPhone &&
                                    <p className='form-error'>{updateError?.response?.data.telefono.message}</p>
                                }
                                {errors.otpPhone && (
                                    <p className="form-error">
                                        {errors["otpPhone"].message}
                                    </p>
                                )}
                            </div>

                        }
                        {!hasChangedPhone && hasChangedMail &&
                            <div className="form-group">
                                <label htmlFor="otpEmail">Código para correo electrónico: {hideEmail(newEmail)}</label>
                                <input type="text" className="form-control" id="otpEmail" name="otpEmail" {...register("otpEmail", {
                                    required: "Campo requerido",
                                    maxLength: {
                                        value: 6,
                                        message: "Máximo 6 caracteres",
                                    }
                                })} />
                                {updateError && errorEmail &&
                                    <p className='error'>{updateError?.response?.data.email.message}</p>
                                }
                                {errors.otpEmail && (
                                    <p className="form-error">
                                        {errors["otpEmail"].message}
                                    </p>
                                )}
                            </div>
                        }
                        {hasChangedPhone && hasChangedMail &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="otpPhone">Código para teléfono: {hideFirstDigits(newPhone)}</label>
                                    <input type="text" className="form-control" id="otpPhone" name="otpPhone"    {...register("otpPhone", {
                                        required: "Campo requerido",
                                        maxLength: {
                                            value: 6,
                                            message: "Máximo 6 caracteres",
                                        }
                                    })} />
                                    {updateError &&
                                        <p className='form-error'>{updateError?.response?.data.telefono.message}</p>
                                    }
                                    {errors.otpPhone && (
                                        <p className="form-error">
                                            {errors["otpPhone"].message}
                                        </p>
                                    )}

                                </div>

                                <div className="form-group">
                                    <label htmlFor="otpEmail">Código para correo electrónico: {hideEmail(newEmail)}</label>
                                    <input type="text" className="form-control" id="otpEmail" name="otpEmail" {...register("otpEmail", {
                                        required: "Campo requerido",
                                        maxLength: {
                                            value: 6,
                                            message: "Máximo 6 caracteres",
                                        }
                                    })} />
                                    {updateError &&
                                        <p className='form-error'>{updateError?.response?.data.email.message}</p>
                                    }
                                    {errors.otpEmail && (
                                        <p className="form-error">
                                            {errors["otpEmail"].message}
                                        </p>
                                    )}
                                </div>
                            </>
                        }


                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <div className="buttons-form-otp">
                        {updateError?.response?.data.telefono.status === 4 && !errorboth &&
                            <div className="container-btn-sbm-otp">
                                <button

                                    value="Generar Código"
                                    className={`btn-box btn-clear`}
                                    onClick={() => { handleGeneratePhoneCode() }}

                                >

                                    Volver a Generar Código Teléfono
                                </button>
                            </div>


                        }
                        {updateError?.response?.data.email.status === 4 && !errorboth &&
                            <div className="container-btn-sbm-otp" >
                                <button

                                    value="Generar Código"
                                    className={`btn-box btn-clear`}
                                    onClick={() => { handleGenerateEmailCode() }}

                                >

                                    Volver a Generar Código Email
                                </button>
                            </div>


                        }
                        {updateError?.response?.data.telefono.status === 4 && updateError?.response?.data.email.status === 4 && errorboth &&

                            <div className="container-btn-sbm-otp">
                                <button

                                    value="Generar Código"
                                    className={`btn-box btn-clear`}
                                    onClick={() => { handleGenerateBothCode() }}

                                >

                                    Generar Códigos Telefono-Email
                                </button>
                            </div>


                        }
                        <Button className={`btn-box btn-accent loading btn-otpep ${loading ? "active" : ""}`} onClick={handleSubmit(onSubmit)}><div className="loader-btn"></div>
                            {loading ? "" : "Enviar"}</Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* </div> */}

        </>
    );
}

export default FormOTPPhoneEmail;
