import imageInicio from '../../../img/icons/ayuda/Inicio.png';
import Inicio1 from '../../../img/icons/ayuda/Inicio1.png'
import Inicio2 from '../../../img/icons/ayuda/Inicio2.png'
import Inicio3  from '../../../img/icons/ayuda/Inicio3.png'
import Inicio4 from '../../../img/icons/ayuda/Inicio4.png'
import imageMisCitas from '../../../img/icons/ayuda/Miscitas.png';
import imageGestiones from '../../../img/icons/ayuda/Misgestiones.png';
import imageNotificaciones from '../../../img/icons/ayuda/Notificaciones.png';
import imagePrestaciones from '../../../img/icons/ayuda/Prestacion.png';
import imageHistorial from '../../../img/icons/ayuda/Historial.png';
export const dataAyuda = [
    {

        "id": "0",
        "url": imageInicio,
        "name": "Inicio"

    },
    {

        "id": "1",
        "url": imageMisCitas,
        "name": "Citas"

    },
    {

        "id": "2",
        "url":imageGestiones,
        "name": "Gestiones"

    },
    {

        "id": "5",
        "url": imageHistorial,
        "name": "Historial"

    },
    {

        "id": "4",
        "url":imagePrestaciones,
        "name": "Prestaciones"

    },
    {

        "id": "3",
        "url": imageNotificaciones,
        "name": "Notificaciones"

    },
   
]
export const dataInicio = [

    // {
    //     id:'0',
    //     url: imageInicio,
    //     name: "Inicio"

    // }
    // ,
    {
        id:'1',
        url: Inicio1,
        "name": "Inicio-proxima-cita"

    },
    {
        id:'1',
        url: Inicio2,
        name: "Inicio-red-centros"

    },
    {
        id:'1',
        url: Inicio3,
        name: "Inicio-comunicar-gestor"

    },
    {
        id:'1',
        url: Inicio4,
        name: "Inicio-esquema-general"

    }
]
export const dataMisCitas = [

    {
        id:'0',
        url: imageMisCitas,
        "name": "Citas"

    }
]
export const dataMisGestiones = [

    {
        id:'0',
        url: imageGestiones,
        "name": "Gestiones"

    }
]
export const dataHistorial = [

    {
        id:'0',
        url: imageHistorial,
        "name": "Historial"

    }
]
export const dataMisNotificaciones = [

    {
        id:'0',
        url: imageNotificaciones,
        "name": "Notificaciones"

    }
]
export const dataMisPrestaciones = [

    {
        id:'0',
        url: imagePrestaciones,
        "name": "Prestaciones"

    }
]



