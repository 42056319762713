import React, { useState, useEffect } from "react";
import useUtils from "services/utils/useUtils";

interface IInputProps {
  errors: any;
  register: any;
  codeCNO: string;
  handlesetCodeFromOcupationCode: (cno: string) => void;
}

// CNO

interface ICnoItem {
  Codigo: string;
  Descripcion: string;
  Grupo: string;
  Nivel: string;
  PadreId: number;
  ExternalId: string;
}

const OcupationCode: React.FC<IInputProps> = ({
  errors,
  register,
  codeCNO,
  handlesetCodeFromOcupationCode,
}) => {
  const [data, setData] = useState<ICnoItem[] | null>(null);
  const [select1, setSelect1] = useState<ICnoItem[] | null>(null);
  const [select2, setSelect2] = useState<ICnoItem[] | null>(null);
  const [select3, setSelect3] = useState<ICnoItem[] | null>(null);
  const [select4, setSelect4] = useState<ICnoItem[] | null>(null);
  const { getAllCno3Digi } = useUtils();
  const [codeSelected, setCodeSelected] = useState<string>(codeCNO || "");

  useEffect(() => {
    handleGetAllCno();
  }, []);

  useEffect(() => {
    if (data) {
      handleFilterSelect1(data);
    }
  }, [data]);

  const handleGetAllCno = async () => {
    const response = await getAllCno3Digi();
    if (response) {
      setData(response);
    } else {
      // SHOW ERROR
    }
  };

  const handleFilterSelect1 = (data: ICnoItem[]) => {
    const filteredSelect1 = data?.filter((item: ICnoItem) => item.Nivel === "1");
    setSelect1(filteredSelect1);
  };

  const handleFilterSelect2 = (select1Value: string) => {
    if (data?.length) {
      const filteredSelect2 = data?.filter(
        (item: ICnoItem) => item.PadreId === parseInt(select1Value)
      );
      setSelect2(filteredSelect2);
      setSelect3(null); // Reset next selects when parent changes
      setSelect4(null);
      setCodeSelected(""); // Reset code when parent changes
    }
  };

  const handleFilterSelect3 = (select2Value: string) => {
    if (data?.length) {
      const filteredSelect3 = data?.filter(
        (item: ICnoItem) => item.PadreId === parseInt(select2Value)
      );
      setSelect3(filteredSelect3);
      setSelect4(null); // Reset next selects when parent changes
      setCodeSelected(""); // Reset code when parent changes
    }
  };

  const handleFilterSelect4 = (select3Value: string) => {
    if (data?.length) {
      const filteredSelect4 = data?.filter(
        (item: ICnoItem) => item.PadreId === parseInt(select3Value)
      );
      setSelect4(filteredSelect4);
      setCodeSelected(""); // Reset code when parent changes
    }
  };

  const handleFilterSelect5 = (select4Value: string) => {
    if (data?.length) {
      const selectedItem = data.find(
        (item: ICnoItem) => item.ExternalId === select4Value
      );
      if (selectedItem) {
        setCodeSelected(selectedItem.Codigo);
        handlesetCodeFromOcupationCode(selectedItem.Codigo);
      }
    }
  };

  const handleChange = (e: any, select: number) => {
    const value = e.target.value;
    switch (select) {
      case 1:
        handleFilterSelect2(value);
        break;
      case 2:
        handleFilterSelect3(value);
        break;
      case 3:
        handleFilterSelect4(value);
        break;
      case 4:
        handleFilterSelect5(value);
        break;
      default:
        break;
    }
  };
  return (
    <fieldset
      className={`input-block ${
        (errors && errors["worker_cno_code"]?.message) ||
        (errors && errors["worker_cno_description"]?.message)
          ? "error"
          : ""
      }`}
    >
      {select1 !== null && (
        <select
          name="worker_cno_code1"
          id="worker_cno_code1"
          {...register("worker_cno_code1")}
          onChange={(e) => handleChange(e, 1)}
        >
          <option value={""}>Seleccione una Ocupación</option>
          {select1.map((item, i) => (
            <option key={i} value={item.ExternalId}>
              {item.Codigo} - {item.Descripcion}
            </option>
          ))}
        </select>
      )}
      {select2 !== null && (
        <select
          name="worker_cno_code2"
          id="worker_cno_code2"
          {...register("worker_cno_code2")}
          onChange={(e) => handleChange(e, 2)}
        >
          <option value={""}>Seleccione una Ocupación</option>
          {select2.map((item) => (
            <option key={item.ExternalId} value={item.ExternalId}>
              {item.Codigo} - {item.Descripcion}
            </option>
          ))}
        </select>
      )}
      {select3 !== null && (
        <select
          name="worker_cno_code3"
          id="worker_cno_code3"
          {...register("worker_cno_code3")}
          onChange={(e) => handleChange(e, 3)}
        >
          <option value={""}>Seleccione una Ocupación</option>
          {select3.map((item) => (
            <option key={item.ExternalId} value={item.ExternalId}>
              {item.Codigo} - {item.Descripcion}
            </option>
          ))}
        </select>
      )}
      {select4 !== null && (
        <select
          name="worker_cno_code4"
          id="worker_cno_code4"
          {...register("worker_cno_code4")}
          onChange={(e) => handleChange(e, 4)}
        >
          <option value={""}>Seleccione una Ocupación</option>
          {select4.map((item) => (
            <option key={item.ExternalId} value={item.ExternalId}>
              {item.Codigo} - {item.Descripcion}
            </option>
          ))}
        </select>
      )}
      {errors && (
        <p className="error m-0">
          {errors["worker_cno_code"]?.message}
          {errors["worker_cno_description"]?.message}
        </p>
      )}
      {/* <input
        type="text"
        value={codeSelected}
        name="ocupation_code"
        readOnly
      /> */}
    </fieldset>
  );
};

export default OcupationCode;
