import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";


const useGetEpisodeImage = () => {
const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
const [loadingGetEpisodeImage, setLoadingGetEpisodeImage] = useState(false);
const [successGetEpisodeImage, setSuccessGetEpisodeImage] = useState(false);
const [errorGetEpisodeImage, setErrorGetEpisodeImage] = useState(null);
const [episodeImage, setEpisodeImage] = useState<null|any>(null);

const getEpisodeImage = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisodeImage(true);
    setSuccessGetEpisodeImage(false);
    apiRecord.getEpisodeImages(userId,prestacionId)
      .then((res:any) => {       
         
        setLoadingGetEpisodeImage(false);
        setSuccessGetEpisodeImage(true);
        setEpisodeImage( res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisodeImage(false);
        setErrorGetEpisodeImage(err)
      })

    }
      return {
        episodeImage,
        getEpisodeImage,
        loadingGetEpisodeImage,
        successGetEpisodeImage,
        errorGetEpisodeImage
        };

    
  }
  export default useGetEpisodeImage;