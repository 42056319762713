import {
  UpdateAccidentRequest,
  UpdateActorsRequest,
  UpdateAssistanceRequest,
  UpdateCompanyRequest,
  UpdateEconomicRequest,
  UpdateWorkerRequest,
  UpdateCccLocationRequest,
  UpdateExtensionRequest,
} from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import Moment from "moment";
import './formatDataTime'
import { formatDataTime, dateFormatES } from "./formatDataTime";
interface IUpdateObjectValues {
  worker: UpdateWorkerRequest;
  company: UpdateCompanyRequest;
  ccc_location: UpdateCccLocationRequest;
  accident: UpdateAccidentRequest;
  extension: UpdateExtensionRequest;
  assistance: UpdateAssistanceRequest;
  economic: UpdateEconomicRequest;
  actors: UpdateActorsRequest;
}


const generateUpdateObjectsDeltaPAT = (values: any, data: any) => {
  const updateObjectValues: any = {
    id: data?.Id,
    tipoDelta: data.TipoDelta ? data.TipoDelta : values.type,
    nombre: data?.Nombre,
    fecha: data?.Fecha,
    trabajador: {
      id: data?.Trabajador.Id,
      apellido1: values.worker_firstName || null,
      apellido2: values.worker_secondName || null,
      nombre: values.worker_name || null,
      naf: values.worker_naf || null,
      fechaIngreso: values.worker_admissionDate
        ? formatDataTime(values.worker_admissionDate)
        : null,
      sexoId: values.worker_genderType || null,
      fechaNacimiento: values.worker_birthDate
        ? formatDataTime(values.worker_birthDate) 
        : null, // NO NULL Date;
      nacionalidad: values.worker_nation || null,
      nacionalidadDescripcion: values.worker_nationDescription || null,
      ipf: values.worker_ipf || null,
      tipoIPFCodigo: values.worker_ipfType || null,
      tipoIPFDescripcion: values.worker_ipfDescription || null,
      situacionId: values.worker_situation ? values.worker_situation : null,
      // SituacionDescripcion: values.worker_situation === '1'?'Asalariado Sector Privado': values.worker_situation === '2' ? 'Asalariado Sector Público':values.worker_situation === '3' ? 'Autónomo Con Asalariados':values.worker_situation === '4' ?'Autónomo Sin Asalariados': values.worker_situation === '5' ? 'Autónomo socio de cooperativa': 'Autónomo trabajador familiar',// null
      contrato: values.worker_contract || null,
      contratoDescripcion: values.worker_contractDescription || null, // ???
      regimenSSId: values.worker_socialSecurityRegime || null,
      regimenDescripcion: values.worker_socialSecurityRegimeDescription || null,
      textoConv: values.worker_agreement_text || null,
      domicilio: values.worker_address || null,
      telefono: values.worker_phone || null,
      provinciaCodigo: values.worker_province || null,
      provinciaDescripcion: values.worker_provinceDescription || null,
      municipio: values.worker_city || null,
      municipioDescripcion: values.worker_cityDescription || null,
      codigoPostal: values.worker_cp || null,
      antiguedadMeses: values.worker_months || null,
      antiguedadDias: values.worker_days || null,
      cnoTexto: values.worker_cno_text || null,
      cnoCodigo: values.worker_cno_code || null,
      cnoDescripcion: values.worker_cno_description || null,
      atepCnae: values.worker_atepCnae || null,
      atepCnaeDescripcion: values.worker_atepCnaeDescription || null,
      atepOcupacion: values.worker_Cno_Price_ATEP || null,
      atepOcupacionDescripcion: values.worker_atepOcupationDescription || null,
    },
    empresa: {
      id: data.Empresa.Id,
      cnaeTexto: values.company_textCNAE || null,
      cnaeCodigo: values.company_codeCNAE || null,
      cnaeDescripcion: values.company_codeCNAEDescription || null,
      cifNif: values.company_cifnif || null,
      razon: values.company_reason || null,
      ccc: values.company_ccc || null,
      plantilla: values.company_workforce || null,
      domicilio: values.company_address || null,
      provinciaCodigo: values.company_province || null,
      provinciaDescripcion: values.company_provinceDescription || null,
      municipio: values.company_city || null,
      municipioDescripcion: values.company_cityDescription || null,
      codigoPostal: values.company_cp || null,
      Telefono: values.company_phone || null,
      esContrata:
        values.company_contracted === "true"
          ? true
          : values.company_contracted === "false"
            ? false
            : null,
      esETT:
        values.company_ett1 === "true"
          ? true
          : values.company_ett1 === "false"
            ? false
            : null,
      esPreventivaAsunPersona: values.company_assumptionPerson ? true : false,
      esPreventivaServPrevPro: values.company_ownPreventionService
        ? true
        : false,
      esPreventivaServPrevAje: values.company_externalPreventionService
        ? true
        : false,
      esPreventivaTrabDesigna: values.company_designatedWorkers ? true : false,
      esPreventivaServPrevMan: values.company_jointPreventionServices
        ? true
        : false,
      esPreventivaNinguna: values.company_nothing ? true : false,
    },
    cccLugar: {
      id: data.CCCLugarId || null,
      datosCnaeTexto: values.ccclocation_company_codeCNAEDescription || null,
      datosCnaeCodigo: values.ccclocation_company_codeCNAE || null,
      codeCNAEDescription: values.ccclocation_company_codeCNAEDescription || null,
      codigoSeleccionado: data.CCCLugar.CodigoSeleccionado, 
      codigo: values.ccclocation_codeDescription || null, //
      esTrafico: values.ccclocation_traffic ? true : false || null, //
      pais: values.ccclocation_nation || null,
      paisDescripcion: values.ccclocation_nationDescription || null,
      provinciaCodigo: values.ccclocation_province,
      provinciaDescripcion: values.ccclocation_provinceDescription || null,
      municipio: values.ccclocation_city || null,
      municipioDescripcion: values.ccclocation_cityDescription || null,
      direccion: values.ccclocation_address || null,
      viakm: values.ccclocation_public_road || null,
      otro: values.ccclocation_other_side || null,
      esCentroEmpresaEp2: values.ccclocation_companyEP2
        ? JSON.parse(values.ccclocation_companyEP2)
        : false, // NO NULL boolean
      esCentroCentroEp2: values.ccclocation_centerEP2
        ? JSON.parse(values.ccclocation_centerEP2)
        : false, //
      // CCCLugarCentroCifTipo: values.ccclocation_CCCLugarCentroCifTipo || null,
      centroTipoEmpresaCodigo:
        values.ccclocation_CCCLugarCentroTipoEmpresaCodigo,
      // companyType: data.CCCLugar.companyType || null,
      centroCifTipo: values.ccclocation_CCCLugarCentroCifTipo || null,
      datosRazon: values.cccclocation_reason || null,
      datosDomicilio: values.cccclocation_company_address || null,
      datosProvinciaCodigo: values.ccclocation_company_province || null,
      datosProvinciaDescripcion: values.ccclocation_company_provinceDescription || null,
      datosMunicipio: values.ccclocation_company_city || null,
      datosMunicipioDescripcion: values.ccclocation_company_cityDescription || null,
      datosCodigopostal: values.ccclocation_company_cp || null,
      datosTelefono: values.ccclocation_company_phone || null,
      datosPlantilla: values.ccclocation_company_workforce || null,
      datosCCC: values.ccclocation_company_codeCNA || null,
    },
    accidente: {
      id: data.AccidenteId,
      fechaAccidente: values.accident_accidentDate ? formatDataTime(values.accident_accidentDate): null,
      fechaBaja: values.accident_leavingDate ? formatDataTime(values.accident_leavingDate) : null,
      diaSemanaInt: values.accident_accidentDate ? formatDataTime(values.accident_accidentDate): null,
      diaSemana: values.accident_accidentDate ? Moment(values.accident_accidentDate, dateFormatES).format("dddd") : null,
      hora: values.accident_hour || null,
      horaTrabajo: values.accident_hourWork || null,
      habitual: values?.accident_habitual
        ? JSON.parse(values?.accident_habitual)
        : null,
      evaluacion: values.accident_evaluation
        ? JSON.parse(values?.accident_evaluation)
        : null,
      descripcion: values.accident_description || null,

      // extensionId: data.accident.extension_id || null,
      ampliacion: {
        id: data.Accidente.Ampliacion?.Id,
        entornoLugar: values.accident_extension_site || null,
        entornoTipoLugar: values.code1_accident_extension_typeSite || null,
        entornoTipoLugarDescripcion:
          values.code1_accident_extension_typeSite || null,
        procesoTrabajo: values.accident_extension_work || null,
        procesoTipoTrabajo: values.code1_accident_extension_typeWork || null,
        procesoTipoTrabajoDescripcion:
          values.accident_extension_typeWorkDescription || null,
        tareaActividad: values.accident_extension_work || null,
        tareaEspecifica: values.code1_accident_extension_specify || null, // phisicalActivityDetailCode
        tareaEspecificaDescripcion: values.accident_extension_agentText || null,
        desencadenanteAgen:
          values.code1_trigger_unusual_fact +
          values.code2_trigger_unusual_fact +
          (values.code3_trigger_unusual_fact
            ? values.code3_trigger_unusual_fact
            : ""),
        desencadenanteHech: values.accident_extension_activity || null,
        desencadenanteAgenDescripcion: null || null,
        desencadenanteDesv: values.code1_accident_extension_desv || null,
        desencadenanteDesvDescripcion: null || null,
        tareaAgente:
          values.code1_physical_activity +
          values.code2_physical_activity +
          (values.code3_physical_activity
            ? values.code3_physical_activity
            : ""),
        modoAgente:
          values.code1_material_Agent_As_Injured +
          values.code2_material_Agent_As_Injured +
          (values.code3_material_Agent_As_Injured
            ? values.code3_material_Agent_As_Injured
            : ""),
        agenteDescripcion: null || null,
        modoTipoModo: values.accident_extension_how_injury,
        formaContactoCodigo: values.code1_accident_extension_form_contact_code,
        injuryForm: values.accident_extension_injuryForm || null,
        modoTextoAgente: values.accident_extension_form_text_agent,
        modoAgenteDescripcion: null || null,
        esMultiples: values?.accident_extension_multiples
          ? JSON.parse(values?.accident_extension_multiples)
          : null,
        esTestigos: values?.accident_extension_witnesses
          ? JSON.parse(values?.accident_extension_witnesses)
          : null,
        datosTestigos: data.Accidente.DatosTestigos || null,
        formaContactoDescripcion:
          data.Accidente.Ampliacion.FormaContactoDescripcion || null,
      },
    },

    asistenciales: {
      id: data?.Asistenciales?.Id,
      esHospitalCodigo: values?.riskAssessment || 'null'
        ? JSON.parse(values?.riskAssessment)
        : null,
      tipoLesionCodigo: values?.code1_assistance_injury
        ? values?.code1_assistance_injury
        : null,
      tipoLesionDescripcion: values.assistance_injuryDescription || null,
      gradoLesionCodigo: values.assistance_degree
        ? values.assistance_degree
        : null,
      parteCuerpoLesionadaCodigo: values.code1_assistance_part
        ? values.code1_assistance_part
        : null,
      parteCuerpoLesionadaDescripcion:
        values.assistance_partDescription || null,
      tipoAsistenciaCodigo: values.assistance_assistenceType || null,
      medicoNombre: values.assistance_nameDoctor || null,
      medicoDomicilio: values.assistance_addressDoctor || null,
      medicoTelefono: values.assistance_phoneDoctor || null,

      // codeHospital: values.assistance_codeHospital || null,
      hospitalNombre: values.assistance_codeHospital || null,
    },
    economicos: {
      id: data?.EconomicosId,
      mensualMesAnterior: values.economic_previousMonth
        ? parseFloat(values.economic_previousMonth)
        : null,
      mensualDias: values.economic_days
        ? parseFloat(values.economic_days)
        : null,
      mensualBase: values.economic_base
        ? parseFloat(values.economic_base)
        : null,
      anualB1: values.economic_b1 ? parseFloat(values.economic_b1) : null,
      anualB2: values.economic_b2 ? parseFloat(values.economic_b2) : null,
      anualTotal: values.economic_totalAnnual
        ? parseFloat(values.economic_totalAnnual)
        : null,
      anualPromedio: values.economic_average
        ? parseFloat(values.economic_average)
        : null,
      subsidioPromedioA: values.economic_averageA
        ? parseFloat(values.economic_averageA)
        : null,
      subsidioPromedioB: values.economic_averageB
        ? parseFloat(values.economic_averageB)
        : null,
      subsidioTotal: values.economic_totalBenefit
        ? parseFloat(values.economic_totalBenefit)
        : null,
      subsidioIndemnizacion: values.economic_compensation
        ? parseFloat(values.economic_compensation)
        : null,
    },
    actores: {
      id: data?.ActoresId,
      FEmpresaNombreApellidos: values.actors_completeName || null,
      FEmpresaCalidadDe: values.actors_serveAs || null,
      FEmpresaProvincia: values.actors_province || null,
      FEmpresaFechaPresenta:
        values.actors_submissionDate &&
          Moment(values.actors_submissionDate, dateFormatES).isValid()
          ? formatDataTime(values.actors_submissionDate)
          : Moment().toISOString(), // date // NO NULL

      EGCCodigoMutua: values.actors_code || null,
      EGCCodigoMutuaDescripcion: null || null,
      EGCNumeroExpediente: values.actors_numExpedientEgc || null,
      EGCFechaAceptacion: values.actors_acceptationDate
        ? formatDataTime(values.actors_acceptationDate)
        : null, //Date;
      ALProvinciaCodigo: values.actors_codeAlp
        ? values.actors_codeAlp
        : null || null, // number;
      ALNumeroExpediente: values.actors_numExpedientAlp || null,
      ALFechaRecepcion: values.actors_receptionDate
        ? formatDataTime(values.actors_receptionDate)
        : null, //Date;
      MotivoRechazo: values.actors_reasonRejection || null,
    },
  };

  return updateObjectValues;
};

export default generateUpdateObjectsDeltaPAT;
