import {useEffect, useRef} from 'react';

const useInterval = (callback:any, delay:any, reset:any) => {
  const savedCallback:any = useRef();
  const savedReset:any = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
    savedReset.current = reset;
  }, [callback, reset]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null || reset !== savedReset.current) {
      let id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay, reset]);
  
}

export default useInterval;
