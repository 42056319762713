import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { guid, IFormTemplateRequest } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

const useGetFormTemplateByForm = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetFormTemplateByForm, setLoadingGetFormTemplateByForm] = useState(false);
  const [successGetFormTemplateByForm, setSuccessGetFormTemplateByForm] = useState(false);
  const [errorGetFormTemplateByForm, setErrorGetFormTemplateByForm] = useState(null);

  const getFormTemplateByForm = (id:guid, formId:guid, _cb?:(data:any)=>void) => {
    setLoadingGetFormTemplateByForm(true);
    setErrorGetFormTemplateByForm(null);
    apiFormsIngestion
      .getFormTemplateByForm(id, formId)
      .then((res: any) => {
        // if(res.data=="") {
        //   setErrorGetFormTemplateByForm(res.status);
        // }
        setSuccessGetFormTemplateByForm(true);
        setLoadingGetFormTemplateByForm(false);
        
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch(err => {
        console.error("getFormTemplateByForm error: ", err);
        setErrorGetFormTemplateByForm(err);
        setLoadingGetFormTemplateByForm(false);
        if (typeof _cb === "function") {
          _cb(null);
        }
      });
  };

  return {
    getFormTemplateByForm,
    successGetFormTemplateByForm,
    loadingGetFormTemplateByForm,
    errorGetFormTemplateByForm
  };
};

export default useGetFormTemplateByForm;