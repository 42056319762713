import { useIsAuthenticated } from "@azure/msal-react/dist";
import { useMsal } from "@azure/msal-react/dist";
function useLogin() {

    const isAuthenticate = useIsAuthenticated();
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();

  return {
    // handleRedirectToLogin,
    isAuthenticate,
    currentAccount

  }
    
}

export default useLogin