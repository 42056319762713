import React from 'react';
import PageMenu from '../../blocks/PageMenu/PageMenu';
import { dataAyuda } from './dataAyuda'
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import { useHistory } from 'react-router-dom';
import './ayuda.scss';

const Ayuda = () => {
  const history = useHistory();


  return (
    <>
      <PageMenu
        title={'Ayuda'}
      >
        <div className="btns-header-container">
          <button onClick={() => history.goBack()}><img src={IconArrowGray} alt="icon arrow right" /></button>
        </div>
      </PageMenu>


      <div className="container container-carrusel-ayuda">
        <div id="carouselAyuda" className="carousel carousel-dark carousel slide carousel-fade" data-bs-touch="false">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Inicio"></button>
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="1" aria-label="Citas"></button>
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="2" aria-label="Historial"></button>
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="3" aria-label="Gestiones"></button>
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="4" aria-label="Notificaciones"></button>
            <button type="button" data-bs-target="#carouselAyuda" data-bs-slide-to="5" aria-label="Prestaciones"></button>
          </div>
          <div className="carousel-inner">

            {

              dataAyuda.map((help, i) => {
                return <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                  <img src={help.url} className='img-fluid img-carrusel-ayuda ' alt="Inicio" />
                </div>
              })
            }
          </div>
          <button className="carousel-control-prev prev" type="button" data-bs-target="#carouselAyuda" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next next" type="button" data-bs-target="#carouselAyuda" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Ayuda;
