import React from "react";
import SolicitudPagoDirecto from "components/pages/GestionForms/SolicitudPagoDirecto";
import View404 from "components/pages/View404/View404";
import Modelo145 from "components/pages/GestionForms/Modelo145";
import GenericForm from "components/pages/GestionForms/GenericForm";
import { useManagementFormState } from "../../GeneralManagement/ManagementFormStateContext";
const ManagementForm2 = (props) => {

  const { state } = useManagementFormState(); 
  switch (state.titleForm) {
    case "Solicitud Pago Directo":
      return <SolicitudPagoDirecto gestionName={state.titleForm} url={state.url} props={props} urlState={state.url} />;
    case "Modelo 145":
      return <Modelo145 gestionName={state.titleForm} url={state.url} props={props} urlState={state.url}/>;

    default:
      return <GenericForm gestionName={state.titleForm} url={state.url} props={props} urlState={state.url}/>;
  }
};

export default ManagementForm2;
