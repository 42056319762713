import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
// import {
//   PdfFontFamily,
//   PdfFontStyle,
//   PdfStandardFont,
// } from "@syncfusion/ej2-pdf-export";
import {
  Grid,
  Inject,
  PdfExport,
  ExcelExport,
  Toolbar,
  Page,
  PageSettingsModel,
  Sort,
  PdfExportProperties,
} from "@syncfusion/ej2-react-grids";
import useAllRatsb from "services/delta/ratsb/useAllRatsb";
import downloadXml from "services/delta/pat/useDownloadXml";
import { array } from "yup";
import useGetRole from "hooks/useGetRole";
interface IGrid {
  data: any;
  isRemitted?: boolean;
}
export interface ExportPat {
  ids: string[];
}
interface FormatedData {
  Accidentados: number;
  Anyo: number;
  DeltaSinBajaId: number;
  Estado: number;
  Fecha: string;
  Id: string;
  Mes: number;
  Nombre: string;
  NumeroReferencia: string;
  Remesado: boolean;
  Validacion: boolean;
  formated_month: string;
  formated_remitted: string;
  formated_validation: string;
  // Agrega otras propiedades según sea necesario
}
const GridDeltaAccidentWithoutLeave: React.FC<IGrid> = ({
  data,
  isRemitted,
}) => {
  const history = useHistory();
  const [formatedData, setFormatedData] = useState<FormatedData>({
    Accidentados: 0,
    Anyo: 2023,
    DeltaSinBajaId: 0,
    Estado: 3,
    Fecha: "",
    Id: "",
    Mes: 11,
    Nombre: "",
    NumeroReferencia: "",
    Remesado: false,
    Validacion: false,
    formated_month: "Noviembre",
    formated_remitted: "Sí",
    formated_validation: "Sí",
  });

  const location = useLocation();
  const section = location?.pathname.includes("remesados");
  const [arrayRemesa, setarrayRemesa] = useState([]);
  const {
    // getCompleteRatsbByUser,
    // getAccidentsByRatsb,
    // validateRatsb,
    exportRatsb,
    // totalRatsb,
    // getRatsb,
    // updateHeader,
    // updateFooter,
    // updateRatsb,
    // createFooter,
  } = useAllRatsb();

  const { isImpersonation } = useGetRole;

  useEffect(() => {
    handleFormatData(data);
  }, [data, isRemitted]);

  const handleFormatData = (data: any) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const formated_data = data.map((item: any) => {
      const formated_month = months[item.Mes - 1];
      const formated_remitted = item.Remesado ? "Sí" : "No";
      const formated_validation = item.Validacion ? "Sí" : "No";
      return {
        ...item,
        formated_month: formated_month,
        formated_remitted: formated_remitted,
        formated_validation: formated_validation,
      };
    });
    setFormatedData(formated_data);
  };

  let grid: Grid | null = new Grid();
  let refresh: boolean;

  // const toolbarOptions: any = ["PdfExport", "ExcelExport"];

  // const groupOptions: Object = { showDropArea : false , columns: ['ShipCountry'] };
  // let gridInstance: GridComponent;

  // const dataBound = () => {
  //   if (refresh) {
  //     // gridInstance.groupColumn('ShipCountry');
  //     refresh = false;
  //   }
  // }
  // const load = () => {
  //   refresh = true;
  // }

  // const toolbarClick = (args: any) => {
  //   if (grid && args.item.id === "grid_pdfexport") {
  //     const exportProperties: PdfExportProperties = {
  //       fileName: `Grid_PDF_${args.item.id}.pdf`,
  //       theme: {
  //         caption: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9) },
  //         header: {
  //           font: new PdfStandardFont(
  //             PdfFontFamily.TimesRoman,
  //             11,
  //             PdfFontStyle.Bold
  //           ),
  //         },
  //         record: { font: new PdfStandardFont(PdfFontFamily.TimesRoman, 10) },
  //       },
  //       header: {
  //         contents: [
  //           {
  //             position: { x: 0, y: 24 },
  //             style: { textBrushColor: "#3A5FA1", fontSize: 13 },
  //             type: "Text",
  //             value: `${args.item.id}`,
  //           },
  //           {
  //             type: "Line",
  //             style: { penColor: "#172E56", penSize: 2, dashStyle: "Solid" },
  //             points: { x1: 0, y1: 4, x2: 685, y2: 4 },
  //           },
  //         ],
  //         fromTop: 0,
  //         height: 80,
  //       },
  //       footer: {
  //         contents: [
  //           {
  //             format: "Pág. {$current} de {$total}",
  //             pageNumberType: "Arabic",
  //             position: { x: -0, y: 0 },
  //             style: {
  //               fontSize: 12,
  //               textBrushColor: "#409CCE",
  //             },
  //             type: "PageNumber",
  //           },
  //         ],
  //         fromBottom: 24,
  //         height: 32,
  //       },
  //       pageOrientation: "Landscape",
  //     };
  //     grid.pdfExport(exportProperties);
  //   }
  //   if (grid && args.item.id === "grid_excelexport") {
  //     grid.excelExport();
  //   }
  // };

  // -	CCC
  // -	Razón social
  // -	Mes relación
  // -	Validación
  // -	Remesado
  // -	Lista accidentados: *es un botón que lleva a una página con información relacionada con el accidente (explicada a continuación)
  const sortingOptions: SortSettingsModel = {
    columns: [{ field: "ccc", direction: "Ascending" }],
  };

  const pageOptions: PageSettingsModel = {
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  };

  const handleEdit = (id: any) => {

    history.push({
      pathname: `/unidad-delta/accidentes-sin-baja-pendientes-delta/${id}`,
    });
  };
  const handleview = (id: any) => {

    history.push({
      pathname: `/unidad-delta/accidentes-sin-baja-delta-remesados/${id}`,
    });
  };

  // const handleRemit = (id: string) => {
  //   const idExists = arrayRemesa.includes(id);
  //   if (idExists) {
  //     const newArrayRemesa = arrayRemesa.filter((remesaId) => remesaId !== id);
  //     setarrayRemesa(newArrayRemesa);
  //   } else {
  //     setarrayRemesa([...arrayRemesa, id]);
  //   }
  // };

  // const handleOnClickRemit = async () => {
  //   let exportIds: ExportPat = {
  //     ids: arrayRemesa,
  //   };
  //   let response = await exportRatsb(exportIds).then((response) => {
  //     try {
  //       // downloadXml(response);
  //       console.log(response)
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });
  // };
  const handleRemit = (id: string) => {
    if (arrayRemesa.includes(id)) {
      let miNewArr = arrayRemesa.filter((item) => item !== id);
      setarrayRemesa([...miNewArr]);
    } else {
      arrayRemesa.push(id);
      setarrayRemesa([...arrayRemesa]);
    }
  };
  
  const downloadXml = (data: any, filename = 'default.xml') => {
    const blob = new Blob([data], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };
  
  const extractFilenameFromXml = (xmlString:any) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const cif = xmlDoc.getElementsByTagName("cif")[0].textContent;
    const mes = xmlDoc.getElementsByTagName("mes")[0].textContent;
    const anno = xmlDoc.getElementsByTagName("anno")[0].textContent;
  
    return `pre_delta_sin_baja_${cif}_${anno}-${mes}.xml`;
  };
  
  const handleOnClickRemit = async () => {
    let exportIds = {
      ids: arrayRemesa,
    };
  
    let response = await exportRatsb(exportIds).then((response) => {
      try {
        const responseText = response && response;
        const filename = extractFilenameFromXml(responseText);
        downloadXml(responseText, filename);
      } catch (error) {
        console.log(error);
      }
    });
  };
  return (
    <>
      <GridComponent
        locale="es"
        dataSource={formatedData}
        ref={(g) => (grid = g)}
        // toolbar={toolbarOptions}
        allowExcelExport={true}
        allowPdfExport={true}
        // toolbarClick={toolbarClick}
        allowPaging={true}
        allowSorting={true}

        pageSettings={pageOptions}
      // sortSettings={sortingOptions}
      >
        <ColumnsDirective>
          {!isRemitted && !isImpersonation() && (
            <ColumnDirective
              textAlign="Center"
              headerText="remesar"
              width="100"
              template={(props: any) => {
                return <input
                  type="checkbox"
                  checked={arrayRemesa.includes(props.Id)}
                  onChange={() => handleRemit(props.Id)}
                  data-id={props.Id}
                  disabled={props.Validacion === false ? true : false}
                />

              }}
            />
          )}

          <ColumnDirective
            headerText={isRemitted ? "Ver" : "Editar"}
            textAlign="Center"
            width="100"
            template={(props: any) => (
              <span
                className="edit-delta-icon"
                onClick={() => {
                  isRemitted ? handleview(props.Id) : handleEdit(props.Id);
                }}
              ></span>
            )}
          />

          <ColumnDirective
            field="Nombre"
            headerText="Razón social"
          ></ColumnDirective>
          <ColumnDirective field="Anyo" headerText="Año"></ColumnDirective>
          <ColumnDirective
            field="formated_month"
            headerText="Mes relación"
          ></ColumnDirective>
          {!isRemitted && (
            <>
              <ColumnDirective
                field="formated_validation"
                headerText="Validación"
                template={(props: any) => (
                  <span
                    className={
                      props.Validacion
                        ? "check-in-delta-icon"
                        : "check-out-delta-icon"
                    }
                  ></span>
                )}
              ></ColumnDirective>
              <ColumnDirective
                field="formated_remitted"
                headerText="Remesado"
                template={(props: any) => (
                  <span
                    className={
                      props.Remesado
                        ? "remesado-delta-icon"
                        : "no-remesado-delta-icon"
                    }
                  ></span>
                )}
              ></ColumnDirective>
            </>
          )}

          {/* BOTÓN */}
          {/* <ColumnDirective field='formated_list' headerText='Lista Accidentados'></ColumnDirective> */}
        </ColumnsDirective>
        <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort]} />
      </GridComponent>

      {!section && !isImpersonation() ? (
        <div className=" container container-btn-remesar">
          <button
            className="btn-remesar btn-box btn-accent loading"
            disabled={
              arrayRemesa.length <= 0 ||
                arrayRemesa[0].length === 0 ||
                isRemitted === true
                ? true
                : false
            }
            onClick={() => handleOnClickRemit()}
          >
            Remesar
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GridDeltaAccidentWithoutLeave;

// interface response {
//   ccc: "45102560650";
//   cif: "B45412186";
//   city: "45119";
//   code_cnae: "236";
//   description_cnae: "FABRICACION PIEZAS HORMIGON";
//   egc: null;
//   id: "71dfaf3e-979a-4ed9-8ba7-08d8df65f9d6";
//   month: "12";
//   naf: "";
//   num_reference: null;
//   province: null;
//   reason: "PRENSAGRA SL";
//   remittance: true;
//   status: 3;
//   validation: false;
//   workforce: "28";
//   year: "2019";
// }
