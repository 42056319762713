import { useState } from "react";
import { transaction } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";
import {ContingencyTypeDTO} from "@solimat/solimat-web-endpoint/lib/record/dto/index";

interface ITransactionAvailable {
  id:guid,
  name:string,
  description:string,
  order:number,
  transaction_type:number,
  forms:any[],
  transaction_version:number|null
}

const useGetAllTransactionAvailableByContingencyTypeByContingencyType = () => {
  const transacciones = [

    {
      id:'uyt65uyt85uytu75uy5u1',
      name:'nombre transaccion',
      description:'descripcion transaccion',
      order:1,
      transaction_type:2,
      forms:['',''],
      transaction_version:0
    },
    {
      id:'uyt65uyt85uytu75uy5u2',
      name:'nombre transaccion2',
      description:'descripcion transaccion2',
      order:2,
      transaction_type:1,
      forms:['',''],
      transaction_version:0
    },
    {
      id:'uyt65uyt85uytu75uy5u3',
      name:'nombre transaccion3',
      description:'descripcion transaccion3',
      order:3,
      transaction_type:1,
      forms:['',''],
      transaction_version:0
    }
  ]
  const BASE_URL = process.env.REACT_APP_TRANSACTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_TRANSACTION_KEY || "";
  const apiTransaction = new transaction.TransactionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllTransactionAvailableByContingencyType, setLoadingGetAllTransactionAvailableByContingencyType] = useState(false);
  const [successGetAllTransactionAvailableByContingencyType, setSuccessGetAllTransactionAvailableByContingencyType] = useState(false);
  const [errorGetAllTransactionAvailableByContingencyType, setErrorGetAllTransactionAvailableByContingencyType] = useState(null);
  const [allTransactionsAvailableByContingencyType, setAllTransactionsAvailableByContingencyType] = useState([]);

  const getAllTransactionAvailableByContingencyType = (roleSelected:number, contingencyType:ContingencyTypeDTO, _cb?:(data:ITransactionAvailable[])=>void) => {
    setLoadingGetAllTransactionAvailableByContingencyType(true);
    setErrorGetAllTransactionAvailableByContingencyType(null);
    // apiTransaction
    //   .getAllTransactionAvailableByContingencyType(roleSelected, contingencyType)
    //   .then((res: any) => {
    //     setSuccessGetAllTransactionAvailableByContingencyType(true);
    //     setLoadingGetAllTransactionAvailableByContingencyType(false);
    //     setAllTransactionsAvailableByContingencyType(res.data)
    //     if (typeof _cb === "function") {
    //       _cb(res.data);
    //     }
    //   })
    //   .catch((err:any) => {
    //     console.error("getAllTransactionAvailableByContingencyType error: ", err);
    //     // setErrorGetAllTransactionAvailableByContingencyType(err);borrrar
    //     setLoadingGetAllTransactionAvailableByContingencyType(false);
    //     setAllTransactionsAvailableByContingencyType(transacciones)//borrar
    //   });
  };

  return {
    allTransactionsAvailableByContingencyType,
    getAllTransactionAvailableByContingencyType,
    successGetAllTransactionAvailableByContingencyType,
    loadingGetAllTransactionAvailableByContingencyType,
    errorGetAllTransactionAvailableByContingencyType
  };
};

export default useGetAllTransactionAvailableByContingencyTypeByContingencyType;