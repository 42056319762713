import { useState } from "react";
import { notifications } from "@solimat/solimat-web-endpoint";


const useUpdateNotice = () => {
  const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_NOTIFICACIONES_KEY || "";
  const apiNotifications = new notifications.NotificationsService(BASE_URL, FUNCTIONS_KEY);
  
  const [loadingUpdateNotice, setLoadingUpdateNotice] = useState(false);
  const [errorUpdateNotice, setErrorUpdateNotice] = useState(null);
  const [responseUpdate, setResponseUpdate] = useState(false);

  const updateNotice = (userId: string, noticeId: string,_cb?:()=>void) => {
    setLoadingUpdateNotice(true);
    setErrorUpdateNotice(null);
    apiNotifications
      .updateNotice(userId, noticeId)
      .then((res: any) => {
        setLoadingUpdateNotice(false);
        setResponseUpdate(res.data);
        if(typeof _cb === 'function') {
          _cb()
        }
      })
      .catch((err) => {
        console.error("updateNotification: ", err);
        setErrorUpdateNotice(err);
        setLoadingUpdateNotice(false);
      })
      
  };

  return {
    updateNotice,
    responseUpdate,
    loadingUpdateNotice,
    errorUpdateNotice
  };
};

export default useUpdateNotice;