import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode
}

const HeaderContext = createContext<any>({});

const HeaderContextProvider = ({children}:Props) => {
  const [headerState, setHeaderState] = useState({
    sideMenuVisible: false,
    navMenuVisible: false
  });
  return (
    <HeaderContext.Provider value={{headerState, setHeaderState}}>
        {children}
    </HeaderContext.Provider>
  );
}

export {HeaderContext, HeaderContextProvider};