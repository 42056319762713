import React from 'react';

import useDecode from "../../../hooks/useDecode";
import moment from 'moment'
import IconCalendarBlue from "../../../img/icons/svg/cita/calendario-azul.svg";
import IconCalendarGrey from "../../../img/icons/svg/cita/calendar.png";
import VideoCallDetail from '../../../img/icons/svg/cita/video-call-detail.png'
import IconType from "../../../img/icons/svg/cita/especialidad-azul.svg";
import IconState from "../../../img/icons/svg/cita/estado-azul.svg";
import IconHospital from "../../../img/icons/svg/cita/hospital-azul.svg";
import Moment from "moment";
import './TableAppointment.scss';

interface ITableAppointment {
  appointment: any
  isExt?: boolean
}

const TableAppointment : React.FC <ITableAppointment>= ({appointment, isExt}) => {
  const tokenResponse = useDecode("token");
  const isnextAppointment = Moment(appointment.fecha).format() >= Moment().format() ? true : false;
  return (
    <>
      <div className="table-appointment-block">
        <div className="table-appointment-header">
          <h3 className="table-appointment_title">{`${tokenResponse.extension_first_name}  ${tokenResponse.extension_first_last_name}`}</h3>
        </div>
        <div className="table-row-info">
          <div className="info-content">
            <div className="icon">
              <img className="icon-calendar-appointment-detail" src={isnextAppointment ? IconCalendarBlue : IconCalendarGrey} alt="calendario"/>
            </div>
            <div className="text-container">
              <p className="text-header">{appointment != null ? moment(appointment.fecha).format('L') : ""}</p>
              <p className="text-header">{appointment != null ? moment(appointment.fecha).format('HH:mm') : ""}h.</p>
            </div>
          </div>
          <div className="info-content">
            <div className="icon">
              {appointment.linkVideoConsulta ?
             <img src={VideoCallDetail} alt="" className='img-table-appointment-icon'/> :<img src={IconHospital} alt=""/>
            }
              
            </div>
            <div className="text-container">
            {appointment.linkVideoConsulta ?
            <p className="text-header">Video Consulta</p>:
            <p className="text-header">{appointment != null ? appointment.centro.descripcion : ""}</p>}
              
            </div>
          </div>
        </div>
        {!isExt ?
        <div className="table-row-info">
          <div className="info-content">
            <div className="icon-container">
              <img src={IconType} className='img-table-appointment-icon' alt=""/>
            </div>
            <div className="text-container">
              <p className="text-header">Especialidad:</p>
              <p className="text">{appointment != null ? appointment.especialidad : ""}</p>
            </div>
          </div>
          
          <div className="info-content"> 
            <div className="icon">
              <img src={IconState} className='img-table-appointment-icon' alt=""/>
            </div>
            <div className="text-container">
              <p className="text-header">Estado de la cita:</p>
              <p className="text">{appointment != null ? appointment.estado : ""}</p>
            </div>
          </div>
        </div>
        : ""}
      </div>
    </>
  )
}

export default TableAppointment;