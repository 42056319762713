import React, { ReactEventHandler } from 'react';
import './ArticleHomeBox.scss';

interface IArticleHomeBox {
  title: string,
  count: number,
  handlePath: string,
  type: string
}

const ArticleHomeBox: React.FC<IArticleHomeBox> = ({title, count, handlePath, type}, props) => {
  const handleRedirect = () => {
  
  }
  return (
    <div className={`article-home-box ${count>0?'active':''} ${type}`}>
      <div className="article-count">
        <span className="article-count__number">{count}</span>
      </div>
      <h2>{title}</h2>
      <div className="btn-container">
        <button 
          className="btn-box btn-accent company"
          onClick={handleRedirect}
          disabled={count>0?false:true}
        >
          Consultar
        </button>
      </div>
    </div>
  )
}

export default ArticleHomeBox;