import { useState } from 'react';
import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import { FilterRemittedPATRequest } from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useFilterRemittedPat = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);

  const [loadingFilterRemittedPat,setLoadingFilterRemittedPat] = useState(false);
  const [successFilterRemittedPat,setSuccessFilterRemittedPat] = useState(false);
  const [errorFilterRemittedPat,setErrorFilterRemittedPat] = useState(false);
  
  const filterRemittedPat = async(data:FilterRemittedPATRequest)=> {
    setLoadingFilterRemittedPat(true);
    try 
    {
      const response = await apiDelta.filterRemittedPat(data)
        setLoadingFilterRemittedPat(false);
        setSuccessFilterRemittedPat(true);
        setErrorFilterRemittedPat(false);
        return response.data;
    } 
    catch (err) {
      setLoadingFilterRemittedPat(false)
      setSuccessFilterRemittedPat(false);
      setErrorFilterRemittedPat(true);
      return false;
    }

  }

  return {
    filterRemittedPat,
    loadingFilterRemittedPat,
    successFilterRemittedPat,
    errorFilterRemittedPat
  }

}

export default useFilterRemittedPat;