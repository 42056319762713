import React, { useEffect } from 'react';

interface IDateDoubleProps {
  id?: string,
  errors: any,
  register: any,
  label: string,
  watchedFromDate?: any,
  setValue?: any,
  registerFrom?: any,
  registerTo?: any
}

const DateDouble: React.FC<IDateDoubleProps> = ({ id, errors, register, label, watchedFromDate, registerFrom, registerTo }) => {
  const today = new Date().toLocaleDateString("es", {}).substring(0, 10)

  const validateFromDate = (value: any) => {
    // console.log(value)
    if (value && value > watchedFromDate) {
      return false
    }
  }
  const validateToDate = (value: any) => {
    if (value && watchedFromDate) {
      return false
    }
  }

  const fromDateId = `${id ? id + 'FromDate' : "FromDate"}`;
  const toDateId = `${id ? id + 'ToDate' : "ToDate"}`;
  return (
    <fieldset className="input-block input-block--fieldset">
      <legend className="input-block--fieldset__legend">{label}</legend>
      <div className="input-block--fieldset__group">
        <input
          className={`${(errors.ToDate || errors.FromDate) ? 'error-validate' : ''}`}
          type="date"
          name={fromDateId}
          id={fromDateId}
          placeholder={label}
          {...register(fromDateId, { validate: validateFromDate })}
          max={today}
          defaultValue={today}
        />

        <input
          className={`${(errors.ToDate || errors.FromDate) ? 'error-validate' : ''}`}
          type="date"
          name={toDateId}
          id={toDateId}
          placeholder={"Fecha de alta"}
          {...register(toDateId, { validate: validateToDate })}
          defaultValue={today}
        />

      </div>
      {(errors.ToDate || errors.FromDate) && <p className="error">La fecha de baja debe ser anterior a la fecha de alta.</p>}

    </fieldset>
  )
}

export default DateDouble;

// <DateDouble
//   label="Fecha Baja"
//   errors={errors}
//   register={(register)}
//   watchedFromDate={watchedFromDate}
//   setValue={setValue}
//   registerFrom={{required:"Campo obligatorio"}}
//   registerTo={{required:"Campo obligatorio",max:watchedFromDate}}
// />