import React from 'react';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';

interface IMonthProps {
  id: string,
  errors: any,
  register: any,
  label: string,
  setValue?: any,
  placeholder?: any
}

const Month: React.FC<IMonthProps> = ({ id, errors, register, label, placeholder }) => {

  const monthsData: { [key: string]: Object }[] = [
    { Month: { name: "Enero", code: '1' } },
    { Month: { name: "Febrero", code: '2' } },
    { Month: { name: "Marzo", code: '3' } },
    { Month: { name: "Abril", code: '4' } },
    { Month: { name: "Mayo", code: '5' } },
    { Month: { name: "Junio", code: '6' } },
    { Month: { name: "Julio", code: '7' } },
    { Month: { name: "Agosto", code: '8' } },
    { Month: { name: "Septiembre", code: '9' } },
    { Month: { name: "Octubre", code: '10' } },
    { Month: { name: "Noviembre", code: '11' } },
    { Month: { name: "Diciembre", code: '12' } }
  ]
  const fields: object = { value: 'Month.name', text: 'Month.name' };
  return (
    <div className="auto-complete-component--syncfusion common-form">
      <div className={`input-block ${errors && errors[id]?.message ? 'error' : ''}`}>
        <div className="input-block__label">{label || "Mes"}</div>
        <AutoCompleteComponent
          id={id}
          name={id}
          dataSource={monthsData}
          fields={fields}
          placeholder={placeholder}
          {...register(id)}
          enabled={false}
        />
        {errors &&
          <p className="error m-0">
            {errors[id]?.message}
          </p>
        }
      </div>
    </div>
  )
}

export default Month;