import React, {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetAllHealthcareDocument = () => {
  const [loadingGetAllHealthcareDocument, setLoadingGetAllHealthcareDocument] = useState(false);
  const [successGetAllHealthcareDocument, setSuccessGetAllHealthcareDocument] = useState(false);
  const [errorGetAllHealthcareDocument, setErrorGetAllHealthcareDocument] = useState(null);
  const [allHealthcareDocuments, setAllHealthcareDocuments] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);

  const getAllHealthcareDocument = (userId: string ,prestacionId: string, _cb?:(data:any)=> void) => {
    apiRecord.getAllHealthcareDocument(userId, prestacionId)
    .then((res:any) => {
      setAllHealthcareDocuments(res.data);
      setLoadingGetAllHealthcareDocument(false);
      setSuccessGetAllHealthcareDocument(true);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any) => {
      console.log(err)
      setLoadingGetAllHealthcareDocument(false);
      setErrorGetAllHealthcareDocument(err)
    })
  }
  
  return {
    allHealthcareDocuments,
    getAllHealthcareDocument,
    loadingGetAllHealthcareDocument,
    successGetAllHealthcareDocument,
    errorGetAllHealthcareDocument

  }
}

export default useGetAllHealthcareDocument;