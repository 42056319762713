import React from 'react';

interface IProps {
  group:any,
  checkFieldInputType: (inputType:number)=>string,
  register:any
}

const SurveyFormFields:React.FC<IProps> = ({group,checkFieldInputType, register}) => {
  return (
    <>
      {group.fields.map((field:any) => {
        if(field.type < 4) {
          return(
            <div className={`input-block ${checkFieldInputType(field.type)}`} key={field.id}>
              <label htmlFor={field.group_field_id}>{field.description}</label>
              <input type={checkFieldInputType(field.type)} placeholder={field.description} id={field.group_field_id} name={field.name} 
                ref={register}
              />
            </div>
          )
        } else if (field.type === 4) { // INPUT CHECKBOX único
          return(
            <div className={`input-block ${checkFieldInputType(field.type)}`} data-width={field.width} key={field.id}>
              <label htmlFor={field.group_field_id}>{field.description}</label>
              <input type={checkFieldInputType(field.type)} id={field.group_field_id} value={field.name} name={field.name} 
                ref={register}
                defaultChecked={false}
              />
            </div>
          )
        } else if (field.type === 5 ) { // select
          return(
            <div className="input-block" data-width={field.width} key={field.id}>
              <label htmlFor={field.group_field_id}>{field.description}</label>
              <select id={field.group_field_id} name={field.name}
                ref={register}
              >
                <option value="" selected >Seleccionar</option>
                {field.options.map((option:any, index:number)=> {
                  return(
                    <option value={option.value} key={index}>{option.value}</option>
                  )
                })}
              </select>
            </div>
          )
        } else if (field.type === 6) { // textarea
          return(
            <div className="input-block" data-width={field.width} key={field.id}>
              <label htmlFor={field.group_field_id}>{field.description}</label>
              <textarea placeholder={field.description} id={field.group_field_id} name={field.name} 
                ref={register}
              ></textarea>
            </div>
          )
        } else if (field.type === 7 ) { // grupo checkbox
          return(                    
            <fieldset className={`input-block ${checkFieldInputType(field.type)} inputs-group`} key={field.id}>
              <legend>{field.description}</legend>
              {field.options.map((option:any, index:number)=>{
                return(
                  <div className={`input-block ${checkFieldInputType(field.type)}`} key={index}>
                    <label htmlFor={`${option.value + '_' + field.group_field_id}`}>{option.value}</label>
                    <input type={checkFieldInputType(field.type)} id={`${option.value + '_' + field.group_field_id}`} name={option.value} value={option.value} defaultChecked={false}
                      ref={register}
                    />
                  </div>
                )
              })}
            </fieldset>
          )
        } else if (field.type === 8 ) { // grupo radio
          return (
            <fieldset className={`input-block ${checkFieldInputType(field.type)} inputs-group`} key={field.id}>
              <legend>{field.description}</legend>
              {field.options.map((option:any, index:number)=> {
                return(
                  <div className={`input-block ${checkFieldInputType(field.type)}`} key={index}>
                    <label htmlFor={`${option.value + '_' + field.group_field_id}`}>{option.value}</label>
                    <input type={checkFieldInputType(field.type)} id={`${option.value} + '_' + ${field.group_field_id}`} name={field.name} value={option.value} defaultChecked={false}
                      ref={register}
                    />
                  </div>
                )
              })}
            </fieldset>
          )
        } else if (field.type === 9 ) { // párrafo
          return (
            <p className="paragraph_field" key={field.id}>{field.description}</p>
          )
        }
      })}
    </>
  )
}

export default SurveyFormFields;