import React, { useState, useEffect, useContext } from "react";
import Loader from "../../blocks/Loader/Loader";
import logo from "../../../img/solimat-logo.svg";

import { identityServer } from "@solimat/solimat-web-endpoint";
import { ITokenResponse } from "@solimat/solimat-web-endpoint/lib/identity-server/dto";
import { UserContext } from "../../../contexts/UserContext";

import IconUserPatient from "../../../img/icons/svg/perfil-seleccion-icon-01.svg";
import IconUserCompany from "../../../img/icons/svg/perfil-seleccion-icon-02.svg";
import IconUserGestor from "../../../img/icons/svg/perfil-seleccion-icon-03.svg";

import "./RoleSelection.scss";
// ROLE: Administrator, Colaborator, Company, Patient, SuperCollaborator, SuperCompany, Freelance

const BASE_URL = process.env.REACT_APP_IDENTITY_SERVER_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_IDENTITY_SERVER_KEY || "";
const apiToken = new identityServer.Token(BASE_URL, FUNCTIONS_KEY);

interface IallMembership {
  id: string;
  name: string;
  description: string;
  user_id: string;
  account_id: string;
  company_id: string;
  permission: number;
}

const RoleSelection: React.FC<{ initial: IallMembership[] }> = (
  props: any,
  { initial = [] }
) => {
  const [userToken, setUserToken] = useState<any>({});
  const userPassword = props.location.password;
  const [disabledBtn, setDisabledBtn] = useState(true); //
  const [allMembership, setAllMembership] = useState(initial);
  const [sameType, setSameType] = useState(false);
  const { userData, setUserData } = useContext(UserContext);

  type membership = {
    id: string;
    name: string;
    description: string;
    user_id: string;
    account_id: string;
    company_id: string;
    permission: number;
    role: number;
  };

  useEffect(() => {
    localStorage.removeItem("otp");
    if (userData.members !== null) {
      const filteredMembershipNoAdmin = userData.members.filter(
        (member: any) => member.role !== 0
      );
      const equalTypes = areSameType(filteredMembershipNoAdmin);
      setSameType(areSameType(filteredMembershipNoAdmin));
      setAllMembership(filteredMembershipNoAdmin);
      if (filteredMembershipNoAdmin.length > 1 && !equalTypes) {
        setDisabledBtn(false);
      }
    } else {
      props.history.push("/inicio");
    }
  }, [userData]);

  const areSameType = (members: any[]) => {
    let firstElType = members[0].role;
    for (let i = 0; i < members.length; i++) {
      if (members[i].role !== firstElType) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    checkRol(sameType, allMembership);
  }, [allMembership]);

  const checkRol = (sameType: boolean, allMembership: membership[]) => {
    setDisabledBtn(false);

    if (allMembership.length) {
      if (sameType) {
        if (allMembership.length === 1 || allMembership[0].role === 3) {
          redirection(allMembership[0], allMembership[0].role);
        } else if (allMembership.length === 1 || allMembership[0].role === 0) {
          // props.history.push("/login"); ojo login
        }
      }
    }
  };
  const redirection = (data: any, role: number) => {
    setDisabledBtn(true);
    const path = checkPath(role);
    const opt = localStorage.getItem("otp");
    const dataToken: any = {
      username: userData.identity,
      password: userPassword,
      otp: opt ?? "",
      role: role.toString(),
    };
    //   apiToken
    //     .connectToken(dataToken)
    //     .then((resToken: any) => {
    //       var token: ITokenResponse = resToken.data;
    //       localStorage.setItem("token", JSON.stringify(token));
    //       localStorage.setItem("role", JSON.stringify(data));
    //       localStorage.setItem("allMembership", JSON.stringify(allMembership));

    //       const membershipStr = JSON.stringify(data);
    //       if(path?.includes('http')) {
    //         window.location.href = path + '/inicio-registro/' + userData.identity + '/' + membershipStr;
    //       } else {
    //         //redirect
    //         props.history.push({
    //           pathname: path,
    //           data: data,
    //         });
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //       // props.history.push("/login");ojo login
    //     });
  };

  const checkPath = (permission: number) => {
    switch (permission) {
      case 1: //Colaborator
      case 2: //Company
      case 4: //SuperCollaborator
      case 5: //SuperCompany
      case 6: //Freelance
        const companiesPath = process.env.REACT_APP_COMPANIESSPA_HOSTNAME;
        return companiesPath;
      case 3:
        return "/inicio";
      default:
        // error view?
        break;
    }
  };

  return (
    <>
      {allMembership !== null && allMembership.length > 1 ? (
        <>
          <div className="role-selection-wrapper">
            <Loader activeLoader={disabledBtn} />
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1>
                    <img src={logo} alt="" />
                  </h1>
                  <h2>
                    <span>Selecciona el perfil</span> con el que deseas
                    conectarte
                  </h2>
                </div>
              </div>
              <div className="row">
                {allMembership.filter(
                  (item: any) => item.role === 3 || item.role === 6
                ).length >= 1 && (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 link-btn-icon_block">
                    <button
                      className="link-btn-icon"
                      disabled={disabledBtn}
                      onClick={() =>
                        redirection(
                          {
                            id: "",
                            name: "Paciente",
                            description: "",
                            user_id: "",
                            account_id: "",
                            company_id: "",
                            role: 3,
                          },
                          3
                        )
                      }
                    >
                      <img src={IconUserPatient} alt="" />
                    </button>
                    <div className="link-btn-icon_label">Paciente</div>
                  </div>
                )}
                {allMembership.filter(
                  (item: any) => item.role === 1 || item.role === 4
                ).length >= 1 &&
                  allMembership
                    .filter((item: any) => item.role === 1 || item.role === 4)
                    .map((item: any, index: number) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-xs-12 link-btn-icon_block"
                          key={index}
                        >
                          <button
                            className="link-btn-icon"
                            disabled={disabledBtn}
                            onClick={() => redirection(item, item.role)}
                          >
                            <img src={IconUserCompany} alt="" />
                          </button>
                          <div className="link-btn-icon_label">{item.name}</div>
                        </div>
                      );
                    })}
                {allMembership.filter(
                  (item: any) => item.role === 2 || item.role === 5
                ).length >= 1 &&
                  allMembership
                    .filter((item: any) => item.role === 2 || item.role === 5)
                    .map((item: any, index: number) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-xs-12 link-btn-icon_block"
                          key={index}
                        >
                          <button
                            className="link-btn-icon"
                            disabled={disabledBtn}
                            onClick={() => redirection(item, item.role)}
                          >
                            <img src={IconUserGestor} alt="" />
                          </button>
                          <div className="link-btn-icon_label">{item.name}</div>
                        </div>
                      );
                    })}
                {allMembership.filter((item: any) => item.role === 6).length >=
                  1 &&
                  allMembership
                    .filter((item: any) => item.role === 6)
                    .map((item: any, index: number) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-xs-12 link-btn-icon_block"
                          key={index}
                        >
                          <button
                            className="link-btn-icon"
                            disabled={disabledBtn}
                            onClick={() => redirection(item, item.role)}
                          >
                            <img src={IconUserGestor} alt="" />
                          </button>
                          <div className="link-btn-icon_label">{item.name}</div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader activeLoader={true} />
      )}
    </>
  );
};

export default RoleSelection;
