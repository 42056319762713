import React from 'react';
import './TabsDeltaWithLeaveDetail.scss';

interface ITabsDeltaWithLeaveDetail {
  handleSelectTab: (tab:number)=>void
  tabSelected: number
}

const TabsDeltaWithLeaveDetail:React.FC<ITabsDeltaWithLeaveDetail> = ({
  handleSelectTab,
  tabSelected
  }) => {

  return ( 
    <div className='tabs-h-container'>
      <ul className="tabs-h">
        <li 
          className={`tab-item ${tabSelected===0?"active":""}`}
          onClick={()=>handleSelectTab(0)}>
            <span>Trabajador</span>
        </li>
        <li 
          className={`tab-item ${tabSelected===1?"active":""}`}
          onClick={()=>handleSelectTab(1)}>
            <span>Empresa</span>
        </li>
        <li 
          className={`tab-item ${tabSelected===2?"active":""}`}
          onClick={()=>handleSelectTab(2)}>
            <span>Lugar del accidente</span>
        </li>
        <li 
          className={`tab-item ${tabSelected===3?"active":""}`}
          onClick={()=>handleSelectTab(3)}>
            <span>Accidente</span>
        </li>
        <li 
          className={`tab-item ${tabSelected===4?"active":""}`}
          onClick={()=>handleSelectTab(4)}> 
          <span>Asistenciales</span>
        </li>
        <li 
          className={`tab-item ${tabSelected===5?"active":""}`}
          onClick={()=>handleSelectTab(5)}>
            <span>Económicos</span>
          </li>
        <li 
          className={`tab-item ${tabSelected===6?"active":""}`}
          onClick={()=>handleSelectTab(6)}>
            <span>Actores</span>
        </li>
        {/* <li 
          className={`tab-item ${tabSelected===7?"active":""}`}
          onClick={()=>handleSelectTab(7)}>
            <span>E. Laboral</span>
        </li> */}
      </ul>
    </div>
  );
}


export default TabsDeltaWithLeaveDetail;