import React, { useEffect } from "react";
import Moment from "moment";
import "../ManagementSheets.scss";
import useGetAdministrationSheets from "services/recordSheets/useGetAdministrationSheets";
import useDecode from "hooks/useDecode";
import EntornoLaboral from "../Comun/EntornoLaboral";
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;

interface WorkEnvironment {
  workEnvironmentCompanyId: number,
  name: string,
  ccc: string,
  cif: string,
}
interface Management {
  name: string,
  date: string,
  company: string
}
interface IDischargeLeavePart extends Management {
  id: string,     
  functional_limitation: string,        
  medical_examination: string,        
  diagnostic: string,        
  diagnostic_code_CIE9: string,        
  diagnostic_CIE10: string,        
  diagnostic_CIE10_description: string,        
  duration_days?: number     
  duration_months?: number,      
  estimate_of?: number,      
  estimate_to?: number,      
  is_relapse: boolean,      
  accident_date?:string,       
  discharge_date?: string,      
  leave_date?:  string,     
  name_doctor: string,        
  number_doctor: string,       
  CIAS_doctor: string,        
  accident_description: string,        
  is_extension_ti?: boolean, 
  is_contingency_determination?: boolean,             
  code_center: string,       
  description_center: string,        
  review_date?: string,
  CNO: string,
  CNO_description: string,        
  estimated_duration_days?: number,   
  code_ep: string,        
  type_parth_description: string,        
  type_process_description: string,        
  discharge_cause_description: string,        
  severity_description: string,              
  code_record_relapse: string,        
  description_record_relapse: string,        
  work_environment_description: string,    
  assistance_injured_description: string,
  record_id: guid,
  work_environment: WorkEnvironment,           
  enable_relapse_check: boolean,
  date_leave_proc_initial_relapse: string,
  management_type: string
}

interface ISheet {
  props:any
}

const ParteAltaBaja:React.FC<ISheet> = ({props}:any)=> {
  const parteId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    dataAdministrationSheet,
    loadingAdministrationSheet,
    errorAdministrationSheet,
    getDischargeLeavePart
    } = useGetAdministrationSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(()=> {
    getDischargeLeavePart(userId, prestacionId, parteId);
    Moment().locale("es");
  },[])

  if(loadingAdministrationSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorAdministrationSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }
  return (
    <>
    {dataAdministrationSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Detalles'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">Tipo de parte</label>
              <input type="text" id="TipoParteDescripcion" className="item-value" value={dataAdministrationSheet.tipoParteDescripcion} readOnly={true}/>
            </div>
            </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">Fecha accidente</label>
              <input type="text" id="FechaAccidente" className="item-value" value={dataAdministrationSheet.fechaAccidente!==null?Moment(dataAdministrationSheet.fechaAccidente).format("L"):''} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha de baja'}</label>
              <input type="text" id="FechaBaja" className="item-value" value={dataAdministrationSheet.fechaBaja!==null?Moment(dataAdministrationSheet.fechaBaja).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha de alta'}</label>
              <input type="text" id="FechaAlta" className="item-value" value={dataAdministrationSheet.fechaAlta!==null?Moment(dataAdministrationSheet.fechaAlta).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
          <div className="item d-flex align-items-center">
              <input type="checkbox" id="habilitarCheckRecaida" defaultChecked={dataAdministrationSheet.habilitarCheckRecaida}/>
              <label className="item-label ml-1">{'Recaída'}</label>
          </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Fecha de baja Proc Inicial Recaida'}</label>
              <input type="text" id="FechaBajaProcInicialRecaida" className="item-value" value={dataAdministrationSheet.fechaBajaProcInicialRecaida!==null?Moment(dataAdministrationSheet.fechaBajaProcInicialRecaida).format("L"):""} readOnly={true}/>
            </div>
          </div>


          <div className="col-xl-6">
            <div className="item d-flex align-items-center">
              <input type="checkbox" id="EsDeterminacionContingencia" value={'EsDeterminacionContingencia'} defaultChecked={dataAdministrationSheet.esDeterminacionContingencia?true:false}/>
              <label className="item-label ml-1">{'Determinación de contingencia'}</label>
            </div>
          </div>
          
          {/* recaida */}
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Expediente Recaida'}</label>
              <input type="text" id="CodigoExpRecaida" className="item-value w-25" value={dataAdministrationSheet.code_record_relapse} readOnly={true}/>
              <input type="text" id="DescripcionExpRecaida" className="item-value w-75" value={dataAdministrationSheet.description_record_relapse} readOnly={true}/>
            </div>
          </div> */}
          
          {/* HabilitarCheckRecaida */}
          <div className="col-xl-6 d-flex align-items-end">
            <div className="item w-100">
              <label className="item-label">{'Tipo de proceso'}</label>
              <input type="text" id="TipoProcesoDescripcion" className="item-value" value={dataAdministrationSheet.tipoProcesoDescripcion} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6 d-flex align-items-end">
            <div className="item w-100">
              <label className="item-label">{'Duración estimada en días'}</label>
              <input type="text" id="DuracionEstimadaDias" className="item-value" value={dataAdministrationSheet.duracionEstimadaDias} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Fecha revisión sigu. reconocimiento'}</label>
              <input type="text" id="FechaRevision" className="item-value" value={dataAdministrationSheet.fechaRevision!==null?Moment(dataAdministrationSheet.fechaRevision).format("L"):""} readOnly={true}/>
            </div>
          </div>
          {/* <div className="col-xl-2">
            <div className="item">
              <label className="item-label">{'Duración días'}</label>
              <input type="text" id="DuracionDias" className="item-value" value={dataAdministrationSheet.duracionDiasProcesoAnterior ? dataAdministrationSheet.duracionDiasProcesoAnterior :''} readOnly={true}/>
            </div>
          </div> */}
          {/* <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Duración meses'}</label>
              <input type="text" id="DuracionMeses" className="item-value" value={dataAdministrationSheet.duration_months} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Estimacion de'}</label>
              <input type="text" id="EstimacionDe" className="item-value" value={dataAdministrationSheet.estimate_of} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Estimacion a'}</label>
              <input type="text" id="EstimacionA" className="item-value" value={dataAdministrationSheet.estimate_to} readOnly={true}/>
            </div>
          </div> */}
          <div className="col-xl-12">
            <div className="item">
              <label className="item-label">{'Causa alta'}</label>
              <input type="text" id="CausaAltaDescripcion" className="item-value" value={dataAdministrationSheet.causaAltaDescripcion ? dataAdministrationSheet.causaAltaDescripcion : ''} readOnly={true}/>
            </div>
          </div>
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Médico'}</label>
              <input type="text" id="NombreFacultativo" className="item-value" value={dataAdministrationSheet.nombreFacultativo} readOnly={true}/>
            </div>
          </div> */}
          {/* <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Número Colegiado'}</label>
              <input type="text" id="NumeroFacultativo" className="item-value" value={dataAdministrationSheet.numeroFacultativo} readOnly={true}/>
            </div>
          </div> */}
        
          <div className="col-xl-12">
            <div className="item">
              <label className="item-label">{'Limitación Funcional'}</label>
              <input type="text" id="LimitacionFuncional" className="item-value" value={dataAdministrationSheet.limitacionFuncional ? dataAdministrationSheet.limitacionFuncional : '' } readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="item">
              <label className="item-label">{'Reconocimiento Médico'}</label>
              <input type="text" id="ReconocimientoMedico" className="item-value" value={dataAdministrationSheet.medical_examination} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Diagnóstico CIE9MC'}</label>
              <input type="text" id="CodigoDiagnosticoCIE9" className="item-value w-25" value={dataAdministrationSheet.codigoDiagnosticoCIE9} readOnly={true}/>
              <input type="text" id="Diagnostico" className="item-value w-75" value={dataAdministrationSheet.diagnostico} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Diagnostico CIE10'}</label>
              <input type="text" id="DiagnosticoCIE10" className="item-value w-25" value={dataAdministrationSheet.diagnosticoCIE10} readOnly={true}/>
              <input type="text" id="DiagnosticoCIE10Descripcion" className="item-value w-75" value={dataAdministrationSheet.diagnosticoCIE10Descripcion} readOnly={true}/>
            </div>
          </div>
          {/* <div className="col-xl-6 d-flex">
            <div className="item w-25">
              <label className="item-label">{'CIAS facultativo'}</label>
              <input type="text" id="CIAS_Facultativo" className="item-value" value={dataAdministrationSheet.CIASFacultativo} readOnly={true}/>
            </div>
            <div className="item w-75 ml-2">
              <label className="item-label">{'Médico control'}</label>
              <input type="text" id="ReconocimientoMedico" className="item-value" value={'-'} readOnly={true}/>
            </div>
          </div> */}
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Código ocupación CNO'}</label>
              <input type="text" id="CNO" className="item-value w-25" value={dataAdministrationSheet.CNO} readOnly={true}/>
              <input type="text" id="CNODescripcion" className="item-value w-75" value={dataAdministrationSheet.CNODescripcion} readOnly={true}/>
            </div>
          </div>
          {/* <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Código enfermedad profesional'}</label>
              <input type="text" id="Codigo_EP" className="item-value" value={dataAdministrationSheet.code_ep} readOnly={true}/>
            </div>
          </div> */}
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Centro Sanitario'}</label>
              <input type="text" id="CodigoCentro" className="item-value w-25" value={dataAdministrationSheet.codigoCentro} readOnly={true}/>
              <input type="text" id="DescripcionCentro" className="item-value w-75" value={dataAdministrationSheet.descripcionCentro} readOnly={true}/>
            </div>
            
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Gravedad'}</label>
              <input type="text" id="GravedadDescripcion" className="item-value" value={dataAdministrationSheet.gravedadDescripcion} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="item">
              <label className="item-label">{'Descripción del accidente'}</label>
              <textarea  id="DescripcionAccidente" className="item-value" readOnly={true} style={{resize:'none'}} defaultValue={dataAdministrationSheet.descripcionAccidente}></textarea>
            </div>
          </div>
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Entorno laboral'}</label>
              <input type="text" id="EntornoLaboralDescripcion" className="item-value" value={dataAdministrationSheet.entornoLaboralIdCausante} readOnly={true}/>
            </div>
          </div> */}
        </div>
      </div>
      <div className="col-xl 12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Facultativo'}</h3>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Médico'}</label>
                <input type="text" id="NombreFacultativo" className="item-value" value={dataAdministrationSheet.nombreFacultativo} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Número Colegiado'}</label>
                <input type="text" id="NumeroFacultativo" className="item-value" value={dataAdministrationSheet.numeroFacultativo} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'CIAS médico'}</label>
                <input type="text" id="CiasFacultativo" className="item-value" value={dataAdministrationSheet.ciasFacultativo} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
      {/* <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Datos Pre-Delta'}</h3>
          </div> */}
         
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Lugar del accidente'}</label>
              <input type="text" className="item-value" value={'**NO DTO**'} readOnly={true}/>
            </div>
          </div> */}
        
          {/* <div className="col-xl-3">
            <div className="item item-checkbox">
              <input type="checkbox" value={''} readOnly={true}/>
              <label className="item-label">{'Ha sido accidente de tráfico ** NO DTO **'}</label>
            </div>
          </div> */}
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Hora de trabajo (1-24)'}</label>
              <input type="text" className="item-value" value={'**NO DTO**'} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Hora del día del accidente (24)'}</label>
              <input type="text" className="item-value" value={'**NO DTO**'} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Descripción de la lesión ** NO DTO **'}</label>
              <input type="text" className="item-value" value={'Fracturas cerradas'} readOnly={true}/>
            </div>
          </div> */}
          {/* <div className="col-xl-6">
            <div className="item">
              <label className="item-label">{'Parte del cuerpo lesionada'}</label>
              <input type="text" id="AsistencialesParteLesionadaDescripcion" className="item-value" value={dataAdministrationSheet.type_parth_description} readOnly={true}/>
            </div>
          </div> */}
        {/* </div>
      </div> */}
      <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.Nombre : ""}
            CCC={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CCC : ""}
            CIF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CIF : ""}
            EntornoLaboralEmpresaId={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
            EsCuentaPropia={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EsCuentaPropia : ""}
            NAF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.NAF : ""}
            data={dataAdministrationSheet}
          />
    </section>
    }
    </>
  )
}

export default ParteAltaBaja;