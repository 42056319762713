const useCheckSurveyStatus = () => {
  const checkSurveyStatus = (status: number | undefined, expired:boolean) => {
    // FALTA DEFINIR ESTADOS

    switch (status) {
      case 0:
        if(!expired){
          return "Pendiente";
        } else {
          return "Caducada";
        }
      case 1:
        return "Enviada";
      default:
        return "";
    }
  };

  return {
    checkSurveyStatus
  }

}

export default useCheckSurveyStatus;
