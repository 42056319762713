import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import useDecode from "../../../hooks/useDecode";

import { user } from "@solimat/solimat-web-endpoint";
const USER_BASE_URL = process.env.REACT_APP_USUARIOS_PATH || "";
const USER_FUNCTIONS_KEY = process.env.REACT_APP_USUARIOS_KEY || "";
const userService = new user.AuthService(USER_BASE_URL, USER_FUNCTIONS_KEY);

interface Ivalues {
  oldPassword: string;
  password: string;
  passwordCopy: string;
}

const FormChangePassword: React.FC = () => {
  const history = useHistory();
  const [userIdentity, setUserIdentity] = useState("");
  const [error, setError] = useState("");
  const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
  const watchAllFields = watch();
  const tokenResponse = useDecode("token");

  useEffect(() => {
    setUserIdentity(tokenResponse.identity);
  }, [])

  const submitForm = (values: any) => {
    validatePassword(values);
  };

  const validatePassword = (values: Ivalues) => {
    if (values.password === values.passwordCopy) {
      if (values.password !== values.oldPassword) {
        const dataValues = {
          identity: userIdentity,
          oldPassword: values.oldPassword,
          password: values.password
        };

        userService
          .changeUserPassword(dataValues)
          .then((res: boolean) => {
            if (res) {
              history.push("/ajustes");
            } else {
              setError("* La contraseña no es correcta.");
            }
          });
      } else {
        setError("* La nueva contraseña no puede ser igual a la anterior");
      }
    } else {
      setError("* Las contraseñas no coinciden");
    }
  };

  const handleCancelSubmit = () => {
    reset();
    history.push("/ajustes");
  }

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="form-modal form-regular form-labels"
    >
      <label htmlFor="oldPassword" className="label-block">

        {error && <p className="form-error">{error}</p>}
        <input
          className={`input ${errors.oldPassword || error ? "invalid" : ""
            } ${watchAllFields.oldPassword !== "" ? "typed" : ""
            }`}
          type="password"
          name="oldPassword"
          id="oldPassword"
          {...register('oldPassword', {
            required: "Campo requerido"
          })}
        />
        <span className="float-label">Contraseña anterior</span>
        {errors.oldPassword && (
          <small className="form-error">
            {errors.oldPassword.message.toString()}
          </small>
        )}
      </label>
      <label htmlFor="password" className="label-block">
        <input
          className={`input ${errors.password || error ? "invalid" : ""
            } ${watchAllFields.password !== "" ? "typed" : ""
            }`}
          type="password"
          name="password"
          id="password"
          placeholder=""
          {...register('password', {
            required: "Campo requerido",
            minLength: {
              value: 6,
              message:
                "La contraseña tiene que tener al menos 6 caracteres"
            }
          })}
        />
        <span className="float-label">Nueva contraseña</span>
        {errors.password && (
          <small className="form-error">
            {errors.password.message.toString()}
          </small>
        )}
      </label>
      <label htmlFor="passwordCopy" className="label-block">
        <input
          className={`input ${errors.passwordCopy || error ? "invalid" : ""
            } ${watchAllFields.passwordCopy !== "" ? "typed" : ""
            }`}
          type="password"
          name="passwordCopy"
          id="passwordCopy"
          placeholder=""
          {...register('passwordCopy', {
            required: "Campo requerido",
            minLength: {
              value: 6,
              message:
                "La contraseña tiene que tener al menos 6 caracteres"
            }
          })}
        />
        <span className="float-label">Repetir nueva contraseña</span>
        {errors.passwordCopy && (
          <small className="form-error">
            {errors.passwordCopy.message.toString()}
          </small>
        )}
      </label>
      <div className="btns-container">
        <button type="button" className="btn-box btn-clear"
          onClick={handleCancelSubmit}
        >Cancelar</button>
        <button type="submit" className="btn-box btn-accent">
          Enviar
        </button>
      </div>
    </form>
  )
}

export default FormChangePassword;