import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import LoaderSubmitBtn from 'components/blocks/Buttons/LoaderSubmitBtn';
import DateDouble from 'components/blocks/Inputs/DateDouble';
import Cnae from './Cnae/Cnae';

interface IProps {
  handleFilterRatsb: (values: any) => void,
  loading: boolean
}
const SearcherWithoutLeavePending: React.FC<IProps> = ({ handleFilterRatsb, loading }) => {

  const { handleSubmit, reset, watch, formState: { errors }, register } = useForm()
  const watchCnae = watch('cnaeCode')

  const submitForm = (values: any) => {
    // console.log(values)
    handleFilterRatsb(values)
  }

  return (
    <form className="container form--simple-styled searcher" onSubmit={handleSubmit(submitForm)}>
      <fieldset className="row">
        <div className="col-xl-3 col-md-6 input-block">
          <label htmlFor="validation" className="input-block__label">Validación: </label>
          <select
            name="validation"
            id="validation"  {...register('validation')}
          >
            <option value="2">Todos</option>
            <option value="0">Correcta</option>
            <option value="1">Incorrecta</option>
          </select>
        </div>
        {/* <div className="col-xl-3 col-md-6 input-block">
          <label htmlFor="remesados" className="input-block__label">Remesado:</label>
          <select
            name="remesados"
            id="remesados"  {...register('remesados')}
          >
            <option value="2">Todos</option>
            <option value="0">Sí</option>
            <option value="1">No</option>
          </select>
        </div> */}
        <div className="col-xl-6">
          <DateDouble
            register={register}
            errors={errors}
            label="Fecha de accidente:"
          />
        </div>
      </fieldset>
      {/* <fieldset className="row">
        <div className="col-md-6">
          <div className="input-block">
            <label htmlFor="name" className="input-block__label">Datos del trabajador:</label>
            <input type="text" name="name" id="name" placeholder={"Introduzca nombre, apellidos, DNI o Nº Afiliación del trabajador"} {...register("name")} />
          </div>
        </div>
        <div className="col-md-6 input-block">
          <Cnae
            register={register}
            errors={errors}
            watchCnae={watchCnae}
          />
        </div>
        <div className="col-xl-12 col-md-12 input-block">
          <label htmlFor="companyCif" className="input-block__label">Empresa (CIF-Razón Social):</label>
          <input type="text" name="companyCif" id="companyCif" placeholder={"CIF"} {...register("companyCif")} />
        </div>
      </fieldset> */}
      <div className="row">
        <div className="col-12">
          <div className="btns-container c-right">
            <input className="btn-box btn-clear" type="button" value="Borrar"
              onClick={() => reset()}
            />
            <LoaderSubmitBtn
              loading={loading}
              text='Buscar'
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default SearcherWithoutLeavePending;