import React, { useState, useEffect, useContext } from "react";
import { AutoCompleteComponent, FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import masterDataUtils from 'services/utils/masterDataUtils';
import { useFormContext } from 'react-hook-form';
import { MasterDataContext } from "components/pages/Delta/Delta";
import Fuse from "fuse.js";

interface IInputProps {
  errors: any;
  register: any;
  watchProvinceCode?: any;
  watchProvinceDescription?: any;
  setValue?: any;
  idCode: string;
  idDescription: string;
}

interface Province {
  code: string;
  id: string;
  province_name: string;
}

const Province: React.FC<IInputProps> = ({
  idCode,
  idDescription,
  errors,
  register,
  setValue,
  watchProvinceCode,
  watchProvinceDescription,
}) => {
  const [data, setData] = useState<any>(null);

  const { getAllProvince, onlyDigitsWithMaxLength, pasteOnlyDigits } = useUtils();

  const iptId = idCode ? idCode : "provinceCode";
  const iptDescription = `${idDescription ? idDescription : "provinceDescription"}`;

  const masterDataContext = useContext(MasterDataContext);
  const { checkAndUpdate } = masterDataUtils(masterDataContext);

  const masterData = masterDataContext?.masterData;

  const itemKey: string = 'province';
  useEffect(() => {
    const provinces = getAllProvince().then((result)=>{
      setData(result)
    })
    // checkAndUpdate(itemKey, data, getAllProvince, setData);
  }, []);

  useEffect(() => {
    setValueProvinceDescription();
  }, [watchProvinceCode, data]);

  const handleChange = (args: any) => {
    if (data) {
      const codeValue = data?.find(
        (item: any) => item.Descripcion === args.value
      );
      if (codeValue) {
        setValue(iptId, codeValue ? codeValue.Codigo : "");
      } else {
        setValue(iptId, "");
      }
    }
  };

  const setValueProvinceDescription = () => {
    if (data) {
      const descriptionValue = data?.find(
        (item: any) => item.Codigo === watchProvinceCode.toString()
      );
      if (descriptionValue) {
        setValue(iptDescription, descriptionValue ? descriptionValue.Descripcion : "");
      } else {
        setValue(iptDescription, "");
      }
    }
  };

  const handleInputBlur = () => {
    const value = getValues(iptId);
    if (value === "") {
      setValue(iptDescription, "");
    }
  };

  const handleAutoCompleteBlur = () => {
    const value = getValues(iptDescription);
    if (value === "") {
      setValue(iptId, "");
    }
  };

  const itemsData: { [key: string]: Object }[] = data;
  const fields: object = { value: "Descripcion" };
  const { getValues } = useFormContext();

  function onFiltering(e: FilteringEventArgs) {
    let options: Object = {
      keys: ["Descripcion"],
      includeMatches: true,
      findAllMatches: true,
    };

    let fuse: any = new Fuse(itemsData && itemsData, options);
    let result: any = fuse.search(e.text);
    let data: { [key: string]: Object }[] = [];
    for (let i: number = 0; i < result.length; i++) {
      data.push(result[i].item as any);
    }
    e.updateData(data, undefined);
    let lists: any = document
      ?.getElementById("agreement_popup")
      ?.querySelectorAll(".e-list-item");
    highlightSearch(lists, result as any);
  }

  function highlightSearch(listItems: Element[], result: any): void {
    if (result.length > 0) {
      for (let i: number = 0; i < listItems?.length; i++) {
        let innerHTML: string = listItems[i]?.innerHTML;
        for (
          let j: number = result[i].matches[0].indices.length - 1;
          j >= 0;
          j--
        ) {
          let indexes: number[] = result[i].matches[0].indices[j];
          innerHTML =
            innerHTML.substring(0, indexes[0]) +
            '<span class="e-highlight">' +
            innerHTML.substring(indexes[0], indexes[1] + 1) +
            "</span>" +
            innerHTML.substring(indexes[1] + 1);
          listItems[i].innerHTML = innerHTML;
        }
      }
    }
  }

  return (
    <fieldset
      className={`input-block input-block--code ${
        (errors && errors[iptId]?.message) || (errors && errors[iptDescription]?.message)
          ? "error"
          : ""
      }`}
    >
      <div className="container-leyend">
        <legend className="input-block--code__legend">Provincia</legend>
      </div>
      <div className="container-inputs-cno-code">
        <input
          className="col-md-3 col-lg-3 code no-arrow"
          type="text"
          inputMode="numeric"
          name={iptId}
          id={iptId}
          {...register(iptId)}
          maxLength={2}
          autoComplete="off"
          onKeyDown={(e) => { onlyDigitsWithMaxLength(e, getValues(iptId), 2) }}
          onPasteCapture={(e) => pasteOnlyDigits(e)}
          onBlur={handleInputBlur}
        />
        <div className="col-md-8 auto-complete-component--syncfusion common-form">
          <AutoCompleteComponent
            change={handleChange}
            id={iptDescription}
            name={iptDescription}
            dataSource={itemsData}
            filtering={onFiltering}
            fields={fields}
            placeholder="Provincia"
            autofill={true}
            ignoreAccent={true}
            ignoreCase={true}
            {...register(iptDescription)}
            onBlur={handleAutoCompleteBlur}
          />
        </div>
      </div>
      {errors && (
        <>
          <p className="error m-0 ">{errors[iptId]?.message}</p>
          <p className="error m-0 ">{errors[iptDescription]?.message}</p>
        </>
      )}
    </fieldset>
  );
};

export default Province;
