import React, { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import SearcherWithLeavePending from 'components/blocks/Searchers/Delta/SearcherWithLeavePending';
import SearcherWithLeaveRemitted from 'components/blocks/Searchers/Delta/SearcherWithLeaveRemitted';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import GridDeltaAccidentWithLeave from 'components/blocks/Grids/GridDeltaAccidentWithLeave';
import Accordion from 'components/blocks/Accordion/Accordion';
import { TextMessagesTemplates } from 'components/blocks/TextMessagesTemplates/TextMessagesTemplates';
import PageMenu from "../../blocks/PageMenu/PageMenu";
//hooks
import useToggleExpandedAccordion from 'hooks/useToggleExpandedAccordion';
import useDecode from "hooks/useDecode";
//services
import useFilterPat from 'services/delta/pat/useFilterPat';
import { FilterPATRequest } from '@solimat/solimat-web-endpoint/lib/Delta/dto';
import useFilterRemittedPat from 'services/delta/pat/useFilterRemittedPat';


const DeltaWithLeave: React.FC = (props: any) => {

  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const breadcrumbs = ["unidad delta", location?.pathname.includes('remesados') ? "Accidentes con baja Delta remesados" : "Accidentes con baja pendientes de delta"];
  const [isRemitted, setIsRemitted] = useState<null | boolean>(null)
  const [patFilterResponse, setPatFilterResponse] = useState<any[] | null>(null)
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {
    filterPat,
    loadingFilterPat,
    successFilterPat,
    errorFilterPat
  } = useFilterPat();
  const { filterRemittedPat } = useFilterRemittedPat();
  const title = location?.pathname.includes('remesados') ? "Accidentes con baja Delta remesados" : "Accidentes con baja pendientes de delta"
  const {
    expandedState,
    toggleExpandedAccordionState
  } = useToggleExpandedAccordion();
  
  useEffect(() => {
    setIsRemitted(location.pathname.includes('remesados'));
  }, [location]);

  useEffect(() => {

    // if (location.pathname.includes('remesados')) {
    //   handleFilterRemittedPat({})
    // } else {
    //   handleFilterPat({});
    // }
  }, [])

  const handleFilterPat = async (values: any) => {
    // console.log('values',values)
    const FilterPat: any = {
      usuarioId: userId,
      validacion: parseInt(values.validation) || null,
      remesado: parseInt(values.remitted) || null,
      fechaBaja: values.FromDate || null,
      fechaAccidente: values.ToDate || null,
      nombre: values.name || null,
      apellido: values.surname || null,
      ipf: values.dni || null,
      numeroSS: values.ccc || null
    }
    // console.log('FilterPat',FilterPat)
    setLoading(true)
    const patResponse = await filterPat(FilterPat);
    if (patResponse) {
      setLoading(false);
      setError(false);
      setSuccess(true);
      setPatFilterResponse(patResponse);
      toggleExpandedAccordionState();
    } else {
      setLoading(false);
      setError(true);
      setSuccess(false);
      // SHOW ERROR
    }
  }

  const handleFilterRemittedPat = async (values: any) => {
    const sendData = {
      fechaBajaDesde: values.FromDate || null,
      fechaBajaHasta: values.ToDate || null,
      trabajador: values.worker || null,
      cnae: values.cnaeDescription || null,
      empresa: values.company || null,
      aceptado: typeof values.deltaPart === 'string' ? JSON.parse(values.deltaPart.toLowerCase()) : null,
    }
    setLoading(true)
    const response = await filterRemittedPat(sendData)
    if (response) {
      setLoading(false);
      setError(false);
      setSuccess(true);
      setPatFilterResponse(response);
      toggleExpandedAccordionState();

    } else {
      setLoading(false);
      setError(true);
      setSuccess(false);
      // SHOW ERROR
    }
  }


  return (
    <>
      <PageMenu title={title} breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to={`/unidad-delta`}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <section>
        {isRemitted &&
          <>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Accordion
                    title="Buscador de Deltas Remesados"
                    expandedInit={true}
                    expandedHide={expandedState}
                  >
                    <SearcherWithLeaveRemitted
                      handleFilterRemittedPat={handleFilterRemittedPat}
                      loading={loading}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </>
        }
        {isRemitted !== null && !isRemitted &&
          <>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Accordion
                    title="Buscador de Deltas"
                    expandedInit={true}
                    expandedHide={expandedState}
                  >
                    <SearcherWithLeavePending
                      handleFilterPat={handleFilterPat}
                      loading={loading}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </>
        }

        {loading &&
          <div className="mt-4">
            {TextMessagesTemplates.loading}
          </div>
        }
        {error &&
          <div className="mt-4">
            {TextMessagesTemplates.errorResponse}
          </div>
        }

        {patFilterResponse &&
          <div className="pl-2 pr-2">
            <div className="container-fluid--wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col p-0 mt-4">
                    <GridDeltaAccidentWithLeave
                      arrData={patFilterResponse}
                    // arrData={arrayData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </section>
    </>
  )
}

export default DeltaWithLeave;