import React, { useEffect } from "react";
import "../ManagementSheets.scss";
import useDecode from "hooks/useDecode";
import EntornoLaboral from "../Comun/EntornoLaboral";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import LoaderMin from "components/blocks/Loader/LoaderMin";
type guid = string;
interface RegulatoryBase {
  id: guid | string;
  baseReguladoraDiaria: string | number;
  coeficienteParcialidadCalculado?: string | number;
  descripcionCalculo: string;
  record_id: guid;
  estaActiva: boolean;
  entornoLaboral: any;
  management_type: string;
}
function BaseReguladora({ props }: any) {
  const baseReguladoraId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getRegulatoryBase,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(() => {
    getRegulatoryBase(userId, prestacionId, baseReguladoraId);
  }, []);

  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin />
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container container-base-reguladora">
          {( dataEconomicSheet.esAceptada === false) &&
          <div className="container-isactive">
            <h1>Base Reguladora Inactiva</h1>
          </div>
          }
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Base Reguladora"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Base Reguladora Diaria"}
                  </label>
                  <input
                    type="text"
                    id="baseReguladoraDiaria"
                    className="item-value"
                    value={dataEconomicSheet?.baseReguladoraDiaria}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Coeficiente Parcialidad Calculado"}
                  </label>
                  <input
                    type="text"
                    id="coeficienteParcialidadCalculado"
                    className="item-value"
                    value={dataEconomicSheet?.coeficienteParcialidadCalculado}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="item">
                  <label className="item-label">{"Descripción Calculo"}</label>
                  <input
                    type="text"
                    id="descripcionCalculo"
                    className="item-value"
                    value={dataEconomicSheet?.descripcionCalculo}
                    readOnly={true}
                  />
                </div>
              </div>
              {/* 
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Activa"}</label>
                  <input
                    type="text"
                    id="estaActiva"
                    className="item-value"
                    value={dataEconomicSheet?.estaActiva === true ? 'Si':'No'}
                    readOnly={true}
                  />
                </div>
              </div>*/}
            </div>
          </div>
          <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.Nombre
                : ""
            }
            CCC={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.CCC
                : ""
            }
            CIF={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.CIF
                : ""
            }
            EntornoLaboralEmpresaId={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId
                : ""
            }
            EsCuentaPropia={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.EsCuentaPropia
                : ""
            }
            NAF={
              dataEconomicSheet?.entornoLaboral
                ? dataEconomicSheet.entornoLaboral.NAF
                : ""
            }
          />
        </section>
      )}
    </>
  );
}

export default BaseReguladora;
