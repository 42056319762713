import { watch } from 'fs';
import React from 'react';

interface IInputProps {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  errors: any,
  register: any
  unit?: string
  watchedValue?: any
  disabled?: boolean
}

const InputFloat: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, watchedValue, register, unit, disabled }) => {

  const handleParseFloat = (e: any) => {
    const valueToFloat = parseFloat(watchedValue).toFixed(2);
    e.currentTarget.value = valueToFloat;
  }
  const isEconomicAverageB = name === 'economic_averageB' || name === 'economic_average' || name === 'economic_totalAnnual' || name === 'economic_b2' || name === 'economic_b1';
  return (
    <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
      <label htmlFor={id} className="input-block__label">{label}</label>
      {unit ?
        <>
          <div className="d-flex">
            <input
              type="text"//cambiar si falla a number
              className='no-arrow'
              name={name}
              id={id}
              placeholder={placeholder}
              {...register(name)}
              readOnly={isEconomicAverageB}


            // step="0.1"
            />
            <p className="ml-2">{unit}</p>
          </div>
          <p className="error m-0">
            {errors[name]?.message}
          </p>
        </>
        :
        <>
          <input
            type="text"//cambiar si falla a number
            className='no-arrow'
            name={name}
            id={id}
            placeholder={placeholder}
            {...register(name)}
            readOnly={isEconomicAverageB}

          // step="0.1"
          />
          {errors &&
            <p className="error m-0">
              {errors[name]?.message}
            </p>
          }
        </>
      }
    </div>
  )
}

export default InputFloat;

// USE EXAMPLE
{/* <InputFloat 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
/> */}