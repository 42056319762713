import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";

const useGetEpisodeRehabilitation = () => {
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
  const [loadingGetEpisodeRehabilitation, setLoadingGetEpisodeRehabilitation] =
    useState(false);
  const [successGetEpisodeRehabilitation, setSuccessGetEpisodeRehabilitation] =
    useState(false);
  const [errorGetEpisodeRehabilitation, setErrorGetEpisodeRehabilitation] =
    useState(null);
  const [episodeRehabilitation, setEpisodeRehabilitation] = useState<
    null | any
  >(null);

  const getEpisodeRehabilitation = (
    userId: string,
    prestacionId: string,
    _cb?: (data: any) => void
  ) => {
    setLoadingGetEpisodeRehabilitation(true);
    setSuccessGetEpisodeRehabilitation(false);
    apiRecord
      .getEpisodeRehabilitation(userId, prestacionId)
      .then((res: any) => {
        setLoadingGetEpisodeRehabilitation(false);
        setSuccessGetEpisodeRehabilitation(true);
        setEpisodeRehabilitation(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingGetEpisodeRehabilitation(false);
        setErrorGetEpisodeRehabilitation(err);
      });
  };
  return {
    episodeRehabilitation,
    getEpisodeRehabilitation,
    loadingGetEpisodeRehabilitation,
    successGetEpisodeRehabilitation,
    errorGetEpisodeRehabilitation,
  };
};
export default useGetEpisodeRehabilitation;
