import React, { useState, useEffect } from "react";
import { DatePicker } from "@syncfusion/ej2-calendars";
// @ts-ignore
import Moment from "moment";

import {
  Column,
  Sort,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Inject,
  Edit,
  PageSettingsModel,
  Page,
  RowDataBoundEventArgs,
} from "@syncfusion/ej2-react-grids";
import { DataManager } from "@syncfusion/ej2-data";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { EditSettingsModel } from "@syncfusion/ej2-react-grids";
import { IEditCell, ToolbarItems, Grid } from "@syncfusion/ej2-react-grids";
import { EmitType, getValue } from "@syncfusion/ej2-base";
import useUtils from "services/utils/useUtils";
import useAllUpdatePat from "services/delta/pat/useAllUpdatePat";
import { TextMessagesTemplates } from "components/blocks/TextMessagesTemplates/TextMessagesTemplates";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
//Load the L10n from ej2-base
import { loadCldr } from "@syncfusion/ej2-base";
import InputText from "../Delta/Inputs/InputText";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
declare var require: any;
loadCldr(
  require("cldr-data/main/es/ca-gregorian.json"),
  require("cldr-data/main/es/numbers.json"),
  require("cldr-data/main/es/timeZoneNames.json"),
  require("cldr-data/supplemental/numberingSystems.json")
);

interface IProps {
  completeRatsbId: any;
  accidents: any;
  handleGetAccidentsByRatsb?: (ratsbId: string) => void;
  loadingAccidents: boolean;
  errorAccidents?: boolean;
  validationPropertyResult: any;
  errors?: any;
  register?: any;
  remesed: boolean;
  dataDelta: any;
}

interface accident {
  accident_date: "2019-10-12T00:00:00";
  complete_name: "RODRIGUEZ ALONSO JUAN ALBERTO";
  contract: 0;
  contract_type: 1;
  gender_type: 1;
  id: "e5269660-8fb2-4483-bd86-08d8e205a7e8";
  injury_part: 0;
  injury_type: 0;
  ipf: "13894492V";
  naf: "451003810115";
  ratsb: null;
  ratsbid: "a286fba9-e452-4fc0-8673-08d8e20597eb";
}

const GridAccidentRelation: React.FC<IProps> = ({
  completeRatsbId,
  errorAccidents,
  loadingAccidents,
  accidents,
  handleGetAccidentsByRatsb,
  validationPropertyResult,
  errors,
  register,
  remesed,
  dataDelta,
}) => {
  let grid: Grid | null = new Grid();

  const [arrAccident, setArrAccident] = useState<any>(null);
  const [dataObjectsParams, setDataObjectsParams] = useState<any>(null);
  const [dataObjectsResponse, setDataObjectsResponse] = useState<any>(null);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(errors);
  let dataParams: any = {};
  let tooltip: any;
  const beforeRender = (args: any) => {
    tooltip.content = args.target.closest("td").innerText;
  };
  const {
    getAllContract,
    getAllContactForm,
    getAllInjuryPartOfBody,
    getTypeInjury,
    getTypeDocument,
  } = useUtils();

  const { createAccident, updateAccident, deleteAccident } = useAllUpdatePat();

  useEffect(() => {
    handleGetAllData();
  }, [errors]);

  const handleGetAllData = async () => {
    try {
      const responseUpdate = await Promise.all([
        getAllContactForm(),
        getAllInjuryPartOfBody(),
        getTypeInjury(),
        getAllContract(),
        getTypeDocument(),
      ]);

      const arrContractType = [
        {
          code: "1",
          description_code: "Contrato indefinido",
        },
        {
          code: "2",
          description_code: "Contrato temporal",
        },
      ];
      const arrGenderType = [
        {
          code: "1",
          description_code: "Hombre",
        },
        {
          code: "2",
          description_code: "Mujer",
        },
      ];
      const responseTypeDocument = responseUpdate[4];
      const responseAllContract2 = responseUpdate[3];
      const responseAllContract = arrContractType;
      const responseAllGender = arrGenderType;
      const responseAllContactForm = responseUpdate[0];
      const responseAllInjuryPartOfBody = responseUpdate[1];
      const responseAllTypeInjury = responseUpdate[2];
      const objectResponse: any = {};
      if (responseTypeDocument) {
        const newData = responseTypeDocument.map((item: any) => {
          return { code: item.Codigo, description_code: item.Descripcion };
        });
        objectResponse.TypeDocument = {
          field: "formatted_type_document",
          data: newData,
        };
      }
      if (responseAllContract2) {
        const newData = responseAllContract2.map((item: any) => {
          return { code: item.Codigo, description_code: item.Descripcion };
        });
        objectResponse.Contracts = {
          field: "formatted_contracts",
          data: newData,
        };
      }
      if (responseAllContract) {
        const newData = responseAllContract.map((item: any) => {
          return { code: item.code, description_code: item.description_code };
        });
        objectResponse.allContracts = {
          field: "formatted_contract_type",
          data: newData,
        };
      }
      if (responseAllGender) {
        const newData = responseAllGender.map((item: any) => {
          return { code: item.code, description_code: item.description_code };
        });
        objectResponse.allGender = {
          field: "formatted_gender_type",
          data: newData,
        };
      }
      if (responseAllContactForm) {
        const newData = responseAllContactForm.map((item: any) => {
          return { code: item?.Codigo, description_code: item?.Descripcion };
        });
        objectResponse.allContactForm = {
          field: "formatted_contact",
          data: newData,
        };
      }
      if (responseAllInjuryPartOfBody) {
        const newData = responseAllInjuryPartOfBody.map((item: any) => {
          return { code: item?.Codigo, description_code: item?.Descripcion };
        });
        objectResponse.allInjuryPartOfBody = {
          field: "formatted_injury_part",
          data: newData,
        };
      }
      if (responseAllTypeInjury) {
        const newData = responseAllTypeInjury.map((item: any) => {
          return { code: item?.Codigo, description_code: item?.TipoLesion };
        });
        objectResponse.allTypeInjury = {
          field: "formatted_injury_type",
          data: newData,
        };
      }

      let objParams: any = {};
      for (const property in objectResponse) {
        objParams[property] = {};
        objParams[property].params = handleGenerateParams(
          objectResponse[property].data,
          objectResponse[property].field
        );
      }
      setDataObjectsParams(objParams);
      setDataObjectsResponse(objectResponse);
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  const formatNumberToString = (number: number, totalLength: number) => {
    let valueOuput: string;
    if (typeof number === "number") {
      valueOuput = number.toString();
      if (valueOuput.length < totalLength) {
        let diff = totalLength - valueOuput.length;
        for (let i = 0; i < diff; i++) {
          valueOuput = "0" + valueOuput;
        }
      }
      return valueOuput;
    }
    return number;
  };

  useEffect(() => {
    setErrorUpdate(errors);
    handleFormatAccidents();
  }, [accidents, dataObjectsResponse]);
  useEffect(() => {
    if (validationPropertyResult === null) return;
    if (Object.entries(validationPropertyResult).length === 0) return;
    handleFormatAccidents();
  }, [validationPropertyResult]);
  const handleFormatAccidents = () => {
    if (accidents !== null && dataObjectsResponse !== null) {
      const arr = accidents.map((item: any, index: number) => {
        const getDescriptionGender = dataObjectsResponse?.allGender?.data.find(
          (gender: any) => gender.code === item.SexoId.toString()
        );
        const getcontract = dataObjectsResponse?.Contracts?.data.find(
          (contract: any) => contract.code === item.Contrato
        );
        const getTypeDocument = dataObjectsResponse?.TypeDocument?.data.find(
          (typeDocu: any) => typeDocu.code === item.TipoIPFCodigo.toString()
        );
        const getDescriptionContractType =
          dataObjectsResponse?.allContracts?.data.find(
            (contract: any) => contract.code === item.TipoContrato?.toString()
          );
        const itemContact = formatNumberToString(item.FormaContactoCodigo, 2);
        let getDescriptionContactForm =
          dataObjectsResponse?.allContactForm?.data.find(
            (contactForm: any) => contactForm.code === itemContact
          );
        const itemInjuryPart = formatNumberToString(
          item.ParteCuerpoLesionadaCodigo,
          2
        );
        let getDescriptionInjuryPartOfBody =
          dataObjectsResponse?.allInjuryPartOfBody?.data.find(
            (partOfBody: any) => partOfBody.code === itemInjuryPart
          );
        const itemInjuryType = formatNumberToString(item.TipoLesionCodigo, 3);
        let getDescriptionTypeInjury =
          dataObjectsResponse?.allTypeInjury?.data.find(
            (typeInjury: any) => typeInjury.code === itemInjuryType
          );
        let rowError = false;
        if (
          validationPropertyResult !== null &&
          validationPropertyResult.length > 0
        ) {
          for (const error of validationPropertyResult) {
            if (error.Item1.includes("accident")) {
              const indexAccident = error.item1.substring(9, 10);
              if (index === parseInt(indexAccident)) {
                // Cumple = red border
                rowError = true;
              }
            }
          }
        }
        return {
          ...item,
          formatted_gender_type: getDescriptionGender?.description_code || null,
          formatted_contract_type:
            getDescriptionContractType?.description_code || null, //'1',
          formatted_contracts: getcontract?.description_code || null,
          formatted_contact:
            getDescriptionContactForm?.description_code || null, //'00',
          formatted_injury_part:
            getDescriptionInjuryPartOfBody?.description_code || null, //'00',
          formatted_injury_type:
            getDescriptionTypeInjury?.description_code || null, //'000',
          formatted_type_document: getTypeDocument?.description_code || null,
          formatted_accident_date: item.FechaAccidente,
          formatted_error: rowError,
          errorUpdate: errors && errors,
        };
      });

      setArrAccident(arr);
    }
  };

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const onComplete = function (args: any) {
    if (args.requestType === "save" && args.action === "add") {
      const getCodeGender = dataObjectsResponse?.allGender?.data.find(
        (item: any) => item.description_code === args.data.formatted_gender_type
      );
      const getCodeContractType = dataObjectsResponse?.allContracts?.data.find(
        (item: any) =>
          item.description_code === args.data.formatted_contract_type
      );
      const getCodeContactForm = dataObjectsResponse?.allContactForm?.data.find(
        (item: any) => item.description_code === args.data.formatted_contact
      );
      const getCodeInjuryPartOfBody =
        dataObjectsResponse?.allInjuryPartOfBody?.data.find(
          (item: any) =>
            item.description_code === args.data.formatted_injury_part
        );
      const getCodeTypeInjury = dataObjectsResponse?.allTypeInjury?.data.find(
        (item: any) => item.description_code === args.data.formatted_injury_type
      );
      const getTypeDocument = dataObjectsResponse?.TypeDocument?.data.find(
        (typeDocu: any) =>
          typeDocu.description_code === args.data.formatted_type_document
      );
      const getcontract = dataObjectsResponse?.Contracts?.data.find(
        (item: any) => item.description_code === args.data.formatted_contracts
      );
      const accidentDate = args.data.formatted_accident_date
        ? new Date(args.data.formatted_accident_date).toISOString()
        : null;
      const sendData = {
        nombreApellidos: args.data.NombreApellidos || null, //string;
        contrato: null,//getcontract?.code ? getcontract?.code : null,
        sexoId: getCodeGender?.code ? getCodeGender?.code : null, //GenderType;
        naf: args.data.NAF || null, //string;
        ipf: args.data.IPF || null, //string;
        tipoContrato: getCodeContractType?.code
          ? getCodeContractType?.code
          : null,
        tipoIPFCodigo: getTypeDocument?.code ? getTypeDocument?.code : null,
        fechaAccidente: accidentDate, //Date;
        formaContactoCodigo: getCodeContactForm?.code
          ? getCodeContactForm?.code
          : null, //number;
        parteCuerpoLesionadaCodigo: getCodeInjuryPartOfBody?.code
          ? getCodeInjuryPartOfBody?.code
          : null, //number;
        tipoLesionCodigo: getCodeTypeInjury?.code
          ? getCodeTypeInjury?.code
          : null, //number;
        deltaSinBajaId: completeRatsbId || null, //guid;
        id: args.data.Id,
      };
      handleCreateAccident(sendData);
    }

    if (args.requestType === "save" && args.action === "edit") {
      const getCodeGender = dataObjectsResponse?.allGender?.data.find(
        (item: any) => item.description_code === args.data.formatted_gender_type
      );
      const getContractType = dataObjectsResponse?.allContracts?.data.find(
        (item: any) =>
          item.description_code === args.data.formatted_contract_type
      );
      const getContactForm = dataObjectsResponse?.allContactForm?.data.find(
        (item: any) => item.description_code === args.data.formatted_contact
      );
      const getInjuryPartOfBody =
        dataObjectsResponse?.allInjuryPartOfBody?.data.find(
          (item: any) =>
            item.description_code === args.data.formatted_injury_part
        );
      const getcontract = dataObjectsResponse?.Contracts?.data.find(
        (item: any) => item.description_code === args.data.formatted_contracts
      );
      const getTypeDocument = dataObjectsResponse?.TypeDocument?.data.find(
        (typeDocu: any) =>
          typeDocu.description_code === args.data.formatted_type_document
      );
      const getTypeInjury = dataObjectsResponse?.allTypeInjury?.data.find(
        (item: any) => item.description_code === args.data.formatted_injury_type
      );
      const accidentDate = args.data.formatted_accident_date
        ? new Date(args.data.formatted_accident_date).toISOString()
        : null;
      const sendData = {
        nombreApellidos: args.data.NombreApellidos || null, //string;
        contrato:null, //getcontract?.code ? getcontract?.code : null,
        sexoId: getCodeGender?.code ? getCodeGender?.code : null, //GenderType;
        naf: args.data.NAF || null, //string;
        ipf: args.data.IPF || null, //string;
        tipoContrato: getContractType?.code ? getContractType?.code : null,
        tipoIPFCodigo: getTypeDocument?.code ? getTypeDocument?.code : null,
        fechaAccidente: accidentDate, //Date;
        formaContactoCodigo: getContactForm?.code ? getContactForm?.code : null, //number;
        parteCuerpoLesionadaCodigo: getInjuryPartOfBody?.code
          ? getInjuryPartOfBody?.code
          : null, //number;
        tipoLesionCodigo: getTypeInjury?.code ? getTypeInjury?.code : null, //number;
        deltaSinBajaId: completeRatsbId || null, //guid;
        id: args.data.Id,
      };
      handleUpdateAccident(sendData);
    }

    if (args.requestType === "delete") {
      handleDeleteAccident(args.data[0].Id);
    }
  };

  const handleCreateAccident = async (data: any) => {
    const response = await createAccident(data);
    if (response) {
      // TO DO GET ACCIDENT

      handleGetAccidentsByRatsb(completeRatsbId);
    } else {
      // SHOW ERROR CREATE ACCIDENT
    }
  };

  const handleUpdateAccident = async (data: any) => {
    const response = await updateAccident(data);
    if (response) {
      // // TO DO GET ACCIDENT
    } else {
      // SHOW ERROR UPDATE ACCIDENT
    }
  };

  const handleDeleteAccident = async (id: any) => {
    const response = await deleteAccident(id);
    if (response) {
      // // TO DO GET ACCIDENT
    } else {
      // SHOW ERROR DELETE ACCIDENT
    }
  };

  const editOptions: EditSettingsModel = {
    allowEditing: !remesed,
    allowAdding: !remesed,
    allowDeleting: !remesed,
    showDeleteConfirmDialog: true,
  };
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    "Delete",
    "Update",
    "Cancel",
    // "Print",
  ];
  const requiredRules: object = { required: [true, "*Campo obligatorio"] };

  const handleGenerateParams = (dataObject: any, field: string) => {
    let stateElem: HTMLElement;
    let stateObj: DropDownList;

    const obj = {
      create: () => {
        stateElem = document.createElement("input");
        return stateElem;
      },
      destroy: () => {
        stateObj.destroy();
      },
      read: () => {
        return stateObj.text;
      },
      write: (args: any) => {
        const itemFieldRowData = dataObject.find(
          (item: any) => item.description_code === args.rowData[field]
        );
        stateObj = new DropDownList({
          dataSource: new DataManager(dataObject),
          enabled: true,
          fields: { value: "code", text: "description_code" },
          floatLabelType: "Never",
          placeholder: "Seleccionar",
          value: itemFieldRowData?.code || null, //stateObj?.value || args.rowData[field],
          width: "300",
          popupWidth: "auto",
        });
        stateObj.appendTo(stateElem);
      },
    };
    return obj;
  };

  let elem: HTMLInputElement;
  let datePickerObj: DatePicker;
  const datepickerTemp: IEditCell = {
    create: () => {
      elem = document.createElement("input");
      return elem;
    },
    destroy: () => {
      datePickerObj.destroy();
    },
    read: () => {
      return datePickerObj.value;
    },
    write: (args: { rowData: any; column: Column }) => {
      const lastDate = new Date(dataDelta.Fecha);
      const lastDayOfLastMonth = new Date(
        lastDate.getFullYear(),
        lastDate.getMonth() + 1,
        0
      );

      // const valueInput = args.rowData[args.column.field]? new Date(args.rowData[args.column.field]): null
      datePickerObj = new DatePicker({
        floatLabelType: "Never",
        value: args.rowData[args.column.field]
          ? new Date(args.rowData[args.column.field])
          : undefined,
        max: lastDayOfLastMonth,
      });
      datePickerObj.appendTo(elem);
    },
  };

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "NAF", direction: "Descending" }],
  });
  if (loadingAccidents) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5 mb-4">
              <h2 className="title-highlighted">Relación de accidentados:</h2>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-4">{TextMessagesTemplates.loading}</div>
      </>
    );
  }

  if (errorAccidents) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 mb-4">
            <h2 className="title-highlighted">Relación de accidentados:</h2>
          </div>
          <div className="col-12 mt-5 mb-4">
            {TextMessagesTemplates.errorResponse}
          </div>
        </div>
      </div>
    );
  }

  const rowDataBound: EmitType<any> = (args: RowDataBoundEventArgs) => {
    if (args.row) {
      if (getValue("formatted_error", args.data) === true) {
        args.row.classList.add("error-row");
      }
    }
  };
  const queryCellInfo = (args: any) => {
    if (args.column.field === "mensaje" && args.data[args.column.field]) {
      args.cell.title = args.data[args.column.field];
    }
  };
  return (
    <>
      <div className="container-fluid--wrapper">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5 mb-4">
                <h2 className="title-highlighted">
                  Relación de accidentados: {accidents?.length}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              {arrAccident !== null && dataObjectsParams !== null ? (
                <div className="control-section col-lg-12 variable-validate__grid">
                  <GridComponent
                    onChange={(e: any) => console.log(e)}
                    locale="es"
                    ref={(g) => (grid = g)}
                    dataSource={arrAccident}
                    toolbar={!remesed ? toolbarOptions : []}
                    actionComplete={((dataObjectsResponse) => {
                      return onComplete;
                    })(dataObjectsResponse)}
                    pageSettings={pageOptions}
                    editSettings={editOptions}
                    allowSorting={true}
                    sortSettings={sortingOptions}
                    rowDataBound={rowDataBound}
                    queryCellInfo={queryCellInfo}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field="formatted_error"
                        headerText="ERROR"
                        width="200"
                        textAlign="Center"
                        hideAtMedia="false"
                      />
                      <ColumnDirective
                        field="Id"
                        headerText="ID"
                        width="200"
                        textAlign="Center"
                        isPrimaryKey={true}
                        hideAtMedia="false"
                      />
                      <ColumnDirective
                        field="NombreApellidos"
                        headerText="Apellidos y Nombre"
                        textAlign="Left"
                        width="250px"
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosNombreApellidos${props.index}`}
                                name={`AccidentadosNombreApellidos${props.index}`}
                                defaultValue={props.NombreApellidos}
                                title={props.NombreApellidos}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosNombreApellidos${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="NAF"
                        headerText="Núm. Afiliación SS"
                        textAlign="Left"
                        width="250px"
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosNAF${props.index}`}
                                name={`AccidentadosNAF${props.index}`}
                                defaultValue={props.NAF}
                                readOnly
                                title={props.NAF}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 error-table-accident">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosNAF${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="formatted_type_document"
                        headerText="Tipo Documento"
                        textAlign="Left"
                        width="200px"
                        edit={dataObjectsParams?.TypeDocument?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosTipoDocumento${props.index}`}
                                name={`AccidentadosTipoDocumento${props.index}`}
                                defaultValue={props.formatted_type_document}
                                title={props.formatted_type_document}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosTipoDocumento${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="IPF"
                        headerText="DNI/NIE"
                        textAlign="Left"
                        width="150px"
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`IPF${props.index}`}
                                name={`IPF${props.index}`}
                                defaultValue={props.IPF}
                                title={props.IPF}
                                readOnly
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosGenero${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="formatted_gender_type"
                        headerText="Sexo"
                        textAlign="Left"
                        width="200px"
                        editType="dropdownedit"
                        edit={dataObjectsParams?.allGender?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosGenero${props.index}`}
                                name={`AccidentadosGenero${props.index}`}
                                defaultValue={props.formatted_gender_type}
                                title={props.formatted_gender_type}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosGenero${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      {/* <ColumnDirective
                        field="formatted_contracts"
                        headerText="Contrato"
                        textAlign="Left"
                        width="200px"
                        // allowEditing={false}
                        editType="dropdownedit"
                        edit={dataObjectsParams?.Contracts?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosContrato${props.index}`}
                                name={`AccidentadosContrato${props.index}`}
                                defaultValue={props.formatted_contracts}
                                // defaultValue={'Contrato indefinido sin clave especifica: A tiempo completo'}
                                
                                title={props.formatted_contracts}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosContrato${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                 */}
                      <ColumnDirective
                        field="formatted_contract_type"
                        headerText="Tipo Contrato"
                        textAlign="Left"
                        width="200px"
                        allowEditing={false}
                        // editType="dropdownedit"
                        // edit={dataObjectsParams?.allContracts?.params}
                        // edit={{
                        //   ...dataObjectsParams?.allContracts?.params,
                        //   params: {
                        //     ...dataObjectsParams?.allContracts?.params?.params,
                        //     value: 'Contrato indefinido' 
                        //   }
                        // }}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosTipoContrato${props.index}`}
                                name={`AccidentadosTipoContrato${props.index}`}
                                readOnly
                                defaultValue={
                                  // props.formatted_contract_type || ""
                                  'Contrato indefinido'
                                }
                                title={props.formatted_contract_type}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosTipoContrato${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                  
                      <ColumnDirective
                        field="formatted_accident_date"
                        headerText="Fecha Accidente"
                        textAlign="Left"
                        width="200px"
                        type="date"
                        format="dd-MM-yyyy"
                        edit={datepickerTemp}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosFechaAccidente${props.index}`}
                                name={`AccidentadosFechaAccidente${props.index}`}
                                defaultValue={Moment(
                                  props.formatted_accident_date
                                ).format("L")}
                                title={Moment(
                                  props.formatted_accident_date
                                ).format("L")}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosFechaAccidente${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="formatted_contact"
                        headerText="Forma Contacto"
                        textAlign="Left"
                        width="200px"
                        editType="dropdownedit"
                        edit={dataObjectsParams?.allContactForm?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosFormaContacto${props.index}`}
                                name={`AccidentadosFormaContacto${props.index}`}
                                defaultValue={props.formatted_contact}
                                title={props.formatted_contact}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosFormaContacto${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="formatted_injury_part"
                        headerText="Parte Lesionada"
                        textAlign="Left"
                        width="200px"
                        editType="dropdownedit"
                        edit={dataObjectsParams?.allInjuryPartOfBody?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id={`AccidentadosParteLesionada${props.index}`}
                                name={`AccidentadosParteLesionada${props.index}`}
                                defaultValue={props.formatted_injury_part}
                                title={props.formatted_injury_part}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosParteLesionada${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      <ColumnDirective
                        field="formatted_injury_type"
                        headerText="Tipo Lesión"
                        textAlign="Left"
                        width="200px"
                        editType="dropdownedit"
                        edit={dataObjectsParams?.allTypeInjury?.params}
                        validationRules={requiredRules}
                        template={(props: any) => {
                          return (
                            <>
                              <input
                                type="text"
                                id="AccidentadosTipoLesión"
                                name={`AccidentadosTipoLesión${props.index}`}
                                defaultValue={props.formatted_injury_type}
                                title={props.formatted_injury_type}
                              ></input>
                              {props.errorUpdate && props.errorUpdate ? (
                                <p className="error m-0 ">
                                  {props.errorUpdate &&
                                    props.errorUpdate[
                                      `AccidentadosTipoLesión${props.index}`
                                    ]?.message}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                      {/* <ColumnDirective field='remitted' headerText='Remesado' textAlign='Center' width="150px"/> */}
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Edit, Page, Sort]} />
                  </GridComponent>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GridAccidentRelation;
// {"nombreApellidos":"DANIEL MARTIN ARREDONDO",
// "contrato":"109",
// "sexoId":"2"
// ,"naf":"450034042880"
// "ipf":"13808866C",
// "tipoContrato":"1",
// "tipoIPFCodigo":"1",
// "fechaAccidente":"2024-01-31T23:00:00.000Z",
// "formaContactoCodigo":"00",
// "parteCuerpoLesionadaCodigo":"10"
// ,"tipoLesionCodigo":"010",
// "deltaSinBajaId":"e955c26e-41ba-4681-c3b7-08dbce3fc5dc"
// }
// {"nombreApellidos":"DANIEL MARTIN ARREDONDO",
// "contrato":"150",
// "sexoId":"2",
// "naf":"450034042880",
// "ipf":"13808866C",
// "tipoContrato":"1",
// "tipoIPFCodigo":"1",
// "fechaAccidente":"2024-02-01T23:00:00.000Z",
// "formaContactoCodigo":"16",
// "parteCuerpoLesionadaCodigo":"18",
// "tipoLesionCodigo":"022",
// "deltaSinBajaId":"e955c26e-41ba-4681-c3b7-08dbce3fc5dc",
// "id":"22771ac2-48db-4626-90cb-5f283f075b3d"}
