import React from 'react';
import { useFormContext } from 'react-hook-form';
interface IInputProps {
  yesId: string,
  noId: string,
  noAnswerId: string,
  name: string,
  errors: any,
  register: any
}

const YesNoNoAnswer: React.FC<IInputProps> = ({
  yesId,
  noId,
  noAnswerId,
  name,
  errors,
  register }) => {


  return (
    <div className={`pl-4 pr-4 pb-2 d-flex inputs--group ${errors && errors.name?.message ? 'error' : ''}`}>
      <label htmlFor={yesId} className="input-block--radio-check__label">
        <span className="text">Sí</span>
        <input
          type="radio"
          name={name}
          id={yesId}
          {...register(name, { required: 'Selecciona al menos una opción', value: 'true' })}
          value={'true'}

        />
      </label>
      <label htmlFor={noId} className="input-block--radio-check__label">
        <span className="text">No</span>
        <input
          type="radio"
          name={name}
          id={noId}
          {...register(name, { required: 'Selecciona al menos una opción', value: 'false' })}
          value={'false'}
        />
      </label>
      <label htmlFor={noAnswerId} className="input-block--radio-check__label">
        <span className="text">Sin contestar</span>
        <input
          type="radio"
          name={name}
          id={noAnswerId}
          {...register(name, { required: 'Selecciona al menos una opción', value: 'null' })}
          value={'null'}
        />
      </label>
      {errors &&
        <>
          <p className="error m-0">
            {errors.name?.message}
          </p>
        </>
      }
    </div>
  )
}

export default YesNoNoAnswer;