import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';


const useGetAllEconomicRecord = () => {
  const [loadingGetAllEconomicRecord, setLoadingGetAllEconomicRecord] = useState(false);
  const [successGetAllEconomicRecord, setSuccessGetAllEconomicRecord] = useState(false);
  const [errorGetAllEconomicRecord, setErrorGetAllEconomicRecord] = useState(null);
  const [dataEconomicRecord, setDataEconomicRecord] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);
 
  const getAllEconomicRecord = (userId: string, prestacionId: string,_cb?:(data:any)=>void) => {
    setLoadingGetAllEconomicRecord(true);
    setSuccessGetAllEconomicRecord(false);
    apiRecordSheets.getAllEconomicRecord(userId,prestacionId)
      .then((res:any) => {
        setLoadingGetAllEconomicRecord(false);
        setSuccessGetAllEconomicRecord(true);
        setDataEconomicRecord(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetAllEconomicRecord(false);
        setErrorGetAllEconomicRecord(err)
      })
  }

  return {
    getAllEconomicRecord,
    loadingGetAllEconomicRecord,
    successGetAllEconomicRecord,
    errorGetAllEconomicRecord,
    dataEconomicRecord
    };
}

export default useGetAllEconomicRecord;