import React, {useState} from 'react';
import { termsAndConditions } from "@solimat/solimat-web-endpoint";

const useGetTermsAndConditionsPage = () => {
  const [loadingGetTermsAndConditionsPage, setLoadingGetTermsAndConditionsPage] = useState(false);
  const [successGetTermsAndConditionsPage, setSuccessGetTermsAndConditionsPage] = useState(false);
  const [errorGetTermsAndConditionsPage, setErrorGetTermsAndConditionsPage] = useState(null);
  const [termsAndConditionsPage, setTermsAndConditionsPage] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_TERMINOS_Y_CONDICIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_TERMINOS_Y_CONDICIONES_KEY || "";
  const apiTermsAndConditions = new termsAndConditions.TermsAndConditionsService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  const getTermsAndConditionsPage = (_cb?:(data:any)=> void) => {
    apiTermsAndConditions.getTermsAndConditionsPage()
    .then((res:any) => {
        setTermsAndConditionsPage(res.data);
        setLoadingGetTermsAndConditionsPage(true);
        setSuccessGetTermsAndConditionsPage(true);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any) => {
      console.log(err)
      setLoadingGetTermsAndConditionsPage(false);
      setErrorGetTermsAndConditionsPage(err)
    })
  }
  
  return {
    termsAndConditionsPage,
    getTermsAndConditionsPage,
    loadingGetTermsAndConditionsPage,
    successGetTermsAndConditionsPage,
    errorGetTermsAndConditionsPage

  }
}

export default useGetTermsAndConditionsPage;