import Delta from '@solimat/solimat-web-endpoint/lib/Delta';

const useValidatePat = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);
  
  const validatePat = async(Id:any)=> {
    try 
    {
      const response = await apiDelta.validatePAT(Id)
        return response.data;
    } 
    catch (err) {
      console.error(err);
      return false;
    }

  }

  return {
    validatePat
  }

}

export default useValidatePat;
