import React, {useState, useEffect} from 'react';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import SurveysGrid from 'components/blocks/Grids/SurveysGrid';
import useGetAllAvailableSurvey from 'services/surveys/useGetAllAvailableUserSurveys';
import LoaderMin from 'components/blocks/Loader/LoaderMin';

// formId = survey_id
const fakeAllAvailableSurvey = [
  {
    id: "", // relación usuario y encuesta
    survey_id: "4fc9e2f5-6bbe-4e2e-ac80-08d86c35f620", // id encuesta formId
    user_id: "",
    name: "Encuesta A",
    send_date: "",
    expiration_date: "",
    status: 1,
    expired: false
  },
  {
    id: "",
    survey_id: "1138a438-e67e-4422-ec69-08d86ba5d47f",
    user_id: "",
    name: "Encuesta B",
    send_date: "",
    expiration_date: "",
    status: 0,
    expired: true
  },
  {
    id: "",
    survey_id: "1138a438-e67e-4422-ec69-08d86ba5d47f",
    user_id: "",
    name: "Encuesta C",
    send_date: "",
    expiration_date: "",
    status: 0,
    expired: false
  }
];

const Surveys:React.FC = (props) => {
  const { allAvailableUserSurveys,
    getAllAvailableUserSurveys,
    successGetAllAvailableUserSurveys,
    loadingGetAllAvailableUserSurveys,
    errorGetAllAvailableUserSurveys } = useGetAllAvailableSurvey();

  useEffect(() => {
    getAllAvailableUserSurveys()
  }, [])

  return(
    <>
      <PageMenu
        title="Mis encuestas"
        breadcrumbs={["Mis encuestas"]}
      >
      </PageMenu>
      <section className="container">
        <div className="row">
          <div className="col">
            {loadingGetAllAvailableUserSurveys?
              // <p className="loader-text">Cargando...</p>
              <LoaderMin/>
            :
              <>
              {successGetAllAvailableUserSurveys && allAvailableUserSurveys.length?    
                <SurveysGrid
                  props={props}
                  arrData={allAvailableUserSurveys}
                />        
                :
                <p className="loader-text">Lo sentimos, se ha producido un error.</p>
              }
              </>
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default Surveys;
