import React, { useState, useEffect } from "react";

import useGetAllAppointmentsByUserId from '../../../services/appointments/useGetAllAppointmentsByUserId';
import useSortData from "../../../hooks/useSortData";

import PageMenu from "../../../components/blocks/PageMenu/PageMenu";
import AppointmentsGrid from './AppointmentsGrid/AppointmentsGrid';
import AppointmentsCards from './AppointmentsCards/AppointmentsCards';
import { TextMessagesTemplates } from '../../blocks/TextMessagesTemplates/TextMessagesTemplates';
import useToggleViewCardsList from 'hooks/useToggleViewCardsList';
import './Appointments.scss';
import LoaderMin from "components/blocks/Loader/LoaderMin";
import NoData from "../noData/NoData";

const Appointments: React.FC = (props: any) => {
  const breadcrumbs = ["Mis citas"];

  const { getAllAppointmentsByUserId,
    crudeAppointmentsScheduled,
    crudeAppointmentsAnother,
    statusRes,
    loadingGetAllAppointmentsByUserId,
    allAppointmentsByUserId,
    errorGetAllAppointmentsByUserId } = useGetAllAppointmentsByUserId();

  const [appointmentsScheduled, setAppointmentsScheduled] = useState<any[]>([]);
  const [appointmentsAnother, setAppointmentsAnother] = useState<any[]>([]);
  const [ascendingSelectedScheduled, setAscendingSelectedScheduled] = useState(false);
  const [ascendingSelectedAnother, setAscendingSelectedAnother] = useState(true);

  const {
    orderDescendingData,
    orderAscendingData
  } = useSortData();

  const { handleToggleViewCard, viewCard } = useToggleViewCardsList();

  useEffect(() => {
    // 1.GET APPOINTMENTS // 2.FILTER APPOINTMENTS // 3.FORMAT APPOINTMENTS // 4.SET CRUDE APPOINTMENTS: scheduled & another 
    getAllAppointmentsByUserId();
  }, []);

  useEffect(() => {
    // 5. ORDER APPOINTMENTS BY NEW
    if (crudeAppointmentsScheduled.length > 0 && allAppointmentsByUserId !== undefined) {
      orderByAscending("scheduled");
    }
  }, [crudeAppointmentsScheduled]);

  useEffect(() => {
    // 5. ORDER APPOINTMENTS BY NEW
    if (crudeAppointmentsAnother.length > 0 && allAppointmentsByUserId !== undefined) {
      orderByDescending("another");
    }
  }, [crudeAppointmentsAnother]);

  const handleSetAppointmentsScheduled = (arrData: any) => {
    setAppointmentsScheduled(arrData);
  };

  const handleSetAppointmentAnother = (arrData: any) => {
    setAppointmentsAnother(arrData);
  };

  const orderByAscending = (typeAppointment: string) => { // from old to new
    if (typeAppointment === "scheduled") {
      orderAscendingData(
        crudeAppointmentsScheduled,
        handleSetAppointmentsScheduled
      );
      setAscendingSelectedScheduled(false);
    } else if (typeAppointment === "another") {
      orderAscendingData(
        crudeAppointmentsAnother,
        handleSetAppointmentAnother
      );
      setAscendingSelectedAnother(false);
    }
  };

  const orderByDescending = (typeAppointment: string) => { // from new to old
    if (typeAppointment === "scheduled") {
      orderDescendingData(
        crudeAppointmentsScheduled,
        handleSetAppointmentsScheduled
      );
      setAscendingSelectedScheduled(true);
    } else if (typeAppointment === "another") {
      orderDescendingData(
        crudeAppointmentsAnother,
        handleSetAppointmentAnother
      );
      setAscendingSelectedAnother(true);
    }
  };
  return (
    <>
      <PageMenu title="Mis citas" type="photo" breadcrumbs={breadcrumbs}>
        {(appointmentsScheduled.length || appointmentsAnother.length) ?
          <div className="btns-header-container">
            <button
              className={`btn-toggle-view ${viewCard ? "selected" : ""}`}
              onClick={handleToggleViewCard}
            >
              <span className="e-icons e-Level4"></span>
            </button>
            <button
              className={`btn-toggle-view ${viewCard ? "" : "selected"}`}
              onClick={handleToggleViewCard}
            >
              <span className="e-icons e-M_Bullets"></span>
            </button>
          </div>
          :
          null
        }
      </PageMenu>
      {(errorGetAllAppointmentsByUserId) ?
        <section className="container">
          {TextMessagesTemplates.errorResponse}
        </section>
        :
        <>
          <section className="container">
            <div className="row">
              <div className="col">
                <h2 className="section-title">Citas del día/Próximas citas</h2>
                {loadingGetAllAppointmentsByUserId || allAppointmentsByUserId === undefined ?

                  statusRes === 204 ?
                    // <p className="loader-text">No hay citas para mostrar.</p>
                    <NoData message={'No hay citas para mostrar.'} /> :
                    // <p className="loader-text">Cargando...</p>
                    <LoaderMin />
                  :
                  <>
                    {appointmentsScheduled.length > 0 ? (
                      <>
                        {viewCard ? (
                          <AppointmentsCards
                            props={props}
                            ascendingSelected={ascendingSelectedScheduled}
                            orderByDescending={orderByDescending}
                            orderByAscending={orderByAscending}
                            typeAppointments={'scheduled'}
                            items={appointmentsScheduled}
                            labelA="antigua"
                            orderIconA="descending"
                            orderIconB="ascending"
                            labelB="reciente"
                          />
                        ) : (
                          <AppointmentsGrid
                            dataGrid={crudeAppointmentsScheduled}
                            classAppointments={'next-appointments'}
                            props={props}
                            direction="Ascending"
                          />
                        )}
                      </>
                    )
                      :
                      <>
                        {/* <p className="loader-text">No hay citas para mostrar.</p> */}
                        <NoData message={'No hay citas para mostrar.'} />                    </>
                    }
                  </>
                }
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row">
              <div className="col">
                <h2 className="section-title">Citas pasadas</h2>
                {loadingGetAllAppointmentsByUserId || allAppointmentsByUserId === undefined ?
                  statusRes === 204 ?
                    //  <p className="loader-text">No hay citas para mostrar.</p>
                    <NoData message={'No hay citas para mostrar.'} /> :
                    <LoaderMin />
                  :
                  <>
                    {appointmentsAnother.length > 0 ?
                      <>
                        {viewCard ? (
                          <AppointmentsCards
                            props={props}
                            ascendingSelected={ascendingSelectedAnother}
                            orderByDescending={orderByDescending}
                            orderByAscending={orderByAscending}
                            typeAppointments={'another'}
                            items={appointmentsAnother}
                            labelA="reciente"
                            labelB="antigua"
                            orderIconA="descending"
                            orderIconB="ascending"
                          />
                        ) : (
                          <AppointmentsGrid
                            dataGrid={crudeAppointmentsAnother}
                            classAppointments={'past-appointments'}
                            props={props}
                            direction="Descending"
                          />
                        )}
                      </>
                      :
                      <>
                        {/* <p className="loader-text">No hay citas para mostrar.</p> */}
                        <NoData message={'No hay citas para mostrar.'} />
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </section>

        </>
      }
    </>
  );
};

export default Appointments;
