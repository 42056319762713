import React, { useState } from "react";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import Loader from "../../blocks/Loader/Loader";
const GenericForm = ({ gestionName, props, url }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };
  return (
    <>
      <PageMenu title={gestionName}>
        <div className="btns-header-container">
          <button onClick={() => props.history.goBack()}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </button>
        </div>
      </PageMenu>
      <div className="container">
        <div></div>
        <div className="view-form">
          {iframeLoaded ? null : <Loader activeLoader={true} label="...Cargando"></Loader>}
        </div>

        <iframe
          src={`${url}`}
          title={gestionName}
          className={`pago-directo-iframe visible}`}
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </>
  );
};

export default GenericForm;
