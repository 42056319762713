import { useState } from 'react';
import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import {FilterPATRequest} from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useFilterPat = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);

  const [loadingFilterPat,setLoadingFilterPat] = useState(false);
  const [successFilterPat,setSuccessFilterPat] = useState(false);
  const [errorFilterPat,setErrorFilterPat] = useState(false);
  
  const filterPat = async(FilterPat:any)=> {
    setLoadingFilterPat(true);
    try 
    {
      const response = await apiDelta.filterPat(FilterPat)
        setLoadingFilterPat(false);
        setSuccessFilterPat(true);
        setErrorFilterPat(false);
        return response.data;
    } 
    catch (err) {
      setLoadingFilterPat(false)
      setSuccessFilterPat(false);
      setErrorFilterPat(true);
      return false;
    }

  }

  return {
    filterPat,
    loadingFilterPat,
    successFilterPat,
    errorFilterPat
  }

}

export default useFilterPat;