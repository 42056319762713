import { useState } from "react";
import SurveyIngestion from "@solimat/solimat-web-endpoint/lib/survey-ingestion";

const useCreateSurveyJson = () => {
  const BASE_URL = process.env.REACT_APP_SURVEY_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_SURVEY_KEY || "";
  const apiSurvey = new SurveyIngestion.SurveyIngestionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateSurveyJson, setLoadingCreateSurveyJson] = useState(false);
  const [successCreateSurveyJson, setSuccessCreateSurveyJson] = useState(false);
  const [errorCreateSurveyJson, setErrorCreateSurveyJson] = useState(null);

  const createSurveyJson = (data:any, _cb?:()=>void) => {
    setLoadingCreateSurveyJson(true);
    setErrorCreateSurveyJson(null);
    apiSurvey
      .createSurveyJson(data)
      .then((res: any) => {
        setSuccessCreateSurveyJson(true);
        setLoadingCreateSurveyJson(false);
        if (typeof _cb === "function") {
          _cb();
        }
      })
      .catch((err:any) => {
        console.error("createSurveyJson error: ", err);
        setErrorCreateSurveyJson(err);
        setLoadingCreateSurveyJson(false);
        if (typeof _cb === "function") {
          _cb();
        }
      });
  };

  return {
    createSurveyJson,
    successCreateSurveyJson,
    loadingCreateSurveyJson,
    errorCreateSurveyJson
  };
};

export default useCreateSurveyJson;