import React, { useEffect, useState } from "react";
// blocks
import PageMenu from "../../blocks/PageMenu/PageMenu";
import Modal from "../../blocks/Modal/Modal";
import FormPassword from "../../blocks/Forms/FormPassword";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
// hooks
import useDecode from "../../../hooks/useDecode";
import useModal from "../../../hooks/useModal";
// styles
import "./Settings.scss";
// services

import useGetAllProvinceUser from "../../../services/utils/useGetAllProvinceUser";
import useGetTypeRoad from "../../../services/utils/useGetTypeRoad";
import { user } from "@solimat/solimat-web-endpoint";
const BASE_URL = process.env.REACT_APP_USUARIOS_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_USUARIOS_KEY || "";
const userService = new user.AuthService(BASE_URL, FUNCTIONS_KEY);

const SettingsNew: React.FC = (props: any) => {
  const [userToken, setUserToken] = useState<any>({});
  const [userAddress, setUserAddress] = useState<any>({});
  const [completeUser, setCompleteUser] = useState<any>({});
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const { collapsed, childrenProps, handleOpenModal, handleCloseModal } =
    useModal();
  const { allTypeRoad, getAllTypeRoad } = useGetTypeRoad();
  const [typeRoadState, setTypeRoadState] = useState([])
  const [typeProviceCode, setTypeProviceCode] = useState([])
  const { allProvinceUser, getAllProvinceUser } = useGetAllProvinceUser();
  const tokenResponse = useDecode("token");


  useEffect(() => {
    getAllTypeRoad().then((res)=>{
      setTypeRoadState(res);
    });
    getAllProvinceUser().then((res)=>{
      setTypeProviceCode(res);
    });
  }, []);

  useEffect(() => {
    if (typeRoadState?.length > 0 && typeProviceCode?.length > 0) {
      getCompleteUser();
    }
  }, [typeRoadState, typeProviceCode]);
 

  const getProvinceUser = (cod: string, data: any) => {
    const province = data?.filter((prov: any) => prov.Codigo === cod);
    return province && province[0]?.Descripcion;
  };
  const getTypeRoad = (cod: string, data: any) => {
    const roadType = data?.filter((type: any) => type.Codigo === cod);
    return roadType && roadType[0]?.Descripcion1;
  };
  const getCompleteUser = () => {
    if (tokenResponse !== undefined) {
      setUserToken(tokenResponse);

      userService
        .GetCompleteUser(tokenResponse.extension_id)
        .then((res: any) => {
          let formated_phone = "";
          let formated_email_address = "";
          const userMainPhone = res["telefonos"].filter(
            (telefono: any) => telefono?.principal
          );
          const userMainEmail = res["emails"].filter(
            (email: any) => email.principal
          );
          if (userMainEmail.length) {
            formated_email_address = userMainEmail[0].correo;
          }
          if (userMainPhone.length) {
            formated_phone = userMainPhone[0].numero;
          }

          setCompleteUser({ ...res, formated_email_address, formated_phone });
          saveAddress(res.direcciones);
          setLoadingPage(false);
        })
        .catch((err: any) => {
          setErrorRequest(true);
          setLoadingPage(false);
        });
    } else {
      props.history.push("");
    }
  };
  const saveAddress = (data: any[]) => {
    const mainAddress = data.filter((direccion: any) => direccion.principal);
    if (mainAddress.length >= 1) {
      const formatedAddress = formatAddress(mainAddress[0]);
      setUserAddress({
        userAddress: mainAddress[0],
        formatedAddress: formatedAddress,
      });
    } else if (data.length >= 1) {
      const formatedAddress = formatAddress(data[0]);

      setUserAddress({
        userAddress: data[0],
        formatedAddress: formatedAddress,
      });
    }
  };

  const formatAddress = (address: any) => {
    
    const {
      nombreVia,
      tipoViaDescripcion,
      tipoViaCodigo,
      numero,
      bisNumero,
      bloque,
      piso,
      puerta,
      escalera,
      codigoPostal,
      provincia,
      codigoProvincia,
      localidad,
    } = address;
    const tipoViaDescripcion2 = getTypeRoad(tipoViaCodigo && tipoViaCodigo, typeRoadState && typeRoadState)||'';
    const provincia2 = getProvinceUser(codigoProvincia, typeProviceCode && typeProviceCode)|| '';
    let formatedAddress: string = "";
    let formatedStreet: string = "";
    if (tipoViaDescripcion2 && nombreVia) {
      if (nombreVia) {
        if (tipoViaDescripcion2)
          formatedStreet += `  ${tipoViaDescripcion2}  -` || '';
        formatedStreet += ` ${nombreVia}` || '';
      }
      if (formatedStreet) {
        formatedAddress += formatedStreet;
        numero
          ? (formatedAddress += `, número ${numero} `||'')
          : (formatedAddress += "");
        bisNumero
          ? (formatedAddress += `, Bis- ${bisNumero} ` ||'')
          : (formatedAddress += "");
        if (bloque) formatedAddress += `, bloque ${bloque} ` || '';
        if (escalera) formatedAddress += `, escalera ${escalera}`||'';
        if (piso) formatedAddress += `, piso ${piso}`;
        if (puerta) formatedAddress += `, puerta ${puerta}`||'';
      }

      if (formatedStreet && codigoPostal) {
        formatedAddress += `, C.P. ${codigoPostal}`;
      } else if (!formatedStreet && codigoPostal) {
        formatedAddress += `C.P. ${codigoPostal}` ||'';
      }

      if ((formatedStreet || codigoPostal) && localidad) {
        formatedAddress += ` - ${localidad}`;
      } else if (!formatedStreet && !codigoPostal && !provincia2 && localidad) {
        formatedAddress += `${localidad}` ||'';
      }

      if ((formatedStreet || codigoPostal || localidad) && provincia2) {
        formatedAddress += `  - ${provincia2}`;
      } else if ((!formatedStreet || !codigoPostal) && provincia2) {
        formatedAddress += `  - ${provincia2}` ||'';
      }

    }

    return formatedAddress;
  };
  const handleToEdit = (e: any) => {
    props.history.push({
      pathname: e.target.id,
      completeUser: completeUser,
    });
  };
  return (
    <>
      <PageMenu
        title="Ajustes de usuario"
        breadcrumbs={["Ajustes de usuario"]}
      />
      {errorRequest && <section>{TextMessagesTemplates.errorResponse}</section>}

      {loadingPage ? (
        <section>{TextMessagesTemplates.loading}</section>
      ) : (
        <>
          {completeUser?.id && (
            <section className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="user-data-block">
                    <h3 className="label">Nombre</h3>
                    <p className="text">
                      {completeUser.nombre ? completeUser.nombre : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="user-data-block">
                    <h3 className="label">Primer Apellido</h3>
                    <p className="text">
                      {completeUser.apellido1 ? completeUser.apellido1 : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="user-data-block">
                    <h3 className="label">Segundo Apellido</h3>
                    <p className="text">
                      {completeUser.apellido2 ? completeUser.apellido2 : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="user-data-block">
                    <h3 className="label">Número de teléfono</h3>
                    <p className="text">
                      {completeUser.formated_phone
                        ? completeUser.formated_phone
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="user-data-block">
                    <h3 className="label">Domicilio</h3>
                    <p className="text address">
                      {userAddress?.userAddress?.id !== undefined
                        ? userAddress?.formatedAddress
                        : `-`}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="user-data-block">
                    <h3 className="label">Email</h3>
                    <p className="text email">
                      {completeUser?.formated_email_address
                        ? completeUser?.formated_email_address
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="container-btn-edit-use">
                  <button
                    className="btn-text "
                    onClick={(e) => handleToEdit(e)}
                  >
                    <h3
                      className="btn-text icon-right text-btn-edit-user"
                      id="ajustes/editar-usuario"
                    >
                      Editar datos de Usuario
                    </h3>
                  </button>
                </div>
                <div className="col-12">
                  <div className="change-password">
                    <h3 className="label">Contraseña</h3>

                    <a
                      className="btn-text icon-right"
                      href={`${process.env.REACT_APP_AUTH_B2C_PATH}oauth2/v2.0/authorize?p=${process.env.REACT_APP_B2C_PR_POLICY}&client_id=${process.env.REACT_APP_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_URL_BASE_WEB}%2Finicio&scope=openid&response_type=id_token&prompt=login&ui_locales=es`}
                    >
                      Cambiar contraseña
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}

      <Modal
        collapsed={collapsed}
        onClose={handleCloseModal}
        title="Introduzca su contraseña"
        subtitle="Para editar sus datos, necesitamos verificar su contraseña"
      >
        {collapsed ? (
          <div className="ghost-form"></div>
        ) : (
          <FormPassword
            redirectPath={childrenProps.path}
            onClose={handleCloseModal}
            props={props}
            completeUser={completeUser}
          />
        )}
      </Modal>
    </>
  );
};

export default SettingsNew;
