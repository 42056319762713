import React, { useEffect } from "react";
import "../ManagementSheets.scss";
import useDecode from "hooks/useDecode";
// import EntornoLaboral from "../Comun/EntornoLaboral";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import Moment from "moment";
import EntornoLaboral from "../Comun/EntornoLaboral";
import LoaderMin from "components/blocks/Loader/LoaderMin";
function CompensacionSLD({ props }: any) {
  const compensacionSLDId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getCompensationSld,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(() => {
    getCompensationSld(userId, prestacionId, compensacionSLDId);
  }, []);
  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin />
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Solicitud"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Tipo de Compensación"}</label>
                  <input
                    type="text"
                    id="tipoCompensacion"
                    className="item-value"
                    value={dataEconomicSheet?.tipoCompensacion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Periodo Desde"}</label>
                  <input
                    type="text"
                    id="periodoDesde"
                    className="item-value"
                    value={
                      dataEconomicSheet?.periodoDesde !== null
                        ? Moment(dataEconomicSheet?.periodoDesde).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Periodo Hasta"}</label>
                  <input
                    type="text"
                    id="periodoHasta"
                    className="item-value"
                    value={
                      dataEconomicSheet?.periodoHasta !== null
                        ? Moment(dataEconomicSheet?.periodoHasta).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe IT"}</label>
                  <input
                    type="text"
                    id="importeIT"
                    className="item-value"
                    value={`${dataEconomicSheet?.importeIT ? dataEconomicSheet?.importeIT : ''}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Razon Social Red Anterior"}
                  </label>
                  <input
                    type="text"
                    id="razonSocialRedAnterior"
                    className="item-value"
                    value={dataEconomicSheet?.razonSocialRedAnterior}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Datos Liquidación"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Tipo de Liquidación"}</label>
                  <input
                    type="text"
                    id="tipoLiquidacion"
                    className="item-value"
                    value={dataEconomicSheet?.tipoLiquidacion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Número de Liquidación"}
                  </label>
                  <input
                    type="text"
                    id="numeroLiquidacion"
                    className="item-value"
                    value={dataEconomicSheet?.numeroLiquidacion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Procedencia de Liquidación"}
                  </label>
                  <input
                    type="text"
                    id="procedenciaLiquidacion"
                    className="item-value"
                    value={dataEconomicSheet?.procedenciaLiquidacion}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Descripción de Liquidación"}
                  </label>
                  <input
                    type="text"
                    id="procedenciaLiquidacionDescripcion"
                    className="item-value"
                    value={dataEconomicSheet?.procedenciaLiquidacionDescripcion}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Fecha de Pago/ingreso"}
                  </label>
                  <input
                    type="text"
                    id="FechaPago"
                    className="item-value"
                    value={
                      dataEconomicSheet?.fechaPago !== null
                        ? Moment(dataEconomicSheet?.fechaPago).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Cuantía Económica Neto"}
                  </label>
                  <input
                    type="text"
                    id="CuantiaEconomicaNeto"
                    className="item-value"
                    value={dataEconomicSheet?.cuantiaEconomicaNeto}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"% de Retención"}</label>
                  <input
                    type="text"
                    id="Retencion"
                    className="item-value"
                    value={dataEconomicSheet?.retencion}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {dataEconomicSheet?.entornoLaboral ? (
            <EntornoLaboral
              id={"EntornoLaboral"}
              nombre={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.Nombre
                  : ""
              }
              CCC={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CCC
                  : ""
              }
              CIF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CIF
                  : ""
              }
              EntornoLaboralEmpresaId={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId
                  : ""
              }
              EsCuentaPropia={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EsCuentaPropia
                  : ""
              }
              NAF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.NAF
                  : ""
              }
            />
          ) : (
            ""
          )}
        </section>
      )}
    </>
  );
}

export default CompensacionSLD;
