import React  from "react";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import InputDate from "../Inputs/InputDate";
import InputText from "../Inputs/InputText";
interface IExpediente {
  ALFechaRecepcion: string | null; 
  ALNumeroExpediente: string;
  ALProvinciaCodigo: string;
  ALProvinciaDescripcion: string;
  EGCCodigoMutua: string;
  EGCCodigoMutuaDescripcion: string;
  EGCNumeroExpediente: string;
  FEmpresaCalidadDe: string;
  FEmpresaFechaPresenta: string; 
  FEmpresaNombreApellidos: string;
  FEmpresaProvincia: string;
  Id: string;
  MotivoRechazo: string;
}
interface ITabContent {
  data: IExpediente;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean;
}

const Partners: React.FC<ITabContent> = ({
  data,
  errors,
  register,
  disabled,
  setValue,
}) => {
  
  return (
    <div className="container form--simple-styled" id="partners_form">
      <div className="row">
        <div className="col-12">
          <FieldsetContainer disabled={disabled} legend="Empresa">
            <div className="col-12">
              <div className="d-flex align-items-center flex-wrap">
                <span
                  className={`input-block mb-0 ml-2 mr-2 ${errors && errors["actors_completeName"]?.message
                    ? "error"
                    : ""
                    }`}
                >
                  <InputText
                    label="Nombre"
                    name="actors_completeName"
                    id="actors_completeName"
                    placeholder={"Nombre..."}
                    register={register}
                    errors={errors}
                  // {...register('actors_completeName')}
                  />
                </span>
                {errors && (
                  <>
                    <p className="error m-0">
                      {errors["actors_completeName"]?.message}
                    </p>
                  </>
                )}
                {/* <span>En calidad de</span> */}
                <span
                  className={`input-block mb-0 ml-2 mr-2 ${errors && errors["actors_serveAs"]?.message ? "error" : ""
                    }`}
                >
                  <InputText
                    label="En calidad de"
                    name="actors_serveAs"
                    id="actors_serveAs"
                    placeholder={"Representante"}
                    register={register}
                    errors={errors}

                  />
                </span>
                {errors && (
                  <>
                    <p className="error m-0">
                      {errors["actors_serveAs"]?.message}
                    </p>
                  </>
                )}
                {/* <span>de la empresa, expide el presente parte en </span> */}
                <span
                  className={`input-block mb-0 ml-2 mr-2 ${errors && errors["actors_province"]?.message ? "error" : ""
                    }`}
                >
                  <InputText
                    label='de la empresa, expide el presente parte en'
                    name="actors_province"
                    id="actors_province"
                    placeholder={"ciudad"}
                    register={register}
                    errors={errors}
                  />
                </span>
                {errors && (
                  <>
                    <p className="error m-0">
                      {errors["actors_province"]?.message}
                    </p>
                  </>
                )}
                <span>a:</span>
                <span
                  className={`input-block mb-0 ml-2 mr-2 ${errors && errors["actors_submissionDate"]?.message
                    ? "error"
                    : ""
                    }`}
                >
                  {/* <input
                    type="text"
                    name="actors_submissionDate"
                    id="actors_submissionDate"
                    placeholder={"?"}
                    {...register('actors_submissionDate')}
                  /> */}
                  <InputDate
                    id="actors_submissionDate"
                    name="actors_submissionDate"
                    label=""
                    placeholder="?"
                    errors={errors}
                    register={register}
                    setValue={setValue}
                  />
                </span>
                {errors && (
                  <>
                    <p className="error m-0">
                      {errors["actors_submissionDate"]?.message}
                    </p>
                  </>
                )}
              </div>
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer
            disabled={true}
            legend="Entidad Gestora o Colaboradora"
          >
            <div className="col-xl-4">
              <InputText
                id="actors_code"
                name="actors_code"
                label="Núm. Mutua"
                placeholder="Núm. Mutua"
                errors={errors}
                register={register}
                //{...register('actors_code') }
                disabled={true}
              />
            </div>
            <div className="col-xl-4">
              <InputText
                id="actors_numExpedientEgc"
                name="actors_numExpedientEgc"
                label="Núm. Expediente Entidad Gestora o Colaboradora"
                placeholder="Núm. Expediente Entidad Gestora o Colaboradora"
                errors={errors}
                register={register}
                // {...register('actors_numExpedientEgc') }
                disabled={true}
              />
            </div>
            <div className="col-xl-4">
              <InputDate
                id="actors_acceptationDate"
                name="actors_acceptationDate"
                label="Fecha aceptación"
                placeholder="Fecha aceptación"
                errors={errors}
                register={register}
              // {...register('actors_acceptationDate') }
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer disabled={true} legend="Autoridad Laboral">
            <div className="col-xl-4">
              <InputText
                id="actors_codeAlp"
                name="actors_codeAlp"
                label="Código"
                placeholder="Código"
                errors={errors}
                register={register}
              // {...register('actors_codeAlp') }
              />
            </div>
            <div className="col-xl-4">
              <InputText
                id="actors_numExpedientAlp"
                name="actors_numExpedientAlp"
                label="Núm. Expediente Autoridad Laboral"
                placeholder="Núm. Expediente Autoridad Laboral"
                errors={errors}
                register={register}
              // {...register('actors_numExpedientAlp') }
              />
            </div>
            <div className="col-xl-4">
              <InputDate
                id="actors_receptionDate"
                name="actors_receptionDate"
                label="Fecha Recepción"
                placeholder="Fecha Recepción"
                errors={errors}
                register={register}
              // {...register('actors_receptionDate') }
              />
            </div>
            <div className="col-12">
              <div
                className={`input-block ${errors && errors["actors_reasonRejection"]?.message
                  ? "error"
                  : ""
                  }`}
              >
                <label
                  htmlFor="actors_reasonRejection"
                  className="input-block__label"
                >
                  Motivo del rechazo
                </label>
                <textarea
                  name="actors_reasonRejection"
                  id="actors_reasonRejection"
                  placeholder="Motivo del rechazo"
                  {...register('actors_reasonRejection')}
                />
              </div>
            </div>
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Partners;
