import { useState } from "react";
import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetAllCityUser = () => {
  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllCityUser, setLoadingGetAllCityUser] = useState(false);
  const [successGetAllCityUser, setSuccessGetAllCityUser] = useState(false);
  const [errorGetAllCityUser, setErrorGetAllCityUser] = useState(null);
  const [allCityUser, setAllCityUser] = useState<null|any>(null);

  const getAllCityUser = async () => {
    try {
      const res = await apiUtils.getAllCityUser()
        setAllCityUser(res.data)
        setSuccessGetAllCityUser(true);
        setLoadingGetAllCityUser(false);
        return res.data;
        
      }
      catch(err) {
        console.error("getAllCityUser error: ", err);
        setErrorGetAllCityUser(err);
        setLoadingGetAllCityUser(false);
      }
  };
  const getCityByProvinceUser = async (codProvincia : string ) => {
    try {
      const res = await apiUtils.getCityByProvinceUser(codProvincia)
      setAllCityUser(res.data)
      setSuccessGetAllCityUser(true);
      setLoadingGetAllCityUser(false);
      return res.data;

    }
    catch (err) {
      console.error("getAllCityUser error: ", err);
      setErrorGetAllCityUser(err);
      setLoadingGetAllCityUser(false);
    }
  };
  return {
    allCityUser,
    getAllCityUser,
    getCityByProvinceUser,
    successGetAllCityUser,
    loadingGetAllCityUser,
    errorGetAllCityUser
  };
};

export default useGetAllCityUser;
