import React, { useEffect, useState, useRef } from "react";
import Moment from "moment";
// import { FieldValues, UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormReset, UseFormSetValue, UseFormWatch, useForm } from "react-hook-form";
import Worker from "components/blocks/Delta/Forms/Worker";
import Company from "components/blocks/Delta/Forms/Company";
import AccidentLocation from "components/blocks/Delta/Forms/AccidentLocation";
import Accident from "components/blocks/Delta/Forms/Accident";
import Assistance from "components/blocks/Delta/Forms/Assistance";
import Economics from "components/blocks/Delta/Forms/Economics";
import Partners from "components/blocks/Delta/Forms/Partners";
// import ELaboral from "components/blocks/Delta/Forms/ELaboral";
import Buttons from "components/blocks/Delta/Buttons/Buttons";
// import { TextMessagesTemplates } from "components/blocks/TextMessagesTemplates/TextMessagesTemplates";
import generateUpdateObjectsDeltaPAT from "hooks/generateUpdateObjectsDeltaPAT";
import { errorsValidationDeltaWithLeave, findNameByDbKey } from "../ValidationDelta.data";
import logo from "../../../../img/solimat-logo-blanco.svg";
import { useHistory } from 'react-router-dom';

// import {
//   UpdateWorkerRequest,
//   UpdateCompanyRequest,
//   UpdateAccidentRequest,
//   UpdateAssistanceRequest,
//   UpdateEconomicRequest,
//   UpdateActorsRequest,
//   ValidatePATRequest,
// } from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import useAllUpdatePat from "services/delta/pat/useAllUpdatePat";
import useValidatePat from "services/delta/pat/useValidatePat";
import useDateFormat from "hooks/useDateFormat";
import "./TabsDeltaWithLeaveDetail.scss";
import Loader from "components/blocks/Loader/Loader";
import useGetCompletePatByUser from "services/delta/pat/useGetCompletePatByUser";
import { guid } from "@solimat/solimat-web-endpoint/lib/Delta/dto";

interface ITabsDeltaWithLeaveDetail {
  completePatByUser?: any;
  tabSelected: number;
  handleSubmit: any, // UseFormHandleSubmit<FieldValues, undefined>,
  reset: any, // UseFormReset<FieldValues> , 
  watch: any, //  UseFormWatch<FieldValues>, 
  register: any, // UseFormRegister<FieldValues>, 
  setValue: any, // UseFormSetValue<FieldValues>, 
  getValues: any, //  UseFormGetValues<FieldValues>,
  reactErrors: any,
  updatedDataDelta: (updated:boolean) => void
  // validationPropertyResult: any
  handleSendValidationPropertyResult: (validatePatResponse: any) => void;
  props:any
}

const TabsContentDeltaWithLeaveDetail: React.FC<ITabsDeltaWithLeaveDetail> = ({
  tabSelected,
  handleSendValidationPropertyResult,
  handleSubmit,
  reset,
  watch,
  register,
  setValue,
  getValues,
  reactErrors,
  updatedDataDelta,
  props
}) => {
  const belongsToAnotherCompany = watch('ccclocation_centerEP2')
  const dataMatchesCompany = watch('ccclocation_companyEP2')
  const [completePatByUser, setCompletePatByUser] = useState<any | null>(null);
  const data = completePatByUser;
  const disabled = data?.Estado > 3 ? true : false;
  const isExtinct = data?.Estado === 6 ? true : false
  const [loading, setLoading] = useState<boolean>(false);
  const { validatePat } = useValidatePat();
  const [codeCNO, setCodeCNO] = useState(data?.Trabajador.CNOCodigo);
  const [validationPropertyResult, setValidationPropertyResult] = useState<
    any | null
  >(null);
  const [valuesForm, setValuesForm] = useState([])
  const {
    updateDelta,
  } = useAllUpdatePat();
  const { dateFormatLocaleShortStr } = useDateFormat();
  const [errors, setErrors] = useState<any | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [showComponent, setShowComponent] = useState(false);
  const deltaId = props.match.params.deltaId;
  const history = useHistory();
  const {
    getCompletePatByUser,
  } = useGetCompletePatByUser();

  useEffect(() => {

    handleGetCompletePatByUser(deltaId)



    if (!validationPropertyResult || validationPropertyResult === null) {
     
      return setErrors({});
    } else {
      let objError: any = {};
      const formValidationData = [...errorsValidationDeltaWithLeave];
      for (const propertyResult of validationPropertyResult) {
        const isProperty = formValidationData.find(
          (item: any) => item.dbKey === propertyResult.Item1 
        );

        if (!isProperty) {
          if (!objError["noDbKey"]) {
            objError["noDbKey"] = {
              messages: [],
              message: "",
            };
          }
          objError["noDbKey"].messages.push(propertyResult.Item2);
          objError["noDbKey"].message += propertyResult.Item2;
        } else {
          if (!objError[findNameByDbKey(propertyResult.Item1, formValidationData)]) {
            objError[findNameByDbKey(propertyResult.Item1, formValidationData)] = {
              ...isProperty,
              messages: [],
            };
          }
          objError[findNameByDbKey(propertyResult.Item1, formValidationData)].messages.push(propertyResult.Item2);
          objError[findNameByDbKey(propertyResult.Item1, formValidationData)].message += propertyResult.Item2;
        }
      }
      setErrors((prevState: any) => {
        return { ...objError };
      });
      window.scrollTo(0, 0);
    }
  }, [validationPropertyResult, showComponent]);
  const handleGetCompletePatByUser = async (deltaId: guid) => {
    setLoading(true);
    const responseGetCompletePatByUser = await getCompletePatByUser(deltaId);
 
    if (responseGetCompletePatByUser) {

      setLoading(false);
      setCompletePatByUser(responseGetCompletePatByUser);
    } else {

      setLoading(false);
    }
  };
  const formatAssistanceInjury = (data: any) => {
    const lengthInjury = data?.assistance?.injury.toString().length;
    let injury = data?.assistance?.injury;
    if (
      data?.assistance?.injury !== null &&
      data?.assistance?.injury !== undefined
    ) {
      for (let i = lengthInjury; i < 3; i++) {
        injury = "0" + injury;
      }
    }
    return injury;
  };


  useEffect(() => {
    if (completePatByUser && completePatByUser.Trabajador) {

      setCodeCNO(completePatByUser.Trabajador.CNOCodigo);
    }
  }, [completePatByUser]);

  useEffect(() => {
    if (belongsToAnotherCompany === 'true' && dataMatchesCompany === 'false') {
      setValue('cccclocation_reason', data?.CCCLugar?.DatosRazon ? data?.CCCLugar?.DatosRazon : '')
      setValue('cccclocation_company_address', data?.CCCLugar?.DatosDomicilio ? data?.CCCLugar?.DatosDomicilio : '')
      setValue('ccclocation_company_province', data?.CCCLugar?.DatosProvinciaCodigo ? data?.CCCLugar?.DatosProvinciaCodigo : '')
      setValue('ccclocation_company_city', data?.CCCLugar?.DatosMunicipio ? data?.CCCLugar?.DatosMunicipio : '')
      setValue('ccclocation_company_cityDescription', data?.CCCLugar?.DatosMunicipioDescripcion ? data?.CCCLugar?.DatosMunicipioDescripcion : '')
      setValue('ccclocation_company_cp', data?.CCCLugar?.DatosCodigopostal ? data?.CCCLugar?.DatosCodigopostal : '')
      setValue('ccclocation_company_phone', data?.CCCLugar?.DatosTelefono ? data?.CCCLugar?.DatosTelefono : '')
      setValue('ccclocation_company_codeCNAE', values2?.company_codeCNAE ? values2?.company_codeCNAE : '')

    } else if (belongsToAnotherCompany === 'true' && dataMatchesCompany === 'true'   ) {
      setValue('cccclocation_reason', values2.company_reason)
      setValue('cccclocation_company_address', values2.company_address)
      setValue('ccclocation_company_province', values2.company_province)
      setValue('ccclocation_company_city', values2.company_city)
      setValue('ccclocation_company_cityDescription', values2.company_cityDescription)
      setValue('ccclocation_company_cp', values2.company_cp)
      setValue('ccclocation_company_phone', values2.company_phone)
      setValue('ccclocation_company_codeCNAE', values2.company_codeCNAE)
    } else if (belongsToAnotherCompany === 'false' && dataMatchesCompany === 'true'   ) {
      setValue('cccclocation_reason', data?.CCCLugar?.DatosRazon ? data?.CCCLugar?.DatosRazon : '')
      setValue('cccclocation_company_address', data?.CCCLugar?.DatosDomicilio ? data?.CCCLugar?.DatosDomicilio : '')
      setValue('ccclocation_company_province', data?.CCCLugar?.DatosProvinciaCodigo ? data?.CCCLugar?.DatosProvinciaCodigo : '')
      setValue('ccclocation_company_city', data?.CCCLugar?.DatosMunicipio ? data?.CCCLugar?.DatosMunicipio : '')
      setValue('ccclocation_company_cityDescription', data?.CCCLugar?.DatosMunicipioDescripcion ? data?.CCCLugar?.DatosMunicipioDescripcion : '')
      setValue('ccclocation_company_cp', data?.CCCLugar?.DatosCodigopostal ? data?.CCCLugar?.DatosCodigopostal : '')
      setValue('ccclocation_company_phone', data?.CCCLugar?.DatosTelefono ? data?.CCCLugar?.DatosTelefono : '')
      setValue('ccclocation_company_codeCNAE', values2?.company_codeCNAE ? values2?.company_codeCNAE : '')
  }

  }, [belongsToAnotherCompany, dataMatchesCompany])
  useEffect(() => {
    if (!completePatByUser) return;
    const data = completePatByUser;
    let values = getValues()
    setValuesForm(values)
    let formatedData = formatEconomic(data?.Economicos);
    let formattedAssistanceInjury = formatAssistanceInjury(data);


    reset({
      // // worker
      worker_name: data?.Trabajador?.Nombre,
      worker_firstName: data?.Trabajador?.Apellido1,
      worker_secondName: data?.Trabajador?.Apellido2,
      worker_ipfType: data?.Trabajador?.TipoIPFCodigo,
      worker_ipf: data?.Trabajador?.IPF,
      worker_naf: data?.Trabajador?.NAF,
      worker_birthDate: data.Trabajador?.FechaNacimiento ? Moment(data.Trabajador?.FechaNacimiento).format("L") : null,
      worker_nation: data?.Trabajador?.Nacionalidad,
      worker_genderType: data?.Trabajador?.SexoId,
      worker_situation: data?.Trabajador?.SituacionId, // compoennt
      worker_admissionDate: data.Trabajador?.FechaIngreso ? Moment(data.Trabajador?.FechaIngreso).format("L") : null,
      worker_cno_text: data?.Trabajador?.CNOTexto,
      worker_cno_code: data?.Trabajador?.CNOCodigo,
      // worker_cno_code1:data?.Trabajador?.CNOCodigo.slice(0, 1),
      // worker_cno_code2:data?.Trabajador?.CNOCodigo.slice(0, 1),
      // worker_cno_code3:data?.Trabajador?.CNOCodigo.slice(1, 2),
      // worker_cno_code4:data?.Trabajador?.CNOCodigo.slice(1, 2),
      worker_cno_description: data?.Trabajador?.CNODescripcion, // OcupationCode component
      worker_months: data?.Trabajador?.AntiguedadMeses,
      worker_days: data?.Trabajador?.AntiguedadDias,
      worker_agreement_text: data?.Trabajador?.TextoConv,
      worker_socialSecurityRegime: data?.Trabajador?.RegimenSSId, // TO MODIFY  data?.worker?.social_security_regime_description SocialSecurityRegime
      worker_contractDescription: data?.Trabajador?.ContratoDescripcion, // agreementType
      worker_contract: data?.Trabajador?.Contrato, // agreementType
      worker_atepCnae: data?.Trabajador?.AtepCnae,
      worker_atepCnaeDescription: data?.Trabajador?.AtepCnaeDescripcion,
      worker_address: data?.Trabajador?.Domicilio,
      worker_Cno_Price_ATEP: data?.Trabajador?.AtepOcupacion,
      worker_phone: data?.Trabajador?.Telefono,
      worker_province: data?.Trabajador?.ProvinciaCodigo,
      worker_provinceDescription: data?.Trabajador?.ProvinciaDescripcion,
      worker_city: data?.Trabajador?.Municipio,
      worker_cityDescription: data?.Trabajador?.MunicipioDescripcion,
      worker_cp: data?.Trabajador?.CodigoPostal,
      // // company
      company_cifnif: data?.Empresa?.CifNif,
      company_ccc: data?.Empresa?.CCC,
      company_workforce: '0',// data?.Empresa?.Plantilla,
      company_reason: data?.Empresa?.Razon,
      company_textCNAE: data?.Empresa?.CnaeTexto,
      company_codeCNAE: data?.Empresa?.CnaeCodigo,
      company_codeCNAEDescription: data?.Empresa?.CnaeDescripcion,
      company_address: data?.Empresa?.Domicilio,
      company_phone: data?.Empresa?.Telefono,
      company_province: data?.Empresa?.ProvinciaCodigo,
      company_provinceDescripcion: data?.Empresa?.ProvinciaDescripcion, // province description,
      company_city: data?.Empresa?.Municipio,
      company_cityDescription: data?.Empresa?.MunicipioDescripcion,
      company_cp: data?.Empresa?.CodigoPostal,
      company_contracted: `${data?.Empresa?.EsContrata}`,
      company_ett1: `${data?.Empresa?.EsETT}`,
      company_assumptionPerson: data?.Empresa?.EsPreventivaAsunPersona,
      company_externalPreventionService: data?.Empresa?.EsPreventivaServPrevAje,
      company_jointPreventionServices: data?.Empresa?.EsPreventivaServPrevMan,
      company_ownPreventionService: data?.Empresa?.EsPreventivaServPrevPro,
      company_designatedWorkers: data?.Empresa?.EsPreventivaTrabDesigna,
      company_nothing: data?.Empresa?.EsPreventivaNinguna,

      // // ccc location / accident location
      ccclocation_codeDescription: data?.CCCLugar?.Codigo,
      ccclocation_traffic: data?.CCCLugar?.EsTrafico,
      ccclocation_centerEP2: `${data?.CCCLugar?.EsCentroCentroEp2}`,
      ccclocation_companyEP2: `${data?.CCCLugar?.EsCentroEmpresaEp2}`,
      ccclocation_CCCLugarCentroTipoEmpresaCodigo: data?.CCCLugar?.CentroTipoEmpresaCodigo,
      ccclocation_nation: data?.CCCLugar?.Pais,
      ccclocation_province: data?.CCCLugar?.ProvinciaCodigo,
      ccclocation_city: data?.CCCLugar?.Municipio,
      ccclocation_provinceDescription: data?.CCCLugar?.ProvinciaDescripcion,
      ccclocation_cityDescription: data?.CCCLugar?.MunicipioDescripcion,
      ccclocation_address: data?.CCCLugar?.Direccion,
      ccclocation_public_road: data?.CCCLugar?.Viakm,
      ccclocation_other_side: data?.CCCLugar?.Otro,
      ccclocation_CCCLugarCentroCifTipo: data?.CCCLugar?.CentroCifTipo || null,
      cccclocation_reason: data?.CCCLugar?.DatosRazon,
      cccclocation_company_address: data?.CCCLugar?.DatosDomicilio,
      ccclocation_company_province: data?.CCCLugar?.DatosProvinciaCodigo,
      ccclocation_company_city: data?.CCCLugar?.DatosMunicipio,
      ccclocation_company_cityDescription: data?.CCCLugar?.DatosMunicipioDescripcion,
      ccclocation_company_cp: data?.CCCLugar?.DatosCodigopostal,
      ccclocation_company_phone: data?.CCCLugar?.DatosTelefono,
      ccclocation_company_ccc: data?.CCCLugar?.DatosCCC,
      ccclocation_company_workforce: '0',//data?.CCCLugar?.DatosPlantilla,
      ccclocation_company_codeCNAE: data?.CCCLugar?.DatosCnaeCodigo,

      // // accident
      accident_accidentDate: data?.Accidente?.FechaAccidente ? Moment(data?.Accidente?.FechaAccidente).format("L") : null,
      accident_leavingDate: data?.Accidente?.FechaBaja ? Moment(data?.Accidente?.FechaBaja).format("L") : null,
      accident_hour: data?.Accidente?.Hora,
      accident_hourWork: data?.Accidente?.HoraTrabajo,
      //accident_day_week: Moment(data?.Accidente?.FechaAccidente).format("dddd"),
      accident_description: data?.Accidente?.Descripcion,
      accident_habitual: `${data?.Accidente?.Habitual}`,
      accident_evaluation: `${data?.Accidente?.Evaluacion}`,
      accident_extension_site: data?.Accidente?.Ampliacion?.EntornoLugar,
      accident_extension_work: data?.Accidente?.Ampliacion?.TareaActividad,
      accident_extension_agentText:
        data?.Accidente?.Ampliacion?.TareaEspecificaDescripcion,
      code1_accident_extension_specify:
        data?.Accidente?.Ampliacion?.TareaEspecifica, //phisicalActivityDetailCode
      code1_accident_extension_desv:
        data?.Accidente?.Ampliacion?.DesencadenanteDesv, //
      code1_accident_extension_typeSite:
        data?.Accidente?.Ampliacion?.EntornoTipoLugar,
      code1_accident_extension_typeWork:
        data?.Accidente?.Ampliacion?.ProcesoTipoTrabajo,
      accident_extension_activity:
        data?.Accidente?.Ampliacion?.DesencadenanteHech,
      code1_accident_extension_form_contact_code:
        data?.Accidente?.Ampliacion?.FormaContactoCodigo,
      accident_extension_injuryForm: data?.Accidente?.Descripcion,
      accident_extension_how_injury: data?.Accidente?.Ampliacion?.ModoTipoModo,
      code1_material_Agent_As_Injured: data?.Accidente?.Ampliacion?.ModoAgente?.slice(
        0,
        2
      ),
      code2_material_Agent_As_Injured: data?.Accidente?.Ampliacion?.ModoAgente?.slice(
        2,
        4
      ),
      code3_material_Agent_As_Injured: data?.Accidente?.Ampliacion?.ModoAgente?.slice(
        4,
        8
      ),
      accident_extension_form_text_agent:
        data?.Accidente?.Ampliacion?.ModoTextoAgente,
      accident_extension_witnesses: `${data?.Accidente?.Ampliacion?.EsTestigos}`,
      accident_extension_multiples: `${data?.Accidente?.Ampliacion?.EsMultiples}`,
      code1_physical_activity: data?.Accidente?.Ampliacion?.TareaAgente?.slice(
        0,
        2
      ),
      code2_physical_activity: data?.Accidente?.Ampliacion?.TareaAgente?.slice(
        2,
        4
      ),
      code3_physical_activity: data?.Accident?.Ampliacion?.TareaAgente?.slice(
        4,
        8
      ),
      code1_trigger_unusual_fact:
        data?.Accidente?.Ampliacion?.DesencadenanteAgen?.slice(0, 2),
      code2_trigger_unusual_fact:
        data?.Accidente?.Ampliacion?.DesencadenanteAgen?.slice(2, 4),
      code3_trigger_unusual_fact:
        data?.Accident?.Ampliacion?.DesencadenanteAgen?.slice(4, 8),

      // // assistance
      code1_assistance_injury: data?.Asistenciales?.TipoLesionCodigo,
      code1_assistance_part: data?.Asistenciales?.ParteCuerpoLesionadaCodigo,
      assistance_injuryDescription: data?.Asistenciales?.TipoLesionCodigo,
      // assistance_part: data?.Asistenciales?.ParteCuerpoLesionadaCodigo,
      assistance_partDescription:
        data?.Asistenciales?.ParteCuerpoLesionadaCodigo,
      assistance_degree: data?.Asistenciales?.GradoLesionCodigo, // mirar valores
      assistance_nameDoctor: data?.Asistenciales?.MedicoNombre,
      assistance_phoneDoctor: data?.Asistenciales?.MedicoTelefono,
      assistance_addressDoctor: data?.Asistenciales?.MedicoDomicilio,
      // assistance_codeHospital: data?.Asistenciales?.code_hospital, // ?
      assistance_assistenceType: data?.Asistenciales?.TipoAsistenciaCodigo
        ? data?.Asistenciales?.TipoAsistenciaCodigo
        : null,
      assistance_codeHospital: data?.Asistenciales?.HospitalNombre,
      riskAssessment: `${data?.Asistenciales?.EsHospitalCodigo}`, // ?
      // // economic / economics
      economic_previousMonth: formatedData.MensualMesAnterior, // En el mes anterior
      economic_days: formatedData.MensualDias, // Días cotizados
      economic_base: formatedData.MensualBase, // Base reguladora A (Base cotización mensual)
      // BASE COTIZACIÓN AÑO
      economic_b1: formatedData.AnualB1 || 0, // B.1.- por horas extras
      economic_b2: formatedData.AnualB2 || 0, // B.2.- por otros conceptos
      economic_totalAnnual: formatedData.AnualTotal || 0, // Total B1 + B2
      economic_average: formatedData.AnualPromedio || 0, // Promedio diario base
      // SUBSIDIO
      economic_averageA: formatedData.SubsidioPromedioA, // Base reguladora A (subsidio)
      economic_averageB: formatedData.SubsidioPromedioB || 0, // Base reguladora B
      economic_totalBenefit: formatedData.SubsidioTotal, // Total B.R. diaria
      economic_compensation: formatedData.SubsidioIndemnizacion, // Cuantía del subsidio 75%
      // // actors / partners
      actors_completeName: data?.Actores?.FEmpresaNombreApellidos,
      actors_serveAs: data?.Actores?.FEmpresaCalidadDe, // Representante
      actors_province: data?.Actores?.FEmpresaProvincia, // Ciudad
      actors_submissionDate: data?.Actores?.FEmpresaFechaPresenta ? Moment(data?.Actores?.FEmpresaFechaPresenta).format("L") : null, // ??
      actors_codeAlp: data?.Actores?.ALProvinciaCodigo, // MAESTRO Núm. Mutua
      actors_numExpedientEgc: data?.Actores?.EGCNumeroExpediente, // Núm. Expediente Entidad Gestora o Colaboradora
      actors_numExpedientAlp: data?.Actores?.ALPNumeroExpediente,
      actors_acceptationDate: data?.Actores?.EGCFechaAceptacion,
      //Fecha aceptación
      actors_code: data?.Actores?.EGCCodigoMutua, // Núm. Expediente Autoridad Laboral
      actors_receptionDate: data?.Actores?.ALPFechaRecepcion ? Moment(data?.Actores?.ALPFechaRecepcion).format("L") : null, //Fecha Recepción
      actors_reasonRejection: data?.Actores?.MotivoRechazo, //"Motivo del rechazo"
    });
  }, [completePatByUser, reset]);
  const formatEconomic = (data: any) => {
    let formatedData: any = {};
    for (const property in data) {
      let value = data[property];
      if (value !== null) {
        value = value.replace(/,/g, ".");
      }
      formatedData[property] = value;
    }
    return formatedData;
  };
  const handleTestValues = async () => {
    const values = getValues();

    const response = await handleUpdateDelta(values);
    return response;
  };
  const values2 = getValues()
  const handleUpdateDelta = async (values: any) => {
    const updateObjectValues = generateUpdateObjectsDeltaPAT(
      values,
      completePatByUser
    );
    const responseUpdate = await updateDelta(updateObjectValues);
    return responseUpdate;
  };

  const submitForm = (values: any) => {
    handleVerify();
  };
  const handleVerify = async () => {
    let responseUpdate = await handleTestValues();

    const deltaId = completePatByUser?.Id;
    setLoading(true);
    if (responseUpdate) {
     
      const response = await validatePat(deltaId);
      if (response) {
        setLoading(false);
       updatedDataDelta(true)
        setValidationPropertyResult(response?.PropertyResult);
        handleSendValidationPropertyResult(response);
       
      }
    } else {
      setLoading(false);
      handleSendValidationPropertyResult({ errorInServer: 'No hemos podido validar el delta, por favor intentelo mas tarde' })
    }
  };
  const toggleComponentVisibility = (show: boolean) => {
    setShowComponent(show);
  };
  const filterCommunity = false;
  const cancelShipment = () => {
    history.push('/unidad-delta/Accidentes-con-baja-pendientes-de-delta');

  }
  return (
    <>
      {showComponent && <Loader activeLoader={true} label="Descargando formulario" />}

      {completePatByUser ? (
        <div className="tabs-content" id="formDelta" ref={formRef}>
          {showComponent && (
            <>
              <div
                className="header-content"
                style={{
                  backgroundColor: "#409CCE",
                  display: "flex",
                  alignItems: "center",
                  height: "100px",
                  padding: "20px",
                  marginBottom: "20px",
                }}
              >
                <div className="logo-block">
                  <div className="logo-container">
                    <img src={logo} className="logo-landingpage" alt="solimat" />
                  </div>
                </div>
              </div>
            </>
          )}
          <form onSubmit={handleSubmit(submitForm)} onKeyDown={(e) => { e.key === "Enter" && e.preventDefault(); }} >
            <div
              className={`${tabSelected === 0 || showComponent ? "" : "d-none"
                }`}
            >
              <Worker
                data={completePatByUser?.Trabajador}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                codeCNO={codeCNO}
                disabled={disabled}
                filterByProvince={filterCommunity}
                valuesForm={valuesForm}
              />
            </div>
            <div
              className={`${tabSelected === 1 || showComponent ? "" : "d-none"
                }`}
            >
              <Company
                dataWorker={{
                  worker: completePatByUser?.Trabajador,
                }}
                data={completePatByUser?.Empresa}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
                filterByProvince={filterCommunity}
              />
            </div>
            <div
              className={`${tabSelected === 2 || showComponent ? "" : "d-none"
                }`}
            >
              {/* STYLE ERRORS */}
              <AccidentLocation
                data={completePatByUser?.CCCLugar}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
                filterByProvince={filterCommunity}
              />
            </div>
            <div
              className={`${tabSelected === 3 || showComponent ? "" : "d-none"
                }`}
            >
              <Accident
                data={completePatByUser?.Accidente}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
                valuesForm={values2}
              />
            </div>
            <div
              className={`${tabSelected === 4 || showComponent ? "" : "d-none"
                }`}
            >
              <Assistance
                data={completePatByUser?.Asistenciales}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
              />
            </div>
            <div
              className={`${tabSelected === 5 || showComponent ? "" : "d-none"
                }`}
            >
              <Economics
                data={completePatByUser?.Economicos}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
              />
            </div>
            <div
              className={`${tabSelected === 6 || showComponent ? "" : "d-none"
                }`}
            >
              <Partners
                data={completePatByUser?.Actores}
                reset={reset}
                watch={watch}
                errors={errors}
                register={register}
                setValue={setValue}
                disabled={disabled}
              />
            </div>

            <div className="container">
              <div className="row mt-4">
                <div className="col-12">
                  {!showComponent ?
                    <Buttons
                      handleReset={reset}
                      handleCancel={cancelShipment}
                      submitForm={submitForm}
                      DomFormId="formDelta"
                      loading={loading}
                      tabSelected={tabSelected}
                      formRef={formRef}
                      toggleComponentVisibility={toggleComponentVisibility}
                      disabled={disabled}
                      isExtinct={isExtinct}
                    />
                    : ''}
                </div>
              </div>
            </div>
          </form>
          {/* <div className={`${tabSelected===7?'':'d-none'}`}>
            <ELaboral
              pat={completePatByUser}
            />
          </div> */}
        </div>
      ) : null}

    </>
  );
};

export default TabsContentDeltaWithLeaveDetail;
