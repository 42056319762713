import React, { useState, useEffect, useCallback } from "react";
import PageMenu from "components/blocks/PageMenu/PageMenu";
import useDecode from "../../../hooks/useDecode";
import useGetRole from "hooks/useGetRole";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { getValue } from '@syncfusion/ej2-base';
import useGetAllNoticesByUser from '../../../services/notices/useGetAllNoticesByUser';
import useUpdateNotice from "services/notices/useUpdateNotices";
import { useGeneralManagementState } from "../GeneralManagement/generalManagementState";
import Loader from "components/blocks/Loader/Loader";
import FilesNoticesGrid from "./FilesNoticesGrid";
const NoticesGrid = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [filesNotice, setFilesNotice] = useState([])
  const [viewedActiveTab, setViewedActiveTab] = useState(false);
  const [allViewedNotices, setAllViewedNotices] = useState([]);
  const [allNotViewedNotices, setAllNotViewedNotices] = useState([]);
  const { getAllUserNotices, allUserNotices, loadingGetAllUserNotices } = useGetAllNoticesByUser();
  const { updateNotice } = useUpdateNotice()
  const [idNoticeSelected, setIdNoticeSelected] = useState('')
  const tokenResponse = useDecode("token");
  const [noticeUpdated, setNoticeUpdated] = useState(0)
  const [stateNotice, setStateNotice] = useState(true)
  const idUser = tokenResponse?.extension_id;
  const {isImpersonation}= useGetRole

  const { state, dispatch } = useGeneralManagementState();
  const [pageOptions] = useState({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState({
    columns: [{ field: "fechaHora", direction: "Descending" }],
  });
  useEffect(() => {

    getAllUserNotices(idUser);
  }, []);

  useEffect(() => {
    if (allUserNotices.length > 0) {
      const viewedNotices = allUserNotices.filter((item) => item.leido);
      const notViewedNotices = allUserNotices.filter((item) => !item.leido);
      setAllViewedNotices(viewedNotices);
      setAllNotViewedNotices(notViewedNotices);

      if (notViewedNotices.length < 1 && viewedNotices.length >= 1) {
        setViewedActiveTab(true);
      }
    }

  }, [allUserNotices]);
  const handleSelectTab = (viewed) => {
    setViewedActiveTab(viewed);
  };

  const rowSelected = (args) => {
    setIdNoticeSelected(args.data.id)
    setSelectedMessage(args.data.html);
    setFilesNotice(args.data.adjuntos)
    setStateNotice(args.data.leido)
    setShowModal(true);

  };

  const handleClose = () => {
    const impersonation = isImpersonation()
    if (impersonation) {
      setShowModal(false);
    }
    
    if (!stateNotice && !impersonation) {
      updateNotice(idUser, idNoticeSelected);
      setNoticeUpdated((prev) => prev + 1);
      setAllNotViewedNotices((prev) => prev.filter((item) => item.id !== idNoticeSelected));
      setAllViewedNotices((prev) => [
        ...prev,
        ...allNotViewedNotices.filter((item) => item.id === idNoticeSelected),
      ]);
      dispatch({
        type: "REFRESH_COUNT_NOTICE",
        payload: true,
      })

    }

    setTimeout(() => {
      dispatch({
        type: "REFRESH_COUNT_NOTICE",
        payload: false,
      })
      setShowModal(false);
    }, 500);
    
  };

  const rowDataBound = (args) => {
    if (args.row) {
      if (getValue('type_notification', args.data) !== 3) {
        args.row.classList.add('link__row');
      }
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === 'html' && args.data[args.column.field]) {
      args.cell.title = args.data[args.column.field];
    }
  };

  return (
    <>
      <PageMenu title="Avisos" />
      <div className="container d-flex justify-content-center">
        <div className="row">
          <div className="col-12">
            <div className="notifications-tabs">
              <div
                onClick={() => handleSelectTab(false)}
                className={`tab ${viewedActiveTab ? '' : 'active'}`}
              >
                <span className={`${allNotViewedNotices?.length >= 1 ? 'news' : ''}`}>NO LEIDOS</span>
              </div>
              <div
                onClick={() => handleSelectTab(true)}
                className={`tab ${viewedActiveTab ? 'active' : ''}`}
              >
                <span className="text">LEIDOS</span>
              </div>
            </div>
            {loadingGetAllUserNotices && <Loader activeLoader={true} label="Descargando Avisos" />}
            <div className="notifications__grid">
              <GridComponent
                dataSource={viewedActiveTab ? allViewedNotices : allNotViewedNotices}
                allowPaging={true}
                allowSorting={true}
                rowSelected={rowSelected}
                pageSettings={pageOptions}
                sortSettings={sortingOptions}
                rowDataBound={rowDataBound}
                queryCellInfo={queryCellInfo}

              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="titulo"
                    headerText="Asunto"
                    textAlign="Left"
                    width="auto"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="fechaHora"
                    headerText="Fecha"
                    type="date"
                    format="dd/MM/yyyy"
                    textAlign="Left"
                    width="200"
                  ></ColumnDirective>
                  {/* <ColumnDirective
                    headerText="Archivo Adjunto"
                    textAlign="Center"
                    width="200"
                    template={(props) =>
                      props.adjuntos.lenght > 0 ? (
                        <span className="file-icon"></span>
                      ) : (
                        <span></span>
                      )
                    }
                  /> */}
                </ColumnsDirective>
                <Inject services={[Page, Sort]} />
              </GridComponent>
            </div>
          </div>
        </div>

        {showModal && (
          <div className="modal fade show bd-example-modal-lg" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
              <div className="modal-content">
                <div className="modal-header header-modal-notice-detail">
                  <h1 className="modal-title title-modal-notice-detail" id="exampleModalLongTitle">Detalle del Aviso</h1>
                  <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div dangerouslySetInnerHTML={{ __html: selectedMessage }} />
                  <div className="container-files-notice">
                    <h4 className="title-files-notices">Archivos adjuntos</h4>
                    <FilesNoticesGrid documents = {filesNotice}></FilesNoticesGrid>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-close-notice-detail" onClick={handleClose}>Cerrar</button>                 
                </div>
              </div>
            </div>
          </div>
        )}

        {showModal && <div className="modal-backdrop fade show"></div>}
      </div>
    </>
  );
};

export default NoticesGrid;
