import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../components/pages/Authenticate/Login";
// Pages
import Home from "../components/pages/Home/Home";
import AppointmentDetail from "../components/pages/AppointmentDetail/AppointmentDetail";
import AppointmentDetailExt from "../components/pages/AppointmentDetail/AppointmentDetailExt";
import EpisodeDetail from "../components/pages/EpisodeDetail/EpisodeDetail";
import Appointments from "../components/pages/Appointments/Appointments";
import RoleSelection from "../components/pages/RoleSelection/RoleSelection";
import View404 from "../components/pages/View404/View404";
import Settings from "../components/pages/Settings/SettingsNew";
import ChangeSettings from "../components/pages/ChangeSettings/ChangeSettings";
import Communications from "../components/pages/Communications/Communications";
import CommunicationChat from "../components/pages/CommunicationChat/CommunicationChat";
import Record from "../components/pages/Record/Record";
import RecordDetail from "../components/pages/RecordDetail/RecordDetail";
import RecordDetailVisit from "../components/pages/RecordDetailVisit/RecordDetailVisit";
import RecordDetailManagement from "../components/pages/RecordDetailManagement/RecordDetailManagement";
import RecordDetailSheets from "../components/pages/RecordDetailSheets/RecordDetailSheets";
import Management from "../components/pages/Management/Management";
import ManagementForms from "../components/pages/Management/ManagementForms.container";
import Application from "../components/pages/Application/Application";
import Notifications from "../components/pages/Notifications/Notifications";
import PrivatePatientRoute from "./PrivatePatientRoute";
import PrivateCompanyRoute from "./PrivateCompanyRoute";
import HomeCompany from "../components/pages/HomeCompany/HomeCompany";
import Centers from "../components/pages/Centers/Centers";
import CookiesPolicy from "components/pages/CookiesPolicy/CookiesPolicy";
import Surveys from "components/pages/Surveys/Surveys";
import DownloadDocument from "components/pages/DownloadDocument/DownloadDocument";
import UseScrollToTop from "./useScrollToTop";
import Ayuda from 'components/pages/ayuda/Ayuda'
// FICHAS
import EntornoTrabajador from "../components/blocks/ManagementSheets/Administracion/EntornoTrabajador";
import TSDPago from "../components/blocks/ManagementSheets/Economico/TSDPago";
import ParteAltaBaja from "../components/blocks/ManagementSheets/Administracion/ParteAltaBaja";
import ParteConfirmacion from "../components/blocks/ManagementSheets/Administracion/ParteConfirmacion";
import ParteSinBaja from "../components/blocks/ManagementSheets/Administracion/ParteSinBaja";
import Recobro from "../components/blocks/ManagementSheets/Economico/Recobro";
import ReposicionOrtoprotesica from "../components/blocks/ManagementSheets/Economico/ReposicionOrtoprotesica";
import IMSResumen from "../components/blocks/ManagementSheets/Economico/ImsResumen";
import BaseReguladoraCata from "../components/blocks/ManagementSheets/Economico/BaseCotizacionCata";
import ResumenCATA from "../components/blocks/ManagementSheets/Administracion/ResumenCATA";
import ComisionPrestacionesEspeciales from "../components/blocks/ManagementSheets/Economico/ComisionPrestacionesEspeciales";
import GastosAsistencial from "../components/blocks/ManagementSheets/Economico/GastosAsistencial";
import { CookiesPolicyProvider } from "contexts/CookiesPolicyContext";
import SurveyDetail from "components/pages/SurveyDetail/SurveyDetail";
import CommunicationChatAppointment from "components/pages/CommunicationChat/CommunicationChatAppointment";
import PagoDirecto from "components/blocks/ManagementSheets/Economico/PagoDirecto";
import CommunicationChatGeneral from "components/pages/CommunicationChat/CommunicationChatGeneral";
import ManagementForm2 from "components/pages/Management/ManagementCards/ManagementForm2";
import CenterDetail from "components/pages/Centers/CenterDetail";
import Delta from "../components/pages/Delta/Delta";
import DeltaWithLeave from "components/pages/DeltaWithLeave/DeltaWithLeave";
import DeltaWithoutLeave from "components/pages/DeltaWithoutLeave/DeltaWithoutLeave";
import DeltaWithLeaveDetail from "components/pages/DeltaWithLeaveDetail/DeltaWithLeaveDetail";
import DeltaWithoutLeaveDetail from "components/pages/DeltaWithoutLeaveDetail/DeltaWithoutLeaveDetail";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "components/blocks/Loader/Loader";
import Internal from "components/pages/Application/Internal";
import AyudaGeneral from "components/pages/ayuda/AyudaGeneral";
import TerminosCondiciones from "components/pages/terminosCondiciones/TerminosCondiciones";
import Shortener from "components/pages/Shortener/Shortener";
import NoticesGrid from "components/pages/notices/NoticesGrid";
import FormRoyalDecree22021 from "components/pages/formRoyalDecree22021/FormRoyalDecree22021"
import ExcepcionesCita from "components/pages/excepcionesCita/ExcepcionesCita";
const LazyAuthorizations = lazy(() => import("../components/pages/Authorizations/Authorizations"));
const AppRouter = () => {
  return (
    <>
      <Router>

        <UseScrollToTop />
        <Switch>
          <PrivatePatientRoute exact path={"/ajustes"} component={Settings}></PrivatePatientRoute>

          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-nombre"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-primer-apellido"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-segundo-apellido"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-contraseña"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-telefono"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-direccion"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/cambiar-email"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/ajustes/editar-usuario"}
            component={ChangeSettings}
          ></PrivatePatientRoute>
          <PrivatePatientRoute exact path={"/red-de-centros"} component={Centers}></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/red-de-centros/centro/:id"}
            component={CenterDetail}
          ></PrivatePatientRoute>

          <PrivatePatientRoute
            exact
            path={"/politica-de-cookies"}
            component={(props: any) => (
              <CookiesPolicyProvider>
                <CookiesPolicy {...props} />
              </CookiesPolicyProvider>
            )}
          ></PrivatePatientRoute>
          {/* PATIENT ROUTES */}

          <PrivatePatientRoute exact component={Home} path="/" />
          <PrivatePatientRoute exact component={Home} path="/inicio" />
          <PrivatePatientRoute exact component={Ayuda} path="/ayuda" />
          <PrivatePatientRoute exact component={AyudaGeneral} path="/ayuda-general" />
          <PrivatePatientRoute
            component={RoleSelection}
            path="/role-selection"
            exact
          />
          <PrivatePatientRoute
            exact
            path={"/mis-citas"}
            component={Appointments}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-citas/:id"}
            component={AppointmentDetail}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-citas/externa/:id"}
            component={AppointmentDetailExt}
          />
          <PrivatePatientRoute
            exact
            path={"/comunicaciones"}
            component={Communications}
          />
          <PrivatePatientRoute
            exact
            path={"/avisos"}
            component={NoticesGrid}
          />
          <PrivatePatientRoute
            exact
            path={"/historial/:id/comunicacion"}
            component={CommunicationChat}
          />
           <PrivatePatientRoute
            exact
            path={"/historial/:id/:idform/formulario-real-decreto"}
            component={FormRoyalDecree22021}
          />
          <PrivatePatientRoute
            exact
            path={"/comunicaciones/:id/comunicacion-general"}
            component={CommunicationChatGeneral}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-citas/:id/comunicacion"}
            component={CommunicationChatAppointment}
          />
          <PrivatePatientRoute exact path={"/historial"} component={Record} />
          <PrivatePatientRoute
            exact
            path={"/historial/:id"}
            component={RecordDetail}
          />
          <PrivatePatientRoute
            exact
            path={"/historial/:id/informacion-asistencial"}
            component={RecordDetailVisit}
          />
          <PrivatePatientRoute
            exact
            path={
              "/historial/:id/informacion-asistencial/:episodeType/:episodeId"
            }
            component={EpisodeDetail}
          />
          <PrivatePatientRoute
            exact
            path={"/historial/:id/:managementType"}
            component={RecordDetailManagement}
          />
          <PrivatePatientRoute
            exact
            path={"/historial/:id/solicitud/:transactionId"}
            component={Application}
          />
          <PrivatePatientRoute
            exact
            path={"/historial/:id/:managementType/:detailId"}
            component={RecordDetailSheets}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-gestiones"}
            component={Management}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-gestiones/:gestion/form"} //de momento
            component={ManagementForm2}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-gestiones/gestion/:id"}
            component={ManagementForms}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-gestiones/solicitud/:transactionId"}
            component={Application}
          />
          <PrivatePatientRoute
            exact
            path={"/notificaciones"}
            component={Notifications}
          />
          <PrivatePatientRoute
            exact
            path={"/notificaciones/:recordId/:documentType/:documentId"}
            component={DownloadDocument}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-encuestas"}
            component={Surveys}
          />
          <PrivatePatientRoute
            exact
            path={"/mis-encuestas/:surveyId"}
            component={SurveyDetail}
          />

          {/* FICHAS */}
          <PrivatePatientRoute
            exact
            path={"/entorno-trabajador"}
            component={EntornoTrabajador}
          />
          <PrivatePatientRoute exact path={"/tsd-pago"} component={TSDPago} />
          <PrivatePatientRoute
            exact
            path={"/parte-alta-baja"}
            component={ParteAltaBaja}
          />
          <PrivatePatientRoute
            exact
            path={"/pago-directo"}
            component={PagoDirecto}
          />
          <PrivatePatientRoute
            exact
            path={"/parte-confirmacion"}
            component={ParteConfirmacion}
          />
          <PrivatePatientRoute
            exact
            path={"/parte-sin-baja"}
            component={ParteSinBaja}
          />
          <PrivatePatientRoute exact path={"/recobro"} component={Recobro} />
          <PrivatePatientRoute
            exact
            path={"/reposicion-ortoprotesica"}
            component={ReposicionOrtoprotesica}
          />
          <PrivatePatientRoute
            exact
            path={"/ims-resumen"}
            component={IMSResumen}
          />
          <PrivatePatientRoute
            exact
            path={"/base-reguladora-cata"}
            component={BaseReguladoraCata}
          />
          <PrivatePatientRoute
            exact
            path={"/resumen-cata"}
            component={ResumenCATA}
          />
          <PrivatePatientRoute
            exact
            path={"/comision-prestaciones-especiales"}
            component={ComisionPrestacionesEspeciales}
          />
          <PrivatePatientRoute
            exact
            path={"/gastos-Asistencial"}
            component={GastosAsistencial}
          />

          {/* COMPANY ROUTES */}
          <PrivateCompanyRoute
            exact
            path={"/inicio-profesional"}
            component={HomeCompany}
          />
          <PrivatePatientRoute exact path={"/unidad-delta"} component={Delta}></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/unidad-delta/accidentes-con-baja-pendientes-de-delta"}
            component={DeltaWithLeave}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={
              "/unidad-delta/accidentes-con-baja-pendientes-de-delta/:deltaId"
            }
            component={DeltaWithLeaveDetail}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={
              "/unidad-delta/accidentes-con-baja-delta-remesados/:deltaId"
            }
            component={DeltaWithLeaveDetail}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/unidad-delta/accidentes-sin-baja-pendientes-delta"}
            component={DeltaWithoutLeave}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/unidad-delta/accidentes-sin-baja-delta-remesados"}
            component={DeltaWithoutLeave}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={"/unidad-delta/accidentes-con-baja-delta-remesados"}
            component={DeltaWithLeave}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={
              "/unidad-delta/accidentes-sin-baja-pendientes-delta/:deltaId"
            }
            component={DeltaWithoutLeaveDetail}
          ></PrivatePatientRoute>
          <PrivatePatientRoute
            exact
            path={
              "/unidad-delta/accidentes-sin-baja-delta-remesados/:deltaId"
            }
            component={DeltaWithoutLeaveDetail}
          ></PrivatePatientRoute>

          {/* HOME REDIRECT */}
          {/* <HomeRedirectRoute exact path={"/"} component={HomeCompany} /> */}

          {/* Unknown routes: needs to stay here at the end of routes */}
          <Route
            exact
            path={"/autorizaciones/:idp/:id"}
          >

            <Suspense fallback={<Loader activeLoader>Loading Authorizations...</Loader>}>
              <LazyAuthorizations />
            </Suspense>
          </Route>

          <Route
            exact
            path={"/internal-app"} component={Internal}></Route>

          <Route
            exact
            path={"/terminos-condiciones"} component={TerminosCondiciones}></Route>
             <Route
            exact
            path={"/informacion-cita"} component={ExcepcionesCita}></Route>

          <Route
            exact
            path={"/bienvenida/:url"} component={Shortener}></Route>


          <PrivatePatientRoute component={View404}></PrivatePatientRoute>
        </Switch>

      </Router>
    </>
  );
};
export default AppRouter;
