import React, {useContext} from 'react';
import {TabsFormsContext} from '../../../contexts/TabsFormsContext';
import {guid} from '@solimat/solimat-web-endpoint/lib/forms-ingestion/dto';

interface IFormTransaction {
  description: string,
  form_id: guid,
  id: guid,
  name: string,
  transaction_id: guid
}

interface ITabsForms {
  transaction: IFormTransaction[];
  handleSaveForm: () => void;
}
const TabsForms:React.FC<ITabsForms> = ({transaction, handleSaveForm}) => {
  const {tabSelected, setTabSelected, setTabFormSelected} = useContext(TabsFormsContext);
  const handleSelectForm = (index:number, formId:guid) => {
    handleSaveForm();
    setTabSelected(index)
    setTabFormSelected(formId);
  }

  return (
    <>
      <ul className="forms-tabs">
        {transaction.map((item:any, index:number)=> {
          return(
            <li className="tab-item" key={index}>
              <button className={`tab-btn ${index===tabSelected?'active':''}`} onClick={()=>handleSelectForm(index, item.form_id)}>
                <span className="tab-btn_number">{index+1}</span>
                <span className="tab-btn_title">{item.name}</span>
              </button>
            </li>
          )
        })}
      </ul>
    </>
  )
}
export default TabsForms;