import React, {useState, useEffect} from 'react';
import useUtils from 'services/utils/useUtils'
interface IInputProps {

  errors: any
  register: any
  id: string
  title: string
  arraData:any[]
}
const TypeOfWork:React.FC<IInputProps> = ({arraData,errors, register,  id, title}) => {
  // const [data,setData] = useState<any[]|null>(null|| arraData);
  const [select1,setSelect1] = useState<any[]|null>(null);
  const [select2,setSelect2] = useState<any[]|null>(null);
  const [select3,setSelect3] = useState<any[]|null>(null);
  // const [select4,setSelect4] = useState<any[]|null>(null);
  const [select1value,setSelect1value] = useState<string|null>(null);
  const [select2value,setSelect2value] = useState<string|null>(null);
  // const [defaultSelect,setDefaultSelect] = useState<any|null>(null)
//14 12 0000


  
  useEffect(() => {
    if(!arraData) return;
    handleFilterSelect1(arraData);
   
  }, [arraData])
  const handleFilterSelect1 = (arraData:any[]) => {
   
    const filteredSelect1:any[] = [];
    arraData.forEach(element => {
      const existCode = filteredSelect1.filter((item:any)=>item.Codigo === element.Codigo);
      if(existCode.length < 1) {
        filteredSelect1.push(element)
      }
    });
    setSelect1(filteredSelect1);
    
  }
  const handleFilterSelect2 = (select1Value:string) => {
    setSelect1value(select1Value)
    setSelect3(null);
    if(arraData?.length) {
      const filteredSelect2 = arraData?.filter((item:any)=> (item.Codigo === select1Value) && item.Codigo3 === '0000');
      setSelect2(filteredSelect2);

    }
  }

  const handleFilterSelect3 = (select2Value:string) => {
    setSelect2value(select2Value)
    if(arraData?.length) {
      const filteredSelect3 = arraData?.filter((item:any)=> (item.Codigo1===select1value) && (item.Codigo2===select2Value) && (item.Codigo3!=='0000'));
      setSelect3(filteredSelect3);

    }
  }


  const handleChange = (e:any ,select:number) => {
    switch(select) {
      case 1:
        handleFilterSelect2(e.target.value)
        return;
      case 2:
        handleFilterSelect3(e.target.value)
        return;
  
      default:
        return;
    }
  }

  return (
    <fieldset className={`input-block ${errors&&errors[`code1_${id}`]?.message||errors&&errors[`code2_${id}`]?.message||errors&&errors[`code3_${id}`]?.message?'error':''}`}>
      <legend className="input-block--code__legend">{title}</legend>
      {select1 !== null && select1?.length>0?
        <select 
          name={`code1_${id}`} 
          id={`code1_${id}`} 
          {...register(`code1_${id}`)}
          onChange={(e)=>handleChange(e,1)}
        >
          <option value={''}>Elija Opción</option>
          {select1?.map((item:any)=> {
            return(
              <option key={item.Codigo* 3} value={item.Codigo}>{item.Codigo} - {item.Descripcion}</option>
            )
          })}
        </select>
        :null}
        {select2 !==null && select2?.length>0?
          <select 
            name={`code2_${id}`} 
            id={`code2_${id}`} 
          {...register(`code2_${id}`)}
            onChange={(e)=>handleChange(e,2)}
          >
            <option value={''}>Elija Opción</option>
            {select2?.map((item:any)=> {
              return(
                <option key={item.Codigo2 * 2} value={item.Codigo2}>{item.Codigo2} - {item.Descripcion2}</option>
              )
            })}
          </select>
      :null}
      {select3 !==null && select3?.length>0?
        <select 
          name={`code3_${id}`} 
          id={`code3_${id}`} 
          {...register(`code3_${id}`)}
          onChange={(e)=>handleChange(e,3)}
        >
          <option value={''}>Elija Opción</option>
          {select3?.map((item:any)=> {
            return(
              <option key={item.code3*4} value={item.Codigo3}>{item.Codigo3} - {item.Descripcion3}</option>
            )
          })}
        </select>
      :null}
      {errors&&      
        <p className="error m-0">
          {errors[`code1_${id}`]?.message}
          {errors[`code2_${id}`]?.message}
          {errors[`code3_${id}`]?.message}
        </p>
      }
    </fieldset>
  )
}

export default TypeOfWork;
