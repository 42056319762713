const useSortData = () => {

    const orderDescendingData = (data:any, _cb?:(orderedData?:any)=>void) => {
      let orderedDescendingData = [...data].sort((a:any, b:any) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
      if (typeof _cb === "function") {
        _cb(orderedDescendingData);
      }
    }

    const orderAscendingData = (data:any, _cb?:(orderedData?:any)=>void) => {
      let orderedAscendingData = [...data].sort((a:any, b:any) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());
      if (typeof _cb === "function") {
        _cb(orderedAscendingData);

      }
    }

    return {orderDescendingData, orderAscendingData};
}

export default useSortData;