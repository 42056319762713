import { useState } from "react";
import { notifications } from "@solimat/solimat-web-endpoint";


const useGetAllNotificationsByUser = () => {
  const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_NOTIFICACIONES_KEY || "";
  const apiNotifications = new notifications.NotificationsService(BASE_URL, FUNCTIONS_KEY);

  const [countNotices, setCountNotices] = useState(0);
  const [countNotifications, setCountNotifications] = useState(0);
  const [loadingGetCounts, setLoadingGetCounts] = useState(false);
  const [errorGetCounts, setErrorGetCounts] = useState(null);

  const getCounts = (userId: string) => {
    setLoadingGetCounts(true);
    setErrorGetCounts(null);
      apiNotifications
        .getCountNoticesAndNotifications(userId)
        .then((data: any) => {
          let dataCount = data.data;
         
          setCountNotifications(dataCount.notificaciones)
          setCountNotices(dataCount.avisos);
          setLoadingGetCounts(false);
        })
        .catch((err: any) => {
          console.error("getAllUserNotices: ", err);
          setErrorGetCounts(err);
          setLoadingGetCounts(false);
        });
  };

  return {
    getCounts,
    countNotices,
    countNotifications,
    loadingGetCounts,
    errorGetCounts
  };
};

export default useGetAllNotificationsByUser;