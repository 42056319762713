// getAllUserCommunicationByUserId

import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { ISendMessageUserToManager } from "@solimat/solimat-web-endpoint/lib/communication/dto"

const useSendMessageUserToManager = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [loadingSendMessageUserToManager, setLoadingSendMessageUserToManager] = useState(false);
  const [successSendMessageUserToManager, setSuccessSendMessageUserToManager] = useState(false);
  const [errorSendMessageUserToManager, setErrorSendMessageUserToManager] = useState(null);

  const sendMessageUserToManager = (data:any, _cb?:(id:string)=>void) => {
    setLoadingSendMessageUserToManager(true);
    setErrorSendMessageUserToManager(null);
    apiCommunication
      .sendMessageUserToManager(data)
      .then((data: any) => {
        setSuccessSendMessageUserToManager(true);
        setLoadingSendMessageUserToManager(false);
        if (typeof _cb === "function") {
          _cb(data);
        }
      })
      .catch((err:any) => {
        console.error("sendMessageUserToManager: ", err);
        setErrorSendMessageUserToManager(err);
        setLoadingSendMessageUserToManager(false);
      });
  };

  return {
    sendMessageUserToManager,
    successSendMessageUserToManager,
    loadingSendMessageUserToManager,
    errorSendMessageUserToManager
  };
};

export default useSendMessageUserToManager;