import React from 'react';

export interface MainLayoutProps {
  children: any
}

const RegisterLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="register-layout">
      {children}
    </div>
  );
}

export default RegisterLayout;