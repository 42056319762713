import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";


const useGetEpisodeEmergencies = () => {
const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
const [loadingGetEpisodeEmergencies, setLoadingGetEpisodeEmergencies] = useState(false);
const [successGetEpisodeEmergencies, setSuccessGetEpisodeEmergencies] = useState(false);
const [errorGetEpisodeEmergencies, setErrorGetEpisodeEmergencies] = useState(null);
const [episodeEmergencies, setEpisodeEmergencies] = useState<null|any>(null);

const getEpisodeEmergencies = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisodeEmergencies(true);
    setSuccessGetEpisodeEmergencies(false);
    apiRecord.getEpisodeEmergencies(userId,prestacionId)
      .then((res:any) => {       
         
        setLoadingGetEpisodeEmergencies(false);
        setSuccessGetEpisodeEmergencies(true);
        setEpisodeEmergencies( res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisodeEmergencies(false);
        setErrorGetEpisodeEmergencies(err)
      })

    }

      return {
        episodeEmergencies,
        getEpisodeEmergencies,
        loadingGetEpisodeEmergencies,
        successGetEpisodeEmergencies,
        errorGetEpisodeEmergencies
        };

    
  }
  export default useGetEpisodeEmergencies;