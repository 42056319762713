import React from 'react'
import logo from "../../../img/logoBlanco.png";
import PageMenu from '../../blocks/PageMenu/PageMenu';
import './excepcionesCita.scss'
const ExcepcionesCita = () => {
    const centers = [
        {
            nombre: 'Hospital Laboral San José Toledo',
            telefono: '925 215267'
        },
        {
            nombre: 'Centro Asistencial Solimat Getafe',
            telefono: '916653680'
        },
        {
            nombre: 'Centro Asistencial Solimat Talavera de la Reina',
            telefono: '925721150'
        },
        {
            nombre: 'Centro Asistencial Solimat Illescas',
            telefono: '925542127'
        },
        {
            nombre: 'Centro Asistencial Solimat Albacete',
            telefono: ' 967511417'
        },
        {
            nombre: 'Centro Asistencial Solimat Ciudad Real',
            telefono: '926271131'
        },
        {
            nombre: 'Centro Asistencial Solimat Cuenca',
            telefono: '96923600'

        },
        {
            nombre: 'Centro Asistencial Solimat Ávila',
            telefono: '920211307'
        },
    ]
    return (
        <div className='container-'>

            <header className={`header-block`}>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="header-content">
                                <div className="logo-block">
                                    <div className="logo-container">
                                        <img src={logo} className="logo-landingpage" alt="solimat" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <PageMenu
                title={'Información importante relacionada con su cita médica'}
            >
            </PageMenu>
            <div className="container">

                <div className="container-text-excepcion-cita">
                    <p className='excepcion-text'>Deberá acudir a consulta médica con <b>toda la información médica de que disponga.</b></p>
                    <p className='excepcion-text'>De <b>no poder asistir a dicha cita</b>, deberá aportar <b>justificación por escrito</b> que acredite el motivo que se lo impide, ya que en caso contrario podría <b>suspenderse</b> y posteriormente <b>extinguirse el derecho al subsidio</b> de incapacidad temporal con la misma fecha de la inasistencia, de conformidad con lo previsto en el artículo 175 del Texto Refundido de la Ley General de la Seguridad Social. (RDL 8/2015).</p>
                    <p className='excepcion-text'>Excepcionalmente: </p>
                    <ul className='list-excepcion'>
                        <li className='excepcion-text list-excepcion'>Si se encuentra embarazada, deberá comunicar tal circunstancia llamando previamente a la fecha de citación, poniéndose en contacto telefónico con el médico responsable, a fin de que se valore la necesidad de acudir a la cita programada. Consulte el teléfono de su centro asistencial asignado más abajo.</li>
                        <li className='excepcion-text list-excepcion'>Si ha sido sometido/a a un procedimiento quirúrgico en las últimas dos semanas, se ruega comunicarlo en los mismos términos indicados en el punto anterior.</li>
                        <li className='excepcion-text list-excepcion'>Si la fecha de la cita coincide con alguna consulta o procedimiento médico que ya tuviera concertado, comuníquelo para modificar la consulta asignada en esta Mutua en los mismos términos indicados en puntos anteriores.</li>
                        <li className='excepcion-text list-excepcion'>Si se encontrase de alta médica, no será necesario acudir a la cita programada. Rogando comunique esta cuestión en los mismos términos indicados anteriormente.</li>
                    </ul>
                    <div className="container-centers-information-appoinment">
                        <p className='excepcion-text-centers-title'>Información de Centros Asistenciales</p>

                        <div className="card-columns">
                            {centers &&
                                centers.map((center, i) => {
                                    return (
                                        <div
                                            className="card-exception-appoinmet"
                                        >
                                            <div className="card-header-exception-appoinmet">
                                                {" "}
                                                {center.nombre}
                                            </div>

                                            <div className="card-footer-exception-appoinmet">
                                                <small className="text-muted-exception-appoinmet">
                                                    Teléfono: {center.telefono ? center.telefono : "No disponible"}
                                                </small>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExcepcionesCita