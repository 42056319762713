import React from "react";
import {Link} from 'react-router-dom';
import './CardLinkIcon.scss';

interface ICardLinkIcon {
  iconType: string;
  title: string;
  pathTo: string;
  bgColorStyle: string;
}

const CardLinkIcon: React.FC<ICardLinkIcon> = ({
  iconType,
  title,
  pathTo,
  bgColorStyle
}) => {
 
  return (
    <>
      <Link className={`record-detail-card_block`} to={pathTo}>
        <div className={`icon-record-type-container ${iconType} bg-color--${bgColorStyle}`}>
          <div className={`icon-record-type`}></div>
        </div>
        <div className={pathTo.includes('unidad-delta') ? 'info-delta-container':'info-record-container'}>
          <h5 className="title">{title}</h5>
        </div>
      </Link>
    </>
  );
};

export default CardLinkIcon;
