import React, { useEffect } from "react";
import Moment from "moment";
import EntornoLaboral from "../Comun/EntornoLaboral";
import useDecode from "hooks/useDecode";
import "../ManagementSheets.scss";
import { recordSheets } from "@solimat/solimat-web-endpoint";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import LoaderMin from "components/blocks/Loader/LoaderMin";

function GastosTransporte({ props }: any) {
  const gastoTransporteId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getTransportationExpense,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(() => {
    getTransportationExpense(userId, prestacionId, gastoTransporteId);
    Moment().locale("es");
  }, []);
  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }

  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Gastos Transporte"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Tipo"}</label>
                  <input
                    type="text"
                    id="Codigo"
                    className="item-value"
                    value={dataEconomicSheet.tiposTransporte}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Cantidad"}</label>
                  <input
                    type="text"
                    id="cantidad"
                    className="item-value"
                    value={dataEconomicSheet.cantidad}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Inicio"}</label>
                  <input
                    type="text"
                    id="fechaInicio"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaInicio !== null
                        ? Moment(dataEconomicSheet.fechaInicio).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Fin"}</label>
                  <input
                    type="text"
                    id="fechaFin"
                    className="item-value"
                    value={
                      dataEconomicSheet.fechaFin !== null
                        ? Moment(dataEconomicSheet.fechaFin).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe"}</label>
                  <input
                    type="text"
                    id="importe"
                    className="item-value"
                    value={`${dataEconomicSheet.importe}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Médico"}</label>
                  <input
                    type="text"
                    id="medico"
                    className="item-value"
                    value={dataEconomicSheet.medico}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Pagado"}</label>
                  <input
                    type="text"
                    id="estaPagado"
                    className="item-value"
                    value={dataEconomicSheet.estaPagado === true ? 'Si': 'No'}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Excluido"}</label>
                  <input
                    type="text"
                    id="estaExcluido"
                    className="item-value"
                    value={dataEconomicSheet.estaExcluido === true ? 'Si': 'No'}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
      {dataEconomicSheet?.entornoLaboral ? 
      <EntornoLaboral
      id={"EntornoLaboral"}
      nombre={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.Nombre : ""}
      CCC={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CCC : ""}
      CIF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.CIF : ""}
      EntornoLaboralEmpresaId={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
      EsCuentaPropia={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EsCuentaPropia : ""}
      NAF={dataEconomicSheet?.entornoLaboral ? dataEconomicSheet.entornoLaboral.NAF : ""}
    />
    
   : '' }
          
        </section>
      )}
    </>
  );
}

export default GastosTransporte;
