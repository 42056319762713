import React, { useState } from "react";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import Loader from "../../blocks/Loader/Loader";
const Modelo145 = ({gestionName,props,url}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <>
    <PageMenu
    title={gestionName}
    >
        <div className="btns-header-container">
                <button onClick={()=> props.history.goBack()}><img src={IconArrowGray} alt="icon arrow right"/></button>
              </div>
    </PageMenu>
    <div className="container">
      <div>
        {/* <p className="text-direct-pay m-3">
          Este servicio permite realizar, por registro electrónico, las
          tramitaciones necesarias en relación con la prestación de incapacidad
          temporal en pago directo gestionadas por Solimat.
        </p>
        <p className="text-direct-pay m-3">
          <b>¿A quién va dirigido? </b>
        </p>
        <p className="text-direct-pay m-3">
          A trabajadores en situación de incapacidad temporal.
        </p>
        <p className="text-direct-pay m-3">
          <b>¿Qué permite realizar? </b>
        </p>
        <p className="text-direct-pay m-3">
          {" "}
          En caso de trabajadores por cuenta ajena: Presentar solicitud para el
          pago directo por incapacidad temporal cuando se produzca la extinción
          de la relación laboral en los casos indicados en la solicitud. En caso
          de trabajadores autónomos: Presentar solicitud para el pago directo
          por incapacidad temporal. Presentar la declaración de situación de
          actividad para trabajadores por cuenta propia o autónomos.
        </p>{" "}
        <p className="text-direct-pay m-3">
          <b>¿Qué necesita para realizarlo? </b>{" "}
        </p>
        <p className="text-direct-pay m-3">
          {" "}
          Cumplimentar el formulario de solicitud de la prestación económica.
          Anexar copia de los documentos que se detallan en las instrucciones de
          la solicitud.
        </p> */}
      </div>
      <div className="view-form">
      {iframeLoaded ? null : <Loader activeLoader={true} label="...Cargando Formulario"></Loader>}
      </div>
      <iframe
        src={`${url}`}
        title={gestionName}
        className={`pago-directo-iframe visible}`}
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
    </>
  )
}

export default Modelo145