import Utils from "@solimat/solimat-web-endpoint/lib/utils"
import { ICnaeResponse } from "@solimat/solimat-web-endpoint/lib/utils/dto";

const useGetAllCnaeEmployee= () => {

  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";

  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const getAllCnaeEmployee = async() => {
    try {
      const res = await apiUtils.getAllCnaeEmployee();
      return res.data;
    } catch (err) {
      console.error("getAllCnae", err);
    }

  }

  return {
    getAllCnaeEmployee
  }

}

export default useGetAllCnaeEmployee;