import React from 'react';
import './loader.scss';
// import { useMsal } from "@azure/msal-react";

interface ILoader {
  activeLoader?:boolean
  label?: string,
  children?:any
}

const Loader : React.FC<ILoader> = ({activeLoader, label,children}) => {
  // const { instance } = useMsal();

    return(
      <>
      <div className={`box-loader  ${activeLoader?"active":""}` } >
        <div className="loader-spinner" data-testid='divLoader'></div>
        <span className='label-loader'>{label}</span>
      </div>
      {children}
      </>
    )
}

export default Loader;