import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';

const useGetAllAdministrationRecord = () => {
  const [loadingGetAllAdministrationRecord, setLoadingGetAllAdministrationRecord] = useState(false);
  const [successGetAllAdministrationRecord, setSuccessGetAllAdministrationRecord] = useState(false);
  const [errorGetAllAdministrationRecord, setErrorGetAllAdministrationRecord] = useState(null);
  const [dataAdministrationRecord, setDataAdministrationRecord] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

  const getAllAdministrationRecord = (userId: string, prestacionId: string,_cb?:(data:any)=>void) => {
    setLoadingGetAllAdministrationRecord(true);
    setSuccessGetAllAdministrationRecord(false);
    apiRecordSheets.getAllAdministrationRecord(userId,prestacionId)
      .then((res:any) => {
        setLoadingGetAllAdministrationRecord(false);
        setSuccessGetAllAdministrationRecord(true);
        setDataAdministrationRecord(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetAllAdministrationRecord(false);
        setErrorGetAllAdministrationRecord(err)
      })
  }

  return {
    getAllAdministrationRecord,
    loadingGetAllAdministrationRecord,
    successGetAllAdministrationRecord,
    errorGetAllAdministrationRecord,
    dataAdministrationRecord
    };
}

export default useGetAllAdministrationRecord;