import React, { useEffect } from "react";
import useDecode from "hooks/useDecode";
import Moment from "moment";
import EntornoLaboral from "../Comun/EntornoLaboral";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import "../ManagementSheets.scss";
import LoaderMin from "components/blocks/Loader/LoaderMin";

const PagoDirecto = ({ props }: any) => {
  const pagoDirectoId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getDirectPayment,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(() => {
    getDirectPayment(userId, prestacionId, pagoDirectoId);
  }, []);
  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin />
      </section>
    );
  }
  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }

  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Base Cálculo"}</h3>
              </div>

              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"F. Inicio Pago Directo"}
                  </label>
                  <input
                    type="text"
                    id="FechaInicioPago"
                    className="item-value"
                    value={
                      dataEconomicSheet.FechaInicioPago !== null
                        ? Moment(dataEconomicSheet.FechaInicioPago).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Motivo Pago Directo"}</label>
                  <input
                    type="text"
                    id="MotivoPagoDirectoDescripcion"
                    className="item-value"
                    value={`${dataEconomicSheet.MotivoPagoDirectoDescripcion}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Periodo Inicial"}</label>
                  <input
                    type="text"
                    id="FechaPeriodoInicial"
                    className="item-value"
                    value={
                      dataEconomicSheet.FechaPeriodoInicial !== null
                        ? Moment(dataEconomicSheet.FechaPeriodoInicial).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Periodo Final"}</label>
                  <input
                    type="text"
                    id="FechaPeriodoFinal"
                    className="item-value"
                    value={
                      dataEconomicSheet.FechaPeriodoFinal !== null
                        ? Moment(dataEconomicSheet.FechaPeriodoFinal).format(
                            "L"
                          )
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Regimen"}</label>
                  <input
                    type="text"
                    id="RegimenDescripcion"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.RegimenDescripcion
                        ? dataEconomicSheet.RegimenDescripcion
                        : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Dias Devengados"}</label>
                  <input
                    type="text"
                    id="DiasDevengados"
                    className="item-value"
                    value={`${dataEconomicSheet.DiasDevengados}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Base Reg. Diaria"}</label>
                  <input
                    type="text"
                    id="BaseReguladoraDiaria"
                    className="item-value"
                    value={dataEconomicSheet.BaseReguladoraDiaria}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"IPREM Diario"}</label>
                  <input
                    type="text"
                    id="IPREM_Diario"
                    className="item-value"
                    value={`${dataEconomicSheet.IPREM_Diario}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"N. Hijos Menores 26 "}</label>
                  <input
                    type="text"
                    id="TotalHijosMenores26"
                    className="item-value"
                    value={dataEconomicSheet.TotalHijosMenores26}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Porcentaje Reducción Jornada"}
                  </label>
                  <input
                    type="text"
                    id="PorcentajeReduccionJornada"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.PorcentajeReduccionJornada
                        ? dataEconomicSheet.PorcentajeReduccionJornada
                        : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Porcentaje Parcialidad"}
                  </label>
                  <input
                    type="text"
                    id="PorcentajeParcialidad"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.PorcentajeParcialidad
                        ? dataEconomicSheet.PorcentajeParcialidad
                        : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Cuota Obrera (FAN)"}</label>
                  <input
                    type="text"
                    id="CuotaObrera"
                    className="item-value"
                    value={`${dataEconomicSheet.CuotaObrera}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe Cuota Obrera"}</label>
                  <input
                    type="text"
                    id="ImporteCuotaObrera"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.ImporteCuotaObrera !== null
                        ? dataEconomicSheet.ImporteCuotaObrera
                        : 0
                    }€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe Retenciones"}</label>
                  <input
                    type="text"
                    id="ImporteRetenciones"
                    className="item-value"
                    value={`${dataEconomicSheet.ImporteRetenciones}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              {/* 
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Base Reguladora Mensual"}
                  </label>
                  <input
                    type="text"
                    id="BaseReguladoraMensual"
                    className="item-value"
                    value={dataEconomicSheet.BaseReguladoraMensual}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Clave Pago Automatico"}
                  </label>
                  <input
                    type="text"
                    id="ClavePagoAutomatico"
                    className="item-value"
                    value={`${dataEconomicSheet.ClavePagoAutomatico
                        ? dataEconomicSheet.ClavePagoAutomatico
                        : ""
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Confirmación Pago Navision"}
                  </label>
                  <input
                    type="text"
                    id="ConfirmacionPagoNavision"
                    className="item-value"
                    value={`${dataEconomicSheet.ConfirmacionPagoNavision === true
                        ? "Si"
                        : "No"
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Contingencia Descripción"}
                  </label>
                  <input
                    type="text"
                    id="ContingenciaDescripcion"
                    className="item-value"
                    value={`${dataEconomicSheet.ContingenciaDescripcion
                        ? dataEconomicSheet.ContingenciaDescripcion
                        : ""
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Contingencia Pago Directo"}
                  </label>
                  <input
                    type="text"
                    id="ContingenciaPagoDirecto"
                    className="item-value"
                    value={`${dataEconomicSheet.ContingenciaPagoDirecto}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Es Pago Afectado Por Regularizacion"}
                  </label>
                  <input
                    type="text"
                    id="EsPagoAfectadoPorRegularizacion"
                    className="item-value"
                    value={`${dataEconomicSheet.EsPagoAfectadoPorRegularizacion === true
                        ? "Si"
                        : "No"
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Es Transferido Tesoreria"}
                  </label>
                  <input
                    type="text"
                    id="EsTransferidoTesoreria"
                    className="item-value"
                    value={`${dataEconomicSheet.EsTransferidoTesoreria === true
                        ? "Si"
                        : "No"
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Id Expediente"}</label>
                  <input
                    type="text"
                    id="ExpedienteId"
                    className="item-value"
                    value={`${dataEconomicSheet.ExpedienteId}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Alta Fin"}</label>
                  <input
                    type="text"
                    id="FechaAltaFin"
                    className="item-value"
                    value={
                      dataEconomicSheet.FechaAltaFin !== null
                        ? Moment(dataEconomicSheet.FechaAltaFin).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Fecha Baja"}</label>
                  <input
                    type="text"
                    id="FechaBaja"
                    className="item-value"
                    value={
                      dataEconomicSheet.FechaBaja !== null
                        ? Moment(dataEconomicSheet.FechaBaja).format("L")
                        : ""
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Importe a Regularizar"}
                  </label>
                  <input
                    type="text"
                    id="ImporteARegularizar"
                    className="item-value"
                    value={`${dataEconomicSheet.ImporteARegularizar}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Id PagoDirecto"}</label>
                  <input
                    type="text"
                    id="PagoDirectoId"
                    className="item-value"
                    value={`${dataEconomicSheet.PagoDirectoId}`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Porcentaje Horas Trabajadas (%)"}
                  </label>
                  <input
                    type="text"
                    id="PorcentajeHorasTrabajadas"
                    className="item-value"
                    value={`${dataEconomicSheet.PorcentajeHorasTrabajadas
                        ? dataEconomicSheet.PorcentajeHorasTrabajadas
                        : ""
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">
                    {"Tipo Ajuste Regularizacion"}
                  </label>
                  <input
                    type="text"
                    id="TipoAjusteRegularizacion"
                    className="item-value"
                    value={`${dataEconomicSheet.TipoAjusteRegularizacion
                        ? dataEconomicSheet.TipoAjusteRegularizacion
                        : ""
                      }`}
                    readOnly={true}
                  />
                </div>
              </div>
              */}
            </div>
          </div>
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Pago"}</h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Importe Por Dia"}</label>
                  <input
                    type="text"
                    id="ImporteDia"
                    className="item-value"
                    value={`${dataEconomicSheet.ImporteDia}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Bruto"}</label>
                  <input
                    type="text"
                    id="Bruto"
                    className="item-value"
                    value={`${dataEconomicSheet.Bruto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Neto"}</label>
                  <input
                    type="text"
                    id="Neto"
                    className="item-value"
                    value={`${dataEconomicSheet.Neto}€`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Retenciones"}</label>
                  <input
                    type="text"
                    id="Retenciones"
                    className="item-value"
                    value={dataEconomicSheet.Retenciones}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Porcentaje 1"}</label>
                  <input
                    type="text"
                    id="Porcentaje1"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.Porcentaje1
                        ? dataEconomicSheet.Porcentaje1
                        : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Porcentaje 2"}</label>
                  <input
                    type="text"
                    id="Porcentaje2"
                    className="item-value"
                    value={`${
                      dataEconomicSheet.Porcentaje2
                        ? dataEconomicSheet.Porcentaje2
                        : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="item">
                  <label className="item-label">{"Cuenta Bancaria"}</label>
                  <input
                    type="text"
                    id="CuentaBancaria"
                    className="item-value"
                    value={`${dataEconomicSheet.CuentaBancaria}`}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">{"Resumen"}</h3>
              </div>
              <div className="col-xl-12">
                <div className="item">
                  <textarea
                    id="resumen"
                    className="item-value"
                    value={
                      dataEconomicSheet?.ResumenAutomatico
                        ? dataEconomicSheet.ResumenAutomatico
                        : ""
                    }
                    readOnly={true}
                    cols={30}
                    rows={10}
                  />
                </div>
              </div>
            </div>
          </div>

          {dataEconomicSheet?.entornoLaboral ? (
            <EntornoLaboral
              id={"EntornoLaboral"}
              nombre={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.Nombre
                  : ""
              }
              CCC={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CCC
                  : ""
              }
              CIF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CIF
                  : ""
              }
              EntornoLaboralEmpresaId={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId
                  : ""
              }
              EsCuentaPropia={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EsCuentaPropia
                  : ""
              }
              NAF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.NAF
                  : ""
              }
            />
          ) : (
            ""
          )}
        </section>
      )}
    </>
  );
};

export default PagoDirecto;
