import React from 'react';

interface IInputProps {
  errors: any,
  register: any
  name: string
  id: string
}

const ProfessionalSituation:React.FC<IInputProps> = ({name, errors, register}) => {
  return (
    <fieldset className={`input-block input-block--fieldset ${errors&&errors[name]?.message?'error':''}`}>
      <legend className="input-block--fieldset__legend">Situación Profesional</legend>
      <div className="input-block--radio-check__group d-block">
        <label htmlFor="privateSalariedWorker" className="input-block--radio-check__label">
          <span className="text">Asalariado Sector Privado</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id={"privateSalariedWorker"} 
            {...register(name)} 
            value="1"
          />
        </label>
        <label htmlFor="publicSalariedWorker" className="input-block--radio-check__label">
          <span className="text">Asalariado Sector Público</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id="publicSalariedWorker"  {...register(name)} 
            value="2"
          />
        </label>
        <label htmlFor="freelanceWithSalaried" className="input-block--radio-check__label">
          <span className="text">Autónomo Con Asalariados</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id="freelanceWithSalaried"  {...register(name)} 
            value="3"
          />
        </label>
        <label htmlFor="freelanceWithoutSalaried" className="input-block--radio-check__label">
          <span className="text">Autónomo Sin Asalariados</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id="freelanceWithoutSalaried"  {...register(name)} 
            value="4"
          />
        </label>
        <label htmlFor="freelanceCooperativeMember" className="input-block--radio-check__label">
          <span className="text">Autónomo socio de cooperativa</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id="freelanceCooperativeMember"  {...register(name)} 
            value="5"
          />
        </label>
        <label htmlFor="freelancecollaborativeFamilyWorker" className="input-block--radio-check__label">
          <span className="text">Autónomo trabajador familiar (colaborador)</span>
          <input 
            type="radio" 
            name={`${name}`} 
            id="freelancecollaborativeFamilyWorker"  {...register(name)} 
            value="6"
          />
        </label>
      </div>
      {errors&&      
        <p className="error m-0">
          {errors[name]?.message}
        </p>
      }
    </fieldset>
  )
}

export default ProfessionalSituation;