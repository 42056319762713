import React, {useEffect} from 'react';
import Moment from 'moment';
import '../ManagementSheets.scss';
import useDecode from "hooks/useDecode";
import useGetEconomicSheets from 'services/recordSheets/useGetEconomicSheets';
import EntornoLaboral from '../Comun/EntornoLaboral';
import LoaderMin from 'components/blocks/Loader/LoaderMin';

type guid = string;
interface Management {
  name: string,
  date: Date | string,
  company: string
}
interface ISpecialBenefitsComission extends Management {
  id: guid | string,
  codigo: string,
  tipoSolicitudSocial: string,
  fechaPresentacion: Date | string,
  importeSolicitud: number,
  estadoSolicitud: string,
  cuantiaEconomicaNeto: number,
  fechaPago: Date | string,
  record_id: guid | string,
  retencion: number,   
  management_type: string
}
interface ISheet {
  props:any
}

const ComisionPrestacionesEspeciales:React.FC<ISheet> = ({props}:any) => {
  const comisionPrestacionEspecialId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {getSpecialBenefitsCommission, dataEconomicSheet, loadingEconomicSheet, errorEconomicSheet}= useGetEconomicSheets()

  useEffect(()=> {
    getSpecialBenefitsCommission(userId,prestacionId, comisionPrestacionEspecialId );
    Moment().locale("es");
  },[])
  
 
  if(loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  return (
    <>
      {dataEconomicSheet===null?
      <section className="container">
        <p className="loader-text">No hay datos para mostrar.</p>
      </section>
      :
      <section className="container">
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Datos Solicitud'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Código'}</label>
                <input type="text" id="Codigo" className="item-value" value={dataEconomicSheet?.codigo} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Solicitud'}</label>
                <input type="text" id="FechaPresentacion" className="item-value" value={dataEconomicSheet?.fechaPresentacion!==null?Moment(dataEconomicSheet?.fechaPresentacion).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Tipo Solicitud'}</label>
                <input type="text" id="TipoSolicitudSocial" className="item-value" value={dataEconomicSheet?.tipoSolicitudSocial!==null?dataEconomicSheet?.tipoSolicitudSocial:""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Importe Solicitud'}</label>
                <input type="text" id="ImporteSolicitud" className="item-value" value={`${dataEconomicSheet?.importeSolicitud}€`} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Datos Resolución'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Estado solicitud'}</label>
                <input type="text" id="EstadoSolicitudTerminado" className="item-value" value={dataEconomicSheet?.estadoSolicitud} readOnly={true}/>
              </div>
            </div>
            
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha de Pago/ingreso'}</label>
                <input type="text" id="FechaPago" className="item-value" value={dataEconomicSheet?.fechaPago!==null?Moment(dataEconomicSheet?.fechaPago).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Cuantía Económica Neto'}</label>
                <input type="text" id="CuantiaEconomicaNeto" className="item-value" value={dataEconomicSheet?.cuantiaEconomicaNeto} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'% de Retención'}</label>
                <input type="text" id="Retencion" className="item-value" value={dataEconomicSheet?.retencion} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
         {/* <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataEconomicSheet.EntornosLaborales.nombre}
            CCC={dataEconomicSheet.EntornosLaborales.ccc}
            CIF={dataEconomicSheet.EntornosLaborales.cif}
          /> */}
      </section>
      }
    </>
  )
}

export default ComisionPrestacionesEspeciales;