import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import { UpdateAccidentRequest, updateDeltaRequest, UpdateAccidentRequestWL,guid, CreateAccidentRequestWL, UpdateActorsRequest, UpdateAssistanceRequest, UpdateCompanyRequest, UpdateEconomicRequest, UpdateWorkerRequest, UpdateCccLocationRequest, UpdateExtensionRequest } from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useAllUpdatePat = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);
  
  const updateAccident = async(data:UpdateAccidentRequestWL)=> {
    try 
    {
      const response = await apiDelta.updateAccident(data)
      return response.data;
    } 
    catch (err) {
      return err;
    }
  }

  const deleteAccident = async(AccidentId:guid)=> {
    try 
    {
      const response = await apiDelta.deleteAccident(AccidentId)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const createAccident = async(data:CreateAccidentRequestWL)=> {
    try 
    {
      const response = await apiDelta.createAccident(data)
      return response.data;
    } 
    catch (err) {
      return err;
    }
  }

  const updateAccidentRemittanceFormatPat = async(data:UpdateAccidentRequest)=> {
    try 
    {
      const response = await apiDelta.updateAccidentRemittanceFormatPat(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateActors = async(data:UpdateActorsRequest)=> {
    try 
    {
      const response = await apiDelta.updateActors(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateAssistance = async(data:UpdateAssistanceRequest)=> {
    try 
    {
      const response = await apiDelta.updateAssistance(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateEconomic = async(data:UpdateEconomicRequest)=> {
    try 
    {
      const response = await apiDelta.updateEconomic(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }
  const updateDelta= async(data:updateDeltaRequest)=> {
    try 
    {
      const response = await apiDelta.updateDelta(data)
      return response.status;
    } 
    catch (err) {
      return false;
    }
  }

  const updateWorker = async(data:UpdateWorkerRequest)=> {
    try 
    {
      const response = await apiDelta.updateWorker(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateCompany = async(data:UpdateCompanyRequest)=> {
    try 
    {
      const response = await apiDelta.updateCompany(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateCccLocation = async(data:UpdateCccLocationRequest)=> {
    try 
    {
      const response = await apiDelta.updateCccLocation(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateExtension = async(data:UpdateExtensionRequest)=> {
    try 
    {
      const response = await apiDelta.updateExtension(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }
 

  return {
    updateDelta,
    createAccident,
    deleteAccident,
    updateAccident,
    updateAccidentRemittanceFormatPat,
    updateActors,
    updateAssistance,
    updateEconomic,
    updateWorker,
    updateCompany,
    updateCccLocation,
    updateExtension
  }

}

export default useAllUpdatePat;