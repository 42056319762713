import React from 'react'
import iconAyuda from '../../../img/icons/ayuda/infoHelp.png'
import { useHistory, useLocation } from 'react-router-dom';

const ButtonGeneralHelp = () => {
    const history = useHistory();
    const location = useLocation();
    const handleClick = () => {
        history.push({
            pathname: '/ayuda-general',
            search: `?from=${encodeURIComponent(location.pathname)}`
          });
    };
  return (
    <div className='container container-button-general-help ' title='Ayuda'>

        <img src={iconAyuda}alt="ayuda" onClick={()=>handleClick()} />
    </div>
  )
}

export default ButtonGeneralHelp