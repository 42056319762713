import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { IFormTemplateRequest } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

const useCreateFormTemplate = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateFormTemplate, setLoadingCreateFormTemplate] = useState(false);
  const [successCreateFormTemplate, setSuccessCreateFormTemplate] = useState(false);
  const [errorCreateFormTemplate, setErrorCreateFormTemplate] = useState(null);

  const createFormTemplate = (data:IFormTemplateRequest, _cb?:()=>void) => {
    setLoadingCreateFormTemplate(true);
    setErrorCreateFormTemplate(null);
    apiFormsIngestion
      .createFormTemplate(data)
      .then((res: any) => {
        setSuccessCreateFormTemplate(true);
        setLoadingCreateFormTemplate(false);
        if (typeof _cb === "function") {
          _cb();
        }
      })
      .catch(err => {
        console.error("createFormTemplate error: ", err);
        setErrorCreateFormTemplate(err);
        setLoadingCreateFormTemplate(false);
      });
  };

  return {
    createFormTemplate,
    successCreateFormTemplate,
    loadingCreateFormTemplate,
    errorCreateFormTemplate
  };
};

export default useCreateFormTemplate;