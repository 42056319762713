import React,{useEffect,useState} from "react";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import GeneralDocumentsGrid from "components/blocks/Grids/GeneralDocumentsGrid";
import  useGetAllGeneralDocument  from 'services/management/useGetAllgeneralDocument.ts'
import { colGroup } from "@syncfusion/ej2-react-grids";
const GeneralDocuments = () => {
const [generalDocuments, setGeneralDocuments] = useState([])
  const {
    allGeneralDocuments,
    getAllGeneralDocuments,
    loadingGetAllGeneralDocuments,
    successGetAllGeneralDocuments,
    errorGetAllGeneralDocuments
  
  }=useGetAllGeneralDocument()
  useEffect(() => {
    getAllGeneralDocuments(setGeneralDocuments)
      
    
  
  }, [])
  return (
    <div className="container pl-5 pr-5 mt-0 mb-4 ">
      <div className="row">
        <div className="col">
          {/*  */}
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item mt-0">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed header-acordeon-title"
                  type="button"
                  data-bs-toggle={
                    generalDocuments && generalDocuments.length <=0 ? "" : "collapse"
                  }
                  data-bs-target="#flush-collapseFour2"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour2"
                >
                  <h1 className="ml-4 title-gestion">Documentos generales</h1>
                  {!loadingGetAllGeneralDocuments &&(
                      <span className="without-active-benefit">
                        <LoaderMin />
                      </span>
                    )}
                  {generalDocuments && generalDocuments?.length === 0 && loadingGetAllGeneralDocuments &&(
                    <span className="without-active-benefit">
                      No hay documentos generales
                    </span>
                  )}
                </button>
              </h2>
              <div
                id="flush-collapseFour2"
                className="accordion-collapse collapse "
                aria-labelledby="flush-headingFour2"
                data-bs-parent="#accordionFlushExample"
              >
                <GeneralDocumentsGrid documents = {allGeneralDocuments && allGeneralDocuments}></GeneralDocumentsGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDocuments;
