import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useGetRole from "../hooks/useGetRole";

const PrivateCompanyRoute = (
  {component: Component, ...rest}) => {
  const roleSelected = useGetRole.getRole();
  const isNotPatient = roleSelected !== 'paciente';
// console.log('isNotPatient',isNotPatient)
  return (
    // Show the component only when the user role is patient
    // Otherwise, redirect the user to /inicio page
    <Route {...rest} render={(props) => (
      isNotPatient ?
            <Component {...props} />
        : <Redirect to="/inicio" />
    )} />
  );
};

export default PrivateCompanyRoute;