import React from 'react';
import {Link} from 'react-router-dom';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import ArticleHomeBox from "../../blocks/ArticleHomeBox/ArticleHomeBox";
import ImageApp from "../../../img/icons/svg/home-company/06.png";
import ImageCenters from "../../../img/icons/svg/home-company/07.png";
import ImageContact from "../../../img/icons/svg/home-company/08.png";
import './HomeCompany.scss';


const HomeCompany:React.FC = function(props:any) {
  return(
    <>
    <PageMenu
      title="Inicio"
    >

    </PageMenu>
    <section className="container">
      <div className="row">
        <div className="col-xl-9">
          <div className="row">
            <article className="col-12">
            <ArticleHomeBox
              title="Últimas comunicaciones recibidas"
              count={124}
              handlePath="/comunicaciones-empresa"
              type='communications'
            />
            </article>
            <article className="col-lg-6 mt-5">
              <ArticleHomeBox
                title="Accidentes con baja pendientes de Delt@"
                count={124}
                handlePath="#"
                type='accident-without-leave'
              />
            </article>
            <article className="col-lg-6 mt-5">
              <ArticleHomeBox
                title="Relación de accidentes sin baja pendientes de Delt@"
                count={0}
                handlePath="#"
                type='accident-leave'
              />
            </article>
            <article className="col-lg-6 mt-5">
              <ArticleHomeBox
                title="Altas médicas de los últimos 30 días"
                count={124}
                handlePath="#"
                type='last-medical-discharge'
              />
            </article>
            <article className="col-lg-6 mt-5">
              <ArticleHomeBox
                title="Trabajadores pendientes de alta médica"
                count={124}
                handlePath="#"
                type='workers-medical-discharge'
              />
            </article>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="row">
            <div className="col-xl-12">
              <article>
                <div className="article-home-link app mt-5 mt-xl-0">
                  <img src={ImageApp} alt=""/>
                  <div>
                    <h2>Solimat App</h2>
                    <Link to="/centros" className="btn-text icon-right">Ver más</Link>
                  </div>
                </div>
              </article>
            </div>
            <div className="col-xl-12 col-sm-6 col-xs-12">
              <article>
                <div className="article-home-link mt-5">
                  <h2>Nuestra red de centros</h2>
                  <img src={ImageCenters} alt=""/>
                  <Link to="/centros" className="btn-text icon-right">Ver Red de Centros</Link>
                </div>
              </article>
            </div>
            <div className="col-xl-12 col-sm-6 col-xs-12">
              <article>
                <div className="article-home-link mt-5">
                  <h2>Contactar con tu gestor</h2>
                  <img src={ImageContact} alt=""/>
                  <Link to="/centros" className="btn-text icon-right">Contactar</Link>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default HomeCompany;