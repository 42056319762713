import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import "./CardLink.scss";
import IconCancel from "../../../img/icons/svg/cita/calendarAppoCancel.png";
import IconReprogram from "../../../img/icons/svg/cita/calendarReprogram.png";
import IconnotCaptured from "../../../img/icons/svg/cita/notCaptured.png";
import IconCalendarBlue from "../../../img/icons/svg/cita/calendario-azul.svg";
import IconCalendarGrey from "../../../img/icons/svg/cita/calendar.png";
import IconVideoCall from "../../../img/icons/svg/cita/video-camera.png";
import IconVideoCallGrey from "../../../img/icons/svg/cita/no-video.png";
import extinct from "../../../img/icons/svg/cita/forbidden.png";
interface ICardLinkColor {
  title: string;
  tipoPrestacion?: number | string;
  contingencia?: number;
  linkTo: string;
  descripcion?: string | undefined;
  tieneExtincion?: boolean;
  descripcionExtincion?: string | undefined;
  fecha?: string | undefined;
  hora?: string | undefined;
  expedienteId?: number | undefined;
  oficioId?: number | undefined;
  procesoId?: number | undefined;
  info?: string;
  info2?: string;
  statusClass: string;
  fechaHora?: string | undefined;
  mensaje?: string | undefined;
  reprogramada?: boolean;
  realizada?: boolean;
  estado?: string;
  props?: any;
  isVideoCall?: string;
}

const CardLinkColor: React.FC<ICardLinkColor> = ({
  props,
  title,
  linkTo,
  tipoPrestacion,
  descripcion,
  tieneExtincion,
  descripcionExtincion,
  fecha,
  hora,
  expedienteId,
  oficioId,
  procesoId,
  info,
  info2,
  statusClass,
  contingencia,
  reprogramada,
  realizada,
  fechaHora,
  estado,
  mensaje,
  isVideoCall,
}) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const [expOficProName, setExpOficProName] = useState("");
  const [expOficProValue, setExpOficProValue] = useState(0);
  const [stateAppointment, setStateAppointment] = useState({
    state: "",
    icon: IconCalendarGrey,
    classState: "",
    backGround: "",
  });
  const isnextAppointment =
    Moment(fechaHora).format("Y MM DD") >= Moment().format("Y MM DD")
      ? true
      : false;

  const handleRedirect = () => {
    props.history.push({
      pathname: linkTo,
      state: {
        tipoPrestacion: tipoPrestacion,
        contingencia: contingencia,
        isnextAppointment: isnextAppointment,
      },
    });
  };

  const getStateAppointment = (estado: string, isVideoCall: string) => {
    if (estado === "Realizada") {
      setStateAppointment({
        state: estado,
        icon: IconCalendarGrey,
        classState: "state-appointment-grey",
        backGround: "",
      });
    }
    if (estado === "Reprogramada") {
      setStateAppointment({
        state: estado,
        icon: IconReprogram,
        classState: "state-appointment",
        backGround: "rescheduled",
      });
    }
    if (estado === "No Realizada") {
      setStateAppointment({
        state: estado,
        icon: IconnotCaptured,
        classState: "state-appointment-grey",
        backGround: "unrealized",
      });
    }
    if (estado === "Cita Anulada") {
      setStateAppointment({
        state: estado,
        icon: IconCancel,
        classState: "state-appointment",
        backGround: "annulled",
      });
    }
    if (estado === "Cita Programada") {
      setStateAppointment({
        state: estado,
        icon: isnextAppointment ? IconCalendarBlue : IconCalendarGrey,
        classState: "state-appointment-grey",
        backGround: "",
      });
    }
    if (estado === "Cita Programada" && isVideoCall) {
      setStateAppointment({
        state: estado,
        icon: isnextAppointment ? IconVideoCall : IconVideoCallGrey,
        classState: "state-appointment-grey",
        backGround: "",
      });
    }
  };
  const checkTypeExp = (
    expedienteId: number,
    oficioId: number,
    procesoId: number
  ) => {
    if (expedienteId) {
      setExpOficProName("EXP");
      setExpOficProValue(expedienteId);
      return;
    }
    if (procesoId) {
      setExpOficProName("PRO");
      setExpOficProValue(procesoId);
    }
    if (oficioId) {
      setExpOficProName("OFI");
      setExpOficProValue(oficioId);
    }
  };
  useEffect(() => {
    checkTypeExp(expedienteId, oficioId, procesoId);
    getStateAppointment(estado, isVideoCall);
  }, [fechaHora]);

  return (
    <a
      title={`Enlace a ${linkTo}`}
      onClick={handleRedirect}
      className={
        isVideoCall
          ? `card-link-block colored ${statusClass} videoCall`
          : `card-link-block colored ${statusClass}`
      }
    >
      {currentPath === "/historial" ? (
        <div className="container-isextinct">
          {tieneExtincion ? (
            <div className="extinct">
              <img className="img-extinct" src={extinct} alt="extinto" />
              <h6 className="text-extinct">{descripcionExtincion}</h6>
            </div>
          ) : (
            ""
          )}
          {expOficProValue !== null && expOficProValue !== 0 ? (
            <span className="exp-num">
              {`${expOficProName}: ${expOficProValue}`}{" "}
            </span>
          ) : (
            <span className="exp-num">Sin Numero Exp</span>
          )}
          <div className="container-title-description">
            <p className="text">{descripcion}</p>
            <p className="card-link_title">{title}</p>
          </div>
          <p className="text-info-card">{`${info} - ${info2}`}</p>{" "}
        </div>
      ) : (
        <div className="container-isextinct">
          {stateAppointment.backGround ? (
            <div className="extinct">
              {isVideoCall ? <img className="img-extinct" src={IconVideoCallGrey} alt="extinto" /> : <img className="img-extinct" src={extinct} alt="extinto" />}
              {isVideoCall && <h6 className="text-isVideoCall">Video Consulta</h6>}
              <h6 className="text-extinct">{stateAppointment.state}</h6>
            </div>
          ) : (
            ""
          )}
          <div
            className={`container-icon-state  ${stateAppointment.backGround}`}
          >
            <img
              className="img-calendar-appointment-card"
              src={stateAppointment.icon}
              alt="calendario"
            />
            {isnextAppointment ? (
              <span className={stateAppointment.classState}>
                {stateAppointment.state}
              </span>
            ) : (
              <span className={stateAppointment.classState}>
                {stateAppointment.state}
              </span>
            )}
          </div>
          {isVideoCall ? (
            <>
              <h3 className="card-link_title">Video Consulta</h3>
              <p className="text">{title}</p>
            </>
          ) : (
            <>
              <p className="text">{descripcion}</p>
              <h3 className="card-link_title">{title}</h3>
            </>
          )}

          <div className="center-appointment">
            <div className="date-hour-container">
              <div className="container-date">
                <p className="label-date">Fecha</p>
                <p className="text-date">{fecha}</p>
              </div>
              <div className="container-hour">
                <p className="label-hour">Hora</p>
                <p className="text-hour">{hora}</p>
              </div>
            </div>
            <p className="text-mensaje-appointment">
              {mensaje ? mensaje : "Sin indicaciones"}
            </p>
          </div>
        </div>
      )}
    </a>
  );
};

export default CardLinkColor;
