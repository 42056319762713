import { MSAL_IMP_APPS } from "hooks/useMsalB2C";


export const ROLES = {
  PACIENTE: "paciente",
  AUTONOMO: "autonomo"
}
const getRole = () => {
  const roleSelected = localStorage.getItem('role');
  if (typeof roleSelected == 'string') {
    let patient2 = roleSelected?.includes(ROLES.PACIENTE);
    let autonomous2 = roleSelected?.includes(ROLES.AUTONOMO);

    if (patient2 && autonomous2) {
      return ROLES.AUTONOMO
    } else {
      return ROLES.PACIENTE
    }
  }
  return roleSelected;
}

const impersonationAppName = () => { return localStorage.getItem('impersonationApp') || '' };

const isImpersonationAppGeneric = (app: string) => { return impersonationAppName() == app; }

const isImpersonation = () => { return !!localStorage.getItem('isImpersonation') };

const isHisam = () => { return isImpersonationAppGeneric(MSAL_IMP_APPS.HISAM) };

const isSIP = () => { return isImpersonationAppGeneric(MSAL_IMP_APPS.SIP) };

const impersonationData = localStorage.getItem('impersonationApp');

const useGetRole = {
  getRole, isImpersonation, impersonationAppName, isImpersonationAppGeneric, isHisam, isSIP, impersonationData
}

export default useGetRole;