import React from 'react';
import { useForm } from "react-hook-form";
import {checkFieldInputType} from 'hooks/checkTypes';
import SurveyFormFields from './SurveyFormFields';
import SurveyFormGrids from './SurveyFormGrids';
import useCreateSurveyJson from "services/surveys/useCreateSurveyJson";
import useGetUserSurveyBySurvey from 'services/surveys/useGetUserSurveyBySurvey';
import useCreateUserSurvey from 'services/surveys/useCreateUserSurvey';
import useModal from 'hooks/useModal';

import Modal from 'components/blocks/Modal/Modal';
import { useHistory } from 'react-router-dom';

interface IProps {
  surveyData: any
  props: any
}

const SurveyForm:React.FC<IProps> = ({surveyData, props}) => {
  const history = useHistory()
  const {handleOpenModal, handleCloseModal, collapsed} = useModal();
  const {handleSubmit, register} = useForm();
  const {createSurveyJson,
    successCreateSurveyJson,
    loadingCreateSurveyJson,
    errorCreateSurveyJson } = useCreateSurveyJson();
  const {getUserSurveyBySurvey} = useGetUserSurveyBySurvey();
    const {createUserSurvey} = useCreateUserSurvey();

  const handleSubmitSurvey = (values:any) => {
    let dataTemplate = values;

    const data = {
      "surveyId": surveyData.id,
      "dataTemplate": JSON.stringify(dataTemplate),
    }
    createSurveyJson(data, handleFinishProcess);
  }

  const handleFinishProcess = async () => {
    const userSurveyBySurvey:any =  await getUserSurveyBySurvey(surveyData.id);
    const data = {
      Id: userSurveyBySurvey.id,
      SurveyId: userSurveyBySurvey.survey_id,
      Name: userSurveyBySurvey.name,
      UserId: userSurveyBySurvey.user_id,
      SendDate: userSurveyBySurvey.send_date,
      ExpirationDate: userSurveyBySurvey.expiration_date,
      Status: 1,
      Expired: userSurveyBySurvey.expired
    }
    createUserSurvey(data, handleOpenModal)

  }

  const handleRedirect = () => {
    history.push('/mis-encuestas');
  }

  return (
    <>
      {/* {loadingCreateSurveyJson &&
        <Loader activeLoader={true}/>
      } */}
      {(errorCreateSurveyJson || successCreateSurveyJson ) &&
        <Modal
          title={errorCreateSurveyJson?"Se ha producido un error":"Proceso finalizado con éxito"}
          collapsed={collapsed}
          onClose={errorCreateSurveyJson?handleCloseModal:handleRedirect}
        >
          <div className="">
            <p className="c-center">{errorCreateSurveyJson?`Inténtelo de nuevo más tarde`:``}</p>
            <div className="btns-container c-center">
              <button className="btn-box btn-accent" 
                onClick={errorCreateSurveyJson?handleCloseModal:handleRedirect}
              >OK</button>
            </div>
          </div>
        </Modal>
      }
      <form onSubmit={handleSubmit(handleSubmitSurvey)} className="form-template login-form">
        <h2>{surveyData.name}</h2>
        <p>{surveyData.description}</p>
        {surveyData.groups.map((group:any)=> {
          return (
          <div className="group" key={group.id}>
            <h3>{group.name}</h3>
            <fieldset>
              <div className="fields">
                {/* FIELDS */}
                <SurveyFormFields 
                  register={register}
                  group={group}
                  checkFieldInputType={checkFieldInputType}
                />
                {/* GRIDS */}
                <SurveyFormGrids 
                  register={register}
                  group={group}
                  checkFieldInputType={checkFieldInputType}
                />
              </div>
            </fieldset>
          </div>
          )
        })}
        <div className={`actions-forms c-right btn-submit-container ${loadingCreateSurveyJson?'loading-active-r':''}`}>
          <button type="submit" value="Enviar" 
            className={`btn-box btn-accent loading ${loadingCreateSurveyJson?'active':''}`}
            disabled={loadingCreateSurveyJson || successCreateSurveyJson}>
              <div className="loader-btn"></div>
              {loadingCreateSurveyJson?'':'Enviar'}
            </button>
        </div>
      </form>
      
  </>
  )
}

export default SurveyForm;