import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import { FilterRatsbRequest, guid, UpdateFooterRequest, CreateFooterRequest, UpdateHeaderRequest, ValidateRATSBRequest,ExportPat } from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useAllRatsb = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Ratsb(BASE_URL, FUNCTIONS_KEY);
  
  const getAllRatsbByUser = async(FilterRatsb:FilterRatsbRequest)=> {
    try 
    {
      const response = await apiDelta.getAllRatsbByUser(FilterRatsb)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }


  const getCompleteRatsbByUser = async(IdentifierRatsb:string)=> {
    try 
    {
      const response = await apiDelta.getCompleteRatsbByUser(IdentifierRatsb)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }
  const getCompleteRatsbByUserRemesed = async(IdentifierRatsb:any)=> {
    try 
    {
      const response = await apiDelta.getCompleteRatsbByUserRemesed(IdentifierRatsb)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const getAccidentsByRatsb = async(RatsbId:guid) => {
    try 
    {
      const response = await apiDelta.getAccidentsByRatsb(RatsbId)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const validateRatsb = async(id:string)=> {
    try 
    {
      const response = await apiDelta.validateRatsb(id)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const exportRatsb = async(Ids:ExportPat)=> {
    try 
    {
      const response = await apiDelta.exportRatsb(Ids)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const totalRatsb = async()=> {
    try 
    {
      const response = await apiDelta.totalRatsb()
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const getRatsb = async(Id:guid)=> {
    try 
    {
      const response = await apiDelta.getRatsb(Id)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  const updateHeader = (data:UpdateHeaderRequest)=> {
    // try 
    // {
    //   const response = await apiDelta.updateHeader(data)
    //   return response.data;
    // } 
    // catch (err) {
    //   return false;
    // }
    return apiDelta.updateHeader(data)
  }
const updateRatsb = async (data:any)=>{
   try 
    {
      const response = await apiDelta.updateRatsb(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
}
  const updateFooter = (data:UpdateFooterRequest)=> {
    // try 
    // {
    //   const response = await apiDelta.updateFooter(data)
    //   return response.data;
    // } 
    // catch (err) {
    //   return false;
    // }
    return apiDelta.updateFooter(data)
  }

  const createFooter = async (data:CreateFooterRequest)=> {
    try 
    {
      const response = await apiDelta.createFooter(data)
      return response.data;
    } 
    catch (err) {
      return false;
    }
  }

  return {
    getAccidentsByRatsb,
    getAllRatsbByUser,
    getCompleteRatsbByUser,
    getCompleteRatsbByUserRemesed,
    validateRatsb,
    exportRatsb,
    totalRatsb,
    getRatsb,
    updateHeader,
    updateFooter,
    createFooter,
    updateRatsb
  }

}

export default useAllRatsb;