import React, {createContext, useState, useEffect} from 'react';

interface Props {
  children: React.ReactNode
}

const CookiesPolicyContext = createContext<any>({});

const CookiesPolicyProvider = ({children}:Props) => {
  const [cookiesPolicyState, setCookiesPolicyState] = useState<boolean>(false);
  useEffect(() => {
    const isCookiesPolicyAccepted = localStorage.getItem("cookiesPolicy");
    if(typeof isCookiesPolicyAccepted === "string") {
      const cookiesState = JSON.parse(isCookiesPolicyAccepted);
      setCookiesPolicyState(cookiesState);
    }
    
  }, [])

  return (
    <CookiesPolicyContext.Provider value={{cookiesPolicyState, setCookiesPolicyState}}>
      {children}
    </CookiesPolicyContext.Provider>
  );
}

export {CookiesPolicyContext, CookiesPolicyProvider}