import {useState} from 'react';
import {appointment} from '@solimat/solimat-web-endpoint';
import useCheckStatusAppointment from '../../hooks/useCheckStatusAppointment';
import Moment from "moment";
import useDecode from "../../hooks/useDecode";

const useGetAllAppointmentsByUserId = () => {
  const BASE_URL = process.env.REACT_APP_CITAS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_CITAS_KEY || "";
  const apiAppointment = new appointment.AppointmeService(BASE_URL, FUNCTIONS_KEY);
  const [statusRes, setStatusRes] = useState(0)
  const [allAppointmentsByUserId, setAllAppointmentsByUserId] = useState();
  const [successGetAllAppointmentsByUserId, setSuccessGetAllAppointmentsByUserId] = useState(false);
  const [loadingGetAllAppointmentsByUserId, setLoadingGetAllAppointmentsByUserId] = useState(false)
  const [errorGetAllAppointmentsByUserId, setErrorGetAllAppointmentsByUserId] = useState(false);
  const [crudeAppointmentsScheduled, setCrudeAppointmentsScheduled] = useState(
    []
  );
  const [crudeAppointmentsAnother, setCrudeAppointmentsAnother] = useState([]);
  const {checkStatusAppointment} = useCheckStatusAppointment();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
 
  

  const formatAppointments = (arrData: any) => {
    let formatedAppos = arrData.map((appo: any) => {
      return {
        ...appo,
        datetime: appo.fecha,
        formated_status: checkStatusAppointment(appo.estado),
        formated_start: Moment(appo.fecha).format("L"),
        formated_hour: Moment(appo.fecha).format("HH:mm"),
        comment:
          appo.communication_id !== null &&
          appo.communication_id !== "" &&
          appo.communication_id !== undefined
            ? "SI"
            : "NO",
      };
    });

    return formatedAppos;
  }


  const getAllAppointmentsByUserId = (_cb?:()=>void) => {
    setLoadingGetAllAppointmentsByUserId(true);

    // 1.GET APPOINTMENTS
    apiAppointment.getAllAppointmentByUserId(userId)
      .then((res:any)=> {
        setStatusRes(res.status)
        setAllAppointmentsByUserId(res.data.rows);
        setSuccessGetAllAppointmentsByUserId(true);
        setLoadingGetAllAppointmentsByUserId(false);
        setErrorGetAllAppointmentsByUserId(false);
        // 2.FILTER APPOINTMENTS
        let arrCrudeAppointmentsScheduled = res.status === 204 ? [] : res.data.rows.filter(
          // (a: any) => Moment(a.fecha).format()>= Moment('').format() compara con hora
          (a: any) => parseInt(Moment(a.fecha).format('Y MM DD').replace(/\s+/g, '')) >= parseInt(Moment().format('Y MM DD').replace(/\s+/g, ''))

        );
        let arrCrudeAppointmentsAnother = res.status === 204 ? [] : res.data.rows.filter(
          // (a: any) => Moment(a.fecha).format()<= Moment('').format() compara con hora
          (a: any) => parseInt(Moment(a.fecha).format('Y MM DD').replace(/\s+/g, '')) < parseInt(Moment().format('Y MM DD').replace(/\s+/g, ''))

          );
        //  let toDay = parseInt(Moment().format('Y MM DD').replace(/\s+/g, ''))
        //  console.log(toDay < 20220505)
        // 3.FORMAT APPOINTMENTS
        arrCrudeAppointmentsScheduled = formatAppointments(arrCrudeAppointmentsScheduled);
        arrCrudeAppointmentsAnother = formatAppointments(arrCrudeAppointmentsAnother);

        // 4.SET CRUDE APPOINTMENTS: scheduled & another
        setCrudeAppointmentsScheduled(arrCrudeAppointmentsScheduled);
        setCrudeAppointmentsAnother(arrCrudeAppointmentsAnother);


      })
      .catch((err:any) => {
        console.log(err);
        setSuccessGetAllAppointmentsByUserId(false);
        setLoadingGetAllAppointmentsByUserId(false);
        setErrorGetAllAppointmentsByUserId(true);
      })

  }


  return {
    getAllAppointmentsByUserId,
    allAppointmentsByUserId,
    crudeAppointmentsScheduled,
    crudeAppointmentsAnother,
    successGetAllAppointmentsByUserId,
    loadingGetAllAppointmentsByUserId,
    errorGetAllAppointmentsByUserId,
    statusRes
  }
}

export default useGetAllAppointmentsByUserId;