import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  errors: any,
  register: any,
  readonly?: boolean,
  defaultValue?: string
  disabled?: boolean
  maxLength?: number,
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any,
  isvalidateNumber?: boolean,
  isvalidateNumberPostalCode?: boolean,
}

const InputText: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, handleOnChange, isvalidateNumber, isvalidateNumberPostalCode }) => {

  // let ref: RefCallBack;
  // if(!register)
  // {
  //   console.log('InputText', name);
  // } else {
  //   const { onChange, onBlur,  ref } = register(name);
  // }
  return (
    <div className={`input-block ${errors && errors[name]?.message ? 'error' : ''}`}>
      <label htmlFor={id} className="input-block__label">{label}</label>
      <input
        type="text"
        name={name}
        id={id}
        placeholder={placeholder}
        {...register(name)}
        readOnly={readonly || false}
        disabled={disabled || false}
        defaultValue={defaultValue || ''}
        maxLength={maxLength}
        onChange={handleOnChange}
      />
      {isvalidateNumber &&
        <p className="error m-0 ">
          Debe contener solo caracteres numericos
        </p>

      }
      {isvalidateNumberPostalCode &&
        <p className="error m-0 ">
          Debe contener solo caracteres numericos
        </p>

      }
      {errors && errors ?
        <p className="error m-0 ">
          {errors && errors[name]?.message}
        </p>
        : ''}
    </div>
  )
}

export default InputText;

// USE EXAMPLE
{/* <InputText 
  id="personalDataName"
  name="personalDataName"
  label="Nombre"
  placeholder="Nombre"
  errors={errors}
  register={register}
/> */}