import { getVirtualData } from "@syncfusion/ej2-react-grids";
import React, { useState, useEffect } from "react";
import useUtils from "services/utils/useUtils";

interface IInputProps {
  errors: any;
  register: any;
  codeCNO: string;
  name: string;
  label: string;
}

// CNO

interface ICnoItem {
  Codigo: string;
  Descripcion: string;
  Grupo: string;
  Nivel: string;
  PadreId: number;
  ExternalId: string;
}

const CnoCotizacionATEP: React.FC<IInputProps> = ({
  errors,
  register,
  codeCNO,
  name,
  label,
}) => {
  const [data, setData] = useState<ICnoItem[] | null>(null);
  const [select1, setSelect1] = useState<ICnoItem[] | null>(null);
  const { getAllCnoLetter } = useUtils();
  const [codeSelected, setcodeSelected] = useState(codeCNO || null);
  const [ocupationCode, setOcupationCode] = useState<ICnoItem[]>([
    {
      Codigo: "",
      Descripcion: "",
      Grupo: "",
      Nivel: "",
      PadreId: 0,
      ExternalId: "",
    },
  ]);
  useEffect(() => {
    handleGetAllCno();
 
  }, []);
  useEffect(() => {
  
    if (data) {
        handleFilterSelect1( data);
    }else{
        return
    }
  }, [data]);

  const handleGetAllCno = async () => {
    const response = await getAllCnoLetter();
    if (response) {
      setData(response);
    } else {
      // SHOW ERROR
    }
  };

  const handleFilterSelect1 = (data: ICnoItem[]) => {
  
    setSelect1(data);
  };

  const handleChange = (e: any, select: number) => {
    switch (select) {
      case 1:
        handleFilterSelect1(data);
        return;
      default:
        return;
    }
  };

  return (
    <fieldset
      className={`input-block ${
        (errors && errors[name]?.message) ||
        (errors && errors[name]?.message)
          ? "error"
          : ""
      }`}
    >
      <label className="input-block__label" htmlFor={name}>
        {" "}
        {label}
      </label>
      {select1 !== null ? (
        <select
          name={name}
          id={name}
          {...register(name)}
          onChange={(e) => handleChange(e, 1)}
          // value={''}
        >
          <option value={""}>Elija una opción</option>
          {select1?.map((item: any, i) => {
            return (
              <option key={i} value={item.Codigo}>
                {item.Codigo} - {item.Descripcion}
              </option>
            );
          })}
        </select>
      ) : null}

      {errors && (
        <p className="error m-0">
          {errors[name]?.message}
          {errors[name]?.message}
        </p>
      )}
      {/* <input
        type="text"
        value={codeSelected}
        name="ocupation_code"
      /> */}
    </fieldset>
  );
};

export default CnoCotizacionATEP;
