import React, {useEffect} from 'react';
import Moment from 'moment';
import '../ManagementSheets.scss';
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import useDecode from "hooks/useDecode";
import EntornoLaboral from '../Comun/EntornoLaboral';

type guid = string;
interface Management {
  name: string,
  date: string,
  company: string
}
interface IOrthoprostheticReplacement extends Management {
  id: number | guid,
  fechaSolicitud?: Date | string,
  estadoSolicitud: string,
  importe?: number,
  fechaAprobacion?: Date | string,
  importeNeto?: number,
  fechaPago?: Date | string,
  retencion?: number,
  record_id: guid,
  tipoComponenteOrtoprotesico: string,
  tipoComponenteDescripcion: string,
  management_type: string
}

interface ISheet {
  props:any
}

const ReposicionOrtoprotesica:React.FC<ISheet> = ({props}:any) => {
  const reposicionOrtoprotesicaId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getOrthoprostheticReplacement,
    dataEconomicSheet,
    loadingEconomicSheet,
    errorEconomicSheet,
  } = useGetEconomicSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;

  useEffect(()=> {
    getOrthoprostheticReplacement(userId, prestacionId, reposicionOrtoprotesicaId);
    Moment().locale("es");
  },[])
  

  if(loadingEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Cargando</p>
      </section>
    )
  }

  if(errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }
  return (
    <>
    {dataEconomicSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Solicitud'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Solicitud'}</label>
              <input type="text" id="FechaSolicitud" className="item-value" value={dataEconomicSheet.fechaSolicitud!==null?Moment(dataEconomicSheet.fechaSolicitud).format("L"):""} readOnly={true}/>
            </div>
          </div>
                
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Aprobación'}</label>
              <input type="text" id="fechaAprobacion" className="item-value" value={dataEconomicSheet.fechaAprobacion!==null?Moment(dataEconomicSheet.fechaAprobacion).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Estado Solicitud'}</label>
              <input type="text" id="estadoSolicitud" className="item-value" value={dataEconomicSheet.estadoSolicitud} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Tipo Componente Ortoprotésico'}</label>
              <input type="text" id="tipoComponenteOrtoprotesico" className="item-value" value={dataEconomicSheet.tipoComponenteOrtoprotesico} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Descripción Componente Ortoprotésico'}</label>
              <input type="text" id="tipoComponenteDescripcion" className="item-value" value={dataEconomicSheet.tipoComponenteDescripcion} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Reposición'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Pago/Ingreso'}</label>
              <input type="text" id="fechaPago" className="item-value" value={dataEconomicSheet.fechaPago!==null?Moment(dataEconomicSheet.fechaPago).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Importe Bruto'}</label>
              <input type="text" id="importe" className="item-value" value={`${dataEconomicSheet.importe}€`}readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Importe Neto'}</label>
              <input type="text" id="ImporteNeto" className="item-value" value={`${dataEconomicSheet.importeNeto}€`} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'% de Retención'}</label>
              <input type="text" id="retencion" className="item-value" value={dataEconomicSheet.retencion} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      {/* <EntornoLaboral
        id={'EntornoLaboral'}
        nombre={dataEconomicSheet.work_environment.nombre}
        CCC={dataEconomicSheet.work_environment.ccc}
        CIF={dataEconomicSheet.work_environment.cif}
      /> */}
    </section>
    
    }
    </>
  )
}

export default ReposicionOrtoprotesica;