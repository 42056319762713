import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.scss";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
// import { UserContextProvider } from "./contexts/UserContext";
import { L10n, setCulture, loadCldr } from "@syncfusion/ej2-base";
import { MsalProvider } from "@azure/msal-react/dist";
import { createBrowserHistory } from "history";
import useMsalB2C from "hooks/useMsalB2C";
import "styles/vendors/material-company/compatibility/material.css";
import { GlobalStateProvider } from "components/pages/GeneralManagement/generalManagementState";
import { SignalRContextProvider } from "contexts/SignalRContext";
import { FormStateProvider } from "components/pages/GeneralManagement/ManagementFormStateContext";
import * as gregorian from "cldr-data/main/es/ca-gregorian.json";
import * as numbers from "cldr-data/main/es/numbers.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import { registerLicense } from "@syncfusion/ej2-base";
loadCldr(numberingSystems, gregorian, numbers);
// require("dotenv").config();



L10n.load({
  es: {
    "react-dropdowns": {
      actionFailureTemplate: "Se ha producido un error al cargar los datos.",
      noRecordsTemplate: "No se han encontrado coincidencias.",
    },
    grid: {
      EmptyRecord: "No hay resultados registrados.",
      Add: "Añadir",
      Edit: "Editar",
      Delete: "Borrar",
      Update: "Actualizar",
      Cancel: "Cancelar",
      Print: "Imprimir",
      PdfExport: "Exportar a PDF",
      ExcelExport: "Exportar a Excel",
      EditOperationAlert: "Seleccione una fila a editar",
      DeleteOperationAlert: "Seleccione una fila a borrar",
      ConfirmDelete: "Seguro que quiere borrar esta fila?",
      CancelButton: "Cancelar",
    },
    dropdowns: {
      nodata: "No hay resultados.",
      noRecordsTemplate: "No hay resultados.",
      clear: "claro",
      comboArrowBtnAriaLabelExpand: "ampliar el cuadro combinado",
      comboArrowBtnAriaLabelCollapse: "colapsar cuadro combinado",
      dropDownListArrowBtnAriaLabel: "seleccione",
    },
    pager: {
      currentPageInfo: "{0} de {1} páginas",
      totalItemsInfo: "({0} registros)",
      pagerDropDown: "resultados por página",
      All: "Todos",
      pagerAllDropDown: "resultados",
    },

    datepicker: {
      placeholder: "Por favor seleccione una fecha",
      today: "Hoy",
    },
  },
});
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

setCulture("es");
const App = () => {
  const history = createBrowserHistory();
  const { msalInstance } = useMsalB2C();
  useEffect(() => {}, [history]);

  return (
    <MsalProvider instance={msalInstance}>
      <SignalRContextProvider>
        <GlobalStateProvider>
          {/* <UserContextProvider> */}
            <FormStateProvider>
              <Routes />
            </FormStateProvider>
          {/* </UserContextProvider> */}
        </GlobalStateProvider>
      </SignalRContextProvider>
    </MsalProvider>
  );
};

const root =ReactDOM.createRoot( document.getElementById("root"));
root.render(<App/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
