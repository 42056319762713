import { useState } from 'react';
import recordSheets from '@solimat/solimat-web-endpoint/lib/recordSheets';
import useCheckTypes from '../../hooks/useCheckRecordStatusAndTypes';
import useDecode from 'hooks/useDecode';

const useGetAllAvailableRecordByUser = () => {
  const [loadingGetAllAvailableRecordByUser, setLoadingGetAllAvailableRecordByUser] = useState(false);
  const [successGetAllAvailableRecordByUser, setSuccessGetAllAvailableRecordByUser] = useState(false);
  const [errorGetAllAvailableRecordByUser, setErrorGetAllAvailableRecordByUser] = useState(null);
  const [records, setRecords] = useState<any | null>(null);
  const {
    checkRecordType,
    checkContType
  } = useCheckTypes()
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";

  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

  const getAllAvailableRecordByUserS = (userId: string, _cb?: (data: any) => void) => {
    setLoadingGetAllAvailableRecordByUser(true);
    setSuccessGetAllAvailableRecordByUser(false);
    if (userId)
      apiRecordSheets.getAllAvailableRecordByUser(userId)
        .then((res: any) => {
          setSuccessGetAllAvailableRecordByUser(true);
          setLoadingGetAllAvailableRecordByUser(false);

          let formatRes = res.data.map((item: any) => {
            let item_date: Date = new Date();
            let format_record_type = null;
            let format_cont_type = null;

            if (item.start_date !== null && item.start_date !== undefined) {
              item_date = item.start_date;
            }

            if (item.record_type !== null && item.record_type !== undefined) {
              format_record_type = checkRecordType(item.record_type);
            } else {
              format_record_type = ""
            }

            if (item.cont_type !== null && item.cont_type !== undefined) {
              format_cont_type = checkContType(item.cont_type);
            } else {
              format_cont_type = ""
            }

            return {
              ...item,
              formatRecordType: format_record_type,
              formatContType: format_cont_type,
              datetime: item_date
            }
          })


          setRecords(formatRes);
          if (typeof _cb === "function") {
            _cb(formatRes);
          }
        })
        .catch((err: any) => {
          setLoadingGetAllAvailableRecordByUser(false);
          setErrorGetAllAvailableRecordByUser(err)
        })
  }

  return {
    getAllAvailableRecordByUserS,
    loadingGetAllAvailableRecordByUser,
    successGetAllAvailableRecordByUser,
    errorGetAllAvailableRecordByUser,
    records,
    userId
  };
}

export default useGetAllAvailableRecordByUser;