import React, { useCallback, createContext, useContext, useEffect, useState } from "react";
import { useLocation, useHistory  } from "react-router-dom";
import { Link } from "react-router-dom";
import HeaderFormDeltaWithLeaveDetail from "components/pages/DeltaWithLeaveDetail/HeaderFormDeltaWithLeaveDetail/HeaderFormDeltaWithLeaveDetail";
import { guid } from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import useGetCompletePatByUser from "services/delta/pat/useGetCompletePatByUser";
import { TextMessagesTemplates } from "components/blocks/TextMessagesTemplates/TextMessagesTemplates";
import TabsDeltaWithLeaveDetail from "./TabsDeltaWithLeaveDetail/TabsDeltaWithLeaveDetail";
import TabsContentDeltaWithLeaveDetail from "./TabsDeltaWithLeaveDetail/TabsContentDeltaWithLeaveDetail";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import {
  errorsValidationDeltaWithLeave,
  findNameByDbKey,
} from "./ValidationDelta.data";
import { FormProvider, useForm } from "react-hook-form";
import { MasterDataContext } from "components/pages/Delta/Delta";
import masterDataUtils from "services/utils/masterDataUtils";
import { Modal, Button } from 'react-bootstrap';
import IMGnotice from "img/icons/symbols/notice.png";
import useModal from 'hooks/useModal';
import { validate } from "uuid";

const DeltaWithLeaveDetail: React.FC = (props: any) => {
  const deltaId = props.match.params.deltaId;
  const location = useLocation();
  const {
    getCompletePatByUser,
  } = useGetCompletePatByUser();
  const [completePatByUser, setCompletePatByUser] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [updatedDelta, setUpdatedDelta] = useState(false)
  const [validatePatResponse, setValidatePatResponse] = useState<any | null>(null);
  const [masterData, setMasterData] = useState<any>({ init: true, type: 'PAT' });
  const title = location?.pathname.includes("remesados")
    ? "Accidentes con baja Delta remesados"
    : "Accidentes con baja pendientes de delta";
  const breadcrumbs = ["unidad delta", `${title}`, "Detalle"];
  const modifiedTitle = title.replace(/\s+/g, "-");
  const history = useHistory();
  const formMethods = useForm();
  const { clearSessionStoragekeys } = masterDataUtils(MasterDataContext);
  const [show, setShow] = useState(false);
  const { handleOpenModal, handleCloseModal, collapsed } = useModal();
  const handleCloseUpdatedDelta = (): void => {
    setUpdatedDelta(false)
    history.push(`/unidad-delta/Accidentes-con-baja-pendientes-de-delta`);
  }
  useEffect(() => {
    if (validatePatResponse?.errorInServer) {
      setShow(true);
    }
  }, [validatePatResponse]);
  const handleClose = () => setShow(false);

  useEffect(() => {
    handleGetCompletePatByUser(deltaId);
    return () => {
      setCompletePatByUser(null);
    };
  }, [deltaId]);
  useEffect(() => {

if (validatePatResponse?.Result) {
  handleOpenModal()

}

  }, [validatePatResponse?.Result]);
 
  const handleGetCompletePatByUser = async (deltaId: guid) => {
    setLoading(true);
    const responseGetCompletePatByUser = await getCompletePatByUser(deltaId);
    clearSessionStoragekeys();
    if (responseGetCompletePatByUser) {
      setError(false);
      setLoading(false);
      setCompletePatByUser(responseGetCompletePatByUser && responseGetCompletePatByUser);
    } else {
      setError(true);
      setLoading(false);
    }
  };
  const updatedDataDelta = (updated: boolean) => {
    setUpdatedDelta(updated)
  }
  const checkTabByName = (name: string) => {
    const nameSplit = name.split(".");
    switch (nameSplit[0]) {
      case "Trabajador":
        return 0;
      case "Empresa":
        return 1;
      case "CCCLugar":
        return 2;
      case "Accidente":
        return 3;
      case "Asistenciales":
        return 4;
      case "Economicos":
        return 5;
      case "Actores":
        return 6;
      default:
        return 0;
    }
  };

  const checkTabByNumber = (type: number) => {
    switch (type) {
      case 0:
        return "Trabajador";
      case 1:
        return "Empresa";
      case 2:
        return "Lugar del accidente";
      case 3:
        return "Accidente";
      case 4:
        return "Asistenciales";
      case 5:
        return "Economicos";
      case 6:
        return "Actores";
      default:
        return "";
    }
  };

  const tab = checkTabByNumber(tabSelected);

  const handleSelectTab = (tabSelected: number) => {
    setTabSelected(tabSelected);
  };

  const handleMasterDataChange = (key: string, value: any) => {
    var newObj = { ...masterData, [key]: value };
    setMasterData(newObj);
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) {
    return <div>{TextMessagesTemplates.loading}</div>;
  }

  if (error) {
    return <div>{TextMessagesTemplates.errorResponse}</div>;
  }
  return (
    <MasterDataContext.Provider value={{ masterData, handleMasterDataChange }}>
      <PageMenu title={`${title} - ${tab}`} breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to={`/unidad-delta/${modifiedTitle}`}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <section className="container">
        {validatePatResponse?.errorInServer && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <img src={IMGnotice} alt="" />
              </div>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
              <h5>
                Lo sentimos, se ha producido un error.
                <br />
                Inténtelo de nuevo más tarde.
              </h5>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Button style={{ background: '#409CCE', color: 'white' }} onClick={handleClose}>
                Entendido
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {!validatePatResponse?.Result && validatePatResponse?.PropertyResult.length >0 && (
          <div className="row mb-4">
            <div className="col-12">
              <div className="container">
                <h1 className="title-errors">Errores de Validación</h1>
              </div>
              <ul className="form-erros-validation">
                {validatePatResponse?.PropertyResult?.map((error: any, index: number) => {
                  const errorItemID = error?.Item1;
                  const tabLocation = checkTabByName(errorItemID);
                  const errorItemMessage = error?.Item2;
                  const nameByBbKey = findNameByDbKey(errorItemID, errorsValidationDeltaWithLeave);
                  return (
                    <li key={index} onClick={() => {
                      handleSelectTab(tabLocation);
                      scrollToElement(nameByBbKey);
                    }}>
                      <span className="badge text-danger-li">
                        {errorItemMessage}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {validatePatResponse?.Result && (
          // <div className="container">
          //   <h1 className="title-errors">Validación Correcta</h1>
          //   <p>Puede remesar este Delta en el listado.</p>
          // </div>
          <Modal show={updatedDelta} onHide={handleCloseUpdatedDelta} centered>
          <Modal.Header closeButton>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              {/* <img src={IMGnotice} alt="" /> */}
              <h1 className="title-errors">Validación Correcta</h1>
             
            </div>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center', justifyContent:'center',alignItems:'center'}}>
            <h5>
            Puede remesar este Delta en el listado.
            </h5>
          </Modal.Body>
          <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Button style={{ background: '#409CCE', color: 'white' }} onClick={handleCloseUpdatedDelta}>
              Entendido
            </Button>
          </Modal.Footer>
        </Modal>
        )}
        <div className="row">
          <div className="col-12">
            {completePatByUser !== null && !loading && !error ? (
              <FormProvider {...formMethods}>
                <HeaderFormDeltaWithLeaveDetail data={completePatByUser} register={formMethods.register} />
                <div className="TabsDeltaWithLeaveDetail">
                  <TabsDeltaWithLeaveDetail
                    handleSelectTab={handleSelectTab}
                    tabSelected={tabSelected}
                  />
                  <TabsContentDeltaWithLeaveDetail
                    updatedDataDelta={updatedDataDelta}
                    tabSelected={tabSelected}
                    handleSendValidationPropertyResult={setValidatePatResponse}
                    handleSubmit={formMethods.handleSubmit}
                    reset={formMethods.reset}
                    watch={formMethods.watch}
                    register={formMethods.register}
                    setValue={formMethods.setValue}
                    getValues={formMethods.getValues}
                    reactErrors={formMethods.formState.errors}
                    props={props}
                  />
                </div>
              </FormProvider>
            ) : null}
          </div>
        </div>
      </section>
      <Modal

        title={"Validación Correcta"}
        collapsed={collapsed}
        onClose={handleCloseModal}
      >



      </Modal>
    </MasterDataContext.Provider>
  );
};

export default DeltaWithLeaveDetail;
