import { useState } from "react";
import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetAllLocationsUser = () => {
  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllLocationsUser, setLoadingGetAllLocationsUser] = useState(false);
  const [successGetAllLocationsUser, setSuccessGetAllLocationsUser] = useState(false);
  const [errorGetAllLocationsUser, setErrorGetAllLocationsUser] = useState(null);
  const [allLocationsUser, setAllLocationsUser] = useState<null|any>(null);

  const getAllLocationsUser = async () => {
    try {
      const res = await apiUtils.getAllLocations()
        setAllLocationsUser(res.data)
        setSuccessGetAllLocationsUser(true);
        setLoadingGetAllLocationsUser(false);
        return res.data;
        
      }
      catch(err) {
        console.error("getAllLocationsUser error: ", err);
        setErrorGetAllLocationsUser(err);
        setLoadingGetAllLocationsUser(false);
      }
  };

  return {
    allLocationsUser,
    getAllLocationsUser,
    successGetAllLocationsUser,
    loadingGetAllLocationsUser,
    errorGetAllLocationsUser
  };
};

export default useGetAllLocationsUser;
