import {useState} from 'react';
import { user } from '@solimat/solimat-web-endpoint';

const useValidateUserCredentials = () => {
  const BASE_URL = process.env.REACT_APP_USUARIOS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_USUARIOS_KEY || "";
  const userService = new user.AuthService(BASE_URL, FUNCTIONS_KEY);

  const [state, setState] = useState<boolean|null>(true);
  const [errorRequest, setErrorRequest ] = useState<boolean>(false)

  const validateUserCredentials = (data:any, _cb:()=>void) => {
    setErrorRequest(false);
   
    userService.validateUserCredentials(data)
   
      .then((res:any)=> {
        if(res) {
          setState(true);
        } else {
          setState(false);
        }
        if(res) {
          if (typeof _cb === "function") {
            _cb()
          }
        }
      })
      .catch((err) => {
        console.log('err validateUserCredentials', err);
        setErrorRequest(true);
      })
  }
  return { state, validateUserCredentials, errorRequest };
}

export default useValidateUserCredentials;