import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom'

import './BreadCrumbs.scss';

interface IBreadCrumb {
  label: string,
  path: string
}

interface IBreadCrumbs {
  title?:string,
  breadcrumbs:string[]
}

const BreadCrumbs:React.FC<IBreadCrumbs> = (Props:IBreadCrumbs) => {
  const [arrPaths, setArrPaths] = useState<IBreadCrumb[]>([])
  const location = useLocation();
  const locationPaths = location.pathname.split("/");
  locationPaths.shift();

  const getPaths = () => {
    let breadcrumbsPaths:IBreadCrumb[] = [];
    let locations:string = "" ;
    for (let i=0;i<locationPaths.length;i++) {
      if(locationPaths.length === Props.breadcrumbs.length) {
        locations = locations.concat('/',locationPaths[i]);
        breadcrumbsPaths.push(
          {
            label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
            path: locations
          }
        )
      } else {
        if(Props.breadcrumbs[i+1]) {
          locations = locations.concat('/',locationPaths[i]);
          breadcrumbsPaths.push(
            {
              label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
              path: locations
            }
          )
        } else {
          locations = locations.concat('/',locationPaths[i],'/',locationPaths[i+1]);
          breadcrumbsPaths.push(
            {
              label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
              path: locations
            }
          )
          break;
        }
      }
    }
    setArrPaths(breadcrumbsPaths)
  }
  
  useEffect(() => {
    getPaths()
  }, [Props.breadcrumbs])

  return (
    <div className="col">
      {(arrPaths && Props.breadcrumbs) &&
      <p className="breadcrumbs">
        <Link className="breadcrumb-link" to="/inicio">Inicio</Link>
        {arrPaths.map((item:IBreadCrumb, index:number) => {
          return (
            <span key={index} className="breadcrumb-container">
              <span className="breadcrumb-separator">{'>'}</span>
              <Link className="breadcrumb-link" to={item.path}>{item.label}</Link>
            </span>
          )
        })}
      </p>
      }
    </div>
  )
}

export default BreadCrumbs;