import React, { useEffect } from "react";
import Moment from "moment";
import "../ManagementSheets.scss";
import useGetAdministrationSheets from "services/recordSheets/useGetAdministrationSheets";
import useDecode from "hooks/useDecode";
import EntornoLaboral from '../Comun/EntornoLaboral';
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;
interface WorkEnvironment {
  WorkEnvironmentCompanyId: number,
  name: string,
  ccc: string,
  cif: string,
}
interface Management {
  name: string,
  date: string,
  company: string
}
interface WithoutLeavePart extends Management {       
  id: guid,
  accident_date?: Date | string,
  center: string,
  accident_description: string,
  code_diagnosis_CIE9: string,
  diagnosis: string,           
  code_diagnosis_CIE10: string,
  diagnosis_CIE10_description: string,
  name_doctor: string,
  number_doctor: string,
  cias_doctor: string,
  attendance_date?: Date | string,
  accident_form_contanct_code: string,
  accident_form_contact_description: string,
  accident_injured_part_code: string,
  accident_injured_part_description: string,
  accident_type_injury_code: string,
  accident_type_injury_description: string,
  record_id: guid,
  work_environment: WorkEnvironment
}

interface ISheet {
  props:any
}

const ParteSinBaja:React.FC<ISheet> = ({props}:any) => {
  const parteId = props.match.params.detailId.split('__')[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    dataAdministrationSheet,
    loadingAdministrationSheet,
    errorAdministrationSheet,
    getWithoutLeavePart
  } = useGetAdministrationSheets();
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(()=> {
    getWithoutLeavePart(userId, prestacionId, parteId)
    Moment().locale("es");
  },[])
  


  if(loadingAdministrationSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(errorAdministrationSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }
  return (
    <>
    {dataAdministrationSheet===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
      <section className="container">
        <div className="col-xl-12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Detalle'}</h3>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha hecho causante'}</label>
                <input type="text" id="FechaAccidente" className="item-value" value={dataAdministrationSheet.fechaAccidente!==null?Moment(dataAdministrationSheet.fechaAccidente).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Fecha Asistencia'}</label>
                <input type="text" id="FechaAsistencia" className="item-value" value={dataAdministrationSheet.fechaAsistencia!==null?Moment(dataAdministrationSheet.fechaAsistencia).format("L"):""} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="item">
                <label className="item-label">{'Diagnóstico CIE 9'}</label>
                <input type="text" id="CodigoDiagnosticoCIE9" className="item-value w-25" value={dataAdministrationSheet.codigoDiagnosticoCIE9} readOnly={true}/>
                <input type="text" id="Diagnostico" className="item-value w-75" value={dataAdministrationSheet.diagnostico} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="item">
                <label className="item-label">{'Diagnóstico CIE 10'}</label>
                <input type="text" id="CodigoDiagnosticoCIE10" className="item-value w-25" value={dataAdministrationSheet.codigoDiagnosticoCIE10} readOnly={true}/>
                <input type="text" id="DiagnosticoCIE10Descripcion" className="item-value w-75" value={dataAdministrationSheet.diagnosticoCIE10Descripcion} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="item">
                <label className="item-label">{'Centro'}</label>
                <input type="text" id="Centro" className="item-value" value={dataAdministrationSheet.centro} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="item">
                <label className="item-label">{'Descripción accidente'}</label>
                <textarea className="item-value" id={'DescripcionAccidente'} readOnly={true} style={{resize:'none'}}>{dataAdministrationSheet.descripcionAccidente}</textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl 12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Facultativo'}</h3>
            </div>
            <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Nombre médico'}</label>
                <input type="text" id="NombreFacultativo" className="item-value" value={dataAdministrationSheet.nombreFacultativo} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'Número médico'}</label>
                <input type="text" id="NumeroFacultativo" className="item-value" value={dataAdministrationSheet.numeroFacultativo} readOnly={true}/>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="item">
                <label className="item-label">{'CIAS médico'}</label>
                <input type="text" id="CiasFacultativo" className="item-value" value={dataAdministrationSheet.ciasFacultativo} readOnly={true}/>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl 12 remarkable">
          <div className="row">
            <div className="col-xl-12">
              <h3 className="remarkable-title">{'Datos Pre-Delta'}</h3>
            </div> */}
           
            {/* <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Forma de Contacto'}</label>
                <input type="text" id="AccidenteFormaContactoCodigo" className="item-value w-25" value={dataAdministrationSheet.accident_form_contanct_code} readOnly={true}/>
                <input type="text" id="AccidenteFormaContactoDescripcion" className="item-value w-75" value={dataAdministrationSheet.accident_form_contact_description} readOnly={true}/>
              </div>
            </div> */}
            {/* <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Parte lesionada'}</label>
                <input type="text" id="AccidenteParteLesionadaCodigo" className="item-value w-25" value={dataAdministrationSheet.accident_injured_part_code} readOnly={true}/>
                <input type="text" id="AccidenteParteLesionadaDescripcion" className="item-value w-75" value={dataAdministrationSheet.accident_injured_part_description} readOnly={true}/>
              </div>
            </div> */}
            {/* <div className="col-xl-6">
              <div className="item">
                <label className="item-label">{'Tipo de lesión'}</label>
                <input type="text" id="AccidenteTipoLesionCodigo" className="item-value w-25" value={dataAdministrationSheet.accident_type_injury_code} readOnly={true}/>
                <input type="text" id="AccidenteTipoLesionDescripcion" className="item-value w-75" value={dataAdministrationSheet.accident_type_injury_description} readOnly={true}/>
              </div>
            </div> */}
          {/* </div>
        </div> */}
        <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.Nombre : ""}
            CCC={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CCC : ""}
            CIF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.CIF : ""}
            EntornoLaboralEmpresaId={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EntornoLaboralEmpresaId : ""}
            EsCuentaPropia={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.EsCuentaPropia : ""}
            NAF={dataAdministrationSheet?.entornoLaboral ? dataAdministrationSheet.entornoLaboral.NAF : ""}
            data={dataAdministrationSheet && dataAdministrationSheet}
          />
      </section>
    }
    </>
  )
}

export default ParteSinBaja;