import React, { useState } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png';

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

export interface IHealthcareDocumentProp {
  documents?: any[] | undefined;
  images?: any[] | undefined;
  handleRequestDocument: (documentID: string) => void;
}

export interface IHealthcareDocumentBlock {
  nombre: string;
  extension: string;
  company: string;
  fecha: Date;
  requiereOtp: boolean;
  uri: string;
}

const HealthcareDocumentBlock: React.FC<IHealthcareDocumentProp> = ({
  documents,
  handleRequestDocument,
}) => {
  let tooltip: any;

  const beforeRender = (args) => {
    const targetColumn = args.target.closest("td").getAttribute('aria-colindex');
    if (targetColumn === '1') { 
      tooltip.content = "descargar";
      tooltip.tipPointerPosition = 'Middle';
      tooltip.position = 'TopLeft';
    } else {
      tooltip.content = args.target.closest("td").innerText;
      tooltip.tipPointerPosition = 'Middle';
      tooltip.position = 'TopLeft';
    }
  };

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const gridTemplate = (props: any): any => {
    if (props.requiereOtp === false) {
      return (
        <div className="link-document">
          <a
            href={props.uri}
            rel={props.nombre}
            target="_blank"
            className="link-bold"
          >
            <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
          </a>
        </div>
      );
    } else {
      return (
        <div className="link-document">
          <a
            rel={props.nombre}
            className="link-bold"
            onClick={() => handleRequestDocument(props.id)}
          >
            <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
          </a>
        </div>
      );
    }
  };

  const template: any = gridTemplate;

  return (
    <div>
      <TooltipComponent
        ref={(t: any) => (tooltip = t)}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
          dataSource={documents}
          allowPaging={documents.length >= 5 ? true : false}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enableHover={false}
          allowSelection={false}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="uri"
              headerText=""
              template={template}
              width="30"
            />
            <ColumnDirective 
              field="nombre" 
              headerText="Nombre" 
              width="170" 
              />
            <ColumnDirective
              field="fecha"
              headerText="Fecha de subida"
              type="date"
              format="dd/MM/yyyy"
              width="50"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default HealthcareDocumentBlock;
