import {useState} from 'react';
import recordSheets from '@solimat/solimat-web-endpoint/lib/recordSheets';
import useDecode from 'hooks/useDecode';
const useGetCompleteRecord = () => {
  const [loadingCompleteRecord, setLoadingCompleteRecord] = useState(false);
  const [successCompleteRecord, setSuccessCompleteRecord] = useState(false);
  const [errorCompleteRecord, setErrorCompleteRecord] = useState<any|null>(null);
  const [record, setRecord] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id
  const getCompleteRecordWithTd = (userId:string, prestacionId:any, _cb?:(data:any)=>void) => {
    setLoadingCompleteRecord(true);
    setSuccessCompleteRecord(false);
    apiRecordSheets.getCompleteRecordWithTd(prestacionId,userId)
      .then((res:any) => {
        setSuccessCompleteRecord(true);
        setLoadingCompleteRecord(false);
        setRecord(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingCompleteRecord(false);
        setErrorCompleteRecord(err)
      })
  }

  const getCompleteRecordWithoutTd = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingCompleteRecord(true);
    setSuccessCompleteRecord(false);
    apiRecordSheets.getCompleteRecordWithoutTd(prestacionId,userId)
      .then((res:any) => {
        if(res.data) {
          setLoadingCompleteRecord(false);
          setSuccessCompleteRecord(true);
          setRecord(res.data);
          if (typeof _cb === "function") {
            _cb(res.data);
          }
        } else {
          setLoadingCompleteRecord(false);
          setSuccessCompleteRecord(false)
          setErrorCompleteRecord(true)
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingCompleteRecord(false);
        setErrorCompleteRecord(err)
      })
  }

  const getCompleteRecordCata = (userId:string, prestacionId:any, _cb?:(data:any)=>void) => {
    setLoadingCompleteRecord(true);
    setSuccessCompleteRecord(false);
    apiRecordSheets.getCompleteRecordCata(prestacionId,userId)
      .then((res:any) => {
        setLoadingCompleteRecord(false);
        setSuccessCompleteRecord(true);
        setRecord(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingCompleteRecord(false);
        setErrorCompleteRecord(err)
      })
  }

  return {
    getCompleteRecordWithTd,
    getCompleteRecordWithoutTd,
    getCompleteRecordCata,
    loadingCompleteRecord,
    successCompleteRecord,
    errorCompleteRecord,
    record,
    userId
    };
}

export default useGetCompleteRecord;