import React, {useState, useEffect} from 'react';
import './Accordion.scss';
import down from '../../../img/icons/Delta/down.png'
interface IProps {
  children:React.ReactNode
  title: string
  expandedHide?: boolean
  expandedInit:boolean
}

const Accordion:React.FC<IProps> = ({children, title, expandedHide, expandedInit}) => {
  const [accordionState, setAccordionState] = useState<boolean>(expandedInit);

  useEffect(() => {
    if (typeof expandedHide === 'boolean')
    setAccordionState(false)
  }, [expandedHide])

  const handleToggleAccordionState = () => {
    setAccordionState(!accordionState);
  }

  return (
    <div className={`Accordion ${accordionState?'expanded':''}`}>
      <p className="accordion-header-block">
        <button
          onClick={handleToggleAccordionState}
          
        > 
          <span className="d-flex justify-content-between align-items-center">
            <span className="header-text d-flex align-items-center">
              <span className="e-icons MT_Preview mr-2"></span>
              <span className="text">{title}</span>
            </span>
            <span className="header-icon e-icons e-down-arrow2"><img src={down}alt="down" className='iconDown'/></span>
          </span>
        </button>
      </p>
      <div className={`accordion-item`}>
        {children}
      </div>
    </div>
  )
}

export default Accordion;