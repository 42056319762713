import React from 'react';
import LoaderMin from '../Loader/LoaderMin';

export const TextMessagesTemplates = {
  errorResponse: (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="loader-text">Lo sentimos, se ha producido un error</p>
          </div>
        </div>
      </div>
    </>
  ),
  loading: (
  <>
    <div className="container">
      <div className="row">
        <div className="col">
          {/* <p className="loader-text">Cargando...</p> */}
          <LoaderMin/>
        </div>
      </div>
    </div>
  </>
  )
};