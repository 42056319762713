import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/communication/dto"


const useGetUserChatCommunicationByIdAppointment = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [userChatCommunicationAppointment, setUserChatCommunicationAppointment] = useState<any>([]);
  const [loadingGetUserChatCommunicationAppointment, setLoadingGetUserChatCommunicationAppointment] = useState(false);
  const [errorGetUserChatCommunicationAppointment, setErrorGetUserChatCommunicationAppointment] = useState(null);

  const getUserChatCommunicationByIdAppointment = async(userId :string, citaId: string ) => {
    setLoadingGetUserChatCommunicationAppointment(true);
    setErrorGetUserChatCommunicationAppointment(null);
    apiCommunication
      .getUserChatCommunicationByIdAppointment(userId, citaId)
      .then((res: any) => {
        const userChatCommunication = res.data.rows;
        setUserChatCommunicationAppointment(userChatCommunication);
        setLoadingGetUserChatCommunicationAppointment(false);
      })
      .catch((err:any) => {
        console.error("getUserChatCommunication: ", err);
        setErrorGetUserChatCommunicationAppointment(err);
        setLoadingGetUserChatCommunicationAppointment(false);
      });
  };

  return {
    getUserChatCommunicationByIdAppointment,
    userChatCommunicationAppointment,
    loadingGetUserChatCommunicationAppointment,
    errorGetUserChatCommunicationAppointment
  };
};

export default useGetUserChatCommunicationByIdAppointment;