import React, { createContext, useState } from 'react';

const PromptContext = createContext();

const PromptContextProvider = ({children}) => {
    const [promptState, setPromptState] = useState({
        id: '',
        name: '',
        description: '',
        user_id: '',
        account_id: '',
        company_id: '',
        role: -1
    });
    const [roleSelected, setRoleSelected] = useState({
        id: '',
        name: '',
        description: '',
        user_id: '',
        account_id: '',
        company_id: '',
        role: -1,
    })
    const [ promptVisible, setPromptVisible ] = useState(false);

    return (
        <PromptContext.Provider value={{promptState, setPromptState,roleSelected, setRoleSelected, promptVisible, setPromptVisible}}>
            {children}
        </PromptContext.Provider>
    );
}

export {PromptContext, PromptContextProvider};