import React from "react";
import IconNoComments from "../../../img/icons/svg/cita/communications-question.svg";
import "./CommunicationOpening.scss";
import { createBrowserHistory } from "history";
interface ICommunicationsBlock {
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  collapsed: boolean;
}

const CommunicationsBlock: React.FC<ICommunicationsBlock> = ({
  handleOpenModal,
  handleCloseModal,
  collapsed,
}) => {
  const history = createBrowserHistory();
  const path = history.location.pathname;
  const subpath = 'mis-citas';

  return (
    <div className="communication-opening">
      <div className="icon-container">
        <img src={IconNoComments} alt="" />
      </div>
      <p className="loader-text">Sin Comentarios.</p>
      {path.includes(subpath) ? (
        ""
      ) : (
        <button className="btn-box btn-accent" onClick={handleOpenModal}>
          Crear comentario
        </button>
      )}
    </div>
  );
};

export default CommunicationsBlock;
