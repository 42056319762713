import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {CookiesPolicyContext} from 'contexts/CookiesPolicyContext';
import useCreateEvent from 'services/stats/useCreateEvent';
import useDecode from "../../../hooks/useDecode";
import './LegalWarningPopup.scss';

const LegalWarningPopup:React.FC = () => {
  const {setCookiesPolicyState} = useContext(CookiesPolicyContext);
  const {
    createEvent} = useCreateEvent();
  const tokenResponse = useDecode("token");

  const handleAccept = () => {
    localStorage?.setItem("cookiesPolicy", "true");
    const data = {
      userId: tokenResponse?.extension_id,
      application: 0,
      operation: 4,
      entity: 0,
      entityId: "",
      status: 0,
      process: 5,
      details: ""
    } 
    createEvent(data)
    setCookiesPolicyState(true);
  }

  return (
    <aside className="popup-advice-bottom legal-warning-popup">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <h2>Uso de Cookies</h2>
            <p>“El uso de las cookies, o tecnologías similares, está destinado a almacenar y procesar datos de las visitas a este sitio web. Estos datos sólo serán utilizados por SOLIMAT MCSS Nº 72 para mejorar los servicios aquí prestados.” <Link to="/politica-de-cookies">Ver Política de Cookies</Link></p>
          </div>
          <div className="offset-xl-2 col-xl-4">
            <div className="btn-container">
              <button className="btn-box btn-accent"
                onClick={handleAccept}
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default LegalWarningPopup;