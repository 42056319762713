import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { guid } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

const useFinishedRequestTransaction = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);

  const [loadingFinishedRequestTransaction, setLoadingFinishedRequestTransaction] = useState(false);
  const [successFinishedRequestTransaction, setSuccessFinishedRequestTransaction] = useState(false);
  const [errorFinishedRequestTransaction, setErrorFinishedRequestTransaction] = useState<boolean|null>(null);

  const finishedRequestTransaction = (requestTransactionId:guid, _cb?:()=>void) => {
    setLoadingFinishedRequestTransaction(true);
    setErrorFinishedRequestTransaction(null);
    apiFormsIngestion
      .finishedRequestTransaction(requestTransactionId)
      .then((res: any) => {
        if(res.data) {
          setSuccessFinishedRequestTransaction(true);
          setLoadingFinishedRequestTransaction(false);
          if (typeof _cb === "function") {
            _cb();
          }
        } else {
          setErrorFinishedRequestTransaction(true);
          setLoadingFinishedRequestTransaction(false);
          setSuccessFinishedRequestTransaction(false);
        }
      })
      .catch(err => {
        console.error("finishedRequestTransaction error: ", err);
        setErrorFinishedRequestTransaction(true);
        setLoadingFinishedRequestTransaction(false);
        setSuccessFinishedRequestTransaction(false);
      });
  };

  return {
    finishedRequestTransaction,
    successFinishedRequestTransaction,
    loadingFinishedRequestTransaction,
    errorFinishedRequestTransaction
  };
};

export default useFinishedRequestTransaction;