import React, { useState } from "react";
import InputText from "../Inputs/InputText";
import MainEconomicActivityCodeCNAE from "../Inputs/MainEconomicActivityCodeCNAE";
import TestCommunity from "../Inputs/TestCommunity";
import Province from "../Inputs/Province";
import YesNoNoAnswer from "../Groups/YesNoNoAnswer";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import { useForm } from "react-hook-form";
import { getValue } from "@syncfusion/ej2-base";
interface ITabContent {
  dataWorker: any;
  data: any;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled?: boolean;
  filterByProvince?: boolean;
}

interface ICompanyData {
  address: string;
  assumption_person: boolean;
  ccc: string;
  cifnif: string;
  city: string;
  city_description: string;
  codeCNAE: string;
  codeCNAEDescription: string;
  contracted: boolean;
  cp: string;
  designated_workers: boolean;
  ett: boolean;
  external_prevention_service: boolean;
  id: string; //guid,
  joint_prevention_services: boolean;
  nothing: boolean;
  own_prevention_service: boolean;
  pat: any;
  phone: string;
  province: string;
  province_descripcion: string;
  reason: string;
  textCNAE: string;
  workforce: string;
}

const Company: React.FC<ITabContent> = ({
  dataWorker,
  data,
  reset,
  watch,
  errors,
  register,
  setValue,
  disabled, 
  filterByProvince
}) => {
  const watchCompanyProvinceCode = watch(`company_province`);
  const watchProvinceDescription = watch(`company_provinceDescription`);
  const watchCommunityCode = watch("company_city");
  const watchCommunityDescription = watch("company_cityDescription");
  const watchCompanyCnaeCode = watch("company_codeCNAE");
  const [isvalidateNumber, setIsValidateNumber] = useState(false);
  const [isvalidateNumberPostalCode, setIsValidateNumberPostalCode] =
    useState(false);
  const maxLengthPhone = 16;
  const maxLengthAddress = 40;
  const maxLengthPostalCode = 5;
  const { getValues } = useForm();
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumber(true);
    } else {
      setIsValidateNumber(false);
    }
  };
  const handleChangePostalCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumberPostalCode(true);
    } else {
      setIsValidateNumberPostalCode(false);
    }
  };
  
  return (
    <div className="container form--simple-styled" id="company_form">
      <div className="row">
        <div className="col-12">
          <FieldsetContainer legend="Datos de la empresa" disabled={disabled}>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="company_cifnif"
                name="company_cifnif"
                label="CIF o NIF/NIE"
                placeholder="CIF o NIF/NIE"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="company_ccc"
                name="company_ccc"
                label="Cuenta Cotización a la Seguridad Social"
                placeholder="Cuenta Cotización a la Seguridad Social"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-4 col-md-6">
              <InputText
                id="company_workforce"
                name="company_workforce"
                label="Plantilla actual de la empresa"
                placeholder="Plantilla actual de la empresa"
                errors={errors}
                register={register}
                readonly
              />
            </div>
            <div className="col-sm-12">
              <InputText
                id="company_reason"
                name="company_reason"
                label="Nombre o razón social"
                placeholder="Nombre o razón social"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputText
                id="company_textCNAE"
                name="company_textCNAE"
                label="Texto actividad económica principal"
                placeholder="Texto actividad económica principal"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <MainEconomicActivityCodeCNAE
                errors={errors}
                register={register}
                watchCnaeCode={watchCompanyCnaeCode}
                setValue={setValue}
                idCode="company_codeCNAE"
                idDescription="company_codeCNAEDescription"
              />
            </div>
            <div className="col-sm-12 col-lg-8">
              <InputText
                id="company_address"
                name="company_address"
                label="Domicilio"
                placeholder="Domicilio"
                errors={errors}
                register={register}
                maxLength={maxLengthAddress}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <InputText
                id="company_phone"
                name="company_phone"
                label="Teléfono"
                placeholder="Teléfono"
                errors={errors}
                register={register}
                maxLength={maxLengthPhone}
                handleOnChange={handleChangePhone}
                isvalidateNumber={isvalidateNumber}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <Province
                setValue={setValue}
                errors={errors}
                register={register}
                watchProvinceCode={watchCompanyProvinceCode}
                watchProvinceDescription={watchProvinceDescription}
                idCode="company_province"
                idDescription="company_provinceDescription"
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <TestCommunity
                errors={errors}
                register={register}
                setValue={setValue}
                idCode="company_city"
                idDescription="company_cityDescription"
                watchCommunityCode={watchCommunityCode}
                watchCommunityDescription={watchCommunityDescription}
                filterByProvince={filterByProvince}
                watchProvinceCode={watchCompanyProvinceCode}
                // idProvinceCode={watchCompanyProvinceCode}
              />
            </div>
            <div className="col-sm-12 col-lg-4">
              <InputText
                id="company_cp"
                name="company_cp"
                label="Código postal"
                placeholder="Código postal"
                errors={errors}
                register={register}
                maxLength={maxLengthPostalCode}
                handleOnChange={handleChangePostalCode}
                isvalidateNumberPostalCode={isvalidateNumberPostalCode}
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-xl-8">
          <FieldsetContainer
            disabled={disabled}
            legend="Marque si actuaba, en el momento del accidente, como contrata o subcontrata:"
          >
            <YesNoNoAnswer
              yesId="company_contractedYes"
              noId="company_contractedNo"
              noAnswerId="company_contractedNoAnswer"
              name="company_contracted"
              errors={errors}
              register={register}
            />
          </FieldsetContainer>
        </div>
        <div className="col-xl-4">
          <FieldsetContainer
            disabled={disabled}
            legend="Es una empresa de trabajo temporal"
          >
            <YesNoNoAnswer
              yesId="temporaryCompanyYes"
              noId="temporaryCompanyNo"
              noAnswerId="temporaryCompanyNoAnswer"
              name="company_ett1"
              errors={errors}
              register={register}
            />
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer
            disabled={disabled}
            legend="¿Cuál o cuales son las modalidades de organización preventiva adoptadas por la empresa?"
          >
            <div className="col-xl-8">
              <div className="input-block--radio-check__group d-block">
                <label
                  htmlFor={"company_assumptionPerson"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    Asunción personal por el empresario de la actividad
                    preventiva de la empresa.
                  </span>
                  <input
                    type={"checkbox"}
                    name={"company_assumptionPerson"}
                    id={"company_assumptionPerson"}
                    {...register("company_assumptionPerson")}
                    value={
                      "Asunción personal por el empresario de la actividad preventiva de la empresa."
                    }
                  />
                </label>
                <label
                  htmlFor={"company_externalPreventionService"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">{"Servicio de prevención ajeno"}</span>
                  <input
                    type={"checkbox"}
                    name={"company_externalPreventionService"}
                    id={"company_externalPreventionService"}
                    {...register("company_externalPreventionService")}
                    value={"Servicio de prevención ajeno"}
                  />
                </label>
                <label
                  htmlFor={"company_jointPreventionServices"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">
                    {"Servicio de prevención mancomunado"}
                  </span>
                  <input
                    type={"checkbox"}
                    name={"company_jointPreventionServices"}
                    id={"company_jointPreventionServices"}
                    {...register("company_jointPreventionServices")}
                    value={"Servicio de prevención mancomunado"}
                  />
                </label>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="input-block--radio-check__group d-block">
                <label
                  htmlFor={"company_ownPreventionService"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">Servicio de prevención propio.</span>
                  <input
                    type={"checkbox"}
                    name={"company_ownPreventionService"}
                    id={"company_ownPreventionService"}
                    {...register("company_ownPreventionService")}
                    value={"Servicio de prevención propio."}
                  />
                </label>
                <label
                  htmlFor={"company_designatedWorkers"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">{"Trabajador(es) designado(s)"}</span>
                  <input
                    type={"checkbox"}
                    name={"company_designatedWorkers"}
                    id={"company_designatedWorkers"}
                    {...register("company_designatedWorkers")}
                    value={"Trabajador(es) designado(s)"}
                  />
                </label>
                <label
                  htmlFor={"company_nothing"}
                  className="input-block--radio-check__label"
                >
                  <span className="text">{"Ninguna"}</span>
                  <input
                    type={"checkbox"}
                    name={"company_nothing"}
                    id={"company_nothing"}
                    {...register("company_nothing")}
                    value={"Ninguna"}
                  />
                </label>
              </div>
            </div>
            {errors && errors['company_nothing']?.message && (
              <p className="error">Seleccione por lo menos una opción</p>
            )}
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Company;
