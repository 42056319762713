import { useState } from "react";
import SurveyIngestion from "@solimat/solimat-web-endpoint/lib/survey-ingestion";

const useGetUserSurveyBySurvey = () => {
  const BASE_URL = process.env.REACT_APP_SURVEY_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_SURVEY_KEY || "";
  const apiSurvey = new SurveyIngestion.SurveyIngestionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetUserSurveyBySurvey, setLoadingGetUserSurveyBySurvey] = useState(false);
  const [successGetUserSurveyBySurvey, setSuccessGetUserSurveyBySurvey] = useState(false);
  const [errorGetUserSurveyBySurvey, setErrorGetUserSurveyBySurvey] = useState(null);
  const [userSurveyBySurvey, setUserSurveyBySurvey] = useState<null|any>(null);

  const getUserSurveyBySurvey = async (surveyId:string) => {
    setLoadingGetUserSurveyBySurvey(true);
    setErrorGetUserSurveyBySurvey(null);
    try {
      const res = await apiSurvey.getUserSurveyBySurvey(surveyId)
        setSuccessGetUserSurveyBySurvey(true);
        setLoadingGetUserSurveyBySurvey(false);
        setUserSurveyBySurvey(res.data)
        return res.data;
        
      }
      catch(err) {
        console.error("getUserSurveyBySurvey error: ", err);
        setErrorGetUserSurveyBySurvey(err);
        setLoadingGetUserSurveyBySurvey(false);
      }
  };

  return {
    userSurveyBySurvey,
    getUserSurveyBySurvey,
    successGetUserSurveyBySurvey,
    loadingGetUserSurveyBySurvey,
    errorGetUserSurveyBySurvey
  };
};

export default useGetUserSurveyBySurvey;
