import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Moment from "moment";
import "moment/locale/es";
import useDecode from "../../../hooks/useDecode";
import { useHistory } from "react-router-dom";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import Message from "../../blocks/Message/Message";
import Modal from "../../blocks/Modal/Modal";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import IconCommentGray from "../../../img/icons/svg/botones/comentarios-gris.svg";
import useCreateGeneralCommunication from "services/communications/useCreateGeneralCommunication";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useCheckTypes from "hooks/useCheckRecordStatusAndTypes";
import useGetAllUserCommunicationByUserId from "../../../services/communications/useGetAllUserCommunicationByUserId";
import FormCreateCommunication from "components/blocks/Forms/FormCreateCommunication";
interface IvaluesFormReplyCommunication {
  subject: string;
  message: string;
  "loadfiles[]": any[];
}
interface IdataComunication {
  externalUserId: string;
  userId: string;
  prestacionId: string;
  asunto: string;
  mensaje: string;
  files: any[];
  comunicacionReferenciaId: null | string;
}
const CommunicationChatGeneral: React.FC = (props: any) => {
  const history = useHistory();
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId;
  const communicationId = arrParamsId;
  const communicationSubject = props.history.location.subject;
  const tokenResponse = useDecode("token");
  const externalUserId = tokenResponse?.extension_external_id;
  const userId = tokenResponse && tokenResponse.extension_id;
  const [collapsed, setCollapsed] = useState(true);
  const [userTokenId, setUserTokenId] = useState("");
  const {
    getAllUserCommunication,
    allUserCommunication,
    loadingGetAllUserCommunication,
    errorGetAllUserCommunication,
  } = useGetAllUserCommunicationByUserId();

  const { createGeneralCommunication,successCreateGeneralCommunication  } = useCreateGeneralCommunication();
  const handleOpenModal = () => {
    setCollapsed(false);
  };
  const handleCloseModal = () => {
    setCollapsed(true);
  };

  useEffect(() => {
    if (tokenResponse !== undefined) {
      setUserTokenId(tokenResponse.extension_id);
    }
    Moment().locale("es");
    getAllUserCommunication(userId);
  }, [successCreateGeneralCommunication]);

  useEffect(() => {
    if (allUserCommunication) {
      // No-op
    }
  }, [allUserCommunication]);

  const dataCommunicationChat =
    allUserCommunication &&
    allUserCommunication.find(
      (communication: any) => communication.id === communicationId
    );
  const subjectForAnswer =
    dataCommunicationChat && dataCommunicationChat?.asunto;

  const convertBase64 = async (files: any) => {

    var arrayAuxiliar: any = [];
    Array.from(files && files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          // id: "",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };
  const onSubmitCommunication = async (values: any) => {
    const dataFiles = await convertBase64(values.files || []);
    setTimeout(() => {
      const newData: IdataComunication = {
        externalUserId: externalUserId,
        userId: userId,
        prestacionId: dataCommunicationChat?.prestacionId ? dataCommunicationChat?.prestacionId : null,
        asunto: values.asunto,
        mensaje: values.mensaje,
        files: dataFiles,
        comunicacionReferenciaId: dataCommunicationChat?.puedeResponder
          ? dataCommunicationChat?.id
          : null,
      };
      createGeneralCommunication(userId, newData);
      if (!successCreateGeneralCommunication) {
        setTimeout(() => {
          history.goBack()
          
        }, 2000);
      }
      setCollapsed(true);
    }, 700);
  };



  return (
    <>
      {loadingGetAllUserCommunication ? (
        <section className="container">
          <LoaderMin />
        </section>
      ) : (
        <>
          <PageMenu
            title={"Comentario"}
            breadcrumbs={["Comunicaciones", "Comunicación General"]}
          >
            <div className="btns-header-container">


              <a onClick={() => props.history.goBack()}>
                <img src={IconArrowGray} alt="icon arrow right" />
              </a>
            </div>
          </PageMenu>
          <div className="container">
            <div className="row">
              <div className="col">
                <section>
                  {allUserCommunication && (
                    <Message
                      userChatCommunication={dataCommunicationChat}
                      userId={userTokenId}
                    />
                  )}
                  {dataCommunicationChat && dataCommunicationChat.puedeResponder &&

                    <div className="container-btn-response">
                      <button className="btn-text btn-gray btn-response" onClick={handleOpenModal}>
                        {/* <img src={IconCommentGray} alt="icon reply" /> */}
                        <span className="e-icons e-AddComment"></span>
                        <span className="text-response"> Responder</span>
                      </button>
                    </div>

                  }

                </section>

              </div>
            </div>
          </div>

          <Modal collapsed={collapsed} onClose={handleCloseModal}>
            <>
              {!collapsed ? (
                <FormCreateCommunication
                  title={"Responder"}
                  onSubmit={onSubmitCommunication}
                  onClose={handleCloseModal}
                  subject={subjectForAnswer}
                  idComunication={dataCommunicationChat?.id}
                />
              ) : (
                <div className="ghost-form"></div>
              )}
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default CommunicationChatGeneral;
