import {useState} from 'react';

const useToggleViewCardsList = () => {
  const [viewCard, setViewCard] = useState(true)
  const handleToggleViewCard = () => {
    setViewCard(!viewCard);
  };
  return {handleToggleViewCard, viewCard}
}

export default useToggleViewCardsList;