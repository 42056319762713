import { useState } from 'react';

const useModals2 = () => {
    const [collapsed2, setCollapsed2] = useState(true);
    const [childrenProps2, setChildrenProps2] = useState({path:''});
    const [ disabledCloseButton2, setDisabledCloseButton2 ] = useState(false);

    const handleOpenModal2 = (childrenProps2?:any, _cb?:()=>void) => {
      setCollapsed2(false);
      setChildrenProps2(childrenProps2);
      if(typeof _cb === 'function') {
        _cb();
      }
    }
    const handleCloseModal2 = () => {
      setCollapsed2(true);
      setChildrenProps2({path:''});
    }

    const handleDisableCloseButton2 = (state:boolean) => {
      setDisabledCloseButton2(state)
    }
 
    return {
      collapsed2, 
      childrenProps2, 
      handleOpenModal2, 
      handleCloseModal2, 
      disabledCloseButton2, 
      handleDisableCloseButton2
    };
}

export default useModals2;