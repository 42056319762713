import React, { useEffect, useState } from "react";
import { getValue } from "@syncfusion/ej2-base";
import { EmitType } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  RowDataBoundEventArgs,
} from "@syncfusion/ej2-react-grids";
import {
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import useCheckRecordStatusAndTypes from "hooks/useCheckRecordStatusAndTypes";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
interface IRecordsGrid {
  arrData: any[] | undefined;
  props: any;
}

const RecordsGrid: React.FC<IRecordsGrid> = ({ arrData, props }) => {
  let tooltip: any;
  const beforeRender = (args: any) => {
    tooltip.content = args.target.closest("td").innerText;
  };
  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });
  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fechaInicio", direction: "Descending" }],
  });
  const [formatedArrData, setFormatedArrData] = useState([]);
  const { checkContingencyType, checkCoverageType } =
    useCheckRecordStatusAndTypes();

  useEffect(() => {
    formatArrData(arrData);
  }, []);

  const formatArrData = (arrData: any) => {
    const formatedArrData = arrData.map((record: any) => {
      return {
        ...record,
        contingencia: checkContingencyType(parseInt(record.contingencia)),
        cobertura: checkCoverageType(parseInt(record.cobertura)),
      };
    });
    setFormatedArrData(formatedArrData);
  };

  // const rowDataBound: EmitType<any> = (args: RowDataBoundEventArgs) => {
  //   if (args.row) {
  //     if (getValue("tipoPrestacion", args.data) === 1) {
  //       args.row.classList.add("record-type__occupational-disease");
  //     } else if (getValue("TipoPrestacion", args.data) === 2) {
  //       args.row.classList.add("record-type__cessation_of_autonomous_activity");
  //     } else {
  //       args.row.classList.add("record-type__general");
  //     }
  //   }
  // };
  const rowDataBound: EmitType<any> = (args: RowDataBoundEventArgs) => {
    if (args.row) {
      if (getValue("tieneExtincion", args.data) === true) {
        args.row.classList.add("extinguida");
      }
    }
  };

  const rowSelected = (args: any) => {
    props.history.push(
      `/historial/${args.data.TipoPrestacion}_${args.data.id}`
    );
  };

  return (
    <TooltipComponent
      ref={(t: any) => (tooltip = t)}
      target=".e-rowcell"
      beforeRender={beforeRender}
    >
      <GridComponent
        dataSource={formatedArrData && formatedArrData}
        allowPaging={true}
        allowSorting={true}
        rowSelected={rowSelected}
        pageSettings={pageOptions}
        sortSettings={sortingOptions}
        rowDataBound={rowDataBound}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="contingencia"
            headerText="Contingencia"
            width="100"
          />
          <ColumnDirective
            field="cobertura"
            headerText="Cobertura"
            width="120"
          />
          <ColumnDirective
            field="fechaInicio"
            headerText="Fecha Inicio"
            type="date"
            format="dd/MM/yyyy"
            width="100"
          />
          <ColumnDirective
            field="fechaFin"
            headerText="Fecha Fin"
            type="date"
            format="dd/MM/yyyy"
            width="100"
          />
        </ColumnsDirective>
        <Inject services={[Page, Sort]} />
      </GridComponent>
    </TooltipComponent>
  );
};

export default RecordsGrid;
