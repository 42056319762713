import React from 'react';

interface IInputProps {
  legend?: string,
  nameA: string,
  idA: string,
  labelA: string,
  valueA: string,
  idB: string,
  labelB: string,
  valueB: string,
  nameB?: string,
  type: string,
  errors: any,
  register: any
  disabled?: boolean
}
const DoubleRadioCheckGroup: React.FC<IInputProps> = ({ legend, idA, labelA, labelB, idB, nameA, nameB, valueA, valueB, type, errors, register, disabled }) => {

  return (
    <fieldset className={`input-block input-block--fieldset ${errors && errors[nameA]?.message || nameB && errors && errors[nameB]?.message ? 'error' : ''}`} disabled={disabled}>
      {legend &&
        <legend className="input-block--fieldset__legend">{legend}</legend>
      }
      <div className="input-block--radio-check__group">
        <label htmlFor={idA} className="input-block--radio-check__label">
          <span className="text">{labelA}</span>
          <input type={type} name={nameA} id={idA}  {...register(nameA)} value={valueA} />
        </label>
        <label htmlFor={idB} className="input-block--radio-check__label">
          <span className="text">{labelB}</span>
          <input type={type} name={nameB ? nameB : nameA} id={idB} {...register(nameA)} value={valueB} />
        </label>
      </div>
      {errors &&
        <p className="error m-0">
          {errors[nameA]?.message}
        </p>
      }
    </fieldset>
  )
}

export default DoubleRadioCheckGroup;

// USE EXAMPLE
{/* <DoubleRadioCheckGroup
  legend="Los datos coinciden con los de la Empresa"
  idA="matchingDataYes"
  valueA="yes"
  valueB="no"
  labelA="Sí"
  idB="matchingDataNo"
  labelB="No"
  nameA="matchingData"
  type="radio"
  errors={errors}
  register={register}
/> */}