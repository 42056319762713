import { useState } from "react";
import { formsIngestion } from "@solimat/solimat-web-endpoint";
import { guid, IFormTemplateRequest } from "@solimat/solimat-web-endpoint/lib/forms-ingestion/dto";

const useGetRequestTransaction = () => {
  const BASE_URL = process.env.REACT_APP_FORMS_INGESTION_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_FORMS_INGESTION_KEY || "";
  const apiFormsIngestion = new formsIngestion.FormTemplateService(BASE_URL, FUNCTIONS_KEY);
  
  const [requestTransaction, setRequestTransaction] = useState<any|null>(null);
  const [loadingGetRequestTransaction, setLoadingGetRequestTransaction] = useState(false);
  const [successGetRequestTransaction, setSuccessGetRequestTransaction] = useState(false);
  const [errorGetRequestTransaction, setErrorGetRequestTransaction] = useState(null);

  const getRequestTransaction = (id:guid, _cb?:(data:any)=>void) => {
    setLoadingGetRequestTransaction(true);
    setErrorGetRequestTransaction(null);
    apiFormsIngestion
      .getRequestTransaction(id)
      .then((res: any) => {
        setSuccessGetRequestTransaction(true);
        setLoadingGetRequestTransaction(false);
        setRequestTransaction(res.data)
        if (typeof _cb === "function") {
          _cb(res.data.forms);
        }
      })
      .catch(err => {
        console.error("getRequestTransaction error: ", err);
        setErrorGetRequestTransaction(err);
        setLoadingGetRequestTransaction(false);
      });
  };

  return {
    getRequestTransaction,
    requestTransaction,
    successGetRequestTransaction,
    loadingGetRequestTransaction,
    errorGetRequestTransaction
  };
};

export default useGetRequestTransaction;