import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageMenu from "../../blocks/PageMenu/PageMenu";
import FormChangePassword from "../../blocks/Forms/FormChangePassword";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useDecode from "../../../hooks/useDecode";
import FormEditUser from "components/blocks/Forms/FormEditUser";

const ChangeSettings: React.FC = (props: any) => {
  const URL_location = props.location.pathname;
  const [titlePage, setTitlePage] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState<string[]>([]);
  const tokenResponse = useDecode("token");

  useEffect(() => {
    if (
      URL_location === "/ajustes/cambiar-contraseña" ||
      props.history.location.completeUser !== undefined
    ) {
      checkURL();
    } else {
      props.history.push("/ajustes");
    }
    if (tokenResponse !== undefined) {
      checkURL();
    } else {
      props.history.push("/inicio");
    }
  }, []);

  const checkURL = () => {
    switch (URL_location) {
      case "/ajustes/cambiar-contraseña":
        setBreadCrumbs(["Ajustes", "Cambiar contraseña"]);
        return setTitlePage("Cambiar contraseña");
      case "/ajustes/editar-usuario":
        setBreadCrumbs(["Ajustes", "Editar usuario"]);
        return setTitlePage("Editar Usuario");
      default:
        return;
    }
  };
  return (
    <>
      <PageMenu title={titlePage} breadcrumbs={breadCrumbs}>
        <div className="btns-header-container">
          <Link to="/ajustes">
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>

      <section>
        {URL_location === "/ajustes/cambiar-contraseña" && (
          <FormChangePassword />
        )}
        {URL_location === "/ajustes/editar-usuario" && (
          <FormEditUser
            props={props}
            completeUser={props.history.location.completeUser}
          />
        )}
      </section>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default ChangeSettings;
