import React, { useState } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

const GeneralDocumentsGrid = ({ documents }) => {
  let tooltip2;
  const beforeRender = (args) => {
    const targetColumn = args.target.closest("td").getAttribute('aria-colindex');
    if (targetColumn === '1') { // Verifica si es la primera columna (index starts at 1)
      tooltip2.content = "Descargar";
      tooltip2.tipPointerPosition = 'Middle';
      tooltip2.position = 'TopLeft';
    } else {
      tooltip2.content = args.target.closest("td").innerText;
      tooltip2.tipPointerPosition = 'Middle';
      tooltip2.position = 'TopLeft';
    }
  };
  const [pageOptions] = useState({
    pageSize: 5,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });
  const [sortingOptions] = useState({
    columns: [{ field: "fecha", direction: "Descending" }],
  });
  const gridTemplateGeneral = (props) => {
    return (
      <div className="link-document">
        <a
          href={props.Uri}
          rel={props.Nombre}
          target="_blank"
          className="link-bold">
          <img style={{width:'20px', height:'20px'}} src={IconDownload} alt="descargar" />
        </a>
      </div>
    );
  };
  const template = gridTemplateGeneral;
  return (
    <div>
      <TooltipComponent
        ref={(t) => (tooltip2 = t)}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
          dataSource={documents && documents}
          allowPaging={true}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enableHover={false}
          allowSelection={false}
        >
          <ColumnsDirective>
          <ColumnDirective
              field="uri"
              headerText=""
              template={template}
              width="30"
              textAlign="center"
              
            />
            <ColumnDirective field="nombre" headerText="Nombre" width="170" />
            <ColumnDirective
              field="fecha"
              headerText="Fecha de subida"
              type="date"
              format="dd/MM/yyyy"
              width="50"
            />
           
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default GeneralDocumentsGrid;
