import Utils from "@solimat/solimat-web-endpoint/lib/utils"

const useGetAllTypeDocument = () => {

  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";

  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const getAllTypeDocument = async() => {
    try {
      const res = await apiUtils.getTypeDocument();
      return res.data;
    } catch (err) {
      console.error("getAllTypeDocument", err);
    }

  }

  return {
    getAllTypeDocument
  }

}

export default useGetAllTypeDocument;