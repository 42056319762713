import React, { useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

interface IRecordManagementGrid {
  arrData: any[];
  props: any;
  typeComponent?: any;
}

const RecordDetailManagementGrid: React.FC<IRecordManagementGrid> = ({
  arrData,
  props,
  typeComponent,
}) => {
  let tooltip: any;
  const beforeRender = (args: any) => {
    tooltip.content = args.target.closest("td").innerText;
  };
  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const rowSelected = (args: any) => {
    if (args != null) {
      let idRow = args.data.id;
      let managementType = args.data.tipo_componente;
      let redirectPath = `/historial/${props.match.params.id}/${typeComponent}/${managementType}__${idRow}`;
      props.history.push({
        pathname: redirectPath,
      });
    }
  };

  return (
    <div>
      <TooltipComponent
        ref={(t: any) => (tooltip = t)}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
          dataSource={arrData}
          allowPaging={arrData.length >= 5 ? true : false}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
        >
          <ColumnsDirective>
            <ColumnDirective field="nombre" headerText="Nombre" width="170" />
            <ColumnDirective field="empresa" headerText="Empresa" width="140" />
            <ColumnDirective
              field="fecha"
              headerText="Fecha Creación"
              type="date"
              format="dd/MM/yyyy"
              width="140"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default RecordDetailManagementGrid;
