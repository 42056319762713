import Loader from "components/blocks/Loader/Loader";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useGetShortener from "services/shortener/useGetShortener";

const Shortener: React.FC = (props: any) => {
  const {
    getShortenerOriginalUrl,
    successGetShortenerPage,
    errorGetShortenerPage,
    shortenerPage,
  } = useGetShortener();
  const history = useHistory();

  useEffect(() => {
    getShortenerOriginalUrl(props.match.params.url);
  }, []);

  useEffect(() => {
    if (shortenerPage || successGetShortenerPage) {
      console.log(shortenerPage);
      window.location.href = shortenerPage;
    }
    if (errorGetShortenerPage && !shortenerPage) {
      history.push("/inicio");
    }
  }, [
    successGetShortenerPage,
    errorGetShortenerPage,
    shortenerPage,
    history.push,
    history,
  ]);
  return (
    <>
      <Loader></Loader>
      </>
  );
};
export default Shortener;
