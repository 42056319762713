// getAllUserCommunicationByUserId

import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";
import { ICreateUserCommunication } from "@solimat/solimat-web-endpoint/lib/communication/dto"

const useCreateUserCommunicationRecord = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateUserCommunicationRecord, setLoadingCreateUserCommunicationRecord] = useState(false);
  const [successCreateUserCommunicationRecord, setSuccessCreateUserCommunicationRecord] = useState(false);
  const [errorCreateUserCommunicationRecord, setErrorCreateUserCommunicationRecord] = useState(null);

  const createUserCommunicationRecord = (userId: string, prestacionId: string ,data:any, _cb?:(id:string)=>void) => {
    setLoadingCreateUserCommunicationRecord(true);
    setErrorCreateUserCommunicationRecord(null);
    apiCommunication
      .createUserCommunicationRecord(userId,prestacionId, data)
      .then((res: any) => {
        setSuccessCreateUserCommunicationRecord(true);
        setLoadingCreateUserCommunicationRecord(false);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch(err => {
        console.error("createUserCommunication error: ", err);
        setErrorCreateUserCommunicationRecord(err);
        setLoadingCreateUserCommunicationRecord(false);
      });
  };

  return {
    createUserCommunicationRecord,
    successCreateUserCommunicationRecord,
    loadingCreateUserCommunicationRecord,
    errorCreateUserCommunicationRecord
  };
};

export default useCreateUserCommunicationRecord;