import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';

const useGetAllHealthcareInformation = () => {
  const [loadingAllHealthcareInformation, setLoadingAllHealthcareInformation] = useState(false);
  const [successAllHealthcareInformation, setSuccessAllHealthcareInformation] = useState(false);
  const [errorAllHealthcareInformation, setErrorAllHealthcareInformation] = useState<boolean|null>(null);
  const [dataAllHealthcareInformation, setDataAllHealthcareInformation] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

  const getAllHealthcareInformation = (userId:string, prestacionId:string,_cb?:(data:any)=>void) => {
    setLoadingAllHealthcareInformation(true);
    setSuccessAllHealthcareInformation(false);
    apiRecordSheets.getCareComponentCount(userId, prestacionId)
      .then((res:any)=> {
        setLoadingAllHealthcareInformation(false)
        if(res.status !== 204) {
          setSuccessAllHealthcareInformation(false);
          setDataAllHealthcareInformation(res.data);
        } else {
          setErrorAllHealthcareInformation(true);
        }

      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingAllHealthcareInformation(false);
        setErrorAllHealthcareInformation(true);

      })
  }
  return {
    getAllHealthcareInformation,
    loadingAllHealthcareInformation,
    successAllHealthcareInformation,
    errorAllHealthcareInformation,
    dataAllHealthcareInformation
  };
}

export default useGetAllHealthcareInformation;