import {useState} from 'react';

const useToggleExpandedAccordion = () => {
  const [expandedState, setExpandedState] = useState<boolean|undefined>(undefined);

  const toggleExpandedAccordionState = () => {
    if(expandedState!==undefined) {
      setExpandedState(!expandedState);
    } else {
      setExpandedState(false);
    }
  }

  return {
    toggleExpandedAccordionState,
    expandedState
  }
}

export default useToggleExpandedAccordion;
