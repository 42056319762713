import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { setTimeout } from "timers";
const PrintButton = ({
  DomIdPrint,
  success,
  formRef,
  toggleComponentVisibility,
}) => {
  const handlePrint = async () => {
    toggleComponentVisibility(true);
    setTimeout(async () => {
      const formElement = formRef.current;
      const canvas = await html2canvas(formElement);
      toggleComponentVisibility(false);
      const contentHeight = canvas.height;
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = 210;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const pdf = new jsPDF("p", "mm", [210, pdfHeight+20]);
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("formulario.pdf");
    }, 1000);
  };

  return (
    <button
      className="btn-box btn-accent"
      onClick={() => handlePrint()}
      disabled={success}
    >
      Imprimir
    </button>
  );
};

export default PrintButton;
