import React, {useEffect, useState} from 'react';
import { getValue } from '@syncfusion/ej2-base';
import { EmitType } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  RowDataBoundEventArgs
} from "@syncfusion/ej2-react-grids";
import {
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import useCheckSurveyStatus from '../../../hooks/useCheckSurveyStatus';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

interface ISurveysGrid {
  arrData: any[] | undefined,
  props:any
}

const SurveysGrid:React.FC<ISurveysGrid> = ({arrData, props}) => {
  let tooltip: any;
  const beforeRender = (args: any) => {
    tooltip.content = args.target.closest("td").innerText;
  };
  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  })
  const [sortingOptions] = useState<Object>({
    columns: [{ field: "send_date", direction: "Descending" }],
  })
  const [formatedArrData,setFormatedArrData] = useState();
  const {checkSurveyStatus} = useCheckSurveyStatus();

  useEffect(()=> {
    formatArrData(arrData)
  },[])

  const formatArrData = (arrData:any) => {
    const formatedArrData = arrData.map((survey:any)=>{
      return {...survey, formated_status: checkSurveyStatus(parseInt(survey.status),survey.expired)}
    })
    setFormatedArrData(formatedArrData)
  }

  const rowDataBound:EmitType<any> = (args: RowDataBoundEventArgs) => {
    if (args.row) {
      if (getValue('formated_status', args.data) === 'Pendiente'){
        args.row.classList.add('selectable-row');
      } else {
        args.row.classList.add('unselectable-row');
      }
    }
  }

  const rowSelected = (args: any) => {
    if(args.data.formated_status === "Pendiente") {
      props.history.push({pathname:`/mis-encuestas/${args.data.survey_id}`});
    }
  };

  return (
    <div className="variable-select__grid">
       <TooltipComponent
    ref={(t: any) => (tooltip = t)}
    target=".e-rowcell"
    beforeRender={beforeRender}
  >
      <GridComponent
        dataSource={formatedArrData}
        allowPaging={true}
        allowSorting={true}
        rowSelected={rowSelected}
        pageSettings={pageOptions}
        sortSettings={sortingOptions}
        rowDataBound={rowDataBound}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="name"
            headerText="Encuesta"
            width="120"
          />
          <ColumnDirective
            field="formated_status"
            headerText="Estado"
            width="100"
          />
          <ColumnDirective
            field="send_date"
            headerText="Fecha de recepción"
            type="date"
            format="dd/MM/yyyy"
            width="100"
          />
          <ColumnDirective
            field="expiration_date"
            headerText="Fecha de finalización"
            type="date"
            format="dd/MM/yyyy"
            width="100"
          />
        </ColumnsDirective>
        <Inject services={[Page, Sort]} />
      </GridComponent>
      </TooltipComponent>
    </div>
  )
}

export default SurveysGrid;