export const navLinksPatient = [
    {
        text: 'Inicio',
        path: '/inicio'
    },
    {
        text: 'Mis citas',
        path: '/mis-citas'
    },
    {
        text: 'Mis gestiones',
        path: '/mis-gestiones'
    },
    {
        text: 'Historial',
        path: '/historial'
    },
    {
        text: 'Notificaciones',
        path: '/notificaciones'
    }
]

export const navLinksCompany = [
    {
        text: 'Inicio',
        path: '/inicio'
    },
    {
        text: 'Unidad Delt@',
        path: '/unidad-delta'
    },
    {
        text: 'Mis citas',
        path: '/mis-citas'
    },
    {
        text: 'Mis gestiones',
        path: '/mis-gestiones'
    },
    {
        text: 'Historial',
        path: '/historial'
    },
    {
        text: 'Notificaciones',
        path: '/notificaciones'
    }
]


export const navLinksImpersonationHisam = [
    {
        text: 'Inicio',
        path: '/inicio'
    },
    {
        text: 'Mis citas',
        path: '/mis-citas'
    },
    {
        text: 'Historial',
        path: '/historial'
    },
    {
        text: 'Notificaciones',
        path: '/notificaciones'
    }
]

export const navLinksImpersonationSip = [
    {
        text: 'Inicio',
        path: '/inicio'
    },
    {
        text: 'Mis gestiones',
        path: '/mis-gestiones'
    },
    {
        text: 'Historial',
        path: '/historial'
    },
    {
        text: 'Notificaciones',
        path: '/notificaciones'
    }
]
export const navLinksImpersonationSipCompany = [ 
    {
        text: 'Inicio',
        path: '/inicio'
    },    
    {
        text: 'Unidad Delt@',
        path: '/unidad-delta'
    },
    {
        text: 'Mis gestiones',
        path: '/mis-gestiones'
    },
    {
        text: 'Historial',
        path: '/historial'
    },
    {
        text: 'Notificaciones',
        path: '/notificaciones'
    }
]

// export const navLinksCompany = [
//   {
//     text: 'Inicio',
//     path: '/inicio-profesional'
//   },
//   {
//       text: 'Unidad Delt@',
//       path: '/unidad-delta'
//   },
//   {
//       text: 'Informes',
//       path: '/informes'
//   },
//   {
//       text: 'Afiliación',
//       path: '/afiliacion'
//   },
//   {
//       text: 'Prestaciones',
//       path: '/prestaciones'
//   },
//   {
//       text: 'Utilidades',
//       path: '/utilidades'
//   }
// ]