import React from 'react';
import { useForm } from "react-hook-form";

interface IformCreateCommunication {
  title?: string,
  onSubmit: (values: any) => void,
  onClose: () => void,
  subject?: string
}

interface IvaluesFormAnswerCommunication { // ?question cómo asociar (values:IvaluesFormCreateCommunication)
  subject: string,
  message: string,
  files?: any[]
}

const FormAnswerCommunication: React.FC<IformCreateCommunication> = ({ title, onSubmit, onClose, subject }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}
      className="form-modal form-regular">
      {title &&
        <h2 className="form-title">{title}</h2>
      }
      <input
        type="text"
        className={`input ${errors.subject ? "invalid" : ""}`}
        name="subject"
        id="subject"
        placeholder="Asunto"
        {...register('subject', {
          required: "Campo requerido",
        })}
        defaultValue={subject}
      />
      {errors.subject && (
        <p className="form-error">{errors["subject"].message.toString()}</p>
      )}
      <textarea
        className={`input textarea ${errors.message ? "invalid" : ""}`}
        name="comment"
        cols={50}
        id="comment"
        placeholder="Escribe tu comentario"
        {...register("comment",{
          required: "Campo requerido"
        })}
      />
      {errors.message &&
        <p className="form-error">{errors["message"].message.toString()}</p>
      }
      <label htmlFor="loadfile">
        <input
          type="file"
          name="loadfiles[]"
          id="loadfiles"
          className="input-file"
          multiple
          {...register("loadfiles")}
        />
        {/* <span className="input-file-icon e-icons e-M_Load"></span> */}
      </label>
      <div className="btns-container">
        <button
          type="button"
          className="btn-box btn-clear"
          onClick={onClose}
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="btn-box btn-accent"
        >
          Enviar
        </button>
      </div>

    </form>
  )
}

export default FormAnswerCommunication;