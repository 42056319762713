import React, {useEffect, useState} from 'react';
import Moment from 'moment';

import EntornoLaboral from '../Comun/EntornoLaboral';
import '../ManagementSheets.scss';

import {recordSheets} from '@solimat/solimat-web-endpoint';
import LoaderMin from 'components/blocks/Loader/LoaderMin';

const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

type guid = string;
interface WorkEnvironment {
  workEnvironmentCompanyId: number,
  name: string,
  ccc: string,
  cif: string,
}
interface Management { // management
  name: string,
  date: string,
  company: string
}
interface ITSDPago extends Management{
  id: number | guid,
  exercise: number,
  pay_start_date: Date | string,
  pay_end_date: Date | string,
  amount: number,
  payment_class: string,
  record_id: number,
  work_environment: WorkEnvironment,
  pay_date: Date | string,
  management_type: string
}

interface ISheet {
  props:any
}

const TSDPago:React.FC<ISheet> = ({props}:any) => {
  const sheetId = props.match.params.detailId.split('__')[1];
  const [data, setData] = useState<ITSDPago|null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(()=> {
    getData();
    Moment().locale("es");
  },[])
  
  const getData = () => {
    setLoading(true)
    apiRecordSheets.getTSDPayment(sheetId)
      .then((res:any)=> {
        setLoading(false);
        setSuccess(true);
        if(res.status !== 204) {
          setData(res.data);
        } else {
          setError(true);
        }
      })
      .catch((err:any) => {
        console.log(err);
        setLoading(false);
        setError(true);
      })
  }

  if(loading) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin/>
      </section>
    )
  }

  if(error) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    )
  }

  return (
    <>
    {data===null?
    <section className="container">
      <p className="loader-text">No hay datos para mostrar.</p>
    </section>
    :
    <section className="container">
      <div className="col-xl-12 remarkable">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="remarkable-title">{'Detalles'}</h3>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Ejercicio'}</label>
              <input type="text" id="Ejercicio" className="item-value" value={data.exercise} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Inicio Pago'}</label>
              <input type="text" id="FechaInicioPago" className="item-value" value={data.pay_start_date!==null?Moment(data.pay_start_date).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Fin Pago'}</label>
              <input type="text" id="FechaFinPago" className="item-value" value={data.pay_end_date!==null?Moment(data.pay_end_date).format("L"):""} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Importe Pago'}</label>
              <input type="text" id="ImportePago" className="item-value" value={data.amount} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Clase Pago'}</label>
              <input type="text" id="ClasePago" className="item-value" value={data.payment_class} readOnly={true}/>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="item">
              <label className="item-label">{'Fecha Pago'}</label>
              <input type="text" id="FechaPago" className="item-value" value={data.pay_date!==null?Moment(data.pay_date).format("L"):""} readOnly={true}/>
            </div>
          </div>
        </div>
      </div>
      {/* <EntornoLaboral
            id={"EntornoLaboral"}
            nombre={data?.entornoLaboral ? data.entornoLaboral.Nombre : ""}
            CCC={data?.entornoLaboral ? data.entornoLaboral.CCC : ""}
            CIF={data.entornoLaboral ? data.entornoLaboral.CIF : ""}
            EntornoLaboralEmpresaId={data?.entornoLaboral ? data.entornoLaboral.EntornoLaboralEmpresaId : ""}
            EsCuentaPropia={data?.entornoLaboral ? dataEconomicSheet.entornoLaboral.EsCuentaPropia : ""}
            NAF={data?.entornoLaboral ? data.entornoLaboral.NAF : ""}
          /> */}
    </section>
    }
    </>
  )
}

export default TSDPago;