import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";


const useGetEpisodeConsultation = () => {
const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
const [loadingGetEpisodeConsultation, setLoadingGetEpisodeConsultation] = useState(false);
const [successGetEpisodeConsultation, setSuccessGetEpisodeConsultation] = useState(false);
const [errorGetEpisodeConsultation, setErrorGetEpisodeConsultation] = useState(null);
const [episodeConsultation, setEpisodeConsultation] = useState<null|any>(null);

const getEpisodeConsultation = (userId:string, prestacionId:string, _cb?:(data:any)=>void) => {
    setLoadingGetEpisodeConsultation(true);
    setSuccessGetEpisodeConsultation(false);
    apiRecord.getEpisodeConsultation(userId,prestacionId)
      .then((res:any) => {       
         
        setLoadingGetEpisodeConsultation(false);
        setSuccessGetEpisodeConsultation(true);
        setEpisodeConsultation( res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetEpisodeConsultation(false);
        setErrorGetEpisodeConsultation(err)
      })

    }

      return {
        episodeConsultation,
        getEpisodeConsultation,
        loadingGetEpisodeConsultation,
        successGetEpisodeConsultation,
        errorGetEpisodeConsultation
        };

    
  }
  export default useGetEpisodeConsultation;