// se ordena por fecha inicio 
const useSortDataRecords = () => {

    const orderDescendingData = (data:any, _cb?:(orderedData?:any)=>void) => {
      let orderedDescendingData = [...data].sort((a:any, b:any) => new Date(b.fechaInicio).getTime() - new Date(a.fechaInicio).getTime());
      if (typeof _cb === "function") {
        _cb(orderedDescendingData);
      }
    }

    const orderAscendingData = (data:any, _cb?:(orderedData?:any)=>void) => {
      let orderedAscendingData = [...data].sort((a:any, b:any) => new Date(a.fechaInicio).getTime() - new Date(b.fechaInicio).getTime());
      if (typeof _cb === "function") {
        _cb(orderedAscendingData);

      }
    }

    return {orderDescendingData, orderAscendingData};
}

export default useSortDataRecords;