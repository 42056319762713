import { useState } from 'react';
import { appointment } from '@solimat/solimat-web-endpoint';
import Moment from 'moment'
import { StatusDto } from '@solimat/solimat-web-endpoint/lib/appointment/dto/Appointment/StatusDto';
import useDecode from 'hooks/useDecode';

const useGetExternalRequestById = () => {
  const [loadingGetExternalRequestById, setLoadingGetExternalRequestById] = useState(false);
  const [successGetExternalRequestById, setSuccessGetExternalRequestById] = useState(false);
  const [errorGetExternalRequestById, setErrorGetExternalRequestById] = useState(null);
  const [dataExternalRequest, setDataExternalRequest] = useState(null);
  const BASE_URL = process.env.REACT_APP_CITAS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_CITAS_KEY || "";
  const apiExternalRequest = new appointment.AppointmeService(BASE_URL, FUNCTIONS_KEY);
  const tokenResponse = useDecode("token");
  const getExternalRequestById = (id:any, _cb:(data:any)=>void) => {
    setLoadingGetExternalRequestById(true);
    setSuccessGetExternalRequestById(false);
    apiExternalRequest.getExternalRequest(id)
      .then((res:any) => {
        
        let formatedExternalRequest = {
          ...res.data,
          formated_item_type: res.data.appointment_type,
          formated_start: Moment(res.data.appointment_date).format("L"),
          formated_hour:  Moment(res.data.appointment_date).format("HH:mm")
        }
        setLoadingGetExternalRequestById(false);
        setSuccessGetExternalRequestById(true);
        setDataExternalRequest(formatedExternalRequest);
        if (typeof _cb === "function") {
          _cb(formatedExternalRequest);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingGetExternalRequestById(false);
        setErrorGetExternalRequestById(err);
      })
  }

  return {
    getExternalRequestById,
    loadingGetExternalRequestById,
    successGetExternalRequestById,
    errorGetExternalRequestById,
    dataExternalRequest
  };
}

export default useGetExternalRequestById;