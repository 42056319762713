import React, { useEffect, useState, useContext } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import useUtils from "services/utils/useUtils";
import { MasterDataContext } from "components/pages/Delta/Delta";
import { useFormContext } from "react-hook-form";
import masterDataUtils from "services/utils/masterDataUtils";

interface IDoubleInputProps {
  legend: string;
  nameCode: string;
  idCode: string;
  nameDescription: string;
  idDescription: string;
  placeholderDescription: string;
  errors: any;
  register: any;
  setValue: any;
  watchNationalityCode: any;
  watchNationalityDescription: any;
  disabled?: boolean;
}

interface Country {
  code: string;
  description_code: string;
}

const Nationality: React.FC<IDoubleInputProps> = ({
  idCode,
  nameCode,
  idDescription,
  nameDescription,
  legend,
  placeholderDescription,
  errors,
  register,
  setValue,
  watchNationalityCode,
  watchNationalityDescription,
  disabled,
}) => {
  const { getAllCountry, onlyDigitsWithMaxLength, pasteOnlyDigits } = useUtils();
  const [allCountry, setAllCountry] = useState<any[] | null>(null);

  const { getValues } = useFormContext();
  const masterDataContext = useContext(MasterDataContext);

  const masterData = masterDataContext?.masterData;

  const itemKey: string = 'country';

  const { checkAndUpdate } = masterDataUtils(masterDataContext);

  useEffect(() => {
    checkAndUpdate(itemKey, allCountry && allCountry, getAllCountry, setAllCountry);
  }, [masterData]);

  useEffect(() => {
    setValueNationalityDescription();
  }, [watchNationalityCode]);

  useEffect(() => {
    if (allCountry) {
      const descriptionValue = allCountry.find(
        (item: any) => item.Codigo === watchNationalityCode
      );
      if (descriptionValue) {
        setValue(idDescription, descriptionValue.Descripcion);
      }
    }
  }, [watchNationalityCode, allCountry]);

  const handleChangeAutoComplete = (args: any) => {
    const selectedDescription = args.value;
    if (selectedDescription === "" || selectedDescription === null) {
      setValue(nameCode, "");
      setValue(idCode, "");
      setValue(idDescription, "");
    } else if (allCountry) {
      const matchingEntry = allCountry.find((item: any) => item.Descripcion === selectedDescription);
      if (matchingEntry) {
        setValue(nameCode, matchingEntry.Codigo);
        setValue(idCode, matchingEntry.Codigo);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(idCode, value);
    if (value === "") {
      setValue(idDescription, "");
    } else if (allCountry) {
      const descriptionValue = allCountry.find((item: any) => item.Codigo === value);
      if (descriptionValue) {
        setValue(idDescription, descriptionValue.Descripcion);
      }
    }
  };

  const setValueNationalityDescription = () => {
    if (allCountry) {
      const descriptionValue = allCountry.find((item: any) => item.Codigo === watchNationalityCode);
      setValue(idDescription, descriptionValue ? descriptionValue.Descripcion : "");
    }
  };

  const itemsData: { [key: string]: Object }[] = allCountry || [];
  const fields: object = { value: "Descripcion" };

  return (
    <fieldset
      disabled={disabled}
      className={`input-block input-block--code ${
        (errors && errors[nameCode]?.message) || (errors && errors[idDescription]?.message)
          ? "error"
          : ""
      }`}
    >
      <div className="container-leyend">
        <legend className="input-block--code__legend">{legend}</legend>
      </div>
      <div className="container-inputs-cno-code">
        <input
          className="col-md-3 col-lg-3 code no-arrow"
          type="text"
          inputMode="numeric"
          name={nameCode}
          id={idCode}
          {...register(idCode)}
          maxLength={3}
          autoComplete="off"
          onKeyDown={(e) => { onlyDigitsWithMaxLength(e, getValues(idCode), 3) }}
          onPasteCapture={(e) => pasteOnlyDigits(e)}
          onChange={handleInputChange}
        />
        <div className="col-md-8 auto-complete-component--syncfusion common-form">
          <AutoCompleteComponent
            id={idDescription}
            name={idDescription}
            dataSource={itemsData}
            fields={fields}
            placeholder={placeholderDescription}
            autofill={true}
            highlight={true}
            change={handleChangeAutoComplete}
            {...register(idDescription)}
            onBlur={() => {
              const value = getValues(idDescription);
              if (value === "") {
                setValue(idCode, "");
                setValue(nameCode, "");
              }
            }}
          />
        </div>
      </div>
      {errors && (
        <p className="error m-0">
          {errors[nameCode]?.message}
          {errors[idDescription]?.message}
        </p>
      )}
    </fieldset>
  );
};

export default Nationality;

