import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';

const useGetAdministrationSheets = () => {
    const [loadingAdministrationSheet, setLoadingAdministrationSheet] = useState(false);
    const [successAdministrationSheet, setSuccessAdministrationSheet] = useState(false);
    const [errorAdministrationSheet, setErrorAdministrationSheet] = useState(null);
    const [dataAdministrationSheet, setDataAdministrationSheet] = useState<any|null>(null);
  
    const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
    const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
    const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);
// PARTE CONFIRMACION
    const getConfirmationPart = (userId: string, prestacionId: string, parteId: string,_cb?:(data:any)=>void) => {
        setLoadingAdministrationSheet(true);
        setSuccessAdministrationSheet(false);
        apiRecordSheets.getConfirmationPart(userId, prestacionId, parteId)
          .then((res:any) => {
            setLoadingAdministrationSheet(false);
            setSuccessAdministrationSheet(true);
            setDataAdministrationSheet(res.data);
            if (typeof _cb === "function") {
              _cb(res.data);
            }
          })
          .catch((err:any)=> {
            console.log(err)
            setLoadingAdministrationSheet(false);
            setErrorAdministrationSheet(err)
          })
      }
// PARTE ALTABAJA
const getDischargeLeavePart = (userId: string, prestacionId: string, parteId: string,_cb?:(data:any)=>void) => {
    setLoadingAdministrationSheet(true);
    setSuccessAdministrationSheet(false);
    apiRecordSheets.getDischargeLeavePart(userId, prestacionId, parteId)
      .then((res:any) => {
        setLoadingAdministrationSheet(false);
        setSuccessAdministrationSheet(true);
        setDataAdministrationSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingAdministrationSheet(false);
        setErrorAdministrationSheet(err)
      })
  }
  // PARTE SIN BAJA
  const getWithoutLeavePart = (userId: string, prestacionId: string, parteId: string,_cb?:(data:any)=>void) => {
    setLoadingAdministrationSheet(true);
    setSuccessAdministrationSheet(false);
    apiRecordSheets.getWithoutLeavePart(userId, prestacionId, parteId)
      .then((res:any) => {
        setLoadingAdministrationSheet(false);
        setSuccessAdministrationSheet(true);
        setDataAdministrationSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingAdministrationSheet(false);
        setErrorAdministrationSheet(err)
      })
  }
// ENTORNO LABORAL
  const getWorkerEnvironment = (userId: string, prestacionId: string, parteId: string,_cb?:(data:any)=>void) => {
    setLoadingAdministrationSheet(true);
    setSuccessAdministrationSheet(false);
    apiRecordSheets.getWorkerEnvironment(userId, prestacionId, parteId)
      .then((res:any) => {
        setLoadingAdministrationSheet(false);
        setSuccessAdministrationSheet(true);
        setDataAdministrationSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingAdministrationSheet(false);
        setErrorAdministrationSheet(err)
      })
  }
  return {
            dataAdministrationSheet,
            successAdministrationSheet,
            loadingAdministrationSheet,
            errorAdministrationSheet,
            getConfirmationPart,
            getDischargeLeavePart,
            getWithoutLeavePart,
            getWorkerEnvironment
         }  
  
}

export default useGetAdministrationSheets