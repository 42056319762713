import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import { ExportPat } from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useExportPat = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);
  
  const exportPat = async(Ids:ExportPat)=> {
    try 
    {
      const response = await apiDelta.exportPat(Ids)
        return response.data;
    } 
    catch (err) {
      return false;
    }

  }

  return {
    exportPat
  }

}

export default useExportPat;
