import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";

const useGetRoyalDecreeFormById = () => {
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
  const [loadingGetRoyalDecreeFormById, setLoadingGetRoyalDecreeFormById] =
    useState(false);
  const [successGetRoyalDecreeFormById, setSuccessGetRoyalDecreeFormById] =
    useState(false);
  const [errorGetRoyalDecreeFormById, setErrorGetRoyalDecreeFormById] =
    useState(null);
  const [royalDecreeFormById, setRoyalDecreeFormById] = useState<
    null | any
  >(null);

  const getRoyalDecreeFormById = (
    userId: string,
    prestacionId: string,
    id:string,
    _cb?: (data: any) => void
  ) => {
    setLoadingGetRoyalDecreeFormById(true);
    setSuccessGetRoyalDecreeFormById(false);
    apiRecord
      .getRoyalDecreeFormById(userId, prestacionId, id)
      .then((res: any) => {
        setLoadingGetRoyalDecreeFormById(false);
        setSuccessGetRoyalDecreeFormById(true);
        setRoyalDecreeFormById(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingGetRoyalDecreeFormById(false);
        setErrorGetRoyalDecreeFormById(err);
      });
  };
  return {
    royalDecreeFormById,
    getRoyalDecreeFormById,
    loadingGetRoyalDecreeFormById,
    successGetRoyalDecreeFormById,
    errorGetRoyalDecreeFormById,
  };
};
export default useGetRoyalDecreeFormById;