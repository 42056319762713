import React, { useState } from 'react';
import InputText from '../Inputs/InputText';
import Province from '../Inputs/Province';
import TestCommunity from '../Inputs/TestCommunity';

interface IInputProps {
  errors: any,
  register: any,
  watchProvinceCode?: any,
  watchProvinceDescription?: any,
  watchCommunityCode: any,
  watchCommunityDescription?: any
  setValue?: any
  idStreet: string
  idPhone: string
  idProvinceCode: string
  idProvinceDescription: string
  idCommunityCode: string
  idCommunityDescription: string
  idPostalCode: string
  filterByProvince?: boolean
}

const AddressGroup: React.FC<IInputProps> = ({
  errors,
  register,
  setValue,
  watchProvinceCode,
  watchProvinceDescription,
  watchCommunityCode,
  watchCommunityDescription,
  idStreet,
  idPhone,
  idProvinceCode,
  idProvinceDescription,
  idCommunityCode,
  idCommunityDescription,
  idPostalCode,
  filterByProvince,
}) => {
  const [isvalidateNumber, setIsValidateNumber] = useState(false);
  const [isvalidateNumberPostalCode, setIsValidateNumberPostalCode] = useState(false);
  const maxLengthPhone = 16
  const maxLengthAddress = 40
  const maxLengthPostalCode = 5

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumber(true)
    } else {
      setIsValidateNumber(false)
    }
  }
  const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/[^0-9]/g.test(inputValue)) {
      setIsValidateNumberPostalCode(true)
    } else {
      setIsValidateNumberPostalCode(false)
    }
  }
  return (
    <>
      <div className="col-xl-8">
        <InputText
          id={`${idStreet ? idStreet : 'streetAddress'}`}
          name={`${idStreet ? idStreet : 'streetAddress'}`}
          label="Domicilio"
          placeholder="Domicilio"
          errors={errors}
          register={register}
          maxLength={maxLengthAddress}
        />
      </div>
      <div className="col-xl-4">
        <InputText
          id={`${idPhone}`}
          name={`${idPhone}`}
          label="Teléfono"
          placeholder="Teléfono"
          errors={errors}
          register={register}
          maxLength={maxLengthPhone}
          handleOnChange={handleChangePhone}
          isvalidateNumber={isvalidateNumber}

        />
      </div>
      <div className="col-xl-4">
        <Province
          setValue={setValue}
          errors={errors}
          register={register}
          watchProvinceCode={watchProvinceCode}
          watchProvinceDescription={watchProvinceDescription}
          idCode={idProvinceCode}
          idDescription={idProvinceDescription}
        />
      </div>
      <div className="col-xl-4">
        <TestCommunity
          errors={errors}
          register={register}
          setValue={setValue}
          idDescription={idCommunityDescription}
          idCode={idCommunityCode}
          watchCommunityCode={watchCommunityCode}
          watchCommunityDescription={watchCommunityDescription}
          filterByProvince={filterByProvince}
          watchProvinceCode={watchProvinceCode}

        />
      </div>
      <div className="col-xl-4">
        <InputText
          id={idPostalCode}
          name={idPostalCode}
          label="Código Postal"
          placeholder="Código Postal"
          errors={errors}
          register={register}
          maxLength={maxLengthPostalCode}
          handleOnChange={handleChangePostalCode}
          isvalidateNumberPostalCode={isvalidateNumberPostalCode}
        />
      </div>
    </>
  )
}

export default AddressGroup;