export const checkFieldInputType = (type:number) => {
  let typeName = "";
  switch(type) {
    case 0:
      typeName = "text";
      break;
    case 1: // 
      typeName = "number";
      break;
    case 2: // decimal
      typeName = "number";
      break;
    case 3: // fecha
      typeName = "date";
      break;
    case 4: // checkbox unico
      typeName = "checkbox";
      break;
    case 5: // select
      typeName = "select";
      break;
    case 6: // textarea
      typeName = "textarea";
      break;
    case 7: // grupo checkbox
      typeName = "checkbox";
      break;
    case 8: // grupo radiobutton
      typeName = "radio";
      break;
    case 9: // párrafo
      typeName = "paragraph";
      break;
    default:
      typeName = "";
      break;
  }
  return typeName;
}