import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

// blocks
import SideMenuUser from "../SideMenu/SideMenuUser";
import SideMenuNav from "../SideMenu/SideMenuNav";

// images
// import logo from "../../../img/solimat-logo.svg";
import logo from "../../../img/logoBlanco.png"
import IconUser from "../../../img/icons/svg/botones/user-navbar-blanco.svg";
import IconHamburger from "../../../img/01.svg";
import IconBell from "../../../img/icons/svg/botones/campana-navbar.svg";
import IconBellActive from "../../../img/icons/svg/botones/campana-navbar-punto.svg";
// contexts
import { HeaderContext } from "../../../contexts/HeaderContext";
import { PromptContextProvider } from "../../../contexts/SideMenuUserContext";
import { SignalRContext } from "../../../contexts/SignalRContext";
import "./Header.scss";
import Notices from '../../pages/notices/Notices'
import useDecode from "../../../hooks/useDecode"
import useCountNoticesAndNotifications from '../../../services/notices/useCountNoticesAndNotifications'
import { useGeneralManagementState } from "../../pages/GeneralManagement/generalManagementState";
interface IHeaderProps {
  dataSource: any;
  homePath: string;
  children?: any;
  isCompany?: boolean;
  isImpersonation?: boolean;
}

const Header: React.FC<IHeaderProps> = (Props: IHeaderProps) => {
  const { headerState, setHeaderState } = useContext(HeaderContext);
  const { notification,allUserNotifications,isUpdated} = useContext(SignalRContext);
  const { countNotices, countNotifications, getCounts } = useCountNoticesAndNotifications()
  const { state, dispatch } = useGeneralManagementState();
  const { countNotice } = state
  const aplication = localStorage.getItem('impersonationApp')
  const tfp = useDecode("token")?.tfp || '';
  const tokenResponse = useDecode("token");
  const idUser = tokenResponse?.extension_id;

  useEffect(() => {
    getCounts(idUser)
  }, [countNotice, notification, allUserNotifications,isUpdated]);

  const handleToggleMenu = () => {
    setHeaderState({ sideMenuVisible: true });
  };
  const handleToggleNav = () => {
    setHeaderState({ navMenuVisible: true });
  };
  return (
    <>
      <header className={`header-block ${Props.isCompany ? "company" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header-content">
                <div className="logo-block">
                  <div className="logo-container">
                    <Link to={Props.homePath} replace>
                      <img src={logo} className="logo" alt="solimat" />
                    </Link>

                  </div>
                </div>
                <div className="links-wrapper">
                  <nav className="nav-container d-none d-xl-flex">
                    {Props.isCompany && (
                      <div className="nav_filter">
                        <input type="text" />
                      </div>
                    )}
                    {Props.dataSource.map((item: any, i: any) => {
                      if (item.path === "/notificaciones") {
                        return (
                          <NavLink
                            to={item.path}
                            className="nav_link"
                            activeClassName="selected"
                            key={i}
                          >
                            <span className="nav_link-icon">
                              <img
                                src={
                                  countNotifications >= 1
                                    ? IconBellActive
                                    : IconBell
                                }
                                alt=""
                              />
                            </span>
                            {item.text}
                            {countNotifications >= 1 && (
                              <span className="nav_link-number">
                                ({countNotifications})
                              </span>
                            )}
                          </NavLink>
                        );
                      }
                      return (
                        <NavLink
                          to={item.path}
                          className="nav_link"
                          activeClassName="selected"
                          key={i}>
                          {item.text}
                        </NavLink>
                      );
                    })}
                  </nav>
                  <button
                    className="toggle_btn d-inline-block d-xl-none"
                    onClick={handleToggleNav}
                  >
                    <img src={IconHamburger} alt="" />
                  </button>
                  {!Props.isImpersonation &&
                    <button className="toggle_btn" onClick={handleToggleMenu}>
                      <img src={IconUser} alt="Usuario" />
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>

      </header>
      {countNotices > 0 ?
        <Notices countNotices={countNotices}></Notices>
        : ''}
      <PromptContextProvider>
        <SideMenuUser />
      </PromptContextProvider>
      <SideMenuNav />
      {
        aplication ? <p className="p-aplication-ext">PORTAL PACIENTES DESDE {aplication} {tfp}</p> : ''
      }
    </>
  );
};

export default Header;
