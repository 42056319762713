import React, {useState} from 'react';
import { management } from "@solimat/solimat-web-endpoint";

const useGetAllAuthorizations = () => {
  const [loadingGetAllAuthorizations, setLoadingGetAllAuthorizations] = useState(false);
  const [successGetAllAuthorizations, setSuccessGetAllAuthorizations] = useState(false);
  const [errorGetAllAuthorizations, setErrorGetAllAuthorizations] = useState(null);
  const [allGetAllAuthorizations, setAllGetAllAuthorizations] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_MANAGEMENT_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MANAGEMENT_KEY || "";
  const apiManagement = new management.ManagementService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  const getAllAuthorizations = (_cb?:(data:any)=> void) => {
    apiManagement.getAllAuthorizations()
    .then((res:any) => {
        setAllGetAllAuthorizations(res?.data);
      setLoadingGetAllAuthorizations(true);
      setSuccessGetAllAuthorizations(true);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any) => {
      console.log(err)
      setLoadingGetAllAuthorizations(false);
      setErrorGetAllAuthorizations(err)
    })
  }
  
  return {
    allGetAllAuthorizations,
    getAllAuthorizations,
    loadingGetAllAuthorizations,
    successGetAllAuthorizations,
    errorGetAllAuthorizations

  }
}

export default useGetAllAuthorizations;