import { useState } from 'react';
import Delta from '@solimat/solimat-web-endpoint/lib/Delta';
import { guid } from '@solimat/solimat-web-endpoint/lib/Delta/dto';

const useGetCompletePatByUser = () => {
  const BASE_URL = process.env.REACT_APP_DELTA_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_DELTA_KEY || "";
  const apiDelta = new Delta.Pat(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetCompletePatByUser,setLoadingGetCompletePatByUser] = useState(false);
  const [successGetCompletePatByUser,setSuccessGetCompletePatByUser] = useState(false);
  const [errorGetCompletePatByUser,setErrorGetCompletePatByUser] = useState(false);
  
  const getCompletePatByUser = async(Id:guid)=> {
    setLoadingGetCompletePatByUser(true);
    try 
    {
      const response = await apiDelta.getCompletePatByUser(Id)
        setLoadingGetCompletePatByUser(false);
        setSuccessGetCompletePatByUser(true);
        setErrorGetCompletePatByUser(false);
        return response.data;
    } 
    catch (err) {
      setLoadingGetCompletePatByUser(false)
      setSuccessGetCompletePatByUser(false);
      setErrorGetCompletePatByUser(true);
      return false;
    }

  }

  return {
    getCompletePatByUser,
    loadingGetCompletePatByUser,
    successGetCompletePatByUser,
    errorGetCompletePatByUser
  }

}

export default useGetCompletePatByUser;