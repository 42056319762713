import {useState} from 'react';

import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetCentersByProvince = () => {
  const BASE_URL = process.env.REACT_APP_RED_CENTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_RED_CENTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY)
  const [successGetCenter, setSuccessGetCenter] = useState<boolean>(false)
  const [errorGetCenter, setErrorGetCenter] = useState<boolean>(false)
  const [loadingGetCenter, setLoadingGetCenter] = useState<boolean>(false)



  const getCenter = async (centroId:string) => {
    setLoadingGetCenter(true)
    try {
      const response = await apiUtils.getCenter(centroId);
      setSuccessGetCenter(true)
      setErrorGetCenter(false)
      setLoadingGetCenter(false)

      return response.data;
    }
    catch(err) {
      console.error(err);
      setLoadingGetCenter(false)
      setSuccessGetCenter(false)
      setErrorGetCenter(true)
    }
  }

  return { 
    getCenter,
    successGetCenter,
    errorGetCenter,
    loadingGetCenter
  };
}

export default useGetCentersByProvince;