import React, { useEffect} from 'react';
interface IInputProps {
  errors: any,
  register: any
  setValue?:any
  watch?:any
}

const RadioCheckGroup:React.FC<IInputProps> = ({errors, register,setValue, watch}) => {

  useEffect(() => {
   
    setValue('deltaPart', 'true');
  }, [setValue]);
  const handleChange = (value: string) => {
    setValue('deltaPart', value);
  
  };
  return (
    <fieldset className="input-block input-block--fieldset">
      <legend className="input-block--fieldset__legend">Parte Delta:</legend>
      <div className="input-block--radio-check__group d-flex">
        <label htmlFor="deltaPartAccept" className="input-block--radio-check__label">
          <span className="text">Aceptado</span>
          <input
            type="radio"
            name="deltaPart"
            id="deltaPartAccept"
            value="true"
            checked={watch('deltaPart') === 'true'}
            onChange={() => handleChange('true')}
            {...register('deltaPart')}
           
          />
        </label>
        <label htmlFor="deltaPartDeny" className="input-block--radio-check__label">
          <span className="text">Rechazado</span>
          <input
            type="radio"
            name="deltaPart"
            id="deltaPartDeny"
            value="false"
            checked={watch('deltaPart') === 'false'}
            onChange={() => handleChange('false')}
            {...register('deltaPart')}
          />
        </label>
      </div>
    </fieldset>
  )
}

export default RadioCheckGroup;