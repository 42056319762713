import React, { useEffect } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CompleteRATSBResponse } from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import InputText from "components/blocks/Delta/Inputs/InputText";
import Province from "components/blocks/Delta/Inputs/Province";
import Month from "components/blocks/Delta/Inputs/Month";
import Year from "components/blocks/Inputs/Year";
import TestCommunity from "components/blocks/Delta/Inputs/TestCommunity";
import CnaeContributionAtEp from "components/blocks/Delta/Inputs/CnaeContributionAtEp";
import MainEconomicActivityCode from "components/blocks/Delta/Inputs/MainEconomicActivityCode";
import FieldsetContainer from "components/blocks/Delta/Inputs/Containers/FieldsetContainer";
import MainEconomicActivityCodeCNAE from "../Inputs/MainEconomicActivityCodeCNAE";
import InputDate from "../Inputs/InputDate";

interface IDeltaWithoutLeaveForm {
  handleSubmit: any;
  reset: any;
  watch: any;
  register: any;
  setValue: any;
  getValues: any;
  submitForm: (values: any) => void;
  errors: any;
  completeRatsbByUser: CompleteRATSBResponse | null;
  handleChange: (e: any) => void;
  watchProvinceCode: any;
  watchProvinceDescription: any;
  watchCnaeCode: any;
  watchCommunityCode: string;
  watchCommunityDescription: string;
  remesed: boolean;
}

const DeltaWithoutLeaveDetailForm: React.FC<IDeltaWithoutLeaveForm> = ({
  handleSubmit,
  reset,
  watch,
  register,
  setValue,
  getValues,
  submitForm,
  errors,
  completeRatsbByUser,
  handleChange,
  watchProvinceCode,
  watchProvinceDescription,
  watchCnaeCode,
  watchCommunityCode,
  watchCommunityDescription,
  remesed,
}) => {
  const Estado: number = completeRatsbByUser?.Estado;
  useEffect(() => {
    // console.log(`Errores VALIDACION:`, errors)
  }, [errors]);
  if (completeRatsbByUser === null) return <></>;
  return (
    <>
      <form
        className="row form--simple-styled"
        onSubmit={handleSubmit(submitForm)}
      >
        {!remesed && (
          <div className="col-12">
            {Estado > 3 && (
              <div className="col-xl-6 col-md-6">
                <InputText
                  id="numReference"
                  name="numReference"
                  label="Nº de referencia Delt@"
                  placeholder="Nº de referencia Delt@"
                  errors={errors}
                  readonly={true}
                  register={register}
                  defaultValue={completeRatsbByUser.Cabecera.NumeroReferencia}
                />
              </div>
            )}
          </div>
        )}
        <div className="col-md-12" style={{padding: '0px'}}>
        {Estado === 6 && (
        <div className="col-xl-3 col-md-6">
          <InputText
            id="extinguish"
            name="extinguish"
            label="Estado"
            placeholder="Extinguido"
            errors={errors}
            readonly={true}
            register={register}
            defaultValue={Estado === 6 ? "Extinguido" : undefined}
          />
        </div>
      )}
        </div>
        <div className="col-xl-4 mb-xl-0">
          <FieldsetContainer legend="Fecha de la Relación" disabled={remesed}>
            <div className="col-xl-6">
              <Month
                id="month"
                label="Mes"
                placeholder="Mes"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-6">
              <Year id="year" label="Año" register={register} errors={errors} defaultValue={parseInt(completeRatsbByUser.Cabecera.Anyo)} />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-xl-8">
          <FieldsetContainer legend="Datos de la empresa" disabled={remesed}>
            <div className="col-xl-4">
              <InputText
                id="cif"
                name="cif"
                label="CIF o NIF/NIE"
                placeholder="CIF o NIF/NIE"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-8">
              <InputText
                id="reason"
                name="reason"
                label="Nombre o Razón Social:"
                placeholder="Nombre o Razón Social:"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12 mt-3">
          <FieldsetContainer
            legend="Datos del Centro de Trabajo"
            disabled={remesed}
          >
            <p className="col-12">
              (Se refieren a los datos del Código Cuenta Cotización en la que
              está inscrito el trabajador accidentado, cuando el accidentado es
              un trabajador autónomo se cumplimentará el NAF)
            </p>
            {/* <div className="col-xl-3">
            <InputText
              id="ccc"
              name="ccc"
              label="Cód. Cuenta Cotización"
              placeholder="Cód. Cuenta Cotización"
              errors={errors}
              register={register}
            />
          </div> */}
            <div className="col-xl-3">
              <InputText
                id="naf"
                name="naf"
                label="NAF"
                placeholder="NAF"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-3">
              <InputText
                id="workforce"
                name="workforce"
                label="Plantilla"
                placeholder="Plantilla"
                errors={errors}
                register={register}
                readonly
              />
            </div>
            <div className="col-xl-6">
              <MainEconomicActivityCodeCNAE
                errors={errors}
                register={register}
                setValue={setValue}
                watchCnaeCode={watchCnaeCode}
                // label="Código Actividad Económica Principal"
                idCode="cnaeContributionAtEpCode"
                idDescription="cnaeContributionAtEpDescription"
              />
            </div>

            <div className="col-xl-6">
              <Province
                setValue={setValue}
                errors={errors}
                register={register}
                watchProvinceCode={watchProvinceCode}
                watchProvinceDescription={watchProvinceDescription}
                idCode="province"
                idDescription="province_description"
              />
            </div>
            <div className="col-xl-6">
              <TestCommunity
                errors={errors}
                register={register}
                setValue={setValue}
                idCode="communityCode"
                idDescription="communityDescription"
                filterByProvince={true}
                watchProvinceCode={watchProvinceCode}
                watchCommunityCode={watchCommunityCode}
                watchCommunityDescription={watchCommunityDescription}
              />
            </div>
            <div className="col-xl-6">
              <InputText
                id="textMainEconomicActivityCode"
                name="textMainEconomicActivityCode"
                label="Texto de Actividad Económica Principal"
                placeholder="Texto de Actividad Económica Principal"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12 mt-3">
          <FieldsetContainer legend="Actores implicados" disabled={remesed}>
            <div className="col-12">
              <InputText
                id="representativeCompleteName"
                name="representativeCompleteName"
                label="Nombre completo"
                placeholder="Nombre y apellidos"
                errors={errors}
                register={register}
              />
              <div className="d-flex align-items-center">
                <label htmlFor="representative" className="input-block__label">
                  En calidad de
                </label>
                <div className="input-block mb-0 ml-2 mr-2">
                  <input
                    type="text"
                    name="representative"
                    id="representative"
                    placeholder={"Representante"}
                    {...register("representative")}
                  />
                  {errors && errors ? (
                    <p className="error m-0 ">
                      {errors && errors["representative"]?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <label
                  htmlFor="representativeLocation"
                  className="input-block__label"
                >
                  de la empresa, expide el presente parte en{" "}
                </label>
                <div className="input-block mb-0 ml-2 mr-2">
                  <input
                    type="text"
                    name="representativeLocation"
                    id="representativeLocation"
                    placeholder={"ciudad"}
                    {...register("representativeLocation")}
                  />
                  {errors && errors ? (
                    <p className="error m-0 ">
                      {errors && errors["representativeLocation"]?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "21px",
                  }}
                >
                  {/* <label htmlFor="acceptation_date">a:</label> */}
                  <InputDate
                    name="submissionDate"
                    id="submissionDate"
                    register={register}
                    errors={errors}
                  ></InputDate>
                </div>
              </div>
              {/* </FieldsetContainer> */}
            </div>
          </FieldsetContainer>
        </div>
      </form>
    </>
  );
};

export default DeltaWithoutLeaveDetailForm;
