import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import LoaderSubmitBtn from 'components/blocks/Buttons/LoaderSubmitBtn';

import DateDouble from 'components/blocks/Inputs/DateDouble';
import RadioCheckGroup from 'components/blocks/Inputs/RadioCheckGroup';
import Cnae from 'components/blocks/Searchers/Delta/Cnae/Cnae'; 

import "../Forms.scss";

interface ISearcherWithLeaveRemitted {
  leaveDateFrom: Date,
  leaveDateTo: Date,
  parte_delta: string, // radio button: aceptado - rechazado
  workerData: IWorkerData,
  cnae_code: string,
  cnae_description: string
}
interface IWorkerData {
  name: string,
  surname: string,

}

interface IProps {
  handleFilterRemittedPat: (data:any)=> void
  loading: boolean
}

const SearcherWithLeaveRemitted:React.FC<IProps> = ({handleFilterRemittedPat, loading}) => {
  const { handleSubmit, reset, watch,  formState: { errors },  register,setValue } = useForm()
  const watchCnae = watch('cnaeCode')

  return (
    <form className="container form--simple-styled searcher" onSubmit={handleSubmit(handleFilterRemittedPat)}>
      <fieldset className="row">
        <div className="col-xl-6">
          <DateDouble
            register={register}
            errors={errors}
            label="Fecha de baja"
          />
        </div>
        <div className="col-xl-3">
          <RadioCheckGroup
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        </div>
      </fieldset>
      <fieldset className="row">
        <div className="col-xl-6">
          <div className="input-block">
            <label htmlFor="worker" className="input-block__label">Datos del trabajador:</label>
            <input type="text" name="worker" id="worker" placeholder={"Introduzca nombre, apellidos, DNI o Nº Afiliación del trabajador"} {...register("worker")}/>
          </div>
        </div>
      
          <Cnae
            register={register}
            errors={errors}
            watchCnae={watchCnae}
          />
       
        <div className="col-12">
          <div className="input-block">
            <label htmlFor="company" className="input-block__label">Empresa (CIF-Razón Social):</label>
            <input type="text" name="company" id="company" placeholder={"Empresa (CIF-Razón Social)"} {...register("company")}/>
          </div>
        </div>
      </fieldset>  
      <div className="row">
        <div className="col-12">
          <div className="btns-container c-right">
            <input className="btn-box btn-clear" type="button" value="Borrar"
              onClick={()=>reset()}
            />
            <LoaderSubmitBtn
              loading={loading}
              text='Buscar'
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default SearcherWithLeaveRemitted;
