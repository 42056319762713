import React, { useState, useEffect } from "react";

import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconCrossGray from "../../../img/icons/svg/hamburger/cerrar-cruz-gris.svg";

import useCheckRecordStatusAndTypes from "../../../hooks/useCheckRecordStatusAndTypes";

// FICHAS
import EntornoTrabajador from "../../../components/blocks/ManagementSheets/Administracion/EntornoTrabajador";
import ParteAltaBaja from "../../../components/blocks/ManagementSheets/Administracion/ParteAltaBaja";
import ParteConfirmacion from "../../../components/blocks/ManagementSheets/Administracion/ParteConfirmacion";
import ParteSinBaja from "../../../components/blocks/ManagementSheets/Administracion/ParteSinBaja";
import Recobro from "../../../components/blocks/ManagementSheets/Economico/Recobro";
import ReposicionOrtoprotesica from "../../../components/blocks/ManagementSheets/Economico/ReposicionOrtoprotesica";
import IMSResumen from "../../../components/blocks/ManagementSheets/Economico/ImsResumen";
import BaseCotizacionCata from "../../blocks/ManagementSheets/Economico/BaseCotizacionCata";
import ComisionPrestacionesEspeciales from "../../../components/blocks/ManagementSheets/Economico/ComisionPrestacionesEspeciales";
import GastosAsistencial from "../../../components/blocks/ManagementSheets/Economico/GastosAsistencial";
import BaseReguladora from "components/blocks/ManagementSheets/Economico/BaseReguladora";
import GastosJuridico from "components/blocks/ManagementSheets/Economico/GastosJuridico";
import CompensacionSLD from "../../blocks/ManagementSheets/Economico/CompensacionSLD";
import GastosTransporte from "components/blocks/ManagementSheets/Economico/GastosTransporte";
import Regularizacion from "components/blocks/ManagementSheets/Economico/Regularizacion";
import GastosFarmacia from "components/blocks/ManagementSheets/Economico/GastosFarmacia";
import PagoDirecto from "components/blocks/ManagementSheets/Economico/PagoDirecto";
interface IRecordDetailSheets {}

const RecordDetailSheets: React.FC<IRecordDetailSheets> = (props: any) => {
  const detailId = props.match.params.detailId;
  const managementType = parseInt(detailId.split("__")[0]);

  const { checkManagementType } = useCheckRecordStatusAndTypes();

  return (
    <>
      <PageMenu title={checkManagementType(managementType)}>
        <div className="btns-header-container">
          <button onClick={() => props.history.goBack()}>
            <img src={IconCrossGray} alt="icon arrow right" />
          </button>
        </div>
      </PageMenu>
      <div>
        {managementType === 0 && <ParteConfirmacion props={props} />}
        {managementType === 1 && <ParteAltaBaja props={props} />}
        {managementType === 2 && <ParteSinBaja props={props} />}
        {managementType === 3 && <EntornoTrabajador props={props} />}
        {/* {managementType === 4 && <ReclamacionAdministrativa props={props} />} */}
        {/* {managementType === 5 && <Litigio props={props} />} */}
        {managementType === 100 && <BaseCotizacionCata props={props} />}
        {managementType === 101 && <BaseReguladora props={props} />}
        {managementType === 102 && ( <ComisionPrestacionesEspeciales props={props} />)}
        {managementType === 103 && <CompensacionSLD props={props} />}
        {managementType === 104 && <GastosAsistencial props={props} />}
        {managementType === 105 && <GastosFarmacia props={props} />}
        {managementType === 106 && <GastosJuridico props={props} />}
        {managementType === 107 && <GastosTransporte props={props} />}
        {managementType === 108 && <Recobro props={props} />}
        {managementType === 109 && <Regularizacion props={props} />}
        {managementType === 110 && <ReposicionOrtoprotesica props={props} />}
        {managementType === 111 && <IMSResumen props={props} />}
        {managementType === 112 && <PagoDirecto props={props} />}
        {/* {managementType === 9 && <TSDPago props={props} />} */}
        {/* {managementType === 11 && <ResumenCATA props={props} />} */}
      </div>
    </>
  );
};

export default RecordDetailSheets;
