import { useState } from "react";
import SurveyIngestion from "@solimat/solimat-web-endpoint/lib/survey-ingestion";

const useCreateUserSurvey = () => {
  const BASE_URL = process.env.REACT_APP_SURVEY_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_SURVEY_KEY || "";
  const apiSurvey = new SurveyIngestion.SurveyIngestionService(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateUserSurvey, setLoadingCreateUserSurvey] = useState(false);
  const [successCreateUserSurvey, setSuccessCreateUserSurvey] = useState(false);
  const [errorCreateUserSurvey, setErrorCreateUserSurvey] = useState(null);
  
  const createUserSurvey = (data:any, _cb?:()=>void) => {
    setLoadingCreateUserSurvey(true);
    setErrorCreateUserSurvey(null);
    apiSurvey
      .createUserSurvey(data)
      .then((res: any) => {
        setSuccessCreateUserSurvey(true);
        setLoadingCreateUserSurvey(false);
        if (typeof _cb === "function") {
          _cb();
        }
      })
      .catch((err:any) => {
        console.error("createUserSurvey error: ", err);
        setErrorCreateUserSurvey(err);
        setLoadingCreateUserSurvey(false);
        if (typeof _cb === "function") {
          _cb();
        }
      });
  };

  return {
    createUserSurvey,
    successCreateUserSurvey,
    loadingCreateUserSurvey,
    errorCreateUserSurvey
  };
};

export default useCreateUserSurvey;