import React, { createContext, useContext, useReducer } from "react";
const ManagementFormStateContext = createContext();
const initialState = {
  url: '',
  titleForm:''
 
};
function reducer(state, action) {
  switch (action.type) {
    case "SET_URL_FORM":
      return { ...state, url: action.payload };
      case "SET_TITLE_FORM":
      return { ...state, titleForm: action.payload };
    default:
      return state;
  }
}
export const FormStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ManagementFormStateContext.Provider value={{ state, dispatch }}>
      {children}
    </ManagementFormStateContext.Provider>
  );
};

export const useManagementFormState = () => {
  const context = useContext(ManagementFormStateContext);
  if (!context) {
    throw new Error(
      "ManagementFormStateContext debe usarse dentro de un ManagementFormStateContext Provider "
    );
  }
  return context;
};
