import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { navLinksImpersonationHisam, navLinksImpersonationSip, navLinksImpersonationSipCompany, navLinksPatient } from "../../../data/nav.data";
import { navLinksCompany } from '../../../data/nav.data';
import IconCloseNav from "../../../img/icons/svg/hamburger/cerrar-cruz-blanco.svg";
import useLayout from "../../../hooks/useLayout";
import './SideMenu.scss';
import useGetRole, { ROLES } from "hooks/useGetRole";

const SideMenuNav: React.FC = () => {
  const { rolePermision } = useLayout();
  const roleSelected = rolePermision;
  const userRole = useGetRole.getRole();
  const [navLinks, setNavLinks] = useState(navLinksPatient)
  const { headerState, setHeaderState } = useContext(HeaderContext);

  const { isImpersonation, isHisam, isSIP, impersonationAppName } = useGetRole;
  const handleOnClick = () => {
    setHeaderState({ navMenuVisible: false });
  };

  useEffect(() => {
    let navLinks: any = null;
    if (isImpersonation() && !!impersonationAppName()) {
      if (isHisam())
        navLinks = navLinksImpersonationHisam;
      if (isSIP())
        navLinks = userRole === ROLES.PACIENTE ? navLinksImpersonationSip : navLinksImpersonationSipCompany;
    }
    if (!navLinks) {
      navLinks = userRole === ROLES.PACIENTE ? navLinksPatient : navLinksCompany;
    }
    setNavLinks(navLinks);
  }, [headerState])
  return (
    <div
      className={`side-menu-wrapper sideMenuNav
      ${headerState.navMenuVisible ? "" : "collapsed"}
      ${roleSelected !== ROLES.PACIENTE ? 'company' : ''}
      `}
    >
      <div className="bg" onClick={handleOnClick}></div>
      <div className="side-menu_block">
        <div className="btn-close btn-close-white" onClick={handleOnClick}>
          {/* <button onClick={handleOnClick}>
            <img src={IconCloseNav} alt="" />
          </button> */}
        </div>
        <div className="nav-list-container">
          <nav>
            {navLinks.map((item: any, i: number) => {
              return (
                <NavLink
                  className="nav_item"
                  activeClassName="selected"
                  key={i}
                  to={item.path}
                  onClick={handleOnClick}
                >
                  <span className="link-text">{item.text}</span>
                </NavLink>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideMenuNav;
