import { useEffect } from "react";
import { useMsal } from "@azure/msal-react/dist";
import { useIsAuthenticated } from "@azure/msal-react/dist";
import { useHistory } from "react-router-dom";
import { b2cPolicies } from "./useMsalB2C";
const jwtDecode = require('jwt-decode');

export const setToken = () => {
  let ok = false;
  let tokenSecret: any = {};
  for (let i = 0; i < sessionStorage.length && !ok; i++) {
    const keyStorage = sessionStorage.key(i);
    if (keyStorage.indexOf("idtoken") > 0) {
      const valueStorage = sessionStorage.getItem(
        `${keyStorage}`
      );
      const valueStorageToJson = JSON.parse(
        `${valueStorage}`
      );
      if (valueStorageToJson.secret) {
        tokenSecret = valueStorageToJson.secret;
        ok = true;
      }
    }
  }
  const token = JSON.stringify(tokenSecret);
  localStorage.setItem("token", token);
  if (token) {
    let token = localStorage.getItem("token");
    let decoded = jwtDecode(token);
    let userRole = decoded && decoded.extension_roles;
    localStorage.setItem("role", userRole);
    const isImp = checkIsImpersonation(decoded);
    if (isImp) {
      localStorage.setItem("isImpersonation", isImp.toString());
      localStorage.setItem("impersonationApp", decoded.impersonationApp ?? null);
    }
  }
}
export const checkIsImpersonation = (decodedToken : any) =>{
  return !!decodedToken.impersonationApp || decodedToken.tfp == b2cPolicies.names.imp;
}
export const useSetToken = () => {

  const { instance } = useMsal();
  const isAuthenticate = useIsAuthenticated();
  const currentAccount = instance.getActiveAccount();
  useEffect(() => {
    if (currentAccount && isAuthenticate) {
      setToken();
      // localStorage.setItem("role", rolePrueba);
      // localStorage.setItem("allMembership", allMembershipPrueba);
    } else {
      // history.push("/inicio")
      // localStorage.setItem("token", tokenPrueba);
      // localStorage.setItem("role", rolePrueba);
      // localStorage.setItem("allMembership", allMembershipPrueba);
    }
  }, [instance, isAuthenticate]);
};