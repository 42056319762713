import React, {useState} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  PageSettingsModel,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-grids";

interface ICommunicationsGrid {
  arrData: any[] | undefined,
  props:any,
  recordType?:number,
  isLoading?: boolean
}

const RecordsGridHome:React.FC<ICommunicationsGrid> = ({arrData, props, recordType}) => {
  const [sortingOptions] = useState<Object>({ columns: [{ field: 'fechaInicio' }] })
  let pageOptions: PageSettingsModel = {
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  };
  const rowSelected = (args: any) => {
    props.history.push({
      pathname: `/comunicaciones/${args.data.Id}/comunicacion-general`,
      subject: 'Detalle',
      communicationId: args.data.Id
    });
  };
  const arrData2 = arrData.length >= 4 ? arrData.slice(0,4): arrData

  return (
    <GridComponent
      dataSource={arrData2}
      allowPaging={false}
      allowSorting={false}
      className='grid-record-home'
    >
      <ColumnsDirective>
        <ColumnDirective
          field="descripcionContingencia"
          headerText="Contingencia"
          textAlign="Left"
          width="300"
        ></ColumnDirective>
        <ColumnDirective
          field="fechaInicio"
          headerText="Fecha"
          type="date"
          format="dd/MM/yyyy"
          textAlign="Left"
          width="100"
        ></ColumnDirective>
      </ColumnsDirective>
      <Inject services={[Page, Sort]} />
    </GridComponent>
  )
}
export default RecordsGridHome;