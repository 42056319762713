import { useState } from "react";
import Utils from "@solimat/solimat-web-endpoint/lib/utils";

const useGetAllProvinceUser = () => {
  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";
  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingGetAllProvinceUser, setLoadingGetAllProvinceUser] = useState(false);
  const [successGetAllProvinceUser, setSuccessGetAllProvinceUser] = useState(false);
  const [errorGetAllProvinceUser, setErrorGetAllProvinceUser] = useState(null);
  const [allProvinceUser, setAllProvinceUser] = useState<null|any>(null);

  const getAllProvinceUser = async () => {
    setLoadingGetAllProvinceUser(true);
    setErrorGetAllProvinceUser(null);
    try {
      const res = await apiUtils.getAllProvinceUser()
        setAllProvinceUser(res.data)
        setLoadingGetAllProvinceUser(false);
        setSuccessGetAllProvinceUser(true);
        return res.data;
        
      }
      catch(err) {
        console.error("getAllProvinceUser error: ", err);
        setErrorGetAllProvinceUser(err);
        setLoadingGetAllProvinceUser(false);
      }
  };

  return {
    allProvinceUser,
    getAllProvinceUser,
    successGetAllProvinceUser,
    loadingGetAllProvinceUser,
    errorGetAllProvinceUser
  };
};

export default useGetAllProvinceUser;
