import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import './PageMenu.scss';

interface PageMenuProps {
  type?: string,
  title?: string,
  children?: any,
  breadcrumbs?: string[]
  params?:any
  level?: number

}

const PageMenu: React.FC<PageMenuProps> = (Props: PageMenuProps) => {
  const history = useHistory();
  const [arrPaths, setArrPaths] = useState<any[]>([])
  const location = useLocation();
  const locationPaths = location.pathname.split("/");
  const style: any = Props.level ? { 'paddingLeft': 15 * Props.level, 'marginLeft': -15 * Props.level, 'marginRight': -15 * Props.level, 'fontSize': (1-(Props.level/4))+'em'}  : {};
  locationPaths.shift();
  useEffect(() => {
    getPaths()
  }, [Props.breadcrumbs])
  const getPaths = () => {
    let breadcrumbsPaths:any[] = [];
    let locations:string = "" ;
    for (let i=0;i<locationPaths.length;i++) {
      if(locationPaths.length === Props.breadcrumbs?.length) {
        locations = `${locations}/${locationPaths[i]}`
        breadcrumbsPaths.push(
          {
            label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
            path: locations
          }
         
        )
        
      } else {
        if(Props.breadcrumbs)
        if(Props.breadcrumbs[i+1]) {
          locations =  `${locations}/${locationPaths[i]}`
          breadcrumbsPaths.push(
            {
              label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
              path:  locations

            }
          )
         
        } else {
          locations = `${locations}/${locationPaths[i]}/${locationPaths[i+1]}`
          breadcrumbsPaths.push(
            {
              label: Props.breadcrumbs? Props.breadcrumbs[i] : Props.title?Props.title:"none" ,
              path: locations
            }
          )
          break;
        }  
      }
    }
    setArrPaths(breadcrumbsPaths)

  }
  const handleRedirect = (path:string,params:any) => {
    if(params) {
      const obj = {
        pathname: path,
        ...params
      }
      history.push(obj)
    
    } else {
      history.push(path)
     
    }

  }

  return (
  <div className={`page-menu ${Props.type ? Props.type : ""}`}>
    <div className="container">
      <div className="row" 
         >
        <div className="col">
          {(arrPaths && Props.breadcrumbs) &&
          <p className="breadcrumbs">
            <Link className="breadcrumb-link" to="/inicio">Inicio</Link>
            { arrPaths.map((item:any, index:number) => {

              const params = Props.params?Props.params[index]:null
              return (
                <span key={index} className="breadcrumb-container">
                  <span className="breadcrumb-separator">{'>'}</span>
                  {/* <Link className="breadcrumb-link" to={item.path}>{item.label}</Link> */}
                  <a onClick={()=>handleRedirect(item.path, params)} className="breadcrumb-link">{item.label}</a>
                </span>
              )
            })}
          </p>
          }
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="content-menu" style={style}>
            <h1>{Props.title}</h1>
            {Props.children}
          </div>
        </div>
      </div>
    </div>
  </div>
)}

export default PageMenu;