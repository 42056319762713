import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

import PageMenu from "../../blocks/PageMenu/PageMenu";
import Modal from "components/blocks/Modal/Modal";
import FormValidateOTP from "components/blocks/Forms/FormValidateOTP";
import useCheckTypes from "../../../hooks/useCheckRecordStatusAndTypes";
import useModal from "hooks/useModal";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";

import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import HealthcareDocumentsGrid from "../../blocks/Grids/HealthcareDocumentsGrid";
import RecordDetailManagementGrid from "../../blocks/Grids/RecordDetailManagementGrid";
import RecordDetailVisitGrid from "../../blocks/Grids/RecordDetailVisitGrid";
import useGetAllHealthcareInformation from "../../../services/recordSheets/useGetAllHealthcareInformation";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import {
  VisitResponse,
  DocumentResponse,
  guid,
} from "@solimat/solimat-web-endpoint/lib/record/dto";
import useDecode from "hooks/useDecode";
import useGetEpisodeConsultation from "services/recordSheets/useGetEpisodeConsultation";
import useGetEpisodeRehabilitation from "services/recordSheets/useGetEpisodeRehabilitation";
import useGetEpisodeOperatingRoom from "services/recordSheets/useGetEpisodeOperatingRoom";
import useGetEpisodeHospitalization from "services/recordSheets/useGetEpisodeHospitalization";
import useGetEpisodeImage from 'services/recordSheets/useGetEpisodeImage'
import useGetEpisodeEmergencies from "../../../services/recordSheets/useGetEpisodeEmergencies";
import useGetAllHealthcareDocument from "services/record/useGetAllHealthcareDocument";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import Accident from 'components/blocks/Delta/Forms/Accident';
import NoData from "../noData/NoData";

const RecordDetailVisit: React.FC = (props: any) => {
  const [allDocuments, setAllDocuments] = useState(
    props.history.location?.allHealthcareDocuments
  );

  const typeSelected: string = props.history.location?.filter?.type;
  const typeId: string = props.history.location?.filter?.type_id;
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const recordType = arrParamsId[0];
  const recordId = arrParamsId[1];
  const contingencyType = arrParamsId[2];
  const [type, setType] = useState(typeSelected ? typeSelected:'Consultas');
  const [type_id, setType_id] = useState(window.localStorage.getItem('typeId'));
  const [visits, setVisits] = useState<VisitResponse[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const {
    getAllHealthcareDocument,
  } = useGetAllHealthcareDocument();
  // const {
  //   getAllHealthcareInformation,
  //   loadingAllHealthcareInformation,
  //   successAllHealthcareInformation,
  //   errorAllHealthcareInformation,
  //   dataAllHealthcareInformation,
  // } = useGetAllHealthcareInformation();
const {
episodeImage,
errorGetEpisodeImage,
getEpisodeImage,
loadingGetEpisodeImage,
successGetEpisodeImage
  
}=useGetEpisodeImage()
  const {
    episodeConsultation,
    getEpisodeConsultation,
    loadingGetEpisodeConsultation,
  } = useGetEpisodeConsultation();
  const {
    episodeRehabilitation,
    getEpisodeRehabilitation,
    loadingGetEpisodeRehabilitation,
  } = useGetEpisodeRehabilitation();
  const {
    episodeEmergencies,
    getEpisodeEmergencies,
    loadingGetEpisodeEmergencies,
  } = useGetEpisodeEmergencies();
  const {
    episodeOperatingRoom,
    getEpisodeOperatingRoom,
    loadingGetEpisodeOperatingRoom,
  } = useGetEpisodeOperatingRoom();
  const {
    episodeHospitalization,
    getEpisodeHospitalization,
    loadingGetEpisodeHospitalization,
  } = useGetEpisodeHospitalization();
  const {
    checkEpisodeType,
    checkDocumentType,
    checkContingencyType,
  } = useCheckTypes();
  const {
    collapsed,
    handleOpenModal,
    handleCloseModal,
    disabledCloseButton,
    handleDisableCloseButton,
  } = useModal();
  const {
    getRequestDocumentOtp,
    errorGetRequestDocumentOtp,
  } = useGetRequestDocumentOtp();
  const currentTypeEpisode = window.localStorage.getItem('typeEpisode')
  const currentTypeId =  window.localStorage.getItem('typeId')
  const [documentID, setDocumentID] = useState<string | guid>("");
  const title = currentTypeEpisode 
  const breadcrumbs = [
    "Historial",
    checkContingencyType(parseInt(contingencyType)),
    title,
  ];


  useEffect(() => {
    if (currentTypeEpisode) {
      setDataVisits(currentTypeEpisode)
    }
    
    // if (
    //   props.history.location?.filter?.type_id === null ||
    //   props.history.location?.filter?.type_id === undefined
    // ) {
    //   setType_id("200");
    //   setType("Consultas");
    // }

    if (typeof allDocuments === "undefined") {
      getAllHealthcareDocument(userId, recordId, setAllDocuments);
    }
   
  
    getEpisode(currentTypeEpisode);

    // getAllHealthcareInformation(userId,recordId);
  
  }, [type, type_id,currentTypeEpisode]);
  const getEpisode = (type: string) => {
    
    switch (type) {
      case 'Urgencias':
        return getEpisodeEmergencies(userId, recordId);
      case 'Consultas':
        return getEpisodeConsultation(userId, recordId);
      case 'Rehabilitación':
        return getEpisodeRehabilitation(userId, recordId);
      case 'Quirófano':
        return getEpisodeOperatingRoom(userId, recordId);
      case 'Hospitalización':
        return getEpisodeHospitalization(userId, recordId);
      case 'Imagen':
        return getEpisodeImage(userId, recordId);
      default:
        setVisits([])
        // return getEpisodeConsultation(userId, recordId);
    }
  };

  useEffect(() => {
    if (episodeConsultation !== null) {
      setDataVisits(currentTypeEpisode);
    }
    if (episodeRehabilitation !== null) {
      setDataVisits(currentTypeEpisode);
    }
    if (episodeOperatingRoom !== null) {
      setDataVisits(currentTypeEpisode);
    }
    if (episodeHospitalization !== null) {
      setDataVisits(currentTypeEpisode);
    }
    if (episodeEmergencies !== null) {
      setDataVisits(currentTypeEpisode);
    }
    if (allDocuments !== undefined) {
      setDataVisits(currentTypeEpisode);
    }
    if (episodeImage !== undefined) {
      setDataVisits(currentTypeEpisode);
    }
  }, [
    episodeConsultation,
    episodeRehabilitation,
    episodeOperatingRoom,
    episodeHospitalization,
    episodeEmergencies,
    episodeImage,
    type
  ]);

  const setDataVisits = (type:string) => {
    if (type === "Consultas" ) {
      setVisits(episodeConsultation);
      setDocuments([]);
    }
    if (
      type === "Rehabilitación" 
    ) {
      setVisits(episodeRehabilitation);
      setDocuments([]);
    }
    if (
      type === "Quirófano"
    ) {
      setVisits(episodeOperatingRoom);
      setDocuments([]);
    }
    if (
      type === "Hospitalización"
    ) {
      setVisits(episodeHospitalization);
      setDocuments([]);
    }
    if (
      type === "Imagen" 
    ) {
      setVisits(episodeImage);
      setDocuments([]);
    }
    if (type === "Urgencias" ) {
      setVisits(episodeEmergencies);
      setDocuments([]);
     
    } else if (type === "Documentación asistencial" && allDocuments) {
     
      setVisits([]);
      setDocuments(allDocuments);
  
    }
    Moment().locale("es").format("L");
  };

  const handleSelectTab = (type: string, visitType: string) => {
    
    setType(checkEpisodeType(parseInt(visitType)));
    setType_id(visitType);
    setDataVisits(currentTypeEpisode)
    window.localStorage.setItem('typeId', visitType);
    window.localStorage.setItem('typeEpisode', type);
  
  };

  if (type === undefined || type === null) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeConsultation) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeRehabilitation) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeOperatingRoom) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeHospitalization) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeEmergencies) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  if (loadingGetEpisodeImage) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  const handleRequestDocument = (documentID: string) => {
    setDocumentID(documentID);
    handleOpenModal();
    getRequestDocumentOtp(userId, recordId, documentID);
  };
  return (
    <>
      <PageMenu title={title} type="bottom-box-info" breadcrumbs={breadcrumbs}>
        <div className="btns-header-container">
          <Link to={`/historial/${recordType}_${recordId}`}>
            <img src={IconArrowGray} alt="icon arrow right" />
          </Link>
        </div>
      </PageMenu>
      <div className="container">
        <section className="row">
          {" "}
          {currentTypeEpisode ? (
            <>
              <div className="  order-sm-1 col-sm-12 col-md-3 order-md-2  col-lg-3  mb-4 ">
                <div className="tab-menu">
                  <button
                    className={`tab-menu__btn visit col-6 col-sm-6  col-md-12 ${
                      currentTypeEpisode === "Consultas" && type_id === "200" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Consultas", "200")}
                  >
                    Consultas
                  </button>
                  <button
                    className={`tab-menu__btn rehab col-6 col-sm-6 col-md-12  ${
                      currentTypeEpisode === "Rehabilitación" && type_id === "205" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Rehabilitación", "205")}
                  >
                    Rehabilitación
                  </button>
                  <button
                    className={`tab-menu__btn operating  col-6 col-sm-6 col-md-12 ${
                      currentTypeEpisode === "Quirófano" && type_id === "204" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Quirófano", "204")}
                  >
                    Quirófano
                  </button>
                  <button
                    className={`tab-menu__btn hospitalization col-6 col-sm-6 col-md-12 ${
                      currentTypeEpisode === "Hospitalización" && type_id === "202" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Hospitalización", "202")}
                  >
                    Hospitalización
                  </button>
                  <button
                    className={`tab-menu__btn emergencies col-6 col-sm-6 col-md-12  ${
                      currentTypeEpisode === "Urgencias" && type_id === "206" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Urgencias", "206")}
                  >
                    Urgencias
                  </button>
                  <button
                    className={`tab-menu__btn documents col-6 col-sm-6 col-md-12 ${
                      currentTypeEpisode === "Documentación asistencial" && type_id === "207" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Documentación asistencial", "207")}
                  >
                    Documentación asistencial
                  </button>
                  <button
                    className={`tab-menu__btn image col-6 col-sm-6 col-md-12 ${
                      currentTypeEpisode === "Imagen" && type_id === "203" ? "active" : ""
                    }`}
                    onClick={() => handleSelectTab("Imagen", "203")}
                  >
                    Imagen
                  </button>
                </div>
              </div>
              <div className="col-sm-12 order-sm-2 col-md-9 order-md-1 ">
                {currentTypeEpisode === "Consultas" || currentTypeEpisode === "Urgencias" || currentTypeEpisode === "Rehabilitación" || currentTypeEpisode === "Quirófano" || currentTypeEpisode === "Imagen" || currentTypeEpisode === "Hospitalización"? (
                  visits && visits.length >= 1 ? (
                    <div className="record-detail-visit__grid">
                      <RecordDetailVisitGrid
                        arrData={visits && visits}
                        type_id={parseInt(currentTypeId)}
                        recordType={recordType}
                        props={props}
                        contingencyType={contingencyType}
                      />
                    </div>
                  ) : (
                    // <p className="m-4 loader-text">No hay datos registrados77777.</p>
                    <NoData message={'No hay datos registrados'}></NoData>
                  )
                ) : currentTypeEpisode === "Documentación asistencial" && documents && documents.length >= 1 ? (
                  <HealthcareDocumentsGrid
                    handleRequestDocument={handleRequestDocument}
                    documents={documents.map((doc: any) => {
                      return {
                        nombre: doc.nombre,
                        extension: doc.extension,
                        company: doc.company ?? "-",
                        fecha: doc.fecha,
                        id: doc.id,
                        requiereOtp: doc.requiereOtp,
                        uri: doc.uri,
                      };
                    })}
                  />
                ) : (
                  // <p className="m-4 loader-text ">No hay documentos para mostrar.</p>
                  <NoData message={'No hay documentos para mostrar.'}/>
                )}
              </div>
            </>
          ) : (
            <div className="col-12" >
              <div className="link-rows__grid">
                {/* GESTIÓN: Administrativo/Económico */}
                {documents.length >= 1 ? (
                  <RecordDetailManagementGrid
                    arrData={documents}
                    props={props}
                  />
                ) : (
                  <p className="loader-text m-4">No hay datos para mostrar.</p>
                )}
              </div>
            </div>
          )}
        </section>
        <Modal
          disabledCloseButton={disabledCloseButton}
          collapsed={collapsed}
          onClose={handleCloseModal}
          title={"Introduzca el código"}
          subtitle={"Para visualizar el documento, introduzca el código que hemos enviado a su teléfono"}
        >
          {collapsed ? (
            <div className="ghost-form"></div>
          ) : (
            <>
              {documentID ? (
                <FormValidateOTP
                  handleDisableCloseButton={handleDisableCloseButton}
                  onClose={handleCloseModal}
                  props={props}
                  documentID={documentID}
                  userId={userId}
                  prestacionId={recordId}
                />
              ) : (
                <>
                  {errorGetRequestDocumentOtp ? (
                    <div>
                      <h3 className="modal_title">Error</h3>
                      <p className=" loader-text m-4">
                        Lo sentimos, se ha producido un error
                      </p>
                    </div>
                  ) : (
                    <LoaderMin/>
                  )}
                </>
              )}
            </>
          )}
        </Modal>
      </div>
    </>
  );
};
export default RecordDetailVisit;
