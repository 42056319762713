import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetRequestDocumentOtp = () => {
  const [loadingGetRequestDocumentOtp, setloadingGetRequestDocumentOtp] = useState(false);
  const [successGetRequestDocumentOtp, setSuccessGetRequestDocumentOtp] = useState(false);
  const [errorGetRequestDocumentOtp, setErrorGetRequestDocumentOtp] = useState(null);
  const [requestDocumentOtp, setRequestDocumentOtp] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);

  const getRequestDocumentOtp = (userId:string, prestacionId: string, documentoId:string, _cb?:(data:any)=>void) => {
    setloadingGetRequestDocumentOtp(true);
    setSuccessGetRequestDocumentOtp(false);
    apiRecord.getRequestDocumentOtp(userId, prestacionId, documentoId)
      .then((res:any) => {        
        setRequestDocumentOtp(res.statusText);
        setloadingGetRequestDocumentOtp(false);
        setSuccessGetRequestDocumentOtp(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setloadingGetRequestDocumentOtp(false);
        setErrorGetRequestDocumentOtp(err)
      })
  }


  return {
    requestDocumentOtp,
    getRequestDocumentOtp,
    loadingGetRequestDocumentOtp,
    successGetRequestDocumentOtp,
    errorGetRequestDocumentOtp
    };
}

export default useGetRequestDocumentOtp;