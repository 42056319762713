import Utils from "@solimat/solimat-web-endpoint/lib/utils"
import { CalculatorAbsenteeismRequest, CalculatorFeesSelfemployeeRequest, OrderRequest, guid } from '@solimat/solimat-web-endpoint/lib/utils/dto/index'
import { Key } from 'ts-key-enum';
const useUtils = () => {

  const BASE_URL = process.env.REACT_APP_MAESTROS_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MAESTROS_KEY || "";

  const apiUtils = new Utils.UtilsService(BASE_URL, FUNCTIONS_KEY);

  const getAllAccidentSeverity = async () => {
    try {
      const res = await apiUtils.getAllAccidentSeverity();
      return res.data;
    } catch (err) {
      console.error("getAllAccidentSeverity", err);
      return false;
    }
  }

  const getBilling = async () => {
    try {
      const res = await apiUtils.getBilling();
      return res.data;
    } catch (err) {
      console.error("getBilling", err);
      return false;
    }
  }

  const calculatorFeesSelfemployee = async (data: CalculatorFeesSelfemployeeRequest) => {
    try {
      const res = await apiUtils.calculatorFeesSelfemployee(data);
      return res.data;
    } catch (err) {
      console.error("calculatorFeesSelfemployee", err);
      return false;
    }
  }

  const calculatorAbsenteeism = async (data: CalculatorAbsenteeismRequest) => {
    try {
      const res = await apiUtils.calculatorAbsenteeism(data);
      return res.data;
    } catch (err) {
      console.error("calculatorAbsenteeism", err);
      return false;
    }
  }

  const getAllCauseOfHigh = async () => {
    try {
      const res = await apiUtils.getAllCauseOfHigh();
      return res.data;
    } catch (err) {
      console.error("getAllCauseOfHigh", err);
      return false;
    }
  }

  const getAllCity = async () => {
    try {
      const res = await apiUtils.getAllCityUser();
      return res.data;
    } catch (err) {
      console.error("getAllCity", err);
      return false;
    }
  }

  const getCityByProvince = async (provinceCode: string) => {
    try {
      const res = await apiUtils.getCityByProvinceUser(provinceCode);
      return res.data;
    } catch (err) {
      console.error("getCityByProvince", err);
      return false;
    }
  }

  const getAllCityUser = async () => {
    try {
      const res = await apiUtils.getAllCityUser();
      return res.data;
    } catch (err) {
      console.error("getAllCityUser", err);
      return false;
    }
  }

  const getFilterCityUser = async (query: string) => {
    try {
      const res = await apiUtils.getFilterCityUser(query);
      return res.data;
    } catch (err) {
      console.error("getFilterCityUser", err);
      return false;
    }
  }

  const getAllCnae = async () => {
    try {
      const res = await apiUtils.getAllCnae();
      return res.data;
    } catch (err) {
      console.error("getAllCnae", err);
      return false;
    }
  }


  const getAllCno = async () => {
    try {
      const res = await apiUtils.getAllCno();
      return res.data;
    } catch (err) {
      console.error("getAllCno", err);
      return false;
    }
  }
  const getAllCnoLetter = async () => {
    try {
      const res = await apiUtils.getAllCnoLetter();
      return res.data;
    } catch (err) {
      console.error("getAllCnoLetter", err);
      return false;
    }
  }
  const getAllCno3Digi = async () => {
    try {
      const res = await apiUtils.getAllCno3Digi();
      return res.data;
    } catch (err) {
      console.error("getAllCno", err);
      return false;
    }
  }

  const getAllContract = async () => {
    try {
      const res = await apiUtils.getAllContract();
      return res.data;
    } catch (err) {
      console.error("getAllContract", err);
      return false;
    }
  }

  const getAllContactForm = async () => {
    try {
      const res = await apiUtils.getAllContactForm();
      return res.data;
    } catch (err) {
      console.error("getAllContract", err);
      return false;
    }
  }


  const getAllCountry = async () => {
    try {
      const res = await apiUtils.getAllCountry();
      return res.data;
    } catch (err) {
      console.error("getAllCountry", err);
      return false;
    }
  }

  const getAllDesviation = async () => {
    try {
      const res = await apiUtils.getAllDesviation();
      return res.data;
    } catch (err) {
      console.error("getAllDesviation", err);
      return false;
    }
  }

  const getAllInjuryPartOfBody = async () => {
    try {
      const res = await apiUtils.getAllInjuryPartOfBody();
      return res.data;
    } catch (err) {
      console.error("getAllInjuryPartOfBody", err);
      return false;
    }
  }

  const getAllMaterialAgent = async () => {
    try {
      const res = await apiUtils.getAllMaterialAgent();
      return res.data;
    } catch (err) {
      console.error("getAllMaterialAgent", err);
      return false;
    }
  }

  const getAllProvince = async () => {
    try {
      const res = await apiUtils.getAllProvince();
      return res.data;
    } catch (err) {
      console.error("getAllProvince", err);
      return false;
    }
  }

  const getAllProvinceUser = async () => {
    try {
      const res = await apiUtils.getAllProvinceUser();
      return res.data;
    } catch (err) {
      console.error("getAllProvinceUser", err);
      return false;
    }
  }

  const getSeedUtil = async () => {
    try {
      const res = await apiUtils.getSeedUtil();
      return res.data;
    } catch (err) {
      console.error("getSeedUtil", err);
      return false;
    }
  }

  const getSocialSecurityRegimen = async (id: guid) => {
    try {
      const res = await apiUtils.getSocialSecurityRegimen(id);
      return res.data;
    } catch (err) {
      console.error("getSocialSecurityRegimen", err);
      return false;
    }
  }

  const getAllSocialSecurityRegimen = async () => {
    try {
      const res = await apiUtils.getAllSocialSecurityRegimen();
      return res.data;
    } catch (err) {
      console.error("getAllSocialSecurityRegimen", err);
      return false;
    }
  }

  const getSpecificActivity = async () => {
    try {
      const res = await apiUtils.getSpecificActivity();
      return res.data;
    } catch (err) {
      console.error("getSpecificActivity", err);
      return false;
    }
  }

  const getTypeDocument = async () => {
    try {
      const res = await apiUtils.getTypeDocument();
      return res.data;
    } catch (err) {
      console.error("getTypeDocument", err);
      return false;
    }
  }

  const getTypeInjury = async () => {
    try {
      const res = await apiUtils.getTypeInjury();
      return res.data;
    } catch (err) {
      console.error("getTypeDocument", err);
      return false;
    }
  }

  const getTypePlace = async () => {
    try {
      const res = await apiUtils.getTypePlace();
      return res.data;
    } catch (err) {
      console.error("getTypeDocument", err);
      return false;
    }
  }

  const getTypeRoad = async () => {
    try {
      const res = await apiUtils.getTypeRoad();
      return res.data;
    } catch (err) {
      console.error("getTypeRoad", err);
      return false;
    }
  }

  const getTypeWork = async () => {
    try {
      const res = await apiUtils.getTypeWork();
      return res.data;
    } catch (err) {
      console.error("getTypeWork", err);
      return false;
    }
  }

  const getAidKeyAvailable = async () => {
    try {
      const res = await apiUtils.getAidKeyAvailable();
      return res.data;
    } catch (err) {
      console.error("getAidKeyAvailable", err);
      return false;
    }
  }

  const createOrderRequest = async (CreateOrderRequest: any) => {
    try {
      const res = await apiUtils.createOrderRequest(CreateOrderRequest);
      return res.data;
    } catch (err) {
      console.error("createOrderRequest", err);
      return false;
    }
  }

  const getAffiliation = async (Term: string, Birthdate: any) => {
    try {
      const res = await apiUtils.getAffiliation(Term, Birthdate);
      return res.data;
    } catch (err) {
      console.error("getAffiliation", err);
      return false;
    }
  }

  const getAffiliationById = async (Id: string) => {
    try {
      const res = await apiUtils.getAffiliationById(Id);
      return res.data;
    } catch (err) {
      console.error("getAffiliationById");
      return false;
    }
  }

  const getDelegatedPayment = async (data: any) => {
    try {
      const res = await apiUtils.getDelegatedPayment(data);
      return res.data;
    } catch (err) {
      console.error("getDelegatedPayment");
      return false;
    }
  }
  const onlyDigitsWithMaxLength = (e: React.KeyboardEvent<HTMLInputElement>, val: any, max: number): boolean => {
    if ((/[ -~]/.test(e.key)) && !e.ctrlKey && e.key.length == 1 && !/[0-9]/.test(e.key)) {
      // console.log('printable no digit');
      e.preventDefault();
      return false;
    };
    if (/[\x80-\xFF]/.test(e.key) || e.key == Key.Dead) {
      // console.log('ascii extended');
      e.preventDefault();
      return false;
    }
    if (/[0-9]/.test(e.key) && val.length >= max && e.currentTarget.selectionStart == e.currentTarget.selectionEnd) {
      // console.log('digit out max');
      e.preventDefault();
      return false;
    }
    return true;
  }
  const pasteOnlyDigits = (e: React.ClipboardEvent<HTMLInputElement>): boolean => {
    const data = e.clipboardData.getData('text');
    // console.log(data);
    if (!/[0-9]+/.test(data)) {
      e.preventDefault();
      return false
    }
    return true;
  }
  return {
    getAllContract,
    getAllContactForm,
    getAllAccidentSeverity,
    getBilling,
    calculatorFeesSelfemployee,
    calculatorAbsenteeism,
    getAllCauseOfHigh,
    getAllCity,
    getCityByProvince,
    getAllCityUser,
    getFilterCityUser,
    getAllCnae,
    getAllCno,
    getAllCnoLetter,
    getAllCno3Digi,
    getAllCountry,
    getAllDesviation,
    getAllInjuryPartOfBody,
    getAllMaterialAgent,
    getAllProvince,
    getAllProvinceUser,
    getSeedUtil,
    getSocialSecurityRegimen,
    getSpecificActivity,
    getTypeDocument,
    getTypeInjury,
    getTypePlace,
    getTypeRoad,
    getTypeWork,
    getAidKeyAvailable,
    createOrderRequest,
    getAllSocialSecurityRegimen,
    getAffiliation,
    getAffiliationById,
    getDelegatedPayment,
    onlyDigitsWithMaxLength,
    pasteOnlyDigits
  }

}

export default useUtils;