import React, { useEffect, useState } from 'react';
import PhisicalActivityDetail from 'components/blocks/Delta/Inputs/PhisicalActivityDetail';
import MaterialAgentPhysicalActivity from 'components/blocks/Delta/Inputs/MaterialAgentPhysicalActivity';
import PhisicalActivityAgentDetail from 'components/blocks/Delta/Inputs/PhisicalActivityAgentDetail';
import useUtils from 'services/utils/useUtils';
import MaterialAgent from './MaterialAgent';


interface IInputProps {
  reset: any
  errors: any,
  register: any,
  setValue: any,
  watchPhisicalActivityDetailCode: any,
  watchPhisicalActivityDetailDescription: any,
  // watchPhisicalActivityAgentDetailCode: any
  // watchPhisicalActivityAgentDetailDescription: any
  concatCode: string
}

const PhysicalActivity: React.FC<IInputProps> = ({ reset, errors, register, setValue, watchPhisicalActivityDetailCode,
  watchPhisicalActivityDetailDescription,
  // watchPhisicalActivityAgentDetailCode,
  // watchPhisicalActivityAgentDetailDescription,
  concatCode }) => {

  const { getAllMaterialAgent, getSpecificActivity } = useUtils();
  const [allMaterialAgent, setAllMaterialAgent] = useState<any | null>(null)
  const [allDataSpecificActivity, setAllDataSpecificActivity] = useState<any>();
  useEffect(() => {
    // handleGetAllMaterialAgent()
    handleGetDataSpecificActivity()
  }, [])
  const handleGetAllMaterialAgent = async () => {
    const response = await getAllMaterialAgent();
    if (response) {
      setAllMaterialAgent(response)
    } else {
      // SHOW ERROR
    }
  }
  const handleGetDataSpecificActivity = async () => {
    const response = await getSpecificActivity()
    if (response) {
      setAllDataSpecificActivity(response);
    }
  }

  return (
    <>
      <PhisicalActivityDetail

        arraData={allDataSpecificActivity && allDataSpecificActivity}
        id="accident_extension_specify"
        errors={errors}
        register={register}
        title="Actividad Física"
      />
      <MaterialAgent
        reset={reset}
        errors={errors}
        register={register}
        setValue={setValue}
        id={'physical_activity'}
        title={'Agente material asociado a la actividad física'}
        concatCode={concatCode}
        code2name="code2_physical_activity"
      />

    </>
  )
}

export default PhysicalActivity;

