import * as msal from "@azure/msal-browser";
import { AuthenticationResult, BrowserCacheLocation, EventMessage, EventType } from "@azure/msal-browser";
import { useState } from "react";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_B2C_SI_POLICY,
    imp: process.env.REACT_APP_B2C_IMP_POLICY
  },
  authorities: {
    signIn: {
      authority: `${process.env.REACT_APP_AUTH_B2C_PATH}${process.env.REACT_APP_B2C_SI_POLICY}`,
    },
    imp: {
      authority: `${process.env.REACT_APP_AUTH_B2C_PATH}${process.env.REACT_APP_B2C_IMP_POLICY}`,
    }
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY
}
const msalConfig: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    // clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    // authoriryB2cPath,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `/inicio`,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
};

export const msalImpConfig: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    // clientId,
    authority: b2cPolicies.authorities.imp.authority,
    // authoriryB2cPath,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `/internal-app`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: true,
  }
};

export const MSAL_IMP_PARAMS = {
  APP: 'app',
  TARGET: 'target',
  ID: 'id',
  TS: 'ts'
}
export const MSAL_STORAGE_KEYS = {
  CONFIG: 'msalConfig',
  INSTANCE: 'msalInstance',
  ERROR: 'msalError'
}

export const MSAL_IMP_APPS = {
  HISAM: 'HISAM',
  SIP: 'SIP'
}

function useMsalB2C() {
  let config;
  const ssConfig = localStorage.getItem(MSAL_STORAGE_KEYS.CONFIG);
  if (ssConfig) {
    config = JSON.parse(ssConfig);
  }
  if (!config) {
    config = msalConfig;
  }
  const msalInstance = new msal.PublicClientApplication(config);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      msalInstance.setActiveAccount((event.payload as AuthenticationResult).account);

      if (localStorage.getItem(MSAL_STORAGE_KEYS.CONFIG) && localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE)) {
        localStorage.setItem(MSAL_STORAGE_KEYS.INSTANCE, event.eventType);
      }
    }
    if (event.eventType === EventType.LOGIN_FAILURE) {
      if (localStorage.getItem(MSAL_STORAGE_KEYS.CONFIG) && localStorage.getItem(MSAL_STORAGE_KEYS.INSTANCE)) {
        localStorage.setItem(MSAL_STORAGE_KEYS.INSTANCE, event.eventType);
        localStorage.setItem(MSAL_STORAGE_KEYS.ERROR, event.error?.message ?? '');
      }
    }
    // console.log(event);
  });

  const handleRedirectToLogin = () => {
    msalInstance
      .handleRedirectPromise()
      .then((authResult) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          msalInstance.loginRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    msalInstance
  };
}

export default useMsalB2C;
