import React, { useEffect } from "react";
import EntornoLaboral from "../Comun/EntornoLaboral";
import "../ManagementSheets.scss";
import useDecode from "hooks/useDecode";
import useGetEconomicSheets from "services/recordSheets/useGetEconomicSheets";
import LoaderMin from "components/blocks/Loader/LoaderMin";

type guid = string;
interface entornoLaboral {
  entornoLaboralEmpresaId: number;
  nombre: string;
  ccc: string;
  cif: string;
}
interface Management {
  name: string;
  date: Date | string;
  company: string;
}
interface RegulatoryBaseCATA extends Management {
  id: guid | string;
  periodo: string;
  baseCotizacion?: number;
  record_id: guid;
  entornoLaboral: entornoLaboral;
  management_type: string;
}

interface ISheet {
  props: any;
}

const BaseCotizacionCata: React.FC<ISheet> = ({ props }: any) => {
  const baseReguladoraCATAId = props.match.params.detailId.split("__")[1];
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const prestacionId = arrParamsId[1];
  const {
    getRegulatoryBaseCATA,
    loadingEconomicSheet,
    successEconomicSheet,
    errorEconomicSheet,
    dataEconomicSheet,
  } = useGetEconomicSheets();

  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(() => {
    getRegulatoryBaseCATA(userId, prestacionId, baseReguladoraCATAId);
  }, []);

  if (loadingEconomicSheet) {
    return (
      <section className="container">
        {/* <p className="loader-text">Cargando</p> */}
        <LoaderMin />
      </section>
    );
  }

  if (errorEconomicSheet) {
    return (
      <section className="container">
        <p className="loader-text">Lo sentimos, se ha producido un error.</p>
      </section>
    );
  }
  return (
    <>
      {dataEconomicSheet === null ? (
        <section className="container">
          <p className="loader-text">No hay datos para mostrar.</p>
        </section>
      ) : (
        <section className="container">
          <div className="col-xl-12 remarkable">
            <div className="row">
              <div className="col-xl-12">
                <h3 className="remarkable-title">
                  {"Datos Base Cotización CATA"}
                </h3>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Periodo"}</label>
                  <input
                    type="text"
                    id="Periodo"
                    className="item-value"
                    value={dataEconomicSheet?.periodo}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-xl-3">
                <div className="item">
                  <label className="item-label">{"Base Cotización"}</label>
                  <input
                    type="text"
                    id="BaseCotizacion"
                    className="item-value"
                    value={dataEconomicSheet?.baseCotizacion?.toFixed(2)}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {dataEconomicSheet?.entornoLaboral ? (
            <EntornoLaboral
              id={"EntornoLaboral"}
              nombre={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.Nombre
                  : ""
              }
              CCC={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CCC
                  : ""
              }
              CIF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.CIF
                  : ""
              }
              EntornoLaboralEmpresaId={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EntornoLaboralEmpresaId
                  : ""
              }
              EsCuentaPropia={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.EsCuentaPropia
                  : ""
              }
              NAF={
                dataEconomicSheet?.entornoLaboral
                  ? dataEconomicSheet.entornoLaboral.NAF
                  : ""
              }
            />
          ) : (
            ""
          )}
        </section>
      )}
    </>
  );
};

export default BaseCotizacionCata;
