import React from 'react';
import SurveyFormGridItem from './SurveyFormGridItem';

interface IProps {
  group:any,
  checkFieldInputType: (inputType:number)=>string,
  register:any
}

const SurveyFormGrids:React.FC<IProps> = ({group,checkFieldInputType, register}) => {
  return(
    <>
      {group.grids.map((grid:any)=> {
        if(grid.fields.length > 0) {
          return (
            <SurveyFormGridItem 
              grid={grid}
              checkFieldInputType={checkFieldInputType}
              register={register}
              key={grid.id}
            />
          )
        }
      })}
    </>
  )

}

export default SurveyFormGrids;