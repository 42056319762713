import React from "react";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import Nationality from "../Inputs/Nationality";
import Province from "../Inputs/Province";
import AddressFieldset from "../Groups/AddressGroup";
import TestCommunity from "../Inputs/TestCommunity";
import InputText from "../Inputs/InputText";
interface ITabContent {
  data?: any;
  reset?: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean;
  filterByProvince?: boolean;
}
const PlaceOutSideWork: React.FC<ITabContent> = ({
  errors,
  watch,
  register,
  setValue,
  disabled,
  filterByProvince
}) => {
  const watchCCCNationCode = watch("ccclocation_nation");
  const watchNationalityDescription = watch("ccclocation_nationDescription");
  const ccclocationWatchProvinceCode = watch("ccclocation_province");
  const ccclocationWatchProvinceDescription = watch(
    "ccclocation_provinceDescription"
  );
  const watchCommunityCode = watch("ccclocation_city");
  const watchCommunityDescription = watch("ccclocation_cityDescription");
  // const watchCommunityCode= watch('ccclocation_Community')
  return (
    <div>
      <FieldsetContainer legend="Lugar del accidente fuera del centro de trabajo" disabled={disabled}>
        <div className="col-sm-12 col-lg-6">
          <Nationality
            legend="Pais"
            nameCode="ccclocation_nation"
            idCode="ccclocation_nation"
            nameDescription="ccclocation_nationDescription"
            idDescription="ccclocation_nationDescription"
            placeholderDescription="Pais"
            errors={errors}
            register={register}
            setValue={setValue}
            watchNationalityCode={watchCCCNationCode}
            watchNationalityDescription={watchNationalityDescription}
          ></Nationality>
        </div>
        <div className="col-sm-12 col-lg-6">
          <Province
            setValue={setValue}
            errors={errors}
            register={register}
            watchProvinceCode={ccclocationWatchProvinceCode}
            watchProvinceDescription={ccclocationWatchProvinceDescription}
            idCode="ccclocation_province"
            idDescription="ccclocation_provinceDescription"
          ></Province>
        </div>
        <div className="col-sm-12 col-lg-6">
          <TestCommunity
            errors={errors}
            register={register}
            setValue={setValue}
            idDescription="ccclocation_cityDescription"
            idCode="ccclocation_city"
            watchCommunityCode={watchCommunityCode}
            watchCommunityDescription={watchCommunityDescription}
            filterByProvince={filterByProvince}
            watchProvinceCode={ccclocationWatchProvinceCode}
          />
        </div>
        <div className="col-sm-12 col-lg-6">
          <InputText
            id="ccclocation_address"
            name="ccclocation_address"
            label="Calle y número"
            placeholder="Calle y número"
            errors={errors}
            register={register}
          />
        </div>
        <div className="col-sm-12 col-lg-6">
          <InputText
            id="ccclocation_public_road"
            name="ccclocation_public_road"
            label="Via pública o punto kilometrico"
            placeholder="Via pública o punto kilometrico"
            errors={errors}
            register={register}
          />
        </div>
        <div className="col-sm-12 col-lg-6">
          <InputText
            id="ccclocation_other_side"
            name="ccclocation_other_side"
            label="Otro lugar"
            placeholder="Otro lugar"
            errors={errors}
            register={register}
          />
        </div>
      </FieldsetContainer>
    </div>
  );
};

export default PlaceOutSideWork;
