import { useEffect, useState } from "react";
import useGetRole from "../hooks/useGetRole";
import { useSetToken } from "../hooks/useSetToken";
import useLogin from "hooks/useLogin";
import { useMsal } from "@azure/msal-react/dist";
function useLayout() {
  const { instance } = useMsal();
  const userRole = useGetRole.getRole();
  const { isImpersonation, impersonationAppName } = useGetRole;
  const [rolePermision, setRolePermision] = useState("");
  const { isAuthenticate } = useLogin();


  useSetToken();
  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((authResult) => {
        const account = instance.getActiveAccount();
        if (!account) {
          instance.loginRedirect();
          localStorage.clear();
        }
      })
      .catch((err) => {

        if (err) {
          window.location.reload();
        }
      });

    if (typeof userRole === "string") {

      setRolePermision(userRole);
    }

  }, [isAuthenticate, userRole]);

  return {
    userRole,
    rolePermision,
    isAuthenticate,
    isImpersonation,
    impersonationAppName
  };
}

export default useLayout;
