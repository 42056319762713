import React from 'react'
import  './CardNewManagement.scss'
import { useHistory } from "react-router-dom";
import Plus from "../../img/icons/svg/cita/plus.png";
function CardNewManagement({props}: any) {
    const history = useHistory();
    const handleRedirect = () => {
        history.push({
          pathname: "/mis-gestiones",
        });
      };
  return (
    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 container-card-link-color">
    {" "}
    <a
      
      onClick={handleRedirect}
      className={`card-link-new-application`}
    >

      <p className='title-new-application'>Nueva Solicitud</p>
      <img className='img-plus' src={Plus} alt="plus" />
    </a>
  </div>
  )
}

export default CardNewManagement