import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';
import {guid} from '@solimat/solimat-web-endpoint/lib/record/dto';

const useGetValidateDocumentOtp = () => {
  const [loadingGetValidateDocumentOtp, setLoadingGetValidateDocumentOtp] = useState(false);
  const [successGetValidateDocumentOtp, setSuccessGetValidateDocumentOtp] = useState(false);
  const [errorGetValidateDocumentOtp, setErrorGetValidateDocumentOtp] = useState<null|boolean>(null);
  const [validateDocumentOtp, setValidateDocumentOtp] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);

  const getValidateDocumentOtp = (userId: guid, prestacionId: string, documentoId: string, otp: string, _cb?:(data:any)=>void) => {
    setLoadingGetValidateDocumentOtp(true);
    setSuccessGetValidateDocumentOtp(false);
    apiRecord.getValidateDocumentOtp(userId, prestacionId, documentoId, otp)
      .then((res:any) => {      
        if(res.data !== "") {
          setValidateDocumentOtp(res.data);
          setLoadingGetValidateDocumentOtp(false);
          setSuccessGetValidateDocumentOtp(true);
          if (typeof _cb === "function") {
            _cb(res.data.uri);
          }
        } else {
          setLoadingGetValidateDocumentOtp(false);
          setErrorGetValidateDocumentOtp(true);
        }
      })
      .catch((err:any)=> {
        setLoadingGetValidateDocumentOtp(false);
        setErrorGetValidateDocumentOtp(true);
        console.log(err);
      })
  }


  return {
    validateDocumentOtp,
    getValidateDocumentOtp,
    loadingGetValidateDocumentOtp,
    successGetValidateDocumentOtp,
    errorGetValidateDocumentOtp
    };
}

export default useGetValidateDocumentOtp;