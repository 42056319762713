import React, { useContext, useEffect, useState } from "react";
import { AutoCompleteComponent, FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import useGetAllCnaeEmployee from "../../../../services/utils/useGetAllCnaeEmployee.";
import useGetAllCnaeCompany from "../../../../services/utils/useGetAllCnaeCompnany "
import { MasterDataContext } from "components/pages/Delta/Delta";
import masterDataUtils from "services/utils/masterDataUtils";
import { useFormContext } from "react-hook-form";
import Fuse from "fuse.js";

interface IInputProps {
  errors: any;
  register: any;
  watchCnaeCode: any;
  setValue?: any;
  idCode: string;
  idDescription: string;
}

const MainEconomicActivityCodeCNAE: React.FC<IInputProps> = ({
  errors,
  register,
  setValue,
  watchCnaeCode,
  idCode,
  idDescription,
}) => {
  // const { getAllCnaeEmployee } = useGetAllCnaeEmployee();
  const [allCnae, setAllCnae] = useState<any>();
  const { getAllCnaeCompany } = useGetAllCnaeCompany();
  const itemKey: string = "cnae";
  const masterDataContext = useContext(MasterDataContext);
  const masterData = masterDataContext?.masterData;
  const { checkAndUpdate } = masterDataUtils(masterDataContext);

  const formContext = useFormContext();
  const getValues = formContext?.getValues;

  useEffect(() => {
    const cnaeEmploye = getAllCnaeCompany().then((result)=>{
      setAllCnae(result.data)
    })
    // checkAndUpdate(itemKey, allCnae, getAllCnaeEmployee, setAllCnae);
  }, []);

  useEffect(() => {
    setValueCnaeDescription();
  }, [watchCnaeCode, allCnae]);

  useEffect(() => {
    setValueCnaeDescription();
  }, [allCnae]);
// console.log('allCnae',allCnae)
  const handleChange = (args: any) => {
    if (allCnae && allCnae) {
      const codeValue = allCnae?.find(
        (item: any) => item?.Descripcion === args.value
      );
      if (codeValue) {
        setValue(idCode, codeValue ? codeValue?.Codigo : "");
      }
    }
  };

  const setValueCnaeDescription = () => {
    if (allCnae && allCnae) {
      const descriptionValue = allCnae?.find(
        (item: any) => item.Codigo === watchCnaeCode
      );
      if (descriptionValue) {
        setValue(
          idDescription,
          descriptionValue ? descriptionValue.Descripcion : ""
        );
      }
    }
  };

  const itemsData: { [key: string]: Object }[] = allCnae;
  const fields: object = { value: "Descripcion" };

  function onFiltering(e: FilteringEventArgs) {
    let options: Object = {
      keys: ["Descripcion"],
      includeMatches: true,
      findAllMatches: true,
    };

    let fuse: any = new Fuse(itemsData, options);
    let result: any = fuse.search(e.text);
    let data: { [key: string]: Object }[] = [];
    for (let i: number = 0; i < result.length; i++) {
      data.push(result[i].item as any);
    }
    e.updateData(data, undefined);

    let lists: any = document
      ?.getElementById("agreement_popup")
      ?.querySelectorAll(".e-list-item");
    if (lists) {
      highlightSearch(lists, result as any);
    }
  }

  function highlightSearch(listItems: Element[], result: any): void {
    if (result.length > 0) {
      for (let i: number = 0; i < listItems.length; i++) {
        let innerHTML: string = listItems[i].innerHTML;
        for (
          let j: number = result[i].matches[0].indices.length - 1;
          j >= 0;
          j--
        ) {
          let indexes: number[] = result[i].matches[0].indices[j];
          innerHTML =
            innerHTML.substring(0, indexes[0]) +
            '<span class="e-highlight">' +
            innerHTML.substring(indexes[0], indexes[1] + 1) +
            "</span>" +
            innerHTML.substring(indexes[1] + 1);
          listItems[i].innerHTML = innerHTML;
        }
      }
    }
  }

  const handleInputBlur = () => {
    const value = getValues(idCode);
    if (value === "") {
      setValue(idDescription, "");
    }
  };

  const handleAutoCompleteBlur = () => {
    const value = getValues(idDescription);
    if (value === "") {
      setValue(idCode, "");
    }
  };

  const handleAutoCompleteChange = (args: any) => {
    handleChange(args);
    if (!args.value) {
      setValue(idCode, "");
    }
  };

  const cnaeCodeId = idCode ? idCode : "cnaeCode";
  const cnaeDescd = idDescription ? idDescription : "cnaeDescription";

  return (
    <fieldset
      className={`input-block input-block--code ${(errors && errors[idCode ? idCode : "cnaeCode"]?.message) ||
        (errors &&
          errors[idDescription ? idDescription : "cnaeDescription"]?.message)
        ? "error"
        : ""
        }`}
    >
      <div className="container-leyend">
        {" "}
        <legend className="input-block--code__legend">
          CNAE para cotización AT/EP
        </legend>
      </div>
      <div className="row">
        <div className="col-sm-8 col-md-3 col-lg-3">
        <input
          className=" "
          type="text"
          name={cnaeCodeId}
          id={cnaeCodeId}
          {...register(cnaeCodeId, {
            required: "CNAE requerido",
            pattern: {
              value: /^\d{3}$/,
              message: "CNAE codigo debe terenr 3 digitos",
            },
          })}
          maxLength={3}
          minLength={3}
          onBlur={handleInputBlur}
        />
        </div>

        {itemsData && itemsData ? (
          <div className="col-sm-12 col-md-8">
            <AutoCompleteComponent
              change={handleAutoCompleteChange}
              id={cnaeDescd}
              name={cnaeDescd}
              dataSource={itemsData}
              filtering={onFiltering}
              fields={fields}
              placeholder="CNAE para cotización AT/EP"
              autofill={true}
              {...register(cnaeDescd)}
              popupHeight="250px"
              popupWidth="250px"
              onBlur={handleAutoCompleteBlur}
            />
          </div>
        ) : null}
      </div>
      {errors && (
        <>
          <p className="error m-0">
            {errors[idCode ? idCode : "cnaeCode"]?.message}
          </p>
          <p className="error m-0">
            {errors[idDescription ? idDescription : "cnaeDescription"]?.message}
          </p>
        </>
      )}
    </fieldset>
  );
};

export default MainEconomicActivityCodeCNAE;
