import {useState} from 'react';
import {recordSheets} from '@solimat/solimat-web-endpoint';

const useGetEconomicSheets = () => {
  const [loadingEconomicSheet, setLoadingEconomicSheet] = useState(false);
  const [successEconomicSheet, setSuccessEconomicSheet] = useState(false);
  const [errorEconomicSheet, setErrorEconomicSheet] = useState(null);
  const [dataEconomicSheet, setDataEconomicSheet] = useState<any|null>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecordSheets = new recordSheets.RecordSheetsService(BASE_URL, FUNCTIONS_KEY);

  const getTSDPayment = (id:string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getTSDPayment(id)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

  const getOrthoprostheticReplacement = (userId: string, prestacionId: string, reposicionOrtoprotesicaId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getOrthoprostheticReplacement(userId, prestacionId, reposicionOrtoprotesicaId )
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

  const getRecovery = (userId:string, prestacionId:string,recobroId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getRecovery(userId,prestacionId,recobroId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

// GASTOS TRANSPORTE
const getTransportationExpense = (userId:string, prestacionId:string, gastoTransporteId: string,_cb?:(data:any)=>void) => {
  setLoadingEconomicSheet(true);
  setSuccessEconomicSheet(false);
  apiRecordSheets.getTransportationExpense(userId,prestacionId,gastoTransporteId)
    .then((res:any) => {
      setLoadingEconomicSheet(false);
      setSuccessEconomicSheet(true);
      setDataEconomicSheet(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any)=> {
      console.log(err)
      setLoadingEconomicSheet(false);
      setErrorEconomicSheet(err)
    })
}
// RESUMEN IMS
  const getImsSummary = (userId:string, prestacionId:string,resumenIMSId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getImsSummary(userId,prestacionId,resumenIMSId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

  //REGULARIZACION
  const getRegularization= (userId:string, prestacionId:string, regularizacionId:string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getRegularization(userId,prestacionId,regularizacionId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }
// Gasto Asistencial
  const getCareExpenses= (userId:string, prestacionId:string, sheetId:string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getCareExpenses(userId,prestacionId,sheetId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

  const getSpecialBenefitsCommission = ( userId: string, prestacionId: string, comisionPrestacionEspecialId: string, _cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getSpecialBenefitsCommission(userId,prestacionId, comisionPrestacionEspecialId )
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }

  const getRegulatoryBaseCATA = (userId: string, prestacionId: string, baseReguladoraCATAId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getRegulatoryBaseCATA(userId, prestacionId, baseReguladoraCATAId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }
  const getRegulatoryBase = (userId: string, prestacionId: string, baseReguladoraCATAId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getRegulatoryBase(userId, prestacionId, baseReguladoraCATAId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }
  const getLegalExpenses = (userId: string, prestacionId: string, gastoJuridicoId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getLegalExpenses(userId, prestacionId, gastoJuridicoId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }
// GASTOS FACRMACIA
const getPharmacyExpense = (userId: string, prestacionId: string, gastoFarmaciaId: string,_cb?:(data:any)=>void) => {
  setLoadingEconomicSheet(true);
  setSuccessEconomicSheet(false);
  apiRecordSheets.getPharmacyExpense(userId, prestacionId, gastoFarmaciaId)
    .then((res:any) => {
      setLoadingEconomicSheet(false);
      setSuccessEconomicSheet(true);
      setDataEconomicSheet(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any)=> {
      console.log(err)
      setLoadingEconomicSheet(false);
      setErrorEconomicSheet(err)
    })
}
 //PAGO DIRECTO
 const getDirectPayment= (userId:string, prestacionId:string, pagoDirectoId:string,_cb?:(data:any)=>void) => {
  setLoadingEconomicSheet(true);
  setSuccessEconomicSheet(false);
  apiRecordSheets.getDirectPayment(userId,prestacionId,pagoDirectoId)
    .then((res:any) => {
      setLoadingEconomicSheet(false);
      setSuccessEconomicSheet(true);
      setDataEconomicSheet(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }
    })
    .catch((err:any)=> {
      console.log(err)
      setLoadingEconomicSheet(false);
      setErrorEconomicSheet(err)
    })
}
  const getCompensationSld = (userId: string, prestacionId: string, compensacionSLDId: string,_cb?:(data:any)=>void) => {
    setLoadingEconomicSheet(true);
    setSuccessEconomicSheet(false);
    apiRecordSheets.getCompensationSld(userId, prestacionId, compensacionSLDId)
      .then((res:any) => {
        setLoadingEconomicSheet(false);
        setSuccessEconomicSheet(true);
        setDataEconomicSheet(res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err:any)=> {
        console.log(err)
        setLoadingEconomicSheet(false);
        setErrorEconomicSheet(err)
      })
  }
  
  return {
    getTSDPayment,
    getOrthoprostheticReplacement,
    getRecovery,
    getImsSummary,
    getCareExpenses,
    getSpecialBenefitsCommission,
    getRegulatoryBaseCATA,
    getRegulatoryBase,
    getLegalExpenses,
    getCompensationSld,
    getTransportationExpense,
    loadingEconomicSheet,
    successEconomicSheet,
    errorEconomicSheet,
    dataEconomicSheet,
    getRegularization,
    getPharmacyExpense,
    getDirectPayment
    };
}

export default useGetEconomicSheets;