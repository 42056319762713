

import { useState } from "react";
import { communication } from "@solimat/solimat-web-endpoint";

const useCreateGeneralCommunication = () => {
  const BASE_URL = process.env.REACT_APP_COMUNICACIONES_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_COMUNICACIONES_KEY || "";
  const apiCommunication = new communication.CommunicationUser(BASE_URL, FUNCTIONS_KEY);

  const [loadingCreateGeneralCommunication, setLoadingCreateGeneralCommunication,] = useState(false);
  const [successCreateGeneralCommunication, setSuccessCreateGeneralCommunication] = useState(false);
  const [errorCreateGeneralCommunication, setErrorCreateGeneralCommunication,] = useState(null);

  const createGeneralCommunication = (userId: string ,data:any, _cb?:(id:string)=>void) => {
    setLoadingCreateGeneralCommunication(true);
    setErrorCreateGeneralCommunication(null);
    apiCommunication
      .createGeneralCommunication(userId, data)
      .then((res: any) => {
        setSuccessCreateGeneralCommunication(true);
        setLoadingCreateGeneralCommunication(false);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch(err => {
        console.error("createUserCommunication error: ", err);
        setErrorCreateGeneralCommunication(err);
        setLoadingCreateGeneralCommunication(false);
      });
  };
  return {
    createGeneralCommunication,
    successCreateGeneralCommunication,
    loadingCreateGeneralCommunication,
    errorCreateGeneralCommunication
  };
};

export default useCreateGeneralCommunication;