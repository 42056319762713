// 
export const errorsValidationDeltaWithoutLeave = [
  {
    dbKey: 'NumeroReferencia',
    numReference: '',
    label: 'Nº de referencia Delt@',
    name: 'numReference',
    message: ''
  },
  {
    dbKey: 'Cabecera.Mes',
    month: '',
    label: 'Mes',
    name: 'month',
    message: ''
  },
  {
    dbKey: 'Cabecera.Anyo',
    year: '',
    label: 'Año',
    name: 'year',
    message: ''
  },
  {
    dbKey: 'Cabecera.CifNif',
    cif: '',
    label: 'CIF o NIF/NIE',
    name: 'cif',
    message: ''
  },
  {
    dbKey: '.Cabecera.Razon',
    reason: '',
    label: 'Nombre o Razón Social',
    name: 'reason',
    message: ''
  },
  {
    dbKey: 'Cabecera.CCC',
    ccc: '',
    label: 'CCC',
    name: 'ccc',
    message: ''
  },
  {
    dbKey: 'Cabecera.NAF',
    naf: '',
    label: 'NAF',
    name: 'naf',
    message: ''
  },
  {
    dbKey: 'Cabecera.Plantilla',
    workforce: '',
    label: 'Plantilla',
    name: 'workforce',
    message: ''
  },
  {
    dbKey: 'Cabecera.Municipio',
    city: '',
    label: 'Municipio',
    name: 'communityCode',
    message: ''
  },
  {
    dbKey: 'Cabecera.ProvinciaCodigo',
    province: '',
    label: 'Provincia',
    name: 'province',
    message: ''
  },
  {
    dbKey: 'Cabecera.CnaeCodigo',
    code_cnae: '',
    label: 'Actividad Económica Principal',
    name: 'cnaeContributionAtEpCode',
    message: ''
  },
  {
    dbKey: 'Cabecera.CnaeTexto',
    code_cnae: '',
    label: 'Actividad Económica Principal',
    name: 'textMainEconomicActivityCode',
    message: ''
  },
  {
    dbKey: 'Cabecera.CnaeDescripcion',
    description_cnae: '',
    label: 'Actividad Económica Principal',
    name: 'cnaeContributionAtEpDescription',
    message: ''
  },
  {
    dbKey: 'Pie.FEmpresaNombreApellidos',
    complete_name: '',
    label: 'Actores implicados', // nombre del grupo
    name: 'representativeCompleteName',
    message: ''
  },
  {
    dbKey: 'Pie.FEmpresaCalidadDe',
    authority_code: '',
    label: 'Actores implicados', // nombre del grupo
    name: 'representative',
    message: ''
  },
  {
    dbKey: 'Pie.FEmpresaProvinciaDescripcion',
    province: '', // SE REPITE PROVINCE DE .header y .footer
    label: 'Actores implicados', // nombre del grupo
    name: 'representativeLocation',
    message: ''
  }, 
  {
    dbKey: 'Pie.FEmpresaFechaPresenta',
    submissionDate: '',
    label: 'Actores implicados', // nombre del grupo
    name: 'actors_submissionDate',
    message: ''
  }
]
export const  findNameByDbKeyError =(dbKey:string,errors:any[] )=> {
  for (const item of errors) {
    if (item.dbKey === dbKey) {
      return item.name;
    }
  }
  return null;
}
export const createNewerrorsObject = (accidented:any,errors:any[])=> {
  const modifiedArray = [...errors];


    accidented?.forEach((item:any, index:number) => {
      const newObj ={
        dbKey: `Accidentados[${index}].TipoLesionCodigo`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosTipoLesión${index}`,
        message: ''
      }
      const newObj2 ={
        dbKey: `Accidentados[${index}].ParteCuerpoLesionadaCodigo`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosParteLesionada${index}`,
        message: ''
      }
      const newObj3 ={
        dbKey: `Accidentados[${index}].FormaContactoCodigo`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosFormaContacto${index}`,
        message: ''
      }
      const newObj4 ={
        dbKey: `Accidentados[${index}].FechaAccidente`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosFechaAccidente${index}`,
        message: ''
      }
      const newObj5 ={
        dbKey: `Accidentados[${index}].TipoIPFCodigo`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosTipoDocumento${index}`,
        message: ''
      }
      const newObj6 ={
        dbKey: `Accidentados[${index}].NAF`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosNAF${index}`,
        message: ''
      }
      const newObj7 ={
        dbKey: `Accidentados[${index}].TipoContrato`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosTipoContrato${index}`,
        message: ''
      }
      const newObj8 ={
        dbKey: `Accidentados[${index}].Contrato`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosContrato${index}`,
        message: ''
      }
      const newObj9 ={
        dbKey: `Accidentados[${index}].SexoId`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosGénero${index}`,
        message: ''
      }
      const newObj10 ={
        dbKey: `Accidentados[${index}].NombreApellidos`,
        reception_date: '',
        label: 'NAF', // nombre del grupo
        name: `AccidentadosNombreApellidos${index}`,
        message: ''
      }
      modifiedArray.push(newObj,newObj2,newObj3,newObj4,newObj5,newObj6,newObj7,newObj8,newObj9,newObj10);
    });

return modifiedArray
}
