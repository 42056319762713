import React, { useEffect } from "react";
import InputFloat from "components/blocks/Delta/Inputs/InputFloat";
import FieldsetContainer from "components/blocks/Delta/Inputs/Containers/FieldsetContainer";

interface ITabContent {
  data: any;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean;
}

interface IEconomics {
  average: "1.25";
  average_a: "1.25";
  average_b: "1.58";
  b1: "2.568";
  b2: "2.658";
  base: "1";
  compensation: "3.2";
  days: "1";
  id: "8414650c-cfe9-4a33-b619-08d8bc955ff2";
  pat: null;
  previous_month: "1";
  total_annual: "265.255";
  total_benefit: "235.56";
}

const Economic: React.FC<ITabContent> = ({
  data,
  reset,
  watch,
  errors,
  register,
  disabled,
  setValue,
}) => {
  return (
    <div
      className="container form--simple-styled Economics"
      id="economics_form"
    >
      <div className="row">
        <div className="col-md-4">
          <FieldsetContainer
            disabled={disabled}
            legend="A) Base de cotización mensual"
          >
            <div className="col-12">
              <InputFloat
                id="economic_previousMonth"
                name="economic_previousMonth"
                label="En el mes anterior"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_days"
                name="economic_days"
                label="Días cotizados"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_base"
                name="economic_base"
                label="Base reguladora A"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-md-4">
          <FieldsetContainer
            disabled={true}
            legend="B) Base de cotización al año"
          >
            <div className="col-12">
              <InputFloat
                id="economic_b1"
                name="economic_b1"
                label="B.1.- por horas extras"
                placeholder="0.00"
                errors={errors}
                register={register}
               
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_b2"
                name="economic_b2"
                label="B.2.- por otros conceptos"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_totalAnnual"
                name="economic_totalAnnual"
                label="Total B1 + B2"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_average"
                name="economic_average"
                label="Promedio diario base B"
                placeholder="0.00"
                errors={errors}
                register={register}
                
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-md-4">
          <FieldsetContainer disabled={disabled} legend="C) Subsidio">
            <div className="col-12">
              <InputFloat
                id="economic_averageA"
                name="economic_averageA"
                label="Base reguladora A"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_averageB"
                name="economic_averageB"
                label="Base reguladora B"
                placeholder="0.00"
                errors={errors}
                register={register}
               
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_totalBenefit"
                name="economic_totalBenefit"
                label="Total B.R. diaria"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <InputFloat
                id="economic_compensation"
                name="economic_compensation"
                label="Cuantía del subsidio 75%"
                placeholder="0.00"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Economic;
