import React, { useEffect, useState } from "react";
import InputText from "../Inputs/InputText";
import InputDate from "../Inputs/InputDate";
import DoubleRadioCheckGroup from "../Inputs/DoubleRadioCheckGroup";
import YesNoNoAnswer from "../Groups/YesNoNoAnswer";
import TypeOfPlace from "../Inputs/TypeOfPlace";
import TypeOfWork from "../Inputs/TypeOfWork";
import PhysicalActivity from "../Inputs/PhysicalActivity";
import TriggerUnusualFact from "../Inputs/TriggerUnusualFact";
import FieldsetContainer from "../Inputs/Containers/FieldsetContainer";
import { guid } from "@solimat/solimat-web-endpoint/lib/Delta/dto";
import DropDownMaster2Digi from "../Inputs/DropDownMaster2Digi";
import useUtils from "../../../../services/utils/useUtils";
import MaterialAgent from "../Inputs/MaterialAgent";
import Moment from 'moment'
interface IAmpliacion {
  DatosTestigos: string;
  DesencadenanteAgen: string;
  DesencadenanteAgenDescripcion: string;
  DesencadenanteDesv: string;
  DesencadenanteDesvDescripcion: string;
  DesencadenanteHech: string;
  EntornoLugar: string;
  EntornoTipoLugar: string;
  EntornoTipoLugarDescripcion: string;
  EsMultiples: boolean;
  EsTestigos: boolean;
  FormaContactoCodigo: string;
  FormaContactoDescripcion: string;
  Id: string;
  ModoAgente: string;
  ModoAgenteDescripcion: string;
  ModoTextoAgente: string;
  ModoTipoModo: string;
  ProcesoTipoTrabajo: string;
  ProcesoTipoTrabajoDescripcion: string;
  ProcesoTrabajo: string;
  TareaActividad: string;
  TareaAgente: string;
  TareaAgenteDescripcion: string;
  TareaEspecifica: string;
  TareaEspecificaDescripcion: string;
}

interface IAccidente {
  Ampliacion: IAmpliacion;
  Descripcion: string;
  DiaSemana: string;
  DiaSemanaInt: number;
  Evaluacion: boolean;
  FechaAccidente: string;
  FechaBaja: string;
  FechaHora: string | null;
  Habitual: boolean;
  Hora: string;
  HoraTrabajo: string;
  Id: string;
  MotivoRechazo: string;
}
interface ITabContent {
  data: IAccidente;
  reset: any;
  watch: any;
  errors: any;
  register: any;
  setValue: any;
  disabled: boolean,
  valuesForm?: any

}
interface IFormContact{
  Codigo: string;
  Descripcion: string;
}
const Accident: React.FC<ITabContent> = ({
  data,
  reset,
  watch,
  errors,
  register,
  setValue,
  disabled,
  valuesForm,


}) => {

  const [allData, setAllData] = useState<IFormContact[]>();
  const [allDataTypeWork, setAllDataTypeWork] = useState<any>();
  const [allTypeOfPlace, setAllTypeOfPlace] = useState<any>()
  const [dayWeek, setDayWeek] = useState(Moment(data.FechaAccidente).format("dddd"))
  const [newleavingDate, setleavingDate] = useState('')
  const watchAccidentDate = watch("accident_accidentDate");
  const watchLeavingDate = watch('aaccident_leavingDate')
  // const watchTypeOfPlaceCode = watch("accident_extension_typeSite");
  // const watchTypeOfWorkCode = watch("accident_extension_typeWork");
  // const watchTypeOfWorkDescription = watch(
  //   "accident_extension_typeWorkDescription"
  // );
  const watchPhisicalActivityDetailCode = watch("accident_extension_specify");
  const watchPhisicalActivityDetailDescription = watch(
    "accident_extension_specifyDescription"
  );
  // const watchPhisicalActivityAgentDetailCode = watch('phisicalActivityAgentDetailCode')
  // const watchPhisicalActivityAgentDetailDescription = watch('phisicalActivityAgentDetailDescription')
  const watchTriggerUnusualFactDetailCode = watch("accident_extension_desv");
  const watchTriggerUnusualFactDetailDescription = watch(
    "accident_extension_desvDescription"
  );
  const {
    getAllContactForm,
    getTypeWork,
    getTypePlace
  } = useUtils();
  useEffect(() => {

    handleGetData();
    handleGetDataTypeWork()
    handleGetTypePlace();

  }, []);
  useEffect(() => {

    if (watchAccidentDate) {
      setleavingDate(watchAccidentDate && watchAccidentDate );
    }
  }, [watchAccidentDate, watchLeavingDate]);
  // useEffect(() => {
  //   setleavingDate(valuesForm?.accident_accidentDate)
  // }, [valuesForm?.accident_accidentDate, valuesForm?.leaving_date,watchLeavingDate])

  const handleGetData = async () => {
    const response = await getAllContactForm();
    if (response) {
      setAllData(response);
    }
  };
  const handleGetDataTypeWork = async () => {
    const response = await getTypeWork()
    if (response) {
      setAllDataTypeWork(response);
    }
  }
  const handleGetTypePlace = async () => {
    const response = await getTypePlace();
    if (response) {
      setAllTypeOfPlace(response)
    }
  }
  const getDayWeek = (date?: Date) => {
    if (date)
      setDayWeek(Moment(date).format("dddd"))
  }
  return (
    <div className="container form--simple-styled" id="accident_form">
      <div className="row">
        <div className="col-12">
          <FieldsetContainer legend="Datos del accidente" disabled={disabled}>
            <div className="col-xl-3 col-md-6">
              <InputDate
                id="accident_accidentDate"
                name="accident_accidentDate"
                label="Fecha del accidente"
                placeholder="Fecha del accidente"
                errors={errors}
                register={register}
                defaultValue={data?.FechaAccidente}
                getDayWeek={getDayWeek}
                setValue={setValue}
                minDate={valuesForm?.worker_admissionDate ? Moment(valuesForm?.worker_admissionDate, 'DD/MM/YYYY', true).toDate() : Moment(data?.FechaAccidente, 'DD/MM/YYYY', true).toDate()}

              />
            </div>
            <div className="col-xl-3 col-md-6">
              <InputDate
                id="accident_leavingDate"
                name="accident_leavingDate"
                label="Fecha de Baja Médica"
                placeholder="Fecha de Baja Médica"
                errors={errors}
                register={register}
                defaultValue={data?.FechaBaja}
                setValue={setValue}
                minDate={valuesForm?.accident_accidentDate ? Moment(valuesForm?.accident_accidentDate, 'DD/MM/YYYY', true).toDate() : Moment(data?.FechaBaja, 'DD/MM/YYYY', true).toDate()}


              />
            </div>
            <div className="col-xl-3 col-md-4">
              <InputText
                id="accident_hour"
                name="accident_hour"
                label="Hora del accidente (1 a 24)"
                placeholder="Hora del accidente (1 a 24)"
                errors={errors}
                register={register}

              />
            </div>
            <div className="col-xl-3 col-md-4">
              <InputText
                id="accident_hourWork"
                name="accident_hourWork"
                label="Hora de trabajo (1ª, 2ª,etc)"
                placeholder="Hora de trabajo (1ª, 2ª,etc)"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-3 col-md-4">
              <InputText
                id="accident_day_week"
                name="accident_day_week"
                label="Dia de la semana"
                placeholder="Dia de la semana"
                errors={errors}
                register={register}
                defaultValue={dayWeek}
                disabled
              />
            </div>
            <div className="col-xl-4">
              <DoubleRadioCheckGroup
                legend="Era su trabajo habitual"
                idA="accident_habitualYes"
                valueA="true"
                valueB="false"
                labelA="Sí"
                idB="accident_habitualNo"
                labelB="No"
                nameA="accident_habitual"
                type="radio"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-xl-8">
              <fieldset className="fieldset-input-block--group">
                <legend className="fieldset-input-block--group__legend">
                  Se ha realizado evaluación de riesgos sobre el puesto de
                  trabajo en el que ha ocurrido el accidente
                </legend>
                <div>
                  <YesNoNoAnswer
                    yesId="accident_evaluationYes"
                    noId="accident_evaluationNo"
                    noAnswerId="accident_evaluationNoAnswer"
                    name="accident_evaluation"
                    errors={errors}
                    register={register}
                  />
                </div>
              </fieldset>
            </div>
            <div className="col-xl-12">
              <div
                className={`input-block ${errors && errors["accident_description"]?.message
                  ? "error"
                  : ""
                  }`}
              >
                <label
                  htmlFor="accident_description"
                  className="input-block__label"
                >
                  Descripción del accidente
                </label>
                <textarea
                  name="accident_description"
                  id="accident_description"
                  cols={30}
                  rows={10}
                  {...register("accident_description")}
                />
                {errors && (
                  <p className="error m-0">
                    {errors["accident_description"]?.message}
                  </p>
                )}
              </div>
            </div>
          </FieldsetContainer>
        </div>

        <div className="col-12 mt-5">
          <h2 className="section-form-title">
            Ampliación de la descripción (según normas de la OIT y EUROSTAT)
          </h2>
        </div>
        <div className="col-12">
          <FieldsetContainer legend="¿En qué tipo de lugar se encontraba la persona accidentada cuando se produjo el accidente?" disabled={disabled}>
            <div className="col-12">
              <InputText
                id="accident_extension_site"
                name="accident_extension_site"
                label=""
                placeholder=""
                errors={errors}
                register={register}
              />
              <TypeOfPlace
                arraData={allTypeOfPlace && allTypeOfPlace}
                id="accident_extension_typeSite"
                errors={errors}
                register={register}
                title="	Tipo de lugar"
              />
            </div>
          </FieldsetContainer>
        </div>

        <div className="col-12">
          <FieldsetContainer legend="¿Qué tipo de trabajo estaba realizando la persona accidentada o en qué proceso de trabajo participaba cuando se produjo el accidente?" disabled={disabled}>
            <div className="col-12">
              <InputText
                id="accident_extension_work"
                name="accident_extension_work"
                label=""
                placeholder=""
                errors={errors}
                register={register}
              />
              <TypeOfWork
                arraData={allDataTypeWork && allDataTypeWork}
                id="accident_extension_typeWork"
                errors={errors}
                register={register}
                title="	Tipo de trabajo"
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer legend="¿Qué estaba haciendo concretamente la persona accidentada cuando se produjo el accidente?" disabled={disabled}>
            <div className="col-12">
              <InputText
                id="accident_extension_agentText"
                name="accident_extension_agentText"
                label=""
                placeholder=""
                errors={errors}
                register={register}
              />
              <PhysicalActivity
                reset={reset}
                errors={errors}
                register={register}
                setValue={setValue}
                watchPhisicalActivityDetailCode={
                  watchPhisicalActivityDetailCode
                }
                watchPhisicalActivityDetailDescription={
                  watchPhisicalActivityDetailDescription
                }
                // watchPhisicalActivityAgentDetailCode={watchPhisicalActivityAgentDetailCode}
                // watchPhisicalActivityAgentDetailDescription={watchPhisicalActivityAgentDetailDescription}
                concatCode={data?.Ampliacion?.DesencadenanteAgen}
              />
            </div>
          </FieldsetContainer>
        </div>
        <div className="col-12">
          <FieldsetContainer legend="¿Qué hecho anormal que se apartase del proceso habitual de trabajo, desencadenó el accidente?" disabled={disabled}>
            <div className="col-12">
              <InputText
                id="accident_extension_activity"
                name="accident_extension_activity"
                label=""
                placeholder=""
                errors={errors}
                register={register}
              />
              <TriggerUnusualFact
                reset={reset}
                errors={errors}
                register={register}
                setValue={setValue}
                watchTriggerUnusualFactDetailCode={
                  watchTriggerUnusualFactDetailCode
                }
                watchTriggerUnusualFactDetailDescription={
                  watchTriggerUnusualFactDetailDescription
                }
                concatCode={data?.Ampliacion?.DesencadenanteAgen}
              />
            </div>
          </FieldsetContainer>
        </div>

        <div className="col-12">
          <FieldsetContainer legend="¿Cómo se ha lesionado la persona accidentada?" disabled={disabled}>
            <div className="col-12">
              <InputText
                id="accident_extension_how_injury"
                name="accident_extension_how_injury"
                label=""
                placeholder=""
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <DropDownMaster2Digi
                arraData={allData && allData}
                id="accident_extension_form_contact_code"
                errors={errors}
                register={register}
                title="	Forma (contacto-modalidad de la lesión)"
              />
            </div>
            <div className="col-12">
              <InputText
                id="accident_extension_form_text_agent"
                name="accident_extension_form_text_agent"
                label="Texto del Aparato o Agente Material causante:"
                placeholder="Texto del Aparato o Agente Material causante:"
                errors={errors}
                register={register}
              />
            </div>
            <div className="col-12">
              <MaterialAgent
                reset={reset}
                errors={errors}
                register={register}
                setValue={setValue}
                id={'material_Agent_As_Injured'}
                title={'Agente Material Causante'}
                concatCode={data?.Ampliacion?.ModoAgente}
                code2name="code2_material_Agent_As_Injured"

              >

              </MaterialAgent>
            </div>

          </FieldsetContainer>
        </div>

        <div className="col-12">
          <FieldsetContainer legend="Marque si ha afectado a más de un trabajador" disabled={disabled}>
            <div className="col-12">
              <YesNoNoAnswer
                yesId="accident_extension_multiplesYes"
                noId="accident_extension_multiplesNo"
                noAnswerId="accident_extension_multiplesNoAnswer"
                name="accident_extension_multiples"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>

        <div className="col-12">
          <FieldsetContainer legend="Marque si hubo testigos" disabled={disabled}>
            <div className="col-12">
              <YesNoNoAnswer
                yesId="accident_extension_witnessesYes"
                noId="accident_extension_witnessesNo"
                noAnswerId="accident_extension_witnessesNoAnswer"
                name="accident_extension_witnesses"
                errors={errors}
                register={register}
              />
            </div>
          </FieldsetContainer>
        </div>
      </div>
    </div>
  );
};

export default Accident;
