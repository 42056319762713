const masterDataUtils = (masterDataContext: any) => {
    const keyPrefix = "masterData";
    const masterData = masterDataContext?.masterData;
    const handleMasterDataChange = masterDataContext?.handleMasterDataChange;

    const handleGetAll = async (itemKey: string, apiCall: Function, setData: Function) => {
        const sessionStorageKey: string = `${keyPrefix}.${itemKey}`;
        const response = await apiCall();
        setData(response);
        if (handleMasterDataChange) {
            handleMasterDataChange(itemKey, { init: false, loading: false, result: response });
        }
        setTimeout(() => {
            sessionStorage.removeItem(sessionStorageKey);
        }, 10000);
    };

    const checkAndUpdate = (itemKey: string, data: any, apiCall: Function, setData: Function) => {
        const sessionStorageKey: string = `${keyPrefix}.${itemKey}`;

        if (masterData && masterData.init && !masterData[itemKey]) {
            if (handleMasterDataChange) {
                handleMasterDataChange(itemKey, { init: true });
            }
        }
        if (masterData && masterData[itemKey] && !masterData[itemKey].loading && masterData[itemKey].init) {
            let ls = sessionStorage.getItem(sessionStorageKey);
            if (!ls && masterData[itemKey].init) {
                sessionStorage.setItem(sessionStorageKey, "true");
                if (handleMasterDataChange) {
                    handleMasterDataChange(itemKey, { init: false, loading: true, result: [] });
                }
                handleGetAll(itemKey, apiCall, setData);
            }
        }
        if (masterData && masterData[itemKey] && !masterData[itemKey].loading && masterData[itemKey].result?.length > 0 && (!data || data.length == 0)) {
            setData(masterData[itemKey].result);
        }
    }
    const clearSessionStoragekeys = () => {
        Object.keys(sessionStorage).filter(x => x.includes(keyPrefix)).forEach(x => {
            // console.log(x);
            sessionStorage.removeItem(x);
        })
    }

    return {
        checkAndUpdate, clearSessionStoragekeys
    }

}

export default masterDataUtils;